/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./shared/layout/full-page-message/full-page-message.component.ngfactory";
import * as i5 from "./shared/layout/full-page-message/full-page-message.component";
import * as i6 from "./shared/layout/loading-wrapper/loading-wrapper.component.ngfactory";
import * as i7 from "./shared/layout/loading-wrapper/loading-wrapper.component";
import * as i8 from "@ngrx/store";
import * as i9 from "./app.component";
import * as i10 from "angulartics2/piwik";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "feedback"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "open-button"], ["routerLinkActive", "hidden"], ["skipLocationChange", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, [[1, 4]], 0, i1.RouterLink, [i1.Router, i1.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { skipLocationChange: [0, "skipLocationChange"], routerLink: [1, "routerLink"] }, null), i0.ɵpod(3, { "feedback": 0 }), i0.ɵpod(4, { outlets: 0 }), i0.ɵpad(5, 2), i0.ɵdid(6, 1720320, null, 2, i1.RouterLinkActive, [i1.Router, i0.ElementRef, i0.Renderer2, [2, i1.RouterLink], [2, i1.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i0.ɵqud(603979776, 1, { links: 1 }), i0.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i0.ɵeld(9, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, ["", ""])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = ""; var currVal_1 = _ck(_v, 5, 0, "", _ck(_v, 4, 0, _ck(_v, 3, 0, "feedback"))); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "hidden"; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_3 = i0.ɵunv(_v, 10, 0, i0.ɵnov(_v, 11).transform("feedback.feedback")); _ck(_v, 10, 0, currVal_3); }); }
function View_AppComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(2, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(3, 16777216, null, null, 1, "router-outlet", [["name", "overlay"]], null, null, null, null, null)), i0.ɵdid(4, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, "overlay"], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(5, 16777216, null, null, 1, "router-outlet", [["name", "feedback"]], null, null, null, null, null)), i0.ɵdid(6, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, "feedback"], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_AppComponent_2)), i0.ɵdid(8, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); _ck(_v, 4, 0); _ck(_v, 6, 0); var currVal_0 = i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform((!_co.isLoginFailurePage() && _co.showFeedbackButton$))); _ck(_v, 8, 0, currVal_0); }, null); }
function View_AppComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "caple-full-page-message", [], null, null, null, i4.View_FullPageMessageComponent_0, i4.RenderType_FullPageMessageComponent)), i0.ɵdid(1, 49152, null, 0, i5.FullPageMessageComponent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 4, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Something went wrong while loading the application. Please come back at a later moment in time. If this message persist, please contact your Caple Representative or send email to "])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "a", [["href", "mailto:support@caple.eu"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["support@caple.eu"])), (_l()(), i0.ɵted(-1, null, ["."])), (_l()(), i0.ɵeld(7, 0, null, 0, 0, "hr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, 0, 4, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Er is iets fout gegaan tijdens het laden van de applicatie. Kom alstublieft op een later moment terug. Als u deze melding blijft zien, neem dan contact op met uw Caple contactpersoon or stuur een email naar "])), (_l()(), i0.ɵeld(10, 0, null, null, 1, "a", [["href", "mailto:support@caple.eu"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["support@caple.eu"])), (_l()(), i0.ɵted(-1, null, ["."]))], null, null); }
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "caple-loading-wrapper", [["key", "urn:preferences:load"]], null, null, null, i6.View_LoadingWrapperComponent_0, i6.RenderType_LoadingWrapperComponent)), i0.ɵdid(1, 49152, null, 0, i7.LoadingWrapperComponent, [i8.Store], { key: [0, "key"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 2, null, View_AppComponent_1)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["noPreferences", 2]], 0, 0, null, View_AppComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "urn:preferences:load"; _ck(_v, 1, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.preferencesLoaded$)); var currVal_2 = i0.ɵnov(_v, 5); _ck(_v, 3, 0, currVal_1, currVal_2); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "caple-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 180224, null, 0, i9.AppComponent, [i8.Store, i10.Angulartics2Piwik, i1.Router], null, null)], null, null); }
var AppComponentNgFactory = i0.ɵccf("caple-root", i9.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
