var _a;
import { createSelector } from '@ngrx/store';
import { eligibilityAdapter, selectFeature } from './reducer';
import { selectHasOneOfRoles } from '../../state-management/login/selector';
import { Role } from '../../shared/api/models/role';
var ɵ0 = function (state) { return state.eligibilityChecks; };
var selectEligibilityChecksSlice = createSelector(selectFeature, ɵ0);
/////////////
//// Selectors
/////////////
/** Eligibility **/
export var selectEligibilityIsLoaded = (_a = eligibilityAdapter.getSelectors(selectEligibilityChecksSlice), _a.selectSingleIsLoaded), selectEligibility = _a.selectSingle;
export var selectEligibilityIdByCorrelationId = function (corrId) { return createSelector(selectFeature, function (state) { return state.correlationIdToEligibilityId[corrId]; }); };
export var selectEligibilityEditable = selectHasOneOfRoles([Role.PARTNER, Role.DIRECTCLIENT]);
export { ɵ0 };
