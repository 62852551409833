import * as tslib_1 from "tslib";
import { actions, EntityAction } from '../../state-management/util/actions';
import { SLICE } from './slice';
export var kycActions = tslib_1.__assign({}, actions, { DOWNLOAD_KYC_PACK: 'DOWNLOAD_KYC_PACK', DOWNLOAD_EXAMPLE: 'DOWNLOAD_EXAMPLE', DOWNLOAD_INSTRUCTIONS: 'DOWNLOAD_INSTRUCTIONS', DOWNLOAD_TEMPLATE: 'DOWNLOAD_TEMPLATE', DOWNLOAD_FILE: 'DOWNLOAD_FILE', ATTACH_FILE: 'ATTACH_FILE', ATTACH_FILE_SUCCESS: 'ATTACH_FILE_SUCCESS', REMOVE_FILE: 'REMOVE_FILE', REMOVE_FILE_SUCCESS: 'REMOVE_FILE_SUCCESS', ASSESS_FILE: 'ASSESS_FILE', ASSESS_FILE_SUCCESS: 'ASSESS_FILE_SUCCESS', ASSESS_ITEM: 'ASSESS_ITEM', ASSESS_ITEM_SUCCESS: 'ASSESS_ITEM_SUCCESS', COMPLETE_KYC: 'COMPLETE_KYC', COMPLETE_KYC_SUCCESS: 'COMPLETE_KYC_SUCCESS', UPGRADE_KYC: 'UPGRADE_KYC', UPGRADE_KYC_SUCCESS: 'UPGRADE_KYC_SUCCESS', REOPEN_KYC: 'REOPEN_KYC', REOPEN_KYC_SUCCESS: 'REOPEN_KYC_SUCCESS', UPDATE_BANKING_INFORMATION: 'UPDATE_BANKING_INFORMATION', UPDATE_BANKING_INFORMATION_SUCCESS: 'UPDATE_BANKING_INFORMATION_SUCCESS' });
var DownloadKYCPack = /** @class */ (function (_super) {
    tslib_1.__extends(DownloadKYCPack, _super);
    function DownloadKYCPack(payload) {
        var _this = _super.call(this, SLICE.KYC_FILE, payload) || this;
        _this.actionName = kycActions.DOWNLOAD_KYC_PACK;
        return _this;
    }
    return DownloadKYCPack;
}(EntityAction));
export { DownloadKYCPack };
var DownloadResource = /** @class */ (function (_super) {
    tslib_1.__extends(DownloadResource, _super);
    function DownloadResource(payload, translationKey) {
        var _this = _super.call(this, SLICE.KYC_FILE, payload) || this;
        _this.translationKey = translationKey;
        return _this;
    }
    return DownloadResource;
}(EntityAction));
export { DownloadResource };
var DownloadExample = /** @class */ (function (_super) {
    tslib_1.__extends(DownloadExample, _super);
    function DownloadExample(payload, translationKey) {
        var _this = _super.call(this, payload) || this;
        _this.translationKey = translationKey;
        _this.actionName = kycActions.DOWNLOAD_EXAMPLE;
        return _this;
    }
    return DownloadExample;
}(DownloadResource));
export { DownloadExample };
var DownloadTemplate = /** @class */ (function (_super) {
    tslib_1.__extends(DownloadTemplate, _super);
    function DownloadTemplate(payload, translationKey) {
        var _this = _super.call(this, payload) || this;
        _this.translationKey = translationKey;
        _this.actionName = kycActions.DOWNLOAD_TEMPLATE;
        return _this;
    }
    return DownloadTemplate;
}(DownloadResource));
export { DownloadTemplate };
var DownloadInstructions = /** @class */ (function (_super) {
    tslib_1.__extends(DownloadInstructions, _super);
    function DownloadInstructions(payload, translationKey) {
        var _this = _super.call(this, payload) || this;
        _this.translationKey = translationKey;
        _this.actionName = kycActions.DOWNLOAD_INSTRUCTIONS;
        return _this;
    }
    return DownloadInstructions;
}(DownloadResource));
export { DownloadInstructions };
var DownloadFile = /** @class */ (function (_super) {
    tslib_1.__extends(DownloadFile, _super);
    function DownloadFile(payload) {
        var _this = _super.call(this, SLICE.KYC_FILE, payload) || this;
        _this.actionName = kycActions.DOWNLOAD_FILE;
        return _this;
    }
    return DownloadFile;
}(EntityAction));
export { DownloadFile };
var AttachFile = /** @class */ (function (_super) {
    tslib_1.__extends(AttachFile, _super);
    function AttachFile(payload) {
        var _this = _super.call(this, SLICE.KYC_FILE, payload) || this;
        _this.actionName = kycActions.ATTACH_FILE;
        return _this;
    }
    return AttachFile;
}(EntityAction));
export { AttachFile };
var AttachFileSuccess = /** @class */ (function (_super) {
    tslib_1.__extends(AttachFileSuccess, _super);
    function AttachFileSuccess(payload) {
        var _this = _super.call(this, SLICE.KYC_FILE, payload) || this;
        _this.actionName = kycActions.ATTACH_FILE_SUCCESS;
        return _this;
    }
    return AttachFileSuccess;
}(EntityAction));
export { AttachFileSuccess };
var RemoveFile = /** @class */ (function (_super) {
    tslib_1.__extends(RemoveFile, _super);
    function RemoveFile(payload) {
        var _this = _super.call(this, SLICE.KYC_FILE, payload) || this;
        _this.actionName = kycActions.REMOVE_FILE;
        return _this;
    }
    return RemoveFile;
}(EntityAction));
export { RemoveFile };
var RemoveFileSuccess = /** @class */ (function (_super) {
    tslib_1.__extends(RemoveFileSuccess, _super);
    function RemoveFileSuccess(payload) {
        var _this = _super.call(this, SLICE.KYC_FILE, payload) || this;
        _this.actionName = kycActions.REMOVE_FILE_SUCCESS;
        return _this;
    }
    return RemoveFileSuccess;
}(EntityAction));
export { RemoveFileSuccess };
var CompleteKYC = /** @class */ (function (_super) {
    tslib_1.__extends(CompleteKYC, _super);
    function CompleteKYC(payload) {
        var _this = _super.call(this, SLICE.KYC_FILE, payload) || this;
        _this.actionName = kycActions.COMPLETE_KYC;
        return _this;
    }
    return CompleteKYC;
}(EntityAction));
export { CompleteKYC };
var CompleteKYCSuccess = /** @class */ (function (_super) {
    tslib_1.__extends(CompleteKYCSuccess, _super);
    function CompleteKYCSuccess(payload) {
        var _this = _super.call(this, SLICE.KYC_FILE, payload) || this;
        _this.actionName = kycActions.COMPLETE_KYC_SUCCESS;
        return _this;
    }
    return CompleteKYCSuccess;
}(EntityAction));
export { CompleteKYCSuccess };
var ReopenKYC = /** @class */ (function (_super) {
    tslib_1.__extends(ReopenKYC, _super);
    function ReopenKYC(payload) {
        var _this = _super.call(this, SLICE.KYC_FILE, payload) || this;
        _this.actionName = kycActions.REOPEN_KYC;
        return _this;
    }
    return ReopenKYC;
}(EntityAction));
export { ReopenKYC };
var ReopenKYCSuccess = /** @class */ (function (_super) {
    tslib_1.__extends(ReopenKYCSuccess, _super);
    function ReopenKYCSuccess(payload) {
        var _this = _super.call(this, SLICE.KYC_FILE, payload) || this;
        _this.actionName = kycActions.REOPEN_KYC_SUCCESS;
        return _this;
    }
    return ReopenKYCSuccess;
}(EntityAction));
export { ReopenKYCSuccess };
var UpgradeKYC = /** @class */ (function (_super) {
    tslib_1.__extends(UpgradeKYC, _super);
    function UpgradeKYC(payload) {
        var _this = _super.call(this, SLICE.KYC_FILE, payload) || this;
        _this.actionName = kycActions.UPGRADE_KYC;
        return _this;
    }
    return UpgradeKYC;
}(EntityAction));
export { UpgradeKYC };
var UpgradeKYCSuccess = /** @class */ (function (_super) {
    tslib_1.__extends(UpgradeKYCSuccess, _super);
    function UpgradeKYCSuccess(payload) {
        var _this = _super.call(this, SLICE.KYC_FILE, payload) || this;
        _this.actionName = kycActions.UPGRADE_KYC_SUCCESS;
        return _this;
    }
    return UpgradeKYCSuccess;
}(EntityAction));
export { UpgradeKYCSuccess };
var AssessFile = /** @class */ (function (_super) {
    tslib_1.__extends(AssessFile, _super);
    function AssessFile(payload) {
        var _this = _super.call(this, SLICE.KYC_FILE, payload) || this;
        _this.actionName = kycActions.ASSESS_FILE;
        return _this;
    }
    return AssessFile;
}(EntityAction));
export { AssessFile };
var AssessFileSuccess = /** @class */ (function (_super) {
    tslib_1.__extends(AssessFileSuccess, _super);
    function AssessFileSuccess(payload) {
        var _this = _super.call(this, SLICE.KYC_FILE, payload) || this;
        _this.actionName = kycActions.ASSESS_FILE_SUCCESS;
        return _this;
    }
    return AssessFileSuccess;
}(EntityAction));
export { AssessFileSuccess };
var AssessItem = /** @class */ (function (_super) {
    tslib_1.__extends(AssessItem, _super);
    function AssessItem(payload) {
        var _this = _super.call(this, SLICE.KYC_FILE, payload) || this;
        _this.actionName = kycActions.ASSESS_ITEM;
        return _this;
    }
    return AssessItem;
}(EntityAction));
export { AssessItem };
var AssessItemSuccess = /** @class */ (function (_super) {
    tslib_1.__extends(AssessItemSuccess, _super);
    function AssessItemSuccess(payload) {
        var _this = _super.call(this, SLICE.KYC_FILE, payload) || this;
        _this.actionName = kycActions.ASSESS_ITEM_SUCCESS;
        return _this;
    }
    return AssessItemSuccess;
}(EntityAction));
export { AssessItemSuccess };
var UpdateBankingInformation = /** @class */ (function (_super) {
    tslib_1.__extends(UpdateBankingInformation, _super);
    function UpdateBankingInformation(payload) {
        var _this = _super.call(this, SLICE.KYC_FILE, payload) || this;
        _this.actionName = kycActions.UPDATE_BANKING_INFORMATION;
        return _this;
    }
    return UpdateBankingInformation;
}(EntityAction));
export { UpdateBankingInformation };
var UpdateBankingInformationSuccess = /** @class */ (function (_super) {
    tslib_1.__extends(UpdateBankingInformationSuccess, _super);
    function UpdateBankingInformationSuccess(payload) {
        var _this = _super.call(this, SLICE.KYC_FILE, payload) || this;
        _this.actionName = kycActions.UPDATE_BANKING_INFORMATION_SUCCESS;
        return _this;
    }
    return UpdateBankingInformationSuccess;
}(EntityAction));
export { UpdateBankingInformationSuccess };
