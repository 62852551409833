import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { enableAutoRenewal, TokenRenewalService } from './token-renewal.service';

@NgModule({
  providers: [
    TokenRenewalService,
    {provide: APP_INITIALIZER, useFactory: enableAutoRenewal, deps: [TokenRenewalService], multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
  ]
})
export class AuthModule {
}
