<h2 mat-dialog-title>{{ 'monitoring.edit-covenants.title' | translate}}</h2>
<mat-dialog-content>
  <form [formGroup]="form.form" class="covenants-form">
    <div class="repeatable-array-container">
      <caple-repeatable-array
        formArrayName="covenants"
        [itemsFormArray]="form.get('covenants')"
        [component]="selectCovenantsComponent">
      </caple-repeatable-array>
    </div>
  </form>

  <mat-dialog-actions>
    <button mat-button (click)="closeModal()">
      {{ 'generic.cancel' | translate }}
    </button>

    <caple-loading-wrapper key="urn:monitoring-file:covenants:update" [diameter]="30">
      <button mat-button (click)="changeCovenants()">
        <span>{{'generic.save' | translate }}</span>
      </button>
    </caple-loading-wrapper>
  </mat-dialog-actions>
</mat-dialog-content>

