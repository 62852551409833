/**
 * Caple Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type Role = 'ADMINISTRATOR' | 'CREDIT_OFFICER' | 'DEVOPS' | 'PARTNER' | 'REPORTING_SOURCE' | 'BORROWER' | 'LOAN_SERVICER' | 'MONITORING_OFFICER' | 'CLIENT' | 'DIRECT_CLIENT' | 'SYSTEM' | 'UNKNOWN';

export const Role = {
    ADMINISTRATOR: 'ADMINISTRATOR' as Role,
    CREDITOFFICER: 'CREDIT_OFFICER' as Role,
    DEVOPS: 'DEVOPS' as Role,
    PARTNER: 'PARTNER' as Role,
    REPORTINGSOURCE: 'REPORTING_SOURCE' as Role,
    BORROWER: 'BORROWER' as Role,
    LOANSERVICER: 'LOAN_SERVICER' as Role,
    MONITORINGOFFICER: 'MONITORING_OFFICER' as Role,
    CLIENT: 'CLIENT' as Role,
    DIRECTCLIENT: 'DIRECT_CLIENT' as Role,
    SYSTEM: 'SYSTEM' as Role,
    UNKNOWN: 'UNKNOWN' as Role
}
