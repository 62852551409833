<caple-loading-wrapper key="urn:preferences:load">
  <div *ngIf="(preferencesLoaded$ | async); else noPreferences">
    <router-outlet></router-outlet>
    <router-outlet name="overlay"></router-outlet>
    <router-outlet name="feedback"></router-outlet>
    <div class="feedback" *ngIf="!isLoginFailurePage() && showFeedbackButton$ | async">
      <div class="open-button"
           [routerLink]="['', { outlets: { 'feedback': 'feedback' } }]"
           routerLinkActive="hidden"
           skipLocationChange>
        <span>{{'feedback.feedback' | translate}}</span>
      </div>
    </div>
  </div>

  <ng-template #noPreferences>
    <caple-full-page-message>
      <p>Something went wrong while loading the application. Please come back at a later moment in time. If this message persist, please contact your Caple Representative or send
        email to <a href="mailto:support@caple.eu">support@caple.eu</a>.</p>
      <hr/>
      <p>Er is iets fout gegaan tijdens het laden van de applicatie. Kom alstublieft op een later moment terug. Als u deze melding blijft zien, neem dan contact op met uw Caple
        contactpersoon or stuur een email naar <a href="mailto:support@caple.eu">support@caple.eu</a>.</p>
    </caple-full-page-message>
  </ng-template>
</caple-loading-wrapper>
