/**
 * Caple Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type FundingProposalStatus = 'IN_PROGRESS' | 'IN_AMENDMENT' | 'COMPLETED';

export const FundingProposalStatus = {
    INPROGRESS: 'IN_PROGRESS' as FundingProposalStatus,
    INAMENDMENT: 'IN_AMENDMENT' as FundingProposalStatus,
    COMPLETED: 'COMPLETED' as FundingProposalStatus
}
