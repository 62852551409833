import { defer, EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, last, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { Action, select, Store } from '@ngrx/store';
import {
  actions,
  CreateSingleSuccess,
  DeleteSingleSuccess,
  EntityAction,
  Failure,
  LoadAll,
  LoadAllSuccess,
  LoadAllWithoutId,
  LoadSingle,
  LoadSingleSuccess,
  LoadSingleWithoutId, LoadSingleWithVersion,
  UpdateSingleSuccess,
  WithId
} from './actions';
import { typeFor } from './util';
import { HideLoadingIndicator, HideProgressIndicator, ShowLoadingIndicator, ShowProgressIndicator, UpdateProgressIndicator } from '../layout/actions';
import { ApiError } from '../../shared/api/api-error';
import { Injectable } from '@angular/core';
import { State } from '../reducers';


/***************
 * Type declarations
 ***************/

/**
 * Interface that will force a scope to be defined with a function. Due to JavaScripts way of handling scope,
 * if we registerEffectHandler the function without explicitly specifying a scope, the current `this` scope will be used. In
 * most cases this will result in an error. Therefore, use this interface to force the user to specify the scope.
 */
export interface FunctionWrapper {
  functionToExecute: (params?: any) => Observable<any>;
  scope: any;
}

/**
 * The result of an execution
 */
export interface ExecutionResult {
  actionName: string;
  action: EntityAction;
  result: any;
}

/**
 * Simple boolean selector
 */
export declare type BooleanSelector = (store: Store<State>) => boolean;

/**
 * Function that returns a boolean selector. This is used to get the selector for a Single object (by using the
 * id as part of the selector)
 */
export declare type IdBooleanSelector = (id: WithId) => BooleanSelector;

/***************
 * Functions
 ***************/

@Injectable()
export class Effects {

  private activeRequests: Set<any> = new Set<any>();

  constructor(private actions$: Actions,
              private store: Store<any>) {
  }

  /**
   * Generic execution function. Will start listening to certain actions and execute the given function when an action is encountered.
   * @param {string[]} allowedTypes
   * @param {string} loadingUri
   * @param {FunctionWrapper} functionWrapper
   * @returns {Observable<ExecutionResult>}
   */
  public registerEffectHandler(allowedTypes: string[], loadingUri: string, functionWrapper: FunctionWrapper): Observable<ExecutionResult | Failure> {
    return this.actions$
      .pipe(
        ofType(...allowedTypes),
        switchMap((action: EntityAction) =>
          this.executeServiceCallWithLoadingIndicator(action.slice, action, loadingUri, functionWrapper).pipe(
            catchError(err => of(err)))
        ));
  }

  /**
   * Execute a function with loading indicators and default error handling
   *
   * @param {string} key
   * @param action
   * @param {string} loadingUri
   * @param {FunctionWrapper} functionWrapper
   * @returns {Observable<any>}
   */
  public executeServiceCallWithLoadingIndicator(key: string, action: EntityAction, loadingUri: string, functionWrapper: FunctionWrapper): Observable<ExecutionResult> {
    this.store.dispatch(new ShowLoadingIndicator(loadingUri));

    return functionWrapper.functionToExecute.apply(functionWrapper.scope, [action.payload || {}])
      .pipe(
        map(result => {
          return {action, result};
        }),
        catchError((response: HttpErrorResponse) => {
          const apiError = new ApiError(response.error.code, response.error.values);
          return throwError(new Failure(key, apiError));
        }),
        finalize(() => this.store.dispatch(new HideLoadingIndicator(loadingUri)))
      );
  }

  /**
   * Execute a function with loading indicators and default error handling
   *
   * @param {string} key
   * @param action
   * @param {string} translationKey
   * @param {FunctionWrapper} functionWrapper
   * @param translationData
   * @returns {Observable<any>}
   */
  public executeServiceCallWithProgressIndicator(key: string, action: EntityAction, translationKey: string, functionWrapper: FunctionWrapper, translationData?: any): Observable<ExecutionResult> {
    this.store.dispatch(new ShowProgressIndicator(translationKey, translationData));

    return functionWrapper.functionToExecute.apply(functionWrapper.scope, [action.payload || {}])
      .pipe(
        tap((event: HttpEvent<Blob>) => {
          if (event.type === HttpEventType.DownloadProgress) {
            const percentDone = Math.round((event.loaded / event.total) * 100);
            this.store.dispatch(new UpdateProgressIndicator(percentDone, event.loaded, event.total));
          }
        }),
        last(),
        map(result => {
          return {action, result};
        }),
        catchError((response: HttpErrorResponse) => {
          const apiError = new ApiError(response.error.code, response.error.values);
          return throwError(new Failure(key, apiError));
        }),
        finalize(() => this.store.dispatch(new HideProgressIndicator()))
      );
  }

  /**
   * Load all items of a certain type. If the items are already present in the store (determined by using the selector), nothing happens
   * except if `forceReload` is true.
   *
   * @param {string} key The unique key to correlate the action
   * @param {string} loadingUri The Unique Resource Identifier to use for displaying of the loading indicator
   * @param {FunctionWrapper} loadFunctionWrapper The actual implementation of the loading function
   * @param {BooleanSelector} isLoadedSelector The selector that helps to determine if the item is already present in store
   * @returns {Observable<any>}
   */
  public loadAll<T>(key: string, loadingUri: string, loadFunctionWrapper: FunctionWrapper, isLoadedSelector: BooleanSelector): Observable<LoadAllSuccess<T>> {
    return this.actions$
      .pipe(
        ofType(typeFor(key, actions.LOAD_ALL)),
        withLatestFrom(defer(() => this.store.pipe(select(isLoadedSelector)))),
        mergeMap(([action, isLoaded]: [LoadAll, boolean]) => {
          if (!action.forceReload && isLoaded) {
            return EMPTY;
          } else {
            return this.executeServiceCallWithLoadingIndicator(key, action, loadingUri, loadFunctionWrapper).pipe(
              map((result: ExecutionResult | Failure) => {
                if (result instanceof Failure) {
                  return result as Failure;
                } else {
                  return new LoadAllSuccess<T>(key, result.result);
                }
              }),
              catchError(error => of(error)));
          }
        }));
  }

  /**
   * Load all items of a certain type that doesn't have a unique ID. If the items are already present in the store (determined by using the selector), nothing happens
   * except if `forceReload` is true.
   *
   * @param {string} key The unique key to correlate the action
   * @param {string} loadingUri The Unique Resource Identifier to use for displaying of the loading indicator
   * @param {FunctionWrapper} loadFunctionWrapper The actual implementation of the loading function
   * @param {BooleanSelector} isLoadedSelector The selector that helps to determine if the item is already present in store
   * @returns {Observable<any>}
   */
  public loadAllWithoutID<T>(key: string, loadingUri: string, loadFunctionWrapper: FunctionWrapper, isLoadedSelector: BooleanSelector) {
    return this.actions$
      .pipe(
        ofType(typeFor(key, actions.LOAD_ALL_WITHOUT_ID)),
        withLatestFrom(defer(() => this.store.pipe(select(isLoadedSelector)))),
        mergeMap(([action, isLoaded]: [LoadAllWithoutId, boolean]) => {
          if (!action.forceReload && isLoaded) {
            return EMPTY;
          } else {
            return this.executeServiceCallWithLoadingIndicator(key, action, loadingUri, loadFunctionWrapper).pipe(
              map((result: ExecutionResult | Failure) => {
                if (result instanceof Failure) {
                  return result as Failure;
                } else {
                  return new LoadAllSuccess<T>(key, result.result);
                }
              }),
              catchError(error => of(error)));
          }
        }));
  }

  /**
   * Load a single item of a certain type. If the item is already present in the store (determined by using the selector), nothing happens
   * except if `forceReload` is true.
   *
   * @param {string} key The unique key to correlate the action
   * @param {string} loadingUri The Unique Resource Identifier to use for displaying of the loading indicator
   * @param {FunctionWrapper} loadFunctionWrapper The actual implementation of the loading function
   * @param {BooleanSelector} isLoadedIdSelector The selector that helps to determine if the item is already present in store
   * @returns {Observable<any>}
   */
  public loadSingle<T>(key: string, loadingUri: string, loadFunctionWrapper: FunctionWrapper, isLoadedIdSelector: IdBooleanSelector): Observable<LoadSingleSuccess<T>> {
    const loadSingle$ = this.actions$.pipe(ofType(typeFor(key, actions.LOAD_SINGLE)));
    return this.handleLoadSingle(loadSingle$, isLoadedIdSelector, key, loadingUri, loadFunctionWrapper);
  }

  public loadSingleWithVersion<T>(key: string, loadingUri: string, loadFunctionWrapper: FunctionWrapper, isLoadedIdSelector: IdBooleanSelector): Observable<LoadSingleSuccess<T>> {
    const loadSingle$ = this.actions$.pipe(ofType(typeFor(key, actions.LOAD_SINGLE_WITH_VERSION)));
    return this.handleLoadSingle(loadSingle$, isLoadedIdSelector, key, loadingUri, loadFunctionWrapper);
  }

  private handleLoadSingle<T>(loadSingle$: Observable<Action>, isLoadedIdSelector: (id: WithId) => BooleanSelector, key: string, loadingUri: string, loadFunctionWrapper: FunctionWrapper): Observable<LoadSingleSuccess<T>> {
    return loadSingle$.pipe(
      filter((action: LoadSingle) => {
        const requestAlreadyActive = this.activeRequests.has(this.getActionIdentifier(action));

        if (!requestAlreadyActive) {
          this.activeRequests.add(this.getActionIdentifier(action));
        }

        return action.forceReload || !requestAlreadyActive;
      }),
      mergeMap((action: LoadSingle) => {
        return of(action).pipe(
          withLatestFrom(this.store.pipe(select(isLoadedIdSelector(action.payload)))),
          mergeMap(([_, isLoaded]: [any, boolean]) => {
            if (!action.forceReload && isLoaded) {
              this.activeRequests.delete(this.getActionIdentifier(action));
              return EMPTY;
            } else {
              return this.executeServiceCallWithLoadingIndicator(key, action, loadingUri, loadFunctionWrapper).pipe(
                map((result: ExecutionResult | Failure) => {
                  if (result instanceof Failure) {
                    return result as Failure;
                  } else {
                    return new LoadSingleSuccess<T>(key, result.result, result.action.payload);
                  }
                }),
                catchError(error => of(error)),
                finalize(() => this.activeRequests.delete(this.getActionIdentifier(action)))
              );
            }
          }));
      })
    );
  }

  /**
   * Load a single item of a certain type that doesn't contain a unique ID.
   *
   * @param {string} key The unique key to correlate the action
   * @param {string} loadingUri The Unique Resource Identifier to use for displaying of the loading indicator
   * @param {FunctionWrapper} loadFunctionWrapper The actual implementation of the loading function
   * @returns {Observable<any>}
   */
  public loadSingleWithoutID<T>(key: string, loadingUri: string, loadFunctionWrapper: FunctionWrapper) {
    return this.actions$
      .pipe(
        ofType(typeFor(key, actions.LOAD_SINGLE_WITHOUT_ID)),
        mergeMap((action: LoadSingleWithoutId) => {
          return this.executeServiceCallWithLoadingIndicator(key, action, loadingUri, loadFunctionWrapper).pipe(
            map((result: ExecutionResult | Failure) => {
              if (result instanceof Failure) {
                return result as Failure;
              } else {
                return new LoadSingleSuccess<T>(key, result.result, result.action.payload);
              }
            }),
            catchError(error => of(error)));
        }));
  }

  /**
   * Create a new item of a certain type
   *
   * @param {string} key
   * @param {string} loadingUri
   * @param {FunctionWrapper} functionWrapper
   * @returns {Observable<CreateSingleSuccess>}
   */
  public createSingle<T>(key: string, loadingUri: string, functionWrapper: FunctionWrapper) {
    return this.registerEffectHandler([typeFor(key, actions.CREATE_SINGLE)], loadingUri, functionWrapper).pipe(
      map((result: ExecutionResult | Failure) => {
        if (result instanceof Failure) {
          return result as Failure;
        } else {
          return new CreateSingleSuccess<T>(key, result.result, result.action.payload);
        }
      }));
  }

  /**
   * Delete a certain item
   *
   * @param {string} key
   * @param {string} loadingUri
   * @param {FunctionWrapper} functionWrapper
   * @returns {Observable<DeleteSingleSuccess<T>>}
   */
  public deleteSingle<T>(key: string, loadingUri: string, functionWrapper: FunctionWrapper) {
    return this.registerEffectHandler([typeFor(key, actions.DELETE_SINGLE)], loadingUri, functionWrapper).pipe(
      map((result: ExecutionResult | Failure) => {
        if (result instanceof Failure) {
          return result as Failure;
        } else {
          return new DeleteSingleSuccess<T>(key, result.result, result.action.payload);
        }
      }));
  }

  /**
   * Update a single entity
   *
   * @param {string} key
   * @param {string} loadingUri
   * @param {FunctionWrapper} functionWrapper
   * @returns {Observable<LoadSingleSuccess<T>>}
   */
  public updateSingle<T>(key: string, loadingUri: string, functionWrapper: FunctionWrapper): Observable<UpdateSingleSuccess<T> | Failure> {
    return this.registerEffectHandler([typeFor(key, actions.UPDATE_SINGLE)], loadingUri, functionWrapper).pipe(
      map((result: ExecutionResult | Failure) => {
        if (result instanceof Failure) {
          return result as Failure;
        } else {
          return new UpdateSingleSuccess<T>(key, result.result, result.action.payload);
        }
      }));
  }

  private getActionIdentifier(action: LoadSingle) {
    return action.type + action.payload.id;
  }
}


