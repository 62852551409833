/**
 * Caple Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type GrowthFundingUseCategory = 'CAPITAL_EXPENDITURE' | 'OPERATIONAL_EXPENDITURE' | 'WORKING_CAPITAL' | 'REFINANCE' | 'ASSET_PURCHASE' | 'SHAREHOLDER_CASH_OUT' | 'OTHER';

export const GrowthFundingUseCategory = {
    CAPITALEXPENDITURE: 'CAPITAL_EXPENDITURE' as GrowthFundingUseCategory,
    OPERATIONALEXPENDITURE: 'OPERATIONAL_EXPENDITURE' as GrowthFundingUseCategory,
    WORKINGCAPITAL: 'WORKING_CAPITAL' as GrowthFundingUseCategory,
    REFINANCE: 'REFINANCE' as GrowthFundingUseCategory,
    ASSETPURCHASE: 'ASSET_PURCHASE' as GrowthFundingUseCategory,
    SHAREHOLDERCASHOUT: 'SHAREHOLDER_CASH_OUT' as GrowthFundingUseCategory,
    OTHER: 'OTHER' as GrowthFundingUseCategory
}
