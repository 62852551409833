<div class="pure-g">
  <div class="pure-u-5-6">
    <div class="label">
      <ng-content></ng-content>
    </div>
  </div>
  <div class="pure-u-1-6">
    <!-- We have to duplicate these 2 fields as mat-form-field doesn't handle hidden inputs correctly -->
    <mat-form-field *ngIf="!countryForCurrency">
      <input matInput type="number" class="form-control" [formControl]="formControlItem"/>
      <mat-error *ngIf="formControlItem.hasError('required')">{{ 'generic.validation.required' | translate}}</mat-error>
      <mat-error *ngIf="formControlItem.hasError('min')">{{ 'generic.validation.min' | translate:{value: '0'} }}</mat-error>
      <mat-error *ngIf="formControlItem.hasError('pattern')">{{ 'generic.validation.valid-sector-code' | translate}}</mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="countryForCurrency">
      <caple-currency-input class="form-control"
                            [country]="countryForCurrency"
                            [formControl]="formControlItem">
      </caple-currency-input>
      <mat-error *ngIf="formControlItem.hasError('required')">{{ 'generic.validation.required' | translate}}</mat-error>
      <mat-error *ngIf="formControlItem.hasError('min')">{{ 'generic.validation.min' | translate:{value: '0'} }}</mat-error>
      <mat-error *ngIf="formControlItem.hasError('pattern')">{{ 'generic.validation.valid-sector-code' | translate}}</mat-error>
    </mat-form-field>
  </div>
</div>

