import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { LoginModule } from './login/login.module';
import { AppRouting } from './app.routing';
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { metaReducers, reducers } from './state-management/reducers';
import { animationModule, modules } from '../environments/environment';
import { AuthenticationGuard } from './shared/authentication/authentication.guard';
import { EffectsModule } from '@ngrx/effects';
import { LoginEffects } from './state-management/login/effects';
import { LayoutEffects } from './state-management/layout/effects';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GenericEffects } from './state-management/generic/effects';
import { AuthorizationGuard } from './shared/authentication/authorization.guard';
import { Effects } from './state-management/util/effect-functions';
import { NavigationComponent } from './navigation/navigation.component';
import { LayoutModule } from './shared/layout/layout.module';
import { PageNotFoundComponent } from './page-not-found.component';
import { PreferencesEffects } from './state-management/preferences/effects';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { registerLocaleData } from '@angular/common';
import localeNL from '@angular/common/locales/nl';
import localeGB from '@angular/common/locales/en-GB';
import { AnnouncementRefreshService, enableAutoRefresh } from './shared/announcement/announcement-refresh.service';
import { DiscussionDialogModule } from './discussion/discussion-dialog/discussion-dialog.module';
import { AdministrationEntryModule } from './administration/administration-entry.module';
import { MonitoringEntryModule } from './monitoring/monitoring-entry.module';
import { OriginationEntryModule } from './origination/origination-entry.module';
import { CaplePreloadStrategy } from './preloading-strategy';
import { Angulartics2Module } from 'angulartics2';
import { AnalyticsService, initialize } from './analytics.service';
import { GlobalErrorHandler } from './error/global-error-handler';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

registerLocaleData(localeGB, 'en-GB');
registerLocaleData(localeNL, 'nl-NL');

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    PageNotFoundComponent
  ],
  imports: [
    Angulartics2Module.forRoot({
      pageTracking: {
        clearHash: true,
        clearIds: true
      }
    }),
    AppRouting,
    HttpClientModule,

    // Angular
    BrowserModule,
    NgxPageScrollCoreModule.forRoot(),
    NgxPageScrollModule,

    // Angular animation module, which our config can turn off
    animationModule,

    // NgRx
    StoreModule.forRoot(reducers, {
      metaReducers,
      // TODO Fix all the reducer issues so we can turn these on
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false
      }
    }),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([GenericEffects, LoginEffects, LayoutEffects, PreferencesEffects]),

    // NgxTranslate
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),

    // Caple Modules
    LoginModule,
    DiscussionDialogModule,
    AdministrationEntryModule,
    OriginationEntryModule,
    MonitoringEntryModule,

    // Caple Generic modules
    LayoutModule,

    // Modules that are environment specific
    ...modules
  ],
  providers: [
    AuthenticationGuard, AuthorizationGuard, Effects,
    CaplePreloadStrategy,
    AnnouncementRefreshService,
    {provide: APP_INITIALIZER, useFactory: enableAutoRefresh, deps: [AnnouncementRefreshService], multi: true},
    AnalyticsService,
    {provide: APP_INITIALIZER, useFactory: initialize, deps: [AnalyticsService], multi: true},
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
