/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./currency-symbol.component";
import * as i2 from "@ngrx/store";
var styles_CurrencySymbolComponent = [".disabled[_nghost-%COMP%] {\n      color: rgba(0, 0, 0, .38);\n    }"];
var RenderType_CurrencySymbolComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_CurrencySymbolComponent, data: {} });
export { RenderType_CurrencySymbolComponent as RenderType_CurrencySymbolComponent };
export function View_CurrencySymbolComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(0, null, ["", "\u00A0"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.symbol; _ck(_v, 0, 0, currVal_0); }); }
export function View_CurrencySymbolComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "caple-currency-symbol", [], null, null, null, View_CurrencySymbolComponent_0, RenderType_CurrencySymbolComponent)), i0.ɵdid(1, 770048, null, 0, i1.CurrencySymbolComponent, [i2.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CurrencySymbolComponentNgFactory = i0.ɵccf("caple-currency-symbol", i1.CurrencySymbolComponent, View_CurrencySymbolComponent_Host_0, { country: "country" }, {}, []);
export { CurrencySymbolComponentNgFactory as CurrencySymbolComponentNgFactory };
