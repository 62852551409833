import * as tslib_1 from "tslib";
import { tap } from 'rxjs/operators';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { GenericActions } from './actions';
import { Router } from '@angular/router';
var GenericEffects = /** @class */ (function () {
    function GenericEffects(actions$, router) {
        var _this = this;
        this.actions$ = actions$;
        this.router = router;
        this.redirectTo$ = this.actions$
            .pipe(ofType(GenericActions.REDIRECT_TO), tap(function (action) {
            if (action.relativeToCurrentPath) {
                _this.router.navigate(action.url, { relativeTo: _this.deepestActivatedRoute() });
            }
            else {
                _this.router.navigate(action.url);
            }
        }));
        this.redirectToWithParameters$ = this.actions$
            .pipe(ofType(GenericActions.REDIRECT_TO_WITH_PARAMETERS), tap(function (action) {
            if (action.relativeToCurrentPath) {
                _this.router.navigate(action.url, { queryParams: action.params, relativeTo: _this.deepestActivatedRoute() });
            }
            else {
                _this.router.navigate(action.url, { queryParams: action.params });
            }
        }));
    }
    /**
     * Its currently not possible to get the ActivatedRoute in a effect without either A) Storing it in the global state of B) Passing it with
     * each action. Both of these solutions are, for their own reasons, not preferred. This method returns the deepestActivedRoute by scanning
     * through the Router. This is far from optimal and should be replaced once it is possible to get the ActivatedRoute from the global context.
     *
     * This is a function and not a getter because the Redux Dev tools crashes if this is a getter as it tries
     * to serialize this getter with a different `this` context.
     *
     * @returns {ActivatedRoute}
     */
    GenericEffects.prototype.deepestActivatedRoute = function () {
        var route = this.router.routerState.root.firstChild;
        while (route.firstChild) {
            route = route.firstChild;
        }
        return route;
    };
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], GenericEffects.prototype, "redirectTo$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], GenericEffects.prototype, "redirectToWithParameters$", void 0);
    return GenericEffects;
}());
export { GenericEffects };
