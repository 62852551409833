import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found.component';
import { NavigationComponent } from './navigation/navigation.component';
import { AuthenticationGuard } from './shared/authentication/authentication.guard';
import { Role } from './shared/api/models/role';
import { CaplePreloadStrategy } from './preloading-strategy';
import { environment } from '../environments/environment';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    component: NavigationComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'origination',
    loadChildren: () => import('./origination/origination.module').then(m => m.OriginationModule),
    data: {
      onlyPreloadFor: [Role.PARTNER, Role.CREDITOFFICER, Role.DIRECTCLIENT]
    }
  },
  {
    path: 'monitoring',
    loadChildren: () => import('./monitoring/monitoring.module').then(m => m.MonitoringModule),
    data: {
      onlyPreloadFor: [Role.MONITORINGOFFICER, Role.REPORTINGSOURCE, Role.BORROWER, Role.LOANSERVICER]
    }
  },
  {
    path: 'kyc',
    loadChildren: () => import('./kyc/kyc.module').then(m => m.KycModule),
    data: {
      onlyPreloadFor: [Role.CLIENT]
    }
  },
  {
    path: 'conditions-precedent',
    loadChildren: () => import('./conditions-precedent/conditions-precedent.module').then(m => m.ConditionsPrecedentModule),
    data: {
      onlyPreloadFor: [Role.CLIENT]
    }
  },
  {
    path: 'administration',
    loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule),
    data: {
      onlyPreloadFor: [Role.ADMINISTRATOR]
    }
  },
  {
    path: 'devops',
    loadChildren: () => import('./devops/devops.module').then(m => m.DevopsModule),
    data: {
      onlyPreloadFor: [Role.DEVOPS]
    }
  },
  {
    path: 'preferences',
    loadChildren: () => import('./preferences/preferences.module').then(m => m.PreferencesModule),
    data: {
      preloadDelay: true
    }
  },
  {
    path: 'knowledge-center',
    loadChildren: () => import('./knowledge-center/knowledge-center.module').then(m => m.KnowledgeCenterModule),
    outlet: 'overlay',
    data: {
      preloadDelay: true
    }
  },
  {
    path: 'changelog',
    loadChildren: () => import('./changelog/changelog-overview.module').then(m => m.ChangelogOverviewModule),
    outlet: 'overlay',
    data: {
      preloadDelay: true
    }
  },
  {
    path: 'feedback',
    loadChildren: () => import('./feedback/feedback.module').then(m => m.FeedbackModule),
    outlet: 'feedback',
    data: {
      preloadDelay: true
    }
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    canActivate: [AuthenticationGuard]
  }
];

export const AppRouting = RouterModule.forRoot(appRoutes, {
  useHash: false,
  paramsInheritanceStrategy: 'always',
  scrollPositionRestoration: 'top',
  anchorScrolling: 'disabled',

  // Required to fix navigation in lazy loaded modules -> https://github.com/angular/angular/issues/13011.
  // Can be removed in Angular 7 as this will become the default
  relativeLinkResolution: 'corrected',

  preloadingStrategy: environment.preload ? CaplePreloadStrategy : NoPreloading
});
