import { ReadUser } from '../../shared/api/models/readUser';
import { CreateSingleSuccess, EntityAction, LoadSingleSuccess, UpdateSingleSuccess } from '../../state-management/util/actions';
import { compare, typeFor } from '../../state-management/util/util';
import { createForeignTableAdapter, createTableAdapter, ForeignTable, Table } from '../../state-management/util/adapters';
import { SLICE } from './slice';
import { ReadCompany } from '../../shared/api/models/readCompany';
import { CreateCompany } from '../../shared/api/models/createCompany';
import { ReadClient } from '../../shared/api/models/readClient';
import {
  administrationActions,
  administrationActions as actions,
  BlockUserSuccess,
  PromoteToCompanySuccess,
  UnblockUserSuccess,
  UpdateCompanyFilteredCountries,
  UpdateCompanySearchQuery
} from './actions';
import { CompanyUserPayload } from '../../shared/api/services/company.service';

/********
 * Adapters
 ********/
export const companiesAdapter = createTableAdapter<ReadCompany>({
  sort: (a, b) => {
    return compare(a.name, b.name);
  }
});
export const usersAdapter = createForeignTableAdapter<ReadUser>();
export const clientsAdapter = createForeignTableAdapter<ReadClient>({
  idSelector: any => any.companyNumber
});

/********
 * State
 ********/
export interface State {
  companies: Table<ReadCompany>;
  users: ForeignTable<ReadUser>;
  clients: ForeignTable<ReadClient>;
  companyFilteredCountries: string[];
  companySearchQuery: string;
}

export const initialState: State = {
  companies: companiesAdapter.getInitialState(),
  users: usersAdapter.getInitialState(),
  clients: clientsAdapter.getInitialState(),
  companyFilteredCountries: [],
  companySearchQuery: ''
};

/********
 * Reducer
 ********/
export function reducer(state = initialState, action: EntityAction): State {
  switch (action.type) {
    case typeFor(SLICE.COMPANY, actions.LOAD_ALL_SUCCESS): {
      return Object.assign({}, state, {
        companies: companiesAdapter.addAll(action.payload, state.companies)
      });
    }
    case typeFor(SLICE.COMPANY, actions.LOAD_SINGLE_SUCCESS): {
      return Object.assign({}, state, {
        companies: companiesAdapter.addOne(action.payload, state.companies)
      });
    }
    case typeFor(SLICE.COMPANY, actions.CREATE_SINGLE_SUCCESS): {
      const successAction = (action as CreateSingleSuccess<CreateCompany>);
      return Object.assign({}, state, {
        companies: companiesAdapter.addOne({
          ...successAction.payload,
          ...successAction.request,
        } as ReadCompany, state.companies)
      });
    }
    case typeFor(SLICE.COMPANY, actions.UPDATE_SINGLE_SUCCESS): {
      return Object.assign({}, state, {
        companies: companiesAdapter.updateOne({id: action.payload.companyId, name: action.payload.newName} as ReadCompany, state.companies)
      });
    }
    case typeFor(SLICE.USER, actions.LOAD_SINGLE_SUCCESS): {
      const companyId = (action as LoadSingleSuccess<ReadUser>).request.id;
      return Object.assign({}, state, {
        users: usersAdapter.addAll(action.payload, companyId, state.users)
      });
    }
    case typeFor(SLICE.USER, actions.UPDATE_SINGLE_SUCCESS): {
      const request = (action as UpdateSingleSuccess<any>).request;
      return Object.assign({}, state, {
        users: usersAdapter.updateOne(action.payload, request.companyId, state.users)
      });
    }
    case typeFor(SLICE.USER, actions.BLOCK_USER_SUCCESS) : {
      const request: CompanyUserPayload = (action as BlockUserSuccess).payload.payload;
      const user = Object.assign({}, state.users.entities[request.companyId].entities[request.id]);
      user.blocked = true;

      return Object.assign({}, state, {
        users: usersAdapter.updateOne(user, request.companyId, state.users)
      });
    }
    case typeFor(SLICE.USER, actions.UNBLOCK_USER_SUCCESS) : {
      const request: CompanyUserPayload = (action as UnblockUserSuccess).payload.payload;
      const user = Object.assign({}, state.users.entities[request.companyId].entities[request.id]);
      user.blocked = false;

      return Object.assign({}, state, {
        users: usersAdapter.updateOne(user, request.companyId, state.users)
      });
    }
    case typeFor(SLICE.CLIENT, actions.LOAD_SINGLE_SUCCESS): {
      const companyId = (action as LoadSingleSuccess<ReadClient>).request.id;
      return Object.assign({}, state, {
        clients: clientsAdapter.addAll(action.payload, companyId, state.clients)
      });
    }
    case typeFor(SLICE.CLIENT, actions.PROMOTE_TO_COMPANY_SUCCESS): {
      const partnerCompanyId = (action as PromoteToCompanySuccess).request.payload.companyId;
      const clientCompanyNumber = (action as PromoteToCompanySuccess).request.payload.companyNumber;
      const clientCompanyId = (action as PromoteToCompanySuccess).payload.id;

      let clientState = state.clients;
      // Iterate over all companies -> clients in order to mark them as connected to a company
      state.clients.ids.forEach(companyId => {
        state.clients.entities[companyId].ids.forEach(clientId => {
          const client = state.clients.entities[companyId].entities[clientId];

          // We have 2 scenario's here:
          // 1) this is the actual client that got connected to a company
          // 2) this is a client with the same company number but not the client that got connected to a company
          if (client.companyNumber === clientCompanyNumber && companyId === partnerCompanyId) { // 1
            clientState = updateClient(client, clientCompanyId, companyId, clientState);
          } else if (client.companyNumber === clientCompanyNumber) { // 2
            clientState = updateClient(client, undefined, companyId, clientState);
          }
        });
      });

      return Object.assign({}, state, {
        clients: clientState
      });
    }
    case typeFor(SLICE.COMPANY, administrationActions.UPDATE_COMPANY_SEARCH_QUERY): {
      const payload = (action as UpdateCompanySearchQuery).payload;
      return Object.assign({}, state, {companySearchQuery: payload.searchQuery});
    }
    case typeFor(SLICE.COMPANY, administrationActions.UPDATE_COMPANY_FILTERED_COUNTRIES): {
      const payload = (action as UpdateCompanyFilteredCountries).payload;
      let newFilter = Object.assign([], state.companyFilteredCountries);
      if (payload.isChecked) {
        newFilter.push(payload.country);
      } else {
        newFilter = newFilter.filter(i => i !== payload.country);
      }
      return Object.assign({}, state, {companyFilteredCountries: newFilter});
    }
  }

  return state;
}

function updateClient(client, clientCompanyId, companyId, state) {
  const newClient = {
    ...client,
    companyNumberConnectedToCompany: true,
    companyId: clientCompanyId
  };
  return clientsAdapter.updateOne(newClient, companyId, state);
}
