import { createSelector } from '@ngrx/store';
import { State as RootState } from '../reducers';
import { State } from './reducer';

export const selectLayout = (state: RootState) => state.layout;
export const selectIsLoading = (key: string | Array<string>) => createSelector(selectLayout, (state: State) => {
  if (state.loadingIndicatorMessages.length === 0) {
    return false;
  }

  if (key instanceof Array) {
    for (const k of key) {
      if (state.loadingIndicatorMessages.indexOf(k) > -1) {
        return true;
      }
    }
    return false;
  } else {
    return state.loadingIndicatorMessages.indexOf(key) > -1;
  }
});

export const selectProgress = createSelector(selectLayout, (state: State) => {
  return state.progressIndicator;
});
export const selectAnnouncement = createSelector(selectLayout, (state: State) => {
  return state.announcement;
});

export const selectChangelog = createSelector(selectLayout, (state: State) => state.changelog);
