/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i2 from "@angular/material/progress-spinner";
import * as i3 from "@angular/cdk/platform";
import * as i4 from "@angular/common";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "./loading-wrapper.component";
import * as i7 from "@ngrx/store";
var styles_LoadingWrapperComponent = [];
var RenderType_LoadingWrapperComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoadingWrapperComponent, data: {} });
export { RenderType_LoadingWrapperComponent as RenderType_LoadingWrapperComponent };
function View_LoadingWrapperComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "loading-wrapper"]], [[2, "fullscreen", null]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i1.View_MatSpinner_0, i1.RenderType_MatSpinner)), i0.ɵdid(3, 49152, null, 0, i2.MatSpinner, [i0.ElementRef, i3.Platform, [2, i4.DOCUMENT], [2, i5.ANIMATION_MODULE_TYPE], i2.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.diameter; _ck(_v, 3, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fullscreen; _ck(_v, 1, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 3)._noopAnimations; var currVal_2 = i0.ɵnov(_v, 3).diameter; var currVal_3 = i0.ɵnov(_v, 3).diameter; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3); }); }
function View_LoadingWrapperComponent_2(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0), (_l()(), i0.ɵand(0, null, null, 0))], null, null); }
export function View_LoadingWrapperComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_LoadingWrapperComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["notLoading", 2]], null, 0, null, View_LoadingWrapperComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.isLoading$)); var currVal_1 = i0.ɵnov(_v, 3); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_LoadingWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "caple-loading-wrapper", [], null, null, null, View_LoadingWrapperComponent_0, RenderType_LoadingWrapperComponent)), i0.ɵdid(1, 49152, null, 0, i6.LoadingWrapperComponent, [i7.Store], null, null)], null, null); }
var LoadingWrapperComponentNgFactory = i0.ɵccf("caple-loading-wrapper", i6.LoadingWrapperComponent, View_LoadingWrapperComponent_Host_0, { diameter: "diameter", fullscreen: "fullscreen", key: "key" }, {}, ["*"]);
export { LoadingWrapperComponentNgFactory as LoadingWrapperComponentNgFactory };
