import { OnDestroy } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { LoadSingle } from '../../../../state-management/util/actions';
import { filter, map } from 'rxjs/operators';
import { CloseModal } from '../../../../state-management/layout/actions';
import { SLICE } from '../../../state-management/slice';
import { selectUsersForCompany } from '../../../state-management/selector';
import { UpdateOwnerUsers } from '../../../state-management/actions';
import { selectUserId } from '../../../../state-management/login/selector';
var UpdatePartnerTeamDialogComponent = /** @class */ (function () {
    function UpdatePartnerTeamDialogComponent(store, formBuilder, data) {
        var _this = this;
        this.store = store;
        this.formBuilder = formBuilder;
        this.data = data;
        this.subscriptions = new Subscription();
        this.id = data.id;
        this.owner = data.owner;
        this.form = formBuilder.group({});
        this.store.dispatch(new LoadSingle(SLICE.COMPANY_USERS, { id: this.owner.partner.companyId }));
        this.users$ = this.store.pipe(select(selectUsersForCompany(this.owner.partner.companyId)), filter(function (table) { return !!table; }), map(function (table) {
            return Object.values(table.entities);
        }));
        this.subscriptions.add(combineLatest([this.users$, this.store.pipe(select(selectUserId))]).subscribe(function (_a) {
            var companyUsers = _a[0], currentUser = _a[1];
            var filteredUsers = companyUsers
                .filter(function (companyUser) { return !!_this.owner.users.find(function (ownerUser) { return ownerUser.userId === companyUser.id; }); })
                .map(function (companyUser) { return companyUser.id; });
            _this.form = formBuilder.group({});
            var amountOfSelected = 0;
            companyUsers.forEach(function (companyUser) {
                var checkUserSelected = filteredUsers.includes(companyUser.id);
                var control = formBuilder.control(checkUserSelected);
                if (currentUser === companyUser.id) {
                    control.disable();
                }
                _this.form.addControl(companyUser.id, control);
                if (checkUserSelected) {
                    amountOfSelected++;
                }
            });
            _this.updateDisabled = amountOfSelected === 0;
        }));
    }
    UpdatePartnerTeamDialogComponent.prototype.closeModal = function () {
        this.store.dispatch(new CloseModal());
    };
    UpdatePartnerTeamDialogComponent.prototype.updateUsers = function () {
        var _this = this;
        var selectedUsers = [];
        Object.keys(this.form.controls).forEach(function (key) {
            if (_this.form.get(key).value) {
                selectedUsers.push(key);
            }
        });
        this.store.dispatch(new UpdateOwnerUsers({
            id: this.id,
            users: selectedUsers
        }));
    };
    UpdatePartnerTeamDialogComponent.prototype.onChange = function (isChecked) {
        var _this = this;
        var amountOfSelected = 0;
        Object.keys(this.form.controls).forEach(function (key) {
            if (_this.form.get(key).value) {
                amountOfSelected++;
            }
        });
        this.updateDisabled = amountOfSelected === 0;
    };
    UpdatePartnerTeamDialogComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    return UpdatePartnerTeamDialogComponent;
}());
export { UpdatePartnerTeamDialogComponent };
