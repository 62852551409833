import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { RepeatableItem } from '../../shared/layout/repeatable-form-item/repeatable-item';
import { CapleFormBuilder } from '../../shared/util/caple-form-builder';
import { CapleFormGroup } from '../../shared/util/caple-form';
import { ClosingDocumentCategory } from '../../shared/api/models/closingDocumentCategory';

@Component({
  selector: 'caple-condition-item',
  templateUrl: './condition-item.component.html'
})
export class ConditionItemComponent extends RepeatableItem implements OnInit {

  @Input()
  public item: FormGroup;

  @Input()
  public showRemove = true;

  @Input()
  public index: number;

  @Output()
  public removed: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(MatExpansionPanel, {static: true})
  public expansionPanel: MatExpansionPanel;

  public categories = Object.values(ClosingDocumentCategory);

  public animationsEnabled = true;

  public static produceFormGroup(val?: any): CapleFormGroup {
    val = val || {};
    const capleFormBuilder = new CapleFormBuilder();
    return capleFormBuilder.group({
      'id': [val.id],
      'number': [val.number, Validators.required],
      'title': [val.title, Validators.required],
      'helpText': [val.helpText],
      'category': [val.category, Validators.required],
      'template': capleFormBuilder.group({
        fileId: [val.template ? val.template.fileId : undefined],
        name: [val.template ? val.template.name : undefined]
      }),
      'instructions': capleFormBuilder.group({
        fileId: [val.instructions ? val.instructions.fileId : undefined],
        name: [val.instructions ? val.instructions.name : undefined]
      })
    });
  }

  public produceFormGroup(val?: any): FormGroup {
    return ConditionItemComponent.produceFormGroup(val);
  }

  public ngOnInit(): void {
    this.openOnStart(this.expansionPanel);
  }

  public enableRemove() {
    if (this.showRemove) {
      // When showRemove is true the conditions precedent file is unpublished this means structure changes are allowed.
      return true;
    } else {
      // When a conditions precedent file is published we only allow the removal of conditions that were not saved yet.
      return !this.item.controls['id'].value;
    }
  }

  public close(): void {
    this.animationsEnabled = false;
    this.expansionPanel.close();
    setTimeout(() => this.animationsEnabled = true, 0);
  }
}
