import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { RepeatableItem } from '../../../../../shared/layout/repeatable-form-item/repeatable-item';
import { DateValidators } from '../../../../../shared/util/date-validators';
import { MatExpansionPanel } from '@angular/material/expansion';
import { CapleFormBuilder } from '../../../../../shared/util/caple-form-builder';
import { select, Store } from '@ngrx/store';
import { selectActiveFundingProposalMode } from '../../../../state-management/selector';
import { map } from 'rxjs/operators';
import { FundingProposalMode } from '../../shared/funding-proposal-mode';
var KeyManagementItemComponent = /** @class */ (function (_super) {
    tslib_1.__extends(KeyManagementItemComponent, _super);
    function KeyManagementItemComponent(store) {
        var _this = _super.call(this) || this;
        _this.store = store;
        _this.removed = new EventEmitter();
        _this.modeIsDifference$ = _this.store.pipe(select(selectActiveFundingProposalMode), map(function (mode) { return mode === FundingProposalMode.DIFFERENCE; }));
        return _this;
    }
    KeyManagementItemComponent.produceFormGroup = function (val) {
        val = val || {};
        return new CapleFormBuilder().group({
            'firstName': [val.firstName, Validators.required],
            'lastName': [val.lastName, Validators.required],
            'dateOfBirth': [val.dateOfBirth, [Validators.required, DateValidators.validDate]],
            'role': [val.role, Validators.required],
            'biography': [val.biography, Validators.required]
        });
    };
    KeyManagementItemComponent.prototype.produceFormGroup = function (val) {
        return KeyManagementItemComponent.produceFormGroup(val);
    };
    KeyManagementItemComponent.prototype.ngOnInit = function () {
        this.openOnStart(this.expansionPanel);
    };
    KeyManagementItemComponent.prototype.equalToSnapShot = function (val) {
        return val &&
            this.item.get('firstName').value === val.firstName &&
            this.item.get('lastName').value === val.lastName;
    };
    KeyManagementItemComponent.prototype.open = function () {
        this.expansionPanel.open();
    };
    return KeyManagementItemComponent;
}(RepeatableItem));
export { KeyManagementItemComponent };
