import { FormArray, FormControl, FormGroup } from '@angular/forms';

/**
 * Mark all controls and their child controls as touched. Can be used to validate all forms in the field.
 */
export function markAllFormFieldsAsTouched(form: FormGroup) {
  validateAllFormFields(form);

  function validateAllFormFields(formItem: FormGroup | FormControl) {
    if(formItem instanceof FormGroup) {
      Object.keys(formItem.controls).forEach(field => {
        const control = formItem.get(field);
        control.markAsTouched({onlySelf: true});
        if (control instanceof FormGroup) {
          validateAllFormFields(control);
        } else if (control instanceof FormArray) {
          control.controls.forEach(validateAllFormFields);
        }
      });
    } else {
      formItem.markAsTouched({onlySelf: true});
    }
  }
}
