import { Actions, LoginActions } from './actions';
import { JwtHelper } from '../../shared/authentication/jwt.helper';

export interface State {
  authenticated: boolean;
  encodedAccessToken?: string;
  decodedAccessToken?: object;
}

export const initialState: State = {
  authenticated: false
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case LoginActions.LOGIN_SUCCESS:
    case LoginActions.ACCESS_TOKEN_RENEWED:
      return Object.assign({}, state,
        {
          authenticated: true,
          encodedAccessToken: action.accessToken,
          decodedAccessToken: JwtHelper.decodeToken(action.accessToken)
        });
    case LoginActions.LOGOUT:
      return Object.assign({}, state,
        {
          authenticated: false,
          encodedAccessToken: null,
          decodedAccessToken: null
        });
    default:
      return state;
  }
}
