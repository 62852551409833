import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AbstractApiService} from './abstract-api.service';
import {CreateClient} from '../models/createClient';
import {ReadClient} from '../models/readClient';
import {Id} from '../models/id';
import { PromoteCompany } from "../models/promoteCompany";


export interface PromoteToCompanyPayload {
  companyId: string,
  companyNumber: string,
  role: PromoteCompany.RoleEnum
}

@Injectable()
export class ClientService extends AbstractApiService {
  private readonly baseUrl = '/api/v1/partners/clients/';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getAll(): Observable<ReadClient[]> {
    return this.get<ReadClient[]>(this.baseUrl);
  }

  public getAllForPartner(id: Id): Observable<ReadClient[]> {
    return this.get<ReadClient[]>('/api/v1/companies/' + id.id + '/clients');
  }

  public getByCompanyId(id: Id): Observable<ReadClient[]> {
    return this.get<ReadClient[]>(this.baseUrl + id.id);
  }

  public getUserForClientCompany(id: Id): Observable<ReadClient[]> {
    return this.get<ReadClient[]>(this.baseUrl + id.id + '/users');
  }

  public create(request: {client: CreateClient}): Observable<Id> {
    return this.post<Id>(this.baseUrl, request.client);
  }

  public promoteToCompany(payload: PromoteToCompanyPayload): Observable<Id> {
    return this.post<Id>('/api/v1/companies/' + payload.companyId + '/clients/' + payload.companyNumber + '/promote', {role: payload.role});
  }
}
