import * as Layout from './actions';
import { HideLoadingIndicator, LayoutActions, ShowLoadingIndicator, UpdateProgressIndicator } from './actions';
import { Announcement } from '../../shared/api/models/announcement';
import { typeFor } from '../util/util';
import { SLICE } from './slice';
import { actions, LoadSingleSuccess, UpdateSingleSuccess } from '../util/actions';
import { SLICE as DevOpsSLICE, SLICE as DEVOPSSLICE } from '../../devops/state-management/slice';
import { Changelog } from '../../shared/api/models/changelog';

export interface State {
  loadingIndicatorMessages: Array<string>;
  progressIndicator: ProgressIndicator | null;
  announcement: Announcement | null;
  changelog: Changelog;
}

export const initialState: State = {
  loadingIndicatorMessages: [],
  progressIndicator: null,
  announcement: null,
  changelog: null
};

export function reducer(state = initialState, action: Layout.Actions): State {
  switch (action.type) {
    case LayoutActions.SHOW_LOADING_INDICATOR:
      return Object.assign({}, state, {
        loadingIndicatorMessages: state.loadingIndicatorMessages.concat((action as ShowLoadingIndicator).key)
      });
    case LayoutActions.HIDE_LOADING_INDICATOR:
      const index = state.loadingIndicatorMessages.indexOf((action as HideLoadingIndicator).key);
      const loadingIndicatorMessages = state.loadingIndicatorMessages.slice();
      if (index > -1) {
        loadingIndicatorMessages.splice(index, 1);
      }

      return Object.assign({}, state, {
        loadingIndicatorMessages: loadingIndicatorMessages
      });
    case LayoutActions.PROGRESS_INDICATOR_OPENED:
      return Object.assign({}, state, {
        progressIndicator: {percentage: 0}
      });
    case LayoutActions.UPDATE_PROGRESS_INDICATOR:
      const updateProgressAction = action as UpdateProgressIndicator;
      return Object.assign({}, state, {
        progressIndicator: Object.assign({}, state.progressIndicator, {
          percentage: updateProgressAction.percentage,
          loaded: updateProgressAction.loaded,
          total: updateProgressAction.total
        })
      });
    case LayoutActions.PROGRESS_INDICATOR_CLOSED:
      return Object.assign({}, state, {
        progressIndicator: null
      });
    case typeFor(SLICE.ANNOUNCEMENT, actions.LOAD_SINGLE_SUCCESS):
      return Object.assign({}, state, {announcement: (action as LoadSingleSuccess<Announcement>).payload});
    case typeFor(DEVOPSSLICE.ANNOUNCEMENT, actions.UPDATE_SINGLE_SUCCESS):
      return Object.assign({}, state, {announcement: (action as UpdateSingleSuccess<Announcement>).request});
    case typeFor(DEVOPSSLICE.ANNOUNCEMENT, actions.DELETE_SINGLE_SUCCESS):
      return Object.assign({}, state, {announcement: undefined});
    case typeFor(SLICE.CHANGELOG, actions.LOAD_SINGLE_SUCCESS): {
      return Object.assign({}, state, {
        changelog: (action as LoadSingleSuccess<Changelog>).payload
      });
    }
    case typeFor(SLICE.CHANGELOG, LayoutActions.MARK_CHANGELOG_AS_READ_SUCCESS): {
      return Object.assign({}, state, {
        changelog: Object.assign({}, state.changelog, {unreadReleases: false})
      });
    }
    case typeFor(DevOpsSLICE.CHANGELOG, actions.CREATE_SINGLE_SUCCESS): {
      return Object.assign({}, state, {
        changelog: null
      });
    }
    default:
      return state;
  }
}

export interface ProgressIndicator {
  percentage: number;
  loaded: number;
  total: number;
}
