import { Action } from '@ngrx/store';
import { Params } from '@angular/router';

export enum GenericActions {
  DO_NOTHING = '[GENERIC] Do nothing',
  REDIRECT_TO = '[Redirect] Redirect',
  REDIRECT_TO_WITH_PARAMETERS = '[Redirect] Redirect with parameters',
  RESET_STATE = '[Reset] Reset all state'
}

export class RedirectTo implements Action {
  readonly type = GenericActions.REDIRECT_TO;

  constructor(public url: any[], public relativeToCurrentPath: boolean = false) {
  }
}

export class RedirectToWithParameters implements Action {
  readonly type = GenericActions.REDIRECT_TO_WITH_PARAMETERS;

  constructor(public url: any[], public params: Params, public relativeToCurrentPath: boolean = false) {
  }
}

export class ResetState implements Action {
  readonly type = GenericActions.RESET_STATE;
}

export class DoNothing implements Action {
  readonly type = GenericActions.DO_NOTHING;
}

export type Actions = RedirectTo | RedirectToWithParameters | ResetState | DoNothing;
