import { Component, Inject, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { State } from '../../../state-management/reducer';
import { CloseModal } from '../../../../state-management/layout/actions';
import { LoadSingle } from '../../../../state-management/util/actions';
import { Observable, Subscription } from 'rxjs';
import { ReadCompany } from '../../../../shared/api/models/readCompany';
import { selectUsersForCompany } from '../../../state-management/selector';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SLICE } from '../../../state-management/slice';
import { UpdateReportingSource } from '../../../state-management/actions';
import { ReadUser } from '../../../../shared/api/models/readUser';
import { filter, map } from 'rxjs/operators';
import { Table } from '../../../../state-management/util/adapters';
import { ReadReportingSource } from '../../../../shared/api/models/readReportingSource';
import { ReadCompanyUser} from '../../../../shared/api/models/readCompanyUser';

@Component({
  selector: 'caple-update-reporting-source-dialog',
  templateUrl: './update-reporting-source-dialog.component.html'
})
export class UpdateReportingSourceDialogComponent implements OnDestroy {

  public users$: Observable<ReadUser[]>;
  public form: FormGroup;
  public company: ReadCompany;
  public subscriptions: Subscription = new Subscription();

  private users: ReadCompanyUser[];
  private fileId: string;

  constructor(private store: Store<State>, private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) private data: UpdateReportingSourceDialogData) {
    this.fileId = data.fileId;
    this.company = data.reportingSource.company;
    this.users = data.reportingSource.users;
    this.form = formBuilder.group({});

    this.store.dispatch(new LoadSingle(SLICE.COMPANY_USERS, {id: this.company.id}));
    this.users$ = this.store.pipe(select(selectUsersForCompany(this.company.id)),
      filter(table => !!table),
      map((table: Table<ReadUser>) => {
        return Object.values(table.entities);
      }));

    this.subscriptions.add(
      this.users$.subscribe(readUsers => {
        const filteredUsers = readUsers
          .filter(user => !!this.users.find(reportingSourceUser => reportingSourceUser.userId === user.id))
          .map(user => user.id);

        this.form = formBuilder.group({});
        readUsers.forEach(readUser => {
          const checkUserSelected = filteredUsers.includes(readUser.id);
          this.form.addControl(readUser.id, formBuilder.control(checkUserSelected));
        });
      }));
  }

  public closeModal() {
    this.store.dispatch(new CloseModal());
  }

  public updateReportingSource() {
    const selectedReportingSourceUsers = [];
    Object.keys(this.form.controls).forEach(key => {
      if (this.form.get(key).value) {
        selectedReportingSourceUsers.push(key);
      }
    });

    this.store.dispatch(new UpdateReportingSource({
      id: this.fileId,
      reportingSourceId: this.company.id,
      reportingSourceUsers: selectedReportingSourceUsers
    }));
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

export interface UpdateReportingSourceDialogData {
  fileId: string;
  reportingSource: ReadReportingSource;
}
