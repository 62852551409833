import {Component} from '@angular/core';
import {CreateClient} from '../../../../shared/api/models/createClient';
import {ClientId} from '../../../../shared/api/models/clientId';

@Component({
  selector: 'caple-company-information',
  templateUrl: './company-information.component.html'
})
export class CompanyInformationComponent {
  public formIsValid = false;
  public newClient: CreateClient;
  public clientId: ClientId;
  public forNewClient = true;

  constructor() {
  }

  public canContinue() {
    return (!this.forNewClient && this.clientId) || (this.forNewClient && this.formIsValid);
  }

}
