import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { AbstractApiService } from './abstract-api.service';
var KnowledgeService = /** @class */ (function (_super) {
    tslib_1.__extends(KnowledgeService, _super);
    function KnowledgeService(httpClient) {
        var _this = _super.call(this, httpClient) || this;
        _this.baseUrl = '/api/v1/knowledge-center';
        return _this;
    }
    KnowledgeService.prototype.getAll = function () {
        return this.get(this.baseUrl);
    };
    KnowledgeService.prototype.downloadFile = function (id) {
        return this.getFileWithProgress(this.baseUrl + "/" + id.id + "/download");
    };
    return KnowledgeService;
}(AbstractApiService));
export { KnowledgeService };
