import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { AbstractApiService } from './abstract-api.service';
var KycService = /** @class */ (function (_super) {
    tslib_1.__extends(KycService, _super);
    function KycService(httpClient) {
        var _this = _super.call(this, httpClient) || this;
        _this.baseUrl = '/api/v1/kyc';
        return _this;
    }
    KycService.prototype.getById = function (id) {
        return this.get(this.baseUrl + "/" + id.id);
    };
    KycService.prototype.getStatusById = function (id) {
        return this.get(this.baseUrl + "/" + id.id + "/status");
    };
    KycService.prototype.downloadKYCPack = function (id) {
        return this.getFileWithProgress(this.baseUrl + "/" + id.id + "/zip");
    };
    KycService.prototype.downloadResource = function (id) {
        return this.getFileWithProgress(this.baseUrl + "/" + id.id + "/resources/" + id.resourceId);
    };
    KycService.prototype.uploadFile = function (id, file) {
        var formData = new FormData();
        formData.append('file', file);
        return this.postFile(this.baseUrl + "/" + id.id + "/files", formData);
    };
    KycService.prototype.attachFile = function (payload) {
        return this.post(this.baseUrl + "/" + payload.id + "/" + payload.chapter + "/" + payload.item, payload.fileDetails);
    };
    KycService.prototype.downloadFile = function (payload) {
        return this.getFileWithProgress(this.baseUrl + "/" + payload.id + "/" + payload.chapter + "/" + payload.item + "\n    /" + payload.fileDetails.fileId);
    };
    KycService.prototype.removeFile = function (payload) {
        return this.delete(this.baseUrl + "/" + payload.id + "/" + payload.chapter + "/" + payload.item + "/" + payload.fileId);
    };
    KycService.prototype.assessFile = function (payload) {
        return this.post(this.baseUrl + "/" + payload.id + "/" + payload.chapter + "/" + payload.item + "/" + payload.fileId + "/assessment", {
            decision: payload.decision
        });
    };
    KycService.prototype.assessItem = function (payload) {
        return this.post(this.baseUrl + "/" + payload.id + "/" + payload.chapter + "/" + payload.item + "/assessment", {
            completed: payload.completed
        });
    };
    KycService.prototype.updateBankingInformation = function (payload) {
        return this.post(this.baseUrl + "/" + payload.id + "/banking-information", payload);
    };
    KycService.prototype.completeKYC = function (id) {
        return this.post(this.baseUrl + "/" + id.id + "/complete", null);
    };
    KycService.prototype.reopenKYC = function (id) {
        return this.post(this.baseUrl + "/" + id.id + "/reopen", null);
    };
    KycService.prototype.upgradeKYC = function (id) {
        return this.post(this.baseUrl + "/" + id.id + "/upgrade", null);
    };
    KycService.prototype.getLatestVersion = function (params) {
        return this.get(this.baseUrl + "/" + params.country + "/" + params.product + "/latest");
    };
    return KycService;
}(AbstractApiService));
export { KycService };
