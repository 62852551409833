import * as tslib_1 from "tslib";
import { createFeatureSelector } from '@ngrx/store';
import { createTableAdapter } from '../../state-management/util/adapters';
import { typeFor } from '../../state-management/util/util';
import { SLICE } from './slice';
import { discussionActions } from './actions';
import { ResourceType } from '../../shared/api/services/discussion.service';
/********
 * Adapters
 ********/
export var discussionAdapter = createTableAdapter();
export var initialState = {
    discussions: discussionAdapter.getInitialState()
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case typeFor(SLICE.DISCUSSION, discussionActions.LOAD_SINGLE_SUCCESS): {
            return Object.assign({}, state, {
                discussions: discussionAdapter.addOne(action.payload, state.discussions)
            });
        }
        case typeFor(SLICE.DISCUSSION, discussionActions.MARK_AS_READ_SUCCESS): {
            var result = action;
            var request = result.payload;
            var discussion = state.discussions.entities[request.id];
            var newDiscussion = tslib_1.__assign({}, discussion, { unreadComments: false });
            return Object.assign({}, state, {
                discussions: discussionAdapter.updateOne(newDiscussion, state.discussions)
            });
        }
        case typeFor(SLICE.DISCUSSION, discussionActions.CHANGE_DISCUSSION_LOCK): {
            var changeLockAction = action;
            var discussion = state.discussions.entities[changeLockAction.payload.id];
            return Object.assign({}, state, {
                discussions: discussionAdapter.updateOne(tslib_1.__assign({}, discussion, { locked: changeLockAction.payload.locked }), state.discussions)
            });
        }
        case typeFor(SLICE.COMMENT, discussionActions.LOAD_SINGLE_SUCCESS): {
            var request = action.request;
            // If discussion exists
            var discussion = state.discussions.entities[request.id];
            if (discussion) {
                var newDiscussion = tslib_1.__assign({}, discussion, { unreadComments: true, comments: discussion.comments.concat([
                        action.payload
                    ]) });
                return Object.assign({}, state, {
                    discussions: discussionAdapter.updateOne(newDiscussion, state.discussions)
                });
            }
            else {
                return state;
            }
        }
        case typeFor(SLICE.DISCUSSION, discussionActions.POST_COMMENT_SUCCESS): {
            var result = action;
            // For KYC and Funding proposals we use websockets to update so no need to do it via this reducer
            // @ts-ignore
            if (result.payload.resourceType === ResourceType.KYC || result.payload.resourceType === ResourceType.FUNDING_PROPOSAL) {
                return state;
            }
            else {
                var request = result.payload;
                var discussion = state.discussions.entities[request.id];
                var newDiscussion = tslib_1.__assign({}, discussion, { comments: discussion.comments.concat([{
                            message: request.comment.message,
                            postedAt: result.postedAt,
                            user: result.user
                        }]) });
                return Object.assign({}, state, {
                    discussions: discussionAdapter.updateOne(newDiscussion, state.discussions)
                });
            }
        }
    }
    return state;
}
export var selectFeature = createFeatureSelector('discussion');
