import { actions, EntityAction } from '../../state-management/util/actions';
import {
  AddReportingSourcePayload,
  CertificateUploadPayload,
  CompleteReportingPeriodPayload,
  DownloadReportingDocumentPayload,
  FinancialsUploadPayload,
  RemoveReportingSourcePayload,
  RequestResubmissionPayload,
  UpdateCovenantPayload,
  UpdateGeneralInformationPayload,
  UpdateReportingSourcePayload
} from '../../shared/api/services/monitoring-files.service';
import { SLICE } from './slice';

export const monitoringActions = {
  ...actions,
  DOWNLOAD_FINANCIALS: 'DOWNLOAD_FINANCIALS',
  DOWNLOAD_CERTIFICATE: 'DOWNLOAD_CERTIFICATE',
  DOWNLOAD_FINANCIALS_TEMPLATE: 'DOWNLOAD_FINANCIALS_TEMPLATE',
  SUBMIT_FINANCIALS: 'SUBMIT_FINANCIALS',
  SUBMIT_FINANCIALS_SUCCESS: 'SUBMIT_FINANCIALS_SUCCESS',
  SUBMIT_CERTIFICATE: 'SUBMIT_CERTIFICATE',
  SUBMIT_CERTIFICATE_SUCCESS: 'SUBMIT_CERTIFICATE_SUCCESS',
  RESUBMIT_FINANCIALS: 'RESUBMIT_FINANCIALS',
  RESUBMIT_FINANCIALS_SUCCESS: 'RESUBMIT_FINANCIALS_SUCCESS',
  RESUBMIT_CERTIFICATE: 'RESUBMIT_CERTIFICATE',
  RESUBMIT_CERTIFICATE_SUCCESS: 'RESUBMIT_CERTIFICATE_SUCCESS',
  UPDATE_COVENANTS: 'UPDATE_COVENANTS',
  UPDATE_COVENANTS_SUCCESS: 'UPDATE_COVENANTS_SUCCESS',
  UPDATE_GENERAL_INFORMATION: 'UPDATE_GENERAL_INFORMATION',
  UPDATE_GENERAL_INFORMATION_SUCCESS: 'UPDATE_GENERAL_INFORMATION_SUCCESS',
  ADD_REPORTING_SOURCE: 'ADD_REPORTING_SOURCE',
  UPDATE_REPORTING_SOURCE: 'UPDATE_REPORTING_SOURCE',
  REMOVE_REPORTING_SOURCE: 'REMOVE_REPORTING_SOURCE',
  REMOVE_REPORTING_SOURCE_SUCCESS: 'REMOVE_REPORTING_SOURCE_SUCCESS',
  REQUEST_RESUBMISSION: 'REQUEST_RESUBMISSION',
  REQUEST_RESUBMISSION_SUCCESS: 'REQUEST_RESUBMISSION_SUCCESS',
  COMPLETE_REPORTING_DOCUMENTS: 'COMPLETE_REPORTING_DOCUMENTS',
  COMPLETE_REPORTING_DOCUMENTS_SUCCESS: 'COMPLETE_REPORTING_DOCUMENTS_SUCCESS'
};

export class DownloadReportingDocumentsFinancials extends EntityAction {
  protected actionName: string = monitoringActions.DOWNLOAD_FINANCIALS;

  constructor(public slice: string, payload: DownloadReportingDocumentPayload) {
    super(slice, payload);
  }
}

export class DownloadReportingDocumentsCertificate extends EntityAction {
  protected actionName: string = monitoringActions.DOWNLOAD_CERTIFICATE;

  constructor(public slice: string, payload: DownloadReportingDocumentPayload) {
    super(slice, payload);
  }
}

export class DownloadReportingDocumentsFinacialsTemplate extends EntityAction {
  protected actionName: string = monitoringActions.DOWNLOAD_FINANCIALS_TEMPLATE;

  constructor(public slice: string, payload: DownloadReportingDocumentPayload) {
    super(slice, payload);
  }
}

export class SubmitFinancials extends EntityAction {
  protected actionName: string = monitoringActions.SUBMIT_FINANCIALS;

  constructor(public slice: string, payload: FinancialsUploadPayload) {
    super(slice, payload);
  }
}

export class SubmitFinancialsSuccess<T extends any> extends EntityAction {
  protected actionName: string = monitoringActions.SUBMIT_FINANCIALS_SUCCESS;

  constructor(public slice: string, public request: T) {
    super(slice);
  }
}

export class SubmitCertificate extends EntityAction {
  protected actionName: string = monitoringActions.SUBMIT_CERTIFICATE;

  constructor(public slice: string, payload: CertificateUploadPayload) {
    super(slice, payload);
  }
}

export class SubmitCertificateSuccess<T extends any> extends EntityAction {
  protected actionName: string = monitoringActions.SUBMIT_CERTIFICATE_SUCCESS;

  constructor(public slice: string, public request: T) {
    super(slice);
  }
}

export class ResubmitFinancials extends EntityAction {
  protected actionName: string = monitoringActions.RESUBMIT_FINANCIALS;

  constructor(public slice: string, payload: FinancialsUploadPayload) {
    super(slice, payload);
  }
}

export class ResubmitFinancialsSuccess<T extends any> extends EntityAction {
  protected actionName: string = monitoringActions.RESUBMIT_FINANCIALS_SUCCESS;

  constructor(public slice: string, public request: T) {
    super(slice);
  }
}

export class ResubmitCertificate extends EntityAction {
  protected actionName: string = monitoringActions.RESUBMIT_CERTIFICATE;

  constructor(public slice: string, payload: CertificateUploadPayload) {
    super(slice, payload);
  }
}

export class ResubmitCertificateSuccess<T extends any> extends EntityAction {
  protected actionName: string = monitoringActions.RESUBMIT_CERTIFICATE_SUCCESS;

  constructor(public slice: string, public request: T) {
    super(slice);
  }
}

export class AddReportingSource extends EntityAction {
  protected actionName: string = monitoringActions.ADD_REPORTING_SOURCE;

  constructor(public payload: AddReportingSourcePayload) {
    super(SLICE.MONITORING_FILE);
  }
}

export class UpdateReportingSource extends EntityAction {
  protected actionName: string = monitoringActions.UPDATE_REPORTING_SOURCE;

  constructor(public payload: UpdateReportingSourcePayload) {
    super(SLICE.MONITORING_FILE);
  }
}

export class RemoveReportingSource extends EntityAction {
  protected actionName: string = monitoringActions.REMOVE_REPORTING_SOURCE;

  constructor(public payload: RemoveReportingSourcePayload) {
    super(SLICE.MONITORING_FILE);
  }
}

export class RemoveReportingSourceSuccess extends EntityAction {
  protected actionName: string = monitoringActions.REMOVE_REPORTING_SOURCE_SUCCESS;

  constructor(public payload: RemoveReportingSourcePayload) {
    super(SLICE.MONITORING_FILE);
  }
}

export class UpdateCovenants extends EntityAction {
  protected actionName: string = monitoringActions.UPDATE_COVENANTS;

  constructor(payload: UpdateCovenantPayload) {
    super(SLICE.MONITORING_FILE, payload);
  }
}

export class UpdateCovenantsSuccess extends EntityAction {
  protected actionName: string = monitoringActions.UPDATE_COVENANTS_SUCCESS;

  constructor(public request: UpdateCovenants) {
    super(SLICE.MONITORING_FILE);
  }
}

export class UpdateGeneralInformation extends EntityAction {
  protected actionName: string = monitoringActions.UPDATE_GENERAL_INFORMATION;

  constructor(payload: UpdateGeneralInformationPayload) {
    super(SLICE.MONITORING_FILE, payload);
  }
}

export class UpdateGeneralInformationSuccess extends EntityAction {
  protected actionName: string = monitoringActions.UPDATE_GENERAL_INFORMATION_SUCCESS;

  constructor(public request: UpdateGeneralInformation) {
    super(SLICE.MONITORING_FILE);
  }
}

export class RequestResubmission extends EntityAction {
  protected actionName: string = monitoringActions.REQUEST_RESUBMISSION;

  constructor(public slice: string, payload: RequestResubmissionPayload) {
    super(slice, payload);
  }
}

export class RequestResubmissionSuccess<T extends any> extends EntityAction {
  protected actionName: string = monitoringActions.REQUEST_RESUBMISSION_SUCCESS;

  constructor(public slice: string, public request: T) {
    super(slice);
  }
}

export class CompleteReportingDocuments extends EntityAction {
  protected actionName: string = monitoringActions.COMPLETE_REPORTING_DOCUMENTS;

  constructor(public slice: string, payload: CompleteReportingPeriodPayload) {
    super(slice, payload);
  }
}

export class CompleteReportingDocumentsSuccess<T extends any> extends EntityAction {
  protected actionName: string = monitoringActions.COMPLETE_REPORTING_DOCUMENTS_SUCCESS;

  constructor(public slice: string, public request: T) {
    super(slice);
  }
}
