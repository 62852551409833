import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {ProductType} from '../../../../shared/api/models/productType';
import {MatRadioChange} from '@angular/material/radio';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {selectCountry} from '../../../../state-management/preferences/selector';
import {State} from '../../../../state-management/reducers';
import {Product} from '../../../../shared/api/models/product';
import {ProductSubType} from '../../../../shared/api/models/productSubType';

@Component({
  selector: 'caple-product-type-selector',
  templateUrl: './product-type-selector.component.html',
  styleUrls: ['product-type-selector.component.scss']
})
export class ProductTypeSelectorComponent implements OnDestroy {

  @Output()
  public onTypeChanged = new EventEmitter<Product>();

  @Output()
  public onValidationChanged = new EventEmitter<Boolean>();

  public form: FormGroup;
  public country$: Observable<String>;
  public activeType: string;
  public activeSubType: string;

  private subscription = new Subscription();
  private startLoanSize: number;

  countryString;

  constructor(private store: Store<State>) {
    this.country$ = store.pipe(select(selectCountry));
    this.form = new FormBuilder().group({});

    this.subscription.add(this.form.statusChanges.subscribe(status => {
      this.onValidationChanged.emit(status === 'VALID');
    }));

    this.subscription.add(this.country$.subscribe(value => {
      this.countryString = value;
    }));

    this.subscription.add(this.form.valueChanges.pipe(
      map(form => form.loanSize),
      filter(value => !!value)
    ).subscribe(loanSize => {
      this.onTypeChanged.emit({
        productType: ProductType.GROWTH,
        productSubType: loanSize > 1500000 ? ProductSubType.GT15M : ProductSubType.LTE15M,
        loanSize
      });
    }));
  }

  @Input()
  public set data(value: Product) {
    if (value) {
      if (value.productType) {
        switch (value.productType) {
          case ProductType.TRANSACTION:
            this.activeSubType = value.productSubType;
            this.setActive('transaction');
            break;
          case ProductType.GROWTH:
            this.setActive('growth');
            break;
          case ProductType.ACQUISITION :
            this.setActive('acquisition');
            break;
          case ProductType.MBI :
            this.setActive('mbi');
            break;
          default:
            console.error('Unknown product type: ' + value.productType);
            break;
        }
      }

      this.startLoanSize = value.loanSize;
      this.form.patchValue({'loanSize': value.loanSize});
    }
  }

  public changed($event: MatRadioChange) {
    if ($event.value === 'MBO' || $event.value === 'OTHER') {
      this.setActive('transaction');
      this.transactionTypeChanged($event);
    } else {
      this.setActive($event.value);
    }
  }

  public transactionTypeChanged($event: MatRadioChange) {
    this.activeSubType = $event.value;
    this.onTypeChanged.emit({productType: ProductType.TRANSACTION, productSubType: $event.value});
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  private setActive(type: string) {
    this.activeType = type;

    this.form.removeControl('loanSize');
    this.form.removeControl('productSubType');

    switch (type) {
      case 'growth':
        this.form.addControl('loanSize', new FormControl(this.startLoanSize, [Validators.required, Validators.min(500000), Validators.max(5000000)]));
        break;
      case 'transaction':
        if (this.countryString === 'NL') {
          this.form.addControl('productSubType', new FormControl(this.activeSubType, [Validators.required]));
        }
        break;
      case 'acquisition':
        this.onTypeChanged.emit({productType: ProductType.ACQUISITION});
        break;
      case 'mbi':
        // Note: need to emit a subtype because the backend needs a subtype to generate a financial rating.
        this.onTypeChanged.emit({productType: ProductType.MBI, productSubType: ProductSubType.MBI});
        break;
      default:
        console.error('Unknown product type: ' + type);
        break;
    }

    this.onValidationChanged.emit(this.form.valid);
  }
}
