import { SupportedCountry } from '../../api/models/supportedCountry';
import { getCurrencySymbol } from '@angular/common';
import { Locale } from '../../api/models/locale';

export function getSymbol(country): string {
  switch (country) {
    case SupportedCountry.GB:
      return getCurrencySymbol('GBP', 'narrow');
    default:
      return getCurrencySymbol('EUR', 'narrow');
  }

}

export function getCurrencyLocale(country): string {
  switch (country) {
    case SupportedCountry.NL:
      return Locale.NlNL;
    default:
      return Locale.EnGB;
  }

}
