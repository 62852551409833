import { createFeatureSelector } from '@ngrx/store';
import { createTableAdapter, Table } from '../../state-management/util/adapters';
import { EntityAction } from '../../state-management/util/actions';
import { typeFor } from '../../state-management/util/util';
import { SLICE } from './slice';
import { Discussion } from '../../shared/api/models/discussion';
import { ChangeDiscussionLock, discussionActions, MarkAsReadSuccess, PostCommentSuccess, StartDiscussionSuccess } from './actions';
import { DiscussionIdPayload, ResourceType } from '../../shared/api/services/discussion.service';

/********
 * Adapters
 ********/
export const discussionAdapter = createTableAdapter<Discussion>();

/********
 * State
 ********/
export interface State {
  discussions: Table<Discussion>;
}

export const initialState: State = {
  discussions: discussionAdapter.getInitialState()
};

export function reducer(state = initialState, action: EntityAction): State {
  switch (action.type) {
    case typeFor(SLICE.DISCUSSION, discussionActions.LOAD_SINGLE_SUCCESS): {
      return Object.assign({}, state, {
        discussions: discussionAdapter.addOne(action.payload, state.discussions)
      });
    }
    case typeFor(SLICE.DISCUSSION, discussionActions.MARK_AS_READ_SUCCESS): {
      const result = action as MarkAsReadSuccess;
      const request = result.payload;
      const discussion = state.discussions.entities[request.id];
      const newDiscussion = {
        ...discussion,
        unreadComments: false
      };
      return Object.assign({}, state, {
        discussions: discussionAdapter.updateOne(newDiscussion, state.discussions)
      });
    }
    case typeFor(SLICE.DISCUSSION, discussionActions.CHANGE_DISCUSSION_LOCK): {
      const changeLockAction = (action as ChangeDiscussionLock);
      const discussion = state.discussions.entities[changeLockAction.payload.id];
      return Object.assign({}, state, {
        discussions: discussionAdapter.updateOne({...discussion, locked: changeLockAction.payload.locked}, state.discussions)
      });
    }
    case typeFor(SLICE.COMMENT, discussionActions.LOAD_SINGLE_SUCCESS): {
      const request: DiscussionIdPayload = (action as any).request;

      // If discussion exists
      const discussion = state.discussions.entities[request.id];

      if (discussion) {
        const newDiscussion: Discussion = {
          ...discussion,
          unreadComments: true,
          comments: [
            ...discussion.comments,
            action.payload
          ]
        };
        return Object.assign({}, state, {
          discussions: discussionAdapter.updateOne(newDiscussion, state.discussions)
        });
      } else {
        return state;
      }
    }
    case typeFor(SLICE.DISCUSSION, discussionActions.POST_COMMENT_SUCCESS): {
      const result = action as PostCommentSuccess;
      // For KYC and Funding proposals we use websockets to update so no need to do it via this reducer
      // @ts-ignore
      if (result.payload.resourceType === ResourceType.KYC || result.payload.resourceType === ResourceType.FUNDING_PROPOSAL) {
        return state;
      } else {
        const request = result.payload;
        const discussion = state.discussions.entities[request.id];
        const newDiscussion = {
          ...discussion,
          comments: [...discussion.comments, {
            message: request.comment.message,
            postedAt: result.postedAt,
            user: result.user
          }]
        };
        return Object.assign({}, state, {
          discussions: discussionAdapter.updateOne(newDiscussion, state.discussions)
        });
      }
    }
  }

  return state;
}

export const selectFeature = createFeatureSelector<State>('discussion');
