import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Auth0Factory } from './auth0-factory.service';
import { State } from '../../state-management/reducers';
import { Store } from '@ngrx/store';
import { RedirectToWithParameters } from '../../state-management/generic/actions';
import { LoginError } from '../failure/login-error';
import { Router } from '@angular/router';
import { config } from '../../../environments/config';
import { selectAccessToken, selectIsAuthenticated } from '../../state-management/login/selector';

@Component({
  selector: 'caple-login-form',
  template: '',
  encapsulation: ViewEncapsulation.None
})
export class LoginFormComponent implements OnInit {

  @Output()
  public success = new EventEmitter<string>();

  private webAuth;

  constructor(private auth0LockFactory: Auth0Factory, private store: Store<State>, private router: Router) {

  }

  public ngOnInit(): void {
    this.webAuth = this.auth0LockFactory.createInstance();

    this.webAuth.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken) {
        this.tokenReceived(authResult);
      } else if (err && err.error === 'unauthorized') {
        // In Auth0 'unauthorized' means blocked: https://auth0.com/docs/libraries/error-messages
        // Decided to change the name in our system to 'blocked' to make it more clear
        this.store.dispatch(new RedirectToWithParameters(['failure'], {reason: LoginError.BLOCKED}, true));
      } else {
        // We have 2 possible routes to take now. 1) we are logged in and can just emit the existing token or 2) we aren't logged in and should log in
        if (this.store.getSync(selectIsAuthenticated)) {
          this.tokenReceived({accessToken: this.store.getSync(selectAccessToken).getToken()});
        } else {
          const redirectUri = config.baseUrl.substr(0, config.baseUrl.length - 1) + this.router.routerState.snapshot.url;
          this.webAuth.authorize({redirectUri: redirectUri});
        }
      }
    });
  }

  private tokenReceived(authResult) {
    this.success.emit(authResult.accessToken);
  }
}
