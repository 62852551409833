import { createSelector } from '@ngrx/store';
import { discussionAdapter, selectFeature, State } from './reducer';
import { WithId } from '../../state-management/util/actions';

const selectDiscussionSlice = createSelector(selectFeature, (state: State) => state.discussions);

/////////////
//// Selectors
/////////////


/** KYC **/
export const {
  selectSingleIsLoaded: selectDiscussionIsLoaded,
  selectSingle: selectDiscussion
} = discussionAdapter.getSelectors(selectDiscussionSlice);

export const selectDiscussionIsUnread = (id: WithId) => createSelector(selectDiscussion(id), discussion => {
  return discussion && discussion.unreadComments;
});
