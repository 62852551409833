<h2 mat-dialog-title>{{ title | translate}}</h2>
<mat-dialog-content>
  <div class="notice warning" *ngIf="warning">
    <i class="material-icons">warning</i>
    <p>{{warning | translate}}</p>
  </div>

  <p [innerHTML]="message | translate"></p>

  <mat-dialog-actions align="end">
    <button mat-button (click)="closeModal()">{{ cancelLabel | translate }}</button>

    <caple-loading-wrapper [key]="confirmLoadingKey" [diameter]="36">
      <button mat-button (click)="confirm()">{{ confirmLabel | translate }}</button>
    </caple-loading-wrapper>
  </mat-dialog-actions>
</mat-dialog-content>
