import { FormGroupContainer } from '../../../../shared/layout/form-group-container';
import { CapleFormBuilder } from '../../../../shared/util/caple-form-builder';
import { Validators } from '@angular/forms';
import { ArrayValidators } from '../../../../shared/util/array-validators';
import { SelectCovenantsComponent } from '../../create-monitoring-file-dialog/covenants.component';

export class CovenantsForm extends FormGroupContainer {
  constructor(private formBuilder: CapleFormBuilder) {
    super(formBuilder.group({
      'covenants': formBuilder.array([], Validators.compose([ArrayValidators.minLength(1), ArrayValidators.maxLength(256)]), null)
    }));
  }
}
