import { Action, ActionReducer, ActionReducerMap, createSelector, MetaReducer } from '@ngrx/store';
import * as Login from './login/reducer';
import * as Layout from './layout/reducer';
import * as Preferences from './preferences/reducer';
import { localStorageSync } from 'ngrx-store-localstorage';
import { metareducers } from '../../environments/config';
import { GenericActions } from './generic/actions';
import { analyticsReducer } from './analytics/AnalyticsReducer';

export interface State {
  login: Login.State;
  layout: Layout.State;
  preferences: Preferences.State;
}

export const reducers: ActionReducerMap<State> = {
  login: Login.reducer,
  layout: Layout.reducer,
  preferences: Preferences.reducer
};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: ['login'],
    rehydrate: true,
    storage: localStorage
  })(reducer);
}

/**
 * This metareducer rewrites the current state to undefined in order to force all sub reducers to reset to their initial state.
 */
export function resetStateReducer(reducer: ActionReducer<State, Action>): ActionReducer<any> {
  return function (state = undefined, action: any) {
    if (action.type === GenericActions.RESET_STATE) {
      state = undefined;
    }
    return reducer(state, action);
  };
}


export const metaReducers: MetaReducer<any>[] = [
  localStorageSyncReducer,
  resetStateReducer,
  analyticsReducer,
  ...metareducers
];

export const selectState = (state: State) => state;

/** Login **/
export const selectLogin = createSelector(selectState, (state: State) => state.login);

/** Preferences **/
export const selectPreferences = createSelector(selectState, (state: State) => state.preferences);
