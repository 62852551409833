/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./file-upload-button-component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "@angular/common";
import * as i7 from "@angular/material/tooltip";
import * as i8 from "@angular/cdk/overlay";
import * as i9 from "@angular/cdk/scrolling";
import * as i10 from "@angular/cdk/platform";
import * as i11 from "@angular/cdk/bidi";
import * as i12 from "@angular/platform-browser";
import * as i13 from "./file-upload-button.component";
import * as i14 from "@ngrx/store";
var styles_FileUploadButtonComponent = [i0.styles];
var RenderType_FileUploadButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FileUploadButtonComponent, data: {} });
export { RenderType_FileUploadButtonComponent as RenderType_FileUploadButtonComponent };
function View_FileUploadButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "i", [["class", "material-icons rotated"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.matIcon; _ck(_v, 1, 0, currVal_0); }); }
function View_FileUploadButtonComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "i", [["class", "material-icons rotated"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.matIcon; _ck(_v, 1, 0, currVal_0); }); }
function View_FileUploadButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "button", [["class", "mat-primary-button"], ["mat-button", ""], ["type", "button"]], [[2, "mat-action-button", null], [2, "mat-secondary-button", null], [1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openFileInput() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_FileUploadButtonComponent_2)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_FileUploadButtonComponent_3)), i1.ɵdid(7, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.disabled; _ck(_v, 1, 0, currVal_4); var currVal_5 = (_co.isIconButton() && _co.iconBeforeText); _ck(_v, 3, 0, currVal_5); var currVal_7 = (_co.isIconButton() && !_co.iconBeforeText); _ck(_v, 7, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isActionButton; var currVal_1 = _co.isSecondaryButton; var currVal_2 = (i1.ɵnov(_v, 1).disabled || null); var currVal_3 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_6 = _co.label; _ck(_v, 5, 0, currVal_6); }); }
function View_FileUploadButtonComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "action"], ["mat-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openFileInput() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["attach_file"]))], null, null); }
export function View_FileUploadButtonComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { fileInput: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["fileInput", 1]], null, 0, "input", [["name", "file"], ["style", "display: none;"], ["type", "file"]], [[8, "accept", 0], [8, "multiple", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.fileChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 16777216, null, null, 5, "span", [], [[2, "caple-upload-button", null], [2, "caple-upload-button-textarea", null]], [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 212992, null, 0, i7.MatTooltip, [i8.Overlay, i1.ElementRef, i9.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i10.Platform, i4.AriaDescriber, i4.FocusMonitor, i7.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i11.Directionality], [2, i7.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i12.HAMMER_LOADER]], { position: [0, "position"], message: [1, "message"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadButtonComponent_1)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadButtonComponent_4)), i1.ɵdid(7, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = "below"; var currVal_5 = _co.getTooltipText(); _ck(_v, 3, 0, currVal_4, currVal_5); var currVal_6 = !_co.isIconOnlyButton; _ck(_v, 5, 0, currVal_6); var currVal_7 = (_co.isIconOnlyButton && !_co.disabled); _ck(_v, 7, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.getAcceptedFileExtensionsString(), ""); var currVal_1 = _co.multiple; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.isPrimaryButton; var currVal_3 = _co.isTextareaButton; _ck(_v, 2, 0, currVal_2, currVal_3); }); }
export function View_FileUploadButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "caple-file-upload-button", [], null, null, null, View_FileUploadButtonComponent_0, RenderType_FileUploadButtonComponent)), i1.ɵdid(1, 49152, null, 0, i13.FileUploadButtonComponent, [i14.Store], null, null)], null, null); }
var FileUploadButtonComponentNgFactory = i1.ɵccf("caple-file-upload-button", i13.FileUploadButtonComponent, View_FileUploadButtonComponent_Host_0, { label: "label", disabled: "disabled", disabledTooltip: "disabledTooltip", accept: "accept", isActionButton: "isActionButton", isSecondaryButton: "isSecondaryButton", isTextareaButton: "isTextareaButton", isPrimaryButton: "isPrimaryButton", iconBeforeText: "iconBeforeText", isIconOnlyButton: "isIconOnlyButton", multiple: "multiple", matIcon: "matIcon" }, { fileSelected: "fileSelected" }, []);
export { FileUploadButtonComponentNgFactory as FileUploadButtonComponentNgFactory };
