import { NoPreloading, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found.component';
import { NavigationComponent } from './navigation/navigation.component';
import { AuthenticationGuard } from './shared/authentication/authentication.guard';
import { Role } from './shared/api/models/role';
import { CaplePreloadStrategy } from './preloading-strategy';
import { environment } from '../environments/environment';
var ɵ0 = function () { return import("./origination/origination.module.ngfactory").then(function (m) { return m.OriginationModuleNgFactory; }); }, ɵ1 = {
    onlyPreloadFor: [Role.PARTNER, Role.CREDITOFFICER, Role.DIRECTCLIENT]
}, ɵ2 = function () { return import("./monitoring/monitoring.module.ngfactory").then(function (m) { return m.MonitoringModuleNgFactory; }); }, ɵ3 = {
    onlyPreloadFor: [Role.MONITORINGOFFICER, Role.REPORTINGSOURCE, Role.BORROWER, Role.LOANSERVICER]
}, ɵ4 = function () { return import("./kyc/kyc.module.ngfactory").then(function (m) { return m.KycModuleNgFactory; }); }, ɵ5 = {
    onlyPreloadFor: [Role.CLIENT]
}, ɵ6 = function () { return import("./conditions-precedent/conditions-precedent.module.ngfactory").then(function (m) { return m.ConditionsPrecedentModuleNgFactory; }); }, ɵ7 = {
    onlyPreloadFor: [Role.CLIENT]
}, ɵ8 = function () { return import("./administration/administration.module.ngfactory").then(function (m) { return m.AdministrationModuleNgFactory; }); }, ɵ9 = {
    onlyPreloadFor: [Role.ADMINISTRATOR]
}, ɵ10 = function () { return import("./devops/devops.module.ngfactory").then(function (m) { return m.DevopsModuleNgFactory; }); }, ɵ11 = {
    onlyPreloadFor: [Role.DEVOPS]
}, ɵ12 = function () { return import("./preferences/preferences.module.ngfactory").then(function (m) { return m.PreferencesModuleNgFactory; }); }, ɵ13 = {
    preloadDelay: true
}, ɵ14 = function () { return import("./knowledge-center/knowledge-center.module.ngfactory").then(function (m) { return m.KnowledgeCenterModuleNgFactory; }); }, ɵ15 = {
    preloadDelay: true
}, ɵ16 = function () { return import("./changelog/changelog-overview.module.ngfactory").then(function (m) { return m.ChangelogOverviewModuleNgFactory; }); }, ɵ17 = {
    preloadDelay: true
}, ɵ18 = function () { return import("./feedback/feedback.module.ngfactory").then(function (m) { return m.FeedbackModuleNgFactory; }); }, ɵ19 = {
    preloadDelay: true
};
var appRoutes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'dashboard',
        component: NavigationComponent,
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'origination',
        loadChildren: ɵ0,
        data: ɵ1
    },
    {
        path: 'monitoring',
        loadChildren: ɵ2,
        data: ɵ3
    },
    {
        path: 'kyc',
        loadChildren: ɵ4,
        data: ɵ5
    },
    {
        path: 'conditions-precedent',
        loadChildren: ɵ6,
        data: ɵ7
    },
    {
        path: 'administration',
        loadChildren: ɵ8,
        data: ɵ9
    },
    {
        path: 'devops',
        loadChildren: ɵ10,
        data: ɵ11
    },
    {
        path: 'preferences',
        loadChildren: ɵ12,
        data: ɵ13
    },
    {
        path: 'knowledge-center',
        loadChildren: ɵ14,
        outlet: 'overlay',
        data: ɵ15
    },
    {
        path: 'changelog',
        loadChildren: ɵ16,
        outlet: 'overlay',
        data: ɵ17
    },
    {
        path: 'feedback',
        loadChildren: ɵ18,
        outlet: 'feedback',
        data: ɵ19
    },
    {
        path: '**',
        component: PageNotFoundComponent,
        canActivate: [AuthenticationGuard]
    }
];
export var AppRouting = RouterModule.forRoot(appRoutes, {
    useHash: false,
    paramsInheritanceStrategy: 'always',
    scrollPositionRestoration: 'top',
    anchorScrolling: 'disabled',
    // Required to fix navigation in lazy loaded modules -> https://github.com/angular/angular/issues/13011.
    // Can be removed in Angular 7 as this will become the default
    relativeLinkResolution: 'corrected',
    preloadingStrategy: environment.preload ? CaplePreloadStrategy : NoPreloading
});
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19 };
