import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AbstractApiService } from './abstract-api.service';
import { WithId, WithIdVersionAndProduct } from '../../../state-management/util/actions';
import { Discussion } from '../models/discussion';
import { Comment } from '../models/comment';
import { ChapterType } from '../models/chapterType';
import { ChapterItemType } from '../models/chapterItemType';
import { Id } from '../models/id';
import { RxStompService } from '@stomp/ng2-stompjs';
import { map } from 'rxjs/operators';
import { ReadComment } from '../models/readComment';

export type ResourceType = 'KYC' | 'CONDITIONS_PRECEDENT' | 'FUNDING_PROPOSAL';
export const ResourceType = {
  KYC: 'KYC' as ResourceType,
  FUNDING_PROPOSAL: 'FUNDING_PROPOSAL' as ResourceType,
  CONDITIONS_PRECEDENT: 'CONDITIONS_PRECEDENT' as ResourceType
};

export interface DiscussionIdPayload extends WithId {
  version?: number;
  product?: string;
  resourceId: string;
  resourceType: ResourceType;
}

export interface ChangeLockPayload extends WithId {
  locked: boolean
}

export interface StartDiscussionPayload {
  resourceId: string;
  resourceType: ResourceType;
  title: string;
  comment: Comment;
}

export interface  StartFundingProcessDiscussionPayload extends StartDiscussionPayload {
  fileId: string;
}

export interface StartKYCDiscussionPayload extends StartDiscussionPayload {
  chapter: ChapterType;
  item: ChapterItemType;
  fileId?: string;
}

export interface PostCommentPayload extends WithId {
  resourceId: string;
  resourceType: ResourceType;
  comment: Comment;
}

@Injectable()
export class DiscussionService extends AbstractApiService {

  constructor(httpClient: HttpClient, private rxStompService: RxStompService) {
    super(httpClient);
  }

  public getById(payload: DiscussionIdPayload): Observable<Discussion> {
    return this.get<Discussion>(`${this.getDiscussionBaseUrl(payload)}/${payload.id}`);
  }

  public startDiscussion(payload: StartDiscussionPayload): Observable<Id> {
    return this.post<Id>(`${this.getDiscussionBaseUrl(payload)}`, payload);
  }

  public postComment(payload: PostCommentPayload): Observable<void> {
    return this.post<void>(`${this.getDiscussionBaseUrl(payload)}/${payload.id}/comment`, payload.comment);
  }

  public markAsRead(payload: DiscussionIdPayload): Observable<void> {
    return this.post<void>(`${this.getDiscussionBaseUrl(payload)}/${payload.id}/mark-as-read`, null);
  }

  public getCommentsStream(payload: DiscussionIdPayload): Observable<ReadComment> {
    return this.rxStompService.watch(this.getCommentStreamUrl(payload)).pipe(
      map((message) => JSON.parse(message.body))
    );
  }

  private getDiscussionBaseUrl(payload: { resourceId: string, resourceType: ResourceType }): string {
    switch (payload.resourceType) {
      case ResourceType.KYC:
        return `/api/v1/kyc/${payload.resourceId}/discussions`;
      case ResourceType.CONDITIONS_PRECEDENT:
        return `/api/v1/conditions-precedent/${payload.resourceId}/discussions`;
      case ResourceType.FUNDING_PROPOSAL:
        return `/api/v1/funding-processes/${payload.resourceId}/discussions`;
      default:
        throw new Error(`ResourceType [${payload.resourceType}] not supported for discussion!`);
    }
  }

  private getCommentStreamUrl(payload: DiscussionIdPayload): string {
    switch (payload.resourceType) {
      case ResourceType.KYC:
        return `/user/queue/kyc/${payload.resourceId}/discussions/${payload.id}`;
      case ResourceType.FUNDING_PROPOSAL:
        return `/user/queue/funding-processes/${payload.resourceId}/discussions/${payload.id}`;
      default:
        throw new Error(`ResourceType [${payload.resourceType}] not supported for comment stream!`);
    }
  }
}
