<h2 mat-dialog-title>{{ 'funding-process.start.title' | translate}} - {{getSecondaryTitle() | translate}}</h2>
<mat-dialog-content>
  <ng-template #closeButton>
    <button (click)="closeModal()" class="cancel-button" mat-button>
      {{ 'generic.cancel' | translate }}
    </button>
  </ng-template>
  <ng-template #testCriteriaButton>
    <button (click)="testCriteria()" class="mat-secondary-button mat-centered-button" mat-button>
      {{'funding-process.start.test-criteria' | translate }}
    </button>
  </ng-template>

  <div [ngSwitch]="phase">
    <div *ngSwitchCase="0">
      <caple-company-information>
      </caple-company-information>
      <mat-dialog-actions>
        <ng-container *ngTemplateOutlet="closeButton"></ng-container>

        <span [matTooltipDisabled]="!nextDisabled()"
              [matTooltipPosition]="'above'"
              matTooltip="{{'funding-process.start.start-button-disabled-tooltip' | translate }}">
          <button (click)="next()" [disabled]="nextDisabled()" class="next-button" mat-button>
            {{ 'generic.next' | translate }}
          </button>
        </span>
      </mat-dialog-actions>
    </div>
    <div *ngSwitchCase="1">
      <caple-product-type-selector (onTypeChanged)="product = $event"
                                   (onValidationChanged)="productValid = $event"></caple-product-type-selector>

      <mat-dialog-actions>
        <ng-container *ngTemplateOutlet="closeButton"></ng-container>
        <caple-loading-wrapper [diameter]="36" class="buttons" key="urn:funding-process:create">
          <button (click)="next()" [disabled]="nextDisabled()" class="next-button" mat-button>
            <span *ngIf="shouldShowEligibilityCriteria();else done">{{ 'generic.next' | translate }}</span>
            <ng-template #done><span>{{ 'funding-process.start.start-button' | translate }}</span></ng-template>
          </button>
        </caple-loading-wrapper>
      </mat-dialog-actions>
    </div>
    <div *ngSwitchCase="2">
      <caple-start-funding-process-basic-eligibility (checked)="scrollToTop()" (onCreated)="eligibilityCreated($event)"
                                                     [directors]="(chamberOfCommerceData$ | async)?.directors?.length"
                                                     [productType]="product.productType"
                                                     [sectorCode]="sectorCode"
                                                     class="scroll-block">
      </caple-start-funding-process-basic-eligibility>
      <mat-dialog-actions>
        <ng-container *ngTemplateOutlet="closeButton"></ng-container>

        <div class="flex">
          <ng-container *ngTemplateOutlet="testCriteriaButton"></ng-container>
          <button (click)="next()" class="next-button" mat-button>
            <span *ngIf="!eligibilityId">{{ 'generic.skip' | translate }}</span>
            <span *ngIf="eligibilityId">{{ 'generic.next' | translate }}</span>
          </button>
        </div>
      </mat-dialog-actions>
    </div>

    <div *ngSwitchCase="3">
      <caple-start-funding-process-advanced-eligibility (checked)="scrollToTop()"
                                                        (onCreated)="eligibilityCreated($event)"
                                                        [eligiblityId]="eligibilityId"
                                                        class="scroll-block">
      </caple-start-funding-process-advanced-eligibility>

      <mat-dialog-actions>
        <ng-container *ngTemplateOutlet="closeButton"></ng-container>

        <div class="flex">
          <ng-container *ngTemplateOutlet="testCriteriaButton"></ng-container>
          <caple-loading-wrapper [diameter]="30" key="urn:funding-process:create">
            <button (click)="startFundingProcess()" class="start-button" mat-button>
              <span *ngIf="!advancedEligibilityTested">{{'funding-process.start.skip-start-button' | translate }}</span>
              <span *ngIf="advancedEligibilityTested">{{'funding-process.start.start-button' | translate }}</span>
            </button>
          </caple-loading-wrapper>
        </div>
      </mat-dialog-actions>
    </div>
  </div>

</mat-dialog-content>

