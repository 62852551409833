/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./eligibility-criteria-result.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./eligibility-criteria-result.component";
var styles_EligibilityCriteriaResultComponent = [i0.styles];
var RenderType_EligibilityCriteriaResultComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EligibilityCriteriaResultComponent, data: {} });
export { RenderType_EligibilityCriteriaResultComponent as RenderType_EligibilityCriteriaResultComponent };
function View_EligibilityCriteriaResultComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "a", [["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(2, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "link-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.link; _ck(_v, 1, 0, currVal_2); var currVal_3 = "active"; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = _co.linkText; _ck(_v, 6, 0, currVal_4); }); }
function View_EligibilityCriteriaResultComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "eligibility-result-notice"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform(("eligibility-criteria.notices." + _v.context.$implicit))); _ck(_v, 0, 0, currVal_0); }); }
function View_EligibilityCriteriaResultComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "vertical-align"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EligibilityCriteriaResultComponent_2)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EligibilityCriteriaResultComponent_3)), i1.ɵdid(7, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.link && _co.linkText); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.notices; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.passedText; _ck(_v, 3, 0, currVal_0); }); }
function View_EligibilityCriteriaResultComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(("eligibility-criteria.criteria." + _v.context.$implicit))); _ck(_v, 1, 0, currVal_0); }); }
function View_EligibilityCriteriaResultComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "result"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 2, "ul", [["class", "errors"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EligibilityCriteriaResultComponent_5)), i1.ɵdid(8, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "result postfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.errors; _ck(_v, 8, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("eligibility-criteria.failed")); _ck(_v, 4, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("eligibility-criteria.failed-additional")); _ck(_v, 11, 0, currVal_2); }); }
export function View_EligibilityCriteriaResultComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "go-no-go"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "badge"]], [[2, "failed", null], [2, "passed", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EligibilityCriteriaResultComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EligibilityCriteriaResultComponent_4)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.errors.length === 0); _ck(_v, 3, 0, currVal_2); var currVal_3 = (_co.errors.length > 0); _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.errors.length > 0); var currVal_1 = (_co.errors.length === 0); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_EligibilityCriteriaResultComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "caple-eligibility-criteria-result", [], null, null, null, View_EligibilityCriteriaResultComponent_0, RenderType_EligibilityCriteriaResultComponent)), i1.ɵdid(1, 49152, null, 0, i5.EligibilityCriteriaResultComponent, [], null, null)], null, null); }
var EligibilityCriteriaResultComponentNgFactory = i1.ɵccf("caple-eligibility-criteria-result", i5.EligibilityCriteriaResultComponent, View_EligibilityCriteriaResultComponent_Host_0, { passedText: "passedText", errors: "errors", notices: "notices", link: "link", linkText: "linkText" }, {}, []);
export { EligibilityCriteriaResultComponentNgFactory as EligibilityCriteriaResultComponentNgFactory };
