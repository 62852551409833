import { map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { RedirectTo, ResetState } from '../generic/actions';
import { LoginActions, LoginSuccess, UnauthenticatedAccess } from './actions';
import { ShowToast } from '../layout/actions';
import { config } from '../../../environments/config';
import { Auth0Factory } from '../../login/login-form/auth0-factory.service';
import { Store } from '@ngrx/store';
import { State } from '../reducers';

@Injectable()
export class LoginEffects {

  @Effect({dispatch: false})
  loginSuccess$ = this.actions$
    .pipe(
      ofType(LoginActions.LOGIN_SUCCESS),
      tap((action: LoginSuccess) => {
        this.router.navigateByUrl(action.redirectUri);
      }));

  @Effect({dispatch: false})
  logout$ = this.actions$
    .pipe(
      ofType(LoginActions.LOGOUT),
      tap(() => {
        this.auth0Factory.createInstance().logout({
          returnTo: config.baseUrl + 'login'
        });
      }));

  @Effect()
  unauthenticatedAccess$ = this.actions$
    .pipe(
      ofType(LoginActions.UNAUTHENTICATED_ACCESS),
      tap((action: UnauthenticatedAccess) => {
        this.router.navigate(['/login'], {queryParams: {redirectUri: action.redirectUri}});
      }),
      map(() => {
        return new ResetState();
      }));

  @Effect()
  unauthorizedAccess$ = this.actions$
    .pipe(
      ofType(LoginActions.UNAUTHORIZED_ACCESS),
      map(() => new RedirectTo(['/dashboard']))
    );

  @Effect()
  tokenRenewalFailed$ = this.actions$
    .pipe(
      ofType(LoginActions.ACCESS_TOKEN_RENEW_FAILED),
      map(() => new ShowToast('generic.authentication.token-renewal-failed')));

  constructor(private router: Router,
              private actions$: Actions,
              private store: Store<State>,
              private auth0Factory: Auth0Factory) {
  }
}
