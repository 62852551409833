import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AbstractApiService } from './abstract-api.service';
import { WithId } from '../../../state-management/util/actions';
import { ReadKnowledgeItem } from '../models/readKnowledgeItem';

@Injectable()
export class KnowledgeService extends AbstractApiService {

  private baseUrl = '/api/v1/knowledge-center';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getAll(): Observable<ReadKnowledgeItem[]> {
    return this.get<ReadKnowledgeItem[]>(this.baseUrl);
  }

  public downloadFile(id: WithId): Observable<HttpEvent<Blob>> {
    return this.getFileWithProgress(`${this.baseUrl}/${id.id}/download`)
  }
}
