/**
 * Caple Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type ProductSubType = 'LTE_1_5M' | 'GT_1_5M' | 'MBO' | 'MBI' | 'OTHER';

export const ProductSubType = {
    LTE15M: 'LTE_1_5M' as ProductSubType,
    GT15M: 'GT_1_5M' as ProductSubType,
    MBO: 'MBO' as ProductSubType,
    MBI: 'MBI' as ProductSubType,
    OTHER: 'OTHER' as ProductSubType
}
