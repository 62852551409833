import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { ControlValueAccessor } from '@angular/forms';
import { FileUploadFailed, HideProgressIndicator, ShowProgressIndicator, UpdateProgressIndicator } from '../../../state-management/layout/actions';
import { ApiError } from '../../api/api-error';
import { finalize, tap } from 'rxjs/operators';
import { HttpEventType } from '@angular/common/http';
var ExcelImportFieldComponent = /** @class */ (function () {
    function ExcelImportFieldComponent(store) {
        this.store = store;
        this.ACCEPTED_FILE_EXTENTIONS = ['.xlsx'];
        /**
         * The translation key that is shown in the progress dialog
         */
        this.translationKey = "generic.importing-file";
        /**
         * If the component should be hidden
         */
        this.hidden = false;
        /**
         * If the component should be disabled
         */
        this.disabled = false;
        this.isActionButton = false;
        this.isSecondaryButton = false;
        this.isTextareaButton = false;
        this.isPrimaryButton = false;
        this.excelImported = new EventEmitter();
    }
    /**
     * Upload the file and after upload, store the file
     */
    ExcelImportFieldComponent.prototype.onFileSelected = function ($event) {
        var _this = this;
        this.store.dispatch(new ShowProgressIndicator(this.translationKey));
        var _a = this.startImport($event), uploadFileObservable$ = _a[0], progressObservable$ = _a[1];
        progressObservable$.pipe(tap(function (event) {
            if (event.type === HttpEventType.UploadProgress) {
                var percentDone = Math.round((event.loaded / event.total) * 100);
                _this.store.dispatch(new UpdateProgressIndicator(percentDone, event.loaded, event.total));
            }
        }), finalize(function () { return _this.store.dispatch(new HideProgressIndicator()); })).subscribe();
        uploadFileObservable$
            .subscribe(function (response) {
            _this.excelImported.emit(response);
        }, function (error) {
            var apiError = new ApiError(error.error.code, error.error.values);
            _this.store.dispatch(new FileUploadFailed(apiError));
        });
    };
    ExcelImportFieldComponent.prototype.startImport = function (file) {
        if (typeof this.id === 'string') {
            return this.importFn({ id: this.id, file: file });
        }
        else {
            return this.importFn(tslib_1.__assign({}, this.id, { file: file }));
        }
    };
    ExcelImportFieldComponent.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
    };
    // ****************************
    // Below methods are empty on purpose
    // ****************************
    ExcelImportFieldComponent.prototype.registerOnChange = function (fn) {
    };
    ExcelImportFieldComponent.prototype.registerOnTouched = function (fn) {
    };
    ExcelImportFieldComponent.prototype.writeValue = function (obj) {
    };
    return ExcelImportFieldComponent;
}());
export { ExcelImportFieldComponent };
