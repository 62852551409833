import { merge as observableMerge, Observable } from 'rxjs';

import { distinctUntilChanged, map } from 'rxjs/operators';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { FormGroupMapperService } from '../../origination/state-management/form-group-mapper.service';
import { markAllFormFieldsAsTouched } from '../util/form-util';
import { CapleFormGroup } from '../util/caple-form';

export abstract class FormGroupContainer {

  protected constructor(public form: CapleFormGroup) {
  }

  /**
   * Whether the form is valid
   * @returns {boolean}
   */
  public get valid() {
    return this.form.valid;
  }

  /**
   * Whether or not the form is read only
   */
  public get readOnly() {
    return this.form.disabled;
  }

  /**
   * Whether or not the form should be read only
   * @param {Boolean} value
   */
  public set readOnly(value: Boolean) {
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  /**
   * Observable for when the status of the form or one of the top-level components changes
   */
  public get statusChanges(): Observable<boolean> {
    const controlObservables = Object.keys(this.form.controls).map(field => this.get(field).statusChanges.pipe(distinctUntilChanged()));
    return observableMerge(...controlObservables).pipe(
      map((status) => {
        return status === 'VALID';
      }));
  }

  /**
   * Get the data as T
   * @returns {T}
   */
  public getData<T>(): T {
    return this.form.getRawValue();
  }

  /**
   * Get a child control
   * @param {Array<string | number> | string} path
   * @returns {AbstractControl}
   */
  public get(path: Array<string | number> | string): AbstractControl | null {
    return this.form.get(path);
  }

  /**
   *  Patches the value of the {@link FormGroup}. It accepts an object with control
   *  names as keys, and will do its best to match the values to the correct controls
   *  in the group.
   */
  public patchValue(value: { [key: string]: any; }, options?: { onlySelf?: boolean; emitEvent?: boolean; }): void {
    this.form.patchValue(value, options);
    this.patchFormArrays(value, this.form);
  }

  /**
   * Get a Map of each top-level control in the form and its valid status.
   * @returns {Map<string, boolean>}
   */
  public getValidityOfControls(): { id: string, valid: boolean }[] {
    const validity = [];
    Object.keys(this.form.controls).forEach(field => {
      validity.push({
        id: field,
        valid: this.form.get(field).valid
      });
    });
    return validity;
  }

  /**
   * Mark all controls and their child controls as touched. Can be used to validate all forms in the field.
   */
  public markAllAsTouched() {
    markAllFormFieldsAsTouched(this.form);
  }

  /**
   * The regular patch function does not push new elements into array's. So lets stay we get 2 items from the backend
   * but currently there is only 1 item in the FormArray, then only the first item will be updated and the second
   * item will be ignored. This is expected, see the docs: https://angular.io/api/forms/FormArray#patchValue
   * Fundamentally, patchValue() doesn't know what the FormGroup items that are contained inside the FormArray look like.
   *
   * In order to update the arrays properly, we need to walk the entire form and make the form controls match the values from the server
   */
  private patchFormArrays(value: { [key: string]: any; }, form: FormGroup) {
    if (!value) {
      return;
    }

    Object.keys(form.controls).forEach(name => {
      const control = form.controls[name];
      if (control instanceof FormGroup) {
        this.patchFormArrays(value[name], control);
      } else if (control instanceof FormArray) {
        while (control.controls.length > 0) {
          control.removeAt(0);
        }

        const newFormArrayElements = FormGroupMapperService.getFormGroupArray(name, value[name]) as AbstractControl[];
        newFormArrayElements.forEach(element => control.push(element));
      }
    });
  }
}
