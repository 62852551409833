import { OnDestroy } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import * as moment from 'moment';
import { DateAdapter } from '@angular/material/core';
import { DatePickerFilter } from './date-picker.filter';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { selectLocale } from '../../../state-management/preferences/selector';
import { filter } from 'rxjs/operators';
var DatePickerComponent = /** @class */ (function () {
    function DatePickerComponent(store, adapter) {
        this.store = store;
        this.adapter = adapter;
        this.isDisabled = false;
        this.subscriptions = new Subscription();
        this.onChange = function (_) {
        };
        this.onTouched = function () {
        };
        this.subscriptions.add(store.pipe(select(selectLocale), filter(function (val) { return !!val; })).subscribe(function (locale) { return adapter.setLocale(locale); }));
    }
    DatePickerComponent.prototype.dateChange = function ($event) {
        var formattedValue = moment($event.value).format('Y-MM-DD');
        if (formattedValue !== 'Invalid date') {
            this.onChange(formattedValue);
        }
        else {
            this.onChange(null);
        }
    };
    Object.defineProperty(DatePickerComponent.prototype, "filter", {
        get: function () {
            if (typeof this.datePickerFilter === 'function') {
                return this.datePickerFilter;
            }
            return DatePickerFilter.getFilter(this.datePickerFilter);
        },
        enumerable: true,
        configurable: true
    });
    DatePickerComponent.prototype.onDatepickerClosed = function () {
        this.onTouched();
    };
    /**
     * Implementation for the ControlValueAccessor interface
     */
    DatePickerComponent.prototype.writeValue = function (obj) {
    };
    /**
     * Implementation for the ControlValueAccessor interface
     */
    DatePickerComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    /**
     * Implementation for the ControlValueAccessor interface
     */
    DatePickerComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    /**
     * Implementation for the ControlValueAccessor interface
     */
    DatePickerComponent.prototype.setDisabledState = function (isDisabled) {
        this.isDisabled = isDisabled;
    };
    DatePickerComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    return DatePickerComponent;
}());
export { DatePickerComponent };
