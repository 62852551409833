import { Injectable, Injector } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { State } from './state-management/reducers';
import { selectAccessToken } from './state-management/login/selector';
import { filter, take } from 'rxjs/operators';
import { AccessToken } from './shared/authentication/access-token';
import { Angulartics2Piwik } from 'angulartics2/piwik';

@Injectable()
export class AnalyticsService {
  constructor(private store: Store<State>, private injector: Injector) {
  }

  public initialize() {
    this.store.pipe(
      select(selectAccessToken),
      filter(token => !!token),
      take(1)
    ).subscribe((accessToken: AccessToken) => {
      const angulartics2Piwik = this.injector.get(Angulartics2Piwik);
      angulartics2Piwik.setUsername(accessToken.getUserId());
      angulartics2Piwik.setUserProperties({
        dimension1: accessToken.getRoles().join(', ')
      });
    });
  }
}

export function initialize(analyticsService: AnalyticsService) {
  return () => analyticsService.initialize();
}
