import {Component, OnDestroy} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {CloseModal} from '../../../../state-management/layout/actions';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CreateSingle} from '../../../../state-management/util/actions';
import {CreateFinancialRatingPayload} from '../../../../shared/api/services/funding-process.service';
import {selectActiveFundingProcess} from '../../../state-management/selector';
import {State} from '../../../../state-management/reducers';
import {SLICE} from '../../../state-management/slice';
import {filter} from 'rxjs/operators';
import {Product} from '../../../../shared/api/models/product';
import {ProductType} from '../../../../shared/api/models/productType';
import {ProductSubType} from '../../../../shared/api/models/productSubType';

@Component({
  selector: 'caple-create-financial-rating-dialog',
  templateUrl: './create-financial-rating-dialog.component.html'
})
export class CreateFinancialRatingDialogComponent implements OnDestroy {

  public static readonly START_YEAR_MIN = 1986;
  public static readonly START_YEAR_MAX = 2023;
  public static readonly MIN_LOAN_AMOUNT = 500000;
  public static readonly MAX_LOAN_AMOUNT = 5000000;
  public static readonly MAX_LOAN_AMOUNT_MBI = 10000000;
  /**
   * Min start year
   * @type {number}
   */
  public minStartYear = CreateFinancialRatingDialogComponent.START_YEAR_MIN;
  /**
   * Max start year
   * @type {number}
   */
  public maxStartYear = CreateFinancialRatingDialogComponent.START_YEAR_MAX;

  /**
   * The form
   */
  public form;
  /**
   * The id of the funding process
   */
  private fundingProcessId: string;

  private product: Product;
  public productType: ProductType;
  /**
   * Subscriptions that need to be cleaned up
   */
  private subscriptions: Subscription = new Subscription();

  constructor(private store: Store<State>) {
    this.subscriptions.add(this.store.pipe(
      select(selectActiveFundingProcess),
      filter(state => !!state))
      .subscribe(state => {
        this.fundingProcessId = state.id;
        this.product = state.product;
        this.productType = this.product.productType;

        const maxLoanAmount = this.product.productType === ProductType.MBI ? CreateFinancialRatingDialogComponent.MAX_LOAN_AMOUNT_MBI : CreateFinancialRatingDialogComponent.MAX_LOAN_AMOUNT;

        this.form = new FormGroup({
          title: new FormControl(null),
          startYear: new FormControl(null, [Validators.required, Validators.min(this.minStartYear), Validators.max(this.maxStartYear)]),
          loanAmount: new FormControl(null, [Validators.required, Validators.min(CreateFinancialRatingDialogComponent.MIN_LOAN_AMOUNT), Validators.max(maxLoanAmount)])
        });

        if (this.product.loanSize) {
          this.form.get('loanAmount').patchValue(this.product.loanSize);
        }
      }));
  }

  public create() {
    // Mark all form fields as touched so validation triggers
    Object.keys(this.form.controls).forEach(field => {
      const control = this.form.get(field);
      control.markAsTouched({onlySelf: true});
    });

    if (this.form.valid) {
      this.form.value.title = this.form.value.title || this.generateTitle();
      const payload: CreateFinancialRatingPayload = {
        fundingProcessId: this.fundingProcessId,
        product: this.product,
        financialRating: this.form.value
      };
      const action = new CreateSingle(SLICE.FINANCIAL_RATING, payload);
      this.store.dispatch(action);
    }
  }

  public closeModal() {
    this.store.dispatch(new CloseModal());
  }

  public getPlaceholder() {
    if (this.form.value.startYear && this.form.value.startYear > 999) {
      return this.generateTitle();
    }
    return '';
  }

  public infoIsAvailable() {
    return this.product && this.product.productType !== ProductType.ACQUISITION;
  }

  public getInfoTranslationKey() {
    if (this.product.productType === ProductType.GROWTH ||
      (this.product.productSubType === ProductSubType.OTHER && this.product.productType === ProductType.TRANSACTION)) {
      return 'financial-ratings.info.growth';
    }
    return 'financial-ratings.info.transaction';
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private generateTitle() {
    return this.form.value.startYear + ' - ' + (this.form.value.startYear + 1);
  }
}
