import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WithId } from '../../state-management/util/actions';
import { companiesAdapter, State } from './reducer';
import { Role } from '../../shared/api/models/role';

/**************
 * Slices
 **************/
const selectFeature = createFeatureSelector<State>('administration');
const selectCompaniesSlice = createSelector(selectFeature, state => state.companies);

/** Companies **/
export const {
  selectAll: selectAllCompanies,
  selectIsLoaded: selectCompaniesIsLoaded,
  selectSingle: selectCompany,
  selectSingleIsLoaded: selectCompanyIsLoaded
} = companiesAdapter.getSelectors(selectCompaniesSlice);

export const selectCompanyHasRole = (companyId: string, role: Role) => createSelector(selectFeature, (state: State) => {
  const company = state.companies.entities[companyId];
  if (company && company.roles) {
    return company.roles.indexOf(role) !== -1;
  }
  return false;
});

export const selectCompanyFilteredCountries = createSelector(selectFeature, (state: State) => state.companyFilteredCountries);

export const selectCompanySearchQuery = createSelector(selectFeature, (state: State) => state.companySearchQuery);

/** Users **/
export const selectUsersForCompany = (companyId: string) => createSelector(selectFeature, (state: State) => {
  return state.users.entities[companyId];
});
export const selectUsersForCompanyLoaded = (company: WithId) => createSelector(selectFeature, (state: State) => {
  return state.users.entities[company.id] !== undefined;
});

/** Clients **/
export const selectClientsForCompany = (companyId: string) => createSelector(selectFeature, (state: State) => {
  return state.clients.entities[companyId];
});
export const selectClientsForCompanyLoaded = (company: WithId) => createSelector(selectFeature, (state: State) => {
  return state.clients.entities[company.id] !== undefined;
});
