import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {RepeatableItem} from '../../../../../shared/layout/repeatable-form-item/repeatable-item';
import {CapleFormBuilder} from '../../../../../shared/util/caple-form-builder';

@Component({
  selector: 'caple-suggested-condition-item',
  templateUrl: './suggested-condition-item.component.html',
  styleUrls: ['./suggested-condition-item.component.scss']
})
export class SuggestedConditionItemComponent extends RepeatableItem {

  @Input()
  public item: FormControl;

  @Input()
  public showRemove: boolean;

  @Input()
  public index: number;

  @Output()
  public removed: EventEmitter<void> = new EventEmitter<void>();

  public static produceFormGroup(val?: any): FormControl {
    val = val || '';
    return new CapleFormBuilder().control(val, Validators.required);
  }

  public produceFormGroup(val?: any): FormControl {
    return SuggestedConditionItemComponent.produceFormGroup(val);
  }
}
