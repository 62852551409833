import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { State } from '../../../state-management/reducers';
import { CloseModal } from '../../../state-management/layout/actions';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'caple-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDialogComponent {

  private action: Action;
  public message: string;
  public title: string;
  public warning: string;
  public confirmLabel: string;
  public confirmLoadingKey: string;
  public cancelLabel: string;

  constructor(private store: Store<State>, @Inject(MAT_DIALOG_DATA) private options: ConfirmDialogOptions) {
    this.action = options.action;
    this.message = options.message;
    this.warning = options.warning;
    this.title = options.title || 'generic.confirmation-title';
    this.confirmLabel = options.confirm || 'generic.confirm';
    this.confirmLoadingKey = options.confirmLoadingKey;
    this.cancelLabel = options.cancel || 'generic.cancel';
  }

  public closeModal() {
    this.store.dispatch(new CloseModal());
  }

  public confirm() {
    this.store.dispatch(this.action);
    if (!this.confirmLoadingKey) {
      this.store.dispatch(new CloseModal());
    }
  }
}

export interface ConfirmDialogOptions {
  message: string;
  warning: string;
  action: Action;
  title?: string;
  confirm?: string;
  confirmLoadingKey?: string;
  cancel?: string;
}
