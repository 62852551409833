import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { State } from '../state-management/reducers';
import { ADMINISTRATION_ROLES, MONITORING_ROLES, ORIGINATION_ROLES, selectRoles } from '../state-management/login/selector';
import { Subscription } from 'rxjs';
import { RedirectTo } from '../state-management/generic/actions';
import { take } from 'rxjs/operators';

@Component({
  selector: 'caple-navigation',
  templateUrl: './navigation.component.html',
  encapsulation: ViewEncapsulation.None
})
export class NavigationComponent implements OnInit, OnDestroy {

  public showError = false;

  private storeSubscription: Subscription;

  constructor(private store: Store<State>) {
  }

  ngOnInit(): void {
    this.storeSubscription =
      this.store.pipe(
        select(selectRoles),
        take(1))
        .subscribe((roles) => {
          if (roles.some(role => ORIGINATION_ROLES.includes(role))) {
            this.store.dispatch(new RedirectTo(['/origination']));
          } else if (roles.some(role => MONITORING_ROLES.includes(role))) {
            this.store.dispatch(new RedirectTo(['/monitoring']));
          } else if (roles.some(role => ADMINISTRATION_ROLES.includes(role))) {
            this.store.dispatch(new RedirectTo(['/administration']));
          } else {
            this.showError = true;
          }
        });
  }

  ngOnDestroy(): void {
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
      this.storeSubscription = null;
    }
  }
}
