<div class="container-full-page-message vertical-align horizontal-align">
  <div class="pure-g">
    <div class="pure-u-1-2 logo vertical-align horizontal-align">
      <img src="assets/images/logo.jpg" alt="Logo">
    </div>
    <div class="pure-u-1-2">
      <ng-content></ng-content>
    </div>
  </div>
</div>
