import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'caple-eligibility-criteria-yes-no-question',
  templateUrl: './eligibility-criteria-yes-no-question.component.html'
})
export class EligibilityCriteriaYesNoQuestionComponent {

  @Input()
  public formControlItem: FormControl;

  @Input()
  public label: string;
}
