import { SupportedCountry } from '../shared/api/models/supportedCountry';

// tslint:disable-next-line
export type SectorListType = {
  [key: string]: Array<number>;
};

/**
 * List of excluded sectors for the GB
 */
export const EXCLUDED_SECTORS_GB: SectorListType = {
  'nightclubs-pubs': [56301, 56302],
  'gambling': [92000],
  'hotels-accommodation': [55100, 55201, 55202, 55209, 55300, 55900],
  'restaurants': [56101, 56102, 56103, 56210, 56290],
  'tobacco': [1150, 12000, 46350],
  'weapon': [25400],
  'nuclear': [24460],
  'mining': [5200, 7100, 7210, 7290, 8110, 8120, 8910, 8990, 5101, 5102],
  'property-development': [41100],
  'financial-insurance': [64110, 64191, 64192, 64201, 64202, 64203, 64204, 64205, 64209, 64301, 64302, 64303, 64304, 64305, 64306,
    64910, 64921, 64922, 64929, 64991, 64992, 64999, 65110, 65120, 65201, 65202, 65300, 66110, 66120, 66190, 66300],
  'real-estate': [68100, 68201, 68202, 68209],
  'extraterritorial-organizations': [99000],
  'wood-pulp': [17110]
};

/**
 * List of excluded sectors for the NL
 */
export const EXCLUDED_SECTORS_NL: SectorListType = {
  'nightclubs-pubs': [5630, 56300],
  'gambling': [9200, 92000, 92001, 92009],
  'hotels-accommodation': [5510, 55100, 55101, 55102, 5520, 55200, 55201, 55202, 5530, 55300],
  'restaurants': [5610, 56100, 56101, 56102, 5621, 56210, 5629, 56290],
  'tobacco': [1200, 4635, 12000, 46350],
  'weapon': [2540, 25400],
  'nuclear': [2446, 24460],
  'mining': [899, 8990], // This is 0899
  'property-development': [4110, 41100],
  'financial-insurance': [6411, 6419, 64110, 64190, 64191, 64192, 6420, 64200, 64301, 64302, 64303, 6491, 6492, 64910, 64920,
    64921, 64922, 6499, 6511, 64990, 65110, 65111, 65112, 65113, 65122, 6520, 6530, 65200, 65300, 65301, 65302, 65303, 65309,
    6611, 6612, 6619, 66110, 66120, 66190, 66191, 66192, 66193, 6630, 66300],
  'real-estate': [6810, 6820, 68100, 68200, 68202, 6820, 68200],
  'extraterritorial-organizations': [9900, 99000],
  'wood-pulp': [1711, 17110]
};

export function getSectorExclusionListForCountry(country: SupportedCountry) {
  if (country === SupportedCountry.GB) {
    return EXCLUDED_SECTORS_GB;
  } else if (country === SupportedCountry.NL) {
    return EXCLUDED_SECTORS_NL;
  } else {
    throw new Error(`No Sector Exclusion List available for Country ${country}`);
  }
}

/**
 * Helper function to see if a sector is contained in a excluded sector list
 * @param sectorList The list of excluded sectors to match against
 * @param sector The sector that might be excluded
 * @returns 'String' of the sector if is matches, null otherwise
 */
export function containsSector(sectorList: SectorListType, sector: string | number): string | null {
  if (!sector) {
    return null;
  }

  if (typeof sector !== 'number') {
    sector = Number.parseInt(sector, 10);
  }

  for (const key of Object.keys(sectorList)) {
    const sectorMatches = sectorList[key].indexOf(sector) > -1;

    if (sectorMatches) {
      return key;
    }
  }

  return null;
}
