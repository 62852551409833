/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../file-upload-button/file-upload-button.component.ngfactory";
import * as i2 from "../file-upload-button/file-upload-button.component";
import * as i3 from "@ngrx/store";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "@angular/forms";
import * as i7 from "./excel-import-field.component";
var styles_ExcelImportFieldComponent = [];
var RenderType_ExcelImportFieldComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ExcelImportFieldComponent, data: {} });
export { RenderType_ExcelImportFieldComponent as RenderType_ExcelImportFieldComponent };
function View_ExcelImportFieldComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "caple-file-upload-button", [], null, [[null, "fileSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("fileSelected" === en)) {
        var pd_0 = (_co.onFileSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_FileUploadButtonComponent_0, i1.RenderType_FileUploadButtonComponent)), i0.ɵdid(1, 49152, null, 0, i2.FileUploadButtonComponent, [i3.Store], { label: [0, "label"], disabled: [1, "disabled"], disabledTooltip: [2, "disabledTooltip"], accept: [3, "accept"], isActionButton: [4, "isActionButton"], isSecondaryButton: [5, "isSecondaryButton"], isTextareaButton: [6, "isTextareaButton"], isPrimaryButton: [7, "isPrimaryButton"], iconBeforeText: [8, "iconBeforeText"] }, { fileSelected: "fileSelected" }), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.label)), ""); var currVal_1 = _co.disabled; var currVal_2 = _co.disabledTooltip; var currVal_3 = _co.ACCEPTED_FILE_EXTENTIONS; var currVal_4 = _co.isActionButton; var currVal_5 = _co.isSecondaryButton; var currVal_6 = _co.isTextareaButton; var currVal_7 = _co.isPrimaryButton; var currVal_8 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
export function View_ExcelImportFieldComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ExcelImportFieldComponent_1)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hidden; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ExcelImportFieldComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "caple-excel-import-field", [], null, null, null, View_ExcelImportFieldComponent_0, RenderType_ExcelImportFieldComponent)), i0.ɵprd(5120, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.ExcelImportFieldComponent]), i0.ɵdid(2, 49152, null, 0, i7.ExcelImportFieldComponent, [i3.Store], null, null)], null, null); }
var ExcelImportFieldComponentNgFactory = i0.ɵccf("caple-excel-import-field", i7.ExcelImportFieldComponent, View_ExcelImportFieldComponent_Host_0, { label: "label", id: "id", translationKey: "translationKey", hidden: "hidden", disabled: "disabled", disabledTooltip: "disabledTooltip", isActionButton: "isActionButton", isSecondaryButton: "isSecondaryButton", isTextareaButton: "isTextareaButton", isPrimaryButton: "isPrimaryButton", importFn: "importFn" }, { excelImported: "excelImported" }, []);
export { ExcelImportFieldComponentNgFactory as ExcelImportFieldComponentNgFactory };
