/**
 * Caple Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type Comparator = 'LESS' | 'LESS_OR_EQUAL' | 'MORE' | 'MORE_OR_EQUAL' | 'EQUAL' | 'UNKNOWN';

export const Comparator = {
    LESS: 'LESS' as Comparator,
    LESSOREQUAL: 'LESS_OR_EQUAL' as Comparator,
    MORE: 'MORE' as Comparator,
    MOREOREQUAL: 'MORE_OR_EQUAL' as Comparator,
    EQUAL: 'EQUAL' as Comparator,
    UNKNOWN: 'UNKNOWN' as Comparator
}
