import { Component, Input } from '@angular/core';
import { FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { State } from '../state-management/reducers';
import { EligibilityParent } from './eligibility-parent';
import { EligibilityCheckType } from '../shared/api/models/eligibilityCheckType';
import { filter } from 'rxjs/operators';
import { ReadEligibility } from '../shared/api/models/readEligibility';
import { COUNTRY_DATA } from '../origination/shared/country-data';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SupportedCountry } from '../shared/api/models/supportedCountry';
import {
  isCapleLoanPercentageHigh,
  isSectorCodeValid,
  isTotalAssetsMinValid,
  isTurnoverAndTotalAssetsMaxValid,
  isTurnoverMinValid,
  MAX_CAPLE_LOAN,
  MAX_ENTITIES_IN_GROUP,
  MIN_CAPLE_LOAN,
  MIN_DIRECTORS_SHAREHOLDERS
} from './eligibility-validator';
import { ScrollTo } from '../state-management/layout/actions';
import { ProductType } from '../shared/api/models/productType';


@Component({
  selector: 'caple-basic-eligibility-criteria',
  templateUrl: './basic-eligibility-criteria.component.html'
})
export class BasicEligibilityCriteriaComponent extends EligibilityParent {

  @Input()
  public passedText = 'eligibility-criteria.passed-basic';

  private readonly YES = 'yes';
  private readonly NO = 'no';

  private _fundingProcessProductType: ProductType;

  public hiddenControls: string[];
  public animatedControls: string[] = [];

  constructor(store: Store<State>) {
    super(store);
    this.form = new FormBuilder().group({
      sectorCode: [null, Validators.required],
      loanAmount: [null, [Validators.required, Validators.min(0)]],
      turnover: [null, [Validators.required, Validators.min(0)]],
      totalAssets: [null, [Validators.required, Validators.min(0)]],

      registeredAccountsCountQuestion: [null, Validators.required],
      registeredAccountsExpectCountQuestion: [null],
      registeredAccountProfitableQuestion: [null],

      directors: [null, [Validators.required, Validators.min(0)]],
      shareholders: [null],
      directorsOrShareholdersExpectedQuestion: [null],
      entitiesInGroup: [null, [Validators.required, Validators.min(0)]],

      currentTotalDebt: [null, [Validators.required, Validators.min(0)]],
      refinanceExistingDebtQuestion: [null, Validators.required],

      paymentOrDemandEventsQuestion: [null, Validators.required]
    });

    this.setFormDisableSetting();
    this.onFormChanged();

    this.hiddenControls = ['registeredAccountsExpectCountQuestion',
      'registeredAccountProfitableQuestion', 'shareholders', 'directorsOrShareholdersExpectedQuestion'
    ];

    this.subscriptions.add(this.form.controls['registeredAccountsCountQuestion'].valueChanges.subscribe((value) => {
      if (value === this.YES) {
        this.hideControl('registeredAccountsExpectCountQuestion');
      } else if (value === this.NO) {
        this.showControl('registeredAccountsExpectCountQuestion');
      }

      if (this.form.controls['registeredAccountsExpectCountQuestion'].value === null) {
        if (value === this.YES) {
          this.showControl('registeredAccountProfitableQuestion');
        } else if (value === this.NO) {
          this.hideControl('registeredAccountProfitableQuestion');
        }
      }
    }));

    this.subscriptions.add(this.form.controls['registeredAccountsExpectCountQuestion'].valueChanges.subscribe((value) => {
      if (value === this.YES) {
        this.showControl('registeredAccountProfitableQuestion');
      } else if (value === this.NO) {
        this.hideControl('registeredAccountProfitableQuestion');
      }
    }));

    this.subscriptions.add(this.form.controls['directors'].valueChanges.pipe(
      filter(value => coerceBooleanProperty(value))
    ).subscribe((value) => {
      if (value < MIN_DIRECTORS_SHAREHOLDERS) {
        this.showControl('shareholders', [Validators.min(0)]);
      } else {
        this.hideControl('shareholders');
        this.hideControl('directorsOrShareholdersExpectedQuestion');
      }
    }));

    this.subscriptions.add(this.form.controls['shareholders'].valueChanges.pipe(
      filter(value => coerceBooleanProperty(value))
    ).subscribe((value) => {
      if (value < MIN_DIRECTORS_SHAREHOLDERS && this.form.controls['directors'].value < MIN_DIRECTORS_SHAREHOLDERS) {
        this.showControl('directorsOrShareholdersExpectedQuestion');
      } else {
        this.hideControl('directorsOrShareholdersExpectedQuestion');
      }
    }));
  }

  @Input()
  public set fundingProcessProductType(value: ProductType) {
    this._fundingProcessProductType = value;
    /*
    We only want to validate basic eligibility if
      - eligibility exists
      - it is filled (we check the field 'turnover' because it is mandatory)
     */
    if (this.eligibilityId && this.form.controls['turnover'].value) {
      this.checkEligibility();
    }
  }

  @Input()
  public set sectorCode(value) {
    if (value && !this.form.controls['sectorCode'].value) {
      this.form.controls['sectorCode'].patchValue(value);
    }
  }

  @Input()
  public set directors(value) {
    if (value !== undefined && value !== null && !this.form.controls['directors'].value) {
      this.form.controls['directors'].patchValue(value);
    }
  }

  @Input()
  public set country(value) {
    this._country = value;
    if (value) {
      this.form.controls['sectorCode'].setValidators([Validators.required, COUNTRY_DATA.get(SupportedCountry[value]).sectorCodePatternFn]);
    }
  }

  public get country() {
    return this._country;
  }

  public patchEligibility(eligibility: ReadEligibility) {
    this.patchEligibilityCheck(eligibility.basicEligibilityCheck);
  }

  public submitBasicEligibilityData() {
    this.submitEligibilityData(EligibilityCheckType.BASIC);
  }

  public validateEligibility() {
    this.validateSectorCode();
    this.validateLoamAmountRange();
    this.validateTurnoverAndTotalAssets();
    this.validateAccounts();
    this.validateCompanyStructure();
    this.validateLoanAmount();
    this.validateQuestion('paymentOrDemandEventsQuestion', this.YES);
    this.scrollAfterCheck();
  }

  private scrollAfterCheck() {
    setTimeout(() => {
      if (this.checked) {
        this.store.dispatch(new ScrollTo({target: 'body'}));
      }
    });
  }

  private validateSectorCode() {
    const sectorCode = this.form.controls['sectorCode'].value;
    if (!isSectorCodeValid(sectorCode, this.country)) {
      this.addError('incorrectSector', ['sectorCode']);
    }
  }

  private validateLoamAmountRange() {
    const loanAmount = this.form.controls['loanAmount'].value;
    if (loanAmount < MIN_CAPLE_LOAN || loanAmount > MAX_CAPLE_LOAN) {
      this.addError('loanAmountNotInRange', ['loanAmount']);
    }
  }

  private validateTurnoverAndTotalAssets() {
    const turnoverName = 'turnover';
    const totalAssetsName = 'totalAssets';

    const turnover = this.form.controls[turnoverName].value;
    if (!isTurnoverMinValid(turnover)) {
      this.addError(turnoverName + 'LessThenMin', [turnoverName]);
    }

    const totalAssets = this.form.controls[totalAssetsName].value;
    if (!isTotalAssetsMinValid(totalAssets)) {
      this.addError(totalAssetsName + 'LessThenMin', [totalAssetsName]);
    }

    if (!isTurnoverAndTotalAssetsMaxValid(turnover, totalAssets)) {
      this.addError('turnoverTotalAssetsMoreThenMax', [turnoverName, totalAssetsName]);
    }
  }

  private validateAccounts() {
    const countResult = this.checkQuestion('registeredAccountsCountQuestion', this.YES);
    const expectedCountHidden = this.hiddenControls.includes('registeredAccountsExpectCountQuestion');
    if ((!countResult && expectedCountHidden)
      || (!countResult && !expectedCountHidden && !this.checkQuestion('registeredAccountsExpectCountQuestion', this.YES))) {
      this.addError('registeredAccountsCountQuestion', ['registeredAccountsCountQuestion', 'registeredAccountsExpectCountQuestion']);
    }

    if (!this.hiddenControls.includes('registeredAccountProfitableQuestion')) {
      this.validateQuestion('registeredAccountProfitableQuestion', this.YES);
    }
  }

  private validateCompanyStructure() {
    if (this.form.controls['directors'].value < MIN_DIRECTORS_SHAREHOLDERS
      && this.form.controls['shareholders'].value < MIN_DIRECTORS_SHAREHOLDERS
      && !this.checkQuestion('directorsOrShareholdersExpectedQuestion', this.YES)) {
      this.addError('directorsAndShareholdersLessThenMin', ['directors', 'shareholders', 'directorsOrShareholdersExpectedQuestion']);
    }
    if (this.form.controls['entitiesInGroup'].value > MAX_ENTITIES_IN_GROUP) {
      this.addError('entitiesInGroupMoreThenMax', ['entitiesInGroup']);
    }
  }

  private validateLoanAmount() {
    const loanAmount = Number.parseInt(this.form.controls['loanAmount'].value, 10);
    const currentTotalDebt = Number.parseInt(this.form.controls['currentTotalDebt'].value, 10);
    const capleLoanPercentage = loanAmount / (loanAmount + currentTotalDebt) * 100;
    if (!isCapleLoanPercentageHigh(capleLoanPercentage)) {
      this.notices.push(this._fundingProcessProductType === ProductType.GROWTH ? 'capleLoanPercentageHighGrowth' : 'capleLoanPercentageHighTransaction');
    }
    this.validateQuestion('refinanceExistingDebtQuestion', this.NO);
  }

  private hideControl(controlName: string) {
    if (!this.hiddenControls.includes(controlName)) {
      this.hiddenControls = [...this.hiddenControls, controlName];
      const control = this.form.controls[controlName];
      control.clearValidators();
      control.setValue(null);
      control.markAsUntouched();
    }
  }

  private showControl(controlName: string, additionalValidators?: ValidatorFn[]) {
    const index = this.hiddenControls.indexOf(controlName);
    if (index > -1) {
      this.hiddenControls.splice(index, 1);
      const validators = additionalValidators ? [Validators.required, ...additionalValidators] : Validators.required;
      this.form.controls[controlName].setValidators(validators);
    }

    if (this.loaded && !this.animatedControls.includes(controlName)) {
      this.animatedControls = [...this.animatedControls, controlName];
    }
  }
}
