/**
 * Caple Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TextWithImages } from './textWithImages';


export interface DebtFacilityV2 {
    id?: string;
    lender?: string;
    facilityType?: string;
    repaymentProfile?: string;
    drawnAmount?: number;
    facilityLimit?: number;
    maturityDate?: string;
    interestRate?: number;
    securityType?: string;
    financialAndNonFinancialCovenants?: TextWithImages;
    loanStatus?: DebtFacilityV2.LoanStatusEnum;
    facilityAgreement?: TextWithImages;
}
export namespace DebtFacilityV2 {
    export type LoanStatusEnum = 'CURRENT' | 'PROPOSED';
    export const LoanStatusEnum = {
        CURRENT: 'CURRENT' as LoanStatusEnum,
        PROPOSED: 'PROPOSED' as LoanStatusEnum
    }
}
