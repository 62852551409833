import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CloseModal } from '../../../state-management/layout/actions';
import { Store } from '@ngrx/store';
import { State } from '../../../state-management/reducers';
import { SLICE } from '../../state-management/slice';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UpdateSingle } from '../../../state-management/util/actions';

@Component({
  selector: 'caple-rename-company-dialog',
  templateUrl: './rename-company-dialog.component.html'
})
export class RenameCompanyDialogComponent {

  /**
   * The formGroup for the form
   */
  public form: FormGroup;

  private companyId: string;

  constructor(private formBuilder: FormBuilder, private store: Store<State>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.form = formBuilder.group({
      'newName': [data.companyName, [Validators.required, Validators.minLength(3)]]
    });
    this.companyId = data.companyId;
  }

  public renameCompany() {
    this.form.markAsTouched();
    if (this.form.valid) {
      this.store.dispatch(new UpdateSingle(SLICE.COMPANY, {companyId: this.companyId, newName: this.form.controls['newName'].value}));
    }
  }

  public closeModal() {
    this.store.dispatch(new CloseModal());
  }
}
