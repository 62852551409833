import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ShowToast } from '../../state-management/layout/actions';
import { Effects } from '../../state-management/util/effect-functions';
import { actions } from '../../state-management/util/actions';
import { typeForSlice } from '../../state-management/util/util';
import { State } from '../../state-management/reducers';
import { Store } from '@ngrx/store';
import { SLICE } from './slice';
import { EligibilityService } from '../../shared/api/services/eligibility.service';
import { selectEligibilityIsLoaded } from './selector';

@Injectable()
export class EligibilityEffects {

  @Effect()
  showError$ = this.actions$
    .pipe(
      ofType(...typeForSlice(SLICE, actions.FAIL)),
      switchMap((action: any) => {
        return of(new ShowToast(action.payload));
      }));

  /*************
   * ELIGIBILITY
   *************/

  @Effect()
  loadEligibility$ = this.effects
    .loadSingle(SLICE.ELIGIBILITY, 'urn:eligibility:load', {
      functionToExecute: this.eligibilityService.getById,
      scope: this.eligibilityService
    }, selectEligibilityIsLoaded);

  @Effect()
  createEligibility$ = this.effects
    .createSingle(SLICE.ELIGIBILITY, 'urn:eligibility:save', {
      functionToExecute: this.eligibilityService.saveEligibility,
      scope: this.eligibilityService
    });

  @Effect()
  updateEligibility$ = this.effects
    .updateSingle(SLICE.ELIGIBILITY, 'urn:eligibility:save', {
      functionToExecute: this.eligibilityService.updateEligibility,
      scope: this.eligibilityService
    });

  constructor(private actions$: Actions,
              private store: Store<State>,
              private effects: Effects,
              private eligibilityService: EligibilityService
  ) {
  }

}
