import * as tslib_1 from "tslib";
import { createFeatureSelector } from '@ngrx/store';
import { createForeignTableAdapter, createTableAdapter } from '../../state-management/util/adapters';
import { actions } from '../../state-management/util/actions';
import { typeFor } from '../../state-management/util/util';
import { originationActions } from './actions';
import { SLICE } from './slice';
import { SLICE as KycSLICE } from '../../kyc/state-management/slice';
import { SLICE as DISCUSSION_SLICE, SLICE as DiscussionSLICE } from '../../discussion/state-management/slice';
import { discussionActions } from '../../discussion/state-management/actions';
import { kycActions } from '../../kyc/state-management/actions';
import { KYCStatus } from '../../shared/api/models/kYCStatus';
import { ResourceType } from '../../shared/api/services/discussion.service';
import { UploadCategory } from '../../shared/api/models/uploadCategory';
import { UploadType } from '../../shared/api/models/uploadType';
/********
 * Adapters
 ********/
export var fundingProcessSummaryAdapter = createTableAdapter();
export var fundingProcessAdapter = createTableAdapter();
var ɵ0 = function (snapshot) { return snapshot.fundingProcessId; };
export var fundingProposalSnapshotAdapter = createTableAdapter({
    idSelector: ɵ0
});
var ɵ1 = function (snapshot) { return snapshot.fundingProcessId; };
export var fundingProposalTranslationAdapter = createTableAdapter({
    idSelector: ɵ1
});
export var fundingProposalModeAdapter = createTableAdapter();
var ɵ2 = function (client) { return client.companyNumber; };
export var clientAdapter = createTableAdapter({
    idSelector: ɵ2
});
export var clientUserAdapter = createForeignTableAdapter();
export var financialRatingSummaryAdapter = createForeignTableAdapter();
export var financialRatingAdapter = createTableAdapter();
export var metaModelAdapter = createTableAdapter();
export var metaModelIdentifierAdapter = createTableAdapter();
export var fundingProcessValidationAdapter = createForeignTableAdapter();
var ɵ3 = function (company) { return company.companyNumber; };
export var chamberOfCommerceAdapter = createTableAdapter({
    idSelector: ɵ3
});
var ɵ4 = function (company) { return company.companyNumber; };
export var chamberOfCommerceNameAdapter = createTableAdapter({
    idSelector: ɵ4
});
export var kycStatusAdapter = createTableAdapter();
export var teamAdapter = createTableAdapter();
export var eligibilityAssessmentPrefillAdapter = createTableAdapter();
export var kycAssessmentPrefillAdapter = createTableAdapter();
export var usersAdapter = createForeignTableAdapter();
export var initialState = {
    fundingProcessesSummaries: fundingProcessSummaryAdapter.getInitialState(),
    fundingProcesses: fundingProcessAdapter.getInitialState(),
    fundingProposalSnapshot: fundingProposalSnapshotAdapter.getInitialState(),
    fundingProposalTranslation: fundingProposalTranslationAdapter.getInitialState(),
    fundingProposalMode: fundingProposalModeAdapter.getInitialState(),
    clients: clientAdapter.getInitialState(),
    client_users: clientUserAdapter.getInitialState(),
    financialRatingSummaries: financialRatingSummaryAdapter.getInitialState(),
    financialRatings: financialRatingAdapter.getInitialState(),
    financialRatingMetaModels: metaModelAdapter.getInitialState(),
    fundingProposalMetaModels: metaModelAdapter.getInitialState(),
    fundingProposalMetaModelLatest: metaModelIdentifierAdapter.getInitialState(),
    fundingProcessValidations: fundingProcessValidationAdapter.getInitialState(),
    fundingProcessesFilteredCountries: undefined,
    fundingProcessesFilteredStatuses: undefined,
    fundingProcessesSearchQuery: '',
    chamberOfCommerce: chamberOfCommerceAdapter.getInitialState(),
    chamberOfCommerceName: chamberOfCommerceNameAdapter.getInitialState(),
    kycStatus: kycStatusAdapter.getInitialState(),
    team: teamAdapter.getInitialState(),
    eligibilityAssessmentPrefill: eligibilityAssessmentPrefillAdapter.getInitialState(),
    kycAssessmentPrefill: kycAssessmentPrefillAdapter.getInitialState(),
    users: usersAdapter.getInitialState()
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case typeFor(SLICE.FUNDING_PROCESS_SUMMARY, actions.LOAD_ALL_SUCCESS): {
            return Object.assign({}, state, {
                fundingProcessesSummaries: fundingProcessSummaryAdapter.addPage(action.payload, state.fundingProcessesSummaries)
            });
        }
        case typeFor(SLICE.FUNDING_PROCESS_SUMMARY, actions.SELECT): {
            return Object.assign({}, state, {
                fundingProcessesSummaries: fundingProcessSummaryAdapter.select(action.payload.id, state.fundingProcessesSummaries)
            });
        }
        case typeFor(SLICE.FUNDING_PROCESS_SUMMARY, actions.LOAD_SINGLE_SUCCESS): {
            return Object.assign({}, state, {
                fundingProcessesSummaries: fundingProcessSummaryAdapter.addOne(action.payload, state.fundingProcessesSummaries)
            });
        }
        case typeFor(SLICE.FUNDING_PROCESS, actions.SELECT): {
            return Object.assign({}, state, {
                fundingProcesses: fundingProcessAdapter.select(action.payload.id, state.fundingProcesses)
            });
        }
        case typeFor(SLICE.FUNDING_PROCESS, actions.LOAD_SINGLE_SUCCESS): {
            return Object.assign({}, state, {
                fundingProcesses: fundingProcessAdapter.addOne(action.payload, state.fundingProcesses)
            });
        }
        case typeFor(SLICE.FUNDING_PROCESS, actions.UPDATE_SINGLE_SUCCESS): {
            return Object.assign({}, state, {
                fundingProcesses: fundingProcessAdapter.updateOne(tslib_1.__assign({}, action.payload, { lastModificationTime: new Date().toString() }), state.fundingProcesses)
            }, {
                fundingProcessesSummaries: fundingProcessSummaryAdapter.updateOne(tslib_1.__assign({}, action.payload, { lastModificationTime: new Date().toString() }), state.fundingProcessesSummaries)
            });
        }
        case typeFor(SLICE.FUNDING_PROCESS, originationActions.PIN_FUNDING_PROCESS_SUCCESS): {
            return Object.assign({}, state, {
                fundingProcesses: fundingProcessAdapter.updateOne({
                    id: action.payload.id,
                    pinned: true,
                }, state.fundingProcesses)
            }, {
                fundingProcessesSummaries: fundingProcessSummaryAdapter.updateOne({
                    id: action.payload.id,
                    pinned: true,
                    recentlyChanged: true
                }, state.fundingProcessesSummaries)
            });
        }
        case typeFor(SLICE.FUNDING_PROCESS, originationActions.UNPIN_FUNDING_PROCESS_SUCCESS): {
            return Object.assign({}, state, {
                fundingProcesses: fundingProcessAdapter.updateOne({
                    id: action.payload.id,
                    pinned: false,
                }, state.fundingProcesses)
            }, {
                fundingProcessesSummaries: fundingProcessSummaryAdapter.updateOne(tslib_1.__assign({}, action.payload, { pinned: false, recentlyChanged: true }), state.fundingProcessesSummaries)
            });
        }
        case typeFor(SLICE.FUNDING_PROCESS, originationActions.ASSIGN_FUNDING_PROCESS_SUCCESS): {
            return Object.assign({}, state, {
                fundingProcesses: fundingProcessAdapter.updateOne({
                    id: action.payload.id,
                    assignedCreditOfficer: {
                        userId: action.payload.userId,
                        name: action.payload.name
                    },
                }, state.fundingProcesses)
            });
        }
        case typeFor(SLICE.FUNDING_PROCESS, originationActions.UN_ASSIGN_FUNDING_PROCESS_SUCCESS): {
            return Object.assign({}, state, {
                fundingProcesses: fundingProcessAdapter.updateOne({
                    id: action.payload.id,
                    assignedCreditOfficer: null,
                }, state.fundingProcesses)
            });
        }
        case typeFor(SLICE.FUNDING_PROCESS, originationActions.DISABLE_RECENTLY_CHANGED): {
            return Object.assign({}, state, {
                fundingProcessesSummaries: fundingProcessSummaryAdapter.updateOne({
                    id: action.payload.id,
                    recentlyChanged: false
                }, state.fundingProcessesSummaries)
            });
        }
        case typeFor(SLICE.FUNDING_PROCESS, actions.DELETE_SINGLE_SUCCESS): {
            var request = action.request;
            return Object.assign({}, state, {
                fundingProcesses: fundingProcessAdapter.removeOne(request.id, state.fundingProcesses),
                fundingProcessesSummaries: fundingProcessSummaryAdapter.removeOne(request.id, state.fundingProcessesSummaries)
            });
        }
        case typeFor(DISCUSSION_SLICE.DISCUSSION, discussionActions.START_DISCUSSION_SUCCESS): {
            var discussionAction_1 = action;
            if (discussionAction_1.request.resourceType !== ResourceType.FUNDING_PROPOSAL) {
                return state;
            }
            var fundingProcess = Object.assign({}, state.fundingProcesses.entities[discussionAction_1.request.resourceId]);
            var copyOfUploads = JSON.parse(JSON.stringify(fundingProcess.fundingProposal.uploads));
            var fileToUpdate = getAllUploadedFiles(fundingProcess.fundingProposalVersion, copyOfUploads)
                .find(function (file) { return file.fileId === discussionAction_1.request.fileId; });
            fileToUpdate.discussionId = discussionAction_1.payload.id;
            var updatedFundingProposal = Object.assign({}, fundingProcess.fundingProposal, { uploads: copyOfUploads });
            var updatedFundingProcess = Object.assign({}, fundingProcess, { fundingProposal: updatedFundingProposal });
            return Object.assign({}, state, {
                fundingProcesses: fundingProcessAdapter.updateOne(tslib_1.__assign({}, updatedFundingProcess), state.fundingProcesses)
            });
        }
        case typeFor(SLICE.FUNDING_PROPOSAL_SNAPSHOT, actions.LOAD_SINGLE_SUCCESS): {
            var request = action.request;
            return Object.assign({}, state, {
                fundingProposalSnapshot: fundingProposalSnapshotAdapter.addOne(action.payload, state.fundingProposalSnapshot)
            });
        }
        case typeFor(SLICE.FUNDING_PROPOSAL_TRANSLATION, actions.LOAD_SINGLE_SUCCESS): {
            var request = action.request;
            return Object.assign({}, state, {
                fundingProposalTranslation: fundingProposalTranslationAdapter.addOne(action.payload, state.fundingProposalTranslation)
            });
        }
        case typeFor(SLICE.FUNDING_PROPOSAL, originationActions.UPDATE_FUNDING_PROPOSAL_MODE): {
            var payload = action.payload;
            return Object.assign({}, state, {
                fundingProposalMode: fundingProposalModeAdapter.addOne(payload, state.fundingProposalMode)
            });
        }
        case typeFor(SLICE.CLIENTS, actions.LOAD_ALL_SUCCESS): {
            return Object.assign({}, state, {
                clients: clientAdapter.addAll(action.payload, clientAdapter.getInitialState())
            });
        }
        case typeFor(SLICE.CLIENTS_USERS, actions.LOAD_SINGLE_SUCCESS): {
            var companyNumber = action.request.id;
            return Object.assign({}, state, {
                client_users: clientUserAdapter.addAll(action.payload, companyNumber, state.client_users)
            });
        }
        case typeFor(SLICE.FINANCIAL_RATING_SUMMARY, actions.LOAD_SINGLE_SUCCESS): {
            var fundingProcessId = action.request.id;
            return Object.assign({}, state, {
                financialRatingSummaries: financialRatingSummaryAdapter.addAll(action.payload, fundingProcessId, state.financialRatingSummaries)
            });
        }
        case typeFor(SLICE.FINANCIAL_RATING, actions.LOAD_SINGLE_SUCCESS): {
            return Object.assign({}, state, {
                financialRatings: financialRatingAdapter.addOne(action.payload, state.financialRatings)
            });
        }
        case typeFor(SLICE.FINANCIAL_RATING_META_MODEL, actions.LOAD_SINGLE_SUCCESS): {
            var fundingProcessId = action.request.id;
            return Object.assign({}, state, {
                financialRatingMetaModels: metaModelAdapter.addOneWithId(action.payload, fundingProcessId, state.financialRatingMetaModels)
            });
        }
        case typeFor(SLICE.FINANCIAL_RATING, actions.CREATE_SINGLE_SUCCESS): {
            var createSuccessAction = action;
            var summary = tslib_1.__assign({}, createSuccessAction.payload, createSuccessAction.request.financialRating);
            summary.product = createSuccessAction.request.product;
            summary.editable = true;
            summary.scoringModelVersion = 2;
            return Object.assign({}, state, {
                financialRatingSummaries: financialRatingSummaryAdapter.addOne(summary, createSuccessAction.request.fundingProcessId, state.financialRatingSummaries)
            });
        }
        case typeFor(SLICE.FINANCIAL_RATING, actions.DELETE_SINGLE_SUCCESS): {
            var ids = action.request;
            return Object.assign({}, state, {
                financialRatings: financialRatingAdapter.removeOne(ids.id, state.financialRatings),
                financialRatingSummaries: financialRatingSummaryAdapter.removeOneById(ids.id, ids.fundingProcessId, state.financialRatingSummaries)
            });
        }
        case typeFor(SLICE.FINANCIAL_RATING, actions.UPDATE_SINGLE_SUCCESS): {
            var request = action.request;
            var financialRating = Object.assign({}, action.payload, {
                financials: request.financialRating.financials,
                editable: true
            });
            return Object.assign({}, state, {
                financialRatings: financialRatingAdapter.updateOne(financialRating, state.financialRatings),
                financialRatingSummaries: financialRatingSummaryAdapter.updateOne(tslib_1.__assign({}, action.payload), request.fundingProcessId, state.financialRatingSummaries)
            });
        }
        case typeFor(SLICE.FUNDING_PROPOSAL_META_MODEL, actions.LOAD_SINGLE_SUCCESS): {
            var fundingProcessId = action.request.id;
            return Object.assign({}, state, {
                fundingProposalMetaModels: metaModelAdapter.addOneWithId(action.payload, fundingProcessId, state.fundingProposalMetaModels)
            });
        }
        case typeFor(SLICE.FUNDING_PROPOSAL_META_MODEL_LATEST, actions.LOAD_SINGLE_SUCCESS): {
            var fundingProcessId = action.request.id;
            return Object.assign({}, state, {
                fundingProposalMetaModelLatest: metaModelIdentifierAdapter.addOneWithId(action.payload, fundingProcessId, state.fundingProposalMetaModelLatest)
            });
        }
        case typeFor(SLICE.FUNDING_PROPOSAL, originationActions.VALIDATION_CHANGED): {
            return Object.assign({}, state, {
                fundingProcessValidations: fundingProcessValidationAdapter.addAll(action.payload.status, action.payload.id, state.fundingProcessValidations)
            });
        }
        case typeFor(SLICE.FUNDING_PROPOSAL, actions.UPDATE_SINGLE_SUCCESS): {
            var request = action.request;
            var fundingProcessId = request.id;
            var fundingProposal = request.fundingProposal;
            var oldFundingProcess = state.fundingProcesses.entities[fundingProcessId];
            var copyOfUploads_1 = {};
            if (request.version === 1 || request.version === 2) {
                copyOfUploads_1 = JSON.parse(JSON.stringify(fundingProposal.uploads));
                // We accept the 99% accuracy of the upload date over loading the proposal after each save to get the actual upload time the backend has
                Object.keys(copyOfUploads_1).forEach(function (fileListName) {
                    copyOfUploads_1[fileListName]
                        .filter(function (file) { return !file.uploadedTime; })
                        .forEach(function (file) { return file.uploadedTime = new Date().toUTCString(); });
                });
            }
            else {
                console.warn("TODO: Version " + request.version + " uploads disabled in reducer update_single_success");
            }
            return Object.assign({}, state, {
                fundingProcesses: fundingProcessAdapter.updateOne(tslib_1.__assign({}, oldFundingProcess, { fundingProposal: Object.assign({}, oldFundingProcess.fundingProposal, fundingProposal, { uploads: copyOfUploads_1 }) }), state.fundingProcesses)
            });
        }
        case typeFor(SLICE.FUNDING_PROCESS_SUMMARY, originationActions.UPDATE_FUNDING_PROCESS_FILTERED_COUNTRIES): {
            var payload = action.payload;
            var newFilter = buildNewFilter(state.fundingProcessesFilteredCountries, payload.isChecked, payload.country);
            return Object.assign({}, state, { fundingProcessesFilteredCountries: newFilter });
        }
        case typeFor(SLICE.FUNDING_PROCESS_SUMMARY, originationActions.UPDATE_FUNDING_PROCESS_FILTERED_STATUSES): {
            var payload = action.payload;
            var newFilter = buildNewFilter(state.fundingProcessesFilteredStatuses, payload.isChecked, payload.status);
            return Object.assign({}, state, { fundingProcessesFilteredStatuses: newFilter });
        }
        case typeFor(SLICE.FUNDING_PROCESS_SUMMARY, originationActions.UPDATE_FUNDING_PROCESS_SEARCH_QUERY): {
            var payload = action.payload;
            return Object.assign({}, state, { fundingProcessesSearchQuery: payload.searchQuery });
        }
        case typeFor(SLICE.ELIGIBILITY, originationActions.ELIGIBILITY_CHECK_CONNECT_SUCCESS): {
            var payload = action.payload;
            for (var _i = 0, _a = Object.keys(state.fundingProcesses.entities); _i < _a.length; _i++) {
                var key = _a[_i];
                var fp = state.fundingProcesses.entities[key];
                if (fp.id === payload.connectEligibility.fundingProcessId && !fp.eligibilityId) {
                    var updatedFundingProcess = Object.assign({}, fp);
                    updatedFundingProcess.eligibilityId = payload.id;
                    return Object.assign({}, state, { fundingProcesses: fundingProcessAdapter.updateOne(tslib_1.__assign({}, updatedFundingProcess), state.fundingProcesses) });
                }
            }
            return state;
        }
        case typeFor(KycSLICE.KYC_FILE, kycActions.COMPLETE_KYC_SUCCESS): {
            var kycFileId = action.payload;
            for (var _b = 0, _c = Object.keys(state.fundingProcessesSummaries.entities); _b < _c.length; _b++) {
                var key = _c[_b];
                var fundingProcessSummary = state.fundingProcessesSummaries.entities[key];
                if (fundingProcessSummary.kycFileId === kycFileId.id) {
                    var updatedFundingProcess = Object.assign({}, state.fundingProcesses.entities[key], { kycStatus: KYCStatus.COMPLETED });
                    var updatedFundingProcessSummary = Object.assign({}, fundingProcessSummary, { kycStatus: KYCStatus.COMPLETED });
                    state = Object.assign({}, state, {
                        fundingProcesses: fundingProcessAdapter.updateOne(tslib_1.__assign({}, updatedFundingProcess), state.fundingProcesses),
                        fundingProcessesSummaries: fundingProcessSummaryAdapter.updateOne(tslib_1.__assign({}, updatedFundingProcessSummary), state.fundingProcessesSummaries)
                    });
                }
            }
            return state;
        }
        case typeFor(SLICE.CHAMBER_OF_COMMERCE, actions.LOAD_SINGLE_SUCCESS): {
            if (!action.payload.result) {
                return Object.assign({}, state, {
                    chamberOfCommerce: chamberOfCommerceAdapter.addOne({ companyNumber: action.request.id }, state.chamberOfCommerce)
                });
            }
            return Object.assign({}, state, {
                chamberOfCommerce: chamberOfCommerceAdapter.addOne(action.payload.result, state.chamberOfCommerce)
            });
        }
        case typeFor(SLICE.CHAMBER_OF_COMMERCE_NAME, actions.LOAD_SINGLE_SUCCESS): {
            return Object.assign({}, state, {
                chamberOfCommerceName: chamberOfCommerceNameAdapter.addOne(action.payload, state.chamberOfCommerceName)
            });
        }
        case typeFor(SLICE.COMMUNICATION_FILES, originationActions.ATTACH_COMMUNICATION_FILE_SUCCESS): {
            var attachFile = action.payload;
            var fundingProcess = Object.assign({}, state.fundingProcesses.entities[attachFile.id]);
            var file = {
                fileId: attachFile.fileDetails.fileId,
                name: attachFile.fileDetails.name,
                uploadedTime: new Date()
            };
            fundingProcess.assessment = Object.assign({}, fundingProcess.assessment, { communicationFiles: [file].concat(fundingProcess.assessment.communicationFiles) });
            return Object.assign({}, state, {
                fundingProcesses: fundingProcessAdapter.updateOne(tslib_1.__assign({}, fundingProcess), state.fundingProcesses)
            });
        }
        case typeFor(SLICE.COMMUNICATION_FILES, originationActions.REMOVE_COMMUNICATION_FILE_SUCCESS): {
            var removeFile_1 = action.payload;
            var fundingProcess = Object.assign({}, state.fundingProcesses.entities[removeFile_1.id]);
            fundingProcess.assessment = Object.assign({}, fundingProcess.assessment, { communicationFiles: fundingProcess.assessment.communicationFiles.filter(function (value) { return value.fileId !== removeFile_1.fileId; }) });
            return Object.assign({}, state, {
                fundingProcesses: fundingProcessAdapter.updateOne(tslib_1.__assign({}, fundingProcess), state.fundingProcesses)
            });
        }
        case typeFor(SLICE.CREDIT_ASSESSMENT, actions.UPDATE_SINGLE_SUCCESS): {
            var updateAction = action;
            var fundingProcess = state.fundingProcesses.entities[updateAction.request.id];
            var updatedFundingProcess = Object.assign({}, fundingProcess, {
                assessment: Object.assign({}, fundingProcess.assessment, { creditAssessment: fundingProcess.assessment ? fundingProcess.assessment.creditAssessment : {} }, { creditAssessment: updateAction.request })
            });
            return Object.assign({}, state, {
                fundingProcesses: fundingProcessAdapter.updateOne(updatedFundingProcess, state.fundingProcesses)
            });
        }
        case typeFor(SLICE.ELIGIBILITY_ASSESSMENT, actions.UPDATE_SINGLE_SUCCESS): {
            var updateAction = action;
            var fundingProcess = state.fundingProcesses.entities[updateAction.request.id];
            var updatedFundingProcess = Object.assign({}, fundingProcess, {
                assessment: Object.assign({}, fundingProcess.assessment, { eligibility: fundingProcess.assessment ? fundingProcess.assessment.eligibility : {} }, { eligibility: updateAction.request })
            });
            return Object.assign({}, state, {
                fundingProcesses: fundingProcessAdapter.updateOne(updatedFundingProcess, state.fundingProcesses)
            });
        }
        case typeFor(SLICE.ELIGIBILITY_ASSESSMENT_PREFILL, actions.LOAD_SINGLE_SUCCESS): {
            var fundingProcessId = action.request.id;
            return Object.assign({}, state, {
                eligibilityAssessmentPrefill: eligibilityAssessmentPrefillAdapter.addOne(tslib_1.__assign({}, action.payload, { id: fundingProcessId }), state.eligibilityAssessmentPrefill)
            });
        }
        case typeFor(SLICE.KYC_ASSESSMENT_PREFILL, actions.LOAD_SINGLE_SUCCESS): {
            var fundingProcessId = action.request.id;
            return Object.assign({}, state, {
                kycAssessmentPrefill: kycAssessmentPrefillAdapter.addOne(tslib_1.__assign({}, action.payload, { id: fundingProcessId }), state.kycAssessmentPrefill)
            });
        }
        case typeFor(SLICE.KYC_ASSESSMENT, actions.UPDATE_SINGLE_SUCCESS): {
            var updateAction = action;
            var fundingProcess = Object.assign({}, state.fundingProcesses.entities[updateAction.request.id]);
            var updatedFundingProcess = Object.assign({}, fundingProcess, {
                assessment: Object.assign({}, fundingProcess.assessment, { kyc: fundingProcess.assessment ? fundingProcess.assessment.eligibility : {} }, { kyc: updateAction.request })
            });
            return Object.assign({}, state, {
                fundingProcesses: fundingProcessAdapter.updateOne(updatedFundingProcess, state.fundingProcesses)
            });
        }
        case typeFor(SLICE.KYC_STATUS, actions.LOAD_SINGLE_SUCCESS): {
            return Object.assign({}, state, {
                kycStatus: kycStatusAdapter.addOne(action.payload, state.kycStatus)
            });
        }
        case typeFor(DiscussionSLICE.DISCUSSION, discussionActions.POST_COMMENT_SUCCESS): {
            var payload = action.payload;
            if (payload.resourceType === ResourceType.KYC) {
                // See comment below where we the same remove is done
                return Object.assign({}, state, {
                    kycStatus: kycStatusAdapter.removeOne(payload.resourceId, state.kycStatus)
                });
            }
            else {
                return state;
            }
        }
        case typeFor(SLICE.TEAM, originationActions.LOAD_SINGLE_SUCCESS): {
            var loadAction = action;
            return Object.assign({}, state, {
                team: teamAdapter.addOne(tslib_1.__assign({ id: loadAction.request.id }, loadAction.payload), state.team)
            });
        }
        case typeFor(SLICE.FUNDING_PROPOSAL, originationActions.UPDATE_UPLOADS_CATEGORIES): {
            var payload = action.payload;
            var fundingProcess = Object.assign({}, state.fundingProcesses.entities[payload.id]);
            var otherUploads = fundingProcess.fundingProposalVersion === 1 ? fundingProcess.fundingProposal.uploads.other : fundingProcess.fundingProposal.uploads[UploadType.OTHER];
            var copyOfOtherFiles_1 = JSON.parse(JSON.stringify(otherUploads));
            payload.files.forEach(function (value) {
                var fileToUpdate = copyOfOtherFiles_1.find(function (otherFile) { return otherFile.fileId === value.fileId; });
                fileToUpdate.category = value.category;
            });
            var updatedFundingProcess = deepmerge(fundingProcess, getChangedOtherUploads(fundingProcess.fundingProposalVersion, copyOfOtherFiles_1), { arrayMerge: overwriteMerge });
            return Object.assign({}, state, {
                fundingProcesses: fundingProcessAdapter.updateOne(tslib_1.__assign({}, updatedFundingProcess), state.fundingProcesses)
            });
        }
        case typeFor(SLICE.FUNDING_PROPOSAL, originationActions.REVIEW_UPLOAD_SUCCESS): {
            var payload_1 = action.payload;
            var fundingProcess = Object.assign({}, state.fundingProcesses.entities[payload_1.id]);
            var copyOfUploads = JSON.parse(JSON.stringify(fundingProcess.fundingProposal.uploads));
            var fileToUpdate = getAllUploadedFiles(fundingProcess.fundingProposalVersion, copyOfUploads)
                .find(function (file) { return file.fileId === payload_1.fileId; });
            fileToUpdate.reviewStatus = payload_1.reviewStatus;
            var updatedFundingProposal = Object.assign({}, fundingProcess.fundingProposal, { uploads: copyOfUploads });
            var updatedFundingProcess = Object.assign({}, fundingProcess, { fundingProposal: updatedFundingProposal });
            return Object.assign({}, state, {
                fundingProcesses: fundingProcessAdapter.updateOne(tslib_1.__assign({}, updatedFundingProcess), state.fundingProcesses)
            });
        }
        case typeFor(SLICE.FUNDING_PROPOSAL, originationActions.MOVE_UPLOAD): {
            var payload = action.payload;
            return moveFile(state, payload);
        }
        case typeFor(SLICE.FUNDING_PROPOSAL, originationActions.MOVE_UPLOAD_FAILURE): {
            var payload = action.payload;
            return moveFile(state, payload);
        }
        case typeFor(SLICE.COMPANY_USERS, actions.LOAD_SINGLE_SUCCESS): {
            var companyId = action.request.id;
            return Object.assign({}, state, {
                users: usersAdapter.addAll(action.payload, companyId, state.users)
            });
        }
    }
    if (action.slice === KycSLICE.KYC_FILE && !action.type.includes('LOAD')) {
        /*
          We remove not an entry from the list but the entire status object (which contains our change list)
          As to make sure it get's reloaded from the server when the user view it again.
         */
        return Object.assign({}, state, {
            kycStatus: kycStatusAdapter.removeOne(action.payload.id, state.kycStatus)
        });
    }
    return state;
}
function moveFile(state, payload) {
    var readOnlyFundingProcess = state.fundingProcesses.entities[payload.id];
    var uploads = JSON.parse(JSON.stringify(readOnlyFundingProcess.fundingProposal.uploads));
    var fileToMove = Object.assign({}, getAllUploadedFiles(readOnlyFundingProcess.fundingProposalVersion, uploads)
        .find(function (file) { return file.fileId === payload.fileId; }));
    var processChanges = {
        fundingProposal: {
            uploads: {}
        }
    };
    var headingToMoveFrom = readOnlyFundingProcess.fundingProposalVersion === 1 ? getUploadFieldNameByHeading(uploads, payload.from) : payload.from;
    var headingToMoveTo = readOnlyFundingProcess.fundingProposalVersion === 1 ? getUploadFieldNameByHeading(uploads, payload.to) : payload.to;
    processChanges.fundingProposal.uploads[headingToMoveFrom] = uploads[headingToMoveFrom].filter(function (entry) { return entry.fileId !== payload.fileId; });
    processChanges.fundingProposal.uploads[headingToMoveTo] = Object.assign([], uploads[headingToMoveTo]);
    processChanges.fundingProposal.uploads[headingToMoveTo].push(fileToMove);
    if (payload.to.toUpperCase() === UploadCategory.OTHER.toString()) {
        fileToMove.category = UploadCategory.OTHER;
    }
    var updatedFundingProcess = deepmerge(readOnlyFundingProcess, processChanges, { arrayMerge: overwriteMerge });
    return Object.assign({}, state, {
        fundingProcesses: fundingProcessAdapter.updateOne(tslib_1.__assign({}, updatedFundingProcess), state.fundingProcesses)
    });
}
function getUploadFieldNameByHeading(uploads, heading) {
    switch (heading) {
        case UploadType.STATUTORYACCOUNTS:
            return 'statutoryAccounts';
        case UploadType.AGEDDEBTORREPORT:
            return 'agedDebtorReport';
        case UploadType.AGEDCREDITORREPORT:
            return 'agedCreditorReport';
        case UploadType.BANKSTATEMENT:
            return 'bankStatement';
        case UploadType.ASSETANDLIABILITYSTATEMENTS:
            return 'assetAndLiabilityStatements';
        case UploadType.SECURITYDOCUMENTATION:
            return 'securityDocumentation';
        case UploadType.ORGANISATIONSTRUCTURE:
            return 'organisationStructure';
        case UploadType.LIMITATIONOFLIABILITY:
            return 'limitationOfLiability';
        case UploadType.ENGAGEMENTLETTER:
            return 'engagementLetter';
        case UploadType.ESGQUESTIONNAIRE:
            return 'esgQuestionnaire';
        case UploadType.MATERIALCOMMERCIALAGREEMENTS:
            return 'materialCommercialAgreements';
        case UploadType.MANAGEMENTSYSTEMACCREDITATION:
            return 'managementSystemAccreditation';
        case UploadType.OTHER:
            return 'other';
        default:
            throw new Error("Unknown upload heading " + heading + " failed to find in uploads");
    }
}
function buildNewFilter(state, isChecked, changedValue) {
    var newFilter = Object.assign([], state);
    if (isChecked) {
        if (!newFilter.includes(changedValue)) {
            newFilter.push(changedValue);
        }
    }
    else {
        newFilter = newFilter.filter(function (i) { return i !== changedValue; });
    }
    return newFilter;
}
function getAllUploadedFiles(fundingProposalVersion, uploads) {
    switch (fundingProposalVersion) {
        case 1:
            return uploads.agedCreditorReport.concat(uploads.agedDebtorReport, uploads.bankStatement, uploads.engagementLetter, uploads.esgQuestionnaire, uploads.limitationOfLiability, uploads.organisationStructure, uploads.other, uploads.statutoryAccounts);
        case 2:
            return Object.keys(uploads)
                .map(function (uploadKey) { return uploads[uploadKey]; })
                .reduce(function (previousValue, currentValue) { return previousValue.concat(currentValue); });
        default:
            throw new Error("Unknown funding proposal version " + fundingProposalVersion);
    }
}
function getChangedOtherUploads(fundingProposalVersion, newOtherUploads) {
    var _a;
    switch (fundingProposalVersion) {
        case 1:
            return {
                fundingProposal: {
                    uploads: {
                        other: newOtherUploads
                    }
                }
            };
        case 2:
            return {
                fundingProposal: {
                    uploads: (_a = {},
                        _a[UploadType.OTHER] = newOtherUploads,
                        _a)
                }
            };
        default:
            throw new Error("Unknown funding proposal version " + fundingProposalVersion);
    }
}
var overwriteMerge = function (destinationArray, sourceArray, options) { return sourceArray; };
var ɵ5 = overwriteMerge;
export var selectFeature = createFeatureSelector('origination');
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
