import * as tslib_1 from "tslib";
import { EMPTY, of, zip } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap, } from 'rxjs/operators';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { LayoutActions, MarkChangelogAsReadSuccess, ModalClosed, ModalOpened, ProgressIndicatorClosed, ProgressIndicatorOpened } from './actions';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { ApiError } from '../../shared/api/api-error';
import { TranslateService } from '@ngx-translate/core';
import { ProgressIndicatorDialogComponent } from '../../shared/layout/progress-indicator-dialog/progress-indicator-dialog.component';
import { SLICE } from './slice';
import { Effects } from '../util/effect-functions';
import { AnnouncementService } from '../../shared/api/services/announcement.service';
import { typeFor } from '../util/util';
import { Failure } from '../util/actions';
import { DoNothing } from '../generic/actions';
import { ChangelogService } from '../../shared/api/services/changelog.service';
import { PageScrollService } from 'ngx-page-scroll-core';
var LayoutEffects = /** @class */ (function () {
    function LayoutEffects(actions$, effects, dialog, store, snackBar, translateService, pageScrollService, announcementService, changelogService, document) {
        var _this = this;
        this.actions$ = actions$;
        this.effects = effects;
        this.dialog = dialog;
        this.store = store;
        this.snackBar = snackBar;
        this.translateService = translateService;
        this.pageScrollService = pageScrollService;
        this.announcementService = announcementService;
        this.changelogService = changelogService;
        this.document = document;
        this.openModal$ = this.actions$
            .pipe(ofType(LayoutActions.OPEN_MODAL), map(function (action) {
            _this.modalReference = _this.dialog.open(action.payload, Object.assign({}, action.config, {
                data: action.data
            }));
            return new ModalOpened();
        }));
        this.closeModal$ = this.actions$
            .pipe(ofType(LayoutActions.CLOSE_MODAL), switchMap(function (action) {
            if (_this.modalReference) {
                _this.modalReference.close();
                _this.modalReference = null;
                return of(new ModalClosed());
            }
            else {
                return EMPTY;
            }
        }));
        this.disableClose$ = this.actions$
            .pipe(ofType(LayoutActions.MODAL_DISABLE_CLOSE), switchMap(function (action) {
            if (_this.modalReference) {
                _this.modalReference.disableClose = true;
            }
            return of();
        }));
        this.enableClose$ = this.actions$
            .pipe(ofType(LayoutActions.MODAL_ENABLE_CLOSE), switchMap(function (action) {
            if (_this.modalReference) {
                _this.modalReference.disableClose = false;
            }
            return of();
        }));
        this.openProgressIndicator$ = this.actions$
            .pipe(ofType(LayoutActions.SHOW_PROGRESS_INDICATOR), switchMap(function (action) {
            _this.progressReference = _this.dialog.open(ProgressIndicatorDialogComponent, {
                disableClose: true,
                closeOnNavigation: false,
                panelClass: 'transparent-panel',
                data: { translationKey: action.translationKey, translationData: action.translationData },
                backdropClass: 'progress-backdrop'
            });
            return of(new ProgressIndicatorOpened());
        }));
        this.hideProgressIndicator$ = this.actions$
            .pipe(ofType(LayoutActions.HIDE_PROGRESS_INDICATOR), switchMap(function (action) {
            if (_this.progressReference) {
                _this.progressReference.close();
                return of(new ProgressIndicatorClosed());
            }
            else {
                return EMPTY;
            }
        }));
        this.scrollTo$ = this.actions$
            .pipe(ofType(LayoutActions.SCROLL_TO), tap(function (action) {
            var service = _this.pageScrollService;
            var doc = _this.document;
            // This is wrapped inside a timeout because the scrolling should happen
            // after all elements on the page are loaded / validation statuses verified.
            // Wrapping something in a timeout forces it to happen after the current
            // 'digest' cycle so after everything is loaded / verified.
            setTimeout(function () {
                service.scroll({
                    document: doc,
                    scrollTarget: action.payload.target,
                    duration: 100,
                    scrollOffset: action.payload.offset || 0,
                    scrollViews: action.payload.document ? [action.payload.document] : []
                });
            }, 1);
        }));
        /**
         * Arbitrary number that will keep the Snackbar open for such a long time that it
         * looks like its infinite. Number.MAX_VALUE isn't used here because somehow when
         * using that value the toast closes immediately.
         *
         * Current value is approximately 30 years.
         */
        this.KEEP_OPEN_FOREVER = 1000000000;
        this.showToast$ = this.actions$
            .pipe(ofType(LayoutActions.SHOW_TOAST), tap(function (action) {
            var messageToTranslate;
            var interpolateParams;
            if (action.message instanceof ApiError) {
                messageToTranslate = action.message.code;
                interpolateParams = tslib_1.__assign({}, action.message.values, action.interpolateParams);
            }
            else {
                messageToTranslate = action.message;
                interpolateParams = action.interpolateParams;
            }
            zip(_this.translateService.get(messageToTranslate, interpolateParams), _this.translateService.get('generic.close')).pipe(take(1))
                .subscribe(function (_a) {
                var translatedMessage = _a[0], translatedCloseButton = _a[1];
                // The long duration is there to fix a bug where the snackbar will close
                // if you call the `open` method while a snackbar is already open. Setting the `duration`
                // somehow fixes this.
                _this.snackBar.open(translatedMessage, translatedCloseButton, { duration: _this.KEEP_OPEN_FOREVER });
            });
        }));
        this.loadAnnouncement$ = this.effects
            .loadSingleWithoutID(SLICE.ANNOUNCEMENT, 'urn:announcement:load', {
            functionToExecute: this.announcementService.getAnnouncement,
            scope: this.announcementService
        });
        /*************
         * Changelog
         *************/
        this.loadChangelog$ = this.effects
            .loadSingleWithoutID(SLICE.CHANGELOG, 'urn:changelog:load', {
            functionToExecute: this.changelogService.getAll,
            scope: this.changelogService
        });
        this.markAsRead$ = this.actions$
            .pipe(ofType(typeFor(SLICE.CHANGELOG, LayoutActions.MARK_CHANGELOG_AS_READ)), mergeMap(function (action) {
            return _this.effects.executeServiceCallWithLoadingIndicator(SLICE.CHANGELOG, action, 'ur:changelog:mark-as-read', {
                functionToExecute: _this.changelogService.markAsRead,
                scope: _this.changelogService
            }).pipe(map(function (executionResult) {
                if (executionResult instanceof Failure) {
                    return new DoNothing(); // If this action fails, we don't want to do anything
                }
                return new MarkChangelogAsReadSuccess();
            }), catchError(function (_) { return of(new DoNothing()); }));
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], LayoutEffects.prototype, "openModal$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], LayoutEffects.prototype, "closeModal$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], LayoutEffects.prototype, "disableClose$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], LayoutEffects.prototype, "enableClose$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], LayoutEffects.prototype, "openProgressIndicator$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], LayoutEffects.prototype, "hideProgressIndicator$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], LayoutEffects.prototype, "scrollTo$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], LayoutEffects.prototype, "showToast$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], LayoutEffects.prototype, "loadAnnouncement$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], LayoutEffects.prototype, "loadChangelog$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], LayoutEffects.prototype, "markAsRead$", void 0);
    return LayoutEffects;
}());
export { LayoutEffects };
