import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'caple-full-page-message',
  templateUrl: './full-page-message.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FullPageMessageComponent {

}
