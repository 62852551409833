import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { RepeatableItem } from '../../../../../shared/layout/repeatable-form-item/repeatable-item';
import { CapleFormBuilder } from '../../../../../shared/util/caple-form-builder';
var ShareholderItemComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ShareholderItemComponent, _super);
    function ShareholderItemComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.removed = new EventEmitter();
        return _this;
    }
    ShareholderItemComponent.produceFormGroup = function (val) {
        val = val || {};
        return new CapleFormBuilder().group({
            'firstName': [val.firstName, Validators.required],
            'lastName': [val.lastName, Validators.required],
            'percentageOwned': [val.percentageOwned, Validators.required],
            'description': [val.description, Validators.required],
        });
    };
    ShareholderItemComponent.prototype.produceFormGroup = function (val) {
        return ShareholderItemComponent.produceFormGroup(val);
    };
    ShareholderItemComponent.prototype.ngOnInit = function () {
        this.openOnStart(this.expansionPanel);
    };
    return ShareholderItemComponent;
}(RepeatableItem));
export { ShareholderItemComponent };
