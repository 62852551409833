import * as tslib_1 from "tslib";
import { compare, typeFor } from '../../state-management/util/util';
import { createForeignTableAdapter, createTableAdapter } from '../../state-management/util/adapters';
import { SLICE } from './slice';
import { administrationActions, administrationActions as actions } from './actions';
var ɵ0 = function (a, b) {
    return compare(a.name, b.name);
};
/********
 * Adapters
 ********/
export var companiesAdapter = createTableAdapter({
    sort: ɵ0
});
export var usersAdapter = createForeignTableAdapter();
var ɵ1 = function (any) { return any.companyNumber; };
export var clientsAdapter = createForeignTableAdapter({
    idSelector: ɵ1
});
export var initialState = {
    companies: companiesAdapter.getInitialState(),
    users: usersAdapter.getInitialState(),
    clients: clientsAdapter.getInitialState(),
    companyFilteredCountries: [],
    companySearchQuery: ''
};
/********
 * Reducer
 ********/
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case typeFor(SLICE.COMPANY, actions.LOAD_ALL_SUCCESS): {
            return Object.assign({}, state, {
                companies: companiesAdapter.addAll(action.payload, state.companies)
            });
        }
        case typeFor(SLICE.COMPANY, actions.LOAD_SINGLE_SUCCESS): {
            return Object.assign({}, state, {
                companies: companiesAdapter.addOne(action.payload, state.companies)
            });
        }
        case typeFor(SLICE.COMPANY, actions.CREATE_SINGLE_SUCCESS): {
            var successAction = action;
            return Object.assign({}, state, {
                companies: companiesAdapter.addOne(tslib_1.__assign({}, successAction.payload, successAction.request), state.companies)
            });
        }
        case typeFor(SLICE.COMPANY, actions.UPDATE_SINGLE_SUCCESS): {
            return Object.assign({}, state, {
                companies: companiesAdapter.updateOne({ id: action.payload.companyId, name: action.payload.newName }, state.companies)
            });
        }
        case typeFor(SLICE.USER, actions.LOAD_SINGLE_SUCCESS): {
            var companyId = action.request.id;
            return Object.assign({}, state, {
                users: usersAdapter.addAll(action.payload, companyId, state.users)
            });
        }
        case typeFor(SLICE.USER, actions.UPDATE_SINGLE_SUCCESS): {
            var request = action.request;
            return Object.assign({}, state, {
                users: usersAdapter.updateOne(action.payload, request.companyId, state.users)
            });
        }
        case typeFor(SLICE.USER, actions.BLOCK_USER_SUCCESS): {
            var request = action.payload.payload;
            var user = Object.assign({}, state.users.entities[request.companyId].entities[request.id]);
            user.blocked = true;
            return Object.assign({}, state, {
                users: usersAdapter.updateOne(user, request.companyId, state.users)
            });
        }
        case typeFor(SLICE.USER, actions.UNBLOCK_USER_SUCCESS): {
            var request = action.payload.payload;
            var user = Object.assign({}, state.users.entities[request.companyId].entities[request.id]);
            user.blocked = false;
            return Object.assign({}, state, {
                users: usersAdapter.updateOne(user, request.companyId, state.users)
            });
        }
        case typeFor(SLICE.CLIENT, actions.LOAD_SINGLE_SUCCESS): {
            var companyId = action.request.id;
            return Object.assign({}, state, {
                clients: clientsAdapter.addAll(action.payload, companyId, state.clients)
            });
        }
        case typeFor(SLICE.CLIENT, actions.PROMOTE_TO_COMPANY_SUCCESS): {
            var partnerCompanyId_1 = action.request.payload.companyId;
            var clientCompanyNumber_1 = action.request.payload.companyNumber;
            var clientCompanyId_1 = action.payload.id;
            var clientState_1 = state.clients;
            // Iterate over all companies -> clients in order to mark them as connected to a company
            state.clients.ids.forEach(function (companyId) {
                state.clients.entities[companyId].ids.forEach(function (clientId) {
                    var client = state.clients.entities[companyId].entities[clientId];
                    // We have 2 scenario's here:
                    // 1) this is the actual client that got connected to a company
                    // 2) this is a client with the same company number but not the client that got connected to a company
                    if (client.companyNumber === clientCompanyNumber_1 && companyId === partnerCompanyId_1) { // 1
                        clientState_1 = updateClient(client, clientCompanyId_1, companyId, clientState_1);
                    }
                    else if (client.companyNumber === clientCompanyNumber_1) { // 2
                        clientState_1 = updateClient(client, undefined, companyId, clientState_1);
                    }
                });
            });
            return Object.assign({}, state, {
                clients: clientState_1
            });
        }
        case typeFor(SLICE.COMPANY, administrationActions.UPDATE_COMPANY_SEARCH_QUERY): {
            var payload = action.payload;
            return Object.assign({}, state, { companySearchQuery: payload.searchQuery });
        }
        case typeFor(SLICE.COMPANY, administrationActions.UPDATE_COMPANY_FILTERED_COUNTRIES): {
            var payload_1 = action.payload;
            var newFilter = Object.assign([], state.companyFilteredCountries);
            if (payload_1.isChecked) {
                newFilter.push(payload_1.country);
            }
            else {
                newFilter = newFilter.filter(function (i) { return i !== payload_1.country; });
            }
            return Object.assign({}, state, { companyFilteredCountries: newFilter });
        }
    }
    return state;
}
function updateClient(client, clientCompanyId, companyId, state) {
    var newClient = tslib_1.__assign({}, client, { companyNumberConnectedToCompany: true, companyId: clientCompanyId });
    return clientsAdapter.updateOne(newClient, companyId, state);
}
export { ɵ0, ɵ1 };
