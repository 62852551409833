<caple-full-page-message *ngIf="showError">
  <p>{{'navigation.sorry-no-role' | translate }}</p>
  <p>{{'navigation.contact-support' | translate }}
    <a href="mailto:support@caple.eu">support@caple.eu</a>
  </p>
  <p>
    <button class="mat-action-button no-extra-padding" routerLink="/logout">
      <span>{{'generic.logout' | translate}}</span>
    </button>
  </p>
</caple-full-page-message>
