import { EntityState } from '@ngrx/entity';
import { IdType, WithId } from './actions';
import { Dictionary } from '@ngrx/entity/src/models';
import { createSelector } from '@ngrx/store';
import { Page } from '../../shared/api/models/page';

/******************
 * Default Table Adapter
 ******************/

/**
 * Interface for a Table. Tables contains items and state information;
 */
export interface Table<T> extends EntityState<T> {
  loaded: boolean;
  size: number;
  selected?: IdType;
  ids: Array<IdType>;
}

/**
 * Different options for a tabe
 *
 * idSelector: How we can get the ID from the item T in Table<T>
 * sort: If the array should be sorted, this is the sort function
 */
export interface TableAdapterOptions<T> {
  idSelector?: (any) => IdType;
  sort?: (a: T, b: T) => number;
}

/**
 * Adapter for the table, always use this class to perform actions
 * on a Table<T>
 *
 * Don't instantiate a instance of this class yourself, use the
 * `createTableAdapter` function.
 */
export class TableAdapter<T> {

  readonly selectId: (any) => IdType;
  protected readonly sort: (a: T, b: T) => number;

  constructor(options: TableAdapterOptions<T> = {}) {
    this.selectId = options.idSelector || ((model: WithId) => model.id);
    this.sort = options.sort;
  }

  /**
   * Get the initial state for the Table.
   * @returns {Table<T>}
   */
  public getInitialState(): Table<T> {
    return {
      ids: [],
      entities: {},
      size: 0,
      loaded: false
    };
  }

  /**
   * Get a set of selectors for the Table<V>, contains:
   * selectTable
   * selectIsLoaded
   * selectIds
   * selectEntities
   * selectAll
   * selectTotal
   * selectSingle (note that this is a function returning a selector)
   * selectSingleIsLoaded
   * selectSelected
   */
  public getSelectors<V>(
    selectState?: (state: V) => Table<T>
  ): {
    selectTable: (state: V) => Table<T>;
    selectIsLoaded: (state: V) => boolean;
    selectIds: (state: V) => IdType[];
    selectEntities: (state: V) => Dictionary<T>;
    selectAll: (state: V) => T[];
    selectSize: (state: V) => number;
    selectSingle: (id: WithId) => (state: V) => T;
    selectSingleIsLoaded: (id: WithId) => (state: V) => boolean;
    selectSelected: (state: V) => T;
  } {
    const selectTable = (state: any) => state;
    const selectIsLoaded = (state: any) => state.loaded;
    const selectIds = (state: any) => state.ids as IdType[];
    const selectEntities = (state: any) => state.entities;
    const selectAll = createSelector(
      selectIds,
      selectEntities,
      (ids: IdType[], entities: Dictionary<T>): any =>
        ids.map((id: any) => (entities as any)[id])
    );
    const selectSize = (state: any) => state.size;
    const selectSingle = (withId: WithId) => (state: any) => state.entities[withId.id];
    const selectSingleIsLoaded = (withId: WithId) => createSelector(selectSingle(withId), (any) => any !== undefined);
    const selectSelected = (state: any) => state.selected ? state.entities[state.selected] : undefined;

    if (!selectState) {
      return {
        selectTable,
        selectIsLoaded,
        selectIds,
        selectEntities,
        selectAll,
        selectSize,
        selectSingle,
        selectSingleIsLoaded,
        selectSelected
      };
    }

    return {
      selectTable: createSelector(selectState, selectTable),
      selectIsLoaded: createSelector(selectState, selectIsLoaded),
      selectIds: createSelector(selectState, selectIds),
      selectEntities: createSelector(selectState, selectEntities),
      selectAll: createSelector(selectState, selectAll),
      selectSize: createSelector(selectState, selectSize),
      selectSingle: (withId: WithId) => createSelector(selectState, selectSingle(withId)),
      selectSingleIsLoaded: (withId: WithId) => createSelector(selectState, selectSingleIsLoaded(withId)),
      selectSelected: createSelector(selectState, selectSelected)
    };

  }

  /**
   * Does the same as addAll but clears the existing items
   */
  public reloadAll(newModels: T[]): Table<T> {
    return this.addAll(newModels, this.getInitialState(), true);
  }

  /**
   * Add all the newModels to the Table. Comparison for this is done by id.
   *
   * @param {T[]} newModels The models to store in the table
   * @param {Table<T>} state The current state
   * @param {boolean} markAsLoaded Whether or not to mark the table as 'loaded' after this action completes
   * @returns {Table<T>}
   */
  public addAll(newModels: T[], state: Table<T>, markAsLoaded = true): Table<T> {
    const newIds = newModels.filter(
      model => !(this.selectId(model) in state.entities)
    ).map(model => this.selectId(model));

    let ids: string[];
    let entities: Dictionary<T>;

    if (this.sort) {
      ids = Object.assign([], state.ids);
      entities = Object.assign({}, state.entities);

      newModels.forEach(model => {
        if (model !== undefined && model !== null) {
          ids = this.addIDAtCorrectIndex(entities, model, this.selectId(model), ids);

          entities = {
            ...entities,
            [this.selectId(model)]: model
          };
        }
      });
    } else {
      // Although id's can only be string, typescript doesn't get that the result of this expression is a
      // string[], therefore we cast explicitly. Will be fixed in https://github.com/Microsoft/TypeScript/issues/10727
      ids = [
        ...state.ids,
        ...newIds
      ] as (string[]);

      entities = {
        ...state.entities,
        ...newModels.reduce((map, obj) => {
          map[this.selectId(obj)] = obj;
          return map;
        }, {})
      };
    }

    return {
      ...state,
      loaded: markAsLoaded,
      size: ids.length,
      ids,
      entities
    };
  }

  public addPage(page: Page, state: Table<T>, markAsLoaded = true): Table<T> {
    const models = page.content;
    const newIds = models.map(model => this.selectId(model));
    // Empty existing lists of ids and entities, because we're creating a new page
    let ids: string[] = [];
    let entities: Dictionary<T> = {};

    if (this.sort) {
      models.forEach(model => {
        if (model !== undefined && model !== null) {
          ids = this.addIDAtCorrectIndex(entities, model, this.selectId(model), ids);

          entities = {
            ...entities,
            [this.selectId(model)]: model
          };
        }
      });
    } else {
      // Although id's can only be string, typescript doesn't get that the result of this expression is a
      // string[], therefore we cast explicitly. Will be fixed in https://github.com/Microsoft/TypeScript/issues/10727
      ids = [
        ...newIds
      ] as (string[]);

      entities = {
        ...models.reduce((map, obj) => {
          map[this.selectId(obj)] = obj;
          return map;
        }, {})
      };
    }

    return {
      ...state,
      loaded: markAsLoaded,
      size: page.totalElements ? page.totalElements : ids.length,
      ids,
      entities
    };
  }

  /**
   * Add a single model to the table, will NOT change the state of `loaded`
   *
   * @param {T} newModel The new model
   * @param {Table<T>} state The current state
   * @returns {Table<T>}
   */
  public addOne(newModel: T, state: Table<T>): Table<T> {
    if (newModel !== undefined && newModel !== null) {
      return this.addOneWithId(newModel, this.selectId(newModel), state);
    } else {
      return state;
    }
  }

  /**
   * Add a single model with a certain id. This ID does not have to equal the ID as retrieved by the
   * selectId function. This is usefull when you want to index entities on an other id then their own.
   *
   * @param {T} newModel
   * @param {IdType} id
   * @param {Table<T>} state
   */
  public addOneWithId(newModel: T, id: IdType, state: Table<T>): Table<T> {
    if (newModel !== undefined && newModel !== null) {
      let ids = [
        ...state.ids
      ] as (IdType[]);

      if (this.sort) {
        ids = this.addIDAtCorrectIndex(state.entities, newModel, id, ids);
      } else {
        if (ids.indexOf(id) < 0) {
          ids.push(id);
        }
      }

      const entities = {
        ...state.entities,
        [id]: newModel
      };

      return {
        ...state,
        size: ids.length,
        ids,
        entities
      };
    } else {
      return state;
    }
  }

  /**
   * Mark a certain entity as selected
   *
   * @param {IdType} id
   * @param {Table<T>} state
   * @returns {Table<T>}
   */
  public select(id: IdType, state: Table<T>): Table<T> {
    return {
      ...state,
      selected: id
    };
  }

  /**
   * Remove a certain entity
   */
  public removeOne(id: string, state: Table<T>): Table<T> {
    if (state.entities[id] !== undefined) {
      const entities = {...state.entities};
      delete entities[id];

      const ids = [...state.ids];
      ids.splice(state.ids.indexOf(id), 1);

      return {
        ...state,
        size: ids.length,
        entities,
        ids
      };
    } else {
      return state;
    }
  }

  /**
   * Update a single entity. The new will merge together with the old model, overwriting
   * any existing keys and adding new ones. It will not remove keys.
   */
  public updateOne(newModel: T, state: Table<T>): Table<T> {
    if (state.ids.indexOf(this.selectId(newModel)) < 0) {
      return state;
    }
    let ids = Object.assign([], state.ids);

    if (this.sort) {
      ids = this.addIDAtCorrectIndex(state.entities, newModel, this.selectId(newModel), ids);
    }

    const entities = {...state.entities};
    entities[this.selectId(newModel)] = Object.assign({}, entities[this.selectId(newModel)], newModel);

    return Object.assign({}, state, {ids: ids, entities: entities});
  }

  private addIDAtCorrectIndex(entities: Dictionary<T>, model: T, modelId: string, ids: string[]): string[] {
    const indexOf = ids.indexOf(modelId);

    if (indexOf >= 0) { // If the id is already in the list, remove it first
      ids.splice(indexOf, 1);
    }

    if (ids.length === 0) {
      ids.push(modelId);
      return ids;
    }

    for (let index = 0; index < ids.length; index++) {
      if (this.sort(model, entities[ids[index]]) <= 0) {
        ids.splice(index, 0, modelId);
        return ids;
      } else if (index === (ids.length - 1)) { // This model is the greatest, append it to the end
        ids.push(modelId);
        return ids;
      }
    }
  }
}

/**
 * Create a new Table Adapter with the given options
 * @param {TableAdapterOptions} options
 * @returns {TableAdapter<T>}
 */
export function createTableAdapter<T>(options?: TableAdapterOptions<T>) {
  return new TableAdapter<T>(options);
}

/******************
 * Foreign Table Adapter
 ******************/

/**
 * Interface for a Foreign Table. A foreign table contains entities
 * that are grouped by a ID that is not theirs, for example:
 * Users group by a PartnerId
 */
export interface ForeignTable<T> extends EntityState<Table<T>> {
  ids: Array<IdType>;
}

/**
 * Adapter for the ForeignTable, always use this class to perform actions
 * on a ForeignTable<T>
 *
 * Don't instantiate a instance of this class yourself, use the
 * `createForeignTableAdapter` function.
 */
export class ForeignTableAdapter<T> {

  protected readonly tableAdapter: TableAdapter<T>;

  constructor(options: TableAdapterOptions<T> = {}) {
    this.tableAdapter = createTableAdapter<T>(options);
  }

  /**
   * Get the initial state
   * @returns {ForeignTable<T>}
   */
  public getInitialState(): ForeignTable<T> {
    return {
      ids: [],
      entities: {}
    };
  }

  /**
   * Will add the given newModels under the given ID. If there is already a Table<T> stored
   * under the given ID, then the newModels are added to that table as described
   * in TableAdapter::addAll.
   *
   * @param {T[]} newModels
   * @param {string} foreignId
   * @param {ForeignTable<T[]>} state
   * @returns {ForeignTable<T[]>}
   */
  public addAll(newModels: T[], foreignId: IdType, state: ForeignTable<T>): ForeignTable<T> {

    const currentState = state.entities[foreignId] || this.tableAdapter.getInitialState();

    const newItem = {
      [foreignId]: this.tableAdapter.addAll(newModels, currentState)
    };

    let ids = state.ids;
    if (!state.entities[foreignId]) {
      // Although id's can only be string, typescript doesn't get that the result of this expression is a
      // string[], therefore we cast explicitly. Will be fixed in https://github.com/Microsoft/TypeScript/issues/10727
      ids = [...state.ids, foreignId] as (string[]);
    }

    const entities = {...state.entities, ...newItem};

    return {ids, entities};
  }

  /**
   * Will add the given newModel under the given ID. If there is already a Table<T> stored
   * under the given ID, then the newModel are added to that table as described
   * in TableAdapter::addOne.
   *
   * @param {T} newModel
   * @param {string} foreignId
   * @param {ForeignTable<T[]>} state
   * @returns {ForeignTable<T[]>}
   */
  public addOne(newModel: T, foreignId: IdType, state: ForeignTable<T>): ForeignTable<T> {
    return this.addAll([newModel], foreignId, state);
  }

  /**
   * Remove an entity from the list of entities
   */
  public removeOne(entity: any, foreignId: IdType, state: ForeignTable<T>): ForeignTable<T> {
    return this.removeOneById(this.tableAdapter.selectId(entity), foreignId, state);
  }

  /**
   * Remove an entity from the list of entities by entityId
   */
  public removeOneById(id: IdType, foreignId: IdType, state: ForeignTable<T>): ForeignTable<T> {
    if (state.entities[foreignId]) {
      const newItem = {
        [foreignId]: this.tableAdapter.removeOne(id, state.entities[foreignId])
      };

      const entities = {...state.entities, ...newItem};

      return {
        ...state,
        entities
      };
    } else {
      return state;
    }
  }

  /**
   * Update a record if it exists. For the exact update behaviour,
   * see TableAdapter::updateOne.
   *
   * @param {T} updatedModel
   * @param {IdType} foreignId
   * @param {ForeignTable<T>} state
   * @returns {ForeignTable<T>}
   */
  public updateOne(updatedModel: T, foreignId: IdType, state: ForeignTable<T>): ForeignTable<T> {
    if (state.ids.indexOf(foreignId) < 0) {
      return state;
    }

    const newItem = {
      [foreignId]: this.tableAdapter.updateOne(updatedModel, state.entities[foreignId])
    };

    const entities = {...state.entities, ...newItem};

    return {...state, entities};
  }

  public getForeignSelectors<V>(
    selectState: (state: V) => ForeignTable<T>,
    foreignId: IdType
  ) {
    const foreignTableSelector = createSelector(selectState, (foreignTable: ForeignTable<T>) => {
      const filteredTable = foreignTable.entities[foreignId];
      return filteredTable || this.tableAdapter.getInitialState();
    });
    return this.tableAdapter.getSelectors(foreignTableSelector);
  }
}

/**
 * Create a new ForeignTable Adapter with the given options
 * @param {TableAdapterOptions} options
 * @returns {TableAdapter<T>}
 */
export function createForeignTableAdapter<T>(options?: TableAdapterOptions<T>) {
  return new ForeignTableAdapter<T>(options);
}
