import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { State } from '../../../state-management/reducers';
import { selectCountry } from '../../../state-management/preferences/selector';
import { Subscription } from 'rxjs';
import { SupportedCountry } from '../../api/models/supportedCountry';
import { getSymbol } from './currency-symbol-util';

@Component({
  selector: 'caple-currency-symbol',
  template: '{{symbol}}&nbsp;',
  styles: [`
    :host.disabled {
      color: rgba(0, 0, 0, .38);
    }
  `]
})
export class CurrencySymbolComponent implements OnInit, OnDestroy, OnChanges {

  public symbol: string;

  @Input()
  public country: string;

  ngOnChanges(changes: SimpleChanges) {
    this.setSymbol(changes.country.currentValue);
  }

  private subscriptions: Subscription = new Subscription();

  constructor(private store: Store<State>) {
  }

  public ngOnInit(): void {
    this.setSymbol(this.country);
  }

  private setSymbol(country: string) {
    if (country) {
      this.symbol = getSymbol(country);
    } else {
      this.subscriptions.add(
        this.store.pipe(select(selectCountry)).subscribe((country: SupportedCountry) => {
          this.symbol = getSymbol(country);
        })
      );
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
