import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { RepeatableItem } from '../../../../../shared/layout/repeatable-form-item/repeatable-item';
import { CapleFormBuilder } from '../../../../../shared/util/caple-form-builder';
var RevenueByCountryItemComponent = /** @class */ (function (_super) {
    tslib_1.__extends(RevenueByCountryItemComponent, _super);
    function RevenueByCountryItemComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.removed = new EventEmitter();
        return _this;
    }
    RevenueByCountryItemComponent.produceFormGroup = function (val) {
        val = val || {};
        return new CapleFormBuilder().group({
            'country': [val.country, Validators.required],
            'percentage': [val.percentage, Validators.required]
        });
    };
    RevenueByCountryItemComponent.prototype.produceFormGroup = function (val) {
        return RevenueByCountryItemComponent.produceFormGroup(val);
    };
    RevenueByCountryItemComponent.prototype.equalToSnapShot = function (val) {
        return this.item.get('country').value === val.country;
    };
    return RevenueByCountryItemComponent;
}(RepeatableItem));
export { RevenueByCountryItemComponent };
