import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { RepeatableItem } from '../../../../../../shared/layout/repeatable-form-item/repeatable-item';
import { selectActiveFundingProcess, selectActiveFundingProcessCountry } from '../../../../../state-management/selector';
import { CapleFormBuilder } from '../../../../../../shared/util/caple-form-builder';
import { DateValidators } from '../../../../../../shared/util/date-validators';
import { DebtFacilityV2 } from '../../../../../../shared/api/models/debtFacilityV2';
import { uuid } from '../../../../../../shared/util/uuid-util';
var LoanStatusEnum = DebtFacilityV2.LoanStatusEnum;
var CompanyDebtFacilitiesV2ItemComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyDebtFacilitiesV2ItemComponent, _super);
    function CompanyDebtFacilitiesV2ItemComponent(store) {
        var _this = _super.call(this) || this;
        _this.store = store;
        _this.index = 0;
        _this.removed = new EventEmitter();
        _this.LoanStatusEnum = Object.keys(LoanStatusEnum).filter(function (k) { return k.indexOf('_') === -1; }).map(function (k) {
            return {
                key: k,
                value: LoanStatusEnum[k]
            };
        });
        _this.country$ = _this.store.pipe(select(selectActiveFundingProcessCountry));
        return _this;
    }
    CompanyDebtFacilitiesV2ItemComponent.produceFormGroup = function (val) {
        val = val || {};
        var capleFormBuilder = new CapleFormBuilder();
        return capleFormBuilder.group({
            'id': [val.id !== null || val.id !== undefined ? val.id : uuid(), []],
            'lender': [val.lender, Validators.required],
            'facilityType': [val.facilityType, Validators.required],
            'repaymentProfile': [val.repaymentProfile, Validators.required],
            'drawnAmount': [val.drawnAmount, [Validators.required, Validators.min(0)]],
            'facilityLimit': [val.facilityLimit, [Validators.required, Validators.min(0)]],
            'maturityDate': [val.maturityDate, [Validators.required, DateValidators.validDate]],
            'interestRate': [val.interestRate, [Validators.required, Validators.min(0)]],
            'securityType': [val.securityType, Validators.required],
            'financialAndNonFinancialCovenants': capleFormBuilder.group({
                text: [val.financialAndNonFinancialCovenants ? val.financialAndNonFinancialCovenants.text : '', Validators.required],
                images: [val.financialAndNonFinancialCovenants ? val.financialAndNonFinancialCovenants.images : []]
            }),
            'loanStatus': [val.loanStatus, [Validators.required]],
            'facilityAgreement': capleFormBuilder.group({
                text: [val.facilityAgreement ? val.facilityAgreement.text : '', Validators.required],
                images: [val.facilityAgreement ? val.facilityAgreement.images : []]
            })
        });
    };
    CompanyDebtFacilitiesV2ItemComponent.prototype.produceFormGroup = function (val) {
        return CompanyDebtFacilitiesV2ItemComponent.produceFormGroup(val);
    };
    CompanyDebtFacilitiesV2ItemComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.fundingProcess$ = this.store.select(selectActiveFundingProcess);
        this.fundingProcessSubscription = this.fundingProcess$.subscribe(function (fundingProcess) {
            if (fundingProcess) {
                _this.currentDebtFacility = fundingProcess.fundingProposal.company.companyDebtFacilities ?
                    fundingProcess.fundingProposal.company.companyDebtFacilities[_this.index] : null;
            }
        });
    };
    CompanyDebtFacilitiesV2ItemComponent.prototype.ngOnDestroy = function () {
        if (this.fundingProcessSubscription) {
            this.fundingProcessSubscription.unsubscribe();
        }
    };
    CompanyDebtFacilitiesV2ItemComponent.prototype.equalToSnapShot = function (val) {
        return this.item.get('lender').value === val.lender &&
            this.item.get('facilityType').value === val.facilityType;
    };
    return CompanyDebtFacilitiesV2ItemComponent;
}(RepeatableItem));
export { CompanyDebtFacilitiesV2ItemComponent };
