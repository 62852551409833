/**
 * Caple Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Country } from './country';


export interface KYCContact {
    firstName?: string;
    lastName?: string;
    nationality?: Country;
    birthDate?: string;
    placeOfBirth?: Country;
    countryOfTaxation?: Country;
    countryOfResidency?: Country;
    percentageOfControlOrVotingRights?: number;
    relatedPersonCategory?: KYCContact.RelatedPersonCategoryEnum;
    politicallyExposedPerson?: boolean;
    highRiskPoliticallyExposedPerson?: boolean;
    politicallyExposedPersonFunction?: string;
    politicallyExposedPersonCountryOfActivity?: Country;
    reason?: string;
}
export namespace KYCContact {
    export type RelatedPersonCategoryEnum = 'DIRECTOR' | 'SIGNATORY' | 'UBO';
    export const RelatedPersonCategoryEnum = {
        DIRECTOR: 'DIRECTOR' as RelatedPersonCategoryEnum,
        SIGNATORY: 'SIGNATORY' as RelatedPersonCategoryEnum,
        UBO: 'UBO' as RelatedPersonCategoryEnum
    }
}
