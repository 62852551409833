import * as tslib_1 from "tslib";
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractApiService } from './abstract-api.service';
import { Phase } from '../models/phase';
var FundingProcessService = /** @class */ (function (_super) {
    tslib_1.__extends(FundingProcessService, _super);
    function FundingProcessService(httpClient) {
        var _this = _super.call(this, httpClient) || this;
        _this.baseUrlV1 = "/api/v1/funding-processes";
        return _this;
    }
    FundingProcessService.prototype.getAll = function (payload) {
        var _this = this;
        if (payload) {
            return this.post(this.baseUrlV1 + '/page', payload).pipe(map(function (fundingProcesses) {
                var newList = fundingProcesses.content.map(function (fundingProcess) { return _this.normalize(fundingProcess); });
                return {
                    content: newList,
                    totalElements: fundingProcesses.totalElements
                };
            }));
        }
    };
    FundingProcessService.prototype.getSummary = function (payload) {
        var _this = this;
        return this.get(this.getSingleFundingProcessUrl(payload) + "/summary").pipe(map(function (fundingProcess) {
            return _this.normalize(fundingProcess);
        }));
    };
    FundingProcessService.prototype.getAllForMonitoring = function () {
        var _this = this;
        var params = new HttpParams()
            .append('phase', 'COMPLETED')
            .append('hasMonitoringFile', 'false');
        var dummyPageRequest = { page: 0, limit: 20, filter: {}, sort: {} };
        return this.postWithParams(this.baseUrlV1 + '/page', dummyPageRequest, params).pipe(map(function (fundingProcesses) {
            var newList = fundingProcesses.content.map(function (fundingProcess) { return _this.normalize(fundingProcess); });
            return {
                content: newList,
                totalElements: fundingProcesses.totalElements
            };
        }));
    };
    FundingProcessService.prototype.create = function (payload) {
        return this.post(this.baseUrlV1, payload);
    };
    FundingProcessService.prototype.updateProductType = function (payload) {
        return this.post(this.getSingleFundingProcessUrl(payload) + "/product-type", {
            productType: payload.productType,
            productSubType: payload.productSubType,
            loanSize: payload.loanSize,
        });
    };
    FundingProcessService.prototype.deleteFundingProcess = function (id) {
        return this.delete(this.getSingleFundingProcessUrl(id));
    };
    FundingProcessService.prototype.getSingle = function (payload) {
        var _this = this;
        return this.get(this.getSingleFundingProcessUrlByVersion(payload)).pipe(map(function (fundingProcess) { return _this.normalize(fundingProcess); }));
    };
    FundingProcessService.prototype.pinFundingProcess = function (id) {
        return this.post(this.baseUrlV1 + '/' + id.id + '/pin', null);
    };
    FundingProcessService.prototype.unpinFundingProcess = function (id) {
        return this.post(this.baseUrlV1 + '/' + id.id + '/unpin', null);
    };
    FundingProcessService.prototype.assignFundingProcess = function (id) {
        return this.post(this.baseUrlV1 + '/' + id.id + '/assigned-credit-officer', null);
    };
    FundingProcessService.prototype.unAssignFundingProcess = function (id) {
        return this.delete(this.baseUrlV1 + '/' + id.id + '/assigned-credit-officer');
    };
    FundingProcessService.prototype.getFinancialRatingMetaModel = function (id) {
        return this.get(this.getSingleFundingProcessUrl(id) + '/financial-ratings/meta-model');
    };
    FundingProcessService.prototype.getFundingProposalMetaModel = function (payload) {
        return this.get(this.getFundingProposalUrl(payload) + '/meta-model');
    };
    FundingProcessService.prototype.getFundingProposalAsPdf = function (payload) {
        return this.getFileWithProgress(this.getFundingProposalUrl(payload) + '/summary');
    };
    FundingProcessService.prototype.downloadFundingProposalResource = function (id) {
        return this.getFileWithProgress(this.getFundingProposalUrl(id) + '/resources/' + id.type);
    };
    FundingProcessService.prototype.downloadFundingProposalUploadsZip = function (payload) {
        return this.getFileWithProgress(this.getFundingProposalUrl(payload) + '/uploads/zip');
    };
    FundingProcessService.prototype.saveFundingProposal = function (payload) {
        return this.post(this.getFundingProposalUrl(payload) + '/draft', payload.fundingProposal);
    };
    FundingProcessService.prototype.submitFundingProposal = function (payload) {
        return this.post(this.getFundingProposalUrl(payload) + '/submitted', payload.fundingProposal);
    };
    FundingProcessService.prototype.snapshotFundingProposal = function (payload) {
        return this.post(this.getSingleFundingProcessUrl(payload) + '/snapshot', null);
    };
    FundingProcessService.prototype.getFundingProposalSnapshot = function (payload) {
        return this.get(this.getSingleFundingProcessUrlByVersion(payload) + '/snapshot');
    };
    FundingProcessService.prototype.translateFundingProposal = function (payload) {
        return this.post(this.getFundingProposalUrl(payload) + '/translate', null);
    };
    FundingProcessService.prototype.getFundingProposalTranslation = function (payload) {
        return this.get(this.getFundingProposalUrl(payload) + '/translate');
    };
    FundingProcessService.prototype.saveFundingProposalTranslation = function (payload) {
        return this.post(this.getFundingProposalUrl(payload) + '/translate/edit', payload);
    };
    FundingProcessService.prototype.getFundingProposalAsTranslatedPdf = function (payload) {
        return this.getFileWithProgress(this.getFundingProposalUrl(payload) + '/translate/summary');
    };
    FundingProcessService.prototype.getFundingProcessFinancialsAsExcel = function (payload) {
        return this.getFileWithProgress(this.getFundingProposalUrl(payload) + '/financials/excel' + (payload.language ? '?language=' + payload.language : ''));
    };
    FundingProcessService.prototype.getFundingProcessScoringAsExcel = function (payload) {
        return this.getFileWithProgress(this.getFundingProposalUrl(payload) + '/scoring/excel');
    };
    FundingProcessService.prototype.getLatestFundingProposalModelVersion = function (payload) {
        return this.get(this.getFundingProposalUrl(payload) + '/meta-model/upgrade');
    };
    FundingProcessService.prototype.upgradeFundingProposalModelVersion = function (payload) {
        return this.post(this.getFundingProposalUrl(payload) + '/meta-model/upgrade', null);
    };
    FundingProcessService.prototype.overrideFundingProposalFinancialData = function (payload) {
        var formData = new FormData();
        formData.append('file', payload.file);
        return this.postFile(this.getFundingProposalUrl(payload) + '/financial-data/override', formData)[0];
    };
    FundingProcessService.prototype.importFundingProcessFinancialsExcel = function (payload) {
        var formData = new FormData();
        formData.append('file', payload.file);
        return this.postFile(this.getFundingProposalUrl(payload) + '/financials/extract-financials', formData);
    };
    FundingProcessService.prototype.createFinancialRating = function (payload) {
        return this.post(this.getSingleFundingProcessUrl({ id: payload.fundingProcessId }) + '/financial-ratings', payload.financialRating);
    };
    FundingProcessService.prototype.deleteFinancialRating = function (id) {
        return this.delete(this.getSingleFundingProcessUrl({ id: id.fundingProcessId }) + '/financial-ratings/' + id.id);
    };
    FundingProcessService.prototype.getFinancialRatings = function (id) {
        return this.get(this.getSingleFundingProcessUrl(id) + '/financial-ratings');
    };
    FundingProcessService.prototype.getFinancialRating = function (id) {
        return this.get(this.getSingleFundingProcessUrl({ id: id.fundingProcessId }) + '/financial-ratings/' + id.id);
    };
    FundingProcessService.prototype.saveFinancialRating = function (payload) {
        return this.post(this.getSingleFundingProcessUrl({ id: payload.fundingProcessId }) + '/financial-ratings/' + payload.id, payload.financialRating);
    };
    FundingProcessService.prototype.getFinancialRatingAsExcel = function (id) {
        return this.getFileWithProgress(this.getSingleFundingProcessUrl({ id: id.fundingProcessId }) + '/financial-ratings/' + id.id + '/excel');
    };
    FundingProcessService.prototype.importFinancialRatingExcel = function (payload) {
        var formData = new FormData();
        formData.append('file', payload.file);
        return this.postFile(this.getSingleFundingProcessUrl({ id: payload.fundingProcessId }) + '/financial-ratings/extract-financials', formData);
    };
    FundingProcessService.prototype.uploadFile = function (payload, file) {
        var formData = new FormData();
        formData.append('file', file);
        return this.postFile(this.getFundingProposalUrl(payload) + '/draft/files', formData);
    };
    FundingProcessService.prototype.downloadFile = function (payload) {
        return this.getFileWithProgress(this.getFundingProposalUrl(payload) + '/draft/files/' + payload.file.fileId);
    };
    FundingProcessService.prototype.loadTeam = function (id) {
        return this.get(this.getSingleFundingProcessUrl(id) + '/team');
    };
    FundingProcessService.prototype.submitToBnp = function (id) {
        return this.post(this.getSingleFundingProcessUrl(id) + '/bnp/submit', null);
    };
    FundingProcessService.prototype.updateUploadsCategories = function (payload) {
        return this.post(this.getFundingProposalUrl(payload) + "/uploads/update-categorisation", payload);
    };
    FundingProcessService.prototype.updateUploadsReview = function (payload) {
        return this.post(this.getFundingProposalUrl(payload) + "/uploads/update-review-status", payload);
    };
    FundingProcessService.prototype.moveUpload = function (payload) {
        return this.post(this.getFundingProposalUrl(payload) + "/uploads/move", payload);
    };
    FundingProcessService.prototype.updateOwner = function (payload) {
        return this.post(this.getSingleFundingProcessUrl(payload) + "/update-owner", payload);
    };
    FundingProcessService.prototype.normalize = function (fundingProcess) {
        if (!fundingProcess.phase) {
            fundingProcess.phase = Phase.UNKNOWN;
        }
        return fundingProcess;
    };
    FundingProcessService.prototype.getSingleFundingProcessUrl = function (id) {
        return this.baseUrlV1 + '/' + id.id;
    };
    FundingProcessService.prototype.getSingleFundingProcessUrlByVersion = function (payload) {
        return "/api/v" + payload.version + "/funding-processes/" + payload.id;
    };
    FundingProcessService.prototype.getFundingProposalUrl = function (payload) {
        var product = payload.product.toLowerCase();
        return "/api/v" + payload.version + "/funding-processes/" + payload.id + "/funding-proposal/" + product;
    };
    return FundingProcessService;
}(AbstractApiService));
export { FundingProcessService };
