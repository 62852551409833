<h2 mat-dialog-title>{{ 'monitoring.edit-reporting-source.title' | translate}}</h2>
<mat-dialog-content>
  <form [formGroup]="form">
    <div class="pure-g">
      <div class="pure-u-1-2">
        <mat-form-field class="full">
          <mat-select placeholder="{{ 'monitoring.reporting-source' | translate }}" [formControl]="form.get('reportingSource').get('reportingSourceId')">
            <mat-option *ngFor="let company of (companies$ | async)" [value]="company.id">
              {{ company.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.hasError('required', ['reportingSource'])">{{ 'generic.validation.required' | translate}}</mat-error>
        </mat-form-field>
      </div>
      <div class="pure-u-1-2">
        <caple-loading-wrapper [key]="['urn:users:load']" [diameter]="36">
          <mat-form-field>
            <mat-label>{{ 'monitoring.users' | translate }}</mat-label>
            <mat-select [formControl]="form.get('reportingSource').get('reportingSourceUsers')" multiple>
              <mat-option *ngFor="let user of (users$ | async)" [value]="user.id">{{user.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </caple-loading-wrapper>
      </div>
    </div>
  </form>

  <div class="notice warning">
    <i class="material-icons">warning</i>
    <p>{{'monitoring.edit-reporting-source.no-remove-warning' | translate}}</p>
  </div>

  <mat-dialog-actions>
    <button mat-button (click)="closeModal()">
      {{ 'generic.cancel' | translate }}
    </button>

    <caple-loading-wrapper key="urn:monitoring-file:edit" [diameter]="30">
      <button mat-button (click)="addReportingSource()">
        <span>{{'generic.add' | translate }}</span>
      </button>
    </caple-loading-wrapper>
  </mat-dialog-actions>
</mat-dialog-content>
