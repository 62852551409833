/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./full-page-message.component";
var styles_FullPageMessageComponent = [];
var RenderType_FullPageMessageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FullPageMessageComponent, data: {} });
export { RenderType_FullPageMessageComponent as RenderType_FullPageMessageComponent };
export function View_FullPageMessageComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "container-full-page-message vertical-align horizontal-align"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "pure-g"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "pure-u-1-2 logo vertical-align horizontal-align"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "img", [["alt", "Logo"], ["src", "assets/images/logo.jpg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "pure-u-1-2"]], null, null, null, null, null)), i0.ɵncd(null, 0)], null, null); }
export function View_FullPageMessageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "caple-full-page-message", [], null, null, null, View_FullPageMessageComponent_0, RenderType_FullPageMessageComponent)), i0.ɵdid(1, 49152, null, 0, i1.FullPageMessageComponent, [], null, null)], null, null); }
var FullPageMessageComponentNgFactory = i0.ɵccf("caple-full-page-message", i1.FullPageMessageComponent, View_FullPageMessageComponent_Host_0, {}, {}, ["*"]);
export { FullPageMessageComponentNgFactory as FullPageMessageComponentNgFactory };
