import { createSelector } from '@ngrx/store';
import { companiesAdapter, fundingProcessAdapter, monitoringFileAdapter, reportingDocumentsSummaryAdapter, selectFeature, State } from './reducer';
import { WithId } from '../../state-management/util/actions';
import { Role } from '../../shared/api/models/role';
import { ForeignTable } from '../../state-management/util/adapters';
import { UploadReportingDocumentsDialogData } from '../dashboard/monitoring-file/upload-reporting-documents/upload-reporting-documents-dialog.component';
import { ReadReportingDocuments } from '../../shared/api/models/readReportingDocuments';
import { ReadReportingDocumentsType } from '../../shared/api/models/readReportingDocumentsType';


/**************
 * Slices
 **************/
const selectCompaniesSlice = createSelector(selectFeature, state => state.companies);
const selectMonitoringFilesSlice = createSelector(selectFeature, (state: State) => state.monitoringFiles);
const selectReportingDocumentsSummarySlice = createSelector(selectFeature, (state: State) => state.reportingDocumentsSummary);
const selectFundingProcessSlice = createSelector(selectFeature, (state: State) => state.fundingProcesses);

/////////////
//// Selectors
/////////////

/** Monitoring files **/
export const {
  selectAll: selectMonitoringFiles,
  selectIsLoaded: selectMonitoringFilesIsLoaded,
  selectSingle: selectMonitoringFile,
  selectSingleIsLoaded: selectMonitoringFileIsLoaded
} = monitoringFileAdapter.getSelectors(selectMonitoringFilesSlice);

/** Companies **/
export const {
  selectAll: selectAllCompanies,
  selectIsLoaded: selectCompaniesIsLoaded,
  selectSingleIsLoaded: selectCompanyIsLoaded,
} = companiesAdapter.getSelectors(selectCompaniesSlice);

export const selectAllCompaniesWithRole = (role: Role) => createSelector(selectFeature, (state: State) => {
  if (state.companies) {
    return Object.values(state.companies.entities).filter(company => company.roles && company.roles.indexOf(role) !== -1);
  } else {
    return [];
  }
});

export const selectUsersForCompany = (companyId: string) => createSelector(selectFeature, (state: State) => {
  return state.users.entities[companyId];
});

export const selectUsersForCompanyLoaded = (company: WithId) => createSelector(selectFeature, (state: State) => {
  return state.users.entities[company.id] !== undefined;
});

/** Funding Processes **/
export const {
  selectAll: selectAllFundingProcesses,
} = fundingProcessAdapter.getSelectors(selectFundingProcessSlice);

export const selectReportingDocumentsSummariesForMonitoringFileLoaded = (monitoringFile: WithId) => createSelector(selectFeature, (state: State) => {
  return state.reportingDocumentsSummary.entities[monitoringFile.id] !== undefined;
});

export const selectFinancialsSummariesForMonitoringFile = (monitoringFile: WithId) =>
  reportingDocumentsSummaryAdapter.getForeignSelectors(selectReportingDocumentsSummarySlice, monitoringFile.id).selectAll;

export const selectReportingDocumentsSummary = (item: UploadReportingDocumentsDialogData) =>
  createSelector(selectReportingDocumentsSummarySlice, (table: ForeignTable<ReadReportingDocuments>) => {
    if (table.entities[item.id]) {
      const firstIdentifierFirstPart = item.reportingFrequency + '-' + item.reportingPeriodNumber + '-' + item.startYear + '-' + (item.version ? item.version : 1) + '-';
      const entryByType = table.entities[item.id].entities[firstIdentifierFirstPart + item.type];
      if (entryByType) {
        return entryByType;
      } else {
        return table.entities[item.id].entities[firstIdentifierFirstPart + ReadReportingDocumentsType.FINAL] ? table.entities[item.id].entities[firstIdentifierFirstPart + ReadReportingDocumentsType.FINAL] :
          table.entities[item.id].entities[firstIdentifierFirstPart + ReadReportingDocumentsType.PARTIAL];
      }
    }
  });
