import { switchMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpHandler, HttpHeaderResponse, HttpInterceptor, HttpProgressEvent, HttpRequest, HttpResponse, HttpSentEvent, HttpUserEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { State } from '../../state-management/reducers';
import { select, Store } from '@ngrx/store';
import { selectAccessToken } from '../../state-management/login/selector';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private store: Store<State>) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any>
    | HttpUserEvent<any>> {

    return this.store.pipe(
      select(selectAccessToken),
      take(1),
      switchMap(accessToken => {
        // Its possible that we get here when we don't have a token yet,
        // for example to load the translations on the login page. In
        // these cases, we should send the request without an Authorization
        // token.
        if (accessToken) {
          const headers = req.headers.set('Authorization', 'Bearer ' + accessToken.getToken());
          const request = req.clone({headers});
          return next.handle(request);
        } else {
          return next.handle(req);
        }
      }),);
  }

}
