/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./loading-dots.component";
var styles_LoadingDotsComponent = [];
var RenderType_LoadingDotsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoadingDotsComponent, data: {} });
export { RenderType_LoadingDotsComponent as RenderType_LoadingDotsComponent };
export function View_LoadingDotsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "span", [["class", "loading"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["."])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["."])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["."]))], null, null); }
export function View_LoadingDotsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "caple-loading-dots", [], null, null, null, View_LoadingDotsComponent_0, RenderType_LoadingDotsComponent)), i0.ɵdid(1, 49152, null, 0, i1.LoadingDotsComponent, [], null, null)], null, null); }
var LoadingDotsComponentNgFactory = i0.ɵccf("caple-loading-dots", i1.LoadingDotsComponent, View_LoadingDotsComponent_Host_0, {}, {}, []);
export { LoadingDotsComponentNgFactory as LoadingDotsComponentNgFactory };
