import { typeFor } from './util';
import { Action } from '@ngrx/store';
import { ApiError } from '../../shared/api/api-error';
import { FileDetails } from '../../shared/api/models/fileDetails';
import { PageRequest } from '../../shared/api/models/pageRequest';

/**
 * Interface that specifies that a certain object should have at least one field called 'id'. This is
 * used in a lot of places were we want to retrieve an entity by a id. This interface is an interface
 * because, should the requirement arise to supply multiple id's, you can do that.
 */
export declare type IdType = string;

export interface WithId {
  id: IdType;
}

export interface WithIdAndVersion extends WithId {
  version: number;
}

export interface WithIdVersionAndProduct extends WithIdAndVersion {
  product: string;
}

export interface FileWithIdVersionAndProduct extends WithIdVersionAndProduct {
  file: FileDetails;
}

/**
 * The generic actions that are supported
 */
export const actions = {
  SELECT: 'SELECT',
  FAIL: 'FAIL',
  LOAD_ALL: 'LOAD_ALL',
  LOAD_ALL_WITHOUT_ID: 'LOAD_ALL_WITHOUT_ID',
  LOAD_ALL_SUCCESS: 'LOAD_ALL_SUCCESS',
  LOAD_SINGLE: 'LOAD_SINGLE',
  LOAD_SINGLE_WITH_VERSION: 'LOAD_SINGLE_WITH_VERSION',
  LOAD_SINGLE_WITHOUT_ID: 'LOAD_SINGLE_WITHOUT_ID',
  LOAD_SINGLE_SUCCESS: 'LOAD_SINGLE_SUCCESS',
  CREATE_SINGLE: 'CREATE_SINGLE',
  CREATE_SINGLE_SUCCESS: 'CREATE_SINGLE_SUCCESS',
  DELETE_SINGLE: 'DELETE_SINGLE',
  DELETE_SINGLE_SUCCESS: 'DELETE_SINGLE_SUCCESS',
  UPDATE_SINGLE: 'UPDATE_SINGLE',
  UPDATE_SINGLE_SUCCESS: 'UPDATE_SINGLE_SUCCESS',
};

/**
 * Parent class for all Actions related to Entity's
 *
 * @param slice The unique key that identifies the Store part this EntityAction correlates to
 * @param payload Optionally a payload the adds more information to this action
 */
export abstract class EntityAction implements Action {
  protected abstract actionName = '';

  protected constructor(public slice: string, public payload?: any) {
  }

  get type(): string {
    return typeFor(this.slice, this.actionName);
  }
}

/***************
 * Actions
 ***************/

export class Select extends EntityAction {
  protected actionName: string = actions.SELECT;

  constructor(public slice: string, public payload: WithId) {
    super(slice, payload);
  }
}

export class Failure extends EntityAction {
  public actionName: string = actions.FAIL;

  constructor(public slice: string, public payload: ApiError) {
    super(slice, payload);
  }
}

export class LoadAll extends EntityAction {
  protected actionName: string = actions.LOAD_ALL;

  constructor(public slice: string, public forceReload = false, public payload?: PageRequest) {
    super(slice, payload);
  }
}

export class LoadAllWithoutId extends EntityAction {
  protected actionName: string = actions.LOAD_ALL_WITHOUT_ID;

  constructor(public slice: string, public forceReload = false) {
    super(slice);
  }
}

export class LoadAllSuccess<T extends any> extends EntityAction {
  protected actionName: string = actions.LOAD_ALL_SUCCESS;

  constructor(public slice: string, public payload: T[]) {
    super(slice, payload);
  }
}

export class LoadSingle extends EntityAction {
  protected actionName: string = actions.LOAD_SINGLE;

  constructor(public slice: string, public payload: any, public forceReload = false) {
    super(slice, payload);
  }
}

export class LoadSingleWithoutId extends EntityAction {
  protected actionName: string = actions.LOAD_SINGLE_WITHOUT_ID;

  constructor(public slice: string, public forceReload = false) {
    super(slice);
  }
}

export class LoadSingleWithVersion extends EntityAction {
  protected actionName: string = actions.LOAD_SINGLE_WITH_VERSION;

  constructor(public slice: string, public payload: WithIdAndVersion | WithIdVersionAndProduct, public forceReload = false) {
    super(slice, payload);
  }
}

export class LoadSingleSuccess<T extends any> extends EntityAction {
  protected actionName: string = actions.LOAD_SINGLE_SUCCESS;

  constructor(public slice: string, public payload: T, public request: WithIdVersionAndProduct) {
    super(slice, payload);
  }
}

export class CreateSingle<T extends any> extends EntityAction {
  protected actionName: string = actions.CREATE_SINGLE;

  constructor(public slice: string, public payload: T) {
    super(slice, payload);
  }
}

export class CreateSingleSuccess<T extends any> extends EntityAction {
  protected actionName: string = actions.CREATE_SINGLE_SUCCESS;

  constructor(public slice: string, public payload: WithId, public request: T) {
    super(slice, payload);
  }
}

export class DeleteSingle<T extends any> extends EntityAction {
  protected actionName: string = actions.DELETE_SINGLE;

  constructor(public slice: string, public payload: WithId) {
    super(slice, payload);
  }
}

export class DeleteSingleSuccess<T extends any> extends EntityAction {
  protected actionName: string = actions.DELETE_SINGLE_SUCCESS;

  constructor(public slice: string, public payload: WithId, public request: T) {
    super(slice, payload);
  }
}

export class UpdateSingle<T extends any> extends EntityAction {
  protected actionName: string = actions.UPDATE_SINGLE;

  constructor(public slice: string, public payload: T) {
    super(slice, payload);
  }
}

export class UpdateSingleSuccess<T extends any> extends EntityAction {
  protected actionName: string = actions.UPDATE_SINGLE_SUCCESS;

  constructor(public slice: string, public payload: WithId, public request: T) {
    super(slice, payload);
  }
}

