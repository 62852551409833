import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {LayoutModule} from '../shared/layout/layout.module';
import {StartFundingProcessDialogComponent} from './dashboard/start-funding-process-dialog/start-funding-process-dialog.component';
import {CreateClientFormComponent} from './dashboard/create-client-form/create-client-form.component';
import {SelectClientFormComponent} from './dashboard/select-client-form/select-client-form.component';
import {CreateFinancialRatingDialogComponent} from './funding-process/financial-ratings/create-financial-rating-dialog/create-financial-rating-dialog.component';
import {AssessmentChoiceModule} from './funding-process/assessment/assessment-choice-dialog/assessment-choice.module';
import {CompanyInformationComponent} from './dashboard/start-funding-process-dialog/company-information/company-information.component';
import {BasicEligiblityComponent} from './dashboard/start-funding-process-dialog/basic-eligibility/basic-eligiblity.component';
import {EligibilityModule} from '../eligibility/eligibility.module';
import {AdvancedEligiblityComponent} from './dashboard/start-funding-process-dialog/advanced-eligiblity/advanced-eligiblity.component';
import {ProductTypeSelectorDialogComponent} from './funding-process/funding-proposal/product-type/product-type-selector-dialog.component';
import {ProductTypeSelectorComponent} from './funding-process/funding-proposal/product-type/product-type-selector.component';
import {UpdatePartnerTeamDialogComponent} from './funding-process/summary/team/update-partner-team-dialog.component';

@NgModule({
  imports: [
    // Angular
    CommonModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),

    AssessmentChoiceModule,

    // Caple Modules
    EligibilityModule,

    // Caple Generic Modules
    LayoutModule,
  ],
  declarations: [
    CreateClientFormComponent,
    SelectClientFormComponent,
    StartFundingProcessDialogComponent,
    CreateFinancialRatingDialogComponent,
    CompanyInformationComponent,
    BasicEligiblityComponent,
    AdvancedEligiblityComponent,
    ProductTypeSelectorComponent,
    ProductTypeSelectorDialogComponent,
    UpdatePartnerTeamDialogComponent
  ],
  exports: [
    CreateFinancialRatingDialogComponent
  ],
  entryComponents: [
    StartFundingProcessDialogComponent,
    CreateFinancialRatingDialogComponent,
    ProductTypeSelectorDialogComponent,
    UpdatePartnerTeamDialogComponent
  ]
})
export class OriginationEntryModule {
}
