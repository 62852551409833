<div *ngIf="forNewClient">
  <caple-create-client-form (statusChanged)="formIsValid = $event"
                            (valueChanged)="newClient = $event"></caple-create-client-form>
  <a (click)="forNewClient = false">{{'funding-process.start.for-existing-client' | translate}}</a>
</div>

<div *ngIf="!forNewClient">
  <caple-select-client-form (clientChanged)="clientId = $event"></caple-select-client-form>
  <a (click)="forNewClient = true">{{'funding-process.start.for-new-client' | translate}}</a>
</div>
