/** Precision that should be shown for number inputs **/
const DIGIT_INFO_NO_DECIMALS = '1.0-2';
const DIGIT_INFO_DECIMALS = '1.2-2';

export function getDigitsInfo(value: number): string {
  return hasDecimals(value) ? DIGIT_INFO_DECIMALS : DIGIT_INFO_NO_DECIMALS;
}

export function hasDecimals(value: number) {
  return value % 1 != 0;
}
