import { Component, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../state-management/reducer';
import { PromoteToCompany } from '../../../state-management/actions';
import { CloseModal } from '../../../../state-management/layout/actions';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PromoteCompany } from '../../../../shared/api/models/promoteCompany';
import RoleEnum = PromoteCompany.RoleEnum;

@Component({
  selector: 'caple-upgrade-client-dialog',
  templateUrl: './upgrade-client-dialog.component.html'
})
export class UpgradeClientDialogComponent {

  private companyNumber: string;
  private companyId: string;

  constructor(private store: Store<State>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.companyNumber = data.companyNumber;
    this.companyId = data.companyId;
  }

  public createStandardClient() {
    this.promote(RoleEnum.CLIENT);
  }

  public createDirectClient() {
    this.promote(RoleEnum.DIRECTCLIENT);
  }

  private promote(role: RoleEnum) {
    const action = new PromoteToCompany({
      companyNumber: this.companyNumber,
      companyId: this.companyId,
      role: role
    });
    this.store.dispatch(action);
  }

  public closeModal() {
    this.store.dispatch(new CloseModal());
  }
}
