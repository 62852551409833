import {Component, Inject, OnDestroy} from '@angular/core';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {ReadUser} from '../../../../shared/api/models/readUser';
import {FormBuilder, FormGroup} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {MAT_DIALOG_DATA} from '@angular/material';
import {LoadSingle} from '../../../../state-management/util/actions';
import {filter, map} from 'rxjs/operators';
import {Table} from '../../../../state-management/util/adapters';
import {ReadFundingProcessOwner} from '../../../../shared/api/models/readFundingProcessOwner';
import {CloseModal} from '../../../../state-management/layout/actions';
import {SLICE} from '../../../state-management/slice';
import {selectUsersForCompany} from '../../../state-management/selector';
import {UpdateOwnerUsers} from '../../../state-management/actions';
import {selectUserId} from '../../../../state-management/login/selector';
import {State} from '../../../../state-management/reducers';

@Component({
  selector: 'caple-update-partner-team-dialog',
  templateUrl: './update-partner-team-dialog.component.html',
  styleUrls: ['update-partner-team-dialog.component.scss']
})
export class UpdatePartnerTeamDialogComponent implements OnDestroy {

  public users$: Observable<ReadUser[]>;
  public form: FormGroup;
  public updateDisabled: boolean;
  public subscriptions: Subscription = new Subscription();

  private id: string;
  private owner: ReadFundingProcessOwner;

  constructor(private store: Store<State>, private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) private data: UpdateOwnerDialogData) {
    this.id = data.id;
    this.owner = data.owner;
    this.form = formBuilder.group({});

    this.store.dispatch(new LoadSingle(SLICE.COMPANY_USERS, {id: this.owner.partner.companyId}));
    this.users$ = this.store.pipe(select(selectUsersForCompany(this.owner.partner.companyId)),
      filter(table => !!table),
      map((table: Table<ReadUser>) => {
        return Object.values(table.entities);
      }));

    this.subscriptions.add(
      combineLatest([this.users$, this.store.pipe(select(selectUserId))]).subscribe(([companyUsers, currentUser]) => {
        const filteredUsers = companyUsers
          .filter(companyUser => !!this.owner.users.find(ownerUser => ownerUser.userId === companyUser.id))
          .map(companyUser => companyUser.id);

        this.form = formBuilder.group({});
        let amountOfSelected = 0;
        companyUsers.forEach(companyUser => {
          const checkUserSelected = filteredUsers.includes(companyUser.id);
          const control = formBuilder.control(checkUserSelected);
          if (currentUser === companyUser.id) {
            control.disable();
          }
          this.form.addControl(companyUser.id, control);

          if (checkUserSelected) {
            amountOfSelected++;
          }
        });

        this.updateDisabled = amountOfSelected === 0;
      }));
  }

  public closeModal() {
    this.store.dispatch(new CloseModal());
  }

  public updateUsers() {
    const selectedUsers = [];
    Object.keys(this.form.controls).forEach(key => {
      if (this.form.get(key).value) {
        selectedUsers.push(key);
      }
    });

    this.store.dispatch(new UpdateOwnerUsers({
      id: this.id,
      users: selectedUsers
    }));
  }

  public onChange(isChecked: boolean) {
    let amountOfSelected = 0;
    Object.keys(this.form.controls).forEach(key => {
      if (this.form.get(key).value) {
        amountOfSelected++;
      }
    });

    this.updateDisabled = amountOfSelected === 0;
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

export interface UpdateOwnerDialogData {
  id: string;
  owner: ReadFundingProcessOwner;
}
