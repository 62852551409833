/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../shared/layout/full-page-message/full-page-message.component.ngfactory";
import * as i2 from "../shared/layout/full-page-message/full-page-message.component";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "./navigation.component";
import * as i7 from "@ngrx/store";
var styles_NavigationComponent = [];
var RenderType_NavigationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NavigationComponent, data: {} });
export { RenderType_NavigationComponent as RenderType_NavigationComponent };
function View_NavigationComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 15, "caple-full-page-message", [], null, null, null, i1.View_FullPageMessageComponent_0, i1.RenderType_FullPageMessageComponent)), i0.ɵdid(1, 49152, null, 0, i2.FullPageMessageComponent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 2, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(5, 0, null, 0, 4, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", " "])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(8, 0, null, null, 1, "a", [["href", "mailto:support@caple.eu"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["support@caple.eu"])), (_l()(), i0.ɵeld(10, 0, null, 0, 5, "p", [], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 4, "button", [["class", "mat-action-button no-extra-padding"], ["routerLink", "/logout"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 12).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(12, 16384, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(13, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(14, null, ["", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_2 = "/logout"; _ck(_v, 12, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform("navigation.sorry-no-role")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform("navigation.contact-support")); _ck(_v, 6, 0, currVal_1); var currVal_3 = i0.ɵunv(_v, 14, 0, i0.ɵnov(_v, 15).transform("generic.logout")); _ck(_v, 14, 0, currVal_3); }); }
export function View_NavigationComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavigationComponent_1)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showError; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_NavigationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "caple-navigation", [], null, null, null, View_NavigationComponent_0, RenderType_NavigationComponent)), i0.ɵdid(1, 245760, null, 0, i6.NavigationComponent, [i7.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavigationComponentNgFactory = i0.ɵccf("caple-navigation", i6.NavigationComponent, View_NavigationComponent_Host_0, {}, {}, []);
export { NavigationComponentNgFactory as NavigationComponentNgFactory };
