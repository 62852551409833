import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { AbstractApiService } from './abstract-api.service';
var ChangelogService = /** @class */ (function (_super) {
    tslib_1.__extends(ChangelogService, _super);
    function ChangelogService(httpClient) {
        var _this = _super.call(this, httpClient) || this;
        _this.baseUrl = '/api/v1/changelog/';
        return _this;
    }
    ChangelogService.prototype.getAll = function () {
        return this.get(this.baseUrl);
    };
    ChangelogService.prototype.createOne = function (release) {
        return this.post(this.baseUrl, release);
    };
    ChangelogService.prototype.markAsRead = function () {
        return this.post(this.baseUrl + "mark-as-read", null);
    };
    return ChangelogService;
}(AbstractApiService));
export { ChangelogService };
