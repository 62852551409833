<h2 mat-dialog-title>{{ 'funding-process.product-type.dialog.title' | translate}}</h2>
<mat-dialog-content>
  <caple-product-type-selector
    (onTypeChanged)="product = $event"
    (onValidationChanged)="isValid = $event"
    [data]="(existingProduct$ | async)"
  ></caple-product-type-selector>

  <mat-dialog-actions>
    <caple-loading-wrapper [diameter]="36" class="buttons" key="urn:funding-process:product-type:update">
      <button (click)="closeModal()" mat-button>{{ 'generic.cancel' | translate }}</button>
      <button (click)="changeProductType()" [disabled]="!isValid" mat-button>
        {{'generic.save' | translate }}
      </button>
    </caple-loading-wrapper>
  </mat-dialog-actions>

</mat-dialog-content>
