<div class="discussion-dialog">
  <div class="heading">
    <i class="material-icons">feedback</i>

    <!-- If discussionId is present and discussion is loaded, show the title of the discussion -->
    <h2 *ngIf="discussionId && (discussion$ | async)">{{(discussion$ | async).title}}</h2>

    <!-- If the discussinId is present but the discussion is still loading, show a 'Loading...' title -->
    <h2 *ngIf="discussionId && !(discussion$ | async)">{{'generic.loading' | translate}}
      <caple-loading-dots></caple-loading-dots>
    </h2>

    <!-- If the discussion isn't started yet, show the title as provided in the options -->
    <h2 *ngIf="!discussionId">{{options.title}}</h2>
  </div>
  <div class="messages" #messageBox>
    <caple-loading-wrapper key="urn:discussion:load" *ngIf="(discussion$ | async)">
      <div class="message-container" *ngFor="let comment of (discussion$ | async).comments">
        <div class="avatar-container">
          <div class="avatar" [class.internal]="comment.user.email.endsWith('caple.eu')">
            {{comment.user.name.charAt(0)}}
          </div>
        </div>
        <div class="message">
          <div class="heading">
            <span class="date">{{comment.postedAt | localizedDate:'longDate'}}</span>
            <span class="name">{{comment.user.name}}</span>
          </div>
          <div class="text">
            {{comment.message}}
          </div>
        </div>
      </div>
      <div class="bottom-anchor"></div>
    </caple-loading-wrapper>
  </div>
  <div class="new-comment">
    <textarea cdkTextareaAutosize [class.invisible]="(discussion$ | async)?.locked" matInput placeholder="{{'discussion.comment' | translate}}" [(ngModel)]="message"></textarea>
    <div class="discussion-dialog-action-buttons">
      <caple-loading-wrapper [key]="['urn:discussion:start', 'urn:discussion:comment:post']" [diameter]="36">
        <button mat-button [class.invisible]="(discussion$ | async)?.locked" [disabled]="message.trim() === ''" (click)="postComment()">{{'discussion.send' | translate}}</button>
        <button mat-button (click)="closeModal()">{{'generic.close' | translate}}</button>
      </caple-loading-wrapper>
    </div>
  </div>
</div>

