import { FormControl, FormGroup } from '@angular/forms';
import { EventEmitter } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { CapleFormControl, CapleFormGroup } from '../../util/caple-form';

export abstract class RepeatableItem {
  public item: FormGroup | FormControl;
  public translationGroup: CapleFormGroup | CapleFormControl | null;
  public snapShotState: any;
  public showRemove: boolean;
  public removed: EventEmitter<void>;
  public index: number;

  public abstract produceFormGroup(val?: any): FormGroup | FormControl;

  public equalToSnapShot(val: any): boolean {
    throw new Error("No implemented for sub type");
  }

  public afterAdd(): void {
  }

  public open(): void {
  }

  public close(): void {
  }

  public openOnStart(expansionPanel: MatExpansionPanel): void {
    if (this.item.invalid || this.index === 0) {
      expansionPanel.open();
    }
  }
}
