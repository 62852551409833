<caple-loading-wrapper key="urn:eligibility:load" class="eligibility-criteria">
  <caple-eligibility-criteria-result *ngIf="checked"
                                     passedText="{{passedText | translate}}"
                                     link="../financial-ratings"
                                     linkText="{{passedLinkText | translate}}"
                                     [errors]="errors">
  </caple-eligibility-criteria-result>

  <div class="go-no-go" *ngIf="!checked">
    <div class="badge info">
    <span>
      <span class="vertical-align">
        <span>{{introText | translate}}</span>
      </span>
    </span>
    </div>
  </div>

  <form autocomplete="off" class="eligibility-criteria-form" [formGroup]="form">

    <div>
      <div class="pure-g">
        <div class="pure-u-5-6">
          <span>{{'eligibility-criteria.control.drawdown-quarter'| translate}}</span>
        </div>
        <div class="pure-u-1-6">
          <div class="pure-u-1-2">
            <mat-form-field class="full">
              <mat-select placeholder="{{ 'eligibility-criteria.control.quarter' | translate }}" [formControl]="form.controls['drawdownQuarter']">
                <mat-option *ngFor="let quarter of ['1', '2', '3', '4']" [value]="quarter">Q{{ quarter }}</mat-option>
              </mat-select>
              <mat-error *ngIf="form.hasError('required', ['drawdownQuarter'])">{{ 'generic.validation.required' | translate}}</mat-error>
            </mat-form-field>
          </div>
          <div class="pure-u-1-2">
            <mat-form-field class="full">
              <mat-select placeholder="{{ 'eligibility-criteria.control.year' | translate }}" [formControl]="form.controls['drawdownYear']">
                <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
              </mat-select>
              <mat-error *ngIf="form.hasError('required', ['drawdownYear'])">{{ 'generic.validation.required' | translate}}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <caple-eligibility-criteria-number-input [class.failed]="isFailedControl('netDebt')"
                                             [formControlItem]="form.controls['netDebt']"
                                             [countryForCurrency]="country">
      <div><span>{{'eligibility-criteria.control.net-debt'| translate}} {{getQuarterTitle()}}</span></div>
      <div class="help-text" [innerHTML]="'eligibility-criteria.control.net-debt-help' | translate"></div>
    </caple-eligibility-criteria-number-input>

    <caple-eligibility-criteria-number-input [class.failed]="isFailedControl('ebitda')"
                                             [formControlItem]="form.controls['ebitda']"
                                             [countryForCurrency]="country">
      <div><span>{{'eligibility-criteria.control.ebitda'| translate}} {{getYearTitle()}}</span></div>
    </caple-eligibility-criteria-number-input>

    <caple-eligibility-criteria-number-input [class.failed]="isFailedControl('ebit')"
                                             [formControlItem]="form.controls['ebit']"
                                             [countryForCurrency]="country">
      <div><span>{{'eligibility-criteria.control.ebit'| translate}} {{getYearTitle()}}</span></div>
    </caple-eligibility-criteria-number-input>

    <caple-eligibility-criteria-number-input [class.failed]="isFailedControl('totalDebtService')"
                                             [formControlItem]="form.controls['totalDebtService']"
                                             [countryForCurrency]="country">
      <div><span>{{'eligibility-criteria.control.total-debt-service'| translate}} {{getYearTitle()}}</span></div>
    </caple-eligibility-criteria-number-input>
  </form>
</caple-loading-wrapper>

