/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../login-form/login-form.component.ngfactory";
import * as i2 from "../login-form/login-form.component";
import * as i3 from "../login-form/auth0-factory.service";
import * as i4 from "@ngrx/store";
import * as i5 from "@angular/router";
import * as i6 from "./login-page.component";
var styles_LoginPageComponent = [];
var RenderType_LoginPageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoginPageComponent, data: {} });
export { RenderType_LoginPageComponent as RenderType_LoginPageComponent };
export function View_LoginPageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "caple-login-form", [], null, [[null, "success"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("success" === en)) {
        var pd_0 = (_co.loginSuccess($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_LoginFormComponent_0, i1.RenderType_LoginFormComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoginFormComponent, [i3.Auth0Factory, i4.Store, i5.Router], null, { success: "success" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_LoginPageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "caple-login-page", [], null, null, null, View_LoginPageComponent_0, RenderType_LoginPageComponent)), i0.ɵdid(1, 180224, null, 0, i6.LoginPageComponent, [i4.Store, i5.ActivatedRoute], null, null)], null, null); }
var LoginPageComponentNgFactory = i0.ɵccf("caple-login-page", i6.LoginPageComponent, View_LoginPageComponent_Host_0, {}, {}, []);
export { LoginPageComponentNgFactory as LoginPageComponentNgFactory };
