<caple-loading-wrapper key="urn:eligibility:load" class="eligibility-criteria">
  <caple-eligibility-criteria-result *ngIf="checked"
                                     passedText="{{passedText | translate}}"
                                     [errors]="errors"
                                     [notices]="notices">
  </caple-eligibility-criteria-result>

  <form autocomplete="off" class="eligibility-criteria-form" [formGroup]="form">

    <caple-eligibility-criteria-number-input [class.failed]="isFailedControl('sectorCode')"
                                             [formControlItem]="form.controls['sectorCode']">
      <div><span>{{'eligibility-criteria.control.sector-code'| translate}}</span></div>
    </caple-eligibility-criteria-number-input>
    <caple-eligibility-criteria-number-input [class.failed]="isFailedControl('loanAmount')"
                                             [formControlItem]="form.controls['loanAmount']"
                                             [countryForCurrency]="country">
      <div><span>{{'eligibility-criteria.control.loan-amount'| translate}}</span></div>
    </caple-eligibility-criteria-number-input>
    <caple-eligibility-criteria-number-input [class.failed]="isFailedControl('turnover')"
                                             [formControlItem]="form.controls['turnover']"
                                             [countryForCurrency]="country">
      <div><span>{{'eligibility-criteria.control.turnover'| translate}}</span></div>
      <div class="help-text" [innerHTML]="'eligibility-criteria.control.turnover-help' | translate"></div>
    </caple-eligibility-criteria-number-input>
    <caple-eligibility-criteria-number-input [class.failed]="isFailedControl('totalAssets')"
                                             [formControlItem]="form.controls['totalAssets']"
                                             [countryForCurrency]="country">
      <div><span>{{'eligibility-criteria.control.total-assets'| translate}}</span></div>
      <div class="help-text" [innerHTML]="'eligibility-criteria.control.total-assets-help' | translate"></div>
    </caple-eligibility-criteria-number-input>


    <caple-eligibility-criteria-yes-no-question [class.failed]="isFailedControl('registeredAccountsCountQuestion')"
                                                [formControlItem]="form.controls['registeredAccountsCountQuestion']"
                                                label="{{'eligibility-criteria.control.registered-accounts-count-question'| translate}}"></caple-eligibility-criteria-yes-no-question>
    <caple-eligibility-criteria-yes-no-question class="additional-question"
                                                *ngIf="!hiddenControls.includes('registeredAccountsExpectCountQuestion')"
                                                [class.with-animation]="animatedControls.includes('registeredAccountsExpectCountQuestion')"
                                                [class.failed]="isFailedControl('registeredAccountsExpectCountQuestion')"
                                                [formControlItem]="form.controls['registeredAccountsExpectCountQuestion']"
                                                label="{{'eligibility-criteria.control.registered-accounts-expected-count-question'| translate}}"></caple-eligibility-criteria-yes-no-question>
    <caple-eligibility-criteria-yes-no-question class="additional-question"
                                                *ngIf="!hiddenControls.includes('registeredAccountProfitableQuestion')"
                                                [class.with-animation]="animatedControls.includes('registeredAccountProfitableQuestion')"
                                                [class.failed]="isFailedControl('registeredAccountProfitableQuestion')"
                                                [formControlItem]="form.controls['registeredAccountProfitableQuestion']"
                                                label="{{'eligibility-criteria.control.registered-account-profitable-question'| translate}}"></caple-eligibility-criteria-yes-no-question>


    <caple-eligibility-criteria-number-input [class.failed]="isFailedControl('directors')"
                                             [formControlItem]="form.controls['directors']">
      <div><span>{{'eligibility-criteria.control.directors'| translate}}</span></div>
    </caple-eligibility-criteria-number-input>
    <caple-eligibility-criteria-number-input class="additional-question"
                                             *ngIf="!hiddenControls.includes('shareholders')"
                                             [class.with-animation]="animatedControls.includes('shareholders')"
                                             [class.failed]="isFailedControl('shareholders')"
                                             [formControlItem]="form.controls['shareholders']">
      <div><span>{{'eligibility-criteria.control.shareholders'| translate}}</span></div>
    </caple-eligibility-criteria-number-input>
    <caple-eligibility-criteria-yes-no-question class="additional-question"
                                                *ngIf="!hiddenControls.includes('directorsOrShareholdersExpectedQuestion')"
                                                [class.with-animation]="animatedControls.includes('directorsOrShareholdersExpectedQuestion')"
                                                [class.failed]="isFailedControl('directorsOrShareholdersExpectedQuestion')"
                                                [formControlItem]="form.controls['directorsOrShareholdersExpectedQuestion']"
                                                label="{{'eligibility-criteria.control.directors-or-shareholders-expected-question'| translate}}"></caple-eligibility-criteria-yes-no-question>
    <caple-eligibility-criteria-number-input [class.failed]="isFailedControl('entitiesInGroup')"
                                             [formControlItem]="form.controls['entitiesInGroup']">
      <div><span>{{'eligibility-criteria.control.entities-in-group'| translate}}</span></div>
    </caple-eligibility-criteria-number-input>


    <caple-eligibility-criteria-number-input [class.failed]="isFailedControl('currentTotalDebt')"
                                             [formControlItem]="form.controls['currentTotalDebt']"
                                             [countryForCurrency]="country">
      <div><span>{{'eligibility-criteria.control.current-total-debt'| translate}}</span></div>
      <div class="help-text" [innerHTML]="'eligibility-criteria.control.current-total-debt-help' | translate"></div>
    </caple-eligibility-criteria-number-input>
    <caple-eligibility-criteria-yes-no-question [class.failed]="isFailedControl('refinanceExistingDebtQuestion')"
                                                [formControlItem]="form.controls['refinanceExistingDebtQuestion']"
                                                label="{{'eligibility-criteria.control.refinance-existing-debt-question'| translate}}"></caple-eligibility-criteria-yes-no-question>


    <caple-eligibility-criteria-yes-no-question [class.failed]="isFailedControl('paymentOrDemandEventsQuestion')"
                                                [formControlItem]="form.controls['paymentOrDemandEventsQuestion']"
                                                label="{{'eligibility-criteria.control.payment-or-demand-events-question'| translate}}"></caple-eligibility-criteria-yes-no-question>
  </form>
</caple-loading-wrapper>

