import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractApiService } from './abstract-api.service';
import { ChamberOfCommerceResult } from '../models/chamberOfCommerceResult';
import { WithId } from '../../../state-management/util/actions';
import { ChamberOfCommerceNameResult } from '../models/chamberOfCommerceNameResult';

@Injectable()
export class ChamberOfCommerceService extends AbstractApiService {
  private readonly baseUrl = '/api/v1/chamber-of-commerce/';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getCompany(companyNumber: WithId) {
    return this.post<ChamberOfCommerceResult>(this.baseUrl, {companyNumber: companyNumber.id});
  }

  public getCompanyName(payload) {
    return this.post<ChamberOfCommerceNameResult>(this.baseUrl + 'name', {companyNumber: payload.id, country: payload.country});
  }
}
