import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ShowToast } from '../../../state-management/layout/actions';
import { Store } from '@ngrx/store';
import { State } from '../../../state-management/reducers';

@Component({
  selector: 'caple-file-upload-button',
  templateUrl: './file-upload-button.component.html',
  styleUrls: ['./file-upload-button-component.scss'],
})
export class FileUploadButtonComponent {

  @Output()
  public fileSelected = new EventEmitter<File>();

  @Input()
  public label: string;

  @Input()
  public disabled: boolean = false;

  @Input()
  public disabledTooltip: string;

  @Input()
  public accept: Array<string> = [];

  @Input()
  public isActionButton: boolean = false;

  @Input()
  public isSecondaryButton: boolean = false;

  @Input()
  public isTextareaButton: boolean = false;

  @Input()
  public isPrimaryButton: boolean = false;

  @Input()
  public iconBeforeText: boolean = false;

  @Input()
  public isIconOnlyButton: boolean = false;

  @Input()
  public multiple: boolean = false;

  @Input()
  public matIcon: string = 'publish';

  @ViewChild('fileInput', {static: false}) fileInput: ElementRef;

  private static FILETYPES: FileTypes = {
    '.pdf': 'application/pdf',
    '.xls': 'application/vnd.ms-excel',
    '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    '.doc': 'application/msword',
    '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    '.txt': 'text/plain',
    '.png': 'image/png',
    '.jpg': 'image/jpg',
    '.jpeg': 'image/jpeg'
  };

  constructor(private store: Store<State>) {
  }

  public fileChanged($event) {
    const files = $event.target.files;
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        if (!this.isAllowedMimeType(files[i])) {
          this.dispatchWrongFileTypeToast(files[i]);
        } else {
          this.fileSelected.emit(files[i]);
        }
      }
      this.fileInput.nativeElement.value = '';
    }
  }

  private isAllowedMimeType(file: File): boolean {
    return !!this.accept.find(value => FileUploadButtonComponent.FILETYPES[value] === file.type);
  }

  private dispatchWrongFileTypeToast($event: File) {
    const fileNameParts = $event.name.split('.');
    const extension = '.' + fileNameParts[fileNameParts.length - 1];
    this.store.dispatch(new ShowToast('core.error.file.wrong-or-unrecognized-file-type', {
      actualFileType: extension,
      expectedFileType: this.getAcceptedFileExtensionsString()
    }));
  }

  public getAcceptedFileExtensionsString() {
    return this.accept.reduce((previousValue, currentValue) => previousValue + ', ' + currentValue);
  }

  public openFileInput() {
    const mouseEvent = new MouseEvent('click', {bubbles: true});
    this.fileInput.nativeElement.dispatchEvent(mouseEvent);
  }

  public isIconButton() {
    return this.isActionButton || this.isSecondaryButton || this.isTextareaButton || this.isPrimaryButton;
  }

  public getTooltipText() {
    if (this.disabled) {
      return this.disabledTooltip;
    } else {
      return this.getAcceptedFileExtensionsString()
    }
  }
}

export interface FileTypes {
  [extension: string]: string
}
