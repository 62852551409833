var _a, _b;
import { createSelector } from '@ngrx/store';
import { companiesAdapter, fundingProcessAdapter, monitoringFileAdapter, reportingDocumentsSummaryAdapter, selectFeature } from './reducer';
import { ReadReportingDocumentsType } from '../../shared/api/models/readReportingDocumentsType';
var ɵ0 = function (state) { return state.companies; };
/**************
 * Slices
 **************/
var selectCompaniesSlice = createSelector(selectFeature, ɵ0);
var ɵ1 = function (state) { return state.monitoringFiles; };
var selectMonitoringFilesSlice = createSelector(selectFeature, ɵ1);
var ɵ2 = function (state) { return state.reportingDocumentsSummary; };
var selectReportingDocumentsSummarySlice = createSelector(selectFeature, ɵ2);
var ɵ3 = function (state) { return state.fundingProcesses; };
var selectFundingProcessSlice = createSelector(selectFeature, ɵ3);
/////////////
//// Selectors
/////////////
/** Monitoring files **/
export var selectMonitoringFiles = (_a = monitoringFileAdapter.getSelectors(selectMonitoringFilesSlice), _a.selectAll), selectMonitoringFilesIsLoaded = _a.selectIsLoaded, selectMonitoringFile = _a.selectSingle, selectMonitoringFileIsLoaded = _a.selectSingleIsLoaded;
/** Companies **/
export var selectAllCompanies = (_b = companiesAdapter.getSelectors(selectCompaniesSlice), _b.selectAll), selectCompaniesIsLoaded = _b.selectIsLoaded, selectCompanyIsLoaded = _b.selectSingleIsLoaded;
export var selectAllCompaniesWithRole = function (role) { return createSelector(selectFeature, function (state) {
    if (state.companies) {
        return Object.values(state.companies.entities).filter(function (company) { return company.roles && company.roles.indexOf(role) !== -1; });
    }
    else {
        return [];
    }
}); };
export var selectUsersForCompany = function (companyId) { return createSelector(selectFeature, function (state) {
    return state.users.entities[companyId];
}); };
export var selectUsersForCompanyLoaded = function (company) { return createSelector(selectFeature, function (state) {
    return state.users.entities[company.id] !== undefined;
}); };
/** Funding Processes **/
export var selectAllFundingProcesses = fundingProcessAdapter.getSelectors(selectFundingProcessSlice).selectAll;
export var selectReportingDocumentsSummariesForMonitoringFileLoaded = function (monitoringFile) { return createSelector(selectFeature, function (state) {
    return state.reportingDocumentsSummary.entities[monitoringFile.id] !== undefined;
}); };
export var selectFinancialsSummariesForMonitoringFile = function (monitoringFile) {
    return reportingDocumentsSummaryAdapter.getForeignSelectors(selectReportingDocumentsSummarySlice, monitoringFile.id).selectAll;
};
export var selectReportingDocumentsSummary = function (item) {
    return createSelector(selectReportingDocumentsSummarySlice, function (table) {
        if (table.entities[item.id]) {
            var firstIdentifierFirstPart = item.reportingFrequency + '-' + item.reportingPeriodNumber + '-' + item.startYear + '-' + (item.version ? item.version : 1) + '-';
            var entryByType = table.entities[item.id].entities[firstIdentifierFirstPart + item.type];
            if (entryByType) {
                return entryByType;
            }
            else {
                return table.entities[item.id].entities[firstIdentifierFirstPart + ReadReportingDocumentsType.FINAL] ? table.entities[item.id].entities[firstIdentifierFirstPart + ReadReportingDocumentsType.FINAL] :
                    table.entities[item.id].entities[firstIdentifierFirstPart + ReadReportingDocumentsType.PARTIAL];
            }
        }
    });
};
export { ɵ0, ɵ1, ɵ2, ɵ3 };
