import * as tslib_1 from "tslib";
import { ElementRef } from '@angular/core';
import { RepeatableArrayParent } from './repeatable-parent';
import { Store } from '@ngrx/store';
import { Angulartics2Piwik } from 'angulartics2/piwik';
var RepeatableArrayComponent = /** @class */ (function (_super) {
    tslib_1.__extends(RepeatableArrayComponent, _super);
    function RepeatableArrayComponent(store, elRef, angulartics2Piwik) {
        return _super.call(this, store, elRef, angulartics2Piwik) || this;
    }
    return RepeatableArrayComponent;
}(RepeatableArrayParent));
export { RepeatableArrayComponent };
