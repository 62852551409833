<div *ngIf="(isLoading$ | async);else notLoading">
  <div class="loading-wrapper" [class.fullscreen]="fullscreen">
      <mat-spinner [diameter]="diameter"></mat-spinner>
  </div>
</div>

<ng-template #notLoading>
  <ng-content></ng-content>
</ng-template>


