import { interval } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { selectAccessToken } from '../../state-management/login/selector';
import { environment } from '../../../environments/environment';
import { config } from '../../../environments/config';
import { AccessTokenRenewed, AccessTokenRenewFailed, Logout } from '../../state-management/login/actions';
import { TokenRenewUrl } from '../../login/login.routing';
import { Auth0Factory } from '../../login/login-form/auth0-factory.service';
import { AccessToken } from './access-token';
var TokenRenewalService = /** @class */ (function () {
    function TokenRenewalService(store, auth0Factory) {
        this.store = store;
        this.auth0Factory = auth0Factory;
        this.SERVER_TIME_COMPENSATION_MINUTES = 5;
    }
    TokenRenewalService.prototype.enableAutoRenewal = function () {
        var _this = this;
        var webAuth = this.auth0Factory.createInstance();
        var renewalTriedAndFailed = false;
        if (this.accessTokenSubscription) {
            this.accessTokenSubscription.unsubscribe();
        }
        this.accessTokenSubscription =
            interval(2000).pipe(withLatestFrom(this.store.pipe(select(selectAccessToken))))
                .subscribe(function (_a) {
                var _ = _a[0], accessToken = _a[1];
                if (accessToken && accessToken instanceof AccessToken) {
                    var timeTillTokenExpires = _this.compensateServerTimeDifference(accessToken.getExpiresIn());
                    if (!renewalTriedAndFailed && timeTillTokenExpires < 0 && accessToken.isNotExpired()) {
                        // We put it to true here because the checkSession is async and we don't want
                        // the logic to be called multiple times if the function is still executing
                        renewalTriedAndFailed = true;
                        webAuth.checkSession({
                            scope: config.scope,
                            audience: config.audience,
                            redirectUri: config.baseUrl + TokenRenewUrl
                        }, function (error, authResult) {
                            if (error) {
                                _this.store.dispatch(new AccessTokenRenewFailed(error.error, error.error_description));
                            }
                            else {
                                renewalTriedAndFailed = false;
                                _this.store.dispatch(new AccessTokenRenewed(authResult.accessToken));
                            }
                        });
                    }
                    if (!accessToken.isNotExpired()) {
                        _this.store.dispatch(new Logout());
                        renewalTriedAndFailed = false;
                    }
                }
            });
    };
    TokenRenewalService.prototype.ngOnDestroy = function () {
        if (this.accessTokenSubscription) {
            this.accessTokenSubscription.unsubscribe();
        }
    };
    /**
     * Compensate for potential difference between server and local time by subtracting X minutes from the potential expiration time.
     * In case we can't refresh the token, this will give the user a bit of time to manually refresh the token before he is automatically
     * logged out and losses all his current work.
     */
    TokenRenewalService.prototype.compensateServerTimeDifference = function (input) {
        return input - (this.SERVER_TIME_COMPENSATION_MINUTES * 60);
    };
    return TokenRenewalService;
}());
export { TokenRenewalService };
export function enableAutoRenewal(tokenRenewalService) {
    return function () {
        if (!environment.disableTimers) {
            tokenRenewalService.enableAutoRenewal();
        }
    };
}
