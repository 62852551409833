import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {RepeatableItem} from '../../../../../shared/layout/repeatable-form-item/repeatable-item';
import {DateValidators} from '../../../../../shared/util/date-validators';
import {select, Store} from '@ngrx/store';
import {State} from '../../../../../state-management/reducers';
import {selectActiveFundingProcessCountry} from '../../../../state-management/selector';
import {MatExpansionPanel} from '@angular/material/expansion';
import {Observable} from 'rxjs';
import {CapleFormBuilder} from '../../../../../shared/util/caple-form-builder';
import {CapleFormGroup} from '../../../../../shared/util/caple-form';

@Component({
  selector: 'caple-hedging-facilities-item',
  templateUrl: './hedging-facilities-item.component.html'
})
export class HedgingFacilitiesItemComponent extends RepeatableItem implements OnInit {
  @Input()
  public item: CapleFormGroup;

  @Input()
  public translationGroup: CapleFormGroup;

  @Input()
  public showRemove: boolean;

  @Input()
  public index: number;

  @Output()
  public removed: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(MatExpansionPanel, {static: true})
  public expansionPanel: MatExpansionPanel;

  public country$: Observable<string>;

  constructor(private store: Store<State>) {
    super();
    this.country$ = this.store.pipe(select(selectActiveFundingProcessCountry));
  }

  public static produceFormGroup(val?: any) {
    val = val || {};
    return new CapleFormBuilder().group({
      'provider': [val.provider, Validators.required],
      'facilityType': [val.facilityType, Validators.required],
      'hedgingLimit': [val.hedgingLimit, [Validators.required, Validators.min(0)]],
      'notionalAmountCovered': [val.notionalAmountCovered, [Validators.required, Validators.min(0)]],
      'hedgingPurpose': [val.hedgingPurpose, Validators.required],
      'maturityDate': [val.maturityDate, [Validators.required, DateValidators.validDate]],
      'indicativeMarkToMarket': [val.indicativeMarkToMarket, Validators.required]
    });
  }

  public produceFormGroup(val?: any): FormGroup {
    return HedgingFacilitiesItemComponent.produceFormGroup(val);
  }

  public ngOnInit(): void {
    this.openOnStart(this.expansionPanel);
  }


  public equalToSnapShot(val: any): boolean {
    return this.item.get('provider').value === val.provider &&
      this.item.get('facilityType').value === val.facilityType;
  }

  public open(): void {
    this.expansionPanel.open();
  }
}
