import { combineLatest, interval, of } from 'rxjs';
import { distinctUntilKeyChanged, filter, startWith } from 'rxjs/operators';
import { OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { LoadSingleWithoutId } from '../../state-management/util/actions';
import { SLICE } from '../../state-management/layout/slice';
import { selectAccessToken } from '../../state-management/login/selector';
var AnnouncementRefreshService = /** @class */ (function () {
    function AnnouncementRefreshService(store) {
        this.store = store;
    }
    AnnouncementRefreshService.prototype.enableAutoRefresh = function () {
        var _this = this;
        if (this.announcementRefreshSubscription) {
            this.announcementRefreshSubscription.unsubscribe();
        }
        this.announcementRefreshSubscription =
            combineLatest(AnnouncementRefreshService.getTimer(), this.store.pipe(select(selectAccessToken), filter(function (a) { return !!a; }), distinctUntilKeyChanged('getToken'))).subscribe(function (_a) {
                var _ = _a[0], accessToken = _a[1];
                if (accessToken && accessToken.isNotExpired()) {
                    _this.store.dispatch(new LoadSingleWithoutId(SLICE.ANNOUNCEMENT, true));
                }
            });
    };
    AnnouncementRefreshService.getTimer = function () {
        if (!environment.disableTimers) {
            return interval(AnnouncementRefreshService.REFRESH_INTERVAL)
                .pipe(startWith(0));
        }
        else {
            return of(0);
        }
    };
    AnnouncementRefreshService.prototype.ngOnDestroy = function () {
        if (this.announcementRefreshSubscription) {
            this.announcementRefreshSubscription.unsubscribe();
        }
    };
    AnnouncementRefreshService.REFRESH_INTERVAL = 600000; // 10 Minutes
    return AnnouncementRefreshService;
}());
export { AnnouncementRefreshService };
export function enableAutoRefresh(announcementRefreshService) {
    return function () { return announcementRefreshService.enableAutoRefresh(); };
}
