import { ModuleWithProviders } from '@angular/core';
var ɵ0 = function () { return new Promise(function () {
}); };
/**
 * Module that allows us to easily import all material design components with a single module import
 */
var MaterialDesignModule = /** @class */ (function () {
    function MaterialDesignModule() {
    }
    MaterialDesignModule.forRoot = function () {
        return {
            ngModule: MaterialDesignModule
        };
    };
    return MaterialDesignModule;
}());
export { MaterialDesignModule };
export { ɵ0 };
