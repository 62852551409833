<div [formGroup]="item">
  <span matTooltip="{{'generic.remove-entry' | translate}}" class="remove">
    <a *ngIf="showRemove" (click)="removed.emit()">
      <i class="material-icons">delete</i>
    </a>
  </span>
  <div class="pure-g">
    <div class="pure-u-8-24">
      <mat-form-field>
        <mat-select placeholder="{{'monitoring.create-monitoring-file.type' | translate}}"
                    formControlName="type">
          <mat-option *ngFor="let type of covenantType" [value]="type">
            {{'monitoring.covenant-types.' + mapToTranslationKey(type) | translate}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="item.get('type').hasError('required')">{{ 'generic.validation.required' | translate}}</mat-error>
      </mat-form-field>
    </div>
    <div class="pure-u-4-24">
      <mat-form-field>
        <mat-select placeholder="{{ 'monitoring.create-monitoring-file.comparator' | translate}}"
                    formControlName="comparator">
          <mat-option [value]="comparator.LESS">{{'comparator.less' | translate}}</mat-option>
          <mat-option [value]="comparator.LESSOREQUAL">{{'comparator.less-or-equal' | translate}}</mat-option>
          <mat-option [value]="comparator.EQUAL">{{'comparator.equal' | translate}}</mat-option>
          <mat-option [value]="comparator.MOREOREQUAL">{{'comparator.more-or-equal' | translate}}</mat-option>
          <mat-option [value]="comparator.MORE">{{'comparator.more' | translate}}</mat-option>
        </mat-select>
        <mat-error *ngIf="item.get('comparator').hasError('required')">{{ 'generic.validation.required' | translate}}</mat-error>
      </mat-form-field>
    </div>
    <div class="pure-u-4-24">
      <mat-form-field>
        <input matInput type="number" class="form-control" placeholder="{{ 'monitoring.create-monitoring-file.threshold' | translate}}"
               formControlName="threshold"/>
        <mat-error *ngIf="item.get('threshold').hasError('required')">{{ 'generic.validation.required' | translate}}</mat-error>
        <mat-error *ngIf="item.get('threshold').hasError('min')">{{ 'generic.validation.min' | translate:{value: '0'} }}</mat-error>
      </mat-form-field>
    </div>

    <div class="pure-u-4-24">
      <caple-date-picker placeholder="monitoring.create-monitoring-file.active-from"
                         formControlName="activeFrom"
                         [formControl]="item.get('activeFrom')"
                         datePickerFilter="LAST_DAY_OF_MONTH">
        <mat-error *ngIf="item.get('activeFrom').hasError('date')">{{ 'generic.validation.date' | translate}}</mat-error>
      </caple-date-picker>
    </div>
    <div class="pure-u-4-24">
      <caple-date-picker placeholder="monitoring.create-monitoring-file.active-to"
                         formControlName="activeTo"
                         [formControl]="item.get('activeTo')"
                         datePickerFilter="LAST_DAY_OF_MONTH">
        <mat-error *ngIf="item.get('activeTo').hasError('date')">{{ 'generic.validation.date' | translate}}</mat-error>
      </caple-date-picker>
    </div>
  </div>
</div>
