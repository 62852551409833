/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "@angular/common";
import * as i3 from "../../../../shared/layout/loading-wrapper/loading-wrapper.component.ngfactory";
import * as i4 from "../../../../shared/layout/loading-wrapper/loading-wrapper.component";
import * as i5 from "@ngrx/store";
import * as i6 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "@ngx-translate/core";
import * as i11 from "./assessment-choice-dialog.component";
var styles_AssessmentChoiceDialogComponent = [];
var RenderType_AssessmentChoiceDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AssessmentChoiceDialogComponent, data: {} });
export { RenderType_AssessmentChoiceDialogComponent as RenderType_AssessmentChoiceDialogComponent };
export function View_AssessmentChoiceDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(1, 81920, null, 0, i1.MatDialogTitle, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], null, null), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 17, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i0.ɵdid(5, 16384, null, 0, i1.MatDialogContent, [], null, null), (_l()(), i0.ɵeld(6, 0, null, null, 15, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(10, 0, null, null, 11, "mat-dialog-actions", [["align", "end"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i0.ɵdid(11, 16384, null, 0, i1.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(12, 0, null, null, 9, "caple-loading-wrapper", [["key", "urn:funding-proposal:assess"]], null, null, null, i3.View_LoadingWrapperComponent_0, i3.RenderType_LoadingWrapperComponent)), i0.ɵdid(13, 49152, null, 0, i4.LoadingWrapperComponent, [i5.Store], { diameter: [0, "diameter"], key: [1, "key"] }, null), (_l()(), i0.ɵeld(14, 0, null, 0, 3, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i0.ɵdid(15, 180224, null, 0, i7.MatButton, [i0.ElementRef, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵted(16, 0, ["", ""])), i0.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(18, 0, null, 0, 3, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i0.ɵdid(19, 180224, null, 0, i7.MatButton, [i0.ElementRef, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵted(20, 0, [" ", " "])), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 1, 0); var currVal_3 = 36; var currVal_4 = "urn:funding-proposal:assess"; _ck(_v, 13, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.action$)); _ck(_v, 2, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform(_co.question$)); _ck(_v, 8, 0, currVal_2); var currVal_5 = (i0.ɵnov(_v, 15).disabled || null); var currVal_6 = (i0.ɵnov(_v, 15)._animationMode === "NoopAnimations"); _ck(_v, 14, 0, currVal_5, currVal_6); var currVal_7 = i0.ɵunv(_v, 16, 0, i0.ɵnov(_v, 17).transform("generic.cancel")); _ck(_v, 16, 0, currVal_7); var currVal_8 = (i0.ɵnov(_v, 19).disabled || null); var currVal_9 = (i0.ɵnov(_v, 19)._animationMode === "NoopAnimations"); _ck(_v, 18, 0, currVal_8, currVal_9); var currVal_10 = i0.ɵunv(_v, 20, 0, i0.ɵnov(_v, 21).transform(_co.action$)); _ck(_v, 20, 0, currVal_10); }); }
export function View_AssessmentChoiceDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "caple-assessment-choice-dialog", [], null, null, null, View_AssessmentChoiceDialogComponent_0, RenderType_AssessmentChoiceDialogComponent)), i0.ɵdid(1, 49152, null, 0, i11.AssessmentChoiceDialogComponent, [i5.Store, i10.TranslateService, i1.MAT_DIALOG_DATA], null, null)], null, null); }
var AssessmentChoiceDialogComponentNgFactory = i0.ɵccf("caple-assessment-choice-dialog", i11.AssessmentChoiceDialogComponent, View_AssessmentChoiceDialogComponent_Host_0, {}, {}, []);
export { AssessmentChoiceDialogComponentNgFactory as AssessmentChoiceDialogComponentNgFactory };
