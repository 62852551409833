import { WebsocketModule } from '../app/websockets/websocket.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export const environment = {
  runInProdMode: true,
  useMockApi: false,
  disableTimers: false,
  preload: true,
  matomoSiteId: '2',
  assessment: true,
  bnpIntegration: true
};

export const modules = [
  WebsocketModule.forRoot()
];

export const animationModule = BrowserAnimationsModule;
