import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { TokenRefreshPageComponent } from './refresh-page/token-refresh.component';
import { LoginFailureComponent } from './failure/login-failure.component';
import { LogoutComponent } from './logout/logout.component';

const loginRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    children: [
      {path: '', component: LoginPageComponent},
      {path: 'renew-token', component: TokenRefreshPageComponent},
      {path: 'failure', component: LoginFailureComponent}
    ]
  },
  {
    path: 'logout',
    component: LogoutComponent
  }
];

export const LoginRouting = RouterModule.forChild(loginRoutes);
export const TokenRenewUrl = 'login/renew-token';
