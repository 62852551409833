import {Component, EventEmitter, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {State} from '../../../state-management/reducers';
import {Observable} from 'rxjs';
import {ReadClient} from '../../../shared/api/models/readClient';
import {ClientId} from '../../../shared/api/models/clientId';
import {selectClients} from '../../state-management/selector';
import {LoadAll} from '../../../state-management/util/actions';
import {SLICE} from '../../state-management/slice';

@Component({
  selector: 'caple-select-client-form',
  templateUrl: './select-client-form.component.html'
})
export class SelectClientFormComponent {

  /**
   * Emits an event every time a client is selected
   */
  @Output()
  public clientChanged: EventEmitter<ClientId> = new EventEmitter<ClientId>();

  public clients$: Observable<ReadClient[]>;

  /**
   * The formGroup for the create client form
   */
  public form: FormGroup;

  constructor(private formBuilder: FormBuilder, private store: Store<State>) {
    this.store.dispatch(new LoadAll(SLICE.CLIENTS));
    this.clients$ = this.store.pipe(select(selectClients));

    this.form = formBuilder.group({
      'client': [undefined, Validators.required]
    });

    this.form.valueChanges.subscribe((formValue) => {
      this.clientChanged.emit({
        companyNumber: formValue.client.companyNumber
      });
    });
  }
}
