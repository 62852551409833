import { Action } from '@ngrx/store';

export enum LoginActions {
  LOGIN_SUCCESS = '[Login] Success',
  ACCESS_TOKEN_RENEWED = '[Login] Access Token Renewed',
  ACCESS_TOKEN_RENEW_FAILED = '[Login] Access Token Renew failed',
  UNAUTHENTICATED_ACCESS = '[Login] Unauthenticated access',
  UNAUTHORIZED_ACCESS = '[Login] Unauthorized access',
  LOGOUT = '[Login] Logout'
}

export class LoginSuccess implements Action {
  readonly type = LoginActions.LOGIN_SUCCESS;

  constructor(public accessToken: string, public redirectUri: string) {
  }
}

export class UnauthenticatedAccess implements Action {
  readonly type = LoginActions.UNAUTHENTICATED_ACCESS;

  constructor(public redirectUri: string) {
  }
}

export class UnauthorizedAccess implements Action {
  readonly type = LoginActions.UNAUTHORIZED_ACCESS;
}

export class Logout implements Action {
  readonly type = LoginActions.LOGOUT;
}

export class AccessTokenRenewed implements Action {
  readonly type = LoginActions.ACCESS_TOKEN_RENEWED;

  constructor(public accessToken: string) {
  }
}

export class AccessTokenRenewFailed implements Action {
  readonly type = LoginActions.ACCESS_TOKEN_RENEW_FAILED;

  constructor(public error: string, public errorDescription: string) {
  }
}

export type Actions = LoginSuccess | UnauthenticatedAccess | UnauthorizedAccess | Logout | AccessTokenRenewed | AccessTokenRenewFailed;
