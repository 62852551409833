/**
 * Caple Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type CovenantType = 'EBITDA' | 'TOTAL_NET_DEBT_TO_EBITDA' | 'EBITDA_TO_TOTAL_INTEREST' | 'EBITDA_TO_DS' | 'CFADS_TO_DS' | 'UNKNOWN';

export const CovenantType = {
    EBITDA: 'EBITDA' as CovenantType,
    TOTALNETDEBTTOEBITDA: 'TOTAL_NET_DEBT_TO_EBITDA' as CovenantType,
    EBITDATOTOTALINTEREST: 'EBITDA_TO_TOTAL_INTEREST' as CovenantType,
    EBITDATODS: 'EBITDA_TO_DS' as CovenantType,
    CFADSTODS: 'CFADS_TO_DS' as CovenantType,
    UNKNOWN: 'UNKNOWN' as CovenantType
}
