import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AssessmentChoiceDialogComponent} from './assessment-choice-dialog.component';
import {LayoutModule} from '../../../../shared/layout/layout.module';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
  imports: [
    // Angular
    CommonModule,
    TranslateModule.forChild(),

    // Caple Generic Modules
    LayoutModule
  ],
  declarations: [
    AssessmentChoiceDialogComponent
  ],
  entryComponents: [
    // Fix for https://github.com/angular/material2/issues/8473
    AssessmentChoiceDialogComponent
  ],
  exports: [
    AssessmentChoiceDialogComponent
  ]
})
export class AssessmentChoiceModule {
}
