import { createSelector } from '@ngrx/store';
import { selectLogin } from '../reducers';
import { State } from './reducer';
import { AccessToken } from '../../shared/authentication/access-token';

export const ORIGINATION_ROLES = ['partner', 'credit_officer', 'devops', 'client', 'direct_client'];
export const MONITORING_ROLES = ['monitoring_officer', 'reporting_source', 'loan_servicer', 'devops'];
export const ADMINISTRATION_ROLES = ['administrator', 'devops'];

export const selectAccessToken = createSelector(selectLogin, (state: State) => state.decodedAccessToken ? new AccessToken(state.encodedAccessToken, state.decodedAccessToken) : null);

export const selectIsAuthenticated = createSelector(selectLogin, selectAccessToken, (login: State, accessToken: AccessToken) => {
  return login.authenticated && accessToken.isNotExpired();
});

export const selectName = createSelector(selectAccessToken, (token: AccessToken) => token ? token.getName() : null);
export const selectNameAndEmail = createSelector(selectAccessToken, (token: AccessToken) => token ? {
  name: token.getName(),
  email: token.getEmail()
} : null);

export const selectCompanyId = createSelector(selectAccessToken, (token: AccessToken) => token ? token.getCompanyId() : null);

export const selectUserId = createSelector(selectAccessToken, (token: AccessToken) => token ? token.getUserId() : null);

export const selectRoles = createSelector(selectAccessToken, accessToken => accessToken ? accessToken.getRoles() : []);
export const selectHasRole = (role: string) => createSelector(selectRoles, roles => {
  return roles && roles.indexOf(role.toLowerCase()) > -1;
});
export const selectHasOneOfRoles = (inputRoles: string[]) => createSelector(selectRoles, roles => {
  return roles && roles.some(role => inputRoles.indexOf(role.toUpperCase()) > -1);
});
export const selectDomainsCount = createSelector(selectRoles, roles => {
  let domainsCount = 0;

  const checkRoles = (dashboardRoles) => {
    if (roles.some(role => dashboardRoles.includes(role))) {
      domainsCount++;
    }
  };

  checkRoles(ORIGINATION_ROLES);
  checkRoles(MONITORING_ROLES);
  checkRoles(ADMINISTRATION_ROLES);
  return domainsCount;
});
