import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { RepeatableItem } from './repeatable-item';
import { Subscription } from 'rxjs';
import { CapleFormGroup } from '../../util/caple-form';

@Component({
  selector: 'caple-repeatable-item-selector',
  template: '<ng-template #container></ng-template>'
})
export class RepeatableItemSelector implements OnInit, OnDestroy, OnChanges {
  @Input()
  public item: CapleFormGroup;

  @Input()
  public translationGroup: CapleFormGroup;

  @Input()
  public snapshotState: any;

  @Input()
  public showRemove: boolean;

  @Output()
  public removed: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  public component;

  @Input()
  public index: number;

  @Input()
  public data: any;

  @Input()
  public rowClass: string;

  @ViewChild('container', {read: ViewContainerRef, static: true})
  public container: ViewContainerRef;

  public componentRef: ComponentRef<any>;

  private subscriptions: Subscription = new Subscription();

  constructor(private resolver: ComponentFactoryResolver) {
  }

  ngOnInit(): void {
    this.container.clear();

    const factory: ComponentFactory<RepeatableItem> = this.resolver.resolveComponentFactory(this.component);

    this.componentRef = this.container.createComponent(factory);
    this.copyAllVariablesToComponentRef();
    this.subscriptions.add(this.componentRef.instance.removed.subscribe(() => this.removed.emit()));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.componentRef) {
      this.copyAllVariablesToComponentRef();
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.componentRef.destroy();
  }

  public afterAdd() {
    this.componentRef.instance.afterAdd();
  }

  public open() {
    this.componentRef.instance.open();
  }

  public close() {
    this.componentRef.instance.close();
  }

  private copyAllVariablesToComponentRef() {
    this.componentRef.instance.item = this.item;
    this.componentRef.instance.translationGroup = this.translationGroup;
    this.componentRef.instance.snapShotState = this.snapshotState;
    this.componentRef.instance.showRemove = this.showRemove;
    this.componentRef.instance.index = this.index;
    this.componentRef.instance.data = this.data;
    if (this.rowClass) {
      setTimeout(() => {
        this.componentRef.location.nativeElement.classList.add(this.rowClass);
      });
    }
  }
}
