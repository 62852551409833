import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../state-management/reducers';
import { TranslateService } from '@ngx-translate/core';
import { config } from '../../../environments/config';
import { Logout } from '../../state-management/login/actions';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'caple-login-failure-page',
  templateUrl: './login-failure.component.html'
})
export class LoginFailureComponent implements OnInit {
  constructor(private store: Store<State>, private translateService: TranslateService, private activatedRoute: ActivatedRoute) {
    // As we aren't logged in here, the preferences aren't loaded so the translations aren't loaded
    // We have to load them here ourselves to make sure the errors on the login page get translated
    // properly
    this.translateService.setDefaultLang('en-GB');
    this.translateService.use('en-GB');
  }

  public url = config.baseUrl;
  public reason: string;

  public ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.reason = params['reason'];
    });
  }

  /**
   * Logout instead of going back to /login directly.
   *
   * To prevent issues that happen down the line when users ended up here whilst still having a valid session.
   * Which happened because one spammed the back button to end up at the login screen again.
   */
  public logout() {
    this.store.dispatch(new Logout());
  }
}
