import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { HAMMER_LOADER } from '@angular/platform-browser';
import { DragDropModule } from '@angular/cdk/drag-drop';

/**
 * Module that allows us to easily import all material design components with a single module import
 */
@NgModule({
  providers: [
    {
      provide: HAMMER_LOADER,
      useValue: () => new Promise(() => {
      })
    }
  ],
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatMomentDateModule,
    MatDatepickerModule,
    MatSnackBarModule,
    MatInputModule,
    MatMenuModule,
    MatIconModule,
    MatCardModule,
    MatListModule,
    MatSelectModule,
    MatTooltipModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatIconModule,
    MatExpansionModule,
    MatGridListModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    DragDropModule
  ],
  exports: [
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatMomentDateModule,
    MatDatepickerModule,
    MatSnackBarModule,
    MatInputModule,
    MatMenuModule,
    MatIconModule,
    MatCardModule,
    MatListModule,
    MatSelectModule,
    MatTooltipModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatStepperModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatIconModule,
    MatExpansionModule,
    MatGridListModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    DragDropModule
  ]
})
export class MaterialDesignModule {

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: MaterialDesignModule
    };
  }

}
