import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {RepeatableItem} from '../../../../../../shared/layout/repeatable-form-item/repeatable-item';
import {CapleFormBuilder} from '../../../../../../shared/util/caple-form-builder';

@Component({
  selector: 'caple-ebitda-to-interest-expenses-covenant-item',
  styleUrls: ['./ebitda-to-interest-expenses-covenant-item.component.scss'],
  templateUrl: './ebitda-to-interest-expenses-covenant-item.component.html'
})
export class EbitdaToInterestExpensesCovenantItemComponent extends RepeatableItem {

  @Input()
  public item: FormGroup;

  @Input()
  public showRemove: boolean;

  @Input()
  public index: number;

  @Output()
  public removed: EventEmitter<void> = new EventEmitter<void>();

  public static produceFormGroup(val?: any) {
    val = val || {};
    return new CapleFormBuilder().group({
      'from': [val.from, [Validators.required]],
      'to': [val.to, Validators.required],
      'minimumRatio': [val.minimumRatio, Validators.required]
    });
  }

  public produceFormGroup(val?: any): FormGroup {
    return EbitdaToInterestExpensesCovenantItemComponent.produceFormGroup(val);
  }
}
