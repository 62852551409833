import { ElementRef, EventEmitter, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormArray } from '@angular/forms';
import { RepeatableItemSelector } from './repeabtable-item-selector';
import { RepeatableItem } from './repeatable-item';
import { Subscription } from 'rxjs';
import { ScrollTo } from '../../../state-management/layout/actions';
import { Angulartics2Piwik } from 'angulartics2/piwik';
import { State } from '../../../state-management/reducers';
import { Store } from '@ngrx/store';

export abstract class RepeatableArrayParent implements OnInit, OnDestroy {
  protected static DEFAULT_SHOW_REMOVE_FUNC = function (itemsFormArray: FormArray) {
    return itemsFormArray.length > 1 && itemsFormArray.enabled;
  };

  @ViewChildren(RepeatableItemSelector)
  public children: QueryList<RepeatableItemSelector>;

  @Input()
  public itemsFormArray: FormArray;

  @Input()
  public component: RepeatableItem;

  @Input()
  public showRemove: boolean | ((a: FormArray) => boolean) = RepeatableArrayParent.DEFAULT_SHOW_REMOVE_FUNC;

  @Input()
  public itemProducer = () => this.component.produceFormGroup();

  @Input()
  public closeOnAdd = false;

  @Input()
  public scrollAfterAdd = false;

  @Input()
  /**
   * If a Event emitter is set this component will not add a button and every time the event emitter emits a event a item will be added to the FormArray
   */
  public addButton: EventEmitter<void>;

  @Input()
  /**
   * Identifier for individual repeatable arrays to store their analytics data with.
   * When not defined, no analytics data for this array will be stored.
   */
  public analyticsKey: string = undefined;

  @Input()
  public data: any;

  protected subscriptions = new Subscription();

  constructor(protected store: Store<State>, protected elRef: ElementRef, protected angulartics2Piwik: Angulartics2Piwik) {
  }

  public ngOnInit(): void {
    if (this.addButton) {
      this.subscriptions.add(
        this.addButton.subscribe(() => {
          this.addItem();
        })
      );
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public addItem() {
    if (this.closeOnAdd) {
      this.children.forEach(child => child.close());
    }
    this.itemsFormArray.push(this.itemProducer());
    if (this.scrollAfterAdd) {
      this.scrollToLastElement();
    }
    setTimeout(() => this.children.last.afterAdd());
    if (this.analyticsKey) {
      this.angulartics2Piwik.eventTrack('add-' + this.analyticsKey, {
        category: 'repeatable-array'
      });
    }
  }

  public removeItem(index) {
    this.itemsFormArray.removeAt(index);
    if (this.analyticsKey) {
      this.angulartics2Piwik.eventTrack('remove-' + this.analyticsKey, {
        category: 'repeatable-array'
      });
    }
  }

  public removeItems() {
    while (this.itemsFormArray.length !== 0) {
      this.itemsFormArray.removeAt(0)
    }
  }

  public shouldShowRemove(itemsFormArray: FormArray): boolean {
    if (typeof this.showRemove === "boolean") {
      if (<boolean>this.showRemove === true) {
        return itemsFormArray.enabled;
      } else {
        return RepeatableArrayParent.DEFAULT_SHOW_REMOVE_FUNC(itemsFormArray);
      }
    } else {
      return this.showRemove(itemsFormArray);
    }
  }

  private scrollToLastElement() {
    const items = this.elRef.nativeElement.querySelectorAll('caple-repeatable-item-selector > *');
    if (items.length > 0) {
      const lastItem = items[items.length - 1];
      const offset = lastItem.getBoundingClientRect().height * 0.3;
      this.store.dispatch(new ScrollTo({target: lastItem, offset: offset}));
    }
  }
}
