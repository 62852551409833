import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { RepeatableItem } from '../../../../../shared/layout/repeatable-form-item/repeatable-item';
import { DateValidators } from '../../../../../shared/util/date-validators';
import { select, Store } from '@ngrx/store';
import { selectActiveFundingProcessCountry } from '../../../../state-management/selector';
import { MatExpansionPanel } from '@angular/material/expansion';
import { CapleFormBuilder } from '../../../../../shared/util/caple-form-builder';
var DebtFacilitiesItemComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DebtFacilitiesItemComponent, _super);
    function DebtFacilitiesItemComponent(store) {
        var _this = _super.call(this) || this;
        _this.store = store;
        _this.removed = new EventEmitter();
        _this.country$ = _this.store.pipe(select(selectActiveFundingProcessCountry));
        return _this;
    }
    DebtFacilitiesItemComponent.produceFormGroup = function (val) {
        val = val || {};
        return new CapleFormBuilder().group({
            'lender': [val.lender, Validators.required],
            'facilityType': [val.facilityType, Validators.required],
            'repaymentProfile': [val.repaymentProfile, Validators.required],
            'drawnAmount': [val.drawnAmount, [Validators.required, Validators.min(0)]],
            'facilityLimit': [val.facilityLimit, [Validators.required, Validators.min(0)]],
            'maturityDate': [val.maturityDate, [Validators.required, DateValidators.validDate]],
            'interestRate': [val.interestRate, [Validators.required, Validators.min(0)]],
            'securityType': [val.securityType, Validators.required],
            'financialCovenants': [val.financialCovenants, Validators.required],
            'nonFinancialCovenants': [val.nonFinancialCovenants, Validators.required]
        });
    };
    DebtFacilitiesItemComponent.prototype.ngOnInit = function () {
        this.openOnStart(this.expansionPanel);
    };
    DebtFacilitiesItemComponent.prototype.produceFormGroup = function (val) {
        return DebtFacilitiesItemComponent.produceFormGroup();
    };
    DebtFacilitiesItemComponent.prototype.equalToSnapShot = function (val) {
        return this.item.get('lender').value === val.lender &&
            this.item.get('facilityType').value === val.facilityType;
    };
    DebtFacilitiesItemComponent.prototype.open = function () {
        this.expansionPanel.open();
    };
    return DebtFacilitiesItemComponent;
}(RepeatableItem));
export { DebtFacilitiesItemComponent };
