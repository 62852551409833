import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LayoutModule } from '../shared/layout/layout.module';
import { CreateMonitoringFileDialogComponent } from './dashboard/create-monitoring-file-dialog/create-monitoring-file-dialog.component';
import { SelectCovenantsComponent } from './dashboard/create-monitoring-file-dialog/covenants.component';
import { AddReportingSourceDialogComponent } from './dashboard/monitoring-file/add-reporting-source-dialog/add-reporting-source-dialog.component';
import { EditCovenantsDialogComponent } from './dashboard/monitoring-file/edit-covenants-dialog/edit-covenants-dialog.component';
import { UploadReportingDocumentsDialogComponent } from './dashboard/monitoring-file/upload-reporting-documents/upload-reporting-documents-dialog.component';
import { UploadBlockComponent } from './dashboard/monitoring-file/upload-reporting-documents/upload-block.component';
import { EditGeneralInformationDialogComponent } from './dashboard/monitoring-file/edit-general-information-dialog/edit-general-information-dialog.component';
import { UpdateReportingSourceDialogComponent } from './dashboard/monitoring-file/update-reporting-source-dialog/update-reporting-source-dialog.component';

@NgModule({
  imports: [
    // Angular
    CommonModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),

    // Caple Generic Modules
    LayoutModule,
  ],
  declarations: [
    CreateMonitoringFileDialogComponent,
    SelectCovenantsComponent,
    AddReportingSourceDialogComponent,
    EditCovenantsDialogComponent,
    EditGeneralInformationDialogComponent,
    UploadReportingDocumentsDialogComponent,
    UploadBlockComponent,
    UpdateReportingSourceDialogComponent
  ],
  entryComponents: [
    CreateMonitoringFileDialogComponent,
    SelectCovenantsComponent,
    AddReportingSourceDialogComponent,
    EditCovenantsDialogComponent,
    EditGeneralInformationDialogComponent,
    UploadReportingDocumentsDialogComponent,
    UpdateReportingSourceDialogComponent
  ]
})
export class MonitoringEntryModule {
}
