import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'caple-upload-reporting-document-block',
  templateUrl: './upload-block.component.html',
  styleUrls: ['./upload-block.component.scss'],
})
export class UploadBlockComponent {

  @Input()
  public uploaded: boolean;

  @Input()
  public title: string;

  @Input()
  public type: string;

  @Input()
  public reuploadAvailable = false;

  @Output()
  public onDownload = new EventEmitter<File>();
}
