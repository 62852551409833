import { Observable, of, throwError } from 'rxjs';
import { catchError, flatMap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent
} from '@angular/common/http';
import { ApiError } from './models/apiError';

/**
 * We expect that all errors from the backend have a 'code' property.
 *
 * If our own api returns an error, this is always true. But if a gateway
 * return an error, or we get a 404, then the error doesn't have a 'code' property.
 *
 * This interceptor validates if a error has a 'code' property and if not, it sets a default one.
 */
@Injectable()
export class ApiErrorInterceptor implements HttpInterceptor {

  constructor() {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any>
    | HttpUserEvent<any>> {

    return next.handle(req).pipe(catchError((response: HttpErrorResponse) => {
        const errorCode$ = this.getErrorCode(response);

        return errorCode$.pipe(
          flatMap((apiError: ApiError) => {
              // Because we don't have a Clone/Copy method on the HttpErrorResponse
              // we have to manually create a new one so we can set the code
              const responseWithErrorCode = new HttpErrorResponse({
                error: apiError,
                headers: response.headers,
                status: response.status,
                statusText: response.statusText,
                url: response.url
              });

              return throwError(responseWithErrorCode)
            }
          )
        );
      }
    ));
  }

  private getErrorCode(response): Observable<ApiError> {
    if (response.error && response.error.code) {
      return of({code: response.error.code, values: response.error.values});
    }

    // It is possible that we get json (with an error code) in a blob if
    // for example a file download fails
    if (response.error && response.error.type === 'application/json') {
      return readFile(response.error)
        .pipe(
          map(txt => {
            return JSON.parse(txt);
          })
        )
    }

    if (response.status === 429) {
      return of({code: 'core.error.too-many-requests'});
    }
    return of({code: 'core.error.internal'});
  }
}

/**
 * Handy abstraction around to FileReader to make it work with Observables
 * Source: https://gist.github.com/iansinnott/3d0ba1e9edc3e6967bc51da7020926b0
 *
 * @param blob
 */
const readFile = (blob: Blob): Observable<string> => Observable.create(obs => {
  if (!(blob instanceof Blob)) {
    obs.error(new Error('`blob` must be an instance of File or Blob.'));
    return;
  }

  const reader = new FileReader();

  reader.onerror = err => obs.error(err);
  reader.onabort = err => obs.error(err);
  reader.onload = () => obs.next(reader.result);
  reader.onloadend = () => obs.complete();

  return reader.readAsText(blob);
});
