import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AbstractApiService } from './abstract-api.service';
import { Id } from '../models/id';
import { ReadEligibility } from '../models/readEligibility';
import { WithId } from '../../../state-management/util/actions';
import { EligibilityCheck } from '../models/eligibilityCheck';
import { ConnectEligibility } from '../models/connectEligibility';

export interface EligibilityCheckPayload extends WithId {
  eligibilityCheck: EligibilityCheck;
  correlationId: string | null;
}

export interface ConnectEligibilityPayload extends WithId {
  connectEligibility: ConnectEligibility;
}

@Injectable()
export class EligibilityService extends AbstractApiService {
  private readonly baseUrl = '/api/v1/eligibility';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getById(id: Id): Observable<ReadEligibility> {
    return this.get<ReadEligibility>(`${this.baseUrl}/${id.id}`);
  }

  public saveEligibility(payload: EligibilityCheckPayload) {
    return this.post(this.baseUrl, payload.eligibilityCheck);
  }

  public updateEligibility(payload: EligibilityCheckPayload) {
    return this.post(`${this.baseUrl}/${payload.id}`, payload.eligibilityCheck);
  }

  public connectEligibility(payload: ConnectEligibilityPayload) {
    return this.post(`${this.baseUrl}/${payload.id}/connect`, payload.connectEligibility);
  }

}
