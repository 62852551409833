/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../eligibility/basic-eligibility-criteria.component.ngfactory";
import * as i2 from "../../../../eligibility/basic-eligibility-criteria.component";
import * as i3 from "@ngrx/store";
import * as i4 from "@angular/common";
import * as i5 from "./basic-eligiblity.component";
var styles_BasicEligiblityComponent = [];
var RenderType_BasicEligiblityComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BasicEligiblityComponent, data: {} });
export { RenderType_BasicEligiblityComponent as RenderType_BasicEligiblityComponent };
export function View_BasicEligiblityComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { basicComponent: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 2, "caple-basic-eligibility-criteria", [], null, [[null, "onCreated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onCreated" === en)) {
        var pd_0 = (_co.eligibilityCreated($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_BasicEligibilityCriteriaComponent_0, i1.RenderType_BasicEligibilityCriteriaComponent)), i0.ɵdid(2, 180224, [[1, 4], ["basicComponent", 4]], 0, i2.BasicEligibilityCriteriaComponent, [i3.Store], { country: [0, "country"], passedText: [1, "passedText"], fundingProcessProductType: [2, "fundingProcessProductType"], sectorCode: [3, "sectorCode"], directors: [4, "directors"] }, { onCreated: "onCreated" }), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.country$)); var currVal_1 = "funding-process.start.passed-basic"; var currVal_2 = _co.productType; var currVal_3 = _co.sectorCode; var currVal_4 = _co.directors; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_BasicEligiblityComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "caple-start-funding-process-basic-eligibility", [], null, null, null, View_BasicEligiblityComponent_0, RenderType_BasicEligiblityComponent)), i0.ɵdid(1, 49152, null, 0, i5.BasicEligiblityComponent, [i3.Store], null, null)], null, null); }
var BasicEligiblityComponentNgFactory = i0.ɵccf("caple-start-funding-process-basic-eligibility", i5.BasicEligiblityComponent, View_BasicEligiblityComponent_Host_0, { sectorCode: "sectorCode", productType: "productType", directors: "directors" }, { checked: "checked", onCreated: "onCreated" }, []);
export { BasicEligiblityComponentNgFactory as BasicEligiblityComponentNgFactory };
