import { actions, EntityAction, WithId } from '../../state-management/util/actions';
import { AssessFilePayload, AssessItemPayload, FilePayload, FilePayloadWithName, KYCPackPayload, ResourceId } from '../../shared/api/services/kyc.service';
import { SLICE } from './slice';
import { Id } from '../../shared/api/models/id';
import { KYCBankingInformation } from '../../shared/api/models/kYCBankingInformation';

export const kycActions = {
  ...actions,
  DOWNLOAD_KYC_PACK: 'DOWNLOAD_KYC_PACK',
  DOWNLOAD_EXAMPLE: 'DOWNLOAD_EXAMPLE',
  DOWNLOAD_INSTRUCTIONS: 'DOWNLOAD_INSTRUCTIONS',
  DOWNLOAD_TEMPLATE: 'DOWNLOAD_TEMPLATE',
  DOWNLOAD_FILE: 'DOWNLOAD_FILE',
  ATTACH_FILE: 'ATTACH_FILE',
  ATTACH_FILE_SUCCESS: 'ATTACH_FILE_SUCCESS',
  REMOVE_FILE: 'REMOVE_FILE',
  REMOVE_FILE_SUCCESS: 'REMOVE_FILE_SUCCESS',
  ASSESS_FILE: 'ASSESS_FILE',
  ASSESS_FILE_SUCCESS: 'ASSESS_FILE_SUCCESS',
  ASSESS_ITEM: 'ASSESS_ITEM',
  ASSESS_ITEM_SUCCESS: 'ASSESS_ITEM_SUCCESS',
  COMPLETE_KYC: 'COMPLETE_KYC',
  COMPLETE_KYC_SUCCESS: 'COMPLETE_KYC_SUCCESS',
  UPGRADE_KYC: 'UPGRADE_KYC',
  UPGRADE_KYC_SUCCESS: 'UPGRADE_KYC_SUCCESS',
  REOPEN_KYC: 'REOPEN_KYC',
  REOPEN_KYC_SUCCESS: 'REOPEN_KYC_SUCCESS',
  UPDATE_BANKING_INFORMATION: 'UPDATE_BANKING_INFORMATION',
  UPDATE_BANKING_INFORMATION_SUCCESS: 'UPDATE_BANKING_INFORMATION_SUCCESS'
};

export interface KYCBankingInformationPayload extends WithId, KYCBankingInformation {

}

export class DownloadKYCPack extends EntityAction {
  protected actionName: string = kycActions.DOWNLOAD_KYC_PACK;

  constructor(payload: KYCPackPayload) {
    super(SLICE.KYC_FILE, payload);
  }
}

export abstract class DownloadResource extends EntityAction {
  protected constructor(payload: ResourceId, public translationKey?: string) {
    super(SLICE.KYC_FILE, payload);
  }
}

export class DownloadExample extends DownloadResource {
  protected actionName: string = kycActions.DOWNLOAD_EXAMPLE;

  constructor(payload: ResourceId, public translationKey?: string) {
    super(payload);
  }
}

export class DownloadTemplate extends DownloadResource {
  protected actionName: string = kycActions.DOWNLOAD_TEMPLATE;

  constructor(payload: ResourceId, public translationKey?: string) {
    super(payload);
  }
}

export class DownloadInstructions extends DownloadResource {
  protected actionName: string = kycActions.DOWNLOAD_INSTRUCTIONS;

  constructor(payload: ResourceId, public translationKey?: string) {
    super(payload);
  }
}

export class DownloadFile extends EntityAction {
  protected actionName: string = kycActions.DOWNLOAD_FILE;

  constructor(payload: FilePayloadWithName) {
    super(SLICE.KYC_FILE, payload);
  }
}

export class AttachFile extends EntityAction {
  protected actionName: string = kycActions.ATTACH_FILE;

  constructor(payload: FilePayloadWithName) {
    super(SLICE.KYC_FILE, payload);
  }
}

export class AttachFileSuccess extends EntityAction {
  protected actionName: string = kycActions.ATTACH_FILE_SUCCESS;

  constructor(payload: FilePayloadWithName) {
    super(SLICE.KYC_FILE, payload);
  }
}

export class RemoveFile extends EntityAction {
  protected actionName: string = kycActions.REMOVE_FILE;

  constructor(payload: FilePayload) {
    super(SLICE.KYC_FILE, payload);
  }
}

export class RemoveFileSuccess extends EntityAction {
  protected actionName: string = kycActions.REMOVE_FILE_SUCCESS;

  constructor(payload: FilePayload) {
    super(SLICE.KYC_FILE, payload);
  }
}

export class CompleteKYC extends EntityAction {
  protected actionName: string = kycActions.COMPLETE_KYC;

  constructor(payload: Id) {
    super(SLICE.KYC_FILE, payload);
  }
}

export class CompleteKYCSuccess extends EntityAction {
  protected actionName: string = kycActions.COMPLETE_KYC_SUCCESS;

  constructor(payload: Id) {
    super(SLICE.KYC_FILE, payload);
  }
}

export class ReopenKYC extends EntityAction {
  protected actionName: string = kycActions.REOPEN_KYC;

  constructor(payload: Id) {
    super(SLICE.KYC_FILE, payload);
  }
}

export class ReopenKYCSuccess extends EntityAction {
  protected actionName: string = kycActions.REOPEN_KYC_SUCCESS;

  constructor(payload: Id) {
    super(SLICE.KYC_FILE, payload);
  }
}

export class UpgradeKYC extends EntityAction {
  protected actionName: string = kycActions.UPGRADE_KYC;

  constructor(payload: Id) {
    super(SLICE.KYC_FILE, payload);
  }
}

export class UpgradeKYCSuccess extends EntityAction {
  protected actionName: string = kycActions.UPGRADE_KYC_SUCCESS;

  constructor(payload: Id) {
    super(SLICE.KYC_FILE, payload);
  }
}

export class AssessFile extends EntityAction {
  protected actionName: string = kycActions.ASSESS_FILE;

  constructor(payload: AssessFilePayload) {
    super(SLICE.KYC_FILE, payload);
  }
}

export class AssessFileSuccess extends EntityAction {
  protected actionName: string = kycActions.ASSESS_FILE_SUCCESS;

  constructor(payload: AssessFilePayload) {
    super(SLICE.KYC_FILE, payload);
  }
}

export class AssessItem extends EntityAction {
  protected actionName: string = kycActions.ASSESS_ITEM;

  constructor(payload: AssessItemPayload) {
    super(SLICE.KYC_FILE, payload);
  }
}

export class AssessItemSuccess extends EntityAction {
  protected actionName: string = kycActions.ASSESS_ITEM_SUCCESS;

  constructor(payload: AssessItemPayload) {
    super(SLICE.KYC_FILE, payload);
  }
}

export class UpdateBankingInformation extends EntityAction {
  protected actionName: string = kycActions.UPDATE_BANKING_INFORMATION;

  constructor(payload: KYCBankingInformationPayload) {
    super(SLICE.KYC_FILE, payload);
  }
}

export class UpdateBankingInformationSuccess extends EntityAction {
  protected actionName: string = kycActions.UPDATE_BANKING_INFORMATION_SUCCESS;

  constructor(payload: KYCBankingInformationPayload) {
    super(SLICE.KYC_FILE, payload);
  }
}
