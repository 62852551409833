import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { mergeMap } from 'rxjs/operators';
import { ExecutionResult } from '../../state-management/util/effect-functions';
import { EMPTY, Observable } from 'rxjs';

/**
 * Wrapper service around the saveAs function so it can be mocked in tests
 */
@Injectable()
export class FileSaverService {

  public saveAs(body: Blob, filename: string) {
    if (body instanceof Blob) {
      saveAs(body, filename);
    } else {
      console.error('parameter body should be a Blob');
    }
  }

}

export const saveFileWithNameFromHeader = (fileSaverService: FileSaverService) =>
  (sourceObservable: Observable<ExecutionResult>) =>
    sourceObservable.pipe(
      mergeMap((executionResult: ExecutionResult) => {
        const header = executionResult.result.headers.get('Content-Disposition');
        const filename = header.split('\"')[1];
        fileSaverService.saveAs(executionResult.result.body, filename);
        return EMPTY;
      }),
    )

export const saveFileWithExtensionFromHeader = (fileSaverService: FileSaverService, fileName: string) =>
  (sourceObservable: Observable<ExecutionResult>) =>
    sourceObservable.pipe(
      mergeMap((executionResult: ExecutionResult) => {
        const filenameFromHeader = getFileNameFromHeader(executionResult);
        const extension = getExtensionFromName(filenameFromHeader);
        fileSaverService.saveAs(executionResult.result.body, fileName + '.' + extension);
        return EMPTY;
      }),
    )

function getFileNameFromHeader(executionResult: ExecutionResult) {
  const header = executionResult.result.headers.get('Content-Disposition');
  return header.split('\"')[1];
}

function getExtensionFromName(filename: string) {
  const split = filename.split('.');
  return split[split.length - 1];
}

export const saveFile = (fileSaverService: FileSaverService, fileName: string) =>
  (sourceObservable: Observable<ExecutionResult>) =>
    sourceObservable.pipe(
      mergeMap((executionResult: ExecutionResult) => {
        fileSaverService.saveAs(executionResult.result.body, fileName);
        return EMPTY;
      }),
    )
