import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { AbstractApiService } from './abstract-api.service';
var AssessmentService = /** @class */ (function (_super) {
    tslib_1.__extends(AssessmentService, _super);
    function AssessmentService(httpClient) {
        var _this = _super.call(this, httpClient) || this;
        _this.baseUrl = '/api/v1/funding-processes';
        return _this;
    }
    AssessmentService.prototype.saveKycAssessment = function (payload) {
        return this.post(this.baseUrl + "/" + payload.id + "/assessment/kyc", payload);
    };
    AssessmentService.prototype.getKycPrefill = function (payload) {
        return this.get(this.baseUrl + "/" + payload.id + "/assessment/kyc/prefill");
    };
    AssessmentService.prototype.saveCreditAssessment = function (payload) {
        return this.post(this.baseUrl + "/" + payload.id + "/assessment/credit", payload);
    };
    AssessmentService.prototype.saveEligibilityAssessment = function (payload) {
        return this.post(this.baseUrl + "/" + payload.id + "/assessment/eligibility", payload);
    };
    AssessmentService.prototype.getEligibilityPrefill = function (payload) {
        return this.get(this.baseUrl + "/" + payload.id + "/assessment/eligibility/prefill");
    };
    AssessmentService.prototype.submitAssessment = function (payload) {
        return this.post(this.baseUrl + "/" + payload.id + "/assessment/submitted", {
            decision: payload.decision
        });
    };
    AssessmentService.prototype.importSensitisedFinancialsExcel = function (payload) {
        var formData = new FormData();
        formData.append('file', payload.file);
        return this.postFile(this.baseUrl + "/" + payload.id + "/assessment/credit/extract-financials", formData);
    };
    AssessmentService.prototype.exportSensitisedFinancialsExcel = function (payload) {
        return this.getFileWithProgress(this.baseUrl + "/" + payload.id + "/assessment/credit/sensitised/excel");
    };
    AssessmentService.prototype.attachCommunicationFile = function (payload) {
        return this.post(this.baseUrl + "/" + payload.id + "/assessment/communication", payload.fileDetails);
    };
    AssessmentService.prototype.removeCommunicationFile = function (payload) {
        return this.delete(this.baseUrl + "/" + payload.id + "/assessment/communication/" + payload.fileId);
    };
    AssessmentService.prototype.downloadCommunicationFile = function (payload) {
        return this.getFileWithProgress(this.baseUrl + "/" + payload.id + "/assessment/communication/" + payload.fileId);
    };
    AssessmentService.prototype.uploadAssessmentFile = function (id, file) {
        var formData = new FormData();
        formData.append('file', file);
        return this.postFile(this.baseUrl + "/" + id.id + "/assessment/files", formData);
    };
    return AssessmentService;
}(AbstractApiService));
export { AssessmentService };
