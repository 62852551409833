import { Component, ElementRef } from '@angular/core';
import { RepeatableArrayParent } from './repeatable-parent';
import { Store } from '@ngrx/store';
import { State } from '../../../state-management/reducers';
import { Angulartics2Piwik } from 'angulartics2/piwik';

@Component({
  selector: 'caple-repeatable-array',
  styleUrls: ['./repeatable-array.component.scss'],
  templateUrl: './repeatable-array.component.html'
})
export class RepeatableArrayComponent extends RepeatableArrayParent {

  constructor(store: Store<State>, elRef: ElementRef<any>, angulartics2Piwik: Angulartics2Piwik) {
    super(store, elRef, angulartics2Piwik);
  }
}
