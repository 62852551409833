<div class="pure-g">
  <div class="pure-u-5-6">
    <div class="label"><span>{{label}}</span></div>
  </div>
  <div class="pure-u-1-6">
    <mat-form-field class="mat-form-field-no-input">
      <input matInput [formControl]="formControlItem">
      <mat-radio-group [formControl]="formControlItem">
        <mat-radio-button value="yes">{{'generic.yes' | translate}}</mat-radio-button>
        <mat-radio-button value="no">{{'generic.no' | translate}}</mat-radio-button>
      </mat-radio-group>
      <mat-error *ngIf="formControlItem.hasError('required')">{{ 'generic.validation.required-selection' | translate}}</mat-error>
    </mat-form-field>
  </div>
</div>
