import { Moment } from 'moment';

export type DatePickerFilterFn = (date: Moment | null) => boolean;

export class DatePickerFilter {

  public static FILTERS = {
    'LAST_DAY_OF_MONTH': (date: Moment | null) => date.date() === date.daysInMonth()
  };

  public static getFilter(name: String) : DatePickerFilterFn {
    if (name === "LAST_DAY_OF_MONTH") {
      return DatePickerFilter.FILTERS.LAST_DAY_OF_MONTH;
    }

    return () => true;
  }

}
