<h2 mat-dialog-title>{{'funding-process.team.update-dialog' | translate}}</h2>
<mat-dialog-content>
  <form [formGroup]="form">
    <mat-label>{{ 'funding-process.team.users' | translate }}</mat-label>
    <mat-list>
      <caple-loading-wrapper [diameter]="36" [key]="['urn:users:load']">
        <mat-list-item *ngFor="let user of (users$ | async); last as last;" class="user-item">
          <div class="pure-g user-row">
            <div class="pure-u-1-2">
              <span>{{user.name}}</span>
            </div>
            <div class="pure-u-1-2 checkbox">
              <mat-checkbox
                (change)="onChange($event.checked)"
                (click)="$event.stopPropagation()"
                [formControl]="form.get(user.id)" [value]="user.id"></mat-checkbox>
            </div>
          </div>
          <mat-divider *ngIf="!last"></mat-divider>
        </mat-list-item>
      </caple-loading-wrapper>
    </mat-list>
  </form>

  <mat-dialog-actions>
    <button (click)="closeModal()" mat-button>
      {{ 'generic.cancel' | translate }}
    </button>

    <caple-loading-wrapper [diameter]="30" key="urn:funding-process:owner:update">
      <button (click)="updateUsers()" *ngIf="!updateDisabled" mat-button>
        <span>{{'funding-process.team.update' | translate }}</span>
      </button>

      <button *ngIf="updateDisabled" [disabled]="updateDisabled"
              mat-button>
        <span
          matTooltip="{{'funding-process.team.update-disabled' | translate}}">{{'funding-process.team.update' | translate }}</span>
      </button>
    </caple-loading-wrapper>
  </mat-dialog-actions>
</mat-dialog-content>
