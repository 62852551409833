import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { RepeatableItem } from '../../../../../shared/layout/repeatable-form-item/repeatable-item';
import { CapleFormBuilder } from '../../../../../shared/util/caple-form-builder';
var SuggestedConditionItemComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SuggestedConditionItemComponent, _super);
    function SuggestedConditionItemComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.removed = new EventEmitter();
        return _this;
    }
    SuggestedConditionItemComponent.produceFormGroup = function (val) {
        val = val || '';
        return new CapleFormBuilder().control(val, Validators.required);
    };
    SuggestedConditionItemComponent.prototype.produceFormGroup = function (val) {
        return SuggestedConditionItemComponent.produceFormGroup(val);
    };
    return SuggestedConditionItemComponent;
}(RepeatableItem));
export { SuggestedConditionItemComponent };
