import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {RepeatableItem} from '../../../../../shared/layout/repeatable-form-item/repeatable-item';

@Component({
  selector: 'caple-incumbent-lender-item',
  templateUrl: './incumbent-lender-item.component.html',
  styleUrls: ['./incumbent-lender-item.component.scss']
})
export class IncumbentLenderItemComponent extends RepeatableItem {

  @Input()
  public item: FormControl;

  @Input()
  public showRemove: boolean;

  @Input()
  public index: number;

  @Output()
  public removed: EventEmitter<void> = new EventEmitter<void>();

  public static produceFormGroup(val?: any): FormControl {
    val = val || '';
    return new FormBuilder().control(val, Validators.required);
  }

  public produceFormGroup(val?: any): FormControl {
    return IncumbentLenderItemComponent.produceFormGroup(val);
  }
}
