import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {MatExpansionPanel} from '@angular/material/expansion';
import {RepeatableItem} from '../../../../../shared/layout/repeatable-form-item/repeatable-item';
import {CapleFormGroup} from '../../../../../shared/util/caple-form';
import {CapleFormBuilder} from '../../../../../shared/util/caple-form-builder';

@Component({
  selector: 'caple-shareholder-item',
  templateUrl: './shareholder-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareholderItemComponent extends RepeatableItem implements OnInit {

  @Input()
  public item: FormGroup;

  @Input()
  public showRemove: boolean;

  @Input()
  public index: number;

  @Output()
  public removed: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(MatExpansionPanel, {static: true})
  public expansionPanel: MatExpansionPanel;

  public static produceFormGroup(val?: any): CapleFormGroup {
    val = val || {};
    return new CapleFormBuilder().group({
      'firstName': [val.firstName, Validators.required],
      'lastName': [val.lastName, Validators.required],
      'percentageOwned': [val.percentageOwned, Validators.required],
      'description': [val.description, Validators.required],
    });
  }

  public produceFormGroup(val?: any): FormGroup {
    return ShareholderItemComponent.produceFormGroup(val);
  }

  public ngOnInit(): void {
    this.openOnStart(this.expansionPanel);
  }
}
