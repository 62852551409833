import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AbstractApiService } from './abstract-api.service';
import { Announcement } from '../models/announcement';

@Injectable()
export class AnnouncementService extends AbstractApiService {
  private readonly baseUrl = '/api/v1/announcement/';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getAnnouncement(): Observable<Announcement> {
    return this.get<Announcement>(this.baseUrl);
  }

  public updateAnnouncement(payload: Announcement) {
    return this.post(this.baseUrl, payload);
  }

  public clearAnnouncement() {
    return this.delete(this.baseUrl);
  }
}
