import { Component, Input } from '@angular/core';

@Component({
  selector: 'caple-eligibility-criteria-result',
  templateUrl: './eligibility-criteria-result.component.html',
  styleUrls: ['./eligibility-criteria-result.component.scss']
})
export class EligibilityCriteriaResultComponent {

  @Input()
  public passedText: string;

  @Input()
  public errors: string[] = [];

  @Input()
  public notices: string[] = [];

  @Input()
  public link: string | null;

  @Input()
  public linkText: string | null;
}
