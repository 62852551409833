import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { RepeatableItem } from '../../../../../shared/layout/repeatable-form-item/repeatable-item';
import { MatExpansionPanel } from '@angular/material/expansion';
import { KYCContact } from '../../../../../shared/api/models/kYCContact';
import { CapleFormBuilder } from '../../../../../shared/util/caple-form-builder';
var RelatedPersonCategoryEnum = KYCContact.RelatedPersonCategoryEnum;
var ContactItemComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ContactItemComponent, _super);
    function ContactItemComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.relatedPersonCategories = Object.values(RelatedPersonCategoryEnum);
        _this.removed = new EventEmitter();
        return _this;
    }
    ContactItemComponent.produceFormGroup = function (val) {
        val = val || {};
        return new CapleFormBuilder().group({
            'firstName': [val.firstName, Validators.required],
            'lastName': [val.lastName, Validators.required],
            'nationality': [val.nationality, Validators.required],
            'birthDate': [val.birthDate, Validators.required],
            'placeOfBirth': [val.placeOfBirth, Validators.required],
            'countryOfTaxation': [val.countryOfTaxation],
            'countryOfResidency': [val.countryOfResidency],
            'percentageOfControlOrVotingRights': [val.percentageOfControlOrVotingRights],
            'relatedPersonCategory': [val.relatedPersonCategory],
            'politicallyExposedPerson': [val.politicallyExposedPerson],
            'highRiskPoliticallyExposedPerson': [val.highRiskPoliticallyExposedPerson],
            'politicallyExposedPersonFunction': [val.politicallyExposedPersonFunction],
            'politicallyExposedPersonCountryOfActivity': [val.politicallyExposedPersonCountryOfActivity],
            'reason': [val.reason],
        });
    };
    ContactItemComponent.prototype.produceFormGroup = function (val) {
        return ContactItemComponent.produceFormGroup(val);
    };
    ContactItemComponent.prototype.ngOnInit = function () {
        this.openOnStart(this.expansionPanel);
    };
    return ContactItemComponent;
}(RepeatableItem));
export { ContactItemComponent };
