import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { RepeatableItem } from '../../../shared/layout/repeatable-form-item/repeatable-item';
import { CovenantType } from '../../../shared/api/models/covenantType';
import { Comparator } from '../../../shared/api/models/comparator';
import { CapleFormBuilder } from '../../../shared/util/caple-form-builder';
var SelectCovenantsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SelectCovenantsComponent, _super);
    function SelectCovenantsComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.covenantType = Object.values(CovenantType).filter(function (item) { return item !== CovenantType.UNKNOWN; });
        _this.comparator = Comparator;
        _this.removed = new EventEmitter();
        return _this;
    }
    SelectCovenantsComponent.produceFormGroup = function (val) {
        var _this = this;
        val = val || {};
        var formBuilder = new CapleFormBuilder().group({
            'type': [val.type, Validators.required],
            'threshold': [val.threshold, [Validators.required, Validators.min(this.getValidatorMinValueForType(val.type))]],
            'comparator': [val.comparator, Validators.required],
            'activeFrom': [val.activeFrom, Validators.required],
            'activeTo': [val.activeTo, Validators.required]
        });
        formBuilder.get('type').valueChanges.subscribe(function (covenantType) {
            formBuilder.get('threshold').setValidators([Validators.required, Validators.min(_this.getValidatorMinValueForType(covenantType))]);
        });
        return formBuilder;
    };
    /**
     * By request from Tom, the MO officer.
     * The EBITDA in the covenant should be possible to fill in a negative number.
     * @See PLATFORM-1163
     *
     * @param typeName The name for the type we are checking for.
     */
    SelectCovenantsComponent.getValidatorMinValueForType = function (typeName) {
        // Arbitrarily chosen lower limit. Don't want to have that much of a negative EBITDA,
        // as we only offer loans of up to 5_000_000.
        var MIN_EBITDA_VALUE = -99000000;
        return typeName === CovenantType.EBITDA ? MIN_EBITDA_VALUE : 0;
    };
    SelectCovenantsComponent.prototype.produceFormGroup = function (val) {
        return SelectCovenantsComponent.produceFormGroup(val);
    };
    SelectCovenantsComponent.prototype.mapToTranslationKey = function (input) {
        return input.toLowerCase().split('_').join('-');
    };
    return SelectCovenantsComponent;
}(RepeatableItem));
export { SelectCovenantsComponent };
