<h2 mat-dialog-title>{{ 'monitoring.documents.upload-dialog.title' | translate}} {{reportingPeriodTitle}}</h2>
<mat-dialog-content>
  <div *ngIf="(status$ | async)?.financialsUploaded && (status$ | async)?.certificateUploaded" class="notice success">
    <i class="material-icons">done</i>
    <p
      *ngIf="(status$ | async)?.financialsUploaded && (status$ | async)?.certificateUploaded; else partialSaved">{{
      'monitoring.documents.upload-dialog.everything-uploaded' | translate}}</p>
    <ng-template #partialSaved>{{ 'monitoring.documents.upload-dialog.partially-uploaded' | translate}}</ng-template>
  </div>
  <div
    *ngIf="((status$ | async)?.financialsUploaded || (status$ | async)?.certificateUploaded) && ! ((status$ | async)?.financialsUploaded && (status$ | async)?.certificateUploaded)"
    class="notice info no-icon">
    <p>{{ 'monitoring.documents.upload-dialog.partially-uploaded' | translate}}</p>
  </div>
  <div *ngIf="data.disabled" class="notice info no-icon">
    <p>{{ 'monitoring.documents.available-message' | translate}}</p>
  </div>

  <div class="pure-g">
    <div class="pure-u-1-2 upload">
      <caple-upload-reporting-document-block
        [uploaded]="(status$ | async)?.certificateUploaded"
        [title]="'monitoring.documents.upload-dialog.certificate' | translate"
        (onDownload)="downloadCertificate()"
        type="certificate">
        <caple-file-upload-button class="upload-button"
          (fileSelected)="fileSelected($event)"
          label="{{'generic.upload' | translate}}"
          [accept]="['.pdf', '.doc', '.docx', '.png', '.jpg', '.jpeg']"
          [isSecondaryButton]="true"
          [disabled]="data.disabled"
          [disabledTooltip]="'monitoring.documents.available-message' | translate">
        </caple-file-upload-button>
      </caple-upload-reporting-document-block>
    </div>
    <div class="pure-u-1-2 upload">
      <caple-upload-reporting-document-block
        [uploaded]="(status$ | async)?.financialsUploaded"
        [title]="'monitoring.documents.title.' + data.reportingFrequency | translate "
        (onDownload)="downloadFinancials()"
        [reuploadAvailable]="(status$ | async)?.type === 'PARTIAL'"
        type="excel">
        <button (click)="downloadFinancialsTemplate()" class="download-template" mat-button type="button">
          <span>{{'monitoring.download-template' | translate}}</span>
          <i class="material-icons rotated">get_app</i>
        </button>
        <caple-excel-import-field class="upload-button"
          [isSecondaryButton]="true"
          (excelImported)="submitFinancials($event)"
          [importFn]="excelImportFn"
          [id]="data.id"
          label="{{ 'generic.upload' | translate }}"
          translationKey="monitoring.documents.importing"
          [disabled]="data.disabled"
          [disabledTooltip]="'monitoring.documents.available-message' | translate">
        </caple-excel-import-field>
        <caple-excel-import-field class="reupload-button"
          [isSecondaryButton]="true"
          (excelImported)="submitFinancials($event)"
          [importFn]="excelImportFn"
          [id]="data.id"
          label="{{ 'generic.reupload' | translate }}"
          translationKey="monitoring.documents.importing"
          [disabled]="data.disabled"
          [disabledTooltip]="'monitoring.documents.available-message' | translate">
        </caple-excel-import-field>
      </caple-upload-reporting-document-block>
    </div>
  </div>

  <mat-dialog-actions>
    <button mat-button (click)="closeModal()">
      {{ 'generic.close' | translate }}
    </button>
  </mat-dialog-actions>
</mat-dialog-content>

