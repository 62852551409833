import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AbstractApiService } from '../shared/api/services/abstract-api.service';
import { LocationStrategy } from '@angular/common';
import * as StackTraceParser from 'error-stack-parser';
import { JsError } from '../shared/api/models/jsError';

@Injectable()
export class GlobalErrorHandler extends AbstractApiService implements ErrorHandler {

  private readonly baseUrl = '/api/v1/frontend-errors';

  // We have to use the injector here as this component will get bootstrapped
  // very early on in the process and not all other dependencies are
  // available already at this stage
  constructor(httpClient: HttpClient, private injector: Injector) {
    super(httpClient);
  }

  public handleError(error: Error | HttpErrorResponse): void {
    if (error instanceof HttpErrorResponse) {
      // We don't specifically log these because we already catch most of them on the backlog
      // and to prevent a infinite-loop from happening if the 'logging of the error on the server'
      // fails
    } else {
      this.sendErrorToServer(error);
    }

    // Regardless of how we handle the error, always log it.
    console.error(error);
  }

  private sendErrorToServer(error: Error) {
    const body: JsError = {
      name: error.name || 'unknown',
      time: new Date(),
      url: this.injector.get(LocationStrategy).path(),
      message: error.message || 'unknown',
      stack: this.getStack(error)
    };

    this.post(this.baseUrl, body).subscribe();
  }

  private getStack(error: Error) {
    try {
      return StackTraceParser.parse(error);
    } catch (e) {
      return [];
    }
  }
}
