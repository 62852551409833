import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { AbstractApiService } from './abstract-api.service';
var CompanyService = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyService, _super);
    function CompanyService(httpClient) {
        var _this = _super.call(this, httpClient) || this;
        _this.baseUrl = '/api/v1/companies';
        return _this;
    }
    CompanyService.prototype.getAll = function () {
        return this.get(this.baseUrl);
    };
    CompanyService.prototype.getSingle = function (companyId) {
        return this.get(this.getSingleCompanyUrl(companyId.id));
    };
    CompanyService.prototype.create = function (payload) {
        return this.post(this.baseUrl, payload);
    };
    CompanyService.prototype.addRole = function (payload) {
        return this.post(this.getSingleCompanyUrl(payload.companyId) + '/roles', payload);
    };
    CompanyService.prototype.renameCompany = function (payload) {
        return this.post(this.getSingleCompanyUrl(payload.companyId) + '/rename', payload);
    };
    CompanyService.prototype.getAllUsersForCompany = function (companyId) {
        return this.get(this.getSingleCompanyUrl(companyId.id) + '/users');
    };
    CompanyService.prototype.createUserForCompany = function (payload) {
        return this.post(this.getSingleCompanyUrl(payload.companyId) + '/users', payload);
    };
    CompanyService.prototype.blockUser = function (payload) {
        return this.post(this.getSingleCompanyUrl(payload.companyId) + '/users/block', {
            id: payload.id
        });
    };
    CompanyService.prototype.unblockUser = function (payload) {
        return this.post(this.getSingleCompanyUrl(payload.companyId) + '/users/unblock', {
            id: payload.id
        });
    };
    CompanyService.prototype.resendWelcomeEmail = function (payload) {
        return this.post(this.getSingleCompanyUrl(payload.companyId) + '/users/resend-welcome-email', { id: payload.id });
    };
    CompanyService.prototype.getSingleCompanyUrl = function (id) {
        return this.baseUrl + '/' + id;
    };
    return CompanyService;
}(AbstractApiService));
export { CompanyService };
