/**
 * Caple Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type UploadCategory = 'FINANCIAL_YEAR_STATEMENTS' | 'PIPELINE_BREAKDOWN' | 'DUE_DILIGENCE_REPORT' | 'SALE_AND_PURCHASE_AGREEMENT' | 'SHAREHOLDER_LOAN_AGREEMENT' | 'VENDOR_LOAN_AGREEMENT' | 'ID_FACILITY_AGREEMENT' | 'LOAN_FACILITY_AGREEMENT' | 'OTHER';

export const UploadCategory = {
    FINANCIALYEARSTATEMENTS: 'FINANCIAL_YEAR_STATEMENTS' as UploadCategory,
    PIPELINEBREAKDOWN: 'PIPELINE_BREAKDOWN' as UploadCategory,
    DUEDILIGENCEREPORT: 'DUE_DILIGENCE_REPORT' as UploadCategory,
    SALEANDPURCHASEAGREEMENT: 'SALE_AND_PURCHASE_AGREEMENT' as UploadCategory,
    SHAREHOLDERLOANAGREEMENT: 'SHAREHOLDER_LOAN_AGREEMENT' as UploadCategory,
    VENDORLOANAGREEMENT: 'VENDOR_LOAN_AGREEMENT' as UploadCategory,
    IDFACILITYAGREEMENT: 'ID_FACILITY_AGREEMENT' as UploadCategory,
    LOANFACILITYAGREEMENT: 'LOAN_FACILITY_AGREEMENT' as UploadCategory,
    OTHER: 'OTHER' as UploadCategory
}
