/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./shared/layout/full-page-message/full-page-message.component.ngfactory";
import * as i2 from "./shared/layout/full-page-message/full-page-message.component";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./page-not-found.component";
import * as i5 from "@ngrx/store";
var styles_PageNotFoundComponent = [];
var RenderType_PageNotFoundComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageNotFoundComponent, data: {} });
export { RenderType_PageNotFoundComponent as RenderType_PageNotFoundComponent };
export function View_PageNotFoundComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "caple-full-page-message", [], null, null, null, i1.View_FullPageMessageComponent_0, i1.RenderType_FullPageMessageComponent)), i0.ɵdid(1, 49152, null, 0, i2.FullPageMessageComponent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 2, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(5, 0, null, 0, 2, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(8, 0, null, 0, 3, "p", [], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 2, "button", [["class", "mat-action-button no-extra-padding"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.home() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(10, null, ["", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform("404-page.title")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform("404-page.message")); _ck(_v, 6, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 10, 0, i0.ɵnov(_v, 11).transform("404-page.action")); _ck(_v, 10, 0, currVal_2); }); }
export function View_PageNotFoundComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "caple-page-not-found", [], null, null, null, View_PageNotFoundComponent_0, RenderType_PageNotFoundComponent)), i0.ɵdid(1, 49152, null, 0, i4.PageNotFoundComponent, [i5.Store], null, null)], null, null); }
var PageNotFoundComponentNgFactory = i0.ɵccf("caple-page-not-found", i4.PageNotFoundComponent, View_PageNotFoundComponent_Host_0, {}, {}, []);
export { PageNotFoundComponentNgFactory as PageNotFoundComponentNgFactory };
