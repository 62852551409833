/**
 * Caple Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type ProductType = 'GROWTH' | 'TRANSACTION' | 'ACQUISITION' | 'MBI';

export const ProductType = {
    GROWTH: 'GROWTH' as ProductType,
    TRANSACTION: 'TRANSACTION' as ProductType,
    ACQUISITION: 'ACQUISITION' as ProductType,
    MBI: 'MBI' as ProductType
}
