import * as tslib_1 from "tslib";
import { select, Store } from '@ngrx/store';
import { CloseModal, ScrollTo } from '../../state-management/layout/actions';
import { ElementRef, OnDestroy, OnInit } from '@angular/core';
import { LoadSingle } from '../../state-management/util/actions';
import { SLICE } from '../state-management/slice';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { selectDiscussion } from '../state-management/selector';
import { MarkAsRead, PostComment, StartDiscussion } from '../state-management/actions';
var DiscussionDialogComponent = /** @class */ (function () {
    function DiscussionDialogComponent(store, options) {
        this.store = store;
        this.options = options;
        /**
         * The content of the message the user is currently typing
         * @type {string}
         */
        this.message = '';
        /**
         * The ID of the current discussion, is a null of not set and other wise just the ID
         */
        this.discussionId = null;
        this.subscriptions = new Subscription();
        this.loadDiscussionSubscription = new Subscription();
    }
    DiscussionDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.add(this.options.discussionId$
            .pipe(filter(function (id) { return !!id; }))
            .subscribe(function (discussionId) {
            _this.updateDiscussionId(discussionId);
        }));
    };
    DiscussionDialogComponent.prototype.updateDiscussionId = function (discussionId) {
        var _this = this;
        this.discussionId = discussionId;
        var payload = {
            id: discussionId,
            resourceId: this.options.resourceId,
            resourceType: this.options.resourceType
        };
        this.store.dispatch(new LoadSingle(SLICE.DISCUSSION, payload));
        this.discussion$ = this.store.pipe(select(selectDiscussion({ id: discussionId })));
        // When a comment is added (or loaded for the first time), this will fire
        this.loadDiscussionSubscription.unsubscribe();
        this.loadDiscussionSubscription =
            this.store.pipe(select(selectDiscussion({ id: discussionId })), filter(function (discussion) { return !!discussion; }), distinctUntilChanged(function (previous, current) { return previous.comments.length === current.comments.length; })).subscribe(function (discussion) {
                _this.store.dispatch(new ScrollTo({ document: _this.messagesElement.nativeElement, target: '.bottom-anchor' }));
                if (discussion.unreadComments) {
                    _this.store.dispatch(new MarkAsRead({ id: discussionId, resourceId: _this.options.resourceId, resourceType: _this.options.resourceType }));
                }
            });
    };
    DiscussionDialogComponent.prototype.postComment = function () {
        if (this.discussionId) { // If the discussion is started already
            this.store.dispatch(new PostComment({
                resourceId: this.options.resourceId,
                resourceType: this.options.resourceType,
                id: this.discussionId,
                comment: {
                    message: this.message
                }
            }));
        }
        else {
            this.store.dispatch(new StartDiscussion(tslib_1.__assign({}, this.options.discussionLocation, { resourceId: this.options.resourceId, resourceType: this.options.resourceType, title: this.options.title, comment: { message: this.message } })));
        }
        this.message = '';
    };
    DiscussionDialogComponent.prototype.closeModal = function () {
        this.store.dispatch(new CloseModal());
    };
    DiscussionDialogComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
        this.loadDiscussionSubscription.unsubscribe();
    };
    return DiscussionDialogComponent;
}());
export { DiscussionDialogComponent };
