<h2 mat-dialog-title>{{ 'administration.client.promote-to-company-confirmation-title' |  translate}}</h2>
<mat-dialog-content>
  <h3>{{'administration.client.promote-to-company-confirmation-standard-client' | translate }}</h3>
  <caple-loading-wrapper key="urn:clients:promote" [diameter]="36">
    <button mat-button (click)="createStandardClient()">
      {{'administration.client.promote-to-company-confirmation-standard-client' | translate }}
    </button>
  </caple-loading-wrapper>
  <p>{{ 'administration.client.promote-to-company-confirmation-standard-client-explanation' |  translate}}</p>
  <p>{{ 'administration.client.promote-to-company-confirmation-kyc-note' |  translate}}</p>

  <mat-divider></mat-divider>

  <h3>{{'administration.client.promote-to-company-confirmation-direct-client' | translate }}</h3>
  <caple-loading-wrapper key="urn:clients:promote" [diameter]="36">
    <button mat-button (click)="createDirectClient()">
      {{'administration.client.promote-to-company-confirmation-direct-client' | translate }}
    </button>
  </caple-loading-wrapper>
  <p>{{ 'administration.client.promote-to-company-confirmation-direct-client-explanation' |  translate}}</p>

  <mat-dialog-actions>
    <button mat-button (click)="closeModal()">{{ 'generic.cancel' | translate }}</button>
  </mat-dialog-actions>
</mat-dialog-content>
