import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { State } from '../../../state-management/reducers';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { selectProgress } from '../../../state-management/layout/selector';
import { ProgressIndicator } from '../../../state-management/layout/reducer';

@Component({
  selector: 'caple-progress-indicator-dialog',
  templateUrl: './progress-indicator-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressIndicatorDialogComponent {

  public translationKey: string;
  public translationData: any;
  public progress$: Observable<ProgressIndicator>;

  constructor(private store: Store<State>, @Inject(MAT_DIALOG_DATA) private options: ProgressIndicatorDialogOptions) {
    this.translationKey = options.translationKey;
    this.translationData = options.translationData;
    this.progress$ = this.store.pipe(select(selectProgress));
  }

  public isTranferingData(progress: ProgressIndicator): boolean {
    return progress ? progress.percentage > 0 && progress.percentage < 100 : false;
  }
}

export interface ProgressIndicatorDialogOptions {
  translationKey: string,
  translationData: any;
}
