import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from './state-management/reducers';
import { RedirectTo } from './state-management/generic/actions';

@Component({
  selector: 'caple-page-not-found',
  templateUrl: './page-not-found.component.html'
})
export class PageNotFoundComponent {

  constructor(private store: Store<State>) {
  }

  public home() {
    this.store.dispatch(new RedirectTo(['/']));
  }
}
