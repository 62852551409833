import { DebtFacilitiesItemComponent } from '../funding-process/funding-proposal/growth/financials/debt-facilities-item.component';
import { HedgingFacilitiesItemComponent } from '../funding-process/funding-proposal/growth/financials/hedging-facilities-item.component';
import { RevenueByCountryItemComponent } from '../funding-process/funding-proposal/growth/business/revenue-by-country-item.component';
import { KeyManagementItemComponent } from '../funding-process/funding-proposal/growth/business/key-management-item.component';
import { ConditionItemComponent } from '../../conditions-precedent/conditions-precedent-file-form/condition-item.component';
import { ContactItemComponent } from '../funding-process/assessment/kyc/repeatables/contact-item.component';
import { ShareholderItemComponent } from '../funding-process/assessment/kyc/repeatables/shareholder-item.component';
import { SelectCovenantsComponent } from '../../monitoring/dashboard/create-monitoring-file-dialog/covenants.component';
import { FormControl } from '@angular/forms';
import { CapitalExpenditureCovenantItemComponent } from '../funding-process/assessment/credit/covenants/capital-expenditure/capital-expenditure-covenant-item.component';
import { EbitdaCovenantItemComponent } from '../funding-process/assessment/credit/covenants/ebitda/ebitda-covenant-item.component';
import { EbitdaToInterestExpensesCovenantItemComponent } from '../funding-process/assessment/credit/covenants/ebitda-to-interest/ebitda-to-interest-expenses-covenant-item.component';
import { SuggestedConditionItemComponent } from '../funding-process/assessment/credit/suggested-condition/suggested-condition-item.component';
import { IncumbentLenderItemComponent } from '../funding-process/assessment/credit/incumbent-lender/incumbent-lender-item.component';
import { PdScoreItemComponent } from '../funding-process/assessment/credit/pd-score/pd-score-item.component';
import { CapleFormGroup } from '../../shared/util/caple-form';
import { GuarantorItemComponent } from '../funding-process/assessment/credit/guarantor/guarantor-item.component';
import { CompanyFundingSourceItemComponent } from '../funding-process/funding-proposal/growth/v2/company/company-funding-source-item.component';
import { CompanyFundingUseItemComponent } from '../funding-process/funding-proposal/growth/v2/company/company-funding-use-item.component';
import { CompanyDebtFacilitiesV2ItemComponent } from '../funding-process/funding-proposal/growth/v2/company/company-debt-facilities-v2-item.component';

/**
 * Service to transform values from the api into FormGroup's
 * Needed during patching of values from the server.
 */
export class FormGroupMapperService {
  public static getFormGroupArray(key: string, value: any[]): FormControl[] | CapleFormGroup[] {
    const EMPTY_ARRAY = [];
    const SINGLE_ITEM_ARRAY = [{}];

    // Switch case on a string seems like a fragile way of determining types.
    // Tried to refactor the string to 'class.name' but failed miserably because of UglifyJS which
    // will mangle names to save space. This happens when the optimization flag is true in angular.json.
    switch (key) {
      case 'revenueByCountry':
        value = value || SINGLE_ITEM_ARRAY;
        return value.map(val => RevenueByCountryItemComponent.produceFormGroup(val));
      case 'keyManagement':
        value = value || SINGLE_ITEM_ARRAY;
        return value.map(val => KeyManagementItemComponent.produceFormGroup(val));
      case 'debtFacilities':
        value = value || SINGLE_ITEM_ARRAY;
        return value.map(val => DebtFacilitiesItemComponent.produceFormGroup(val));
      case 'companyDebtFacilities':
        value = value || SINGLE_ITEM_ARRAY;
        return value.map(val => CompanyDebtFacilitiesV2ItemComponent.produceFormGroup(val));
      case 'hedgingFacilities':
        value = value || EMPTY_ARRAY;
        return value.map(val => HedgingFacilitiesItemComponent.produceFormGroup(val));
      case 'conditions':
        value = value || SINGLE_ITEM_ARRAY;
        return value.map(val => ConditionItemComponent.produceFormGroup(val));
      case 'contacts':
        value = value || SINGLE_ITEM_ARRAY;
        return value.map(val => ContactItemComponent.produceFormGroup(val));
      case 'bearerSharesOwners':
        value = value || EMPTY_ARRAY;
        return value.map(val => ShareholderItemComponent.produceFormGroup(val));
      case 'covenants':
        value = value || SINGLE_ITEM_ARRAY;
        return value.map(val => SelectCovenantsComponent.produceFormGroup(val));
      case 'incumbentLenders':
        value = value || SINGLE_ITEM_ARRAY;
        return value.map(val => IncumbentLenderItemComponent.produceFormGroup(val));
      case 'guarantors':
        value = value || EMPTY_ARRAY;
        return value.map(val => GuarantorItemComponent.produceFormGroup(val));
      case 'suggestedConditionsPrecedent':
        value = value || SINGLE_ITEM_ARRAY;
        return value.map(val => SuggestedConditionItemComponent.produceFormGroup(val));
      case 'ebitdaToInterestExpenses':
        value = value || SINGLE_ITEM_ARRAY;
        return value.map(val => EbitdaToInterestExpensesCovenantItemComponent.produceFormGroup(val));
      case 'ebitdas':
        value = value || SINGLE_ITEM_ARRAY;
        return value.map(val => EbitdaCovenantItemComponent.produceFormGroup(val));
      case 'capitalExpenditures':
        value = value || SINGLE_ITEM_ARRAY;
        return value.map(val => CapitalExpenditureCovenantItemComponent.produceFormGroup(val));
      case 'pdScores':
        value = value || SINGLE_ITEM_ARRAY;
        return value.map(val => PdScoreItemComponent.produceFormGroup(val));
      case 'fundingSources':
        value = value || SINGLE_ITEM_ARRAY;
        return value.map(val => CompanyFundingSourceItemComponent.produceFormGroup(val));
      case 'fundingUses':
        value = value || SINGLE_ITEM_ARRAY;
        return value.map(val => CompanyFundingUseItemComponent.produceFormGroup(val));
      default:
        throw new TypeError('Only expecting FormArray\'s on preprogrammed keys found [' + key + ']');
    }
  }
}
