import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {selectCountry} from '../../../../state-management/preferences/selector';
import {Observable} from 'rxjs';
import {AdvancedEligibilityCriteriaComponent} from '../../../../eligibility/advanced-eligibility-criteria.component';
import {State} from '../../../../state-management/reducers';
import {SupportedCountry} from '../../../../shared/api/models/supportedCountry';

@Component({
  selector: 'caple-start-funding-process-advanced-eligibility',
  templateUrl: './advanced-eligibility.component.html'
})
export class AdvancedEligiblityComponent {

  @Input()
  public eligiblityId: string;

  @Output()
  public checked = new EventEmitter<void>();
  @Output()
  public onCreated = new EventEmitter<string>();
  public country$: Observable<SupportedCountry>;

  @ViewChild(AdvancedEligibilityCriteriaComponent, {static: false})
  private advancedComponent: AdvancedEligibilityCriteriaComponent;

  constructor(private store: Store<State>) {
    this.country$ = store.pipe(select(selectCountry));
  }

  public eligibilityCreated(event) {
    this.eligiblityId = event.id;
    this.onCreated.emit(event.id);
  }

  public startCheck() {
    this.advancedComponent.submitAdvancedEligibilityData();
    this.checked.emit();
  }
}
