<h2 mat-dialog-title>{{ 'monitoring.create-monitoring-file.title' | translate}}</h2>
<mat-dialog-content class="no-side-padding">
  <caple-loading-wrapper [key]="['urn:companies:load', 'urn:funding-processes:load']">
    <div>
      <mat-horizontal-stepper [linear]="true" #stepper (selectionChange)="stepChanged($event)">
        <ng-template matStepperIcon="edit">
          <mat-icon>done</mat-icon>
        </ng-template>

        <mat-step [stepControl]="form">
          <form [formGroup]="form">
            <ng-template matStepLabel>{{ 'monitoring.create-monitoring-file.general' | translate }}</ng-template>

            <div class="notice warning" *ngIf="(fundingProcesses$ | async)?.length == 0">
              <i class="material-icons">warning</i>
              <p>{{'monitoring.create-monitoring-file.no-funding-processes' | translate}}</p>
            </div>

            <div class="notice error" *ngIf="form.hasError('mismatchedReportingPeriodAndAnnual')">
              <i class="material-icons">error</i>
              <p>{{'monitoring.validation.invalid-reporting-period-annual-match' | translate}}</p>
            </div>

            <div *ngIf="(fundingProcesses$ | async)?.length > 0">
              <div class="pure-g">
                <div class="pure-u-1-1">
                  <mat-form-field class="full">
                    <mat-select placeholder="{{ 'monitoring.reporting-frequency.title' | translate }}" [formControl]="form.controls['reportingFrequency']">
                      <mat-option *ngFor="let frequency of reportingFrequencyValues" [value]="frequency">{{ 'monitoring.reporting-frequency.' + frequency |translate}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.hasError('required', ['reportingFrequency'])">{{ 'generic.validation.required' | translate}}</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="pure-g">
                <div class="pure-u-1-2">
                  <caple-date-picker placeholder="monitoring.create-monitoring-file.first-reporting-period-documents-expected"
                                     formControlName="firstReportingEndDate"
                                     [formControl]="form.get('firstReportingEndDate')"
                                     datePickerFilter="LAST_DAY_OF_MONTH">
                    <mat-error *ngIf="form.get('firstReportingEndDate').hasError('date')">{{ 'generic.validation.date' | translate}}</mat-error>
                    <mat-error *ngIf="form.get('firstReportingEndDate').hasError('required')">{{ 'generic.validation.required' | translate}}</mat-error>
                  </caple-date-picker>
                </div>
                <div class="pure-u-1-2">
                  <caple-date-picker placeholder="monitoring.create-monitoring-file.first-annual-budget-expected" formControlName="firstAnnualBudgetYearEndDate"
                                     [formControl]="form.get('firstAnnualBudgetYearEndDate')"
                                     datePickerFilter="LAST_DAY_OF_MONTH">
                    <mat-error *ngIf="form.get('firstAnnualBudgetYearEndDate').hasError('date')">{{ 'generic.validation.date' | translate}}</mat-error>
                    <mat-error *ngIf="form.get('firstAnnualBudgetYearEndDate').hasError('required')">{{ 'generic.validation.required' | translate}}</mat-error>
                  </caple-date-picker>
                </div>
              </div>

              <div class="pure-g">
                <div class="pure-u-1-2">
                  <caple-date-picker placeholder="monitoring.first-interest-payment-by" formControlName="firstInterestPayment"
                                     [formControl]="form.get('firstInterestPayment')">
                    <mat-error *ngIf="form.get('firstInterestPayment').hasError('date')">{{ 'generic.validation.date' | translate}}</mat-error>
                    <mat-error *ngIf="form.get('firstInterestPayment').hasError('required')">{{ 'generic.validation.required' | translate}}</mat-error>
                  </caple-date-picker>
                </div>
                <div class="pure-u-1-2">
                  <caple-date-picker placeholder="monitoring.first-redemption-payment-by" formControlName="firstRedemptionPayment"
                                     [formControl]="form.get('firstRedemptionPayment')">
                    <mat-error *ngIf="form.get('firstRedemptionPayment').hasError('date')">{{ 'generic.validation.date' | translate}}</mat-error>
                    <mat-error *ngIf="form.get('firstRedemptionPayment').hasError('required')">{{ 'generic.validation.required' | translate}}</mat-error>
                  </caple-date-picker>
                </div>
              </div>
              <div class="pure-g">
                <div class="pure-u-1-2">
                  <mat-form-field class="full">
                    <input type="number" matInput placeholder="{{ 'monitoring.term' | translate}}"
                           [formControl]="form.controls['term']"/>
                    <mat-error *ngIf="form.hasError('required', ['term'])">{{ 'generic.validation.required' | translate}}</mat-error>
                    <mat-error *ngIf="form.hasError('min', ['term'])">{{ 'generic.validation.min' | translate:{value: '1'} }}</mat-error>
                  </mat-form-field>
                </div>
                <div class="pure-u-1-2">
                  <mat-form-field class="full">
                    <input type="number" matInput placeholder="{{ 'monitoring.interest-rate' | translate}}"
                           [formControl]="form.controls['interestRate']"/>
                    <span matPrefix>%&nbsp;</span>
                    <mat-error *ngIf="form.hasError('required', ['interestRate'])">{{ 'generic.validation.required' | translate}}</mat-error>
                    <mat-error *ngIf="form.hasError('min', ['interestRate'])">{{ 'monitoring.create-monitoring-file.errors.interest-rate-between-0-100' |
                      translate}}
                    </mat-error>
                    <mat-error *ngIf="form.hasError('max', ['interestRate'])">{{ 'monitoring.create-monitoring-file.errors.interest-rate-between-0-100' |
                      translate}}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="pure-g">
                <div class="pure-u-1-2">
                  <mat-form-field class="full">
                    <caple-currency-input
                      placeholder="{{ 'monitoring.loan-amount' | translate}}"
                      [country]="getCountryForSelectedProcess((fundingProcesses$| async), form.controls['fundingProcess'])"
                      [formControl]="form.controls['loanAmount']"></caple-currency-input>
                    <mat-error *ngIf="form.hasError('required', ['loanAmount'])">{{ 'generic.validation.required' | translate}}</mat-error>
                    <mat-error *ngIf="form.hasError('min', ['loanAmount'])">{{ 'generic.validation.min' | translate:{value: '1'} }}</mat-error>
                  </mat-form-field>
                </div>

                <div class="pure-u-1-2">
                  <mat-form-field class="full">
                    <mat-select placeholder="{{ 'monitoring.borrower' | translate }}" [formControl]="form.controls['borrower']">
                      <mat-option *ngFor="let company of (companies$ | async)" [value]="company.id">{{ company.name }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.hasError('required', ['borrower'])">{{ 'generic.validation.required' | translate}}</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="pure-g">
                <div class="pure-u-1-1">
                  <mat-form-field class="full">
                    <mat-select placeholder="{{ 'monitoring.funding-process' | translate }}" [formControl]="form.controls['fundingProcess']">
                      <mat-option *ngFor="let fundingProcess of (fundingProcesses$ | async)" [value]="fundingProcess.id">
                        {{ fundingProcess.client.companyName }} - {{ fundingProcess.id}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.hasError('required', ['fundingProcess'])">{{ 'generic.validation.required' | translate}}</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="pure-g">
                <div class="pure-u-1-2">
                  <mat-form-field class="full">
                    <mat-select placeholder="{{ 'monitoring.reporting-source' | translate }}" [formControl]="form.get('reportingSource').get('reportingSourceId')">
                      <mat-option *ngFor="let company of (companies$ | async)" [value]="company.id">
                        {{ company.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.hasError('required', ['reportingSource'])">{{ 'generic.validation.required' | translate}}</mat-error>
                  </mat-form-field>
                </div>
                <div class="pure-u-1-2">
                  <caple-loading-wrapper [key]="['urn:users:load']" [diameter]="36">
                    <mat-form-field>
                      <mat-label>{{ 'monitoring.users' | translate }}</mat-label>
                      <mat-select [formControl]="form.get('reportingSource').get('reportingSourceUsers')" multiple>
                        <mat-option *ngFor="let user of (users$ | async)" [value]="user.id">{{user.name}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </caple-loading-wrapper>
                </div>
              </div>
              <div class="pure-g">
                <div class="pure-u-1-1">
                  <mat-form-field>
                    <mat-select placeholder="{{ 'monitoring.loan-servicer' | translate }}" [formControl]="form.controls['loanServicer']">
                      <mat-option *ngFor="let company of (loanServicers$ | async)" [value]="company.id">{{ company.name }} - {{company.country}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.hasError('required', ['loanServicer'])">{{ 'generic.validation.required' | translate}}</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="navigation-buttons">
              <button mat-button (click)="closeModal()">{{ 'generic.close' | translate }}</button>
              <button mat-button matStepperNext *ngIf="(fundingProcesses$ | async)?.length > 0">{{ 'generic.next' | translate }}</button>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="covenantsFormGroup">
          <ng-template matStepLabel>{{ 'monitoring.create-monitoring-file.covenants' | translate }}</ng-template>

          <form [formGroup]="covenantsFormGroup" class="covenants-form">
            <div class="repeatable-array-container">
              <caple-repeatable-array
                formArrayName="covenants"
                [itemsFormArray]="covenantsFormGroup.get('covenants')"
                [component]="selectCovenantsComponent"
                [itemProducer]="createCovenenant()">
              </caple-repeatable-array>
            </div>
          </form>
          <div class="navigation-buttons">
            <button mat-button (click)="closeModal()">{{ 'generic.close' | translate }}</button>
            <button mat-button matStepperPrevious>{{ 'generic.previous' | translate }}</button>
            <caple-loading-wrapper key="urn:monitoring-file:create" [diameter]="36">
              <button mat-button (click)="createMonitoringFile()" [disabled]="!form.valid||!covenantsFormGroup.valid">
                {{'monitoring.create-monitoring-file.create' | translate }}
              </button>
            </caple-loading-wrapper>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </caple-loading-wrapper>
</mat-dialog-content>

