/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./token-refresh.component";
var styles_TokenRefreshPageComponent = [];
var RenderType_TokenRefreshPageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TokenRefreshPageComponent, data: {} });
export { RenderType_TokenRefreshPageComponent as RenderType_TokenRefreshPageComponent };
export function View_TokenRefreshPageComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_TokenRefreshPageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "caple-token-refresh-page", [], null, null, null, View_TokenRefreshPageComponent_0, RenderType_TokenRefreshPageComponent)), i0.ɵdid(1, 49152, null, 0, i1.TokenRefreshPageComponent, [], null, null)], null, null); }
var TokenRefreshPageComponentNgFactory = i0.ɵccf("caple-token-refresh-page", i1.TokenRefreshPageComponent, View_TokenRefreshPageComponent_Host_0, {}, {}, []);
export { TokenRefreshPageComponentNgFactory as TokenRefreshPageComponentNgFactory };
