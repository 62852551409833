import * as tslib_1 from "tslib";
import { actions, EntityAction } from '../../state-management/util/actions';
import { SLICE } from './slice';
export var administrationActions = tslib_1.__assign({}, actions, { BLOCK_USER: 'BLOCK_USER', BLOCK_USER_SUCCESS: 'BLOCK_USER_SUCCESS', UNBLOCK_USER: 'UNBLOCK_USER', UNBLOCK_USER_SUCCESS: 'UNBLOCK_USER_SUCCESS', RESEND_WELCOME_EMAIL: 'RESEND_WELCOME_EMAIL', RESEND_WELCOME_EMAIL_SUCCESS: 'RESEND_WELCOME_EMAIL_SUCCESS', PROMOTE_TO_COMPANY: 'PROMOTE_TO_COMPANY', PROMOTE_TO_COMPANY_SUCCESS: 'PROMOTE_TO_COMPANY_SUCCESS', UPDATE_COMPANY_FILTERED_COUNTRIES: 'UPDATE_COMPANY_FILTERED_COUNTRIES', UPDATE_COMPANY_SEARCH_QUERY: 'UPDATE_COMPANY_SEARCH_QUERY' });
var BlockUser = /** @class */ (function (_super) {
    tslib_1.__extends(BlockUser, _super);
    function BlockUser(payload) {
        var _this = _super.call(this, SLICE.USER, payload) || this;
        _this.actionName = administrationActions.BLOCK_USER;
        return _this;
    }
    return BlockUser;
}(EntityAction));
export { BlockUser };
var BlockUserSuccess = /** @class */ (function (_super) {
    tslib_1.__extends(BlockUserSuccess, _super);
    function BlockUserSuccess(request) {
        var _this = _super.call(this, SLICE.USER, request) || this;
        _this.actionName = administrationActions.BLOCK_USER_SUCCESS;
        return _this;
    }
    return BlockUserSuccess;
}(EntityAction));
export { BlockUserSuccess };
var UnblockUser = /** @class */ (function (_super) {
    tslib_1.__extends(UnblockUser, _super);
    function UnblockUser(payload) {
        var _this = _super.call(this, SLICE.USER, payload) || this;
        _this.actionName = administrationActions.UNBLOCK_USER;
        return _this;
    }
    return UnblockUser;
}(EntityAction));
export { UnblockUser };
var UnblockUserSuccess = /** @class */ (function (_super) {
    tslib_1.__extends(UnblockUserSuccess, _super);
    function UnblockUserSuccess(request) {
        var _this = _super.call(this, SLICE.USER, request) || this;
        _this.actionName = administrationActions.UNBLOCK_USER_SUCCESS;
        return _this;
    }
    return UnblockUserSuccess;
}(EntityAction));
export { UnblockUserSuccess };
var ResendWelcomeEmail = /** @class */ (function (_super) {
    tslib_1.__extends(ResendWelcomeEmail, _super);
    function ResendWelcomeEmail(slice, payload) {
        var _this = _super.call(this, slice, payload) || this;
        _this.slice = slice;
        _this.actionName = administrationActions.RESEND_WELCOME_EMAIL;
        return _this;
    }
    return ResendWelcomeEmail;
}(EntityAction));
export { ResendWelcomeEmail };
var ResendWelcomeEmailSuccess = /** @class */ (function (_super) {
    tslib_1.__extends(ResendWelcomeEmailSuccess, _super);
    function ResendWelcomeEmailSuccess(request) {
        var _this = _super.call(this, SLICE.USER, request) || this;
        _this.actionName = administrationActions.RESEND_WELCOME_EMAIL_SUCCESS;
        return _this;
    }
    return ResendWelcomeEmailSuccess;
}(EntityAction));
export { ResendWelcomeEmailSuccess };
var PromoteToCompany = /** @class */ (function (_super) {
    tslib_1.__extends(PromoteToCompany, _super);
    function PromoteToCompany(payload) {
        var _this = _super.call(this, SLICE.CLIENT, payload) || this;
        _this.payload = payload;
        _this.actionName = administrationActions.PROMOTE_TO_COMPANY;
        return _this;
    }
    return PromoteToCompany;
}(EntityAction));
export { PromoteToCompany };
var PromoteToCompanySuccess = /** @class */ (function (_super) {
    tslib_1.__extends(PromoteToCompanySuccess, _super);
    function PromoteToCompanySuccess(payload, request) {
        var _this = _super.call(this, SLICE.CLIENT, payload) || this;
        _this.payload = payload;
        _this.request = request;
        _this.actionName = administrationActions.PROMOTE_TO_COMPANY_SUCCESS;
        return _this;
    }
    return PromoteToCompanySuccess;
}(EntityAction));
export { PromoteToCompanySuccess };
var UpdateCompanyFilteredCountries = /** @class */ (function (_super) {
    tslib_1.__extends(UpdateCompanyFilteredCountries, _super);
    function UpdateCompanyFilteredCountries(payload) {
        var _this = _super.call(this, SLICE.COMPANY, payload) || this;
        _this.actionName = administrationActions.UPDATE_COMPANY_FILTERED_COUNTRIES;
        return _this;
    }
    return UpdateCompanyFilteredCountries;
}(EntityAction));
export { UpdateCompanyFilteredCountries };
var UpdateCompanySearchQuery = /** @class */ (function (_super) {
    tslib_1.__extends(UpdateCompanySearchQuery, _super);
    function UpdateCompanySearchQuery(payload) {
        var _this = _super.call(this, SLICE.COMPANY, payload) || this;
        _this.actionName = administrationActions.UPDATE_COMPANY_SEARCH_QUERY;
        return _this;
    }
    return UpdateCompanySearchQuery;
}(EntityAction));
export { UpdateCompanySearchQuery };
