import * as tslib_1 from "tslib";
import { ErrorHandler, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AbstractApiService } from '../shared/api/services/abstract-api.service';
import { LocationStrategy } from '@angular/common';
import * as StackTraceParser from 'error-stack-parser';
var GlobalErrorHandler = /** @class */ (function (_super) {
    tslib_1.__extends(GlobalErrorHandler, _super);
    // We have to use the injector here as this component will get bootstrapped
    // very early on in the process and not all other dependencies are
    // available already at this stage
    function GlobalErrorHandler(httpClient, injector) {
        var _this = _super.call(this, httpClient) || this;
        _this.injector = injector;
        _this.baseUrl = '/api/v1/frontend-errors';
        return _this;
    }
    GlobalErrorHandler.prototype.handleError = function (error) {
        if (error instanceof HttpErrorResponse) {
            // We don't specifically log these because we already catch most of them on the backlog
            // and to prevent a infinite-loop from happening if the 'logging of the error on the server'
            // fails
        }
        else {
            this.sendErrorToServer(error);
        }
        // Regardless of how we handle the error, always log it.
        console.error(error);
    };
    GlobalErrorHandler.prototype.sendErrorToServer = function (error) {
        var body = {
            name: error.name || 'unknown',
            time: new Date(),
            url: this.injector.get(LocationStrategy).path(),
            message: error.message || 'unknown',
            stack: this.getStack(error)
        };
        this.post(this.baseUrl, body).subscribe();
    };
    GlobalErrorHandler.prototype.getStack = function (error) {
        try {
            return StackTraceParser.parse(error);
        }
        catch (e) {
            return [];
        }
    };
    return GlobalErrorHandler;
}(AbstractApiService));
export { GlobalErrorHandler };
