import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { RepeatableItem } from '../../../../../shared/layout/repeatable-form-item/repeatable-item';
import { CapleFormBuilder } from '../../../../../shared/util/caple-form-builder';
var PdScoreItemComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PdScoreItemComponent, _super);
    function PdScoreItemComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.removed = new EventEmitter();
        return _this;
    }
    PdScoreItemComponent.produceFormGroup = function (val) {
        val = val || {};
        return new CapleFormBuilder().group({
            'score': [val.score, [Validators.required, Validators.min(0)]],
            'name': [val.name, Validators.required],
            'accountYear': [val.accountYear, Validators.required]
        });
    };
    PdScoreItemComponent.prototype.produceFormGroup = function (val) {
        return PdScoreItemComponent.produceFormGroup(val);
    };
    return PdScoreItemComponent;
}(RepeatableItem));
export { PdScoreItemComponent };
