import { Injectable } from '@angular/core';
import { config } from '../../../environments/config';

@Injectable()
export class Auth0Factory {

  private static webAuth;

  private readonly auth0Config = {
    clientID: config.auth0ClientId,
    domain: config.domain,
    audience: config.audience,
    responseType: 'token id_token',
    redirectUri: config.baseUrl + 'login',
    scope: config.scope
  };

  public createInstance() {
    if (!Auth0Factory.webAuth) {
      Auth0Factory.webAuth = new auth0.WebAuth(this.auth0Config);
    }

    return Auth0Factory.webAuth;
  }

}
