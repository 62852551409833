import { AsyncValidatorFn, FormBuilder, ValidatorFn } from '@angular/forms';
import { Injectable } from '@angular/core';
import { CapleAbstractControl, CapleFormArray, CapleFormGroup } from './caple-form';

@Injectable()
/**
 * Standard Angular FormGroup's and FormArray's their patch functions cannot handle having an undefined or null as their value.
 * When loading state from the server we can run into problems with getting back fields that will still be null.
 * So we're using a custom FormBuilder to make it so that at form construction time we can use our own form objects.
 */
export class CapleFormBuilder extends FormBuilder {

  group(controlsConfig: { [p: string]: any }, extra?: { [p: string]: any } | null): CapleFormGroup {
    const formGroup = super.group(controlsConfig, extra);

    // Wrap the patch function of the formGroup
    const originalPatchValue = formGroup.patchValue;
    formGroup.patchValue = (value: {
      [key: string]: any;
    }, options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    }): void => {// Making sure to run the original function with the right this scope
      originalPatchValue.apply(formGroup, [value || {}, options]);
    };

    for (let controlKey in formGroup.controls) {
      let control = formGroup.controls[controlKey];
      (control as CapleFormGroup).name = controlKey;
    }

    return formGroup as CapleFormGroup;
  }

  public array(controlsConfig: any[], validator?: ValidatorFn | null, asyncValidator?: AsyncValidatorFn | null): CapleFormArray {
    const formArray = super.array(controlsConfig, validator, asyncValidator);

    const originalPatchValue = formArray.patchValue;
    formArray.patchValue = (value: any[], options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    }): void => {
      originalPatchValue.apply(formArray, [value || [], options]);
    };

    for (let controlKey in formArray.controls) {
      let control = formArray.controls[controlKey];
      (control as CapleAbstractControl).name = controlKey;
    }

    return formArray as CapleFormArray;
  }
}
