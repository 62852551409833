import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {RepeatableItem} from '../../../../../shared/layout/repeatable-form-item/repeatable-item';
import {DateValidators} from '../../../../../shared/util/date-validators';
import {MatExpansionPanel} from '@angular/material/expansion';
import {CapleFormGroup} from '../../../../../shared/util/caple-form';
import {CapleFormBuilder} from '../../../../../shared/util/caple-form-builder';
import {State} from '../../../../../state-management/reducers';
import {select, Store} from '@ngrx/store';
import {selectActiveFundingProposalMode} from '../../../../state-management/selector';
import {map} from 'rxjs/operators';
import {FundingProposalMode} from '../../shared/funding-proposal-mode';
import {Observable} from 'rxjs';

@Component({
  selector: 'caple-key-management-item',
  templateUrl: './key-management-item.component.html'
})
export class KeyManagementItemComponent extends RepeatableItem implements OnInit {

  @Input()
  public item: CapleFormGroup;

  @Input()
  public translationGroup: CapleFormGroup;

  @Input()
  public snapShotState: any;

  @Input()
  public showRemove: boolean;

  @Input()
  public index: number;

  @Output()
  public removed: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(MatExpansionPanel, {static: true})
  public expansionPanel: MatExpansionPanel;

  public modeIsDifference$: Observable<boolean>;

  constructor(private store: Store<State>) {
    super();
    this.modeIsDifference$ = this.store.pipe(
      select(selectActiveFundingProposalMode),
      map(mode => mode === FundingProposalMode.DIFFERENCE)
    );
  }

  public static produceFormGroup(val?: any): CapleFormGroup {
    val = val || {};
    return new CapleFormBuilder().group({
      'firstName': [val.firstName, Validators.required],
      'lastName': [val.lastName, Validators.required],
      'dateOfBirth': [val.dateOfBirth, [Validators.required, DateValidators.validDate]],
      'role': [val.role, Validators.required],
      'biography': [val.biography, Validators.required]
    });
  }

  public produceFormGroup(val?: any): FormGroup {
    return KeyManagementItemComponent.produceFormGroup(val);
  }

  public ngOnInit(): void {
    this.openOnStart(this.expansionPanel);
  }

  public equalToSnapShot(val: any): boolean {
    return val &&
      this.item.get('firstName').value === val.firstName &&
      this.item.get('lastName').value === val.lastName;
  }

  public open(): void {
    this.expansionPanel.open();
  }
}
