import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { State } from '../../../state-management/reducers';
import { selectIsLoading } from '../../../state-management/layout/selector';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'caple-loading-wrapper',
  templateUrl: './loading-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingWrapperComponent {

  /**
   * Size of the element
   */
  @Input()
  public diameter: number = 100;

  @Input()
  public fullscreen: boolean = false;

  private keySubject = new BehaviorSubject<string | Array<string>>('');

  @Input() set key(value: string | Array<string>) {
    this.keySubject.next(value);
  }

  public isLoading$ = this.keySubject.pipe(
    switchMap(key => this.store.pipe(select(selectIsLoading(key))))
  );

  constructor(private store: Store<State>) {
  }
}
