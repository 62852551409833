export const SLICE = {
  FUNDING_PROCESS_SUMMARY: '|ORG| FUNDING_PROCESS_SUMMARY',
  FUNDING_PROCESS: '|ORG| FUNDING_PROCESS',
  FUNDING_PROPOSAL: '|ORG| FUNDING_PROPOSAL',
  FUNDING_PROPOSAL_SNAPSHOT: '|ORG| FUNDING_PROPOSAL_SNAPSHOT',
  FUNDING_PROPOSAL_TRANSLATION: '|ORG| FUNDING_PROPOSAL_TRANSLATION',
  ASSESSMENT: '|ORG| ASSESSMENT',
  CLIENTS: '|ORG| CLIENTS',
  CLIENTS_USERS: '|ORG| CLIENT USERS',
  FINANCIAL_RATING_SUMMARY: '|ORG| FINANCIAL_RATING_SUMMARY',
  FINANCIAL_RATING: '|ORG| FINANCIAL_RATING',
  FINANCIAL_RATING_META_MODEL: '|ORG| FINANCIAL_RATING_META_MODEL',
  FUNDING_PROPOSAL_META_MODEL: '|ORG| FUNDING_PROPOSAL_META_MODEL',
  FUNDING_PROPOSAL_META_MODEL_LATEST: '|ORG| FUNDING_PROPOSAL_META_MODEL_LATEST',
  ELIGIBILITY: '|ORG| ELIGIBILITY',
  ELIGIBILITY_ASSESSMENT: '|ORG| ELIGIBILITY_ASSESSMENT',
  ELIGIBILITY_ASSESSMENT_PREFILL: '|ORG| ELIGIBILITY_ASSESSMENT_PREFILL',
  CHAMBER_OF_COMMERCE: '|ORG| CHAMBER_OF_COMMERCE',
  CHAMBER_OF_COMMERCE_NAME: '|ORG| CHAMBER_OF_COMMERCE_NAME',
  KYC_ASSESSMENT: '|ORG| KYC ASSESSMENT',
  KYC_ASSESSMENT_PREFILL: '|ORG| KYC ASSESSMENT_PREFILL',
  CREDIT_ASSESSMENT: '|ORG| CREDIT ASSESSMENT',
  COMMUNICATION_FILES: '|ORG| COMMUNICATION_FILES',
  KYC_STATUS: '|ORG| KYC_STATUS',
  TEAM: '|ORG| TEAM',
  COMPANY_USERS: '|ORG| COMPANY_USERS'
};
