import { createFeatureSelector } from '@ngrx/store';
import { createTableAdapter, Table } from '../../state-management/util/adapters';
import { actions, CreateSingleSuccess, EntityAction, UpdateSingleSuccess } from '../../state-management/util/actions';
import { ReadEligibility } from '../../shared/api/models/readEligibility';
import { typeFor } from '../../state-management/util/util';
import { SLICE } from './slice';
import { EligibilityCheckPayload } from '../../shared/api/services/eligibility.service';
import { ReadEligibilityCheck } from '../../shared/api/models/readEligibilityCheck';
import { EligibilityCheckType } from '../../shared/api/models/eligibilityCheckType';

/********
 * Adapters
 ********/
export const eligibilityAdapter = createTableAdapter<ReadEligibility>({
  idSelector: (obj: ReadEligibility) => obj.eligibilityId
});

/********
 * State
 ********/
export interface State {
  eligibilityChecks: Table<ReadEligibility>;
  correlationIdToEligibilityId: {
    [key: string]: string;
  };
}

export const initialState: State = {
  eligibilityChecks: eligibilityAdapter.getInitialState(),
  correlationIdToEligibilityId: {}
};

export function reducer(state = initialState, action: EntityAction): State {
  function isBasic(requestCheck) {
    return requestCheck.eligibilityCheckType === EligibilityCheckType.BASIC;
  }

  switch (action.type) {
    case typeFor(SLICE.ELIGIBILITY, actions.LOAD_SINGLE_SUCCESS): {
      return Object.assign({}, state, {
        eligibilityChecks: eligibilityAdapter.addOne(action.payload, state.eligibilityChecks)
      });
    }
    case typeFor(SLICE.ELIGIBILITY, actions.CREATE_SINGLE_SUCCESS): {
      const successAction = action as CreateSingleSuccess<EligibilityCheckPayload>;
      const id = successAction.payload.id;
      const requestCheck = successAction.request.eligibilityCheck;
      const correlationId = successAction.request.correlationId;

      const eligibilityCheck: ReadEligibilityCheck = {
        data: requestCheck.data,
        errors: requestCheck.errors
      };
      const result: ReadEligibility = {
        eligibilityId: id,
        basicEligibilityCheck: isBasic(requestCheck) ? eligibilityCheck : null,
        advancedEligibilityCheck: !isBasic(requestCheck) ? eligibilityCheck : null
      };

      const newObj = {};
      newObj[correlationId] = id;
      return Object.assign({}, state, {
        eligibilityChecks: eligibilityAdapter.addOne(result, state.eligibilityChecks),
        correlationIdToEligibilityId: Object.assign({}, state.correlationIdToEligibilityId, newObj)
      });
    }
    case typeFor(SLICE.ELIGIBILITY, actions.UPDATE_SINGLE_SUCCESS): {
      const successAction = action as UpdateSingleSuccess<EligibilityCheckPayload>;
      const id = successAction.request.id;
      const requestCheck = successAction.request.eligibilityCheck;

      const eligibilityCheck: ReadEligibilityCheck = {
        data: requestCheck.data,
        errors: requestCheck.errors
      };
      const updatedEligibility = isBasic(requestCheck)
        ? Object.assign({}, state.eligibilityChecks.entities[id], {basicEligibilityCheck: eligibilityCheck})
        : Object.assign({}, state.eligibilityChecks.entities[id], {advancedEligibilityCheck: eligibilityCheck});

      return Object.assign({}, state, {
        eligibilityChecks: eligibilityAdapter.updateOne(updatedEligibility, state.eligibilityChecks),
      });
    }
  }

  return state;
}

export const selectFeature = createFeatureSelector<State>('eligibility');
