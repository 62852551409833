import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {RepeatableItem} from '../../../../../shared/layout/repeatable-form-item/repeatable-item';
import {DateValidators} from '../../../../../shared/util/date-validators';
import {select, Store} from '@ngrx/store';
import {State} from '../../../../../state-management/reducers';
import {selectActiveFundingProcessCountry} from '../../../../state-management/selector';
import {MatExpansionPanel} from '@angular/material/expansion';
import {Observable} from 'rxjs';
import {CapleFormBuilder} from '../../../../../shared/util/caple-form-builder';
import {CapleFormGroup} from '../../../../../shared/util/caple-form';

@Component({
  selector: 'caple-debt-facilities-item',
  templateUrl: './debt-facilities-item.component.html'
})
export class DebtFacilitiesItemComponent extends RepeatableItem implements OnInit {
  @Input()
  public item: CapleFormGroup;

  @Input()
  public translationGroup: CapleFormGroup;

  @Input()
  public snapShotState: any;

  @Input()
  public showRemove: boolean;

  @Input()
  public index: number;

  @Output()
  public removed: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(MatExpansionPanel, {static: true})
  public expansionPanel: MatExpansionPanel;

  public country$: Observable<string>;

  constructor(private store: Store<State>) {
    super();
    this.country$ = this.store.pipe(select(selectActiveFundingProcessCountry));
  }

  public static produceFormGroup(val?: any) {
    val = val || {};
    return new CapleFormBuilder().group({
      'lender': [val.lender, Validators.required],
      'facilityType': [val.facilityType, Validators.required],
      'repaymentProfile': [val.repaymentProfile, Validators.required],
      'drawnAmount': [val.drawnAmount, [Validators.required, Validators.min(0)]],
      'facilityLimit': [val.facilityLimit, [Validators.required, Validators.min(0)]],
      'maturityDate': [val.maturityDate, [Validators.required, DateValidators.validDate]],
      'interestRate': [val.interestRate, [Validators.required, Validators.min(0)]],
      'securityType': [val.securityType, Validators.required],
      'financialCovenants': [val.financialCovenants, Validators.required],
      'nonFinancialCovenants': [val.nonFinancialCovenants, Validators.required]
    });
  }

  public ngOnInit(): void {
    this.openOnStart(this.expansionPanel);
  }

  public produceFormGroup(val?: any): FormGroup {
    return DebtFacilitiesItemComponent.produceFormGroup();
  }

  public equalToSnapShot(val: any): boolean {
    return this.item.get('lender').value === val.lender &&
      this.item.get('facilityType').value === val.facilityType;
  }

  public open(): void {
    this.expansionPanel.open();
  }
}
