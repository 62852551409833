/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./repeabtable-item-selector";
var styles_RepeatableItemSelector = [];
var RenderType_RepeatableItemSelector = i0.ɵcrt({ encapsulation: 2, styles: styles_RepeatableItemSelector, data: {} });
export { RenderType_RepeatableItemSelector as RenderType_RepeatableItemSelector };
function View_RepeatableItemSelector_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
export function View_RepeatableItemSelector_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { container: 0 }), (_l()(), i0.ɵand(16777216, [[1, 3], ["container", 2]], null, 0, null, View_RepeatableItemSelector_1))], null, null); }
export function View_RepeatableItemSelector_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "caple-repeatable-item-selector", [], null, null, null, View_RepeatableItemSelector_0, RenderType_RepeatableItemSelector)), i0.ɵdid(1, 770048, null, 0, i1.RepeatableItemSelector, [i0.ComponentFactoryResolver], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RepeatableItemSelectorNgFactory = i0.ɵccf("caple-repeatable-item-selector", i1.RepeatableItemSelector, View_RepeatableItemSelector_Host_0, { item: "item", translationGroup: "translationGroup", snapshotState: "snapshotState", showRemove: "showRemove", component: "component", index: "index", data: "data", rowClass: "rowClass" }, { removed: "removed" }, []);
export { RepeatableItemSelectorNgFactory as RepeatableItemSelectorNgFactory };
