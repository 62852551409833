import { Injectable } from '@angular/core';
import { HttpClient, HttpUploadProgressEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AbstractApiService } from './abstract-api.service';
import { Id } from '../models/id';
import { ReadMonitoringFile } from '../models/readMonitoringFile';
import { CreateMonitoringFile } from '../models/createMonitoringFile';
import { IdType, WithId } from '../../../state-management/util/actions';
import { FinancialModel } from '../models/financialModel';
import { UpdateCovenants } from '../models/updateCovenants';
import { UpdateGeneralInformation } from '../models/updateGeneralInformation';
import { FileUploaded } from '../models/fileUploaded';
import { CertificateUpload } from '../models/certificateUpload';
import { FinancialsUpload } from '../models/financialsUpload';
import { ReportingFrequency } from '../models/reportingFrequency';
import { ReadReportingDocuments } from '../models/readReportingDocuments';
import { ReportingPeriod } from '../models/reportingPeriod';

export interface DownloadReportingDocumentPayload extends WithId {
  startYear: number;
  endYear: number;
  reportingPeriodNumber: number;
  reportingFrequency: ReportingFrequency;
  borrower: string;
  language?: 'en';
  version?: number;
}

export interface ImportPayload extends WithId {
  reportingPeriodNumber: number;
  startYear: number;
  file: File;
}

export interface FinancialsUploadPayload extends WithId, FinancialsUpload {
  endYear: number;
}

export interface CertificateUploadPayload extends WithId, CertificateUpload {
}

export interface AddReportingSourcePayload extends WithId {
  reportingSource: string;
}

export interface UpdateReportingSourcePayload extends WithId {
  reportingSourceId: string;
  reportingSourceUsers: string[];
}

export interface RemoveReportingSourcePayload extends WithId {
  reportingSourceId: string;
}

export interface UpdateCovenantPayload extends WithId, UpdateCovenants {
}

export interface UpdateGeneralInformationPayload extends WithId, UpdateGeneralInformation {
}

export interface RequestResubmissionPayload extends WithId {
  reportingPeriod: ReportingPeriod,
  version: number;
}

export interface CompleteReportingPeriodPayload extends WithId {
  reportingPeriod: ReportingPeriod,
  version: number;
}

@Injectable()
export class MonitoringFilesService extends AbstractApiService {
  private readonly baseUrl = '/api/v1/monitoring-files';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getAll(): Observable<ReadMonitoringFile[]> {
    return this.get<ReadMonitoringFile[]>(this.baseUrl);
  }

  public create(create: CreateMonitoringFile): Observable<Id> {
    return this.post<Id>(this.baseUrl, create);
  }

  public getSingle(id: Id): Observable<ReadMonitoringFile> {
    return this.get<ReadMonitoringFile>(this.baseUrl + '/' + id.id);
  }

  public getFinancials(payload: DownloadReportingDocumentPayload) {
    let queryParams = '';
    if (payload.language || payload.version) {
      queryParams = this.buildURLQueryParameters({language: payload.language, version: payload.version});
    }
    const frequency = this.mapReportingFrequencyAttribute(payload.reportingFrequency);

    return this.getFileWithProgress(`${this.baseUrl}/${payload.id}/documents/${payload.startYear}/${payload.reportingPeriodNumber}/${frequency}/financials${queryParams}`);
  }

  public getCertificate(payload: DownloadReportingDocumentPayload) {
    let queryParams = '';
    if (payload.version) {
      queryParams = this.buildURLQueryParameters({version: payload.version});
    }
    const frequency = this.mapReportingFrequencyAttribute(payload.reportingFrequency);

    return this.getFileWithProgress(`${this.baseUrl}/${payload.id}/documents/${payload.startYear}/${payload.reportingPeriodNumber}/${frequency}/certificate${queryParams}`);
  }

  public getFinancialsTemplate(payload: DownloadReportingDocumentPayload) {
    return this.getFileWithProgress(`${this.baseUrl}/${payload.id}/documents/financials-template`);
  }

  public getAllReportingDocumentsForMonitoringFile(id: Id) {
    return this.get<ReadReportingDocuments[]>(`${this.baseUrl}/${id.id}/documents`);
  }

  public importFinancialsExcel(payload: ImportPayload): [Observable<FinancialModel>, Observable<HttpUploadProgressEvent>] {
    const formData = new FormData();
    formData.append('file', payload.file);

    return this.postFile(`${this.baseUrl}/${payload.id}/documents/financials-extract`, formData);
  }

  public uploadFile(id: IdType, file: File): [Observable<FileUploaded>, Observable<HttpUploadProgressEvent>] {
    const formData = new FormData();
    formData.append('file', file);

    return this.postFile(`${this.baseUrl}/${id}/documents/files`, formData);
  }

  public submitFinancials(payload: FinancialsUploadPayload): Observable<FinancialModel> {
    return this.post(`${this.baseUrl}/${payload.id}/documents/financials`, payload);
  }

  public submitCertificate(payload: CertificateUploadPayload): Observable<void> {
    return this.post(`${this.baseUrl}/${payload.id}/documents/certificate`, payload);
  }

  public resubmitFinancials(payload: FinancialsUploadPayload): Observable<FinancialModel> {
    return this.post(`${this.baseUrl}/${payload.id}/documents/financials/resubmit`, payload);
  }

  public resubmitCertificate(payload: CertificateUploadPayload): Observable<FinancialModel> {
    return this.post(`${this.baseUrl}/${payload.id}/documents/certificate/resubmit`, payload);
  }

  public addReportingSource(payload: AddReportingSourcePayload) {
    return this.post(`${this.baseUrl}/${payload.id}/add-reporting-source`, payload.reportingSource);
  }

  public updateReportingSource(payload: UpdateReportingSourcePayload) {
    return this.post(`${this.baseUrl}/${payload.id}/update-reporting-source`, payload);
  }

  public removeReportingSource(payload: RemoveReportingSourcePayload) {
    return this.post(`${this.baseUrl}/${payload.id}/remove-reporting-source`, payload);
  }

  public updateCovenants(payload: UpdateCovenantPayload) {
    return this.post(`${this.baseUrl}/${payload.id}/covenants`, {covenants: payload.covenants});
  }

  public updateGeneralInformation(payload: UpdateGeneralInformationPayload) {
    return this.post(`${this.baseUrl}/${payload.id}`, payload);
  }

  public requestResubmission(payload: RequestResubmissionPayload) {
    const frequency = this.mapReportingFrequencyAttribute(payload.reportingPeriod.reportingFrequency);
    return this.post(`${this.baseUrl}/${payload.id}/documents/${payload.reportingPeriod.startYear}/${payload.reportingPeriod.reportingPeriodNumber}/${frequency}/request-resubmission`, null);
  }

  public completeReportingPeriod(payload: CompleteReportingPeriodPayload) {
    const frequency = this.mapReportingFrequencyAttribute(payload.reportingPeriod.reportingFrequency);
    return this.post(`${this.baseUrl}/${payload.id}/documents/${payload.reportingPeriod.startYear}/${payload.reportingPeriod.reportingPeriodNumber}/${frequency}/complete`, null);
  }

  public mapReportingFrequencyAttribute(reportingFrequency: ReportingFrequency) {
    switch (reportingFrequency) {
      case ReportingFrequency.HALFYEARLY:
        return 'half-year';
      case ReportingFrequency.QUARTERLY:
        return 'quarter';
      default:
        throw new Error('Unknown reporting frequency' + reportingFrequency);
    }
  }
}
