import { MetaReducer } from '@ngrx/store';

export const config = {
  auth0namespace: 'https://platform.caple.eu',
  auth0ClientId: 'G6MvL3Yxw2ZU4d0Va2EZPR1E17mjtyO9',
  domain: 'test-login.caple.eu',
  scope: 'openid',
  audience: 'urn:caple-platform',
  baseUrl: 'https://test.caple.info/',
  wsBaseUrl: 'wss://api.test.caple.info/',
  apiUrl: 'https://api.test.caple.info'
};

export const metareducers: MetaReducer<any>[] = [];
