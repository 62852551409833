import { createSelector } from '@ngrx/store';
import * as Login from './login/reducer';
import * as Layout from './layout/reducer';
import * as Preferences from './preferences/reducer';
import { localStorageSync } from 'ngrx-store-localstorage';
import { metareducers } from '../../environments/config';
import { GenericActions } from './generic/actions';
import { analyticsReducer } from './analytics/AnalyticsReducer';
export var reducers = {
    login: Login.reducer,
    layout: Layout.reducer,
    preferences: Preferences.reducer
};
export function localStorageSyncReducer(reducer) {
    return localStorageSync({
        keys: ['login'],
        rehydrate: true,
        storage: localStorage
    })(reducer);
}
/**
 * This metareducer rewrites the current state to undefined in order to force all sub reducers to reset to their initial state.
 */
export function resetStateReducer(reducer) {
    return function (state, action) {
        if (state === void 0) { state = undefined; }
        if (action.type === GenericActions.RESET_STATE) {
            state = undefined;
        }
        return reducer(state, action);
    };
}
export var metaReducers = [
    localStorageSyncReducer,
    resetStateReducer,
    analyticsReducer
].concat(metareducers);
export var selectState = function (state) { return state; };
var ɵ0 = function (state) { return state.login; };
/** Login **/
export var selectLogin = createSelector(selectState, ɵ0);
var ɵ1 = function (state) { return state.preferences; };
/** Preferences **/
export var selectPreferences = createSelector(selectState, ɵ1);
export { ɵ0, ɵ1 };
