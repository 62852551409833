<h2 mat-dialog-title>{{ action$ | async }}</h2>
<mat-dialog-content>


  <div>
    <p>{{ question$ | async }}</p>

    <mat-dialog-actions align="end">
      <caple-loading-wrapper [diameter]="36" key="urn:funding-proposal:assess">
        <button (click)="closeModal()" mat-button>{{ 'generic.cancel' | translate }}</button>
        <button (click)="confirm()" mat-button>
          {{ action$ | async }}
        </button>
      </caple-loading-wrapper>
    </mat-dialog-actions>
  </div>
</mat-dialog-content>
