import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '../shared/layout/layout.module';
import { TranslateModule } from '@ngx-translate/core';
import { CreateCompanyDialogComponent } from './company/create-company/create-company-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ApiModule } from '../shared/api/api.module';
import { CreateUserDialogComponent } from './company/create-user/create-user-dialog.component';
import { UpgradeClientDialogComponent } from './company/client-overview/upgrade-client-dialog/upgrade-client-dialog.component';
import { RenameCompanyDialogComponent } from './company/rename-company/rename-company-dialog.component';

@NgModule({
  imports: [
    // Angular
    CommonModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),

    // Caple Generic Modules
    LayoutModule,
    ApiModule
  ],
  declarations: [
    CreateUserDialogComponent,
    CreateCompanyDialogComponent,
    UpgradeClientDialogComponent,
    RenameCompanyDialogComponent
  ],
  entryComponents: [
    CreateCompanyDialogComponent,
    CreateUserDialogComponent,
    UpgradeClientDialogComponent,
    RenameCompanyDialogComponent
  ]
})
export class AdministrationEntryModule {
}
