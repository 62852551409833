/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../create-client-form/create-client-form.component.ngfactory";
import * as i2 from "../../create-client-form/create-client-form.component";
import * as i3 from "@angular/forms";
import * as i4 from "@ngrx/store";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../select-client-form/select-client-form.component.ngfactory";
import * as i7 from "../../select-client-form/select-client-form.component";
import * as i8 from "@angular/common";
import * as i9 from "./company-information.component";
var styles_CompanyInformationComponent = [];
var RenderType_CompanyInformationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CompanyInformationComponent, data: {} });
export { RenderType_CompanyInformationComponent as RenderType_CompanyInformationComponent };
function View_CompanyInformationComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "caple-create-client-form", [], null, [[null, "statusChanged"], [null, "valueChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("statusChanged" === en)) {
        var pd_0 = ((_co.formIsValid = $event) !== false);
        ad = (pd_0 && ad);
    } if (("valueChanged" === en)) {
        var pd_1 = ((_co.newClient = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_CreateClientFormComponent_0, i1.RenderType_CreateClientFormComponent)), i0.ɵdid(2, 245760, null, 0, i2.CreateClientFormComponent, [i3.FormBuilder, i4.Store], null, { statusChanged: "statusChanged", valueChanged: "valueChanged" }), (_l()(), i0.ɵeld(3, 0, null, null, 2, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.forNewClient = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform("funding-process.start.for-existing-client")); _ck(_v, 4, 0, currVal_0); }); }
function View_CompanyInformationComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "caple-select-client-form", [], null, [[null, "clientChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clientChanged" === en)) {
        var pd_0 = ((_co.clientId = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_SelectClientFormComponent_0, i6.RenderType_SelectClientFormComponent)), i0.ɵdid(2, 49152, null, 0, i7.SelectClientFormComponent, [i3.FormBuilder, i4.Store], null, { clientChanged: "clientChanged" }), (_l()(), i0.ɵeld(3, 0, null, null, 2, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.forNewClient = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform("funding-process.start.for-new-client")); _ck(_v, 4, 0, currVal_0); }); }
export function View_CompanyInformationComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_CompanyInformationComponent_1)), i0.ɵdid(1, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CompanyInformationComponent_2)), i0.ɵdid(3, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.forNewClient; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.forNewClient; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_CompanyInformationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "caple-company-information", [], null, null, null, View_CompanyInformationComponent_0, RenderType_CompanyInformationComponent)), i0.ɵdid(1, 49152, null, 0, i9.CompanyInformationComponent, [], null, null)], null, null); }
var CompanyInformationComponentNgFactory = i0.ɵccf("caple-company-information", i9.CompanyInformationComponent, View_CompanyInformationComponent_Host_0, {}, {}, []);
export { CompanyInformationComponentNgFactory as CompanyInformationComponentNgFactory };
