import { containsSector, getSectorExclusionListForCountry } from './excluded-sectors';
import { SupportedCountry } from '../shared/api/models/supportedCountry';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const MIN_CAPLE_LOAN = 500000;
export const MAX_CAPLE_LOAN = 5000000;
export const MAX_ENTITIES_IN_GROUP = 20;
export const MIN_TURNOVER_TOTAL_ASSETS = 1000000;
export const MAX_TURNOVER_TOTAL_ASSETS = 50000000;
export const MIN_DIRECTORS_SHAREHOLDERS = 2;
export const MAX_NET_DEBT_TO_EBITDA = 4;
export const MIN_EBIT_TO_TOTAL_DEBT_SERVICE = 1.2;
export const MAX_CAPLE_LOAN_PERCENTAGE = 70;
export const MAX_LOAN_GRANTED = 10000000;

export function isSectorCodeValid(sectorCode: string, country: string): boolean {
  if (country) {
    const excludedSectors = getSectorExclusionListForCountry(SupportedCountry[country]);
    if (containsSector(excludedSectors, sectorCode)) {
      return false;
    }
    return true;
  } else {
    return false;
  }
}

export function isSectorCodeValidControl(country: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return isSectorCodeValid(control.value, country) ? null : {
      'excludedSector': {
        valid: false
      }
    };
  };
}

export function isTotalAssetsMinValid(totalAssets: number): boolean {
  return totalAssets >= MIN_TURNOVER_TOTAL_ASSETS;
}

export function isTurnoverMinValid(turnover: number): boolean {
  return turnover >= MIN_TURNOVER_TOTAL_ASSETS;
}

export function isTurnoverAndTotalAssetsMaxValid(turnover: number, totalAssets: number): boolean {
  return turnover <= MAX_TURNOVER_TOTAL_ASSETS || totalAssets <= MAX_TURNOVER_TOTAL_ASSETS;
}

export function isNetDebtToEBITDAValid(netDebtToEbida: number): boolean {
  return netDebtToEbida < MAX_NET_DEBT_TO_EBITDA;
}

export function isEBITToTotalDebtValid(ebitToTotalDebt: number): boolean {
  return ebitToTotalDebt > MIN_EBIT_TO_TOTAL_DEBT_SERVICE;
}

export function isCapleLoanPercentageHigh(capleLoanPercentage: number) {
  return capleLoanPercentage <= MAX_CAPLE_LOAN_PERCENTAGE;
}
