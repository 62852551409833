import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../state-management/reducers';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FinancialModel } from '../../api/models/financialModel';
import { FileUploadFailed, HideProgressIndicator, ShowProgressIndicator, UpdateProgressIndicator } from '../../../state-management/layout/actions';
import { ApiError } from '../../api/api-error';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { HttpEventType, HttpProgressEvent, HttpUploadProgressEvent } from '@angular/common/http';

@Component({
  selector: 'caple-excel-import-field',
  templateUrl: './excel-import-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ExcelImportFieldComponent),
      multi: true
    }
  ]
})
export class ExcelImportFieldComponent implements ControlValueAccessor {

  public readonly ACCEPTED_FILE_EXTENTIONS = ['.xlsx'];

  /**
   * The label that is displayed on the upload button
   */
  @Input()
  public label: string;

  /**
   * The id of the entity this excel is imported for or an object representing the id
   */
  @Input()
  public id: string | object;

  /**
   * The translation key that is shown in the progress dialog
   */
  @Input()
  public translationKey = "generic.importing-file";

  /**
   * If the component should be hidden
   */
  @Input()
  public hidden = false;

  /**
   * If the component should be disabled
   */
  @Input()
  public disabled = false;

  @Input()
  public disabledTooltip;

  @Input()
  public isActionButton: boolean = false;

  @Input()
  public isSecondaryButton: boolean = false;

  @Input()
  public isTextareaButton: boolean = false;

  @Input()
  public isPrimaryButton: boolean = false;

  @Input()
  public importFn: (_: any) => [Observable<FinancialModel>, Observable<HttpUploadProgressEvent>];

  @Output()
  public excelImported = new EventEmitter<FinancialModel>();

  constructor(private store: Store<State>) {
  }

  /**
   * Upload the file and after upload, store the file
   */
  public onFileSelected($event: File) {
    this.store.dispatch(new ShowProgressIndicator(this.translationKey));

    const [uploadFileObservable$, progressObservable$] = this.startImport($event);

    progressObservable$.pipe(
      tap((event: HttpProgressEvent) => {
        if (event.type === HttpEventType.UploadProgress) {
          const percentDone = Math.round((event.loaded / event.total) * 100);
          this.store.dispatch(new UpdateProgressIndicator(percentDone, event.loaded, event.total));
        }
      }),
      finalize(() => this.store.dispatch(new HideProgressIndicator()))
    ).subscribe();

    uploadFileObservable$
      .subscribe(
        response => {
          this.excelImported.emit(response);
        },
        error => {
          const apiError = new ApiError(error.error.code, error.error.values);
          this.store.dispatch(new FileUploadFailed(apiError));
        }
      );
  }

  private startImport(file: File): [Observable<FinancialModel>, Observable<HttpUploadProgressEvent>] {
    if (typeof this.id === 'string') {
      return this.importFn({id: this.id, file});
    } else {
      return this.importFn({...this.id, file});
    }
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // ****************************
  // Below methods are empty on purpose
  // ****************************

  public registerOnChange(fn: any): void {
  }

  public registerOnTouched(fn: any): void {
  }

  public writeValue(obj: any): void {
  }
}
