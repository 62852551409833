import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {RepeatableItem} from '../../../../../shared/layout/repeatable-form-item/repeatable-item';
import {CapleFormBuilder} from '../../../../../shared/util/caple-form-builder';
import {CapleFormGroup} from '../../../../../shared/util/caple-form';

@Component({
  selector: 'caple-pd-score-item',
  templateUrl: './pd-score-item.component.html',
  styleUrls: ['./pd-score-item.component.scss']
})
export class PdScoreItemComponent extends RepeatableItem {

  @Input()
  public item: FormGroup;

  @Input()
  public showRemove: boolean;

  @Input()
  public index: number;

  @Output()
  public removed: EventEmitter<void> = new EventEmitter<void>();

  public static produceFormGroup(val?: any): CapleFormGroup {
    val = val || {};
    return new CapleFormBuilder().group({
      'score': [val.score, [Validators.required, Validators.min(0)]],
      'name': [val.name, Validators.required],
      'accountYear': [val.accountYear, Validators.required]
    });
  }

  public produceFormGroup(val?: any): FormGroup {
    return PdScoreItemComponent.produceFormGroup(val);
  }
}
