import { combineLatest, interval, of, Subscription } from 'rxjs';
import { distinctUntilKeyChanged, filter, startWith } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { State } from '../../state-management/reducers';
import { environment } from '../../../environments/environment';
import { LoadSingleWithoutId } from '../../state-management/util/actions';
import { SLICE } from '../../state-management/layout/slice';
import { selectAccessToken } from '../../state-management/login/selector';

@Injectable()
export class AnnouncementRefreshService implements OnDestroy {

  private static readonly REFRESH_INTERVAL = 600000; // 10 Minutes

  private announcementRefreshSubscription: Subscription;

  constructor(private store: Store<State>) {
  }

  public enableAutoRefresh() {
    if (this.announcementRefreshSubscription) {
      this.announcementRefreshSubscription.unsubscribe();
    }

    this.announcementRefreshSubscription =
      combineLatest(
        AnnouncementRefreshService.getTimer(),
        this.store.pipe(
          select(selectAccessToken),
          filter(a => !!a),
          distinctUntilKeyChanged('getToken')
        )
      ).subscribe(([_, accessToken]) => {
        if (accessToken && accessToken.isNotExpired()) {
          this.store.dispatch(new LoadSingleWithoutId(SLICE.ANNOUNCEMENT, true));
        }
      });
  }

  private static getTimer() {
    if (!environment.disableTimers) {
      return interval(AnnouncementRefreshService.REFRESH_INTERVAL)
        .pipe(
          startWith(0)
        );
    } else {
      return of(0);
    }
  }

  public ngOnDestroy(): void {
    if (this.announcementRefreshSubscription) {
      this.announcementRefreshSubscription.unsubscribe();
    }
  }

}

export function enableAutoRefresh(announcementRefreshService: AnnouncementRefreshService) {
  return () => announcementRefreshService.enableAutoRefresh();
}
