import { Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';
import { DateAdapter } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePickerFilter, DatePickerFilterFn } from './date-picker.filter';
import { State } from '../../../state-management/reducers';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { selectLocale } from '../../../state-management/preferences/selector';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'caple-date-picker',
  templateUrl: './date-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    }
  ],
  styleUrls: ['date-picker.component.scss']
})
/**
 * Takes care of dealing with differently formatted date information coming from different sources.
 * Like the date picker text input field and to and from the server
 */
export class DatePickerComponent implements ControlValueAccessor, OnDestroy {

  @Input()
  public placeholder: string;

  @Input()
  public formControl: any;

  @Input()
  public minDate: Date;

  @Input()
  public maxDate: Date;

  @Input()
  public datePickerFilter: string | DatePickerFilterFn;

  public isDisabled = false;

  private subscriptions: Subscription = new Subscription();

  private onChange = (_: any) => {
  }

  public onTouched = () => {
  }

  public constructor(private store: Store<State>, private adapter: DateAdapter<any>) {
    this.subscriptions.add(
      store.pipe(
        select(selectLocale),
        filter(val => !!val)
      ).subscribe(locale => adapter.setLocale(locale))
    );
  }

  public dateChange($event: MatDatepickerInputEvent<Date>) {
    const formattedValue = moment($event.value).format('Y-MM-DD');
    if (formattedValue !== 'Invalid date') {
      this.onChange(formattedValue);
    } else {
      this.onChange(null);
    }
  }

  public get filter() {
    if (typeof this.datePickerFilter === 'function') {
      return this.datePickerFilter;
    }

    return DatePickerFilter.getFilter(this.datePickerFilter);
  }

  public onDatepickerClosed() {
    this.onTouched();
  }

  /**
   * Implementation for the ControlValueAccessor interface
   */
  public writeValue(obj: any): void {
  }

  /**
   * Implementation for the ControlValueAccessor interface
   */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Implementation for the ControlValueAccessor interface
   */
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Implementation for the ControlValueAccessor interface
   */
  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
