import * as tslib_1 from "tslib";
import { typeFor } from './util';
/**
 * The generic actions that are supported
 */
export var actions = {
    SELECT: 'SELECT',
    FAIL: 'FAIL',
    LOAD_ALL: 'LOAD_ALL',
    LOAD_ALL_WITHOUT_ID: 'LOAD_ALL_WITHOUT_ID',
    LOAD_ALL_SUCCESS: 'LOAD_ALL_SUCCESS',
    LOAD_SINGLE: 'LOAD_SINGLE',
    LOAD_SINGLE_WITH_VERSION: 'LOAD_SINGLE_WITH_VERSION',
    LOAD_SINGLE_WITHOUT_ID: 'LOAD_SINGLE_WITHOUT_ID',
    LOAD_SINGLE_SUCCESS: 'LOAD_SINGLE_SUCCESS',
    CREATE_SINGLE: 'CREATE_SINGLE',
    CREATE_SINGLE_SUCCESS: 'CREATE_SINGLE_SUCCESS',
    DELETE_SINGLE: 'DELETE_SINGLE',
    DELETE_SINGLE_SUCCESS: 'DELETE_SINGLE_SUCCESS',
    UPDATE_SINGLE: 'UPDATE_SINGLE',
    UPDATE_SINGLE_SUCCESS: 'UPDATE_SINGLE_SUCCESS',
};
/**
 * Parent class for all Actions related to Entity's
 *
 * @param slice The unique key that identifies the Store part this EntityAction correlates to
 * @param payload Optionally a payload the adds more information to this action
 */
var EntityAction = /** @class */ (function () {
    function EntityAction(slice, payload) {
        this.slice = slice;
        this.payload = payload;
        this.actionName = '';
    }
    Object.defineProperty(EntityAction.prototype, "type", {
        get: function () {
            return typeFor(this.slice, this.actionName);
        },
        enumerable: true,
        configurable: true
    });
    return EntityAction;
}());
export { EntityAction };
/***************
 * Actions
 ***************/
var Select = /** @class */ (function (_super) {
    tslib_1.__extends(Select, _super);
    function Select(slice, payload) {
        var _this = _super.call(this, slice, payload) || this;
        _this.slice = slice;
        _this.payload = payload;
        _this.actionName = actions.SELECT;
        return _this;
    }
    return Select;
}(EntityAction));
export { Select };
var Failure = /** @class */ (function (_super) {
    tslib_1.__extends(Failure, _super);
    function Failure(slice, payload) {
        var _this = _super.call(this, slice, payload) || this;
        _this.slice = slice;
        _this.payload = payload;
        _this.actionName = actions.FAIL;
        return _this;
    }
    return Failure;
}(EntityAction));
export { Failure };
var LoadAll = /** @class */ (function (_super) {
    tslib_1.__extends(LoadAll, _super);
    function LoadAll(slice, forceReload, payload) {
        if (forceReload === void 0) { forceReload = false; }
        var _this = _super.call(this, slice, payload) || this;
        _this.slice = slice;
        _this.forceReload = forceReload;
        _this.payload = payload;
        _this.actionName = actions.LOAD_ALL;
        return _this;
    }
    return LoadAll;
}(EntityAction));
export { LoadAll };
var LoadAllWithoutId = /** @class */ (function (_super) {
    tslib_1.__extends(LoadAllWithoutId, _super);
    function LoadAllWithoutId(slice, forceReload) {
        if (forceReload === void 0) { forceReload = false; }
        var _this = _super.call(this, slice) || this;
        _this.slice = slice;
        _this.forceReload = forceReload;
        _this.actionName = actions.LOAD_ALL_WITHOUT_ID;
        return _this;
    }
    return LoadAllWithoutId;
}(EntityAction));
export { LoadAllWithoutId };
var LoadAllSuccess = /** @class */ (function (_super) {
    tslib_1.__extends(LoadAllSuccess, _super);
    function LoadAllSuccess(slice, payload) {
        var _this = _super.call(this, slice, payload) || this;
        _this.slice = slice;
        _this.payload = payload;
        _this.actionName = actions.LOAD_ALL_SUCCESS;
        return _this;
    }
    return LoadAllSuccess;
}(EntityAction));
export { LoadAllSuccess };
var LoadSingle = /** @class */ (function (_super) {
    tslib_1.__extends(LoadSingle, _super);
    function LoadSingle(slice, payload, forceReload) {
        if (forceReload === void 0) { forceReload = false; }
        var _this = _super.call(this, slice, payload) || this;
        _this.slice = slice;
        _this.payload = payload;
        _this.forceReload = forceReload;
        _this.actionName = actions.LOAD_SINGLE;
        return _this;
    }
    return LoadSingle;
}(EntityAction));
export { LoadSingle };
var LoadSingleWithoutId = /** @class */ (function (_super) {
    tslib_1.__extends(LoadSingleWithoutId, _super);
    function LoadSingleWithoutId(slice, forceReload) {
        if (forceReload === void 0) { forceReload = false; }
        var _this = _super.call(this, slice) || this;
        _this.slice = slice;
        _this.forceReload = forceReload;
        _this.actionName = actions.LOAD_SINGLE_WITHOUT_ID;
        return _this;
    }
    return LoadSingleWithoutId;
}(EntityAction));
export { LoadSingleWithoutId };
var LoadSingleWithVersion = /** @class */ (function (_super) {
    tslib_1.__extends(LoadSingleWithVersion, _super);
    function LoadSingleWithVersion(slice, payload, forceReload) {
        if (forceReload === void 0) { forceReload = false; }
        var _this = _super.call(this, slice, payload) || this;
        _this.slice = slice;
        _this.payload = payload;
        _this.forceReload = forceReload;
        _this.actionName = actions.LOAD_SINGLE_WITH_VERSION;
        return _this;
    }
    return LoadSingleWithVersion;
}(EntityAction));
export { LoadSingleWithVersion };
var LoadSingleSuccess = /** @class */ (function (_super) {
    tslib_1.__extends(LoadSingleSuccess, _super);
    function LoadSingleSuccess(slice, payload, request) {
        var _this = _super.call(this, slice, payload) || this;
        _this.slice = slice;
        _this.payload = payload;
        _this.request = request;
        _this.actionName = actions.LOAD_SINGLE_SUCCESS;
        return _this;
    }
    return LoadSingleSuccess;
}(EntityAction));
export { LoadSingleSuccess };
var CreateSingle = /** @class */ (function (_super) {
    tslib_1.__extends(CreateSingle, _super);
    function CreateSingle(slice, payload) {
        var _this = _super.call(this, slice, payload) || this;
        _this.slice = slice;
        _this.payload = payload;
        _this.actionName = actions.CREATE_SINGLE;
        return _this;
    }
    return CreateSingle;
}(EntityAction));
export { CreateSingle };
var CreateSingleSuccess = /** @class */ (function (_super) {
    tslib_1.__extends(CreateSingleSuccess, _super);
    function CreateSingleSuccess(slice, payload, request) {
        var _this = _super.call(this, slice, payload) || this;
        _this.slice = slice;
        _this.payload = payload;
        _this.request = request;
        _this.actionName = actions.CREATE_SINGLE_SUCCESS;
        return _this;
    }
    return CreateSingleSuccess;
}(EntityAction));
export { CreateSingleSuccess };
var DeleteSingle = /** @class */ (function (_super) {
    tslib_1.__extends(DeleteSingle, _super);
    function DeleteSingle(slice, payload) {
        var _this = _super.call(this, slice, payload) || this;
        _this.slice = slice;
        _this.payload = payload;
        _this.actionName = actions.DELETE_SINGLE;
        return _this;
    }
    return DeleteSingle;
}(EntityAction));
export { DeleteSingle };
var DeleteSingleSuccess = /** @class */ (function (_super) {
    tslib_1.__extends(DeleteSingleSuccess, _super);
    function DeleteSingleSuccess(slice, payload, request) {
        var _this = _super.call(this, slice, payload) || this;
        _this.slice = slice;
        _this.payload = payload;
        _this.request = request;
        _this.actionName = actions.DELETE_SINGLE_SUCCESS;
        return _this;
    }
    return DeleteSingleSuccess;
}(EntityAction));
export { DeleteSingleSuccess };
var UpdateSingle = /** @class */ (function (_super) {
    tslib_1.__extends(UpdateSingle, _super);
    function UpdateSingle(slice, payload) {
        var _this = _super.call(this, slice, payload) || this;
        _this.slice = slice;
        _this.payload = payload;
        _this.actionName = actions.UPDATE_SINGLE;
        return _this;
    }
    return UpdateSingle;
}(EntityAction));
export { UpdateSingle };
var UpdateSingleSuccess = /** @class */ (function (_super) {
    tslib_1.__extends(UpdateSingleSuccess, _super);
    function UpdateSingleSuccess(slice, payload, request) {
        var _this = _super.call(this, slice, payload) || this;
        _this.slice = slice;
        _this.payload = payload;
        _this.request = request;
        _this.actionName = actions.UPDATE_SINGLE_SUCCESS;
        return _this;
    }
    return UpdateSingleSuccess;
}(EntityAction));
export { UpdateSingleSuccess };
