import {Component, Inject} from '@angular/core';
import {Action, Store} from '@ngrx/store';
import {State} from '../../../../state-management/reducers';
import {CloseModal, OpenModal} from '../../../../state-management/layout/actions';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {AssessmentDecision} from '../../../../shared/api/models/assessmentDecision';
import {AssessFundingProposal} from '../../../state-management/actions';

class DialogOptions {
  constructor(public title: any, public action: Action) {
  }
}

@Component({
  selector: 'caple-assessment-choice-dialog',
  templateUrl: './assessment-choice-dialog.component.html'
})
export class AssessmentChoiceDialogComponent {

  public action$: Observable<string>;
  public question$: Observable<string>;

  constructor(private store: Store<State>, private translate: TranslateService, @Inject(MAT_DIALOG_DATA) private options: DialogOptions) {
    this.action$ = translate.get(`funding-process.assessment.decision.${options.title}.action`);
    this.question$ = translate.get(`funding-process.assessment.decision.${options.title}.question`);
  }

  public static approveDialogAction(id: string): OpenModal {
    return new OpenModal(AssessmentChoiceDialogComponent, 'AssessmentApproveConfirmation', {},
      new DialogOptions('approve-funding-proposal', new AssessFundingProposal({
        id: id, decision: AssessmentDecision.APPROVED
      })));
  }

  public static rejectDialogAction(id: string): OpenModal {
    return new OpenModal(AssessmentChoiceDialogComponent, 'AssessmentRejectConfirmation', {},
      new DialogOptions('reject-funding-proposal', new AssessFundingProposal({
        id: id, decision: AssessmentDecision.REJECTED
      })));
  }

  public static imcompleteDialogAction(id: string): OpenModal {
    return new OpenModal(AssessmentChoiceDialogComponent, 'AssessmentIncompleteConfirmation', {},
      new DialogOptions('incomplete-funding-proposal', new AssessFundingProposal({
        id: id, decision: AssessmentDecision.INCOMPLETE
      })));
  }

  public confirm() {
    this.store.dispatch(this.options.action);
  }

  public closeModal() {
    this.store.dispatch(new CloseModal());
  }

}
