import { Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CloseModal } from '../../../state-management/layout/actions';
import { select, Store } from '@ngrx/store';
import { State } from '../../../state-management/reducers';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreateSingle } from '../../../state-management/util/actions';
import { SLICE } from '../../state-management/slice';
import { CreateUserPayload } from '../../../shared/api/services/company.service';
import { selectCompany } from '../../state-management/selector';
import { Subscription } from 'rxjs';

@Component({
  selector: 'caple-create-user-dialog',
  templateUrl: './create-user-dialog.component.html'
})
export class CreateUserDialogComponent implements OnDestroy {

  /**
   * The formGroup for the form
   */
  public form: FormGroup;

  public showNoRolesWarning = false;

  private companyId: string;

  private subscriptions: Subscription = new Subscription();

  constructor(private formBuilder: FormBuilder, private store: Store<State>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.form = formBuilder.group({
      'email': ['', [Validators.required, Validators.email]],
      'name': ['', Validators.required]
    });
    this.companyId = data.companyId;

    this.subscriptions.add(
      this.store.pipe(select(selectCompany({id: this.companyId}))).subscribe(company => {
        if (!company.roles || company.roles.length === 0) {
          this.showNoRolesWarning = true;
        }
      }));
  }

  public createUser() {
    this.form.markAsTouched();
    if (this.form.valid) {
      const payload: CreateUserPayload = {companyId: this.companyId, email: this.form.controls['email'].value, name: this.form.controls['name'].value};
      const action = new CreateSingle(SLICE.USER, payload);
      this.store.dispatch(action);
    }
  }

  public closeModal() {
    this.store.dispatch(new CloseModal());
  }

  public ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
      this.subscriptions = null;
    }
  }
}
