import { Injectable } from '@angular/core';
import { HttpClient, HttpUploadProgressEvent } from '@angular/common/http';
import { AbstractApiService } from './abstract-api.service';
import { WithId } from '../../../state-management/util/actions';
import { AssessmentDecision } from '../models/assessmentDecision';
import { Id } from '../models/id';
import { Observable } from 'rxjs';
import { FileUploaded } from '../models/fileUploaded';
import { FileDetails } from '../models/fileDetails';
import { FinancialModel } from '../models/financialModel';
import { ImportPayload } from './funding-process.service';
import { ExportToExcelPayload } from '../../../origination/state-management/actions';

export interface FilePayloadWithName extends WithId {
  fileDetails: FileDetails;
}


export interface FilePayload extends WithId {
  fileId: string;
}

@Injectable()
export class AssessmentService extends AbstractApiService {
  private readonly baseUrl = '/api/v1/funding-processes';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public saveKycAssessment(payload: WithId) {
    return this.post(`${this.baseUrl}/${payload.id}/assessment/kyc`, payload);
  }

  public getKycPrefill(payload: WithId) {
    return this.get(`${this.baseUrl}/${payload.id}/assessment/kyc/prefill`);
  }

  public saveCreditAssessment(payload: WithId) {
    return this.post(`${this.baseUrl}/${payload.id}/assessment/credit`, payload);
  }

  public saveEligibilityAssessment(payload: WithId) {
    return this.post(`${this.baseUrl}/${payload.id}/assessment/eligibility`, payload);
  }

  public getEligibilityPrefill(payload: WithId) {
    return this.get(`${this.baseUrl}/${payload.id}/assessment/eligibility/prefill`);
  }

  public submitAssessment(payload: { id: string, decision: AssessmentDecision }) {
    return this.post(`${this.baseUrl}/${payload.id}/assessment/submitted`, {
      decision: payload.decision
    });
  }

  public importSensitisedFinancialsExcel(payload: ImportPayload): [Observable<FinancialModel>, Observable<HttpUploadProgressEvent>] {
    const formData = new FormData();
    formData.append('file', payload.file);

    return this.postFile(`${this.baseUrl}/${payload.id}/assessment/credit/extract-financials`, formData);
  }

  public exportSensitisedFinancialsExcel(payload: ExportToExcelPayload) {
    return this.getFileWithProgress(`${this.baseUrl}/${payload.id}/assessment/credit/sensitised/excel`);
  }

  public attachCommunicationFile(payload: FilePayloadWithName) {
    return this.post(`${this.baseUrl}/${payload.id}/assessment/communication`, payload.fileDetails);
  }

  public removeCommunicationFile(payload: FilePayload) {
    return this.delete(`${this.baseUrl}/${payload.id}/assessment/communication/${payload.fileId}`);
  }

  public downloadCommunicationFile(payload) {
    return this.getFileWithProgress(`${this.baseUrl}/${payload.id}/assessment/communication/${payload.fileId}`);
  }

  public uploadAssessmentFile(id: Id, file: File): [Observable<FileUploaded>, Observable<HttpUploadProgressEvent>] {
    const formData = new FormData();
    formData.append('file', file);

    return this.postFile(`${this.baseUrl}/${id.id}/assessment/files`, formData);
  }
}
