import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as Moment from 'moment';

export class DateValidators {

  private static DATE_PATTERN = "Y-MM-DD";
  private static DATE_PATTERN_REGEX = new RegExp('^\\d{1,2}-\\d{1,2}-\\d{4}$');
  // This date pattern is returned when the date is retrieved from the server
  private static ISO_DATE_PATTERN_REGEX = new RegExp('^\\d{4}-\\d{1,2}-\\d{1,2}$');

  public static validDate(control: AbstractControl): ValidationErrors | null {
    if (control.value === null || control.value === undefined || control.value instanceof Date) {
      return null;  // don't validate empty values to allow optional controls
    }

    let isValid = false;

    if(Moment.isMoment(control.value) === true) {
      isValid = (<any>control.value).isValid();
    } else {
      let datePatternResult = DateValidators.DATE_PATTERN_REGEX.test(<any>control.value);
      let isoDatePatternResult = DateValidators.ISO_DATE_PATTERN_REGEX.test(<any>control.value);
      isValid = datePatternResult || isoDatePatternResult;
    }

    return isValid ? null :  {'date': {'expectedPattern': DateValidators.DATE_PATTERN}};
  };


}
