import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ApiModule } from '../shared/api/api.module';
import { LayoutModule } from '../shared/layout/layout.module';
import { EligibilityCriteriaYesNoQuestionComponent } from './input/eligibility-criteria-yes-no-question.component';
import { EligibilityCriteriaNumberInputComponent } from './input/eligibility-criteria-number-input.component';
import { BasicEligibilityCriteriaComponent } from './basic-eligibility-criteria.component';
import { AdvancedEligibilityCriteriaComponent } from './advanced-eligibility-criteria.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as Root from './state-management/effects';
import { reducer } from './state-management/reducer';
import { EligibilityCriteriaResultComponent } from './input/eligibility-criteria-result.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    // Angular
    CommonModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    RouterModule.forChild([]),

    // NgRx
    StoreModule.forFeature('eligibility', reducer),
    EffectsModule.forFeature([Root.EligibilityEffects]),

    // Caple Generic Modules
    ApiModule,
    LayoutModule
  ],
  declarations: [
    BasicEligibilityCriteriaComponent,
    AdvancedEligibilityCriteriaComponent,
    EligibilityCriteriaResultComponent,
    EligibilityCriteriaYesNoQuestionComponent,
    EligibilityCriteriaNumberInputComponent
  ],
  exports: [
    BasicEligibilityCriteriaComponent,
    AdvancedEligibilityCriteriaComponent
  ]
})
export class EligibilityModule {
}
