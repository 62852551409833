import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { AbstractApiService } from './abstract-api.service';
var ClientService = /** @class */ (function (_super) {
    tslib_1.__extends(ClientService, _super);
    function ClientService(httpClient) {
        var _this = _super.call(this, httpClient) || this;
        _this.baseUrl = '/api/v1/partners/clients/';
        return _this;
    }
    ClientService.prototype.getAll = function () {
        return this.get(this.baseUrl);
    };
    ClientService.prototype.getAllForPartner = function (id) {
        return this.get('/api/v1/companies/' + id.id + '/clients');
    };
    ClientService.prototype.getByCompanyId = function (id) {
        return this.get(this.baseUrl + id.id);
    };
    ClientService.prototype.getUserForClientCompany = function (id) {
        return this.get(this.baseUrl + id.id + '/users');
    };
    ClientService.prototype.create = function (request) {
        return this.post(this.baseUrl, request.client);
    };
    ClientService.prototype.promoteToCompany = function (payload) {
        return this.post('/api/v1/companies/' + payload.companyId + '/clients/' + payload.companyNumber + '/promote', { role: payload.role });
    };
    return ClientService;
}(AbstractApiService));
export { ClientService };
