import { Observable, of, Subscription } from 'rxjs';
import { Component, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { State } from './state-management/reducers';
import { LoadSingleWithoutId } from './state-management/util/actions';
import { SLICE } from './state-management/preferences/slice';
import { selectPreferencesLoaded } from './state-management/preferences/selector';
import { selectIsAuthenticated } from './state-management/login/selector';
import { applyStorePatch } from './shared/store/store-patch';
import { delay, switchMap } from 'rxjs/operators';
import { Angulartics2Piwik } from 'angulartics2/piwik';
import { Router } from '@angular/router';

@Component({
  selector: 'caple-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnDestroy {

  public preferencesLoaded$: Observable<boolean>;
  public showFeedbackButton$: Observable<boolean>;

  private subscriptions: Subscription = new Subscription();

  constructor(private store: Store<State>, private angulartics2Piwik: Angulartics2Piwik, private route: Router) {
    angulartics2Piwik.startTracking();
    /**
     * This call is here to apply the `StorePatch` as early as possible during the starting of the app so all code can safely depend on the patch.
     * We couldn't get it working more neatly than this sadly.
     */
    applyStorePatch(this.store);

    this.preferencesLoaded$ = this.store.pipe(
      select(selectIsAuthenticated),
      switchMap(authenticated => {
        if (authenticated) {
          this.store.dispatch(new LoadSingleWithoutId(SLICE.PREFERENCES));

          return this.store.pipe(select(selectPreferencesLoaded));
        } else {
          return of(true);
        }
      }));

    /**
     * We want to hide the feedback button until a page is loaded so we don't show it if for example
     * the login component is loading and we are going to navigate away.
     *
     * Because there is no arbitrary way in angular to detect this, we use a plain old delay
     */
    this.showFeedbackButton$ = of(true).pipe(delay(500));
  }

  isLoginFailurePage() {
    return this.route.url.includes('login/failure');
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
