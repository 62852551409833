import { Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { State } from '../state-management/reducers';
import { EligibilityParent } from './eligibility-parent';
import { EligibilityCheckType } from '../shared/api/models/eligibilityCheckType';
import { ReadEligibility } from '../shared/api/models/readEligibility';
import { isEBITToTotalDebtValid, isNetDebtToEBITDAValid } from './eligibility-validator';

@Component({
  selector: 'caple-advanced-eligibility-criteria',
  templateUrl: './advanced-eligibility-criteria.component.html'
})
export class AdvancedEligibilityCriteriaComponent extends EligibilityParent {
  @Input()
  public introText: string;
  @Input()
  public passedText: string;
  @Input()
  public passedLinkText: string;

  private readonly START_SELECT_DRAWDOWN_YEAR = 2018;
  private readonly END_SELECT_DRAWDOWN_YEAR = 2028;

  public years = [];

  constructor(store: Store<State>) {
    super(store);
    for (let i = this.START_SELECT_DRAWDOWN_YEAR; i <= this.END_SELECT_DRAWDOWN_YEAR; i++) {
      this.years.push(i.toString());
    }

    this.form = new FormBuilder().group({
      drawdownQuarter: ['1', Validators.required],
      drawdownYear: [new Date().getFullYear().toString(), Validators.required],
      netDebt: [null, [Validators.required, Validators.min(0)]],
      ebitda: [null, [Validators.required, Validators.min(0)]],
      ebit: [null, [Validators.required, Validators.min(0)]],
      totalDebtService: [null, [Validators.required, Validators.min(0)]]
    });

    this.setFormDisableSetting();
    this.onFormChanged();
  }

  public patchEligibility(eligibility: ReadEligibility) {
    this.patchEligibilityCheck(eligibility.advancedEligibilityCheck);
  }

  public submitAdvancedEligibilityData() {
    this.submitEligibilityData(EligibilityCheckType.ADVANCED);
  }

  public getQuarterTitle(): string {
    const quarter = this.form.controls['drawdownQuarter'].value;
    const nextYear = Number.parseInt(this.form.controls['drawdownYear'].value, 10) + 1;
    return `Q${quarter} ${nextYear}`;
  }

  public getYearTitle(): string {
    const quarter = this.form.controls['drawdownQuarter'].value;
    const year = Number.parseInt(this.form.controls['drawdownYear'].value, 10);
    const nextYear = year + 1;
    return `Q${quarter} ${year} - Q${quarter} ${nextYear}`;
  }

  public validateEligibility() {
    this.validateRatios();
  }

  private validateRatios() {
    const netDebt = this.form.controls['netDebt'].value;
    const ebitda = this.form.controls['ebitda'].value;
    if (!isNetDebtToEBITDAValid(netDebt / ebitda)) {
      this.addError('netDebtToEBITDAMoreThenMin', ['netDebt', 'ebitda']);
    }

    const ebit = this.form.controls['ebit'].value;
    const totalDebtService = this.form.controls['totalDebtService'].value;
    if (!isEBITToTotalDebtValid(ebit / totalDebtService)) {
      this.addError('EBITToTotalDebtServiceLessThenMin', ['ebit', 'totalDebtService']);
    }
  }
}
