import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractApiService } from './abstract-api.service';
import { Feedback } from '../models/feedback';


@Injectable()
export class FeedbackService extends AbstractApiService {
  private readonly baseUrl = '/api/v1/feedback';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }


  public sendFeedback(payload: Feedback) {
    return this.post(this.baseUrl, payload);
  }
}
