<caple-loading-wrapper [diameter]="50" key="urn:clients:load">
  <div *ngIf="(clients$ | async)?.length > 0;else no_clients">
    <form [formGroup]="form">
      <mat-form-field class="full" floatLabel="always">
        <mat-select [formControl]="form.controls['client']" placeholder="{{ 'funding-process.client' | translate }}">
          <mat-option *ngFor="let client of (clients$ | async)" [value]="client">{{ client.companyName }}</mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>

  <ng-template #no_clients>{{ 'funding-process.no-clients' | translate }}</ng-template>
</caple-loading-wrapper>
