import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CloseModal } from '../../../../state-management/layout/actions';
import { FormBuilder, Validators } from '@angular/forms';
import { selectFinancialsSummariesForMonitoringFile, selectMonitoringFile } from '../../../state-management/selector';
import { UpdateGeneralInformation } from '../../../state-management/actions';
import { filter } from 'rxjs/operators';
import { reportingPeriodAndAnnualDatesMatch } from '../../../validation/reportingPeriodAndAnnualDatesMatch';
import { Subscription } from 'rxjs';
import { ReportingFrequency } from '../../../../shared/api/models/reportingFrequency';
var EditGeneralInformationDialogComponent = /** @class */ (function () {
    function EditGeneralInformationDialogComponent(store, formBuilder, data) {
        var _this = this;
        this.store = store;
        this.formBuilder = formBuilder;
        this.data = data;
        this.subscriptions = new Subscription();
        this.ReportingFrequencyEnum = Object.values(ReportingFrequency);
        this.fileId = data.id;
        this.form = formBuilder.group({
            'reportingEndDate': ['', [Validators.required]],
            'reportingFrequency': ['', Validators.required],
            'reportingYearEndDate': ['', [Validators.required]],
            'term': ['', [Validators.required, Validators.min(1)]],
            'interestRate': ['', [Validators.required, Validators.min(1), Validators.max(100)]],
            'loanAmount': ['', [Validators.required, Validators.min(1)]],
            'firstInterestPayment': ['', [Validators.required]],
            'firstRedemptionPayment': ['', [Validators.required]],
        }, {
            validator: reportingPeriodAndAnnualDatesMatch('reportingEndDate', 'reportingYearEndDate', 'reportingFrequency')
        });
        this.subscriptions.add(this.store.pipe(select(selectMonitoringFile({ id: this.fileId })), filter(function (monitoringFile) { return !!monitoringFile; })).subscribe(function (monitoringFile) {
            _this.monitoringFile = monitoringFile;
            _this.form.patchValue(monitoringFile);
        }));
        this.subscriptions.add(this.store.pipe(select(selectFinancialsSummariesForMonitoringFile({ id: this.fileId })), filter(function (documents) { return !!documents; })).subscribe(function (documents) {
            var documentsAlreadyUploaded = documents.length > 0;
            _this.setEndDatesEnabled(!documentsAlreadyUploaded);
        }));
    }
    EditGeneralInformationDialogComponent.prototype.dueDateKeyIfControlIsDisabled = function (control) {
        if (control.disabled) {
            return 'core.error.monitoring.cannot-modify-due-date';
        }
    };
    EditGeneralInformationDialogComponent.prototype.closeModal = function () {
        this.store.dispatch(new CloseModal());
    };
    EditGeneralInformationDialogComponent.prototype.updateGeneralInformation = function () {
        this.store.dispatch(new UpdateGeneralInformation(tslib_1.__assign({}, this.form.getRawValue(), { id: this.fileId })));
    };
    EditGeneralInformationDialogComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    EditGeneralInformationDialogComponent.prototype.setEndDatesEnabled = function (enabled) {
        if (enabled) {
            this.form.controls['reportingEndDate'].enable();
            this.form.controls['reportingYearEndDate'].enable();
        }
        else {
            this.form.controls['reportingEndDate'].disable();
            this.form.controls['reportingYearEndDate'].disable();
        }
    };
    return EditGeneralInformationDialogComponent;
}());
export { EditGeneralInformationDialogComponent };
