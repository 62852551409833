import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { RepeatableItem } from '../../../shared/layout/repeatable-form-item/repeatable-item';
import { CovenantType } from '../../../shared/api/models/covenantType';
import { Comparator } from '../../../shared/api/models/comparator';
import { CapleFormBuilder } from '../../../shared/util/caple-form-builder';

@Component({
  selector: 'caple-select-covenant',
  templateUrl: './covenants.component.html'
})
export class SelectCovenantsComponent extends RepeatableItem {

  public covenantType = Object.values(CovenantType).filter(item => item !== CovenantType.UNKNOWN);
  public comparator = Comparator;

  @Input()
  public item: FormGroup;

  @Input()
  public showRemove: boolean;

  @Input()
  public index: number;

  @Output()
  public removed: EventEmitter<void> = new EventEmitter<void>();

  public static produceFormGroup(val?: any) {
    val = val || {};
    const formBuilder = new CapleFormBuilder().group({
      'type': [val.type, Validators.required],
      'threshold': [val.threshold, [Validators.required, Validators.min(this.getValidatorMinValueForType(val.type))]],
      'comparator': [val.comparator, Validators.required],
      'activeFrom': [val.activeFrom, Validators.required],
      'activeTo': [val.activeTo, Validators.required]
    });

    formBuilder.get('type').valueChanges.subscribe((covenantType) => {
      formBuilder.get('threshold').setValidators([Validators.required, Validators.min(this.getValidatorMinValueForType(covenantType))]);
    });

    return formBuilder;
  }

  /**
   * By request from Tom, the MO officer.
   * The EBITDA in the covenant should be possible to fill in a negative number.
   * @See PLATFORM-1163
   *
   * @param typeName The name for the type we are checking for.
   */
  private static getValidatorMinValueForType(typeName: string): number {
    // Arbitrarily chosen lower limit. Don't want to have that much of a negative EBITDA,
    // as we only offer loans of up to 5_000_000.
    const MIN_EBITDA_VALUE = -99_000_000;
    return typeName === CovenantType.EBITDA ? MIN_EBITDA_VALUE : 0;
  }

  public produceFormGroup(val?: any): FormGroup {
    return SelectCovenantsComponent.produceFormGroup(val);
  }

  public mapToTranslationKey(input: string) {
    return input.toLowerCase().split('_').join('-');
  }
}
