import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {RepeatableItem} from '../../../../../shared/layout/repeatable-form-item/repeatable-item';
import {CapleFormBuilder} from '../../../../../shared/util/caple-form-builder';
import {debounceTime, filter, take} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {SupportedCountry} from '../../../../../shared/api/models/supportedCountry';
import {State} from '../../../../../state-management/reducers';
import {
  selectActiveFundingProcessCountry,
  selectChamberOfCommerceNameResult
} from '../../../../state-management/selector';
import {ChamberOfCommerceNameLoad} from '../../../../state-management/actions';

@Component({
  selector: 'caple-guarantor-item',
  templateUrl: './guarantor-item.component.html',
  styleUrls: ['./guarantor-item.component.scss']
})
export class GuarantorItemComponent extends RepeatableItem implements OnInit, OnDestroy {

  @Input()
  public item: FormControl;

  @Input()
  public showRemove: boolean;

  @Input()
  public index: number;

  @Output()
  public removed: EventEmitter<void> = new EventEmitter<void>();

  private subscriptions: Subscription = new Subscription();
  private chamberOfCommerceName: Subscription = new Subscription();

  constructor(private store: Store<State>) {
    super();
  }

  public static produceFormGroup(val?: any) {
    val = val || {};
    return new CapleFormBuilder().group({
      companyNumber: [val.companyNumber],
      companyName: [val.companyName]
    });
  }

  public ngOnInit(): void {
    this.subscriptions.add(this.store.pipe(
      select(selectActiveFundingProcessCountry),
      take(1),
      filter(country => country === SupportedCountry.GB)
    ).subscribe((country: SupportedCountry) => {
      this.setFormControlsEnabledState(!!this.item.get('companyName').value);

      this.reactOnCompanyNumberValueChanges(country);
    }));
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.chamberOfCommerceName.unsubscribe();
  }

  public produceFormGroup(val?: any) {
    return GuarantorItemComponent.produceFormGroup(val);
  }

  private reactOnCompanyNumberValueChanges(country) {
    this.subscriptions.add(
      this.item.get('companyNumber').valueChanges.pipe(
        filter(value => !!value),
        debounceTime(300)
      ).subscribe(value => {
        this.setFormControlsEnabledState(false);
        this.store.dispatch(new ChamberOfCommerceNameLoad({id: value, country: country}));

        this.patchFormAfterLoad(value);
      }));
  }

  private patchFormAfterLoad(value) {
    this.chamberOfCommerceName.unsubscribe();
    this.chamberOfCommerceName =
      this.store.pipe(
        select(selectChamberOfCommerceNameResult({id: value})),
        filter(result => !!result)
      ).subscribe((result) => {
        this.setFormControlsEnabledState(true);
        if (result.companyName) {
          this.item.get('companyName').patchValue(result.companyName);
        }
      });
  }

  private setFormControlsEnabledState(enabled: boolean) {
    setTimeout(() => {
      const nameControl = this.item.get('companyName');
      enabled ? nameControl.enable() : nameControl.disable();
    });
  }
}
