import { Component, Inject, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { State } from '../../../state-management/reducer';
import { CloseModal } from '../../../../state-management/layout/actions';
import { WithId } from '../../../../state-management/util/actions';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { selectFinancialsSummariesForMonitoringFile, selectMonitoringFile } from '../../../state-management/selector';
import { ReadMonitoringFile } from '../../../../shared/api/models/readMonitoringFile';
import { UpdateGeneralInformation } from '../../../state-management/actions';
import { filter } from 'rxjs/operators';
import { reportingPeriodAndAnnualDatesMatch } from '../../../validation/reportingPeriodAndAnnualDatesMatch';
import { Subscription } from 'rxjs';
import { ReportingFrequency } from '../../../../shared/api/models/reportingFrequency';

@Component({
  selector: 'caple-general-information-dialog',
  templateUrl: './edit-general-information-dialog.component.html'
})
export class EditGeneralInformationDialogComponent implements OnDestroy {
  public form: FormGroup;
  public monitoringFile: ReadMonitoringFile;
  private subscriptions = new Subscription();
  private readonly fileId: string;

  public ReportingFrequencyEnum = Object.values(ReportingFrequency);

  constructor(private store: Store<State>, private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) private data: WithId) {
    this.fileId = data.id;

    this.form = formBuilder.group({
      'reportingEndDate': ['', [Validators.required]],
      'reportingFrequency': ['', Validators.required],
      'reportingYearEndDate': ['', [Validators.required]],
      'term': ['', [Validators.required, Validators.min(1)]],
      'interestRate': ['', [Validators.required, Validators.min(1), Validators.max(100)]],
      'loanAmount': ['', [Validators.required, Validators.min(1)]],
      'firstInterestPayment': ['', [Validators.required]],
      'firstRedemptionPayment': ['', [Validators.required]],
    }, {
      validator: reportingPeriodAndAnnualDatesMatch('reportingEndDate', 'reportingYearEndDate', 'reportingFrequency')
    });

    this.subscriptions.add(this.store.pipe(
      select(selectMonitoringFile({id: this.fileId})),
      filter(monitoringFile => !!monitoringFile)
    ).subscribe(monitoringFile => {
      this.monitoringFile = monitoringFile;
      this.form.patchValue(monitoringFile);
    }));


    this.subscriptions.add(this.store.pipe(
      select(selectFinancialsSummariesForMonitoringFile({id: this.fileId})),
      filter(documents => !!documents)
    ).subscribe(documents => {
      const documentsAlreadyUploaded = documents.length > 0;
      this.setEndDatesEnabled(!documentsAlreadyUploaded);
    }));
  }

  public dueDateKeyIfControlIsDisabled(control: AbstractControl) {
    if (control.disabled) {
      return 'core.error.monitoring.cannot-modify-due-date';
    }
  }

  public closeModal() {
    this.store.dispatch(new CloseModal());
  }

  public updateGeneralInformation() {
    this.store.dispatch(new UpdateGeneralInformation({...this.form.getRawValue(), id: this.fileId}));
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setEndDatesEnabled(enabled: boolean) {
    if (enabled) {
      this.form.controls['reportingEndDate'].enable();
      this.form.controls['reportingYearEndDate'].enable();
    } else {
      this.form.controls['reportingEndDate'].disable();
      this.form.controls['reportingYearEndDate'].disable();
    }
  }
}
