<mat-form-field>
  <input matInput [matDatepicker]="datePicker" [min]="minDate" [max]="maxDate" placeholder="{{placeholder | translate}}"
         [required]="formControl.errors && formControl.errors.required"
         (dateInput)="dateChange($event)" [value]="formControl.value" [matDatepickerFilter]="filter" readonly [formControl]="formControl"
         (click)="datePicker.open()"/>
  <mat-datepicker-toggle matSuffix [for]="datePicker" *ngIf="!isDisabled"></mat-datepicker-toggle>
  <mat-datepicker (closed)="onDatepickerClosed()" #datePicker startView="multi-year" disabled="false"></mat-datepicker>
  <mat-error>
    <ng-content></ng-content>
  </mat-error>
</mat-form-field>
