import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ApiModule } from '../../shared/api/api.module';
import { UtilModule } from '../../shared/util/util.module';
import { LayoutModule } from '../../shared/layout/layout.module';
import { DiscussionDialogComponent } from './discussion-dialog.component';

@NgModule({
  imports: [
    // Angular
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    FormsModule,

    // Caple Modules
    ApiModule,
    UtilModule,
    LayoutModule
  ],
  providers: [],
  declarations: [
    DiscussionDialogComponent
  ],
  exports: [
    DiscussionDialogComponent
  ],
  entryComponents: [
    DiscussionDialogComponent
  ]
})
export class DiscussionDialogModule {
}
