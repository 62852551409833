<ng-container *ngIf="uploaded; else notUploaded">
        <span class="title">
           <i class="material-icons">check_circle</i>
          {{title}}</span>
  <img src="/assets/images/uploads/{{type}}-active.png"/>
  <div class="justify-space-between">
    <button mat-button (click)="onDownload.emit()">
      {{'generic.download' | translate}} <i class="material-icons">vertical_align_bottom</i>
    </button>
    <div *ngIf="reuploadAvailable">
      <ng-content select=".reupload-button"></ng-content>
    </div>
  </div>
</ng-container>
<ng-template #notUploaded>
  <span class="title">{{title}}</span>
  <!--  We're using this approach of selecting a different image because IE doesn't work with a css based solution-->
  <img src="/assets/images/uploads/{{type}}-inactive.png"/>
  <div class="justify-space-between">
    <ng-content select=".download-template"></ng-content>
    <ng-content select=".upload-button"></ng-content>
  </div>
</ng-template>
