import {debounceTime, distinctUntilChanged, filter, take} from 'rxjs/operators';
import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CreateClient} from '../../../shared/api/models/createClient';
import {selectCountry} from '../../../state-management/preferences/selector';
import {State} from '../../../state-management/reducers';
import {select, Store} from '@ngrx/store';
import {COUNTRY_DATA, CountryData} from '../../shared/country-data';
import {Subscription} from 'rxjs';
import {LoadSingle} from '../../../state-management/util/actions';
import {SLICE} from '../../state-management/slice';
import {selectChamberOfCommerceResult} from '../../state-management/selector';
import {ChamberOfCommerceCompany} from '../../../shared/api/models/chamberOfCommerceCompany';
import {SupportedCountry} from '../../../shared/api/models/supportedCountry';

@Component({
  selector: 'caple-create-client-form',
  templateUrl: './create-client-form.component.html'
})
export class CreateClientFormComponent implements OnInit, OnDestroy {

  /**
   * Emits an event every time the validation status of the form
   * is re-calculated.
   */
  @Output()
  public statusChanged: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  /**
   * Emits an event every time the content of one of the form fields
   * is changed.
   */
  @Output()
  public valueChanged: EventEmitter<CreateClient> = new EventEmitter<CreateClient>();
  /**
   * The formGroup for the create client form
   */
  public form: FormGroup;
  /**
   * Track if all the controls other than companyNumber are enabled or not
   */
  private formEnabled = true;

  private countryData: CountryData;

  private subscriptions: Subscription = new Subscription();
  private chamberOfCommerceForCompaniesNumber: Subscription = new Subscription();

  constructor(private formBuilder: FormBuilder, private store: Store<State>) {
    this.subscriptions.add(
      this.store.pipe(select(selectCountry))
        .subscribe((country: SupportedCountry) => {
          this.countryData = COUNTRY_DATA.get(country);
          this.form = formBuilder.group({
            'companyNumber': ['', [Validators.required, this.countryData.companyNumberFn]],
            'companyName': ['', Validators.required],
            'addressLine1': ['', Validators.required],
            'addressLine2': [''],
            'city': ['', Validators.required],
            'region': [''],
            'postalCode': ['', Validators.required],
            'financialYearEnd': [undefined, Validators.required],
            'sector': ['', [Validators.required, this.countryData.sectorCodePatternFn, this.countryData.sectorCodeValidFn]]
          });
        })
    );

    this.subscriptions.add(
      this.form.statusChanges.pipe(distinctUntilChanged()).subscribe((newStatus) => {
        // If the form is Disabled then validation only touches enabled fields
        // Which upstream components don't expect
        this.statusChanged.emit(newStatus === 'VALID' && this.formEnabled);
      })
    );

    this.subscriptions.add(
      this.form.valueChanges.subscribe((formValue) => {
        const client = this.convertFormFieldsToClient(formValue);
        this.valueChanged.emit(client);
      })
    );
  }

  public ngOnInit(): void {
    this.store.pipe(
      select(selectCountry),
      take(1)
    ).subscribe((country: SupportedCountry) => {
      if (country !== SupportedCountry.NL && country !== SupportedCountry.GB) {
        throw new Error('Chamber of commerce not supported for ' + country);
      }
      this.setFormControlsEnabledState(false);

      this.reactOnCompanyNumberValueChanges();
    });
  }

  public ngOnDestroy(): void {
    this.chamberOfCommerceForCompaniesNumber.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  private reactOnCompanyNumberValueChanges() {
    this.subscriptions.add(
      this.form.controls['companyNumber'].valueChanges.pipe(
        filter(value => value && this.form.controls['companyNumber'].errors === null),
        debounceTime(300)
      ).subscribe(value => {
        this.setFormControlsEnabledState(false);
        this.store.dispatch(new LoadSingle(SLICE.CHAMBER_OF_COMMERCE, {id: value}));

        this.patchFormAfterLoad(value);
      }));
  }

  private convertFormFieldsToClient(formValue): CreateClient {
    return {
      companyNumber: formValue.companyNumber,
      companyName: formValue.companyName,
      addressLine1: formValue.addressLine1,
      addressLine2: formValue.addressLine2,
      city: formValue.city,
      region: formValue.region,
      postalCode: formValue.postalCode,
      sector: {
        type: this.countryData.sectorType,
        code: formValue.sector
      },
      financialYearEnd: formValue.financialYearEnd
    };
  }

  private setFormControlsEnabledState(enabled: boolean) {
    this.formEnabled = enabled;
    for (const property in this.form.controls) {
      if (property !== 'companyNumber') {
        enabled ? this.form.controls[property].enable() :
          this.form.controls[property].disable();
      }
    }
  }

  private patchFormAfterLoad(value) {
    this.chamberOfCommerceForCompaniesNumber.unsubscribe();
    this.chamberOfCommerceForCompaniesNumber =
      this.store.pipe(
        select(selectChamberOfCommerceResult({id: value})),
        filter(result => !!result)
      ).subscribe((result) => {
        this.setFormControlsEnabledState(true);
        // If the read object just contains just the companyNumber then the search didn't return anything
        if (Object.keys(result)
          .filter(key => key !== 'companyNumber').length > 0) {
          this.patchFormControl(result);
        } else {
          this.form.reset({companyNumber: value}, {emitEvent: false, onlySelf: true});
        }
      });
  }


  private patchFormControl(readCompany: ChamberOfCommerceCompany) {
    const createClient: any = {
      companyName: readCompany.companyName,
      financialYearEnd: readCompany.accounts && readCompany.accounts.accountingReferenceDate &&
        readCompany.accounts.accountingReferenceDate.month,
      sector: readCompany.mainSicCode
    };

    if (readCompany.registeredAddress) {
      createClient.city = readCompany.registeredAddress.locality;
      createClient.postalCode = readCompany.registeredAddress.postalCode;
      if (readCompany.registeredAddress.firstAddressLine) {
        createClient.addressLine1 = readCompany.registeredAddress.firstAddressLine;
      }
      if (readCompany.registeredAddress.secondAddressLine) {
        createClient.addressLine2 = readCompany.registeredAddress.secondAddressLine;
      }
    }

    this.form.patchValue(createClient);
  }
}
