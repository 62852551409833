import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { RepeatableItem } from '../../../../../../shared/layout/repeatable-form-item/repeatable-item';
import { CapleFormGroup } from '../../../../../../shared/util/caple-form';
import { selectActiveFundingProcess, selectActiveFundingProcessCountry } from '../../../../../state-management/selector';
import { CapleFormBuilder } from '../../../../../../shared/util/caple-form-builder';
import { DateValidators } from '../../../../../../shared/util/date-validators';
import { State } from '../../../../../state-management/reducer';
import { DebtFacilityV2 } from '../../../../../../shared/api/models/debtFacilityV2';
import { FundingProcessV2 } from '../../../../../../shared/api/models/fundingProcessV2';
import { uuid } from '../../../../../../shared/util/uuid-util';
import LoanStatusEnum = DebtFacilityV2.LoanStatusEnum;

@Component({
  selector: 'caple-company-debt-facilities-v2-item',
  templateUrl: './company-debt-facilities-v2-item.component.html',
  styleUrls: ['./company-debt-facilities-v2-item.component.scss']
})
export class CompanyDebtFacilitiesV2ItemComponent extends RepeatableItem implements OnInit, OnDestroy {

  @Input()
  public item: CapleFormGroup;

  @Input()
  public translationGroup: CapleFormGroup;

  @Input()
  public snapShotState: DebtFacilityV2;

  @Input()
  public showRemove: boolean;

  @Input()
  public index = 0;

  @Output()
  public removed: EventEmitter<void> = new EventEmitter<void>();

  public country$: Observable<string>;

  public fundingProcess$: Observable<FundingProcessV2>;
  public currentDebtFacility: DebtFacilityV2;
  public LoanStatusEnum = Object.keys(LoanStatusEnum).filter(k => k.indexOf('_') === -1).map(k => {
    return {
      key: k,
      value: LoanStatusEnum[k]
    };
  });
  private fundingProcessSubscription: Subscription;

  constructor(private store: Store<State>) {
    super();
    this.country$ = this.store.pipe(select(selectActiveFundingProcessCountry));
  }

  public static produceFormGroup(val?: any) {
    val = val || {};
    const capleFormBuilder = new CapleFormBuilder();

    return capleFormBuilder.group({
      'id': [val.id !== null || val.id !== undefined ? val.id : uuid(), []],
      'lender': [val.lender, Validators.required],
      'facilityType': [val.facilityType, Validators.required],
      'repaymentProfile': [val.repaymentProfile, Validators.required],
      'drawnAmount': [val.drawnAmount, [Validators.required, Validators.min(0)]],
      'facilityLimit': [val.facilityLimit, [Validators.required, Validators.min(0)]],
      'maturityDate': [val.maturityDate, [Validators.required, DateValidators.validDate]],
      'interestRate': [val.interestRate, [Validators.required, Validators.min(0)]],
      'securityType': [val.securityType, Validators.required],
      'financialAndNonFinancialCovenants': capleFormBuilder.group({
        text: [val.financialAndNonFinancialCovenants ? val.financialAndNonFinancialCovenants.text : '', Validators.required],
        images: [val.financialAndNonFinancialCovenants ? val.financialAndNonFinancialCovenants.images : []]
      }),
      'loanStatus': [val.loanStatus, [Validators.required]],
      'facilityAgreement': capleFormBuilder.group({
        text: [val.facilityAgreement ? val.facilityAgreement.text : '', Validators.required],
        images: [val.facilityAgreement ? val.facilityAgreement.images : []]
      })
    });
  }

  public produceFormGroup(val?: any): FormGroup {
    return CompanyDebtFacilitiesV2ItemComponent.produceFormGroup(val);
  }

  public ngOnInit(): void {

    this.fundingProcess$ = this.store.select(selectActiveFundingProcess) as Observable<FundingProcessV2>;
    this.fundingProcessSubscription = this.fundingProcess$.subscribe(fundingProcess => {
      if (fundingProcess) {
        this.currentDebtFacility = fundingProcess.fundingProposal.company.companyDebtFacilities ?
          fundingProcess.fundingProposal.company.companyDebtFacilities[this.index] : null;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.fundingProcessSubscription) {
      this.fundingProcessSubscription.unsubscribe();
    }
  }

  public equalToSnapShot(val: any): boolean {
    return this.item.get('lender').value === val.lender &&
      this.item.get('facilityType').value === val.facilityType;
  }
}
