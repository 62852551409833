<caple-full-page-message>
  <div class="error-message" *ngIf="!reason">
    <p>Something went wrong whilst trying to log in. Please return to the login page and try again.</p>
    <p>This can happen if you used a bookmark to get to the login page, please make sure your bookmark points to <a href="{{url}}">{{url}}</a></p>
    <hr/>
    <p>Er is een fout opgetreden bij het inloggen. Ga terug naar de login pagina en probeer het opnieuw.</p>
    <p>Dit kan gebeuren als u een bladwijzer gebruikt om op de login pagina te komen. Zorg ervoor dat uw bladwijzer is ingesteld op <a href="{{url}}">{{url}}</a></p>
  </div>
  <div class="error-message" *ngIf="reason == 'blocked'">
    <p>Your account is currently blocked. If you believe this to be a mistake please contact <a href="support@caple.eu">support@caple.eu</a></p>
    <hr/>
    <p>Uw account is momenteel geblokkeerd. Neem contact op als u denkt dat dit een fout is <a href="support@caple.eu">support@caple.eu</a></p>
  </div>
  <div mat-dialog-actions>
    <button mat-button class="mat-action-button" (click)="logout()">
      <span>Login page</span>
      <i class="material-icons">arrow_back</i>
    </button>
  </div>
</caple-full-page-message>
