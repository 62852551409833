import { OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CloseModal } from '../../../../state-management/layout/actions';
import { LoadAll, LoadSingle } from '../../../../state-management/util/actions';
import { combineLatest, Subscription } from 'rxjs';
import { selectAllCompanies, selectMonitoringFile, selectUsersForCompany } from '../../../state-management/selector';
import { FormBuilder, Validators } from '@angular/forms';
import { SLICE } from '../../../state-management/slice';
import { AddReportingSource } from '../../../state-management/actions';
import { filter, map } from 'rxjs/operators';
var AddReportingSourceDialogComponent = /** @class */ (function () {
    function AddReportingSourceDialogComponent(store, formBuilder, data) {
        this.store = store;
        this.formBuilder = formBuilder;
        this.data = data;
        this.subscriptions = new Subscription();
        this.fileId = data.id;
        this.store.dispatch(new LoadAll(SLICE.COMPANY));
        this.companies$ = combineLatest([
            this.store.pipe(select(selectMonitoringFile({ id: this.fileId }))),
            this.store.pipe(select(selectAllCompanies))
        ])
            .pipe(filter(function (_a) {
            var file = _a[0], companies = _a[1];
            return companies.length > 0;
        }), map(function (_a) {
            var file = _a[0], companies = _a[1];
            file.reportingSources.forEach(function (reportingSource) {
                companies = companies.filter(function (company) { return company.id !== reportingSource.company.id; });
            });
            return companies;
        }));
        this.form = formBuilder.group({
            'reportingSource': formBuilder.group({
                'reportingSourceId': ['', Validators.required],
                'reportingSourceUsers': [null]
            })
        });
    }
    AddReportingSourceDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.add(this.form.get('reportingSource.reportingSourceId').valueChanges.subscribe(function (value) {
            _this.form.get('reportingSource.reportingSourceUsers').setValue([]);
            var reportingSourceId = _this.form.get('reportingSource.reportingSourceId').value;
            _this.store.dispatch(new LoadSingle(SLICE.COMPANY_USERS, { id: reportingSourceId }));
            _this.users$ = _this.store.pipe(select(selectUsersForCompany(reportingSourceId)), filter(function (table) { return !!table; }), map(function (table) {
                return Object.values(table.entities);
            }));
        }));
    };
    AddReportingSourceDialogComponent.prototype.closeModal = function () {
        this.store.dispatch(new CloseModal());
    };
    AddReportingSourceDialogComponent.prototype.addReportingSource = function () {
        if (this.form.valid) {
            this.store.dispatch(new AddReportingSource({
                id: this.fileId,
                reportingSource: this.form.controls['reportingSource'].value
            }));
        }
    };
    AddReportingSourceDialogComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    return AddReportingSourceDialogComponent;
}());
export { AddReportingSourceDialogComponent };
