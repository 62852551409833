import {createFeatureSelector} from '@ngrx/store';
import {createForeignTableAdapter, createTableAdapter, ForeignTable, Table} from '../../state-management/util/adapters';
import {
  actions,
  CreateSingleSuccess,
  DeleteSingleSuccess,
  EntityAction,
  LoadSingleSuccess,
  UpdateSingleSuccess,
  WithId
} from '../../state-management/util/actions';
import {typeFor} from '../../state-management/util/util';
import {FundingProcess} from '../../shared/api/models/fundingProcess';
import {ReadClient} from '../../shared/api/models/readClient';
import {FinancialRating} from '../../shared/api/models/financialRating';
import {FinancialRatingSummary} from '../../shared/api/models/financialRatingSummary';
import {MetaModel} from '../../shared/api/models/metaModel';
import {FinancialRatingId} from '../../shared/api/services/funding-process.service';
import {
  MoveUpload,
  MoveUploadFailure,
  MoveUploadPayload,
  originationActions,
  ReviewUploadSuccess,
  UpdateFundingProcessFilteredCountries,
  UpdateFundingProcessFilteredStatuses,
  UpdateFundingProcessSearchQuery,
  UpdateFundingProposalMode,
  UpdateUploadCategories,
  UpdateUploadsCategoriesPayload
} from './actions';
import {SLICE} from './slice';
import {SLICE as KycSLICE} from '../../kyc/state-management/slice';
import { SLICE as DISCUSSION_SLICE, SLICE as DiscussionSLICE } from '../../discussion/state-management/slice';
import {discussionActions} from '../../discussion/state-management/actions';
import {MetaModelIdentifier} from '../../shared/api/models/metaModelIdentifier';
import {kycActions} from '../../kyc/state-management/actions';
import {Id} from '../../shared/api/models/id';
import {KYCStatus} from '../../shared/api/models/kYCStatus';
import {ReadUser} from '../../shared/api/models/readUser';
import {ConnectEligibilityPayload} from '../../shared/api/services/eligibility.service';
import {FundingProcessSummary} from '../../shared/api/models/fundingProcessSummary';
import {ChamberOfCommerceCompany} from '../../shared/api/models/chamberOfCommerceCompany';
import {KYCFileStatusOverview} from '../../shared/api/models/kYCFileStatusOverview';
import {PostCommentPayload, ResourceType} from '../../shared/api/services/discussion.service';
import {Team} from '../../shared/api/models/team';
import {FundingProposalSnapshot} from '../../shared/api/models/fundingProposalSnapshot';
import {FilePayloadWithName} from '../../shared/api/services/kyc.service';
import {KYCDocumentDetails} from '../../shared/api/models/kYCDocumentDetails';
import {FilePayload} from '../../shared/api/services/assessment.service';
import {EligibilityAssessment} from '../../shared/api/models/eligibilityAssessment';
import {KYCAssessment} from '../../shared/api/models/kYCAssessment';
import {TranslatedFundingProposal} from '../../shared/api/models/translatedFundingProposal';
import {FundingProposalMode} from '../funding-process/funding-proposal/shared/funding-proposal-mode';
import {ReadFundingProposalUploads} from '../../shared/api/models/readFundingProposalUploads';
import {UploadCategory} from '../../shared/api/models/uploadCategory';
import {UploadType} from '../../shared/api/models/uploadType';
import {FundingProcessV2} from '../../shared/api/models/fundingProcessV2';
import {FundingProposalSnapshotV2} from '../../shared/api/models/fundingProposalSnapshotV2';
import {ReadFundingProposalUploadsV2} from '../../shared/api/models/readFundingProposalUploadsV2';
import {ReadUploadsFileDetailsList} from '../../shared/api/models/readUploadsFileDetailsList';
import {TranslatedFundingProposalV2} from '../../shared/api/models/translatedFundingProposalV2';

export interface FundingProcessSummaryRecentlyChanged extends FundingProcessSummary {
  recentlyChanged: boolean;
}

export interface EligibilityAssessmentWithId extends WithId, EligibilityAssessment {
}

export interface FundingProposalModeRow extends WithId {
  mode: FundingProposalMode;
}

export interface KycAssessmentWithId extends WithId, KYCAssessment {

}

export interface ChamberOfCommerceName {
  companyNumber: string;
  companyName?: string;
}

/********
 * Adapters
 ********/
export const fundingProcessSummaryAdapter = createTableAdapter<FundingProcessSummaryRecentlyChanged>();
export const fundingProcessAdapter = createTableAdapter<FundingProcess | FundingProcessV2>();
export const fundingProposalSnapshotAdapter = createTableAdapter<FundingProposalSnapshot | FundingProposalSnapshotV2>({
  idSelector: (snapshot: FundingProposalSnapshot) => snapshot.fundingProcessId
});
export const fundingProposalTranslationAdapter = createTableAdapter<TranslatedFundingProposal | TranslatedFundingProposalV2>({
  idSelector: (snapshot: FundingProposalSnapshot) => snapshot.fundingProcessId
});
export const fundingProposalModeAdapter = createTableAdapter<FundingProposalModeRow>();
export const clientAdapter = createTableAdapter<ReadClient>({
  idSelector: (client: ReadClient) => client.companyNumber
});
export const clientUserAdapter = createForeignTableAdapter<ReadUser>();
export const financialRatingSummaryAdapter = createForeignTableAdapter<FinancialRatingSummary>();
export const financialRatingAdapter = createTableAdapter<FinancialRating>();
export const metaModelAdapter = createTableAdapter<MetaModel>();
export const metaModelIdentifierAdapter = createTableAdapter<MetaModelIdentifier>();
export const fundingProcessValidationAdapter = createForeignTableAdapter<{ id: string, valid: boolean }>();
export const chamberOfCommerceAdapter = createTableAdapter<ChamberOfCommerceCompany>({
  idSelector: (company: ChamberOfCommerceCompany) => company.companyNumber
});
export const chamberOfCommerceNameAdapter = createTableAdapter<ChamberOfCommerceName>({
  idSelector: (company: ChamberOfCommerceName) => company.companyNumber
});
export const kycStatusAdapter = createTableAdapter<KYCFileStatusOverview>();
export const teamAdapter = createTableAdapter<Team>();
export const eligibilityAssessmentPrefillAdapter = createTableAdapter<EligibilityAssessmentWithId>();
export const kycAssessmentPrefillAdapter = createTableAdapter<KycAssessmentWithId>();
export const usersAdapter = createForeignTableAdapter<ReadUser>();

/********
 * State
 ********/
export interface State {
  fundingProcessesSummaries: Table<FundingProcessSummaryRecentlyChanged>;
  fundingProcesses: Table<FundingProcess | FundingProcessV2>;
  fundingProposalSnapshot: Table<FundingProposalSnapshot | FundingProposalSnapshotV2>;
  fundingProposalTranslation: Table<TranslatedFundingProposal | TranslatedFundingProposalV2>;
  fundingProposalMode: Table<FundingProposalModeRow>;
  clients: Table<ReadClient>;
  client_users: ForeignTable<ReadUser>;
  financialRatingSummaries: ForeignTable<FinancialRatingSummary>;
  financialRatings: Table<FinancialRating>;
  financialRatingMetaModels: Table<MetaModel>;
  fundingProposalMetaModels: Table<MetaModel>;
  fundingProposalMetaModelLatest: Table<MetaModelIdentifier>;
  fundingProcessValidations: ForeignTable<{ id: string, valid: boolean }>;
  fundingProcessesFilteredCountries: string[];
  fundingProcessesFilteredStatuses: string[];
  fundingProcessesSearchQuery: string;
  chamberOfCommerce: Table<ChamberOfCommerceCompany>;
  chamberOfCommerceName: Table<ChamberOfCommerceName>;
  kycStatus: Table<KYCFileStatusOverview>;
  team: Table<Team>;
  eligibilityAssessmentPrefill: Table<EligibilityAssessmentWithId>;
  kycAssessmentPrefill: Table<KycAssessmentWithId>;
  users: ForeignTable<ReadUser>;
}

export const initialState: State = {
  fundingProcessesSummaries: fundingProcessSummaryAdapter.getInitialState(),
  fundingProcesses: fundingProcessAdapter.getInitialState(),
  fundingProposalSnapshot: fundingProposalSnapshotAdapter.getInitialState(),
  fundingProposalTranslation: fundingProposalTranslationAdapter.getInitialState(),
  fundingProposalMode: fundingProposalModeAdapter.getInitialState(),
  clients: clientAdapter.getInitialState(),
  client_users: clientUserAdapter.getInitialState(),
  financialRatingSummaries: financialRatingSummaryAdapter.getInitialState(),
  financialRatings: financialRatingAdapter.getInitialState(),
  financialRatingMetaModels: metaModelAdapter.getInitialState(),
  fundingProposalMetaModels: metaModelAdapter.getInitialState(),
  fundingProposalMetaModelLatest: metaModelIdentifierAdapter.getInitialState(),
  fundingProcessValidations: fundingProcessValidationAdapter.getInitialState(),
  fundingProcessesFilteredCountries: undefined,
  fundingProcessesFilteredStatuses: undefined,
  fundingProcessesSearchQuery: '',
  chamberOfCommerce: chamberOfCommerceAdapter.getInitialState(),
  chamberOfCommerceName: chamberOfCommerceNameAdapter.getInitialState(),
  kycStatus: kycStatusAdapter.getInitialState(),
  team: teamAdapter.getInitialState(),
  eligibilityAssessmentPrefill: eligibilityAssessmentPrefillAdapter.getInitialState(),
  kycAssessmentPrefill: kycAssessmentPrefillAdapter.getInitialState(),
  users: usersAdapter.getInitialState()
};

export function reducer(state = initialState, action: EntityAction): State {
  switch (action.type) {
    case typeFor(SLICE.FUNDING_PROCESS_SUMMARY, actions.LOAD_ALL_SUCCESS): {
      return Object.assign({}, state, {
        fundingProcessesSummaries: fundingProcessSummaryAdapter.addPage(action.payload, state.fundingProcessesSummaries)
      });
    }
    case typeFor(SLICE.FUNDING_PROCESS_SUMMARY, actions.SELECT): {
      return Object.assign({}, state, {
        fundingProcessesSummaries: fundingProcessSummaryAdapter.select(action.payload.id, state.fundingProcessesSummaries)
      });
    }
    case typeFor(SLICE.FUNDING_PROCESS_SUMMARY, actions.LOAD_SINGLE_SUCCESS): {
      return Object.assign({}, state, {
        fundingProcessesSummaries: fundingProcessSummaryAdapter.addOne(action.payload, state.fundingProcessesSummaries)
      });
    }
    case typeFor(SLICE.FUNDING_PROCESS, actions.SELECT): {
      return Object.assign({}, state, {
        fundingProcesses: fundingProcessAdapter.select(action.payload.id, state.fundingProcesses)
      });
    }
    case typeFor(SLICE.FUNDING_PROCESS, actions.LOAD_SINGLE_SUCCESS): {
      return Object.assign({}, state, {
        fundingProcesses: fundingProcessAdapter.addOne(action.payload, state.fundingProcesses)
      });
    }
    case typeFor(SLICE.FUNDING_PROCESS, actions.UPDATE_SINGLE_SUCCESS): {
      return Object.assign({}, state, {
          fundingProcesses: fundingProcessAdapter.updateOne({
            ...action.payload,
            lastModificationTime: new Date().toString()
          } as FundingProcess, state.fundingProcesses)
        },
        {
          fundingProcessesSummaries: fundingProcessSummaryAdapter.updateOne({
            ...action.payload,
            lastModificationTime: new Date().toString()
          } as FundingProcessSummaryRecentlyChanged, state.fundingProcessesSummaries)
        });
    }
    case typeFor(SLICE.FUNDING_PROCESS, originationActions.PIN_FUNDING_PROCESS_SUCCESS): {
      return Object.assign({}, state, {
          fundingProcesses: fundingProcessAdapter.updateOne({
            id: action.payload.id,
            pinned: true,
          } as any, state.fundingProcesses)
        },
        {
          fundingProcessesSummaries: fundingProcessSummaryAdapter.updateOne({
            id: action.payload.id,
            pinned: true,
            recentlyChanged: true
          } as any, state.fundingProcessesSummaries)
        });
    }
    case typeFor(SLICE.FUNDING_PROCESS, originationActions.UNPIN_FUNDING_PROCESS_SUCCESS): {
      return Object.assign({}, state, {
          fundingProcesses: fundingProcessAdapter.updateOne({
            id: action.payload.id,
            pinned: false,
          } as any, state.fundingProcesses)
        },
        {
          fundingProcessesSummaries: fundingProcessSummaryAdapter.updateOne({
            ...action.payload,
            pinned: false,
            recentlyChanged: true
          } as FundingProcessSummaryRecentlyChanged, state.fundingProcessesSummaries)
        });
    }
    case typeFor(SLICE.FUNDING_PROCESS, originationActions.ASSIGN_FUNDING_PROCESS_SUCCESS): {
      return Object.assign({}, state, {
        fundingProcesses: fundingProcessAdapter.updateOne({
          id: action.payload.id,
          assignedCreditOfficer: {
            userId: action.payload.userId,
            name: action.payload.name
          },
        } as any, state.fundingProcesses)
      });
    }
    case typeFor(SLICE.FUNDING_PROCESS, originationActions.UN_ASSIGN_FUNDING_PROCESS_SUCCESS): {
      return Object.assign({}, state, {
        fundingProcesses: fundingProcessAdapter.updateOne({
          id: action.payload.id,
          assignedCreditOfficer: null,
        } as any, state.fundingProcesses)
      });
    }
    case typeFor(SLICE.FUNDING_PROCESS, originationActions.DISABLE_RECENTLY_CHANGED): {
      return Object.assign({}, state,
        {
          fundingProcessesSummaries: fundingProcessSummaryAdapter.updateOne({
            id: action.payload.id,
            recentlyChanged: false
          } as any, state.fundingProcessesSummaries)
        });
    }
    case typeFor(SLICE.FUNDING_PROCESS, actions.DELETE_SINGLE_SUCCESS): {
      const request: WithId = (action as DeleteSingleSuccess<any>).request;
      return Object.assign({}, state, {
        fundingProcesses: fundingProcessAdapter.removeOne(request.id, state.fundingProcesses),
        fundingProcessesSummaries: fundingProcessSummaryAdapter.removeOne(request.id, state.fundingProcessesSummaries)
      });
    }

    case typeFor(DISCUSSION_SLICE.DISCUSSION, discussionActions.START_DISCUSSION_SUCCESS): {
      const discussionAction = action as any;
      if (discussionAction.request.resourceType !== ResourceType.FUNDING_PROPOSAL ) {
        return state;
      }

      const fundingProcess = Object.assign({}, state.fundingProcesses.entities[discussionAction.request.resourceId]);
      const copyOfUploads = JSON.parse(JSON.stringify(fundingProcess.fundingProposal.uploads));
      const fileToUpdate = getAllUploadedFiles(fundingProcess.fundingProposalVersion, copyOfUploads)
        .find(file => file.fileId === discussionAction.request.fileId);

      fileToUpdate.discussionId = discussionAction.payload.id;

      const updatedFundingProposal = Object.assign({}, fundingProcess.fundingProposal, {uploads: copyOfUploads});
      const updatedFundingProcess = Object.assign({}, fundingProcess, {fundingProposal: updatedFundingProposal});
      return Object.assign({}, state, {
        fundingProcesses: fundingProcessAdapter.updateOne({...updatedFundingProcess}, state.fundingProcesses)
      });
    }


    case typeFor(SLICE.FUNDING_PROPOSAL_SNAPSHOT, actions.LOAD_SINGLE_SUCCESS): {
      const request: WithId = (action as LoadSingleSuccess<any>).request;
      return Object.assign({}, state, {
        fundingProposalSnapshot: fundingProposalSnapshotAdapter.addOne(action.payload, state.fundingProposalSnapshot)
      });
    }

    case typeFor(SLICE.FUNDING_PROPOSAL_TRANSLATION, actions.LOAD_SINGLE_SUCCESS): {
      const request: WithId = (action as LoadSingleSuccess<any>).request;
      return Object.assign({}, state, {
        fundingProposalTranslation: fundingProposalTranslationAdapter.addOne(action.payload, state.fundingProposalTranslation)
      });
    }

    case typeFor(SLICE.FUNDING_PROPOSAL, originationActions.UPDATE_FUNDING_PROPOSAL_MODE): {
      const payload = (action as UpdateFundingProposalMode).payload;
      return Object.assign({}, state, {
        fundingProposalMode: fundingProposalModeAdapter.addOne(payload, state.fundingProposalMode)
      });
    }

    case typeFor(SLICE.CLIENTS, actions.LOAD_ALL_SUCCESS): {
      return Object.assign({}, state, {
        clients: clientAdapter.addAll(action.payload, clientAdapter.getInitialState())
      });
    }
    case typeFor(SLICE.CLIENTS_USERS, actions.LOAD_SINGLE_SUCCESS): {
      const companyNumber = (action as LoadSingleSuccess<any>).request.id;
      return Object.assign({}, state, {
        client_users: clientUserAdapter.addAll(action.payload, companyNumber, state.client_users)
      });
    }
    case typeFor(SLICE.FINANCIAL_RATING_SUMMARY, actions.LOAD_SINGLE_SUCCESS): {
      const fundingProcessId = (action as LoadSingleSuccess<FinancialRatingSummary>).request.id;
      return Object.assign({}, state, {
        financialRatingSummaries: financialRatingSummaryAdapter.addAll(action.payload, fundingProcessId, state.financialRatingSummaries)
      });
    }
    case typeFor(SLICE.FINANCIAL_RATING, actions.LOAD_SINGLE_SUCCESS): {
      return Object.assign({}, state, {
        financialRatings: financialRatingAdapter.addOne(action.payload, state.financialRatings)
      });
    }
    case typeFor(SLICE.FINANCIAL_RATING_META_MODEL, actions.LOAD_SINGLE_SUCCESS): {
      const fundingProcessId = (action as LoadSingleSuccess<any>).request.id;
      return Object.assign({}, state, {
        financialRatingMetaModels: metaModelAdapter.addOneWithId(action.payload, fundingProcessId, state.financialRatingMetaModels)
      });
    }
    case typeFor(SLICE.FINANCIAL_RATING, actions.CREATE_SINGLE_SUCCESS): {
      const createSuccessAction = (action as CreateSingleSuccess<any>);
      const summary = {...createSuccessAction.payload, ...createSuccessAction.request.financialRating} as FinancialRatingSummary;
      summary.product = createSuccessAction.request.product;
      summary.editable = true;
      summary.scoringModelVersion = 2;
      return Object.assign({}, state, {
        financialRatingSummaries: financialRatingSummaryAdapter.addOne(
          summary,
          createSuccessAction.request.fundingProcessId,
          state.financialRatingSummaries)
      });
    }
    case typeFor(SLICE.FINANCIAL_RATING, actions.DELETE_SINGLE_SUCCESS): {
      const ids: FinancialRatingId = (action as DeleteSingleSuccess<any>).request;
      return Object.assign({}, state, {
        financialRatings: financialRatingAdapter.removeOne(ids.id, state.financialRatings),
        financialRatingSummaries: financialRatingSummaryAdapter.removeOneById(ids.id, ids.fundingProcessId, state.financialRatingSummaries)
      });
    }
    case typeFor(SLICE.FINANCIAL_RATING, actions.UPDATE_SINGLE_SUCCESS): {
      const request = (action as UpdateSingleSuccess<any>).request;
      const financialRating = Object.assign({}, action.payload, {
        financials: request.financialRating.financials,
        editable: true
      });
      return Object.assign({}, state, {
        financialRatings: financialRatingAdapter.updateOne(financialRating, state.financialRatings),
        financialRatingSummaries: financialRatingSummaryAdapter.updateOne({
            ...action.payload
          } as FinancialRatingSummary,
          request.fundingProcessId,
          state.financialRatingSummaries)
      });
    }
    case typeFor(SLICE.FUNDING_PROPOSAL_META_MODEL, actions.LOAD_SINGLE_SUCCESS): {
      const fundingProcessId = (action as LoadSingleSuccess<any>).request.id;
      return Object.assign({}, state, {
        fundingProposalMetaModels: metaModelAdapter.addOneWithId(action.payload, fundingProcessId, state.fundingProposalMetaModels)
      });
    }
    case typeFor(SLICE.FUNDING_PROPOSAL_META_MODEL_LATEST, actions.LOAD_SINGLE_SUCCESS): {
      const fundingProcessId = (action as LoadSingleSuccess<any>).request.id;
      return Object.assign({}, state, {
        fundingProposalMetaModelLatest: metaModelIdentifierAdapter.addOneWithId(action.payload, fundingProcessId, state.fundingProposalMetaModelLatest)
      });
    }
    case typeFor(SLICE.FUNDING_PROPOSAL, originationActions.VALIDATION_CHANGED): {
      return Object.assign({}, state, {
        fundingProcessValidations: fundingProcessValidationAdapter.addAll(action.payload.status, action.payload.id, state.fundingProcessValidations)
      });
    }
    case typeFor(SLICE.FUNDING_PROPOSAL, actions.UPDATE_SINGLE_SUCCESS): {
      const request = (action as UpdateSingleSuccess<any>).request;
      const fundingProcessId = request.id;
      const fundingProposal = request.fundingProposal;
      const oldFundingProcess = state.fundingProcesses.entities[fundingProcessId];

      let copyOfUploads = {};
      if (request.version === 1 || request.version === 2) {
        copyOfUploads = JSON.parse(JSON.stringify(fundingProposal.uploads));
        // We accept the 99% accuracy of the upload date over loading the proposal after each save to get the actual upload time the backend has
        Object.keys(copyOfUploads).forEach(fileListName => {
          copyOfUploads[fileListName]
            .filter(file => !file.uploadedTime)
            .forEach(file => file.uploadedTime = new Date().toUTCString());
        });
      } else {
        console.warn(`TODO: Version ${request.version} uploads disabled in reducer update_single_success`);
      }

      return Object.assign({}, state, {
        fundingProcesses: fundingProcessAdapter.updateOne({
          ...oldFundingProcess,
          fundingProposal: Object.assign({}, oldFundingProcess.fundingProposal, fundingProposal, {uploads: copyOfUploads})
        }, state.fundingProcesses)
      });
    }
    case typeFor(SLICE.FUNDING_PROCESS_SUMMARY, originationActions.UPDATE_FUNDING_PROCESS_FILTERED_COUNTRIES): {
      const payload = (action as UpdateFundingProcessFilteredCountries).payload;
      const newFilter = buildNewFilter(state.fundingProcessesFilteredCountries, payload.isChecked, payload.country);
      return Object.assign({}, state, {fundingProcessesFilteredCountries: newFilter});
    }
    case typeFor(SLICE.FUNDING_PROCESS_SUMMARY, originationActions.UPDATE_FUNDING_PROCESS_FILTERED_STATUSES): {
      const payload = (action as UpdateFundingProcessFilteredStatuses).payload;
      const newFilter = buildNewFilter(state.fundingProcessesFilteredStatuses, payload.isChecked, payload.status);
      return Object.assign({}, state, {fundingProcessesFilteredStatuses: newFilter});
    }
    case typeFor(SLICE.FUNDING_PROCESS_SUMMARY, originationActions.UPDATE_FUNDING_PROCESS_SEARCH_QUERY): {
      const payload = (action as UpdateFundingProcessSearchQuery).payload;
      return Object.assign({}, state, {fundingProcessesSearchQuery: payload.searchQuery});
    }
    case typeFor(SLICE.ELIGIBILITY, originationActions.ELIGIBILITY_CHECK_CONNECT_SUCCESS): {
      const payload = action.payload as ConnectEligibilityPayload;
      for (const key of Object.keys(state.fundingProcesses.entities)) {
        const fp = state.fundingProcesses.entities[key];
        if (fp.id === payload.connectEligibility.fundingProcessId && !fp.eligibilityId) {
          const updatedFundingProcess = Object.assign({}, fp);
          updatedFundingProcess.eligibilityId = payload.id;
          return Object.assign({}, state, {fundingProcesses: fundingProcessAdapter.updateOne({...updatedFundingProcess}, state.fundingProcesses)});
        }
      }
      return state;
    }
    case typeFor(KycSLICE.KYC_FILE, kycActions.COMPLETE_KYC_SUCCESS): {
      const kycFileId = action.payload as Id;
      for (const key of Object.keys(state.fundingProcessesSummaries.entities)) {
        const fundingProcessSummary = state.fundingProcessesSummaries.entities[key];
        if (fundingProcessSummary.kycFileId === kycFileId.id) {
          const updatedFundingProcess = Object.assign({}, state.fundingProcesses.entities[key], {kycStatus: KYCStatus.COMPLETED});
          const updatedFundingProcessSummary = Object.assign({}, fundingProcessSummary, {kycStatus: KYCStatus.COMPLETED});

          state = Object.assign({}, state, {
            fundingProcesses: fundingProcessAdapter.updateOne({...updatedFundingProcess}, state.fundingProcesses),
            fundingProcessesSummaries: fundingProcessSummaryAdapter.updateOne({...updatedFundingProcessSummary}, state.fundingProcessesSummaries)
          });
        }
      }
      return state;
    }
    case typeFor(SLICE.CHAMBER_OF_COMMERCE, actions.LOAD_SINGLE_SUCCESS): {
      if (!action.payload.result) {
        return Object.assign({}, state, {
          chamberOfCommerce: chamberOfCommerceAdapter.addOne({companyNumber: (action as CreateSingleSuccess<WithId>).request.id}, state.chamberOfCommerce)
        });
      }
      return Object.assign({}, state, {
        chamberOfCommerce: chamberOfCommerceAdapter.addOne(action.payload.result, state.chamberOfCommerce)
      });
    }
    case typeFor(SLICE.CHAMBER_OF_COMMERCE_NAME, actions.LOAD_SINGLE_SUCCESS): {
      return Object.assign({}, state, {
        chamberOfCommerceName: chamberOfCommerceNameAdapter.addOne(action.payload, state.chamberOfCommerceName)
      });
    }
    case typeFor(SLICE.COMMUNICATION_FILES, originationActions.ATTACH_COMMUNICATION_FILE_SUCCESS): {
      const attachFile = action.payload as FilePayloadWithName;

      const fundingProcess = Object.assign({}, state.fundingProcesses.entities[attachFile.id]) as FundingProcess;

      const file = {
        fileId: attachFile.fileDetails.fileId,
        name: attachFile.fileDetails.name,
        uploadedTime: new Date()
      } as KYCDocumentDetails;

      fundingProcess.assessment = Object.assign({}, fundingProcess.assessment, {communicationFiles: [file, ...fundingProcess.assessment.communicationFiles]});

      return Object.assign({}, state, {
        fundingProcesses: fundingProcessAdapter.updateOne({...fundingProcess}, state.fundingProcesses)
      });
    }
    case typeFor(SLICE.COMMUNICATION_FILES, originationActions.REMOVE_COMMUNICATION_FILE_SUCCESS): {
      const removeFile = action.payload as FilePayload;

      const fundingProcess = Object.assign({}, state.fundingProcesses.entities[removeFile.id]) as FundingProcess;

      fundingProcess.assessment = Object.assign({}, fundingProcess.assessment, {communicationFiles: fundingProcess.assessment.communicationFiles.filter(value => value.fileId !== removeFile.fileId)});

      return Object.assign({}, state, {
        fundingProcesses: fundingProcessAdapter.updateOne({...fundingProcess}, state.fundingProcesses)
      });
    }
    case typeFor(SLICE.CREDIT_ASSESSMENT, actions.UPDATE_SINGLE_SUCCESS): {
      const updateAction = (<UpdateSingleSuccess<any>>action);
      const fundingProcess = state.fundingProcesses.entities[updateAction.request.id];
      const updatedFundingProcess = Object.assign({}, fundingProcess,
        {
          assessment: Object.assign({},
            fundingProcess.assessment,
            {creditAssessment: fundingProcess.assessment ? fundingProcess.assessment.creditAssessment : {}},
            {creditAssessment: updateAction.request})
        });

      return Object.assign({}, state, {
        fundingProcesses: fundingProcessAdapter.updateOne(updatedFundingProcess, state.fundingProcesses)
      });
    }
    case typeFor(SLICE.ELIGIBILITY_ASSESSMENT, actions.UPDATE_SINGLE_SUCCESS): {
      const updateAction = (<UpdateSingleSuccess<any>>action);
      const fundingProcess = state.fundingProcesses.entities[updateAction.request.id];
      const updatedFundingProcess = Object.assign({}, fundingProcess,
        {
          assessment: Object.assign({},
            fundingProcess.assessment,
            {eligibility: fundingProcess.assessment ? fundingProcess.assessment.eligibility : {}},
            {eligibility: updateAction.request})
        });

      return Object.assign({}, state, {
        fundingProcesses: fundingProcessAdapter.updateOne(updatedFundingProcess, state.fundingProcesses)
      });
    }
    case typeFor(SLICE.ELIGIBILITY_ASSESSMENT_PREFILL, actions.LOAD_SINGLE_SUCCESS): {
      const fundingProcessId = (action as LoadSingleSuccess<EligibilityAssessment>).request.id;
      return Object.assign({}, state, {
        eligibilityAssessmentPrefill: eligibilityAssessmentPrefillAdapter.addOne({
          ...action.payload,
          id: fundingProcessId
        }, state.eligibilityAssessmentPrefill)
      });
    }
    case typeFor(SLICE.KYC_ASSESSMENT_PREFILL, actions.LOAD_SINGLE_SUCCESS): {
      const fundingProcessId = (action as LoadSingleSuccess<KycAssessmentWithId>).request.id;
      return Object.assign({}, state, {
        kycAssessmentPrefill: kycAssessmentPrefillAdapter.addOne({
          ...action.payload,
          id: fundingProcessId
        }, state.kycAssessmentPrefill)
      });
    }
    case typeFor(SLICE.KYC_ASSESSMENT, actions.UPDATE_SINGLE_SUCCESS): {
      const updateAction = (<UpdateSingleSuccess<any>>action);
      const fundingProcess = Object.assign({}, state.fundingProcesses.entities[updateAction.request.id]);
      const updatedFundingProcess = Object.assign({}, fundingProcess,
        {
          assessment: Object.assign({},
            fundingProcess.assessment,
            {kyc: fundingProcess.assessment ? fundingProcess.assessment.eligibility : {}},
            {kyc: updateAction.request})
        });

      return Object.assign({}, state, {
        fundingProcesses: fundingProcessAdapter.updateOne(updatedFundingProcess, state.fundingProcesses)
      });
    }
    case typeFor(SLICE.KYC_STATUS, actions.LOAD_SINGLE_SUCCESS): {
      return Object.assign({}, state, {
        kycStatus: kycStatusAdapter.addOne(action.payload, state.kycStatus)
      });
    }
    case typeFor(DiscussionSLICE.DISCUSSION, discussionActions.POST_COMMENT_SUCCESS): {
      const payload = action.payload as PostCommentPayload;
      if (payload.resourceType === ResourceType.KYC) {
        // See comment below where we the same remove is done
        return Object.assign({}, state, {
          kycStatus: kycStatusAdapter.removeOne(payload.resourceId, state.kycStatus)
        });
      } else {
        return state;
      }
    }
    case typeFor(SLICE.TEAM, originationActions.LOAD_SINGLE_SUCCESS): {
      const loadAction = <LoadSingleSuccess<Team>>action;

      return Object.assign({}, state, {
        team: teamAdapter.addOne({
          id: loadAction.request.id,
          ...loadAction.payload
        } as any, state.team)
      });
    }
    case typeFor(SLICE.FUNDING_PROPOSAL, originationActions.UPDATE_UPLOADS_CATEGORIES): {
      const payload = (action as UpdateUploadCategories).payload as UpdateUploadsCategoriesPayload;

      const fundingProcess = Object.assign({}, state.fundingProcesses.entities[payload.id]) as FundingProcess;
      const otherUploads = fundingProcess.fundingProposalVersion === 1 ? fundingProcess.fundingProposal.uploads.other : fundingProcess.fundingProposal.uploads[UploadType.OTHER];
      const copyOfOtherFiles = JSON.parse(JSON.stringify(otherUploads));
      payload.files.forEach(value => {
        const fileToUpdate = copyOfOtherFiles.find(otherFile => otherFile.fileId === value.fileId);
        fileToUpdate.category = value.category;
      });


      const updatedFundingProcess = deepmerge(fundingProcess, getChangedOtherUploads(fundingProcess.fundingProposalVersion, copyOfOtherFiles), {arrayMerge: overwriteMerge});
      return Object.assign({}, state, {
        fundingProcesses: fundingProcessAdapter.updateOne({...updatedFundingProcess}, state.fundingProcesses)
      });
    }
    case typeFor(SLICE.FUNDING_PROPOSAL, originationActions.REVIEW_UPLOAD_SUCCESS): {
      const payload = (action as ReviewUploadSuccess).payload;

      const fundingProcess = Object.assign({}, state.fundingProcesses.entities[payload.id]);
      const copyOfUploads = JSON.parse(JSON.stringify(fundingProcess.fundingProposal.uploads));
      const fileToUpdate = getAllUploadedFiles(fundingProcess.fundingProposalVersion, copyOfUploads)
        .find(file => file.fileId === payload.fileId);

      fileToUpdate.reviewStatus = payload.reviewStatus;

      const updatedFundingProposal = Object.assign({}, fundingProcess.fundingProposal, {uploads: copyOfUploads});
      const updatedFundingProcess = Object.assign({}, fundingProcess, {fundingProposal: updatedFundingProposal});
      return Object.assign({}, state, {
        fundingProcesses: fundingProcessAdapter.updateOne({...updatedFundingProcess}, state.fundingProcesses)
      });
    }
    case typeFor(SLICE.FUNDING_PROPOSAL, originationActions.MOVE_UPLOAD): {
      const payload = (action as MoveUpload).payload;

      return moveFile(state, payload);
    }
    case typeFor(SLICE.FUNDING_PROPOSAL, originationActions.MOVE_UPLOAD_FAILURE): {
      const payload = (action as MoveUploadFailure).payload;

      return moveFile(state, payload);
    }
    case typeFor(SLICE.COMPANY_USERS, actions.LOAD_SINGLE_SUCCESS): {
      const companyId = (action as LoadSingleSuccess<ReadUser>).request.id;
      return Object.assign({}, state, {
        users: usersAdapter.addAll(action.payload, companyId, state.users)
      });
    }
  }

  if (action.slice === KycSLICE.KYC_FILE && !action.type.includes('LOAD')) {
    /*
      We remove not an entry from the list but the entire status object (which contains our change list)
      As to make sure it get's reloaded from the server when the user view it again.
     */
    return Object.assign({}, state, {
      kycStatus: kycStatusAdapter.removeOne(action.payload.id, state.kycStatus)
    });
  }

  return state;
}

function moveFile(state: State, payload: MoveUploadPayload) {
  const readOnlyFundingProcess = state.fundingProcesses.entities[payload.id];
  const uploads = JSON.parse(JSON.stringify(readOnlyFundingProcess.fundingProposal.uploads));
  const fileToMove = Object.assign({}, getAllUploadedFiles(readOnlyFundingProcess.fundingProposalVersion, uploads)
    .find(file => file.fileId === payload.fileId));

  const processChanges = {
    fundingProposal: {
      uploads: {}
    }
  };

  const headingToMoveFrom = readOnlyFundingProcess.fundingProposalVersion === 1 ? getUploadFieldNameByHeading(uploads, payload.from) : payload.from;
  const headingToMoveTo = readOnlyFundingProcess.fundingProposalVersion === 1 ? getUploadFieldNameByHeading(uploads, payload.to) : payload.to;
  processChanges.fundingProposal.uploads[headingToMoveFrom] = uploads[headingToMoveFrom].filter(entry => entry.fileId !== payload.fileId);
  processChanges.fundingProposal.uploads[headingToMoveTo] = Object.assign([], uploads[headingToMoveTo]);
  processChanges.fundingProposal.uploads[headingToMoveTo].push(fileToMove);

  if (payload.to.toUpperCase() === UploadCategory.OTHER.toString()) {
    fileToMove.category = UploadCategory.OTHER;
  }

  const updatedFundingProcess = deepmerge(readOnlyFundingProcess, processChanges, {arrayMerge: overwriteMerge});
  return Object.assign({}, state, {
    fundingProcesses: fundingProcessAdapter.updateOne({...updatedFundingProcess}, state.fundingProcesses)
  });
}

function getUploadFieldNameByHeading(uploads: ReadFundingProposalUploads, heading: UploadType) {
  switch (heading) {
    case UploadType.STATUTORYACCOUNTS:
      return 'statutoryAccounts';
    case UploadType.AGEDDEBTORREPORT:
      return 'agedDebtorReport';
    case UploadType.AGEDCREDITORREPORT:
      return 'agedCreditorReport';
    case UploadType.BANKSTATEMENT:
      return 'bankStatement';
    case UploadType.ASSETANDLIABILITYSTATEMENTS:
      return 'assetAndLiabilityStatements';
    case UploadType.SECURITYDOCUMENTATION:
      return 'securityDocumentation';
    case UploadType.ORGANISATIONSTRUCTURE:
      return 'organisationStructure';
    case UploadType.LIMITATIONOFLIABILITY:
      return 'limitationOfLiability';
    case UploadType.ENGAGEMENTLETTER:
      return 'engagementLetter';
    case UploadType.ESGQUESTIONNAIRE:
      return 'esgQuestionnaire';
    case UploadType.MATERIALCOMMERCIALAGREEMENTS:
      return 'materialCommercialAgreements';
    case UploadType.MANAGEMENTSYSTEMACCREDITATION:
      return 'managementSystemAccreditation';
    case UploadType.OTHER:
      return 'other';
    default:
      throw new Error(`Unknown upload heading ${heading} failed to find in uploads`);
  }
}

function buildNewFilter(state, isChecked: boolean, changedValue) {
  let newFilter = Object.assign([], state);
  if (isChecked) {
    if (!newFilter.includes(changedValue)) {
      newFilter.push(changedValue);
    }
  } else {
    newFilter = newFilter.filter(i => i !== changedValue);
  }

  return newFilter;
}

function getAllUploadedFiles(fundingProposalVersion: number, uploads: ReadFundingProposalUploads | ReadFundingProposalUploadsV2) {
  switch (fundingProposalVersion) {
    case 1:
      return uploads.agedCreditorReport.concat(
        uploads.agedDebtorReport,
        uploads.bankStatement,
        uploads.engagementLetter,
        uploads.esgQuestionnaire,
        uploads.limitationOfLiability,
        uploads.organisationStructure,
        uploads.other,
        uploads.statutoryAccounts
      );
    case 2:
      return Object.keys(uploads)
        .map(uploadKey => uploads[uploadKey] as ReadUploadsFileDetailsList)
        .reduce((previousValue, currentValue) => previousValue.concat(currentValue));

    default:
      throw new Error(`Unknown funding proposal version ${fundingProposalVersion}`);
  }

}

function getChangedOtherUploads(fundingProposalVersion: number, newOtherUploads) {
  switch (fundingProposalVersion) {
    case 1:
      return {
        fundingProposal: {
          uploads: {
            other: newOtherUploads
          }
        }
      };
    case 2:
      return {
        fundingProposal: {
          uploads: {
            [UploadType.OTHER]: newOtherUploads
          }
        }
      };
    default:
      throw new Error(`Unknown funding proposal version ${fundingProposalVersion}`);
  }
}

const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;

export const selectFeature = createFeatureSelector<State>('origination');
