import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { AbstractApiService } from './abstract-api.service';
var FeedbackService = /** @class */ (function (_super) {
    tslib_1.__extends(FeedbackService, _super);
    function FeedbackService(httpClient) {
        var _this = _super.call(this, httpClient) || this;
        _this.baseUrl = '/api/v1/feedback';
        return _this;
    }
    FeedbackService.prototype.sendFeedback = function (payload) {
        return this.post(this.baseUrl, payload);
    };
    return FeedbackService;
}(AbstractApiService));
export { FeedbackService };
