/**
 * Caple Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type UploadType = 'INFORMATION_MEMORANDUM' | 'GROUP_STRUCTURE_CHART' | 'ANNUAL_REPORTS' | 'MANAGEMENT_ACCOUNTS' | 'MANAGEMENT_FORECASTS' | 'SUPPORT_OF_PROJECTIONS' | 'AGED_STOCK_LIST' | 'OTHER_LENDERS' | 'BORROWER_PARTNER_AGREEMENT' | 'STATUTORY_ACCOUNTS' | 'AGED_DEBTOR_REPORT' | 'AGED_CREDITOR_REPORT' | 'BANK_STATEMENT' | 'ASSET_AND_LIABILITY_STATEMENTS' | 'SECURITY_DOCUMENTATION' | 'ORGANISATION_STRUCTURE' | 'LIMITATION_OF_LIABILITY' | 'ENGAGEMENT_LETTER' | 'ESG_QUESTIONNAIRE' | 'MANAGEMENT_INFORMATION' | 'MATERIAL_COMMERCIAL_AGREEMENTS' | 'MANAGEMENT_SYSTEM_ACCREDITATION' | 'OTHER';

export const UploadType = {
    INFORMATIONMEMORANDUM: 'INFORMATION_MEMORANDUM' as UploadType,
    GROUPSTRUCTURECHART: 'GROUP_STRUCTURE_CHART' as UploadType,
    ANNUALREPORTS: 'ANNUAL_REPORTS' as UploadType,
    MANAGEMENTACCOUNTS: 'MANAGEMENT_ACCOUNTS' as UploadType,
    MANAGEMENTFORECASTS: 'MANAGEMENT_FORECASTS' as UploadType,
    SUPPORTOFPROJECTIONS: 'SUPPORT_OF_PROJECTIONS' as UploadType,
    AGEDSTOCKLIST: 'AGED_STOCK_LIST' as UploadType,
    OTHERLENDERS: 'OTHER_LENDERS' as UploadType,
    BORROWERPARTNERAGREEMENT: 'BORROWER_PARTNER_AGREEMENT' as UploadType,
    STATUTORYACCOUNTS: 'STATUTORY_ACCOUNTS' as UploadType,
    AGEDDEBTORREPORT: 'AGED_DEBTOR_REPORT' as UploadType,
    AGEDCREDITORREPORT: 'AGED_CREDITOR_REPORT' as UploadType,
    BANKSTATEMENT: 'BANK_STATEMENT' as UploadType,
    ASSETANDLIABILITYSTATEMENTS: 'ASSET_AND_LIABILITY_STATEMENTS' as UploadType,
    SECURITYDOCUMENTATION: 'SECURITY_DOCUMENTATION' as UploadType,
    ORGANISATIONSTRUCTURE: 'ORGANISATION_STRUCTURE' as UploadType,
    LIMITATIONOFLIABILITY: 'LIMITATION_OF_LIABILITY' as UploadType,
    ENGAGEMENTLETTER: 'ENGAGEMENT_LETTER' as UploadType,
    ESGQUESTIONNAIRE: 'ESG_QUESTIONNAIRE' as UploadType,
    MANAGEMENTINFORMATION: 'MANAGEMENT_INFORMATION' as UploadType,
    MATERIALCOMMERCIALAGREEMENTS: 'MATERIAL_COMMERCIAL_AGREEMENTS' as UploadType,
    MANAGEMENTSYSTEMACCREDITATION: 'MANAGEMENT_SYSTEM_ACCREDITATION' as UploadType,
    OTHER: 'OTHER' as UploadType
}
