import { merge as observableMerge } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { FormArray, FormGroup } from '@angular/forms';
import { FormGroupMapperService } from '../../origination/state-management/form-group-mapper.service';
import { markAllFormFieldsAsTouched } from '../util/form-util';
var FormGroupContainer = /** @class */ (function () {
    function FormGroupContainer(form) {
        this.form = form;
    }
    Object.defineProperty(FormGroupContainer.prototype, "valid", {
        /**
         * Whether the form is valid
         * @returns {boolean}
         */
        get: function () {
            return this.form.valid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormGroupContainer.prototype, "readOnly", {
        /**
         * Whether or not the form is read only
         */
        get: function () {
            return this.form.disabled;
        },
        /**
         * Whether or not the form should be read only
         * @param {Boolean} value
         */
        set: function (value) {
            if (value) {
                this.form.disable();
            }
            else {
                this.form.enable();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormGroupContainer.prototype, "statusChanges", {
        /**
         * Observable for when the status of the form or one of the top-level components changes
         */
        get: function () {
            var _this = this;
            var controlObservables = Object.keys(this.form.controls).map(function (field) { return _this.get(field).statusChanges.pipe(distinctUntilChanged()); });
            return observableMerge.apply(void 0, controlObservables).pipe(map(function (status) {
                return status === 'VALID';
            }));
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Get the data as T
     * @returns {T}
     */
    FormGroupContainer.prototype.getData = function () {
        return this.form.getRawValue();
    };
    /**
     * Get a child control
     * @param {Array<string | number> | string} path
     * @returns {AbstractControl}
     */
    FormGroupContainer.prototype.get = function (path) {
        return this.form.get(path);
    };
    /**
     *  Patches the value of the {@link FormGroup}. It accepts an object with control
     *  names as keys, and will do its best to match the values to the correct controls
     *  in the group.
     */
    FormGroupContainer.prototype.patchValue = function (value, options) {
        this.form.patchValue(value, options);
        this.patchFormArrays(value, this.form);
    };
    /**
     * Get a Map of each top-level control in the form and its valid status.
     * @returns {Map<string, boolean>}
     */
    FormGroupContainer.prototype.getValidityOfControls = function () {
        var _this = this;
        var validity = [];
        Object.keys(this.form.controls).forEach(function (field) {
            validity.push({
                id: field,
                valid: _this.form.get(field).valid
            });
        });
        return validity;
    };
    /**
     * Mark all controls and their child controls as touched. Can be used to validate all forms in the field.
     */
    FormGroupContainer.prototype.markAllAsTouched = function () {
        markAllFormFieldsAsTouched(this.form);
    };
    /**
     * The regular patch function does not push new elements into array's. So lets stay we get 2 items from the backend
     * but currently there is only 1 item in the FormArray, then only the first item will be updated and the second
     * item will be ignored. This is expected, see the docs: https://angular.io/api/forms/FormArray#patchValue
     * Fundamentally, patchValue() doesn't know what the FormGroup items that are contained inside the FormArray look like.
     *
     * In order to update the arrays properly, we need to walk the entire form and make the form controls match the values from the server
     */
    FormGroupContainer.prototype.patchFormArrays = function (value, form) {
        var _this = this;
        if (!value) {
            return;
        }
        Object.keys(form.controls).forEach(function (name) {
            var control = form.controls[name];
            if (control instanceof FormGroup) {
                _this.patchFormArrays(value[name], control);
            }
            else if (control instanceof FormArray) {
                while (control.controls.length > 0) {
                    control.removeAt(0);
                }
                var newFormArrayElements = FormGroupMapperService.getFormGroupArray(name, value[name]);
                newFormArrayElements.forEach(function (element) { return control.push(element); });
            }
        });
    };
    return FormGroupContainer;
}());
export { FormGroupContainer };
