let typeForCache: { [slice: string]: { [action: string]: string } } = {};

/**
 * Take a slice and an action and turn it into a string literal. This string literal
 * can be used as type for a certain action.
 *
 * @param {string} slice
 * @param {string} action
 * @returns {string}
 */
export function typeFor(slice: string, action: string) {
  if (typeForCache[slice] && typeForCache[slice][action]) {
    return typeForCache[slice][action];
  } else {
    typeForCache[slice] = typeForCache[slice] || {};
    typeForCache[slice][action] = `[${slice}] ${action}`;
    return typeForCache[slice][action];
  }
}

/**
 * Take an entire slice object and turn it into an array of string literal.
 * @param {Object} sliceObject
 * @param {string} action
 * @returns {string[]}
 */
export function typeForSlice(sliceObject: Object, action: string): string[] {
  const result = [];
  Object.keys(sliceObject).forEach((key) => {
    result.push(typeFor(sliceObject[key], action));
  });
  return result;
}

export function resetTypeCaches() {
  typeForCache = {};
}

/**
 * Compare 2 objects with each other. The result is a number where:
 * -1 means a is smaller than b
 * 0 means that both are equal
 * 1 means that a is bigger than b
 */
export function compare(a: any, b: any): any {
  if (!a) {
    return -1;
  }

  if (!b) {
    return 1;
  }

  if (a === b) {
    return 0;
  }

  if (typeof a === 'string') {
    return (a as string).localeCompare(b as string);
  }

  if (a instanceof Date) {
    return (a as Date).getDate() - (b as Date).getDate();
  }

  throw new Error('Unsupported comparison type. [' + typeof a + '] is not supported');
}
