import { Component } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { CloseModal } from '../../../state-management/layout/actions';
import { Store } from '@ngrx/store';
import { State } from '../../../state-management/reducers';
import { CreateSingle } from '../../../state-management/util/actions';
import { SLICE } from '../../state-management/slice';
import { CreateCompany } from '../../../shared/api/models/createCompany';
import { Role } from '../../../shared/api/models/role';
import { SupportedCountry } from '../../../shared/api/models/supportedCountry';

@Component({
  selector: 'caple-create-company-dialog',
  templateUrl: './create-company-dialog.component.html'
})
export class CreateCompanyDialogComponent {
  public readonly countries = Object.values(SupportedCountry);
  public readonly allowedInitialRoles = [Role.PARTNER, Role.BORROWER, Role.REPORTINGSOURCE];

  /**
   * The formGroup for the form
   */
  public form: FormGroup;

  constructor(private formBuilder: FormBuilder, private store: Store<State>) {
    this.form = formBuilder.group({
      'name': ['', Validators.required],
      'country': ['', Validators.required],
      'roles': formBuilder.array(
        this.allowedInitialRoles.map(_ => formBuilder.control(false)),
        [this.mustSelectARoleValidator()]
      )
    });
  }

  public createCompany() {
    this.form.markAsTouched();
    // Form array's don't get marked as dirty and due to the form field not working on there the dirty flag is needed for showing errors
    this.form.controls['roles'].markAsDirty();
    if (this.form.valid) {
      const payload: CreateCompany = {
        name: this.form.controls['name'].value,
        country: this.form.controls['country'].value,
        roles: this.mapSelectedCheckboxesToRoles()
      };
      const action = new CreateSingle(SLICE.COMPANY, payload);
      this.store.dispatch(action);
    }
  }

  public closeModal() {
    this.store.dispatch(new CloseModal());
  }

  private mapSelectedCheckboxesToRoles() {
    const formRoles = this.form.controls['roles'].value;
    const selectedRoles = [];

    for (let i = 0; i < formRoles.length; i++) {
      if (formRoles[i] === true) {
        selectedRoles.push(this.allowedInitialRoles[i]);
      }
    }

    return selectedRoles;
  }

  private mustSelectARoleValidator(): ValidatorFn {
    return (array) => {
      if (!array.value.includes(true)) {
        return {'shouldSelectARole': true};
      }
      return null;
    };
  }
}
