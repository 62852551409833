import { actions, EntityAction } from '../../state-management/util/actions';
import { SLICE } from './slice';
import { ChangeLockPayload, DiscussionIdPayload, PostCommentPayload, StartDiscussionPayload } from '../../shared/api/services/discussion.service';
import { Id } from '../../shared/api/models/id';

export const discussionActions = {
  ...actions,
  START_DISCUSSION: 'START_DISCUSSION',
  START_DISCUSSION_SUCCESS: 'START_DISCUSSION_SUCCESS',
  POST_COMMENT: 'POST_COMMENT',
  POST_COMMENT_SUCCESS: 'POST_COMMENT_SUCCESS',
  MARK_AS_READ: 'MARK_CHANGELOG_AS_READ',
  MARK_AS_READ_SUCCESS: 'MARK_CHANGELOG_AS_READ_SUCCESS',
  CHANGE_DISCUSSION_LOCK: 'CHANGE_DISCUSSION_LOCK'
};

export class StartDiscussion extends EntityAction {
  protected actionName: string = discussionActions.START_DISCUSSION;

  constructor(payload: StartDiscussionPayload) {
    super(SLICE.DISCUSSION, payload);
  }
}

export class StartDiscussionSuccess extends EntityAction {
  protected actionName: string = discussionActions.START_DISCUSSION_SUCCESS;
  public postedAt = new Date();

  constructor(public payload: Id, public request: StartDiscussionPayload, public user: { name: string, email: string }) {
    super(SLICE.DISCUSSION, payload);
  }
}

export class PostComment extends EntityAction {
  protected actionName: string = discussionActions.POST_COMMENT;

  constructor(payload: PostCommentPayload) {
    super(SLICE.DISCUSSION, payload);
  }
}

export class PostCommentSuccess extends EntityAction {
  protected actionName: string = discussionActions.POST_COMMENT_SUCCESS;
  public postedAt = new Date();

  constructor(public payload: PostCommentPayload, public user: { name: string, email: string }) {
    super(SLICE.DISCUSSION, payload);
  }
}

export class ChangeDiscussionLock extends EntityAction {
  protected actionName: string = discussionActions.CHANGE_DISCUSSION_LOCK;

  constructor(payload: ChangeLockPayload) {
    super(SLICE.DISCUSSION, payload);
  }
}

export class MarkAsRead extends EntityAction {
  protected actionName: string = discussionActions.MARK_AS_READ;

  constructor(payload: DiscussionIdPayload) {
    super(SLICE.DISCUSSION, payload);
  }
}

export class MarkAsReadSuccess extends EntityAction {
  protected actionName: string = discussionActions.MARK_AS_READ_SUCCESS;

  constructor(public payload: DiscussionIdPayload) {
    super(SLICE.DISCUSSION, payload);
  }
}
