import { Component, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../state-management/reducers';
import { CloseModal } from '../../../state-management/layout/actions';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'caple-tooltip-dialog',
  templateUrl: './tooltip-dialog.component.html'
})
export class TooltipDialogComponent {

  public message: string;
  public title: string;

  constructor(private store: Store<State>, @Inject(MAT_DIALOG_DATA) private data: TooltipDialogOptions) {
    this.message = data.message;
    this.title = data.title || 'generic.expected-information';
  }

  public closeModal() {
    this.store.dispatch(new CloseModal());
  }

}

export interface TooltipDialogOptions {
  message: string;
  title?: string;
}
