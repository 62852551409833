import { Component, Inject, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { State } from '../../../state-management/reducer';
import { CloseModal } from '../../../../state-management/layout/actions';
import { WithId } from '../../../../state-management/util/actions';
import { Subscription } from 'rxjs';
import { selectMonitoringFile } from '../../../state-management/selector';
import { SelectCovenantsComponent } from '../../create-monitoring-file-dialog/covenants.component';
import { map } from 'rxjs/operators';
import { CapleFormBuilder } from '../../../../shared/util/caple-form-builder';
import { UpdateCovenants } from '../../../state-management/actions';
import { CovenantsForm } from './covenants-form';

@Component({
  selector: 'caple-edit-covenants-dialog',
  templateUrl: './edit-covenants-dialog.component.html'
})
export class EditCovenantsDialogComponent implements OnDestroy {
  public selectCovenantsComponent = SelectCovenantsComponent;

  public form: CovenantsForm;

  private readonly fileId: string;
  private subscription = new Subscription();

  constructor(private store: Store<State>, @Inject(MAT_DIALOG_DATA) private data: WithId) {
    this.fileId = data.id;

    this.store.pipe(
      select(selectMonitoringFile(data)),
      map(monitoringFile => monitoringFile.covenants)
    )
      .subscribe(covenants => {
        this.form = new CovenantsForm(new CapleFormBuilder());

        this.form.patchValue({'covenants': covenants});
      });
  }

  public closeModal() {
    this.store.dispatch(new CloseModal());
  }

  public changeCovenants() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.store.dispatch(
        new UpdateCovenants(
          {
            id: this.fileId,
            covenants: this.form.get('covenants').value
          }
        )
      );
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }
}
