import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AbstractApiService } from './abstract-api.service';
import { Id } from '../models/id';
import { ReadCompany } from '../models/readCompany';
import { CreateCompany } from '../models/createCompany';
import { CreateUser } from '../models/createUser';
import { ReadUser } from '../models/readUser';
import { WithId } from '../../../state-management/util/actions';
import { AddRole } from '../models/addRole';
import { RenameCompany } from '../models/renameCompany';

export interface RolePayload extends AddRole {
  companyId: string
}

export interface RenameCompanyPayload extends RenameCompany {
  companyId: string
}

export interface CompanyUserPayload extends WithId {
  companyId: string
}

export interface CreateUserPayload extends CreateUser {
  companyId: string
}

@Injectable()
export class CompanyService extends AbstractApiService {
  private readonly baseUrl = '/api/v1/companies';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getAll(): Observable<ReadCompany[]> {
    return this.get<ReadCompany[]>(this.baseUrl);
  }

  public getSingle(companyId: Id): Observable<ReadCompany> {
    return this.get<ReadCompany>(this.getSingleCompanyUrl(companyId.id));
  }

  public create(payload: CreateCompany): Observable<Id> {
    return this.post<Id>(this.baseUrl, payload);
  }

  public addRole(payload: RolePayload) {
    return this.post(this.getSingleCompanyUrl(payload.companyId) + '/roles', payload);
  }

  public renameCompany(payload: RenameCompanyPayload) {
    return this.post(this.getSingleCompanyUrl(payload.companyId) + '/rename', payload);
  }

  public getAllUsersForCompany(companyId: Id): Observable<ReadUser[]> {
    return this.get<ReadUser[]>(this.getSingleCompanyUrl(companyId.id) + '/users');
  }

  public createUserForCompany(payload: CreateUserPayload): Observable<Id> {
    return this.post<Id>(this.getSingleCompanyUrl(payload.companyId) + '/users', payload);
  }

  public blockUser(payload: CompanyUserPayload) {
    return this.post(this.getSingleCompanyUrl(payload.companyId) + '/users/block', {
      id: payload.id
    });
  }

  public unblockUser(payload: CompanyUserPayload) {
    return this.post(this.getSingleCompanyUrl(payload.companyId) + '/users/unblock', {
      id: payload.id
    });
  }

  public resendWelcomeEmail(payload: CompanyUserPayload) {
    return this.post(this.getSingleCompanyUrl(payload.companyId) + '/users/resend-welcome-email', {id: payload.id});
  }

  private getSingleCompanyUrl(id) {
    return this.baseUrl + '/' + id;
  }

}
