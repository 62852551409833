<h2 mat-dialog-title>{{ 'administration.companies.add-company' | translate}}</h2>
<mat-dialog-content>
  <form [formGroup]="form">

    <div class="pure-g">
      <div class="pure-u-1-2">
        <mat-form-field floatLabel="always">
          <input matInput placeholder="{{ 'administration.company.name' | translate}}"
                 [formControl]="form.controls['name']"/>
          <mat-error *ngIf="form.hasError('required', ['name'])">{{ 'generic.validation.required' | translate}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="pure-u-1-2">
        <mat-form-field class="full">
          <mat-select placeholder="{{ 'administration.company.country' | translate}}"
                      [formControl]="form.controls['country']">
            <mat-option *ngFor="let country of countries" [value]="country">{{ country }}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.hasError('required', ['country'])">{{ 'generic.validation.required' | translate}}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="pure-g">
      <div class="pure-u-1-2">
      </div>
      <div class="pure-u-1-2 roles">
        <div class="heading">{{'administration.company.roles' | translate}}</div>
        <div formArrayName="roles" *ngFor="let role of allowedInitialRoles; let i = index">
          <mat-checkbox [formControlName]="i">
            {{'administration.roles.' + role | translate}}
          </mat-checkbox>
        </div>
        <mat-error *ngIf="form.hasError('shouldSelectARole', ['roles']) && form.controls['roles'].dirty">
          {{ 'administration.roles.required' | translate}}
        </mat-error>
      </div>
    </div>

    <mat-dialog-actions>
      <button mat-button (click)="closeModal()">{{ 'generic.cancel' | translate }}</button>
      <caple-loading-wrapper key="urn:company:create" [diameter]="36">
        <button mat-button (click)="createCompany()">
          {{'administration.companies.add-company' | translate }}
        </button>
      </caple-loading-wrapper>
    </mat-dialog-actions>
  </form>
</mat-dialog-content>
