import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { AbstractApiService } from './abstract-api.service';
var AnnouncementService = /** @class */ (function (_super) {
    tslib_1.__extends(AnnouncementService, _super);
    function AnnouncementService(httpClient) {
        var _this = _super.call(this, httpClient) || this;
        _this.baseUrl = '/api/v1/announcement/';
        return _this;
    }
    AnnouncementService.prototype.getAnnouncement = function () {
        return this.get(this.baseUrl);
    };
    AnnouncementService.prototype.updateAnnouncement = function (payload) {
        return this.post(this.baseUrl, payload);
    };
    AnnouncementService.prototype.clearAnnouncement = function () {
        return this.delete(this.baseUrl);
    };
    return AnnouncementService;
}(AbstractApiService));
export { AnnouncementService };
