import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AbstractApiService } from './abstract-api.service';
import { Preferences } from '../models/preferences';
import { NotificationPreferences } from '../models/notificationPreferences';

@Injectable()
export class PreferencesService extends AbstractApiService {
  private readonly baseUrl = '/api/v1/preferences';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getPreferences(): Observable<Preferences> {
    return this.get<Preferences>(this.baseUrl);
  }

  public getNotificationPreferences(): Observable<NotificationPreferences> {
    return this.get<NotificationPreferences>(this.baseUrl + '/notifications');
  }

  public updateNotificationPreferences(notificationPreferences: NotificationPreferences): Observable<void> {
    return this.post<void>(this.baseUrl + '/notifications', notificationPreferences);
  }

}
