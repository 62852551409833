import { FormGroup } from '@angular/forms';
import * as Moment from 'moment';
import { ReportingFrequency } from '../../shared/api/models/reportingFrequency';

export function reportingPeriodAndAnnualDatesMatch(reportingPeriodKey: string, annualKey: string, reportingFrequency: string) {
  return (group: FormGroup): { [key: string]: any } => {
    const reportingPeriodField = group.controls[reportingPeriodKey];
    const annualBudgetField = group.controls[annualKey];
    const reportingFrequencyField = group.controls[reportingFrequency];

    if (reportingPeriodField.value && annualBudgetField.value && reportingFrequencyField.value) {

      const reportingPeriodMonth = Moment(reportingPeriodField.value).month() + 1;
      const annualMonth = Moment(annualBudgetField.value).month() + 1;
      switch (reportingFrequencyField.value) {
        case ReportingFrequency.QUARTERLY:
          if ((annualMonth - reportingPeriodMonth) % 3 !== 0) {
            return {
              mismatchedReportingPeriodAndAnnual: true
            };
          }
          break;
        case ReportingFrequency.HALFYEARLY:
          if ((annualMonth - reportingPeriodMonth) % 6 !== 0) {
            return {
              mismatchedReportingPeriodAndAnnual: true
            };
          }
          break;
        default:
          throw new Error('Unknown Reporting frequency');
      }
    }
    return null;
  };
}
