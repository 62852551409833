/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./login-form.component";
import * as i2 from "./auth0-factory.service";
import * as i3 from "@ngrx/store";
import * as i4 from "@angular/router";
var styles_LoginFormComponent = [];
var RenderType_LoginFormComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoginFormComponent, data: {} });
export { RenderType_LoginFormComponent as RenderType_LoginFormComponent };
export function View_LoginFormComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_LoginFormComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "caple-login-form", [], null, null, null, View_LoginFormComponent_0, RenderType_LoginFormComponent)), i0.ɵdid(1, 114688, null, 0, i1.LoginFormComponent, [i2.Auth0Factory, i3.Store, i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginFormComponentNgFactory = i0.ɵccf("caple-login-form", i1.LoginFormComponent, View_LoginFormComponent_Host_0, {}, { success: "success" }, []);
export { LoginFormComponentNgFactory as LoginFormComponentNgFactory };
