import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {selectCountry} from '../../../../state-management/preferences/selector';
import {Observable} from 'rxjs';
import {BasicEligibilityCriteriaComponent} from '../../../../eligibility/basic-eligibility-criteria.component';
import {State} from '../../../../state-management/reducers';
import {SupportedCountry} from '../../../../shared/api/models/supportedCountry';
import {ProductType} from '../../../../shared/api/models/productType';

@Component({
  selector: 'caple-start-funding-process-basic-eligibility',
  templateUrl: './basic-eligibility.component.html'
})
export class BasicEligiblityComponent {

  @Output()
  public checked = new EventEmitter<void>();
  @Output()
  public onCreated = new EventEmitter<string>();

  public country$: Observable<SupportedCountry>;
  @Input()
  public sectorCode: string;
  @Input()
  public productType: ProductType;
  @Input()
  public directors: number;
  @ViewChild(BasicEligibilityCriteriaComponent, {static: false})
  private basicComponent: BasicEligibilityCriteriaComponent;
  private eligiblityId: string;

  constructor(private store: Store<State>) {
    this.country$ = store.pipe(select(selectCountry));
  }

  public eligibilityCreated(event) {
    this.eligiblityId = event.id;
    this.onCreated.emit(event.id);
  }

  public startCheck() {
    this.basicComponent.submitBasicEligibilityData();
    this.checked.emit();
  }
}
