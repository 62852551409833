/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "../loading-wrapper/loading-wrapper.component.ngfactory";
import * as i9 from "../loading-wrapper/loading-wrapper.component";
import * as i10 from "@ngrx/store";
import * as i11 from "./confirm-dialog.component";
var styles_ConfirmDialogComponent = [];
var RenderType_ConfirmDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ConfirmDialogComponent, data: {} });
export { RenderType_ConfirmDialogComponent as RenderType_ConfirmDialogComponent };
function View_ConfirmDialogComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "notice warning"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["warning"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform(_co.warning)); _ck(_v, 4, 0, currVal_0); }); }
export function View_ConfirmDialogComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i0.ElementRef, i2.MatDialog], null, null), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 17, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i0.ɵdid(5, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ConfirmDialogComponent_1)), i0.ɵdid(7, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 1, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(10, 0, null, null, 11, "mat-dialog-actions", [["align", "end"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i0.ɵdid(11, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(12, 0, null, null, 3, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i0.ɵdid(13, 180224, null, 0, i5.MatButton, [i0.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵted(14, 0, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(16, 0, null, null, 5, "caple-loading-wrapper", [], null, null, null, i8.View_LoadingWrapperComponent_0, i8.RenderType_LoadingWrapperComponent)), i0.ɵdid(17, 49152, null, 0, i9.LoadingWrapperComponent, [i10.Store], { diameter: [0, "diameter"], key: [1, "key"] }, null), (_l()(), i0.ɵeld(18, 0, null, 0, 3, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i0.ɵdid(19, 180224, null, 0, i5.MatButton, [i0.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵted(20, 0, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_2 = _co.warning; _ck(_v, 7, 0, currVal_2); var currVal_7 = 36; var currVal_8 = _co.confirmLoadingKey; _ck(_v, 17, 0, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.title)); _ck(_v, 2, 0, currVal_1); var currVal_3 = i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform(_co.message)); _ck(_v, 8, 0, currVal_3); var currVal_4 = (i0.ɵnov(_v, 13).disabled || null); var currVal_5 = (i0.ɵnov(_v, 13)._animationMode === "NoopAnimations"); _ck(_v, 12, 0, currVal_4, currVal_5); var currVal_6 = i0.ɵunv(_v, 14, 0, i0.ɵnov(_v, 15).transform(_co.cancelLabel)); _ck(_v, 14, 0, currVal_6); var currVal_9 = (i0.ɵnov(_v, 19).disabled || null); var currVal_10 = (i0.ɵnov(_v, 19)._animationMode === "NoopAnimations"); _ck(_v, 18, 0, currVal_9, currVal_10); var currVal_11 = i0.ɵunv(_v, 20, 0, i0.ɵnov(_v, 21).transform(_co.confirmLabel)); _ck(_v, 20, 0, currVal_11); }); }
export function View_ConfirmDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "caple-confirm-dialog", [], null, null, null, View_ConfirmDialogComponent_0, RenderType_ConfirmDialogComponent)), i0.ɵdid(1, 49152, null, 0, i11.ConfirmDialogComponent, [i10.Store, i2.MAT_DIALOG_DATA], null, null)], null, null); }
var ConfirmDialogComponentNgFactory = i0.ɵccf("caple-confirm-dialog", i11.ConfirmDialogComponent, View_ConfirmDialogComponent_Host_0, {}, {}, []);
export { ConfirmDialogComponentNgFactory as ConfirmDialogComponentNgFactory };
