import { EMPTY, of, zip } from 'rxjs';

import { catchError, map, mergeMap, switchMap, take, tap, } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  LayoutActions,
  MarkChangelogAsRead,
  MarkChangelogAsReadSuccess,
  ModalClosed,
  ModalOpened,
  OpenModal,
  ProgressIndicatorClosed,
  ProgressIndicatorOpened,
  ScrollTo,
  ShowProgressIndicator,
  ShowToast
} from './actions';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { State } from '../reducers';
import { Store } from '@ngrx/store';
import { ApiError } from '../../shared/api/api-error';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { ProgressIndicatorDialogComponent } from '../../shared/layout/progress-indicator-dialog/progress-indicator-dialog.component';
import { SLICE } from './slice';
import { Effects, ExecutionResult } from '../util/effect-functions';
import { AnnouncementService } from '../../shared/api/services/announcement.service';
import { typeFor } from '../util/util';
import { Failure } from '../util/actions';
import { DoNothing } from '../generic/actions';
import { ChangelogService } from '../../shared/api/services/changelog.service';
import { PageScrollService } from 'ngx-page-scroll-core';

@Injectable()
export class LayoutEffects {

  private modalReference: MatDialogRef<any, any>;

  @Effect()
  openModal$ = this.actions$
    .pipe(
      ofType(LayoutActions.OPEN_MODAL),
      map((action: OpenModal) => {
        this.modalReference = this.dialog.open(action.payload, Object.assign({}, action.config, {
          data: action.data
        }));
        return new ModalOpened();
      }));

  @Effect()
  closeModal$ = this.actions$
    .pipe(
      ofType(LayoutActions.CLOSE_MODAL),
      switchMap((action) => {
        if (this.modalReference) {
          this.modalReference.close();
          this.modalReference = null;
          return of(new ModalClosed());
        } else {
          return EMPTY;
        }
      }));

  @Effect()
  disableClose$ = this.actions$
    .pipe(
      ofType(LayoutActions.MODAL_DISABLE_CLOSE),
      switchMap((action) => {
        if (this.modalReference) {
          this.modalReference.disableClose = true;
        }
        return of();
      }));

  @Effect()
  enableClose$ = this.actions$
    .pipe(
      ofType(LayoutActions.MODAL_ENABLE_CLOSE),
      switchMap((action) => {
        if (this.modalReference) {
          this.modalReference.disableClose = false;
        }
        return of();
      }));

  private progressReference: MatDialogRef<any, any>;

  @Effect()
  openProgressIndicator$ = this.actions$
    .pipe(
      ofType(LayoutActions.SHOW_PROGRESS_INDICATOR),
      switchMap((action: ShowProgressIndicator) => {
        this.progressReference = this.dialog.open(ProgressIndicatorDialogComponent, {
          disableClose: true,
          closeOnNavigation: false,
          panelClass: 'transparent-panel',
          data: {translationKey: action.translationKey, translationData: action.translationData},
          backdropClass: 'progress-backdrop'
        });
        return of(new ProgressIndicatorOpened());
      })
    );

  @Effect()
  hideProgressIndicator$ = this.actions$
    .pipe(
      ofType(LayoutActions.HIDE_PROGRESS_INDICATOR),
      switchMap((action) => {
        if (this.progressReference) {
          this.progressReference.close();
          return of(new ProgressIndicatorClosed());
        } else {
          return EMPTY;
        }
      })
    );

  @Effect({dispatch: false})
  scrollTo$ = this.actions$
    .pipe(
      ofType(LayoutActions.SCROLL_TO),
      tap((action: ScrollTo) => {
        const service = this.pageScrollService;
        const doc = this.document;

        // This is wrapped inside a timeout because the scrolling should happen
        // after all elements on the page are loaded / validation statuses verified.
        // Wrapping something in a timeout forces it to happen after the current
        // 'digest' cycle so after everything is loaded / verified.
        setTimeout(function () {
          service.scroll({
            document: doc,
            scrollTarget: action.payload.target,
            duration: 100,
            scrollOffset: action.payload.offset || 0,
            scrollViews: action.payload.document ? [action.payload.document] : []
          });
        }, 1);
      }));
  /**
   * Arbitrary number that will keep the Snackbar open for such a long time that it
   * looks like its infinite. Number.MAX_VALUE isn't used here because somehow when
   * using that value the toast closes immediately.
   *
   * Current value is approximately 30 years.
   */
  private KEEP_OPEN_FOREVER = 1000000000;
  @Effect({dispatch: false})
  showToast$ = this.actions$
    .pipe(
      ofType(LayoutActions.SHOW_TOAST),
      tap((action: ShowToast) => {
        let messageToTranslate: string;
        let interpolateParams: Object;

        if (action.message instanceof ApiError) {
          messageToTranslate = action.message.code;
          interpolateParams = {
            ...action.message.values,
            ...action.interpolateParams
          };
        } else {
          messageToTranslate = action.message;
          interpolateParams = action.interpolateParams;
        }

        zip(
          this.translateService.get(messageToTranslate, interpolateParams),
          this.translateService.get('generic.close')
        ).pipe(take(1))
          .subscribe(([translatedMessage, translatedCloseButton]) => {
            // The long duration is there to fix a bug where the snackbar will close
            // if you call the `open` method while a snackbar is already open. Setting the `duration`
            // somehow fixes this.
            this.snackBar.open(translatedMessage, translatedCloseButton, {duration: this.KEEP_OPEN_FOREVER});
          });
      }));


  @Effect()
  loadAnnouncement$ = this.effects
    .loadSingleWithoutID(SLICE.ANNOUNCEMENT, 'urn:announcement:load', {
      functionToExecute: this.announcementService.getAnnouncement,
      scope: this.announcementService
    });

  /*************
   * Changelog
   *************/

  @Effect()
  loadChangelog$ = this.effects
    .loadSingleWithoutID(SLICE.CHANGELOG, 'urn:changelog:load', {
      functionToExecute: this.changelogService.getAll,
      scope: this.changelogService
    });

  @Effect()
  markAsRead$ = this.actions$
    .pipe(
      ofType(typeFor(SLICE.CHANGELOG, LayoutActions.MARK_CHANGELOG_AS_READ)),
      mergeMap((action: MarkChangelogAsRead) => {
          return this.effects.executeServiceCallWithLoadingIndicator(SLICE.CHANGELOG, action,
            'ur:changelog:mark-as-read', {
              functionToExecute: this.changelogService.markAsRead,
              scope: this.changelogService
            }).pipe(
            map((executionResult: ExecutionResult | Failure) => {
              if (executionResult instanceof Failure) {
                return new DoNothing(); // If this action fails, we don't want to do anything
              }
              return new MarkChangelogAsReadSuccess();
            }),
            catchError(_ => of(new DoNothing())));
        }
      ));

  constructor(private actions$: Actions,
              private effects: Effects,
              private dialog: MatDialog,
              private store: Store<State>,
              private snackBar: MatSnackBar,
              private translateService: TranslateService,
              private pageScrollService: PageScrollService,
              private announcementService: AnnouncementService,
              private changelogService: ChangelogService,
              @Inject(DOCUMENT) private document: any) {
  }
}
