<h2 mat-dialog-title>{{ 'financial-ratings.new-financial-rating' | translate}}</h2>
<mat-dialog-content>

  <div *ngIf="infoIsAvailable()" class="notice info">
    <i class="material-icons">info_outline</i>
    <p>{{getInfoTranslationKey() | translate}}</p>
  </div>

  <form [formGroup]="form">

    <div class="pure-g">
      <div class="pure-u-1-3">
        <mat-form-field class="full" floatLabel="always">
          <input [formControl]="form.controls['startYear']" matInput
                 placeholder="{{ 'financial-ratings.start-year' | translate}} *"
                 type="number"/>
          <mat-error
            *ngIf="form.hasError('required', ['startYear'])">{{ 'generic.validation.required' | translate}}</mat-error>
          <mat-error *ngIf="form.hasError('min', ['startYear'])">
            <span [translateParams]="{value: minStartYear}" [translate]="'generic.validation.min'"></span>
          </mat-error>
          <mat-error *ngIf="form.hasError('max', ['startYear'])">
            <span [translateParams]="{value: maxStartYear}" [translate]="'generic.validation.max'"></span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="pure-u-1-3 middle">
        <mat-form-field class="full" floatLabel="always">
          <caple-currency-input
            [formControl]="form.controls['loanAmount']"
            placeholder="{{'financial-ratings.loan-amount' | translate}} *"></caple-currency-input>
          <mat-error
            *ngIf="form.hasError('required', ['loanAmount'])">{{ 'generic.validation.required' | translate}}</mat-error>
          <mat-error *ngIf="form.hasError('min', ['loanAmount']) || form.hasError('max', ['loanAmount'])">
            <span *ngIf="productType == 'MBI'" [translate]="'funding-process.funding-proposal-min-max-loan-mbi'"></span>
            <span *ngIf="productType != 'MBI'" [translate]="'funding-process.funding-proposal-min-max-loan'"></span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="pure-u-1-3">
        <mat-form-field floatLabel="always">
          <mat-label>{{ 'financial-ratings.title' | translate}} ({{ 'generic.optional' | translate}})</mat-label>
          <input [formControl]="form.controls['title']" [placeholder]="getPlaceholder()" matInput/>
        </mat-form-field>
      </div>
    </div>
  </form>

  <mat-dialog-actions align="end">
    <button (click)="closeModal()" mat-button>{{ 'generic.cancel' | translate }}</button>

    <caple-loading-wrapper [diameter]="36" key="urn:financial-rating:create">
      <button (click)="create()" mat-button>{{ 'generic.create' | translate }}</button>
    </caple-loading-wrapper>
  </mat-dialog-actions>
</mat-dialog-content>
