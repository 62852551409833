/**
 * Caple Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type Phase = 'FUNDING_PROPOSAL' | 'ASSESSMENT' | 'COMPLETED' | 'UNKNOWN';

export const Phase = {
    FUNDINGPROPOSAL: 'FUNDING_PROPOSAL' as Phase,
    ASSESSMENT: 'ASSESSMENT' as Phase,
    COMPLETED: 'COMPLETED' as Phase,
    UNKNOWN: 'UNKNOWN' as Phase
}
