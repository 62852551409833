import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpParams, HttpResponse, HttpUploadProgressEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, share } from 'rxjs/operators';
import { map, partition } from 'rxjs/internal/operators';
import { config } from '../../../../environments/config';

/**
 * Abstract api class that will set the correct headers on all requests
 */
export abstract class AbstractApiService {

  protected constructor(private httpClient: HttpClient) {
  }

  protected buildURLQueryParameters(obj) {
    return '?' +
      Object.entries(obj)
        .filter(pair => !!pair[1])
        .map(pair => pair.map(encodeURIComponent).join('='))
        .join('&');
  }

  protected get<T>(url: string, params?: HttpParams): Observable<T> {
    return this.httpClient.get<T>(AbstractApiService.getCorrectedUrl(url), {
      headers: this.getHeaders(),
      params: params
    });
  }

  protected post<T>(url: string, body: any | null): Observable<T> {
    return this.httpClient.post<T>(AbstractApiService.getCorrectedUrl(url), body, {
      headers: this.getHeaders()
    });
  }

  protected postWithParams<T>(url: string, body: any | null, params: HttpParams): Observable<T> {
    return this.httpClient.post<T>(AbstractApiService.getCorrectedUrl(url), body, {
      headers: this.getHeaders(),
      params: params
    });
  }

  protected delete<T>(url: string): Observable<T> {
    return this.httpClient.delete<T>(AbstractApiService.getCorrectedUrl(url), {
      headers: this.getHeaders()
    });
  }

  protected getFile(url: string): Observable<HttpResponse<Blob>> {
    return this.httpClient.get(AbstractApiService.getCorrectedUrl(url), {
      headers: {
        'Content-Type': 'application/json'
      },
      observe: 'response',
      responseType: 'blob'
    });
  }

  protected getFileWithProgress(url: string): Observable<HttpEvent<Blob>> {
    return this.httpClient.get(AbstractApiService.getCorrectedUrl(url), {
      headers: {
        'Content-Type': 'application/json'
      },
      observe: 'events',
      reportProgress: true,
      responseType: 'blob'
    })
      .pipe(share());
  }

  /**
   * Upload a file to the server. This method returns 2 observables. The first one can be used to observe the response from the server.
   * The second can be used to observe the upload progress.
   * @param {string} url
   * @param {FormData} body
   * @returns {[Observable<T> , Observable<HttpUploadProgressEvent>]}
   */
  protected postFile<T>(url: string, body: FormData): [Observable<T>, Observable<HttpUploadProgressEvent>] {
    const post = this.httpClient.post<T>(AbstractApiService.getCorrectedUrl(url), body, {
      headers: {
        'Accept': 'application/json'
      },
      reportProgress: true,
      observe: 'events'
    }).pipe(share());

    return this.splitUploadObservable(post);
  }

  protected postResonseTypeBlob(url: string, payload: any) {
    return this.httpClient.post(AbstractApiService.getCorrectedUrl(url), payload, {
      headers: this.getHeaders(),
      responseType: 'blob'
    }).pipe(share());
  }

  protected getHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
  }

  /**
   * Split a Observable of HttpEvents into two Observables. One containing the result and the other containing
   * HttpUploadProgressEvents
   * @param {Observable<HttpEvent<T>>} httpEvents
   * @returns {[Observable<T> , Observable<HttpUploadProgressEvent>]}
   */
  protected splitUploadObservable<T>(httpEvents: Observable<HttpEvent<T>>) {
    const filteredHttpEvents = httpEvents.pipe(
      filter((event) => event.type === HttpEventType.UploadProgress || event.type === HttpEventType.Response)
    );

    const splittedResponse = partition((event: HttpEvent<T>) => event.type === HttpEventType.UploadProgress)(filteredHttpEvents);
    const observableBody = splittedResponse[1].pipe(map((event: HttpResponse<T>) => event.body));

    return [observableBody, splittedResponse[0]] as [Observable<T>, Observable<HttpUploadProgressEvent>]
  }

  private static getCorrectedUrl(url: string): string {
    return config.apiUrl ? config.apiUrl + url: url;
  }
}
