import {CreateClient} from '../../shared/api/models/createClient';
import { actions, EntityAction, FileWithIdVersionAndProduct, IdType, WithId, WithIdAndVersion, WithIdVersionAndProduct } from '../../state-management/util/actions';
import {Id} from '../../shared/api/models/id';
import {SLICE} from './slice';
import {ImportPayload} from '../../shared/api/services/funding-process.service';
import {FundingProcessResourceType} from '../../shared/api/models/fundingProcessResourceType';
import {ConnectEligibilityPayload} from '../../shared/api/services/eligibility.service';
import {FileDetails} from '../../shared/api/models/fileDetails';
import {AssessmentDecision} from '../../shared/api/models/assessmentDecision';
import {ProductType} from '../../shared/api/models/productType';
import {Product} from '../../shared/api/models/product';
import {FilePayload, FilePayloadWithName} from '../../shared/api/services/assessment.service';
import {ProductSubType} from '../../shared/api/models/productSubType';
import {FundingProposalModeRow} from './reducer';
import {CategorisedFiles} from '../../shared/api/models/categorisedFiles';
import {UpdateReviewStatusRequest} from '../../shared/api/models/updateReviewStatusRequest';
import {UploadType} from '../../shared/api/models/uploadType';

export const originationActions = {
  ...actions,
  START_FUNDING_PROCESS_WITH_NEW_CLIENT: 'START_FUNDING_PROCESS_WITH_NEW_CLIENT',
  EXPORT_TO_EXCEL: 'EXPORT_TO_EXCEL',
  EXPORT_CREDIT_SCORING_TO_EXCEL: 'EXPORT_CREDIT_SCORING_TO_EXCEL',
  EXPORT_TO_PDF: 'EXPORT_TO_PDF',
  EXPORT_TO_TRANSLATED_PDF: 'EXPORT_TO_TRANSLATED_PDF',
  SUBMIT_SINGLE: 'SUBMIT_SINGLE',
  SUBMIT_SINGLE_SUCCESS: 'SUBMIT_SINGLE_SUCCESS',
  VALIDATION_CHANGED: 'VALIDATION_CHANGED',
  SNAPSHOT_FUNDING_PROPOSAL: 'SNAPSHOT_FUNDING_PROPOSAL',
  SNAPSHOT_FUNDING_PROPOSAL_SUCCESS: 'SNAPSHOT_FUNDING_PROPOSAL_SUCCESS',
  TRANSLATE_FUNDING_PROPOSAL: 'TRANSLATE_FUNDING_PROPOSAL',
  TRANSLATE_FUNDING_PROPOSAL_SUCCESS: 'TRANSLATE_FUNDING_PROPOSAL_SUCCESS',
  UPDATE_FUNDING_PROPOSAL_MODE: 'UPDATE_FUNDING_PROPOSAL_MODE',
  ASSESS_FUNDING_PROPOSAL: 'ASSESS_FUNDING_PROPOSAL',
  ASSESS_FUNDING_PROPOSAL_SUCCESS: 'ASSESS_FUNDING_PROPOSAL_SUCCESS',
  ATTACH_COMMUNICATION_FILE: 'ATTACH_COMMUNICATION_FILE',
  ATTACH_COMMUNICATION_FILE_SUCCESS: 'ATTACH_COMMUNICATION_FILE_SUCCESS',
  REMOVE_COMMUNICATION_FILE: 'REMOVE_COMMUNICATION_FILE',
  REMOVE_COMMUNICATION_FILE_SUCCESS: 'REMOVE_COMMUNICATION_FILE_SUCCESS',
  DOWNLOAD_COMMUNICATION_FILE: 'DOWNLOAD_COMMUNICATION_FILE_SUCCESS',
  PIN_FUNDING_PROCESS: 'PIN_FUNDING_PROCESS',
  PIN_FUNDING_PROCESS_SUCCESS: 'PIN_FUNDING_PROCESS_SUCCESS',
  UNPIN_FUNDING_PROCESS: 'UNPIN_FUNDING_PROCESS',
  UNPIN_FUNDING_PROCESS_SUCCESS: 'UNPIN_FUNDING_PROCESS_SUCCESS',
  ASSIGN_FUNDING_PROCESS: 'ASSIGN_FUNDING_PROCESS',
  ASSIGN_FUNDING_PROCESS_SUCCESS: 'ASSIGN_FUNDING_PROCESS_SUCCESS',
  UN_ASSIGN_FUNDING_PROCESS: 'UN_ASSIGN_FUNDING_PROCESS',
  UN_ASSIGN_FUNDING_PROCESS_SUCCESS: 'UN_ASSIGN_FUNDING_PROCESS_SUCCESS',
  DISABLE_RECENTLY_CHANGED: 'DISABLE_RECENTLY_CHANGED',
  UPDATE_FUNDING_PROCESS_FILTERED_COUNTRIES: 'UPDATE_FUNDING_PROCESS_FILTERED_COUNTRIES',
  UPDATE_FUNDING_PROCESS_FILTERED_STATUSES: 'UPDATE_FUNDING_PROCESS_FILTERED_STATUSES',
  UPDATE_FUNDING_PROCESS_SEARCH_QUERY: 'UPDATE_FUNDING_PROCESS_SEARCH_QUERY',
  UPGRADE_FUNDING_PROPOSAL_FINANCIAL_MODEL_VERSION: 'UPGRADE_FUNDING_PROPOSAL_FINANCIAL_MODEL_VERSION',
  UPGRADE_FUNDING_PROPOSAL_FINANCIAL_MODEL_VERSION_SUCCESS: 'UPGRADE_FUNDING_PROPOSAL_FINANCIAL_MODEL_VERSION_SUCCESS',
  OVERRIDE_FUNDING_PROPOSAL_FINANCIAL_DATA: 'OVERRIDE_FUNDING_PROPOSAL_FINANCIAL_DATA',
  OVERRIDE_FUNDING_PROPOSAL_FINANCIAL_DATA_SUCCESS: 'OVERRIDE_FUNDING_PROPOSAL_FINANCIAL_DATA_SUCCESS',
  DOWNLOAD_RESOURCE: 'DOWNLOAD_RESOURCE',
  DOWNLOAD_UPLOADS_ZIP: 'DOWNLOAD_UPLOADS_ZIP',
  DOWNLOAD_UPLOADS_FILE: 'DOWNLOAD_UPLOADS_FILE',
  ELIGIBILITY_CHECK_CONNECT: 'ELIGIBILITY_CHECK_CONNECT',
  ELIGIBILITY_CHECK_CONNECT_SUCCESS: 'ELIGIBILITY_CHECK_CONNECT_SUCCESS',
  SUBMIT_TO_BNP: 'SUBMIT_TO_BNP',
  SUBMIT_TO_BNP_SUCCESS: 'SUBMIT_TO_BNP_SUCCESS',
  DOWNLOAD_GROUP_STRUCTURE_CHART: 'DOWNLOAD_GROUP_STRUCTURE_CHART',
  DOWNLOAD_GROUP_STRUCTURE_CHART_SUCCESS: 'DOWNLOAD_GROUP_STRUCTURE_CHART_SUCCESS',
  UPDATE_PRODUCT_TYPE: 'UPDATE_PRODUCT_TYPE',
  UPDATE_PRODUCT_TYPE_SUCCESS: 'UPDATE_PRODUCT_TYPE_SUCCESS',
  CHAMBER_OF_COMMERCE_NAME_LOAD: 'CHAMBER_OF_COMMERCE_NAME_LOAD',
  UPDATE_UPLOADS_CATEGORIES: 'UPDATE_UPLOADS_CATEGORIES',
  UPDATE_UPLOADS_CATEGORIES_SUCCESS: 'UPDATE_UPLOADS_CATEGORIES_SUCCESS',
  REVIEW_UPLOAD: 'REVIEW_UPLOAD',
  REVIEW_UPLOAD_SUCCESS: 'REVIEW_UPLOAD_SUCCESS',
  MOVE_UPLOAD: 'MOVE_UPLOAD',
  MOVE_UPLOAD_SUCCESS: 'MOVE_UPLOAD_SUCCESS',
  MOVE_UPLOAD_FAILURE: 'MOVE_UPLOAD_FAILURE',
  UPDATE_OWNER_USERS: 'UPDATE_OWNER_USERS',
  UPDATE_OWNER_USERS_SUCCESS: 'UPDATE_OWNER_USERS_SUCCESS',
};

export interface ExportToExcelPayload extends WithIdVersionAndProduct {
  language?: string;
}

export class ExportToExcel extends EntityAction {
  protected actionName: string = originationActions.EXPORT_TO_EXCEL;

  constructor(public slice: string, payload: ExportToExcelPayload, public title?: string) {
    super(slice, payload);
  }
}

export class ExportCreditScoringToExcel extends EntityAction {
  protected actionName: string = originationActions.EXPORT_CREDIT_SCORING_TO_EXCEL;

  constructor(public slice: string, payload: ExportToExcelPayload, public title?: string) {
    super(slice, payload);
  }
}

export interface DownloadResourcePayload extends WithId {
  version?: number;
  product?: string;
  type: FundingProcessResourceType;
}

export interface AssignFundingProcessPayload extends WithId {
  userId: string;
  name: string;
}

export interface UpdateProductTypePayload extends WithId {
  productType: ProductType;
  productSubType?: ProductSubType;
  loanSize?: number;
}

export interface UpdateUploadsCategoriesPayload extends WithIdVersionAndProduct {
  files: CategorisedFiles[];
}

export interface ReviewUploadPayload extends UpdateReviewStatusRequest, WithIdVersionAndProduct {
}

export interface MoveUploadPayload extends WithIdVersionAndProduct {
  fileId: string;
  from: UploadType;
  to: UploadType;
}

export interface UpdateOwnerUsersPayload extends WithId {
  users: string[];
}

/***********
 * Non-generic actions
 **********/
export class StartFundingProcessWithNewClient extends EntityAction {
  protected actionName: string = originationActions.START_FUNDING_PROCESS_WITH_NEW_CLIENT;

  constructor(payload: { client: CreateClient, product: Product }) {
    super(SLICE.FUNDING_PROCESS, payload);
  }
}

export class ExportFundingProposalToPdf extends EntityAction {
  protected actionName: string = originationActions.EXPORT_TO_PDF;

  constructor(payload: WithIdVersionAndProduct) {
    super(SLICE.FUNDING_PROPOSAL, payload);
  }
}

export class ExportFundingProposalToTranslatedPdf extends EntityAction {
  protected actionName: string = originationActions.EXPORT_TO_TRANSLATED_PDF;

  constructor(payload: WithIdVersionAndProduct) {
    super(SLICE.FUNDING_PROPOSAL, payload);
  }
}

export class SubmitSingle<T> extends EntityAction {
  protected actionName: string = originationActions.SUBMIT_SINGLE;

  constructor(public payload: T) {
    super(SLICE.FUNDING_PROPOSAL, payload);
  }
}

export class SubmitSingleSuccess<T extends any> extends EntityAction {
  protected actionName: string = originationActions.SUBMIT_SINGLE_SUCCESS;

  constructor(public payload: WithId, public request: T) {
    super(SLICE.FUNDING_PROPOSAL, payload);
  }
}

export class ValidationChanged extends EntityAction {
  protected actionName: string = originationActions.VALIDATION_CHANGED;

  constructor(payload: { id: string, status: { id: string, valid: boolean }[] }) {
    super(SLICE.FUNDING_PROPOSAL, payload);
  }
}

export class SnapshotFundingProposal extends EntityAction {
  protected actionName: string = originationActions.SNAPSHOT_FUNDING_PROPOSAL;

  constructor(payload: WithId) {
    super(SLICE.FUNDING_PROPOSAL_SNAPSHOT, payload);
  }
}

export class SnapshotFundingProposalSuccess extends EntityAction {
  protected actionName: string = originationActions.SNAPSHOT_FUNDING_PROPOSAL_SUCCESS;

  constructor(payload: WithId) {
    super(SLICE.FUNDING_PROPOSAL_SNAPSHOT, payload);
  }
}

export class TranslateFundingProposal extends EntityAction {
  protected actionName: string = originationActions.TRANSLATE_FUNDING_PROPOSAL;

  constructor(payload: WithIdVersionAndProduct) {
    super(SLICE.FUNDING_PROPOSAL_TRANSLATION, payload);
  }
}

export class TranslateFundingProposalSuccess extends EntityAction {
  protected actionName: string = originationActions.TRANSLATE_FUNDING_PROPOSAL_SUCCESS;

  constructor(payload: WithIdVersionAndProduct) {
    super(SLICE.FUNDING_PROPOSAL_TRANSLATION, payload);
  }
}

export class UpdateFundingProposalMode extends EntityAction {
  protected actionName: string = originationActions.UPDATE_FUNDING_PROPOSAL_MODE;

  constructor(payload: FundingProposalModeRow) {
    super(SLICE.FUNDING_PROPOSAL, payload);
  }
}

export class AssessFundingProposal extends EntityAction {
  protected actionName: string = originationActions.ASSESS_FUNDING_PROPOSAL;

  constructor(payload: { id: string, decision: AssessmentDecision }) {
    super(SLICE.ASSESSMENT, payload);
  }
}

export class AssessFundingProposalSuccess<T extends any> extends EntityAction {
  protected actionName: string = originationActions.ASSESS_FUNDING_PROPOSAL_SUCCESS;

  constructor(public request: T) {
    super(SLICE.ASSESSMENT, null);
  }
}

export class PinFundingProcess extends EntityAction {
  protected actionName: string = originationActions.PIN_FUNDING_PROCESS;

  constructor(payload: Id) {
    super(SLICE.FUNDING_PROCESS, payload);
  }
}

export class PinFundingProcessSuccess extends EntityAction {
  protected actionName: string = originationActions.PIN_FUNDING_PROCESS_SUCCESS;

  constructor(payload: Id) {
    super(SLICE.FUNDING_PROCESS, payload);
  }
}

export class UnpinFundingProcess extends EntityAction {
  protected actionName: string = originationActions.UNPIN_FUNDING_PROCESS;

  constructor(payload: Id) {
    super(SLICE.FUNDING_PROCESS, payload);
  }
}

export class UnpinFundingProcessSuccess extends EntityAction {
  protected actionName: string = originationActions.UNPIN_FUNDING_PROCESS_SUCCESS;

  constructor(payload: Id) {
    super(SLICE.FUNDING_PROCESS, payload);
  }
}

export class AssignFundingProcess extends EntityAction {
  protected actionName: string = originationActions.ASSIGN_FUNDING_PROCESS;

  constructor(payload: AssignFundingProcessPayload) {
    super(SLICE.FUNDING_PROCESS, payload);
  }
}

export class AssignFundingProcessSuccess extends EntityAction {
  protected actionName: string = originationActions.ASSIGN_FUNDING_PROCESS_SUCCESS;

  constructor(payload: AssignFundingProcessPayload) {
    super(SLICE.FUNDING_PROCESS, payload);
  }
}

export class UnAssignFundingProcess extends EntityAction {
  protected actionName: string = originationActions.UN_ASSIGN_FUNDING_PROCESS;

  constructor(payload: Id) {
    super(SLICE.FUNDING_PROCESS, payload);
  }
}

export class UnAssignFundingProcessSuccess extends EntityAction {
  protected actionName: string = originationActions.UN_ASSIGN_FUNDING_PROCESS_SUCCESS;

  constructor(payload: Id) {
    super(SLICE.FUNDING_PROCESS, payload);
  }
}

export class DisableRecentlyChanged extends EntityAction {
  protected actionName: string = originationActions.DISABLE_RECENTLY_CHANGED;

  constructor(payload: Id) {
    super(SLICE.FUNDING_PROCESS, payload);
  }
}

export class UpdateFundingProcessFilteredCountries extends EntityAction {
  protected actionName: string = originationActions.UPDATE_FUNDING_PROCESS_FILTERED_COUNTRIES;

  constructor(payload: { country: string, isChecked: boolean }) {
    super(SLICE.FUNDING_PROCESS_SUMMARY, payload);
  }
}

export class UpdateFundingProcessFilteredStatuses extends EntityAction {
  protected actionName: string = originationActions.UPDATE_FUNDING_PROCESS_FILTERED_STATUSES;

  constructor(payload: { status: string, isChecked: boolean }) {
    super(SLICE.FUNDING_PROCESS_SUMMARY, payload);
  }
}

export class UpdateFundingProcessSearchQuery extends EntityAction {
  protected actionName: string = originationActions.UPDATE_FUNDING_PROCESS_SEARCH_QUERY;

  constructor(payload: { searchQuery: string }) {
    super(SLICE.FUNDING_PROCESS_SUMMARY, payload);
  }
}

export class UpgradeFundingProposalFinancialModelVersion extends EntityAction {
  protected actionName: string = originationActions.UPGRADE_FUNDING_PROPOSAL_FINANCIAL_MODEL_VERSION;

  constructor(payload: WithIdVersionAndProduct) {
    super(SLICE.FUNDING_PROPOSAL, payload);
  }
}

export class UpgradeFundingProposalFinancialModelVersionSuccess<T extends any> extends EntityAction {
  protected actionName: string = originationActions.UPGRADE_FUNDING_PROPOSAL_FINANCIAL_MODEL_VERSION_SUCCESS;

  constructor(public request: T) {
    super(SLICE.FUNDING_PROPOSAL, null);
  }
}

export class OverrideFundingProposalFinancialData extends EntityAction {
  protected actionName: string = originationActions.OVERRIDE_FUNDING_PROPOSAL_FINANCIAL_DATA;

  constructor(payload: ImportPayload) {
    super(SLICE.FUNDING_PROPOSAL, payload);
  }
}

export class OverrideFundingProposalFinancialDataSuccess<T extends any> extends EntityAction {
  protected actionName: string = originationActions.OVERRIDE_FUNDING_PROPOSAL_FINANCIAL_DATA_SUCCESS;

  constructor(public request: T) {
    super(SLICE.FUNDING_PROPOSAL, null);
  }
}

export class DownloadResource extends EntityAction {
  protected actionName: string = originationActions.DOWNLOAD_RESOURCE;

  constructor(payload: DownloadResourcePayload) {
    super(SLICE.FUNDING_PROPOSAL, payload);
  }
}

export class DownloadUploadsZip extends EntityAction {
  protected actionName: string = originationActions.DOWNLOAD_UPLOADS_ZIP;

  constructor(payload: WithIdVersionAndProduct) {
    super(SLICE.FUNDING_PROPOSAL, payload);
  }
}

export class DownloadUploadsFile extends EntityAction {
  protected actionName: string = originationActions.DOWNLOAD_UPLOADS_FILE;

  constructor(payload: FileWithIdVersionAndProduct) {
    super(SLICE.FUNDING_PROPOSAL, payload);
  }
}

export class EligibilityCheckConnect extends EntityAction {
  protected actionName: string = originationActions.ELIGIBILITY_CHECK_CONNECT;

  constructor(payload: ConnectEligibilityPayload) {
    super(SLICE.ELIGIBILITY, payload);
  }
}

export class EligibilityCheckConnectSuccess extends EntityAction {
  protected actionName: string = originationActions.ELIGIBILITY_CHECK_CONNECT_SUCCESS;

  constructor(payload: ConnectEligibilityPayload) {
    super(SLICE.ELIGIBILITY, payload);
  }
}

export class SubmitToBnp extends EntityAction {
  protected actionName: string = originationActions.SUBMIT_TO_BNP;

  constructor(payload: WithId) {
    super(SLICE.FUNDING_PROCESS, payload);
  }
}

export class AttachCommunicationFile extends EntityAction {
  protected actionName: string = originationActions.ATTACH_COMMUNICATION_FILE;

  constructor(payload: FilePayloadWithName) {
    super(SLICE.COMMUNICATION_FILES, payload);
  }
}

export class AttachCommunicationFileSuccess extends EntityAction {
  protected actionName: string = originationActions.ATTACH_COMMUNICATION_FILE_SUCCESS;

  constructor(payload: FilePayloadWithName) {
    super(SLICE.COMMUNICATION_FILES, payload);
  }
}

export class RemoveCommunicationFile extends EntityAction {
  protected actionName: string = originationActions.REMOVE_COMMUNICATION_FILE;

  constructor(payload: FilePayload) {
    super(SLICE.COMMUNICATION_FILES, payload);
  }
}

export class RemoveCommunicationFileSuccess extends EntityAction {
  protected actionName: string = originationActions.REMOVE_COMMUNICATION_FILE_SUCCESS;

  constructor(payload: FilePayload) {
    super(SLICE.COMMUNICATION_FILES, payload);
  }
}

export class DownloadCommunicationFile extends EntityAction {
  protected actionName: string = originationActions.DOWNLOAD_COMMUNICATION_FILE;

  constructor(payload: FilePayload) {
    super(SLICE.COMMUNICATION_FILES, payload);
  }
}

export class SubmitToBnpSuccess extends EntityAction {
  protected actionName: string = originationActions.SUBMIT_TO_BNP_SUCCESS;

  constructor(payload: WithId) {
    super(SLICE.FUNDING_PROCESS, payload);
  }
}

export class UpdateProductType extends EntityAction {
  protected actionName: string = originationActions.UPDATE_PRODUCT_TYPE;

  constructor(payload: UpdateProductTypePayload) {
    super(SLICE.FUNDING_PROCESS, payload);
  }
}

export class UpdateProductTypeSuccess extends EntityAction {
  protected actionName: string = originationActions.UPDATE_PRODUCT_TYPE_SUCCESS;

  constructor(payload: UpdateProductTypePayload) {
    super(SLICE.FUNDING_PROCESS, payload);
  }
}

export class UpdateUploadCategories extends EntityAction {
  protected actionName: string = originationActions.UPDATE_UPLOADS_CATEGORIES;

  constructor(payload: UpdateUploadsCategoriesPayload) {
    super(SLICE.FUNDING_PROPOSAL, payload);
  }
}

export class UpdateUploadCategoriesSuccess extends EntityAction {
  protected actionName: string = originationActions.UPDATE_UPLOADS_CATEGORIES_SUCCESS;

  constructor(payload: UpdateUploadsCategoriesPayload) {
    super(SLICE.FUNDING_PROPOSAL, payload);
  }
}

export class ReviewUpload extends EntityAction {
  protected actionName: string = originationActions.REVIEW_UPLOAD;

  constructor(payload: ReviewUploadPayload) {
    super(SLICE.FUNDING_PROPOSAL, payload);
  }
}

export class ReviewUploadSuccess extends EntityAction {
  protected actionName: string = originationActions.REVIEW_UPLOAD_SUCCESS;

  constructor(payload: ReviewUploadPayload) {
    super(SLICE.FUNDING_PROPOSAL, payload);
  }
}

export class MoveUpload extends EntityAction {
  protected actionName: string = originationActions.MOVE_UPLOAD;

  constructor(payload: MoveUploadPayload) {
    super(SLICE.FUNDING_PROPOSAL, payload);
  }
}

export class MoveUploadSuccess extends EntityAction {
  protected actionName: string = originationActions.MOVE_UPLOAD_SUCCESS;

  constructor() {
    super(SLICE.FUNDING_PROPOSAL);
  }
}

export class MoveUploadFailure extends EntityAction {
  protected actionName: string = originationActions.MOVE_UPLOAD_FAILURE;

  constructor(payload: MoveUploadPayload) {
    super(SLICE.FUNDING_PROPOSAL, payload);
  }
}

export class DownloadCreditScoringModelFile extends EntityAction {
  protected actionName: string = originationActions.DOWNLOAD_UPLOADS_FILE;

  constructor(payload: { id: IdType, file: { fileId: string } }) {
    super(SLICE.CREDIT_ASSESSMENT, payload);
  }
}

export class ChamberOfCommerceNameLoad extends EntityAction {
  protected actionName: string = originationActions.CHAMBER_OF_COMMERCE_NAME_LOAD;

  constructor(payload: { id: IdType, country: string }) {
    super(SLICE.CHAMBER_OF_COMMERCE_NAME, payload);
  }
}

export class UpdateOwnerUsers extends EntityAction {
  protected actionName: string = originationActions.UPDATE_OWNER_USERS;

  constructor(payload: UpdateOwnerUsersPayload) {
    super(SLICE.FUNDING_PROCESS, payload);
  }
}

export class UpdateOwnerUsersSuccess extends EntityAction {
  protected actionName: string = originationActions.UPDATE_OWNER_USERS_SUCCESS;

  constructor(payload: UpdateOwnerUsersPayload) {
    super(SLICE.FUNDING_PROCESS, payload);
  }
}
