import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RepeatableItem } from '../../../../../../shared/layout/repeatable-form-item/repeatable-item';
import { CapleFormGroup } from '../../../../../../shared/util/caple-form';
import { FundingUse } from '../../../../../../shared/api/models/fundingUse';
import { CapleFormBuilder } from '../../../../../../shared/util/caple-form-builder';
import { GrowthFundingUseCategory } from '../../../../../../shared/api/models/growthFundingUseCategory';


interface ItemValue {
  readonly use: GrowthFundingUseCategory;
  readonly amount: number;
  readonly comment: string;
}

@Component({
  selector: 'caple-funding-use-item',
  templateUrl: 'company-funding-use-item.component.html'
})
export class CompanyFundingUseItemComponent extends RepeatableItem {
  @Input()
  public item: FormGroup;

  @Input()
  public index: number;

  @Input()
  public showRemove: boolean;

  @Input()
  public snapShotState: FundingUse;

  @Input()
  public translationGroup: CapleFormGroup;

  @Output()
  public removed: EventEmitter<void> = new EventEmitter<void>();

  public FundingUsesCategory = Object.keys(GrowthFundingUseCategory).filter(k => k.indexOf('_') === -1).map(k => {
    return {
      key: k,
      value: GrowthFundingUseCategory[k]
    };
  });

  public static produceFormGroup(itemValOpt?: ItemValue): CapleFormGroup {
    const val = itemValOpt || {use: null, amount: '', comment: ''};
    return new CapleFormBuilder().group({
      'use': [val.use, Validators.required],
      'amount': [val.amount, [Validators.required, Validators.min(0)]],
      'comment': val.comment
    });
  }

  public produceFormGroup(val?: any): FormGroup | FormControl {
    return CompanyFundingUseItemComponent.produceFormGroup(val);
  }

  public equalToSnapShot(val: any): boolean {
    return this.item.get('use').value === val.use;
  }
}
