import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { State } from '../../../state-management/reducers';
import { CreateSingle, LoadAll, LoadSingle } from '../../../state-management/util/actions';
import { CreateMonitoringFile } from '../../../shared/api/models/createMonitoringFile';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ReadCompany } from '../../../shared/api/models/readCompany';
import { SLICE } from '../../state-management/slice';
import { selectAllCompanies, selectAllCompaniesWithRole, selectAllFundingProcesses, selectUsersForCompany } from '../../state-management/selector';
import { FundingProcess } from '../../../shared/api/models/fundingProcess';
import { Role } from '../../../shared/api/models/role';
import { ArrayValidators } from '../../../shared/util/array-validators';
import { SelectCovenantsComponent } from './covenants.component';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { reportingPeriodAndAnnualDatesMatch } from '../../validation/reportingPeriodAndAnnualDatesMatch';
import { CloseModal } from '../../../state-management/layout/actions';
import { ReportingFrequency } from '../../../shared/api/models/reportingFrequency';
import { Table } from '../../../state-management/util/adapters';
import { ReadUser } from '../../../shared/api/models/readUser';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'caple-create-monitoring-file-dialog',
  templateUrl: './create-monitoring-file-dialog.component.html'
})
export class CreateMonitoringFileDialogComponent implements OnInit, OnDestroy {
  public selectCovenantsComponent = SelectCovenantsComponent;

  /**
   * The formGroup for the create client form
   */
  public form: FormGroup;
  public covenantsFormGroup: FormGroup;

  public companies$: Observable<ReadCompany[]>;
  public loanServicers$: Observable<ReadCompany[]>;
  public fundingProcesses$: Observable<FundingProcess[]>;
  public subscriptions: Subscription = new Subscription();

  public reportingFrequencyValues = Object.values(ReportingFrequency);
  public users$: Observable<ReadUser[]>;

  constructor(private store: Store<State>, private formBuilder: FormBuilder) {
    this.store.dispatch(new LoadAll(SLICE.COMPANY));
    this.store.dispatch(new LoadAll(SLICE.FUNDING_PROCESS, true));
    this.companies$ = this.store.pipe(select(selectAllCompanies));
    this.loanServicers$ = this.store.pipe(select(selectAllCompaniesWithRole(Role.LOANSERVICER)));
    this.fundingProcesses$ = this.store.pipe(select(selectAllFundingProcesses));

    this.form = formBuilder.group({
      'firstReportingEndDate': ['', Validators.required],
      'reportingFrequency': ['', Validators.required],
      'firstAnnualBudgetYearEndDate': ['', [Validators.required]],
      'term': ['', [Validators.required, Validators.min(1)]],
      'interestRate': ['', [Validators.required, Validators.min(1), Validators.max(100)]],
      'loanAmount': ['', [Validators.required, Validators.min(1)]],
      'firstInterestPayment': ['', Validators.required],
      'firstRedemptionPayment': ['', [Validators.required]],
      'reportingSource': formBuilder.group({
        'reportingSourceId': ['', Validators.required],
        'reportingSourceUsers': [null]
      }),
      'borrower': ['', [Validators.required]],
      'fundingProcess': ['', [Validators.required]],
      'loanServicer': ['', [Validators.required]]
    }, {
      validator: reportingPeriodAndAnnualDatesMatch('firstReportingEndDate', 'firstAnnualBudgetYearEndDate', 'reportingFrequency')
    });

    this.covenantsFormGroup = formBuilder.group({
      'covenants': formBuilder.array([
        this.createCovenenant()()
      ], Validators.compose([ArrayValidators.minLength(1), ArrayValidators.maxLength(256)]))
    });
  }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.form.get('firstInterestPayment').valueChanges.subscribe(value => {
        const firstRedemptionPayment = this.form.get('firstRedemptionPayment');
        if (firstRedemptionPayment.untouched) {
          firstRedemptionPayment.patchValue(value);
        }
      })
    );

    this.subscriptions.add(
      this.form.get('reportingSource.reportingSourceId').valueChanges.subscribe(value => {
        this.form.get('reportingSource.reportingSourceUsers').setValue([]);
        const reportingSourceId = this.form.get('reportingSource.reportingSourceId').value;
        this.store.dispatch(new LoadSingle(SLICE.COMPANY_USERS, {id: reportingSourceId}));
        this.users$ = this.store.pipe(select(selectUsersForCompany(reportingSourceId)),
          filter(table => !!table),
          map((table: Table<ReadUser>) => {
            return Object.values(table.entities);
          }));
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public createMonitoringFile() {
    const payload: CreateMonitoringFile = {
      firstReportingEndDate: this.form.controls['firstReportingEndDate'].value,
      firstAnnualBudgetYearEndDate: this.form.controls['firstAnnualBudgetYearEndDate'].value,
      term: this.form.controls['term'].value,
      interestRate: this.form.controls['interestRate'].value,
      loanAmount: this.form.controls['loanAmount'].value,
      firstInterestPayment: this.form.controls['firstInterestPayment'].value,
      firstRedemptionPayment: this.form.controls['firstRedemptionPayment'].value,
      reportingSource: this.form.controls['reportingSource'].value,
      borrowerId: this.form.controls['borrower'].value,
      fundingProcessId: this.form.controls['fundingProcess'].value,
      loanServicerId: this.form.controls['loanServicer'].value,
      covenants: this.covenantsFormGroup.get('covenants').value,
      reportingFrequency: this.form.controls['reportingFrequency'].value
    };
    this.store.dispatch(new CreateSingle(SLICE.MONITORING_FILE, payload));
  }

  public createCovenenant() {
    return () => SelectCovenantsComponent.produceFormGroup({
      activeFrom: this.form.controls['firstReportingEndDate'].value
    });
  }

  public stepChanged($event: StepperSelectionEvent) {
    const firstReportingEndDate = this.form.controls['firstReportingEndDate'].value;

    const covenantsArray = (this.covenantsFormGroup.controls['covenants'] as FormArray).controls;
    const covenantStepActive = $event.selectedIndex === 1;
    const genericStepActive = $event.selectedIndex === 0;

    // When switching to the covenant step, set the default value
    // When switching to the generic step, remove the default value so any changes to the term
    // or firstReportingEndDate will be reflected in the covenants
    if (covenantStepActive) {
      covenantsArray.forEach((covenant) => {
        if (covenant.get('activeFrom').value === '') {
          covenant.get('activeFrom').setValue(firstReportingEndDate);
        }
      });
    } else if (genericStepActive) {
      covenantsArray.forEach((covenant) => {
        if (covenant.get('activeFrom').value === firstReportingEndDate) {
          covenant.get('activeFrom').setValue('');
        }
      });
    }
  }

  public getCountryForSelectedProcess(fundingProcesses: FundingProcess[], fundingProcessId): string | null {
    if (fundingProcessId) {
      const strings = fundingProcesses.filter(fp => fp.id === fundingProcessId.value).map(fp => fp.client.country);
      if (strings.length > 0) {
        return strings[0];
      }
    }
    return null;
  }

  public closeModal() {
    this.store.dispatch(new CloseModal());
  }
}
