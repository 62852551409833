import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class ArrayValidators {

  public static minLength(minLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === null || control.value === undefined || !Array.isArray(control.value)) {
        return null;  // don't validate empty values to allow optional controls
      }

      return control.value.length >= minLength ? null : {'minlength': {'requiredLength': minLength, 'actualLength': control.value.length}};
    };
  }

  public static maxLength(maxLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === null || control.value === undefined || !Array.isArray(control.value)) {
        return null;  // don't validate empty values to allow optional controls
      }

      return control.value.length > maxLength ? {'maxlength': {'maxLength': maxLength, 'actualLength': control.value.length}} : null;
    };
  }

}
