import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpUploadProgressEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AbstractApiService } from './abstract-api.service';
import { Id } from '../models/id';
import { CreateConditionsPrecedentFile } from '../models/createConditionsPrecedentFile';
import { ConditionsPrecedentFile } from '../models/conditionsPrecedentFile';
import { FileUploaded } from '../models/fileUploaded';
import { ResourceId } from './kyc.service';
import { WithId } from '../../../state-management/util/actions';
import { FileDetails } from '../models/fileDetails';
import { Decision } from '../models/decision';
import { UpdateConditionsPrecedentFile } from '../models/updateConditionsPrecedentFile';
import { DefaultConditions } from '../models/defaultConditions';


export interface AttachFilePayload extends ConditionPayload {
  fileDetails: FileDetails;
}

export interface ResourceId extends WithId {
  resourceId: string;
}

export interface FilePayload extends ConditionPayload {
  fileId: string;
}

export interface ConditionPayload extends WithId {
  conditionId: string;
}

export interface AssessFilePayload extends FilePayload {
  decision: Decision;
}

export interface AssessConditionPayload extends ConditionPayload {
  completed: boolean;
}

export interface UpdateConditionsPrecedentFilePayload extends WithId {
  updatedFile: UpdateConditionsPrecedentFile;
}

@Injectable()
export class ConditionsPrecedentService extends AbstractApiService {
  private readonly baseUrl = '/api/v1/conditions-precedent';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public createSingle(payload: CreateConditionsPrecedentFile): Observable<Id> {
    return this.post<Id>(this.baseUrl, payload);
  }

  public loadSingle(payload: Id): Observable<ConditionsPrecedentFile> {
    return this.get<ConditionsPrecedentFile>(`${this.baseUrl}/${payload.id}`);
  }

  public updateFile(payload: UpdateConditionsPrecedentFilePayload) {
    return this.post(`${this.baseUrl}/${payload.id}`, payload.updatedFile);
  }

  public publishFile(payload: WithId) {
    return this.post(`${this.baseUrl}/${payload.id}/publish`, null);
  }

  public uploadResource(file: File): [Observable<FileUploaded>, Observable<HttpUploadProgressEvent>] {
    const formData = new FormData();
    formData.append('file', file);

    return this.postFile(`${this.baseUrl}/resources`, formData);
  }

  public uploadFile(id: Id, file: File): [Observable<FileUploaded>, Observable<HttpUploadProgressEvent>] {
    const formData = new FormData();
    formData.append('file', file);

    return this.postFile(`${this.baseUrl}/${id.id}/files`, formData);
  }

  public downloadResource(id: ResourceId): Observable<HttpEvent<Blob>> {
    return this.getFileWithProgress(`${this.baseUrl}/${id.id}/resources/${id.resourceId}`);
  }

  public downloadFile(filePayload: FilePayload): Observable<HttpEvent<Blob>> {
    return this.getFileWithProgress(`${this.baseUrl}/${filePayload.id}/${filePayload.conditionId}/${filePayload.fileId}`);
  }

  public downloadZip(id: WithId) {
    return this.getFileWithProgress(`${this.baseUrl}/${id.id}/zip`);
  }

  public removeFile(filePayload: FilePayload) {
    return this.delete(`${this.baseUrl}/${filePayload.id}/${filePayload.conditionId}/${filePayload.fileId}`);
  }

  public attachFile(payload: AttachFilePayload) {
    return this.post(`${this.baseUrl}/${payload.id}/${payload.conditionId}`, payload.fileDetails);
  }

  public loadAll(): Observable<Array<ConditionsPrecedentFile>> {
    return this.get<Array<ConditionsPrecedentFile>>(`${this.baseUrl}`);
  }

  public assessFile(payload: AssessFilePayload) {
    return this.post(`${this.baseUrl}/${payload.id}/${payload.conditionId}/${payload.fileId}/assessment`, {
      decision: payload.decision
    });
  }

  public assessCondition(payload: AssessConditionPayload) {
    return this.post(`${this.baseUrl}/${payload.id}/${payload.conditionId}/assessment`, {
      completed: payload.completed
    });
  }


  public loadDefault(payload: Id) {
    return this.get<Array<DefaultConditions>>(`${this.baseUrl}/defaults/${payload.id}`);
  }

}
