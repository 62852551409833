import {Component, ElementRef, ViewChild} from '@angular/core';
import {Action, select, Store} from '@ngrx/store';
import {State} from '../../../state-management/reducers';
import {CloseModal} from '../../../state-management/layout/actions';
import {EligibilityCheckConnect, StartFundingProcessWithNewClient} from '../../state-management/actions';
import {SLICE} from '../../state-management/slice';
import {CreateSingle} from '../../../state-management/util/actions';
import {MatDialogRef} from '@angular/material/dialog';
import {selectActiveFundingProcess, selectChamberOfCommerceResult} from '../../state-management/selector';
import {filter, switchMap, take} from 'rxjs/operators';
import {BasicEligiblityComponent} from './basic-eligibility/basic-eligiblity.component';
import {AdvancedEligiblityComponent} from './advanced-eligiblity/advanced-eligiblity.component';
import {CompanyInformationComponent} from './company-information/company-information.component';
import {ChamberOfCommerceCompany} from '../../../shared/api/models/chamberOfCommerceCompany';
import {Observable} from 'rxjs';
import {Product} from '../../../shared/api/models/product';
import {CreateClient} from '../../../shared/api/models/createClient';
import {ClientId} from '../../../shared/api/models/clientId';
import { ProductType } from '../../../shared/api/models/productType';

@Component({
  selector: 'caple-start-funding-process-dialog',
  templateUrl: './start-funding-process-dialog.component.html'
})
export class StartFundingProcessDialogComponent {

  @ViewChild(CompanyInformationComponent, {static: false})
  companyInformationComponent: CompanyInformationComponent;
  @ViewChild(BasicEligiblityComponent, {static: false})
  basicEligibility: BasicEligiblityComponent;
  @ViewChild(AdvancedEligiblityComponent, {static: false})
  advancedEligibility: AdvancedEligiblityComponent;

  public phase = 0;
  public eligibilityId: string;
  public advancedEligibilityTested = false;
  public chamberOfCommerceData$: Observable<ChamberOfCommerceCompany>;
  public sectorCode: string;
  public product: Product;
  public productValid = false;

  private startFundingProcessAction: Action;
  private client: CreateClient;
  private clientId: ClientId;

  constructor(private store: Store<State>, private elRef: ElementRef, private dialogRef: MatDialogRef<StartFundingProcessDialogComponent>) {
  }

  public startFundingProcess() {
    this.store.dispatch(this.startFundingProcessAction);

    if (this.eligibilityId) {
      // After the FP is created the dialog will be closed and we can connect the eligibility
      this.dialogRef.afterClosed()
        .pipe(
          switchMap(() => this.store.pipe(select(selectActiveFundingProcess))),
          filter(value => !!value),
          take(1)
        )
        .subscribe(fundingProcess => {
          this.store.dispatch(new EligibilityCheckConnect({
            id: this.eligibilityId,
            connectEligibility: {
              fundingProcessId: fundingProcess.id
            }
          }));
        });
    }
  }

  public nextDisabled() {
    switch (this.phase) {
      case 0:
        if (!this.companyInformationComponent) {
          return true;
        }
        return this.companyInformationComponent && !this.companyInformationComponent.canContinue();
      case 1:
        return !this.productValid;
      default:
        throw new Error('Unknown phase');
    }
  }

  public next() {
    switch (this.phase) {
      case 0:
        this.clientId = this.companyInformationComponent.clientId;

        if (!this.isForExistingClient()) {
          this.sectorCode = this.companyInformationComponent.newClient.sector.code;
          this.client = this.companyInformationComponent.newClient;
          this.chamberOfCommerceData$ = this.store.pipe(
            select(selectChamberOfCommerceResult({id: this.companyInformationComponent.newClient.companyNumber}))
          );
        }
        this.phase += 1;
        break;
      case 1:
        if (this.isForExistingClient()) { // existing client, no need for e8y
          this.store.dispatch(new CreateSingle(SLICE.FUNDING_PROCESS, {
            clientId: this.clientId,
            product: this.product
          }));
          return;
        }
        this.startFundingProcessAction = new StartFundingProcessWithNewClient({
          client: this.client,
          product: this.product
        });
        this.startFundingProcess();
        return;
      default:
        throw new Error('Current phase unknown');
    }
    this.scrollToTop();
  }

  public testCriteria() {
    switch (this.phase) {
      case 2:
        this.basicEligibility.startCheck();
        break;
      case 3:
        this.advancedEligibility.startCheck();
        break;
      default:
        throw new Error('Unknown phase cannot determine kind of eligibility check to execute');
    }
  }

  public scrollToTop() {
    const scrollBlock = this.elRef.nativeElement.querySelector('mat-dialog-content .scroll-block');
    if (scrollBlock) {
      scrollBlock.scrollTop = 0;
    }
  }

  public closeModal() {
    this.store.dispatch(new CloseModal());
  }

  public getSecondaryTitle() {
    switch (this.phase) {
      case 0:
        return 'funding-process.start.title-create-client';
      case 1:
        return 'funding-process.start.title-type';
      case 2:
        return 'funding-process.start.title-basic';
      case 3:
        return 'funding-process.start.title-advanced';
      default:
        throw new Error('Unknown phase cannot determine secondary title');
    }
  }

  public eligibilityCreated($event: string) {
    this.eligibilityId = $event;
    this.advancedEligibilityTested = true;
  }

  /*
     we disabled the EligibilyCriteria for everyone as part of a platform update
     Before the eligibility criteria where disabled for existingClients and ProductMbi's
   */
  public shouldShowEligibilityCriteria() {
    return false;
  }

  public isProductMbi() {
    return this.product && this.product.productSubType === ProductType.MBI;
  }

  public isForExistingClient(): boolean {
    return !!this.clientId;
  }
}
