import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {RepeatableItem} from '../../../../../shared/layout/repeatable-form-item/repeatable-item';
import {CapleFormBuilder} from '../../../../../shared/util/caple-form-builder';

@Component({
  selector: 'caple-revenue-by-country-item',
  templateUrl: './revenue-by-country-item.component.html'
})
export class RevenueByCountryItemComponent extends RepeatableItem {
  @Input()
  public item: FormGroup;

  @Input()
  public showRemove: boolean;

  @Input()
  public index: number;

  @Output()
  public removed: EventEmitter<void> = new EventEmitter<void>();

  public static produceFormGroup(val?: any) {
    val = val || {};
    return new CapleFormBuilder().group({
      'country': [val.country, Validators.required],
      'percentage': [val.percentage, Validators.required]
    });
  }

  public produceFormGroup(val?: any): FormGroup {
    return RevenueByCountryItemComponent.produceFormGroup(val);
  }

  public equalToSnapShot(val: any): boolean {
    return this.item.get('country').value === val.country;
  }
}
