import { Action } from '@ngrx/store';
import { ComponentType } from '@angular/cdk/portal';
import { ApiError } from '../../shared/api/api-error';
import { MatDialogConfig } from '@angular/material/dialog/typings/dialog-config';
import { EntityAction } from '../util/actions';
import { SLICE } from './slice';
import { PageScrollTarget } from 'ngx-page-scroll-core';

export enum LayoutActions {
  SHOW_TOAST = '[Toast] Show Toast',
  OPEN_MODAL = '[Modal] Open modal',
  CLOSE_MODAL = '[Modal] Close modal',
  MODAL_OPENED = '[Modal] Modal Opened',
  MODAL_CLOSED = '[Modal] Modal Closed',
  MODAL_DISABLE_CLOSE = '[Modal] Modal Disable Close',
  MODAL_ENABLE_CLOSE = '[Modal] Modal Enable Close',
  SHOW_LOADING_INDICATOR = '[Loading] Show indicator',
  HIDE_LOADING_INDICATOR = '[Loading] Hide indicator',
  SHOW_PROGRESS_INDICATOR = '[Progress] Show indicator',
  PROGRESS_INDICATOR_OPENED = '[Progress] indicator opened',
  HIDE_PROGRESS_INDICATOR = '[Progress] Hide indicator',
  PROGRESS_INDICATOR_CLOSED = '[Progress] indicator closed',
  UPDATE_PROGRESS_INDICATOR = '[Progress] Update indicator',
  FILE_UPLOAD_FAILED = '[Upload] File upload failed',
  FILE_DOWNLOAD_FAILED = '[Upload] File download failed',
  SCROLL_TO = '[Scroll] Scroll to',
  MARK_CHANGELOG_AS_READ = 'MARK_CHANGELOG_AS_READ',
  MARK_CHANGELOG_AS_READ_SUCCESS = 'MARK_CHANGELOG_AS_READ_SUCCESS'
}

export class OpenModal implements Action {
  readonly type = LayoutActions.OPEN_MODAL;

  constructor(public payload: ComponentType<any>, public componentName: string, public config?: MatDialogConfig, public data?: any) {
  }
}

export class ModalOpened implements Action {
  readonly type = LayoutActions.MODAL_OPENED;

  constructor() {
  }
}

export class CloseModal implements Action {
  readonly type = LayoutActions.CLOSE_MODAL;
}

export class ModalClosed implements Action {
  readonly type = LayoutActions.MODAL_CLOSED;
}

export class ModalDisableClose implements Action {
  readonly type = LayoutActions.MODAL_DISABLE_CLOSE;
}

export class ModalEnableClose implements Action {
  readonly type = LayoutActions.MODAL_ENABLE_CLOSE;
}

export class ShowToast implements Action {
  readonly type = LayoutActions.SHOW_TOAST;

  constructor(public message: string | ApiError, public interpolateParams?: Object) {
  }
}

export class ShowLoadingIndicator implements Action {
  readonly type = LayoutActions.SHOW_LOADING_INDICATOR;

  constructor(public key: string) {
  }
}

export class HideLoadingIndicator implements Action {
  readonly type = LayoutActions.HIDE_LOADING_INDICATOR;

  constructor(public key: string) {
  }
}

export class ShowProgressIndicator implements Action {
  readonly type = LayoutActions.SHOW_PROGRESS_INDICATOR;

  constructor(public translationKey: string, public translationData?: any) {
  }
}

export class ProgressIndicatorOpened implements Action {
  readonly type = LayoutActions.PROGRESS_INDICATOR_OPENED;
}

export class HideProgressIndicator implements Action {
  readonly type = LayoutActions.HIDE_PROGRESS_INDICATOR;

  constructor() {
  }
}

export class ProgressIndicatorClosed implements Action {
  readonly type = LayoutActions.PROGRESS_INDICATOR_CLOSED;

  constructor() {
  }
}

export class UpdateProgressIndicator implements Action {
  readonly type = LayoutActions.UPDATE_PROGRESS_INDICATOR;

  constructor(public percentage: number, public loaded: number, public total: number) {
  }
}

export class FileUploadFailed implements Action {
  readonly type = LayoutActions.FILE_UPLOAD_FAILED;

  constructor(public payload: ApiError) {
  }
}

export class FileDownloadFailed implements Action {
  readonly type = LayoutActions.FILE_DOWNLOAD_FAILED;

  constructor(public payload: ApiError) {
  }
}

export class ScrollTo implements Action {
  readonly type = LayoutActions.SCROLL_TO;

  constructor(public payload: { document?: HTMLElement, target: PageScrollTarget, offset?: number }) {
  }
}

export class MarkChangelogAsRead extends EntityAction {
  readonly actionName = LayoutActions.MARK_CHANGELOG_AS_READ;

  constructor() {
    super(SLICE.CHANGELOG);
  }
}

export class MarkChangelogAsReadSuccess extends EntityAction {
  readonly actionName = LayoutActions.MARK_CHANGELOG_AS_READ_SUCCESS;

  constructor() {
    super(SLICE.CHANGELOG);
  }
}


export type Actions = OpenModal | CloseModal | ModalOpened | ModalClosed |
  ShowToast | ShowLoadingIndicator | HideLoadingIndicator |
  ShowProgressIndicator | ProgressIndicatorOpened | UpdateProgressIndicator |
  HideProgressIndicator | ProgressIndicatorClosed |
  FileDownloadFailed | FileUploadFailed | ScrollTo;
