<h2 mat-dialog-title>{{ 'administration.company.rename-dialog.title' | translate}}</h2>
<mat-dialog-content>
  <form [formGroup]="form">

    <div class="pure-g">
      <div class="pure-u-1-1">
        <mat-form-field floatLabel="always">
          <input matInput placeholder="{{ 'administration.company.rename-dialog.new-name' | translate}}" [formControl]="form.controls['newName']"/>
          <mat-error *ngIf="form.hasError('required', ['newName'])">{{ 'generic.validation.required' | translate}}</mat-error>
          <mat-error *ngIf="form.hasError('minlength', ['newName'])">{{ 'generic.validation.min-length' | translate:{value:3} }}</mat-error>
        </mat-form-field>
      </div>
    </div>

    <mat-dialog-actions>
      <caple-loading-wrapper key="urn:company:rename" [diameter]="36">
        <button mat-button (click)="closeModal()">{{ 'generic.cancel' | translate }}</button>
        <button mat-button (click)="renameCompany()">
          {{'administration.company.rename' | translate }}
        </button>
      </caple-loading-wrapper>
    </mat-dialog-actions>
  </form>
</mat-dialog-content>
