import {ValidatorFn, Validators} from '@angular/forms';
import {SectorType} from '../../shared/api/models/sectorType';
import {SupportedCountry} from '../../shared/api/models/supportedCountry';
import {isSectorCodeValidControl} from '../../eligibility/eligibility-validator';


export interface CountryData {
  companyNumberFn: ValidatorFn;
  sectorCodePatternFn: ValidatorFn;
  sectorCodeValidFn: ValidatorFn;
  sectorType: SectorType;
}

export const COUNTRY_DATA = new Map<SupportedCountry, CountryData>()
  .set(SupportedCountry.GB, {
    companyNumberFn: Validators.pattern('(^[0-9]{8}$)|(^[A-Za-z]{2}[0-9]{6}$)'),
    sectorCodePatternFn: Validators.pattern('^[0-9]{5}$'),
    sectorCodeValidFn: isSectorCodeValidControl(SupportedCountry.GB),
    sectorType: 'SIC'
  })
  .set(SupportedCountry.NL, {
    companyNumberFn: Validators.pattern('^[0-9]{8}$'),
    sectorCodePatternFn: Validators.pattern('^[0-9]{4,5}$'),
    sectorCodeValidFn: isSectorCodeValidControl(SupportedCountry.NL),
    sectorType: 'SBI'
  });
