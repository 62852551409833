import { NgModule } from '@angular/core';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { MenuBarComponent } from './menu-bar/menu-bar.component';
import { FileUploadButtonComponent } from './file-upload-button/file-upload-button.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { TooltipDialogComponent } from './tooltip/tooltip-dialog.component';
import { LoadingWrapperComponent } from './loading-wrapper/loading-wrapper.component';
import { CommonModule } from '@angular/common';
import { MaterialDesignModule } from './material-design.module';
import { TranslateModule } from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { RouterModule } from '@angular/router';
import { RepeatableItemSelector } from './repeatable-form-item/repeabtable-item-selector';
import { RepeatableArrayComponent } from './repeatable-form-item/repeatable-array.component';
import { FullPageMessageComponent } from './full-page-message/full-page-message.component';
import { LocalizedDatePipe } from './date-pipe/date.pipe';
import { CurrencySymbolComponent } from './currency-symbol/currency-symbol.component';
import { ExcelImportFieldComponent } from './excel-import-field/excel-import-field.component';
import { StatusIndicatorComponent } from './status-indicator/status-indicator.component';
import { ProgressIndicatorDialogComponent } from './progress-indicator-dialog/progress-indicator-dialog.component';
import { FileSizePipe } from './file-size/file-size.pipe';
import { LoadingDotsComponent } from './loading-dots/loading-dots.component';
import { RequiredRoleDirective } from './required-role.directive';
import { AnnouncementComponent } from './announcement/announcement.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StopClickPropagation } from './stop-click-propogation.directive';
import { CurrencyInputComponent } from './currency-input/currency-input.component';
import { InlineTooltipComponent } from './tooltip/inline-tooltip.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { VerificationStatusComponent } from './verification-status/verification-status.component';
import { ChangelogIconComponent } from './changelog-icon/changelog-icon.component';
import { NoticeComponent } from './notice/notice.component';
import { TableFilterComponent } from './table-filter/table-filter.component';
import { Angulartics2Module } from 'angulartics2';
import { BlockTooltipComponent } from './tooltip/block-tooltip.component';
import { CurrencySymbolPipe } from './currency-symbol/currency-symbol.pipe';
import { NumberInputComponent } from './number-input/number-input.component';

@NgModule({
  imports: [
    // Angular
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    Angulartics2Module,


    // Angular Material Design
    MaterialDesignModule,

    // NgRx
    StoreModule,
    TranslateModule
  ],
  declarations: [
    FileUploadButtonComponent,
    CurrencySymbolComponent,
    CurrencyInputComponent,
    CurrencySymbolPipe,
    NumberInputComponent,
    ChangelogIconComponent,
    DatePickerComponent,
    LocalizedDatePipe,
    LoadingWrapperComponent,
    ProgressIndicatorDialogComponent,
    ConfirmDialogComponent,
    TooltipComponent,
    TooltipDialogComponent,
    InlineTooltipComponent,
    BlockTooltipComponent,
    MenuBarComponent,
    RepeatableItemSelector,
    RepeatableArrayComponent,
    FullPageMessageComponent,
    ExcelImportFieldComponent,
    StatusIndicatorComponent,
    FileSizePipe,
    LoadingDotsComponent,
    RequiredRoleDirective,
    LoadingDotsComponent,
    AnnouncementComponent,
    StopClickPropagation,
    PageHeaderComponent,
    VerificationStatusComponent,
    NoticeComponent,
    TableFilterComponent
  ],
  exports: [
    FileUploadButtonComponent,
    CurrencySymbolComponent,
    CurrencyInputComponent,
    CurrencySymbolPipe,
    NumberInputComponent,
    ChangelogIconComponent,
    DatePickerComponent,
    LocalizedDatePipe,
    LoadingWrapperComponent,
    ProgressIndicatorDialogComponent,
    ConfirmDialogComponent,
    TooltipComponent,
    TooltipDialogComponent,
    InlineTooltipComponent,
    BlockTooltipComponent,
    MenuBarComponent,
    RepeatableItemSelector,
    RepeatableArrayComponent,
    FullPageMessageComponent,
    ExcelImportFieldComponent,
    StatusIndicatorComponent,
    MaterialDesignModule,
    FileSizePipe,
    LoadingDotsComponent,
    RequiredRoleDirective,
    StopClickPropagation,
    PageHeaderComponent,
    VerificationStatusComponent,
    NoticeComponent,
    TableFilterComponent
  ],
  entryComponents: [
    // Fix for https://github.com/angular/material2/issues/8473
    ProgressIndicatorDialogComponent,
    ConfirmDialogComponent,
    TooltipDialogComponent
  ]
})
export class LayoutModule {
}
