import { actions, EntityAction } from '../../state-management/util/actions';
import { CompanyUserPayload } from '../../shared/api/services/company.service';
import { PromoteToCompanyPayload } from '../../shared/api/services/client.service';
import { SLICE } from './slice';
import { Id } from '../../shared/api/models/id';

export const administrationActions = {
  ...actions,
  BLOCK_USER: 'BLOCK_USER',
  BLOCK_USER_SUCCESS: 'BLOCK_USER_SUCCESS',
  UNBLOCK_USER: 'UNBLOCK_USER',
  UNBLOCK_USER_SUCCESS: 'UNBLOCK_USER_SUCCESS',
  RESEND_WELCOME_EMAIL: 'RESEND_WELCOME_EMAIL',
  RESEND_WELCOME_EMAIL_SUCCESS: 'RESEND_WELCOME_EMAIL_SUCCESS',
  PROMOTE_TO_COMPANY: 'PROMOTE_TO_COMPANY',
  PROMOTE_TO_COMPANY_SUCCESS: 'PROMOTE_TO_COMPANY_SUCCESS',
  UPDATE_COMPANY_FILTERED_COUNTRIES: 'UPDATE_COMPANY_FILTERED_COUNTRIES',
  UPDATE_COMPANY_SEARCH_QUERY: 'UPDATE_COMPANY_SEARCH_QUERY',
};

export class BlockUser extends EntityAction {
  protected actionName: string = administrationActions.BLOCK_USER;

  constructor(payload: CompanyUserPayload) {
    super(SLICE.USER, payload);
  }
}

export class BlockUserSuccess extends EntityAction {
  protected actionName: string = administrationActions.BLOCK_USER_SUCCESS;

  constructor(request: BlockUser) {
    super(SLICE.USER, request);
  }
}

export class UnblockUser extends EntityAction {
  protected actionName: string = administrationActions.UNBLOCK_USER;

  constructor(payload: CompanyUserPayload) {
    super(SLICE.USER, payload);
  }
}

export class UnblockUserSuccess extends EntityAction {
  protected actionName: string = administrationActions.UNBLOCK_USER_SUCCESS;

  constructor(request: UnblockUser) {
    super(SLICE.USER, request);
  }
}

export class ResendWelcomeEmail extends EntityAction {
  protected actionName: string = administrationActions.RESEND_WELCOME_EMAIL;

  constructor(public slice: string, payload: CompanyUserPayload) {
    super(slice, payload);
  }
}

export class ResendWelcomeEmailSuccess extends EntityAction {
  protected actionName: string = administrationActions.RESEND_WELCOME_EMAIL_SUCCESS;

  constructor(request: ResendWelcomeEmail) {
    super(SLICE.USER, request);
  }
}

export class PromoteToCompany extends EntityAction {
  protected actionName: string = administrationActions.PROMOTE_TO_COMPANY;

  constructor(public payload: PromoteToCompanyPayload) {
    super(SLICE.CLIENT, payload);
  }
}

export class PromoteToCompanySuccess extends EntityAction {
  protected actionName: string = administrationActions.PROMOTE_TO_COMPANY_SUCCESS;

  constructor(public payload: Id, public request: PromoteToCompany) {
    super(SLICE.CLIENT, payload);
  }
}

export class UpdateCompanyFilteredCountries extends EntityAction {
  protected actionName: string = administrationActions.UPDATE_COMPANY_FILTERED_COUNTRIES;

  constructor(payload: { country: string, isChecked: boolean }) {
    super(SLICE.COMPANY, payload);
  }
}


export class UpdateCompanySearchQuery extends EntityAction {
  protected actionName: string = administrationActions.UPDATE_COMPANY_SEARCH_QUERY;

  constructor(payload: { searchQuery: string }) {
    super(SLICE.COMPANY, payload);
  }
}
