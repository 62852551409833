import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { RepeatableItem } from '../../../../../shared/layout/repeatable-form-item/repeatable-item';
import { CapleFormBuilder } from '../../../../../shared/util/caple-form-builder';
import { debounceTime, filter, take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { SupportedCountry } from '../../../../../shared/api/models/supportedCountry';
import { selectActiveFundingProcessCountry, selectChamberOfCommerceNameResult } from '../../../../state-management/selector';
import { ChamberOfCommerceNameLoad } from '../../../../state-management/actions';
var GuarantorItemComponent = /** @class */ (function (_super) {
    tslib_1.__extends(GuarantorItemComponent, _super);
    function GuarantorItemComponent(store) {
        var _this = _super.call(this) || this;
        _this.store = store;
        _this.removed = new EventEmitter();
        _this.subscriptions = new Subscription();
        _this.chamberOfCommerceName = new Subscription();
        return _this;
    }
    GuarantorItemComponent.produceFormGroup = function (val) {
        val = val || {};
        return new CapleFormBuilder().group({
            companyNumber: [val.companyNumber],
            companyName: [val.companyName]
        });
    };
    GuarantorItemComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.add(this.store.pipe(select(selectActiveFundingProcessCountry), take(1), filter(function (country) { return country === SupportedCountry.GB; })).subscribe(function (country) {
            _this.setFormControlsEnabledState(!!_this.item.get('companyName').value);
            _this.reactOnCompanyNumberValueChanges(country);
        }));
    };
    GuarantorItemComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
        this.chamberOfCommerceName.unsubscribe();
    };
    GuarantorItemComponent.prototype.produceFormGroup = function (val) {
        return GuarantorItemComponent.produceFormGroup(val);
    };
    GuarantorItemComponent.prototype.reactOnCompanyNumberValueChanges = function (country) {
        var _this = this;
        this.subscriptions.add(this.item.get('companyNumber').valueChanges.pipe(filter(function (value) { return !!value; }), debounceTime(300)).subscribe(function (value) {
            _this.setFormControlsEnabledState(false);
            _this.store.dispatch(new ChamberOfCommerceNameLoad({ id: value, country: country }));
            _this.patchFormAfterLoad(value);
        }));
    };
    GuarantorItemComponent.prototype.patchFormAfterLoad = function (value) {
        var _this = this;
        this.chamberOfCommerceName.unsubscribe();
        this.chamberOfCommerceName =
            this.store.pipe(select(selectChamberOfCommerceNameResult({ id: value })), filter(function (result) { return !!result; })).subscribe(function (result) {
                _this.setFormControlsEnabledState(true);
                if (result.companyName) {
                    _this.item.get('companyName').patchValue(result.companyName);
                }
            });
    };
    GuarantorItemComponent.prototype.setFormControlsEnabledState = function (enabled) {
        var _this = this;
        setTimeout(function () {
            var nameControl = _this.item.get('companyName');
            enabled ? nameControl.enable() : nameControl.disable();
        });
    };
    return GuarantorItemComponent;
}(RepeatableItem));
export { GuarantorItemComponent };
