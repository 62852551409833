<h2 mat-dialog-title>{{ 'administration.users.add-user' | translate}}</h2>
<mat-dialog-content>

  <div class="notice warning" *ngIf="showNoRolesWarning">
    <i class="material-icons">warning</i>
    <p>{{ 'administration.users.warning-company-has-no-roles' | translate }}</p>
  </div>

  <form [formGroup]="form">

    <div class="pure-g">
      <div class="pure-u-1-2">
        <mat-form-field floatLabel="always">
          <input matInput placeholder="{{ 'administration.user.name' | translate}}" [formControl]="form.controls['name']"/>
          <mat-error *ngIf="form.hasError('required', ['name'])">{{ 'generic.validation.required' | translate}}</mat-error>
        </mat-form-field>
      </div>
      <div class="pure-u-1-2">
        <mat-form-field floatLabel="always">
          <input matInput placeholder="{{ 'administration.user.email' | translate}}" [formControl]="form.controls['email']" type="email"/>
          <mat-error *ngIf="form.hasError('required', ['email'])">{{ 'generic.validation.required' | translate}}</mat-error>
          <mat-error *ngIf="form.hasError('email', ['email'])">{{ 'generic.validation.email' | translate}}</mat-error>
        </mat-form-field>
      </div>
    </div>

    <mat-dialog-actions>
      <caple-loading-wrapper key="urn:user:create" [diameter]="36">
        <button mat-button (click)="closeModal()">{{ 'generic.cancel' | translate }}</button>
        <button mat-button (click)="createUser()">
          {{'administration.users.add-user' | translate }}
        </button>
      </caple-loading-wrapper>
    </mat-dialog-actions>
  </form>
</mat-dialog-content>
