import { actions, EntityAction } from '../util/actions';
import { Preferences } from '../../shared/api/models/preferences';
import { typeFor } from '../util/util';
import { SLICE } from './slice';

export type State = Preferences;

export const initialState: State = {
  locale: undefined,
  country: undefined
};

export function reducer(state = initialState, action: EntityAction): State {
  switch (action.type) {
    case typeFor(SLICE.PREFERENCES, actions.LOAD_SINGLE_SUCCESS): {
      return action.payload;
    }
    default:
      return state;
  }
}
