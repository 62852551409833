import { EventEmitter, OnDestroy } from '@angular/core';
import { CreateSingle, LoadSingle, UpdateSingle } from '../state-management/util/actions';
import { SLICE } from './state-management/slice';
import { select } from '@ngrx/store';
import { selectEligibility, selectEligibilityEditable, selectEligibilityIdByCorrelationId } from './state-management/selector';
import { filter, take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import * as empty from 'deep-empty-object';
var EligibilityParent = /** @class */ (function () {
    function EligibilityParent(store) {
        this.store = store;
        this.onCreated = new EventEmitter();
        this.subscriptions = new Subscription();
        this.loaded = false;
        this.errors = [];
        this.notices = [];
        this.failedControls = [];
        this.checked = false;
        this.editable = true;
    }
    Object.defineProperty(EligibilityParent.prototype, "country", {
        get: function () {
            return this._country;
        },
        set: function (value) {
            this._country = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EligibilityParent.prototype, "eligibilityId", {
        get: function () {
            return this._eligibilityId;
        },
        set: function (value) {
            var _this = this;
            this._eligibilityId = value;
            if (this.eligibilityId) {
                this.store.dispatch(new LoadSingle(SLICE.ELIGIBILITY, { id: this.eligibilityId }));
                this.store.pipe(select(selectEligibility({ id: this.eligibilityId })), filter(function (eligibility) { return !!eligibility; }), take(1))
                    .subscribe(function (eligibility) {
                    _this.patchEligibility(eligibility);
                    _this.loaded = true;
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    EligibilityParent.prototype.checkEligibility = function () {
        this.errors = [];
        this.notices = [];
        this.failedControls = [];
        this.validateEligibility();
        this.checked = true;
    };
    EligibilityParent.prototype.onFormChanged = function () {
        var _this = this;
        this.subscriptions.add(this.form.valueChanges.subscribe(function () {
            _this.checked = false;
        }));
    };
    EligibilityParent.prototype.setFormDisableSetting = function () {
        var _this = this;
        this.subscriptions.add(this.store.pipe(select(selectEligibilityEditable)).subscribe(function (isEditable) {
            if (!isEditable) {
                _this.form.disable();
            }
        }));
    };
    EligibilityParent.prototype.submitEligibilityData = function (type) {
        var _this = this;
        this.setFormSettingsForValidation();
        if (this.form.valid) {
            this.checkEligibility();
            var eligibilityCheck = {
                data: this.generateEligibilityData(),
                errors: this.errors,
                eligibilityCheckType: type
            };
            if (this.eligibilityId) {
                this.store.dispatch(new UpdateSingle(SLICE.ELIGIBILITY, {
                    id: this.eligibilityId,
                    eligibilityCheck: eligibilityCheck
                }));
            }
            else {
                var correlationId = new Date().getUTCMilliseconds().toString();
                this.store.dispatch(new CreateSingle(SLICE.ELIGIBILITY, {
                    eligibilityCheck: eligibilityCheck,
                    correlationId: correlationId
                }));
                this.store.pipe(select(selectEligibilityIdByCorrelationId(correlationId)), filter(function (value) { return !!value; }), take(1))
                    .subscribe(function (value) {
                    _this.eligibilityId = value;
                    _this.onCreated.next({ id: value });
                });
            }
        }
    };
    EligibilityParent.prototype.setFormSettingsForValidation = function () {
        var _this = this;
        Object.keys(this.form.controls).forEach(function (field) {
            var control = _this.form.get(field);
            control.updateValueAndValidity();
            control.markAsTouched({ onlySelf: true });
        });
    };
    EligibilityParent.prototype.isFailedControl = function (controlName) {
        return this.checked && this.failedControls.includes(controlName);
    };
    EligibilityParent.prototype.generateEligibilityData = function () {
        var _this = this;
        var formData = this.form.getRawValue();
        var eligibilityData = {};
        Object.keys(formData).forEach(function (field) {
            if (formData[field] !== null && formData[field] !== undefined) {
                eligibilityData[field] = { value: formData[field], valid: !_this.isFailedControl(field) };
            }
        });
        return eligibilityData;
    };
    EligibilityParent.prototype.validateQuestion = function (key, correctAnswer) {
        var result = this.checkQuestion(key, correctAnswer);
        if (!result) {
            this.addError(key, [key]);
        }
        return result;
    };
    EligibilityParent.prototype.checkQuestion = function (key, correctAnswer) {
        var answer = this.form.controls[key].value;
        if (answer !== correctAnswer) {
            return false;
        }
        return true;
    };
    EligibilityParent.prototype.addError = function (criteria, controls) {
        this.errors.push(criteria);
        this.failedControls = this.failedControls.concat(controls);
    };
    EligibilityParent.prototype.patchEligibilityCheck = function (eligibilityCheck) {
        var _this = this;
        if (eligibilityCheck) {
            var formData_1 = {};
            var data_1 = eligibilityCheck.data;
            Object.keys(data_1).forEach(function (key) {
                formData_1[key] = data_1[key].value;
                if (!data_1[key].valid) {
                    _this.failedControls.push(key);
                }
            });
            this.form.patchValue(formData_1);
            this.errors = eligibilityCheck.errors.slice();
            this.checkEligibility();
        }
    };
    EligibilityParent.prototype.formHasUnsavedChanges = function () {
        return !this.checked && !empty.all(this.form.getRawValue());
    };
    EligibilityParent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    return EligibilityParent;
}());
export { EligibilityParent };
