/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./product-type-selector-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./product-type-selector.component.ngfactory";
import * as i5 from "./product-type-selector.component";
import * as i6 from "@ngrx/store";
import * as i7 from "@angular/common";
import * as i8 from "../../../../shared/layout/loading-wrapper/loading-wrapper.component.ngfactory";
import * as i9 from "../../../../shared/layout/loading-wrapper/loading-wrapper.component";
import * as i10 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i11 from "@angular/material/button";
import * as i12 from "@angular/cdk/a11y";
import * as i13 from "@angular/platform-browser/animations";
import * as i14 from "./product-type-selector-dialog.component";
var styles_ProductTypeSelectorDialogComponent = [i0.styles];
var RenderType_ProductTypeSelectorDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductTypeSelectorDialogComponent, data: {} });
export { RenderType_ProductTypeSelectorDialogComponent as RenderType_ProductTypeSelectorDialogComponent };
export function View_ProductTypeSelectorDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 16, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "caple-product-type-selector", [], null, [[null, "onTypeChanged"], [null, "onValidationChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onTypeChanged" === en)) {
        var pd_0 = ((_co.product = $event) !== false);
        ad = (pd_0 && ad);
    } if (("onValidationChanged" === en)) {
        var pd_1 = ((_co.isValid = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_ProductTypeSelectorComponent_0, i4.RenderType_ProductTypeSelectorComponent)), i1.ɵdid(7, 180224, null, 0, i5.ProductTypeSelectorComponent, [i6.Store], { data: [0, "data"] }, { onTypeChanged: "onTypeChanged", onValidationChanged: "onValidationChanged" }), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 11, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(10, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 9, "caple-loading-wrapper", [["class", "buttons"], ["key", "urn:funding-process:product-type:update"]], null, null, null, i8.View_LoadingWrapperComponent_0, i8.RenderType_LoadingWrapperComponent)), i1.ɵdid(12, 49152, null, 0, i9.LoadingWrapperComponent, [i6.Store], { diameter: [0, "diameter"], key: [1, "key"] }, null), (_l()(), i1.ɵeld(13, 0, null, 0, 3, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_MatButton_0, i10.RenderType_MatButton)), i1.ɵdid(14, 180224, null, 0, i11.MatButton, [i1.ElementRef, i12.FocusMonitor, [2, i13.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(15, 0, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(17, 0, null, 0, 3, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeProductType() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_MatButton_0, i10.RenderType_MatButton)), i1.ɵdid(18, 180224, null, 0, i11.MatButton, [i1.ElementRef, i12.FocusMonitor, [2, i13.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵted(19, 0, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_2 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(_co.existingProduct$)); _ck(_v, 7, 0, currVal_2); var currVal_3 = 36; var currVal_4 = "urn:funding-process:product-type:update"; _ck(_v, 12, 0, currVal_3, currVal_4); var currVal_10 = !_co.isValid; _ck(_v, 18, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("funding-process.product-type.dialog.title")); _ck(_v, 2, 0, currVal_1); var currVal_5 = (i1.ɵnov(_v, 14).disabled || null); var currVal_6 = (i1.ɵnov(_v, 14)._animationMode === "NoopAnimations"); _ck(_v, 13, 0, currVal_5, currVal_6); var currVal_7 = i1.ɵunv(_v, 15, 0, i1.ɵnov(_v, 16).transform("generic.cancel")); _ck(_v, 15, 0, currVal_7); var currVal_8 = (i1.ɵnov(_v, 18).disabled || null); var currVal_9 = (i1.ɵnov(_v, 18)._animationMode === "NoopAnimations"); _ck(_v, 17, 0, currVal_8, currVal_9); var currVal_11 = i1.ɵunv(_v, 19, 0, i1.ɵnov(_v, 20).transform("generic.save")); _ck(_v, 19, 0, currVal_11); }); }
export function View_ProductTypeSelectorDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "caple-product-type-selector-dialog", [], null, null, null, View_ProductTypeSelectorDialogComponent_0, RenderType_ProductTypeSelectorDialogComponent)), i1.ɵdid(1, 49152, null, 0, i14.ProductTypeSelectorDialogComponent, [i6.Store, i2.MAT_DIALOG_DATA], null, null)], null, null); }
var ProductTypeSelectorDialogComponentNgFactory = i1.ɵccf("caple-product-type-selector-dialog", i14.ProductTypeSelectorDialogComponent, View_ProductTypeSelectorDialogComponent_Host_0, {}, {}, []);
export { ProductTypeSelectorDialogComponentNgFactory as ProductTypeSelectorDialogComponentNgFactory };
