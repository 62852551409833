import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { State } from '../../state-management/reducers';
import { Store } from '@ngrx/store';
import { selectHasRole } from '../../state-management/login/selector';
import { UnauthorizedAccess } from '../../state-management/login/actions';
import { Role } from '../api/models/role';

@Injectable()
export class AuthorizationGuard implements CanActivate {

  constructor(private store: Store<State>) {
  }

  /**
   * Dispatch UnauthorizedAccess if the user doesn't have at least one of the required roles.
   */
  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.store.getSync(selectHasRole(Role.DEVOPS))) {
      return true;
    }

    const requiredRoles = !!route && route.data ? route.data.requiredRoles : [];
    const hasRole: boolean = requiredRoles.filter(value => this.store.getSync(selectHasRole(value)) === true).length > 0;

    if (!hasRole) {
      this.store.dispatch(new UnauthorizedAccess());
    }

    return hasRole;
  }
}
