import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { RepeatableItem } from '../../../../../../shared/layout/repeatable-form-item/repeatable-item';
import { CapleFormBuilder } from '../../../../../../shared/util/caple-form-builder';
var CapitalExpenditureCovenantItemComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CapitalExpenditureCovenantItemComponent, _super);
    function CapitalExpenditureCovenantItemComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.removed = new EventEmitter();
        return _this;
    }
    CapitalExpenditureCovenantItemComponent.produceFormGroup = function (val) {
        val = val || {};
        return new CapleFormBuilder().group({
            'year': [val.year, [Validators.required]],
            'value': [val.value, Validators.required]
        });
    };
    CapitalExpenditureCovenantItemComponent.prototype.produceFormGroup = function (val) {
        return CapitalExpenditureCovenantItemComponent.produceFormGroup(val);
    };
    return CapitalExpenditureCovenantItemComponent;
}(RepeatableItem));
export { CapitalExpenditureCovenantItemComponent };
