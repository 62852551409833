import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { registerLocaleData } from '@angular/common';
import localeNL from '@angular/common/locales/nl';
import localeGB from '@angular/common/locales/en-GB';
import { enableAutoRefresh } from './shared/announcement/announcement-refresh.service';
import { initialize } from './analytics.service';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http);
}
registerLocaleData(localeGB, 'en-GB');
registerLocaleData(localeNL, 'nl-NL');
var ɵ0 = enableAutoRefresh, ɵ1 = initialize;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1 };
