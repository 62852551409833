/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./tooltip-dialog.component";
import * as i8 from "@ngrx/store";
var styles_TooltipDialogComponent = [];
var RenderType_TooltipDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TooltipDialogComponent, data: {} });
export { RenderType_TooltipDialogComponent as RenderType_TooltipDialogComponent };
export function View_TooltipDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(1, 81920, null, 0, i1.MatDialogTitle, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], null, null), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 9, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i0.ɵdid(5, 16384, null, 0, i1.MatDialogContent, [], null, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(8, 0, null, null, 5, "mat-dialog-actions", [["align", "end"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i0.ɵdid(9, 16384, null, 0, i1.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(10, 0, null, null, 3, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i0.ɵdid(11, 180224, null, 0, i4.MatButton, [i0.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵted(12, 0, ["", ""])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.title)); _ck(_v, 2, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform(_co.message)); _ck(_v, 6, 0, currVal_2); var currVal_3 = (i0.ɵnov(_v, 11).disabled || null); var currVal_4 = (i0.ɵnov(_v, 11)._animationMode === "NoopAnimations"); _ck(_v, 10, 0, currVal_3, currVal_4); var currVal_5 = i0.ɵunv(_v, 12, 0, i0.ɵnov(_v, 13).transform("generic.close")); _ck(_v, 12, 0, currVal_5); }); }
export function View_TooltipDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "caple-tooltip-dialog", [], null, null, null, View_TooltipDialogComponent_0, RenderType_TooltipDialogComponent)), i0.ɵdid(1, 49152, null, 0, i7.TooltipDialogComponent, [i8.Store, i1.MAT_DIALOG_DATA], null, null)], null, null); }
var TooltipDialogComponentNgFactory = i0.ɵccf("caple-tooltip-dialog", i7.TooltipDialogComponent, View_TooltipDialogComponent_Host_0, {}, {}, []);
export { TooltipDialogComponentNgFactory as TooltipDialogComponentNgFactory };
