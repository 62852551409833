import { EventEmitter, OnDestroy } from '@angular/core';
import { ProductType } from '../../../../shared/api/models/productType';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { selectCountry } from '../../../../state-management/preferences/selector';
import { ProductSubType } from '../../../../shared/api/models/productSubType';
var ProductTypeSelectorComponent = /** @class */ (function () {
    function ProductTypeSelectorComponent(store) {
        var _this = this;
        this.store = store;
        this.onTypeChanged = new EventEmitter();
        this.onValidationChanged = new EventEmitter();
        this.subscription = new Subscription();
        this.country$ = store.pipe(select(selectCountry));
        this.form = new FormBuilder().group({});
        this.subscription.add(this.form.statusChanges.subscribe(function (status) {
            _this.onValidationChanged.emit(status === 'VALID');
        }));
        this.subscription.add(this.country$.subscribe(function (value) {
            _this.countryString = value;
        }));
        this.subscription.add(this.form.valueChanges.pipe(map(function (form) { return form.loanSize; }), filter(function (value) { return !!value; })).subscribe(function (loanSize) {
            _this.onTypeChanged.emit({
                productType: ProductType.GROWTH,
                productSubType: loanSize > 1500000 ? ProductSubType.GT15M : ProductSubType.LTE15M,
                loanSize: loanSize
            });
        }));
    }
    Object.defineProperty(ProductTypeSelectorComponent.prototype, "data", {
        set: function (value) {
            if (value) {
                if (value.productType) {
                    switch (value.productType) {
                        case ProductType.TRANSACTION:
                            this.activeSubType = value.productSubType;
                            this.setActive('transaction');
                            break;
                        case ProductType.GROWTH:
                            this.setActive('growth');
                            break;
                        case ProductType.ACQUISITION:
                            this.setActive('acquisition');
                            break;
                        case ProductType.MBI:
                            this.setActive('mbi');
                            break;
                        default:
                            console.error('Unknown product type: ' + value.productType);
                            break;
                    }
                }
                this.startLoanSize = value.loanSize;
                this.form.patchValue({ 'loanSize': value.loanSize });
            }
        },
        enumerable: true,
        configurable: true
    });
    ProductTypeSelectorComponent.prototype.changed = function ($event) {
        if ($event.value === 'MBO' || $event.value === 'OTHER') {
            this.setActive('transaction');
            this.transactionTypeChanged($event);
        }
        else {
            this.setActive($event.value);
        }
    };
    ProductTypeSelectorComponent.prototype.transactionTypeChanged = function ($event) {
        this.activeSubType = $event.value;
        this.onTypeChanged.emit({ productType: ProductType.TRANSACTION, productSubType: $event.value });
    };
    ProductTypeSelectorComponent.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    };
    ProductTypeSelectorComponent.prototype.setActive = function (type) {
        this.activeType = type;
        this.form.removeControl('loanSize');
        this.form.removeControl('productSubType');
        switch (type) {
            case 'growth':
                this.form.addControl('loanSize', new FormControl(this.startLoanSize, [Validators.required, Validators.min(500000), Validators.max(5000000)]));
                break;
            case 'transaction':
                if (this.countryString === 'NL') {
                    this.form.addControl('productSubType', new FormControl(this.activeSubType, [Validators.required]));
                }
                break;
            case 'acquisition':
                this.onTypeChanged.emit({ productType: ProductType.ACQUISITION });
                break;
            case 'mbi':
                // Note: need to emit a subtype because the backend needs a subtype to generate a financial rating.
                this.onTypeChanged.emit({ productType: ProductType.MBI, productSubType: ProductSubType.MBI });
                break;
            default:
                console.error('Unknown product type: ' + type);
                break;
        }
        this.onValidationChanged.emit(this.form.valid);
    };
    return ProductTypeSelectorComponent;
}());
export { ProductTypeSelectorComponent };
