import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { AbstractApiService } from './abstract-api.service';
var ChamberOfCommerceService = /** @class */ (function (_super) {
    tslib_1.__extends(ChamberOfCommerceService, _super);
    function ChamberOfCommerceService(httpClient) {
        var _this = _super.call(this, httpClient) || this;
        _this.baseUrl = '/api/v1/chamber-of-commerce/';
        return _this;
    }
    ChamberOfCommerceService.prototype.getCompany = function (companyNumber) {
        return this.post(this.baseUrl, { companyNumber: companyNumber.id });
    };
    ChamberOfCommerceService.prototype.getCompanyName = function (payload) {
        return this.post(this.baseUrl + 'name', { companyNumber: payload.id, country: payload.country });
    };
    return ChamberOfCommerceService;
}(AbstractApiService));
export { ChamberOfCommerceService };
