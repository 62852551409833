import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { actions, LoadSingleSuccess } from '../util/actions';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { typeFor } from '../util/util';
import { SLICE } from './slice';
import { PreferencesService } from '../../shared/api/services/preferences.service';
import { Effects } from '../util/effect-functions';
import { TranslateService } from '@ngx-translate/core';
import { Preferences } from '../../shared/api/models/preferences';

@Injectable()
export class PreferencesEffects {

  @Effect()
  loadPreferences$ = this.effects
    .loadSingleWithoutID(SLICE.PREFERENCES, 'urn:preferences:load', {
      functionToExecute: this.preferencesService.getPreferences,
      scope: this.preferencesService
    });

  @Effect({dispatch: false})
  preferencesLoaded$ = this.actions$
    .pipe(
      ofType(typeFor(SLICE.PREFERENCES, actions.LOAD_SINGLE_SUCCESS)),
      tap((action: LoadSingleSuccess<Preferences>) => {
        this.translateService.setDefaultLang(action.payload.locale);
        this.translateService.use(action.payload.locale);
      }));

  constructor(private actions$: Actions,
              private effects: Effects,
              private preferencesService: PreferencesService,
              private translateService: TranslateService) {
  }
}
