import { interval, Subscription } from 'rxjs';

import { withLatestFrom } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { State } from '../../state-management/reducers';
import { selectAccessToken } from '../../state-management/login/selector';
import { environment } from '../../../environments/environment';
import { config } from '../../../environments/config';
import { AccessTokenRenewed, AccessTokenRenewFailed, Logout } from '../../state-management/login/actions';
import { TokenRenewUrl } from '../../login/login.routing';
import { Auth0Factory } from '../../login/login-form/auth0-factory.service';
import { AccessToken } from './access-token';

@Injectable()
export class TokenRenewalService implements OnDestroy {

  private SERVER_TIME_COMPENSATION_MINUTES = 5;

  private accessTokenSubscription: Subscription;

  constructor(private store: Store<State>, private auth0Factory: Auth0Factory) {
  }

  public enableAutoRenewal() {
    const webAuth = this.auth0Factory.createInstance();
    let renewalTriedAndFailed = false;

    if (this.accessTokenSubscription) {
      this.accessTokenSubscription.unsubscribe();
    }

    this.accessTokenSubscription =
      interval(2000).pipe(
        withLatestFrom(this.store.pipe(select(selectAccessToken))))
        .subscribe(([_, accessToken]) => {

          if (accessToken && accessToken instanceof AccessToken) {
            let timeTillTokenExpires = this.compensateServerTimeDifference(accessToken.getExpiresIn());

            if (!renewalTriedAndFailed && timeTillTokenExpires < 0 && accessToken.isNotExpired()) {
              // We put it to true here because the checkSession is async and we don't want
              // the logic to be called multiple times if the function is still executing
              renewalTriedAndFailed = true;

              webAuth.checkSession({
                scope: config.scope,
                audience: config.audience,
                redirectUri: config.baseUrl + TokenRenewUrl
              }, (error, authResult) => {
                if (error) {
                  this.store.dispatch(new AccessTokenRenewFailed(error.error, error.error_description));
                } else {
                  renewalTriedAndFailed = false;
                  this.store.dispatch(new AccessTokenRenewed(authResult.accessToken));
                }
              });
            }

            if (!accessToken.isNotExpired()) {
              this.store.dispatch(new Logout());
              renewalTriedAndFailed = false;
            }
          }

        });
  }

  public ngOnDestroy(): void {
    if (this.accessTokenSubscription) {
      this.accessTokenSubscription.unsubscribe();
    }
  }

  /**
   * Compensate for potential difference between server and local time by subtracting X minutes from the potential expiration time.
   * In case we can't refresh the token, this will give the user a bit of time to manually refresh the token before he is automatically
   * logged out and losses all his current work.
   */
  private compensateServerTimeDifference(input: number): number {
    return input - (this.SERVER_TIME_COMPENSATION_MINUTES * 60);
  }

}

export function enableAutoRenewal(tokenRenewalService: TokenRenewalService) {
  return () => {
    if (!environment.disableTimers) {
      tokenRenewalService.enableAutoRenewal();
    }
  };
}
