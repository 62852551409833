import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpUploadProgressEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AbstractApiService } from './abstract-api.service';
import { Id } from '../models/id';
import { KYCFile } from '../models/kYCFile';
import { FileUploaded } from '../models/fileUploaded';
import { WithId } from '../../../state-management/util/actions';
import { ChapterType } from '../models/chapterType';
import { ChapterItemType } from '../models/chapterItemType';
import { FileDetails } from '../models/fileDetails';
import { Decision } from '../models/decision';
import { LatestKYCVersion } from '../models/latestKYCVersion';
import { KYCFileStatusOverview } from '../models/kYCFileStatusOverview';
import { KYCBankingInformationPayload } from '../../../kyc/state-management/actions';
import { KYCParams } from '../models/kYCParams';

export interface ResourceId extends WithId {
  resourceId: string;
}

export interface ItemPayload extends WithId {
  chapter: ChapterType;
  item: ChapterItemType;
}

export interface FilePayload extends ItemPayload {
  fileId: string;
  discussionId?: string;
}

export interface FilePayloadWithName extends ItemPayload {
  fileDetails: FileDetails;
}

export interface AssessFilePayload extends FilePayload {
  decision: Decision;
}

export interface AssessItemPayload extends ItemPayload {
  completed: boolean;
}

export interface KYCPackPayload extends WithId {
  client: string;
}

@Injectable()
export class KycService extends AbstractApiService {
  private readonly baseUrl = '/api/v1/kyc';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getById(id: Id): Observable<KYCFile> {
    return this.get<KYCFile>(`${this.baseUrl}/${id.id}`);
  }

  public getStatusById(id: Id) {
    return this.get<KYCFileStatusOverview>(`${this.baseUrl}/${id.id}/status`);
  }

  public downloadKYCPack(id: KYCPackPayload) {
    return this.getFileWithProgress(`${this.baseUrl}/${id.id}/zip`);
  }

  public downloadResource(id: ResourceId): Observable<HttpEvent<Blob>> {
    return this.getFileWithProgress(`${this.baseUrl}/${id.id}/resources/${id.resourceId}`);
  }

  public uploadFile(id: Id, file: File): [Observable<FileUploaded>, Observable<HttpUploadProgressEvent>] {
    const formData = new FormData();
    formData.append('file', file);
    return this.postFile(`${this.baseUrl}/${id.id}/files`, formData);
  }

  public attachFile(payload: FilePayloadWithName) {
    return this.post(`${this.baseUrl}/${payload.id}/${payload.chapter}/${payload.item}`, payload.fileDetails);
  }

  public downloadFile(payload: FilePayloadWithName) {
    return this.getFileWithProgress(`${this.baseUrl}/${payload.id}/${payload.chapter}/${payload.item}
    /${payload.fileDetails.fileId}`);
  }

  public removeFile(payload: FilePayload) {
    return this.delete(`${this.baseUrl}/${payload.id}/${payload.chapter}/${payload.item}/${payload.fileId}`);
  }

  public assessFile(payload: AssessFilePayload) {
    return this.post(`${this.baseUrl}/${payload.id}/${payload.chapter}/${payload.item}/${payload.fileId}/assessment`, {
      decision: payload.decision
    });
  }

  public assessItem(payload: AssessItemPayload) {
    return this.post(`${this.baseUrl}/${payload.id}/${payload.chapter}/${payload.item}/assessment`, {
      completed: payload.completed
    });
  }

  public updateBankingInformation(payload: KYCBankingInformationPayload) {
    return this.post(`${this.baseUrl}/${payload.id}/banking-information`, payload);
  }

  public completeKYC(id: Id) {
    return this.post(`${this.baseUrl}/${id.id}/complete`, null);
  }

  public reopenKYC(id: Id) {
    return this.post(`${this.baseUrl}/${id.id}/reopen`, null);
  }

  public upgradeKYC(id: Id) {
    return this.post(`${this.baseUrl}/${id.id}/upgrade`, null);
  }

  public getLatestVersion(params: KYCParams): Observable<LatestKYCVersion> {
    return this.get<KYCFile>(`${this.baseUrl}/${params.country}/${params.product}/latest`);
  }
}
