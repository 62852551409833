var _a;
import { createSelector } from '@ngrx/store';
import { discussionAdapter, selectFeature } from './reducer';
var ɵ0 = function (state) { return state.discussions; };
var selectDiscussionSlice = createSelector(selectFeature, ɵ0);
/////////////
//// Selectors
/////////////
/** KYC **/
export var selectDiscussionIsLoaded = (_a = discussionAdapter.getSelectors(selectDiscussionSlice), _a.selectSingleIsLoaded), selectDiscussion = _a.selectSingle;
export var selectDiscussionIsUnread = function (id) { return createSelector(selectDiscussion(id), function (discussion) {
    return discussion && discussion.unreadComments;
}); };
export { ɵ0 };
