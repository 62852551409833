import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiErrorInterceptor } from './api-error.interceptor';

import { ClientService } from './services/client.service';
import { FundingProcessService } from './services/funding-process.service';
import { CompanyService } from './services/company.service';
import { MonitoringFilesService } from './services/monitoring-files.service';
import { PreferencesService } from './services/preferences.service';
import { KycService } from './services/kyc.service';
import { DiscussionService } from './services/discussion.service';
import { AnnouncementService } from './services/announcement.service';
import { FeedbackService } from './services/feedback.service';
import { AuthModule } from '../authentication/auth.module';
import { KnowledgeService } from './services/knowledge.service';
import { EligibilityService } from './services/eligibility.service';
import { ChamberOfCommerceService } from './services/chamber-of-commerce.service';
import { ConditionsPrecedentService } from './services/conditions-precedent.service';
import { AssessmentService } from './services/assessment.service';
import { ChangelogService } from './services/changelog.service';

@NgModule({
  imports: [
    AuthModule,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ApiErrorInterceptor, multi: true},
    ClientService,
    FundingProcessService,
    CompanyService,
    ChangelogService,
    MonitoringFilesService,
    PreferencesService,
    KycService,
    DiscussionService,
    AnnouncementService,
    FeedbackService,
    KnowledgeService,
    EligibilityService,
    ChamberOfCommerceService,
    ConditionsPrecedentService,
    AssessmentService
  ]
})
export class ApiModule {
}
