<input #fileInput type="file" (change)="fileChanged($event)" name="file" accept="{{getAcceptedFileExtensionsString()}}" style="display: none;" [multiple]="multiple">

<span [matTooltip]="getTooltipText()" [class.caple-upload-button]="isPrimaryButton"
      [class.caple-upload-button-textarea]="isTextareaButton" [matTooltipPosition]="'below'">
  <button *ngIf="!isIconOnlyButton" mat-button type="button" (click)="openFileInput()"
          class="mat-primary-button"
          [class.mat-action-button]="isActionButton" [class.mat-secondary-button]="isSecondaryButton"
          [disabled]="disabled">
    <i class="material-icons rotated" *ngIf="isIconButton() && iconBeforeText">{{matIcon}}</i>
    <span>{{label}}</span>
    <i class="material-icons rotated" *ngIf="isIconButton() && !iconBeforeText">{{matIcon}}</i>
  </button>
  <span *ngIf="isIconOnlyButton && !disabled" mat-button class="action" (click)="openFileInput()">
    <i class="material-icons">attach_file</i>
  </span>
</span>
