import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'caple-eligibility-criteria-number-input',
  templateUrl: './eligibility-criteria-number-input.component.html'
})
export class EligibilityCriteriaNumberInputComponent {

  @Input()
  public formControlItem: FormControl;

  @Input()
  public countryForCurrency: string;

}

