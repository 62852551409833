import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {RepeatableItem} from '../../../../../shared/layout/repeatable-form-item/repeatable-item';
import {MatExpansionPanel} from '@angular/material/expansion';
import {KYCContact} from '../../../../../shared/api/models/kYCContact';
import {CapleFormBuilder} from '../../../../../shared/util/caple-form-builder';
import {CapleFormGroup} from '../../../../../shared/util/caple-form';
import RelatedPersonCategoryEnum = KYCContact.RelatedPersonCategoryEnum;

@Component({
  selector: 'caple-contact-item',
  templateUrl: './contact-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['contact-item.component.scss']
})
export class ContactItemComponent extends RepeatableItem implements OnInit {

  public relatedPersonCategories = Object.values(RelatedPersonCategoryEnum);

  @Input()
  public item: FormGroup;

  @Input()
  public showRemove: boolean;

  @Input()
  public index: number;

  @Output()
  public removed: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(MatExpansionPanel, {static: true})
  public expansionPanel: MatExpansionPanel;

  public static produceFormGroup(val?: any): CapleFormGroup {
    val = val || {};
    return new CapleFormBuilder().group({
      'firstName': [val.firstName, Validators.required],
      'lastName': [val.lastName, Validators.required],
      'nationality': [val.nationality, Validators.required],
      'birthDate': [val.birthDate, Validators.required],
      'placeOfBirth': [val.placeOfBirth, Validators.required],
      'countryOfTaxation': [val.countryOfTaxation],
      'countryOfResidency': [val.countryOfResidency],
      'percentageOfControlOrVotingRights': [val.percentageOfControlOrVotingRights],
      'relatedPersonCategory': [val.relatedPersonCategory],
      'politicallyExposedPerson': [val.politicallyExposedPerson],
      'highRiskPoliticallyExposedPerson': [val.highRiskPoliticallyExposedPerson],
      'politicallyExposedPersonFunction': [val.politicallyExposedPersonFunction],
      'politicallyExposedPersonCountryOfActivity': [val.politicallyExposedPersonCountryOfActivity],
      'reason': [val.reason],
    });
  }

  public produceFormGroup(val?: any): FormGroup {
    return ContactItemComponent.produceFormGroup(val);
  }

  public ngOnInit(): void {
    this.openOnStart(this.expansionPanel);
  }
}
