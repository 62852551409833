import { FileSaverService } from './file-saver.service';
import { NgModule } from '@angular/core';
import { HumanizeDatePipe } from './humanize-date.pipe';

@NgModule({
  declarations: [
    HumanizeDatePipe
  ],
  exports: [
    HumanizeDatePipe
  ],
  providers: [
    FileSaverService
  ]
})
export class UtilModule {
}
