<div class="go-no-go">
  <div class="badge" [class.failed]="errors.length > 0" [class.passed]="errors.length === 0">
    <div class="vertical-align" *ngIf="errors.length === 0">
      <div>
        <span>{{passedText}}<a *ngIf="link && linkText" [routerLink]="link" routerLinkActive="active"><span class="link-text">{{linkText}}</span></a></span>
        <div *ngFor="let notice of notices" [innerHTML]="'eligibility-criteria.notices.' + notice| translate" class="eligibility-result-notice"></div>
      </div>
    </div>
    <div class="flex" *ngIf="errors.length > 0">
      <div>
        <div class="result"><span>{{'eligibility-criteria.failed'| translate}}</span></div>
      <ul class="errors">
        <li *ngFor="let error of errors">{{'eligibility-criteria.criteria.' + error| translate}}</li>
      </ul>
      <div class="result postfix"><span>{{'eligibility-criteria.failed-additional'| translate}}</span></div>
      </div>
    </div>
  </div>
</div>
