import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { State } from '../../../state-management/reducer';
import { CloseModal } from '../../../../state-management/layout/actions';
import { LoadAll, LoadSingle, WithId } from '../../../../state-management/util/actions';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { ReadCompany } from '../../../../shared/api/models/readCompany';
import { selectAllCompanies, selectMonitoringFile, selectUsersForCompany } from '../../../state-management/selector';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SLICE } from '../../../state-management/slice';
import { AddReportingSource } from '../../../state-management/actions';
import { ReadUser } from '../../../../shared/api/models/readUser';
import { filter, map } from 'rxjs/operators';
import { Table } from '../../../../state-management/util/adapters';

@Component({
  selector: 'caple-reporting-source-dialog',
  templateUrl: './add-reporting-source-dialog.component.html'
})
export class AddReportingSourceDialogComponent implements OnInit, OnDestroy {

  public companies$: Observable<ReadCompany[]>;
  public users$: Observable<ReadUser[]>;
  public form: FormGroup;
  public subscriptions: Subscription = new Subscription();

  private fileId: string;

  constructor(private store: Store<State>, private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) private data: WithId) {
    this.fileId = data.id;

    this.store.dispatch(new LoadAll(SLICE.COMPANY));
    this.companies$ = combineLatest([
      this.store.pipe(select(selectMonitoringFile({id: this.fileId}))),
      this.store.pipe(select(selectAllCompanies))])
      .pipe(
        filter(([file, companies]) => companies.length > 0),
        map(([file, companies]) => {
          file.reportingSources.forEach(reportingSource => {
            companies = companies.filter(company => company.id !== reportingSource.company.id);
          });
          return companies;
        })
      );


    this.form = formBuilder.group({
      'reportingSource': formBuilder.group({
        'reportingSourceId': ['', Validators.required],
        'reportingSourceUsers': [null]
      })
    });
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.form.get('reportingSource.reportingSourceId').valueChanges.subscribe(value => {
        this.form.get('reportingSource.reportingSourceUsers').setValue([]);
        const reportingSourceId = this.form.get('reportingSource.reportingSourceId').value;
        this.store.dispatch(new LoadSingle(SLICE.COMPANY_USERS, {id: reportingSourceId}));
        this.users$ = this.store.pipe(select(selectUsersForCompany(reportingSourceId)),
          filter(table => !!table),
          map((table: Table<ReadUser>) => {
            return Object.values(table.entities);
          }));
      })
    );
  }

  public closeModal() {
    this.store.dispatch(new CloseModal());
  }

  public addReportingSource() {
    if (this.form.valid) {
      this.store.dispatch(
        new AddReportingSource(
          {
            id: this.fileId,
            reportingSource: this.form.controls['reportingSource'].value
          }
        )
      );
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
