import * as tslib_1 from "tslib";
import { EntityAction } from '../util/actions';
import { SLICE } from './slice';
export var LayoutActions;
(function (LayoutActions) {
    LayoutActions["SHOW_TOAST"] = "[Toast] Show Toast";
    LayoutActions["OPEN_MODAL"] = "[Modal] Open modal";
    LayoutActions["CLOSE_MODAL"] = "[Modal] Close modal";
    LayoutActions["MODAL_OPENED"] = "[Modal] Modal Opened";
    LayoutActions["MODAL_CLOSED"] = "[Modal] Modal Closed";
    LayoutActions["MODAL_DISABLE_CLOSE"] = "[Modal] Modal Disable Close";
    LayoutActions["MODAL_ENABLE_CLOSE"] = "[Modal] Modal Enable Close";
    LayoutActions["SHOW_LOADING_INDICATOR"] = "[Loading] Show indicator";
    LayoutActions["HIDE_LOADING_INDICATOR"] = "[Loading] Hide indicator";
    LayoutActions["SHOW_PROGRESS_INDICATOR"] = "[Progress] Show indicator";
    LayoutActions["PROGRESS_INDICATOR_OPENED"] = "[Progress] indicator opened";
    LayoutActions["HIDE_PROGRESS_INDICATOR"] = "[Progress] Hide indicator";
    LayoutActions["PROGRESS_INDICATOR_CLOSED"] = "[Progress] indicator closed";
    LayoutActions["UPDATE_PROGRESS_INDICATOR"] = "[Progress] Update indicator";
    LayoutActions["FILE_UPLOAD_FAILED"] = "[Upload] File upload failed";
    LayoutActions["FILE_DOWNLOAD_FAILED"] = "[Upload] File download failed";
    LayoutActions["SCROLL_TO"] = "[Scroll] Scroll to";
    LayoutActions["MARK_CHANGELOG_AS_READ"] = "MARK_CHANGELOG_AS_READ";
    LayoutActions["MARK_CHANGELOG_AS_READ_SUCCESS"] = "MARK_CHANGELOG_AS_READ_SUCCESS";
})(LayoutActions || (LayoutActions = {}));
var OpenModal = /** @class */ (function () {
    function OpenModal(payload, componentName, config, data) {
        this.payload = payload;
        this.componentName = componentName;
        this.config = config;
        this.data = data;
        this.type = LayoutActions.OPEN_MODAL;
    }
    return OpenModal;
}());
export { OpenModal };
var ModalOpened = /** @class */ (function () {
    function ModalOpened() {
        this.type = LayoutActions.MODAL_OPENED;
    }
    return ModalOpened;
}());
export { ModalOpened };
var CloseModal = /** @class */ (function () {
    function CloseModal() {
        this.type = LayoutActions.CLOSE_MODAL;
    }
    return CloseModal;
}());
export { CloseModal };
var ModalClosed = /** @class */ (function () {
    function ModalClosed() {
        this.type = LayoutActions.MODAL_CLOSED;
    }
    return ModalClosed;
}());
export { ModalClosed };
var ModalDisableClose = /** @class */ (function () {
    function ModalDisableClose() {
        this.type = LayoutActions.MODAL_DISABLE_CLOSE;
    }
    return ModalDisableClose;
}());
export { ModalDisableClose };
var ModalEnableClose = /** @class */ (function () {
    function ModalEnableClose() {
        this.type = LayoutActions.MODAL_ENABLE_CLOSE;
    }
    return ModalEnableClose;
}());
export { ModalEnableClose };
var ShowToast = /** @class */ (function () {
    function ShowToast(message, interpolateParams) {
        this.message = message;
        this.interpolateParams = interpolateParams;
        this.type = LayoutActions.SHOW_TOAST;
    }
    return ShowToast;
}());
export { ShowToast };
var ShowLoadingIndicator = /** @class */ (function () {
    function ShowLoadingIndicator(key) {
        this.key = key;
        this.type = LayoutActions.SHOW_LOADING_INDICATOR;
    }
    return ShowLoadingIndicator;
}());
export { ShowLoadingIndicator };
var HideLoadingIndicator = /** @class */ (function () {
    function HideLoadingIndicator(key) {
        this.key = key;
        this.type = LayoutActions.HIDE_LOADING_INDICATOR;
    }
    return HideLoadingIndicator;
}());
export { HideLoadingIndicator };
var ShowProgressIndicator = /** @class */ (function () {
    function ShowProgressIndicator(translationKey, translationData) {
        this.translationKey = translationKey;
        this.translationData = translationData;
        this.type = LayoutActions.SHOW_PROGRESS_INDICATOR;
    }
    return ShowProgressIndicator;
}());
export { ShowProgressIndicator };
var ProgressIndicatorOpened = /** @class */ (function () {
    function ProgressIndicatorOpened() {
        this.type = LayoutActions.PROGRESS_INDICATOR_OPENED;
    }
    return ProgressIndicatorOpened;
}());
export { ProgressIndicatorOpened };
var HideProgressIndicator = /** @class */ (function () {
    function HideProgressIndicator() {
        this.type = LayoutActions.HIDE_PROGRESS_INDICATOR;
    }
    return HideProgressIndicator;
}());
export { HideProgressIndicator };
var ProgressIndicatorClosed = /** @class */ (function () {
    function ProgressIndicatorClosed() {
        this.type = LayoutActions.PROGRESS_INDICATOR_CLOSED;
    }
    return ProgressIndicatorClosed;
}());
export { ProgressIndicatorClosed };
var UpdateProgressIndicator = /** @class */ (function () {
    function UpdateProgressIndicator(percentage, loaded, total) {
        this.percentage = percentage;
        this.loaded = loaded;
        this.total = total;
        this.type = LayoutActions.UPDATE_PROGRESS_INDICATOR;
    }
    return UpdateProgressIndicator;
}());
export { UpdateProgressIndicator };
var FileUploadFailed = /** @class */ (function () {
    function FileUploadFailed(payload) {
        this.payload = payload;
        this.type = LayoutActions.FILE_UPLOAD_FAILED;
    }
    return FileUploadFailed;
}());
export { FileUploadFailed };
var FileDownloadFailed = /** @class */ (function () {
    function FileDownloadFailed(payload) {
        this.payload = payload;
        this.type = LayoutActions.FILE_DOWNLOAD_FAILED;
    }
    return FileDownloadFailed;
}());
export { FileDownloadFailed };
var ScrollTo = /** @class */ (function () {
    function ScrollTo(payload) {
        this.payload = payload;
        this.type = LayoutActions.SCROLL_TO;
    }
    return ScrollTo;
}());
export { ScrollTo };
var MarkChangelogAsRead = /** @class */ (function (_super) {
    tslib_1.__extends(MarkChangelogAsRead, _super);
    function MarkChangelogAsRead() {
        var _this = _super.call(this, SLICE.CHANGELOG) || this;
        _this.actionName = LayoutActions.MARK_CHANGELOG_AS_READ;
        return _this;
    }
    return MarkChangelogAsRead;
}(EntityAction));
export { MarkChangelogAsRead };
var MarkChangelogAsReadSuccess = /** @class */ (function (_super) {
    tslib_1.__extends(MarkChangelogAsReadSuccess, _super);
    function MarkChangelogAsReadSuccess() {
        var _this = _super.call(this, SLICE.CHANGELOG) || this;
        _this.actionName = LayoutActions.MARK_CHANGELOG_AS_READ_SUCCESS;
        return _this;
    }
    return MarkChangelogAsReadSuccess;
}(EntityAction));
export { MarkChangelogAsReadSuccess };
