import { tap } from 'rxjs/operators';
/**
 File to place generic effects that would otherwise have to be duplicated
 in multiple modules
 **/
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { GenericActions, RedirectTo, RedirectToWithParameters } from './actions';
import { Router } from '@angular/router';

@Injectable()
export class GenericEffects {

  @Effect({dispatch: false})
  redirectTo$ = this.actions$
    .pipe(
      ofType(GenericActions.REDIRECT_TO),
      tap((action: RedirectTo) => {
        if (action.relativeToCurrentPath) {
          this.router.navigate(action.url, {relativeTo: this.deepestActivatedRoute()});
        } else {
          this.router.navigate(action.url);
        }
      }));

  @Effect({dispatch: false})
  redirectToWithParameters$ = this.actions$
    .pipe(
      ofType(GenericActions.REDIRECT_TO_WITH_PARAMETERS),
      tap((action: RedirectToWithParameters) => {
        if (action.relativeToCurrentPath) {
          this.router.navigate(action.url, {queryParams: action.params, relativeTo: this.deepestActivatedRoute()});
        } else {
          this.router.navigate(action.url, {queryParams: action.params});
        }
      }));

  constructor(private actions$: Actions, private router: Router) {
  }

  /**
   * Its currently not possible to get the ActivatedRoute in a effect without either A) Storing it in the global state of B) Passing it with
   * each action. Both of these solutions are, for their own reasons, not preferred. This method returns the deepestActivedRoute by scanning
   * through the Router. This is far from optimal and should be replaced once it is possible to get the ActivatedRoute from the global context.
   *
   * This is a function and not a getter because the Redux Dev tools crashes if this is a getter as it tries
   * to serialize this getter with a different `this` context.
   *
   * @returns {ActivatedRoute}
   */
  private deepestActivatedRoute() {
    let route = this.router.routerState.root.firstChild;

    while (route.firstChild) {
      route = route.firstChild;
    }

    return route;
  }
}
