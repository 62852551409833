import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RepeatableItem } from '../../../../../../shared/layout/repeatable-form-item/repeatable-item';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CapleFormGroup } from '../../../../../../shared/util/caple-form';
import { CapleFormBuilder } from '../../../../../../shared/util/caple-form-builder';
import { FundingSource } from '../../../../../../shared/api/models/fundingSource';
import { GrowthFundingSourceCategory } from '../../../../../../shared/api/models/growthFundingSourceCategory';


interface ItemValue {
  readonly source: GrowthFundingSourceCategory;
  readonly amount: number;
  readonly comment: string;
}

@Component({
  selector: 'caple-company-funding-source-item',
  templateUrl: './company-funding-source-item.component.html',
})
export class CompanyFundingSourceItemComponent extends RepeatableItem {
  @Input()
  public item: FormGroup;

  @Input()
  public index: number;

  @Input()
  public showRemove: boolean;

  @Input()
  public snapShotState: FundingSource;

  @Input()
  public translationGroup: CapleFormGroup;

  @Output()
  public removed: EventEmitter<void> = new EventEmitter<void>();


  public FundingSourcesCategory = Object.keys(GrowthFundingSourceCategory).filter(k => k.indexOf('_') === -1).map(k => {
    return {
      key: k,
      value: GrowthFundingSourceCategory[k]
    };
  });

  public static produceFormGroup(itemValueOpt?: ItemValue): CapleFormGroup {
    const val = itemValueOpt || {source: null, amount: '', comment: ''};
    return new CapleFormBuilder().group({
      'source': [val.source, Validators.required],
      'amount': [val.amount, [Validators.required, Validators.min(0)]],
      'comment': val.comment
    });
  }

  public produceFormGroup(val?: any): FormGroup | FormControl {
    return CompanyFundingSourceItemComponent.produceFormGroup(val);
  }

  public equalToSnapShot(val: any): boolean {
    return this.item.get('source').value === val.source;
  }
}
