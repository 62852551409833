import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../state-management/reducers';
import { Logout } from '../../state-management/login/actions';

@Component({
  selector: 'caple-logout',
  template: '',
})
export class LogoutComponent {
  constructor(private store: Store<State>) {
    this.store.dispatch(new Logout());
  }
}
