import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpParams, HttpUploadProgressEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AbstractApiService } from './abstract-api.service';
import { Id } from '../models/id';
import { FundingProcess } from '../models/fundingProcess';
import { MetaModel } from '../models/metaModel';
import { Phase } from '../models/phase';
import { FundingProposal } from '../models/fundingProposal';
import { ClientId } from '../models/clientId';
import { FinancialModel } from '../models/financialModel';
import { FileUploaded } from '../models/fileUploaded';
import { FinancialRatingSummary } from '../models/financialRatingSummary';
import { FinancialRatingCreate } from '../models/financialRatingCreate';
import { FinancialRating } from '../models/financialRating';
import { FinancialRatingUpdate } from '../models/financialRatingUpdate';
import { WithId, WithIdAndVersion, WithIdVersionAndProduct } from '../../../state-management/util/actions';

import {
  AssignFundingProcessPayload,
  ExportToExcelPayload,
  MoveUploadPayload,
  ReviewUploadPayload,
  UpdateOwnerUsersPayload,
  UpdateProductTypePayload,
  UpdateUploadsCategoriesPayload
} from '../../../origination/state-management/actions';
import { MetaModelIdentifier } from '../models/metaModelIdentifier';
import { Team } from '../models/team';
import { Product } from '../models/product';
import { StartFundingProcess } from '../models/startFundingProcess';
import { Page } from '../models/page';
import { PageRequest } from '../models/pageRequest';

export interface FinancialRatingId extends WithId {
  fundingProcessId: string;
}

export interface CreateFinancialRatingPayload {
  fundingProcessId: string;
  product: Product;
  financialRating: FinancialRatingCreate;
}

export interface SaveFinancialRatingPayload extends FinancialRatingId {
  financialRating: FinancialRatingUpdate;
  translationKey: string;
}

export interface ImportPayload extends WithIdVersionAndProduct {
  file: File;
}

export interface ImportFinancialRatingPayload extends FinancialRatingId, ImportPayload {
}

export interface UpdateFundingProposalPayload extends WithIdVersionAndProduct {
  fundingProposal: FundingProposal;
}

@Injectable()
export class FundingProcessService extends AbstractApiService {
  private readonly baseUrlV1 = `/api/v1/funding-processes`;

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getAll(payload?: PageRequest): Observable<Page> {
    if (payload) {
      return this.post<Page>(this.baseUrlV1 + '/page', payload).pipe(
        map(fundingProcesses => {
            const newList = fundingProcesses.content.map(fundingProcess => this.normalize(fundingProcess));
            return {
              content: newList,
              totalElements: fundingProcesses.totalElements
            };
        }
        ));
    }
  }

  public getSummary(payload: WithId): Observable<FundingProcess> {
    return this.get<FundingProcess>(`${this.getSingleFundingProcessUrl(payload)}/summary`).pipe(
      map(fundingProcess =>
        this.normalize(fundingProcess)
      ));
  }

  public getAllForMonitoring(): Observable<Page> {
    const params = new HttpParams()
      .append('phase', 'COMPLETED')
      .append('hasMonitoringFile', 'false');
    const dummyPageRequest: PageRequest = {page: 0, limit: 20, filter: {}, sort: {}};

    return this.postWithParams<Page>(this.baseUrlV1 + '/page', dummyPageRequest, params).pipe(
      map(fundingProcesses => {
          const newList = fundingProcesses.content.map(fundingProcess => this.normalize(fundingProcess));
          return {
            content: newList,
            totalElements: fundingProcesses.totalElements
          };
        }
      ));
  }

  public create(payload: StartFundingProcess): Observable<ClientId> {
    return this.post<ClientId>(this.baseUrlV1, payload);
  }

  public updateProductType(payload: UpdateProductTypePayload) {
    return this.post<void>(`${this.getSingleFundingProcessUrl(payload)}/product-type`, {
      productType: payload.productType,
      productSubType: payload.productSubType,
      loanSize: payload.loanSize,
    } as Product);
  }

  public deleteFundingProcess(id: Id): Observable<void> {
    return this.delete<void>(this.getSingleFundingProcessUrl(id));
  }

  public getSingle(payload: WithIdAndVersion): Observable<FundingProcess> {
    return this.get<FundingProcess>(this.getSingleFundingProcessUrlByVersion(payload)).pipe(
      map(fundingProcess => this.normalize(fundingProcess)));
  }

  public pinFundingProcess(id: Id) {
    return this.post(this.baseUrlV1 + '/' + id.id + '/pin', null);
  }

  public unpinFundingProcess(id: Id) {
    return this.post(this.baseUrlV1 + '/' + id.id + '/unpin', null);
  }

  public assignFundingProcess(id: AssignFundingProcessPayload) {
    return this.post(this.baseUrlV1 + '/' + id.id + '/assigned-credit-officer', null);
  }

  public unAssignFundingProcess(id: Id) {
    return this.delete(this.baseUrlV1 + '/' + id.id + '/assigned-credit-officer');
  }

  public getFinancialRatingMetaModel(id: Id): Observable<MetaModel> {
    return this.get<MetaModel>(this.getSingleFundingProcessUrl(id) + '/financial-ratings/meta-model');
  }

  public getFundingProposalMetaModel(payload: WithIdVersionAndProduct): Observable<MetaModel> {
    return this.get<MetaModel>(this.getFundingProposalUrl(payload) + '/meta-model');
  }

  public getFundingProposalAsPdf(payload: WithIdVersionAndProduct): Observable<HttpEvent<Blob>> {
    return this.getFileWithProgress(this.getFundingProposalUrl(payload) + '/summary');
  }

  public downloadFundingProposalResource(id: { id, version, product, type }): Observable<HttpEvent<Blob>> {
    return this.getFileWithProgress(this.getFundingProposalUrl(id as WithIdVersionAndProduct) + '/resources/' + id.type);
  }

  public downloadFundingProposalUploadsZip(payload: WithIdVersionAndProduct) {
    return this.getFileWithProgress(this.getFundingProposalUrl(payload) + '/uploads/zip');
  }

  public saveFundingProposal(payload: UpdateFundingProposalPayload) {
    return this.post(this.getFundingProposalUrl(payload) + '/draft', payload.fundingProposal);
  }

  public submitFundingProposal(payload: UpdateFundingProposalPayload) {
    return this.post(this.getFundingProposalUrl(payload) + '/submitted', payload.fundingProposal);
  }

  public snapshotFundingProposal(payload: WithId) {
    return this.post(this.getSingleFundingProcessUrl(payload) + '/snapshot', null);
  }

  public getFundingProposalSnapshot(payload: WithIdAndVersion) {
    return this.get(this.getSingleFundingProcessUrlByVersion(payload) + '/snapshot');
  }

  public translateFundingProposal(payload: WithIdVersionAndProduct) {
    return this.post(this.getFundingProposalUrl(payload) + '/translate', null);
  }

  public getFundingProposalTranslation(payload: WithIdVersionAndProduct) {
    return this.get(this.getFundingProposalUrl(payload) + '/translate');
  }

  public saveFundingProposalTranslation(payload: WithIdVersionAndProduct) {
    return this.post(this.getFundingProposalUrl(payload) + '/translate/edit', payload);
  }

  public getFundingProposalAsTranslatedPdf(payload: WithIdVersionAndProduct): Observable<HttpEvent<Blob>> {
    return this.getFileWithProgress(this.getFundingProposalUrl(payload) + '/translate/summary');
  }

  public getFundingProcessFinancialsAsExcel(payload: ExportToExcelPayload) {
    return this.getFileWithProgress(this.getFundingProposalUrl(payload) + '/financials/excel' + (payload.language ? '?language=' + payload.language : ''));
  }

  public getFundingProcessScoringAsExcel(payload: ExportToExcelPayload) {
    return this.getFileWithProgress(this.getFundingProposalUrl(payload) + '/scoring/excel');
  }

  public getLatestFundingProposalModelVersion(payload: WithIdVersionAndProduct) {
    return this.get<MetaModelIdentifier>(this.getFundingProposalUrl(payload) + '/meta-model/upgrade');
  }

  public upgradeFundingProposalModelVersion(payload: WithIdVersionAndProduct) {
    return this.post(this.getFundingProposalUrl(payload) + '/meta-model/upgrade', null);
  }

  public overrideFundingProposalFinancialData(payload: ImportPayload) {
    const formData = new FormData();
    formData.append('file', payload.file);

    return this.postFile(this.getFundingProposalUrl(payload) + '/financial-data/override', formData)[0];
  }

  public importFundingProcessFinancialsExcel(payload: ImportPayload): [Observable<FinancialModel>, Observable<HttpUploadProgressEvent>] {
    const formData = new FormData();
    formData.append('file', payload.file);
    return this.postFile(this.getFundingProposalUrl(payload) + '/financials/extract-financials', formData);
  }

  public createFinancialRating(payload: CreateFinancialRatingPayload): Observable<Id> {
    return this.post(this.getSingleFundingProcessUrl({id: payload.fundingProcessId}) + '/financial-ratings', payload.financialRating);
  }

  public deleteFinancialRating(id: FinancialRatingId): Observable<void> {
    return this.delete(this.getSingleFundingProcessUrl({id: id.fundingProcessId}) + '/financial-ratings/' + id.id);
  }

  public getFinancialRatings(id: Id): Observable<FinancialRatingSummary[]> {
    return this.get(this.getSingleFundingProcessUrl(id) + '/financial-ratings');
  }

  public getFinancialRating(id: FinancialRatingId): Observable<FinancialRating> {
    return this.get(this.getSingleFundingProcessUrl({id: id.fundingProcessId}) + '/financial-ratings/' + id.id);
  }

  public saveFinancialRating(payload: SaveFinancialRatingPayload): Observable<FinancialRatingSummary> {
    return this.post(this.getSingleFundingProcessUrl({id: payload.fundingProcessId}) + '/financial-ratings/' + payload.id, payload.financialRating);
  }

  public getFinancialRatingAsExcel(id: FinancialRatingId): Observable<HttpEvent<Blob>> {
    return this.getFileWithProgress(this.getSingleFundingProcessUrl({id: id.fundingProcessId}) + '/financial-ratings/' + id.id + '/excel');
  }

  public importFinancialRatingExcel(payload: ImportFinancialRatingPayload): [Observable<FinancialModel>, Observable<HttpUploadProgressEvent>] {
    const formData = new FormData();
    formData.append('file', payload.file);

    return this.postFile(this.getSingleFundingProcessUrl({id: payload.fundingProcessId}) + '/financial-ratings/extract-financials', formData);
  }

  public uploadFile(payload: WithIdVersionAndProduct, file: File): [Observable<FileUploaded>, Observable<HttpUploadProgressEvent>] {
    const formData = new FormData();
    formData.append('file', file);

    return this.postFile(this.getFundingProposalUrl(payload) + '/draft/files', formData);
  }

  public downloadFile(payload) {
    return this.getFileWithProgress(this.getFundingProposalUrl(payload) + '/draft/files/' + payload.file.fileId);
  }

  public loadTeam(id: WithId): Observable<Team> {
    return this.get(this.getSingleFundingProcessUrl(id) + '/team');
  }

  public submitToBnp(id: WithId) {
    return this.post(this.getSingleFundingProcessUrl(id) + '/bnp/submit', null);
  }

  public updateUploadsCategories(payload: UpdateUploadsCategoriesPayload) {
    return this.post(`${this.getFundingProposalUrl(payload)}/uploads/update-categorisation`, payload);
  }

  public updateUploadsReview(payload: ReviewUploadPayload) {
    return this.post(`${this.getFundingProposalUrl(payload)}/uploads/update-review-status`, payload);
  }

  public moveUpload(payload: MoveUploadPayload) {
    return this.post(`${this.getFundingProposalUrl(payload)}/uploads/move`, payload);
  }

  public updateOwner(payload: UpdateOwnerUsersPayload) {
    return this.post(`${this.getSingleFundingProcessUrl(payload)}/update-owner`, payload);
  }

  private normalize(fundingProcess: FundingProcess) {
    if (!fundingProcess.phase) {
      fundingProcess.phase = Phase.UNKNOWN;
    }
    return fundingProcess;
  }

  private getSingleFundingProcessUrl(id: Id) {
    return this.baseUrlV1 + '/' + id.id;
  }

  private getSingleFundingProcessUrlByVersion(payload: WithIdAndVersion) {
    return `/api/v${payload.version}/funding-processes/${payload.id}`;
  }

  private getFundingProposalUrl(payload: WithIdVersionAndProduct) {
    const product = payload.product.toLowerCase();
    return `/api/v${payload.version}/funding-processes/${payload.id}/funding-proposal/${product}`;
  }
}
