import { createSelector } from '@ngrx/store';
import { eligibilityAdapter, selectFeature, State } from './reducer';
import { selectHasOneOfRoles } from '../../state-management/login/selector';
import { Role } from '../../shared/api/models/role';

const selectEligibilityChecksSlice = createSelector(selectFeature, (state: State) => state.eligibilityChecks);

/////////////
//// Selectors
/////////////


/** Eligibility **/
export const {
  selectSingleIsLoaded: selectEligibilityIsLoaded,
  selectSingle: selectEligibility
} = eligibilityAdapter.getSelectors(selectEligibilityChecksSlice);

export const selectEligibilityIdByCorrelationId = (corrId: string) => createSelector(selectFeature, (state) => state.correlationIdToEligibilityId[corrId]);
export const selectEligibilityEditable = selectHasOneOfRoles([Role.PARTNER, Role.DIRECTCLIENT]);
