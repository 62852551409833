import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { AbstractApiService } from './abstract-api.service';
var EligibilityService = /** @class */ (function (_super) {
    tslib_1.__extends(EligibilityService, _super);
    function EligibilityService(httpClient) {
        var _this = _super.call(this, httpClient) || this;
        _this.baseUrl = '/api/v1/eligibility';
        return _this;
    }
    EligibilityService.prototype.getById = function (id) {
        return this.get(this.baseUrl + "/" + id.id);
    };
    EligibilityService.prototype.saveEligibility = function (payload) {
        return this.post(this.baseUrl, payload.eligibilityCheck);
    };
    EligibilityService.prototype.updateEligibility = function (payload) {
        return this.post(this.baseUrl + "/" + payload.id, payload.eligibilityCheck);
    };
    EligibilityService.prototype.connectEligibility = function (payload) {
        return this.post(this.baseUrl + "/" + payload.id + "/connect", payload.connectEligibility);
    };
    return EligibilityService;
}(AbstractApiService));
export { EligibilityService };
