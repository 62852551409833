import { ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, NgControl } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { Subject } from 'rxjs';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { selectLocale } from '../../../state-management/preferences/selector';
import { select, Store } from '@ngrx/store';
import { Locale } from '../../api/models/locale';
import { SupportedCountry } from '../../api/models/supportedCountry';
import { getDigitsInfo, hasDecimals } from './currency-input-util';
var CurrencyInputComponent = /** @class */ (function () {
    function CurrencyInputComponent(formBuilder, focusMonitor, elementRef, store, decimalPipe, ngControl) {
        var _this = this;
        this.focusMonitor = focusMonitor;
        this.elementRef = elementRef;
        this.store = store;
        this.decimalPipe = decimalPipe;
        this.ngControl = ngControl;
        /** Dutch thousand-separator **/
        this.DOT = '\\.'; // As this is used as a regular expr., we have to escape the dot
        /** GB thousand-separator **/
        this.COMMA = ',';
        /** Current active thousand-separator **/
        this.thousandSeparator = this.COMMA;
        /** Current active locale **/
        this.locale = 'en-GB';
        /** Place holders for ControlValueAccessor **/
        this._onChange = function (val) {
        };
        this._onTouched = function () {
        };
        /** Implemented as part of MatFormFieldControl. */
        this.id = "caple-currency-input-" + CurrencyInputComponent.nextId++;
        /** Implemented as part of MatFormFieldControl. */
        this.describedBy = '';
        /** Implemented as part of MatFormFieldControl. */
        this.controlType = 'caple-currency-input';
        /** Implemented as part of MatFormFieldControl. */
        this.stateChanges = new Subject();
        this._value = null;
        this._placeholder = '';
        this._required = false;
        this._disabled = false;
        this._focused = false;
        if (this.ngControl != null) {
            // Setting the value accessor directly (instead of using the providers) to
            // avoid running into a circular import
            this.ngControl.valueAccessor = this;
        }
        this.focusSubscription = focusMonitor.monitor(elementRef.nativeElement, true).subscribe(function (origin) {
            _this.focused = !!origin;
            _this.stateChanges.next();
        });
        this.form = formBuilder.group({
            'amount': ''
        });
        // Start listening to changes on the input so we can act on them
        this.formValueSubscription = this.form.valueChanges
            .subscribe(function (formValues) {
            _this._value = _this.parseNumber(formValues.amount);
            _this._onChange(_this._value);
            _this.stateChanges.next();
        });
    }
    Object.defineProperty(CurrencyInputComponent.prototype, "value", {
        get: function () {
            return this._value;
        },
        /**
         * Implemented as part of MatFormFieldControl.
         *
         * This is called when someone sets the value of this component; patch uses this.
         *
         * For type safety, we have to say that this method only accepts number | null. In reality
         * strings are also accepted but as parsed to a number immediately.
         * */
        set: function (value) {
            if (typeof value !== 'number') {
                value = this.parseNumber(value);
            }
            this._value = value;
            this.form.patchValue({ amount: value }, { emitEvent: false });
            this.updateNumberView();
            this.stateChanges.next();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrencyInputComponent.prototype, "placeholder", {
        get: function () {
            return this._placeholder;
        },
        /** Implemented as part of MatFormFieldControl. */
        set: function (placeholder) {
            this._placeholder = placeholder;
            this.stateChanges.next();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrencyInputComponent.prototype, "required", {
        get: function () {
            return this._required;
        },
        /** Implemented as part of MatFormFieldControl. */
        set: function (req) {
            this._required = coerceBooleanProperty(req);
            this.stateChanges.next();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrencyInputComponent.prototype, "disabled", {
        get: function () {
            if (this.ngControl && this.ngControl.disabled !== null) {
                return this.ngControl.disabled;
            }
            return this._disabled;
        },
        /** Implemented as part of MatFormFieldControl. */
        set: function (req) {
            this._disabled = coerceBooleanProperty(req);
            if (this._disabled) {
                this.form.disable({ emitEvent: false });
            }
            else {
                this.form.enable({ emitEvent: false });
            }
            // Browsers may not fire the blur event if the input is disabled too quickly.
            // Reset from here to ensure that the element doesn't become stuck.
            if (this.focused) {
                this.focused = false;
                this.stateChanges.next();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrencyInputComponent.prototype, "focused", {
        get: function () {
            return this._focused;
        },
        /** Implemented as part of MatFormFieldControl. */
        set: function (val) {
            if (this._focused && val === false) { // 'onBlur', mark as touched
                this._onTouched();
            }
            this._focused = val;
            this.updateNumberView();
            this.stateChanges.next();
        },
        enumerable: true,
        configurable: true
    });
    /**
     * After initialisation, setup the locale, thousand separator and allowed characters
     */
    CurrencyInputComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.localeSubscription = this.store.pipe(select(selectLocale))
            .subscribe(function (locale) {
            _this.locale = locale;
            switch (locale) {
                case Locale.NlNL:
                    _this.country = _this.country ? _this.country : SupportedCountry.NL;
                    _this.thousandSeparator = _this.DOT;
                    _this.allowedCharacters = new RegExp('^[0-9\-' + _this.COMMA + ']$');
                    break;
                default:
                    _this.country = _this.country ? _this.country : SupportedCountry.GB;
                    _this.thousandSeparator = _this.COMMA;
                    _this.allowedCharacters = new RegExp('^[0-9\-' + _this.DOT + ']$');
            }
        });
    };
    Object.defineProperty(CurrencyInputComponent.prototype, "empty", {
        /** Implemented as part of MatFormFieldControl. */
        get: function () {
            var amount = this.form.value.amount;
            return amount === null || amount === undefined || amount === '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrencyInputComponent.prototype, "shouldLabelFloat", {
        /** Implemented as part of MatFormFieldControl. */
        get: function () {
            return this.focused || !this.empty;
        },
        enumerable: true,
        configurable: true
    });
    /** Prevents the input of non-numerical characters except for the . and , corresponding with the locale **/
    CurrencyInputComponent.prototype.onKeyPress = function (event) {
        if (!this.allowedCharacters.test(event.key)) {
            event.preventDefault();
        }
    };
    Object.defineProperty(CurrencyInputComponent.prototype, "errorState", {
        /** Implemented as part of MatFormFieldControl. */
        get: function () {
            var _this = this;
            var currentErrorState = this.ngControl && this.ngControl.touched && this.ngControl.errors != null;
            if (currentErrorState != this._errorState) {
                setTimeout(function () { return _this.stateChanges.next(); });
                this._errorState = currentErrorState;
            }
            return currentErrorState;
        },
        enumerable: true,
        configurable: true
    });
    /** Implemented as part of MatFormFieldControl. */
    CurrencyInputComponent.prototype.onContainerClick = function (event) {
        this.elementRef.nativeElement.querySelector('input').focus();
    };
    /** Implemented as part of MatFormFieldControl. */
    CurrencyInputComponent.prototype.setDescribedByIds = function (ids) {
        this.describedBy = ids.join(' ');
    };
    CurrencyInputComponent.prototype.ngOnDestroy = function () {
        this.stateChanges.complete();
        this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
        if (this.localeSubscription) {
            this.localeSubscription.unsubscribe();
            this.localeSubscription = null;
        }
        if (this.focusSubscription) {
            this.focusSubscription.unsubscribe();
            this.focusSubscription = null;
        }
        if (this.formValueSubscription) {
            this.formValueSubscription.unsubscribe();
            this.formValueSubscription = null;
        }
    };
    /** Implemented as part of ControlValueAccessor */
    CurrencyInputComponent.prototype.registerOnChange = function (fn) {
        this._onChange = fn;
    };
    /** Implemented as part of ControlValueAccessor */
    CurrencyInputComponent.prototype.registerOnTouched = function (fn) {
        this._onTouched = fn;
    };
    /** Implemented as part of ControlValueAccessor */
    CurrencyInputComponent.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
    };
    /** Implemented as part of ControlValueAccessor */
    CurrencyInputComponent.prototype.writeValue = function (obj) {
        this.value = obj;
    };
    /**
     * This will overwrite the value in the amountField with the correct value.
     *
     * If focused; show a plain number.
     * If not-focused: show a formatted number according to the users locale
     *
     * When the user clicks somewhere in the input field, he wants his cursor to start where he clicked. However,
     * because we change the input after clicking, the cursor gets place at the end. This function also fixes this
     * by placing the cursor where the user expects it, taking into account the offset for removing dots/comma's
     * from the un-formatted number view.
     */
    CurrencyInputComponent.prototype.updateNumberView = function () {
        var _this = this;
        if (this.amountField && this.value) {
            // This change has to happen after the change detection cycle so angular doesn't
            // overwrite the value we are setting here
            setTimeout(function () {
                var nativeElement = _this.amountField.nativeElement;
                if (_this.focused) {
                    var oldValue = nativeElement.value;
                    // Store the current carrot position so we can restore it
                    var carrotPosition = nativeElement.selectionStart;
                    // Overwite the value
                    nativeElement.value = _this.value;
                    // if the value has only 1 decimal, append a 0 to make it the same as the un-focused value
                    if (_this.hasSingleDecimal(_this.value)) {
                        nativeElement.value += '0';
                    }
                    // Restore the carrot position, taking into account the offset for removing dots and comma's
                    var relevantValuePart = oldValue.toString().substring(0, carrotPosition);
                    var amountOfThousandSeparators = (relevantValuePart.match(new RegExp(_this.thousandSeparator, 'g')) || []).length;
                    var newCaretPosition = carrotPosition - amountOfThousandSeparators;
                    nativeElement.setSelectionRange(newCaretPosition, newCaretPosition);
                }
                else {
                    var value = _this.parseNumber(_this.value);
                    var digitInfo = getDigitsInfo(value);
                    nativeElement.value = _this.decimalPipe.transform(value, digitInfo, _this.locale);
                }
            });
        }
    };
    CurrencyInputComponent.prototype.hasSingleDecimal = function (value) {
        return hasDecimals(value) && value.toFixed(2) !== value.toLocaleString();
    };
    CurrencyInputComponent.prototype.parseNumber = function (input) {
        if (input === null || input === undefined) {
            return null;
        }
        if (typeof input === 'number') {
            return input;
        }
        if (this.thousandSeparator === this.DOT) {
            input = input.replace(',', '.');
        }
        var result = Number.parseFloat(input);
        if (isNaN(result)) {
            return null;
        }
        else {
            return result;
        }
    };
    /** Next ID for the mat-form-field **/
    CurrencyInputComponent.nextId = 0;
    return CurrencyInputComponent;
}());
export { CurrencyInputComponent };
