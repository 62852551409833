import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { AbstractApiService } from './abstract-api.service';
import { ReportingFrequency } from '../models/reportingFrequency';
var MonitoringFilesService = /** @class */ (function (_super) {
    tslib_1.__extends(MonitoringFilesService, _super);
    function MonitoringFilesService(httpClient) {
        var _this = _super.call(this, httpClient) || this;
        _this.baseUrl = '/api/v1/monitoring-files';
        return _this;
    }
    MonitoringFilesService.prototype.getAll = function () {
        return this.get(this.baseUrl);
    };
    MonitoringFilesService.prototype.create = function (create) {
        return this.post(this.baseUrl, create);
    };
    MonitoringFilesService.prototype.getSingle = function (id) {
        return this.get(this.baseUrl + '/' + id.id);
    };
    MonitoringFilesService.prototype.getFinancials = function (payload) {
        var queryParams = '';
        if (payload.language || payload.version) {
            queryParams = this.buildURLQueryParameters({ language: payload.language, version: payload.version });
        }
        var frequency = this.mapReportingFrequencyAttribute(payload.reportingFrequency);
        return this.getFileWithProgress(this.baseUrl + "/" + payload.id + "/documents/" + payload.startYear + "/" + payload.reportingPeriodNumber + "/" + frequency + "/financials" + queryParams);
    };
    MonitoringFilesService.prototype.getCertificate = function (payload) {
        var queryParams = '';
        if (payload.version) {
            queryParams = this.buildURLQueryParameters({ version: payload.version });
        }
        var frequency = this.mapReportingFrequencyAttribute(payload.reportingFrequency);
        return this.getFileWithProgress(this.baseUrl + "/" + payload.id + "/documents/" + payload.startYear + "/" + payload.reportingPeriodNumber + "/" + frequency + "/certificate" + queryParams);
    };
    MonitoringFilesService.prototype.getFinancialsTemplate = function (payload) {
        return this.getFileWithProgress(this.baseUrl + "/" + payload.id + "/documents/financials-template");
    };
    MonitoringFilesService.prototype.getAllReportingDocumentsForMonitoringFile = function (id) {
        return this.get(this.baseUrl + "/" + id.id + "/documents");
    };
    MonitoringFilesService.prototype.importFinancialsExcel = function (payload) {
        var formData = new FormData();
        formData.append('file', payload.file);
        return this.postFile(this.baseUrl + "/" + payload.id + "/documents/financials-extract", formData);
    };
    MonitoringFilesService.prototype.uploadFile = function (id, file) {
        var formData = new FormData();
        formData.append('file', file);
        return this.postFile(this.baseUrl + "/" + id + "/documents/files", formData);
    };
    MonitoringFilesService.prototype.submitFinancials = function (payload) {
        return this.post(this.baseUrl + "/" + payload.id + "/documents/financials", payload);
    };
    MonitoringFilesService.prototype.submitCertificate = function (payload) {
        return this.post(this.baseUrl + "/" + payload.id + "/documents/certificate", payload);
    };
    MonitoringFilesService.prototype.resubmitFinancials = function (payload) {
        return this.post(this.baseUrl + "/" + payload.id + "/documents/financials/resubmit", payload);
    };
    MonitoringFilesService.prototype.resubmitCertificate = function (payload) {
        return this.post(this.baseUrl + "/" + payload.id + "/documents/certificate/resubmit", payload);
    };
    MonitoringFilesService.prototype.addReportingSource = function (payload) {
        return this.post(this.baseUrl + "/" + payload.id + "/add-reporting-source", payload.reportingSource);
    };
    MonitoringFilesService.prototype.updateReportingSource = function (payload) {
        return this.post(this.baseUrl + "/" + payload.id + "/update-reporting-source", payload);
    };
    MonitoringFilesService.prototype.removeReportingSource = function (payload) {
        return this.post(this.baseUrl + "/" + payload.id + "/remove-reporting-source", payload);
    };
    MonitoringFilesService.prototype.updateCovenants = function (payload) {
        return this.post(this.baseUrl + "/" + payload.id + "/covenants", { covenants: payload.covenants });
    };
    MonitoringFilesService.prototype.updateGeneralInformation = function (payload) {
        return this.post(this.baseUrl + "/" + payload.id, payload);
    };
    MonitoringFilesService.prototype.requestResubmission = function (payload) {
        var frequency = this.mapReportingFrequencyAttribute(payload.reportingPeriod.reportingFrequency);
        return this.post(this.baseUrl + "/" + payload.id + "/documents/" + payload.reportingPeriod.startYear + "/" + payload.reportingPeriod.reportingPeriodNumber + "/" + frequency + "/request-resubmission", null);
    };
    MonitoringFilesService.prototype.completeReportingPeriod = function (payload) {
        var frequency = this.mapReportingFrequencyAttribute(payload.reportingPeriod.reportingFrequency);
        return this.post(this.baseUrl + "/" + payload.id + "/documents/" + payload.reportingPeriod.startYear + "/" + payload.reportingPeriod.reportingPeriodNumber + "/" + frequency + "/complete", null);
    };
    MonitoringFilesService.prototype.mapReportingFrequencyAttribute = function (reportingFrequency) {
        switch (reportingFrequency) {
            case ReportingFrequency.HALFYEARLY:
                return 'half-year';
            case ReportingFrequency.QUARTERLY:
                return 'quarter';
            default:
                throw new Error('Unknown reporting frequency' + reportingFrequency);
        }
    };
    return MonitoringFilesService;
}(AbstractApiService));
export { MonitoringFilesService };
