import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginRouting } from './login.routing';
import { LoginComponent } from './login.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { TokenRefreshPageComponent } from './refresh-page/token-refresh.component';
import { Auth0Factory } from './login-form/auth0-factory.service';
import { LoginFailureComponent } from './failure/login-failure.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialDesignModule } from '../shared/layout/material-design.module';
import { LogoutComponent } from './logout/logout.component';
import { LayoutModule } from '../shared/layout/layout.module';

@NgModule({
  imports: [
    CommonModule,
    LoginRouting,
    TranslateModule,
    LayoutModule,

    // Angular Material Design
    MaterialDesignModule
  ],
  declarations: [
    LoginComponent,
    LogoutComponent,
    LoginFormComponent,
    LoginPageComponent,
    TokenRefreshPageComponent,
    LoginFailureComponent
  ],
  providers: [
    Auth0Factory,
  ]
})
export class LoginModule {
}
