import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";

/**
 * Helper function for the ngrx store to synchronously get items from the store
 * Credits: https://github.com/ngrx/store/issues/296
 */

declare module "@ngrx/store/src/store" {
  interface Store<T> {
    getSync: typeof getSync;
  }
}
let subscriptions = new WeakMap<Store<any>, Subscription>();
let currentStates = new WeakMap<Store<any>, any>();

/**
 * Runs the given selector and returns the current value synchronously from the store.
 */
export function getSync<TState, TResult>(this: Store<TState>, selector?: (state: TState) => TResult): TResult {
  if (!subscriptions.get(this)) {
    subscriptions.set(this, this.subscribe(data => {
      currentStates.set(this, data);
    }));
  }

  const state = currentStates.get(this);
  return selector
    ? selector(state)
    : state;
}

export function applyStorePatch(store: Store<any>) {
  store.getSync = getSync;
}
