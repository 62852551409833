import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NgControl } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { Subject, Subscription } from 'rxjs';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { selectLocale } from '../../../state-management/preferences/selector';
import { select, Store } from '@ngrx/store';
import { State } from '../../../state-management/reducers';
import { Locale } from '../../api/models/locale';
import { SupportedCountry } from '../../api/models/supportedCountry';
import { getDigitsInfo, hasDecimals } from './currency-input-util';

@Component({
  selector: 'caple-currency-input',
  templateUrl: './currency-input.component.html',
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: CurrencyInputComponent
    },
    DecimalPipe
  ],
  styles: [`
      :host {
          display: flex;
      }
  `]
})
export class CurrencyInputComponent implements MatFormFieldControl<number | string>, OnDestroy, ControlValueAccessor, OnInit {

  /** Next ID for the mat-form-field **/
  private static nextId = 0;

  /** Dutch thousand-separator **/
  private readonly DOT = '\\.'; // As this is used as a regular expr., we have to escape the dot

  /** GB thousand-separator **/
  private readonly COMMA = ',';

  /** Current active thousand-separator **/
  private thousandSeparator = this.COMMA;

  /** Current active locale **/
  public locale = 'en-GB';

  /** Characters that are allowed within the input field. This depends on the locale. **/
  private allowedCharacters: RegExp;

  /** Subscriptions that keeps track of the active locale **/
  private localeSubscription: Subscription;

  /** Subscriptions that keeps track of the focus for the input field **/
  private focusSubscription: Subscription;

  /** Subscriptions that keeps track of the amount value field **/
  private formValueSubscription: Subscription;

  /** Place holders for ControlValueAccessor **/
  private _onChange = (val: any) => {
  };
  private _onTouched = () => {
  };

  /** Implemented as part of MatFormFieldControl. */
  @HostBinding()
  public readonly id: string = `caple-currency-input-${CurrencyInputComponent.nextId++}`;

  /** Implemented as part of MatFormFieldControl. */
  @HostBinding('attr.aria-describedby')
  public describedBy = '';

  /** Implemented as part of MatFormFieldControl. */
  public controlType: string = 'caple-currency-input';

  /** Implemented as part of MatFormFieldControl. */
  public readonly stateChanges: Subject<void> = new Subject<void>();

  /** Reference to the internal input field **/
  @ViewChild('input', {static: false})
  public amountField: ElementRef;

  /** Form group that is used to send/retrieve values form the input field **/
  public form: FormGroup;

  /** Country that determines the locale / currency symbol **/
  @Input()
  public country: string | null;

  /**
   * Implemented as part of MatFormFieldControl.
   *
   * This is called when someone sets the value of this component; patch uses this.
   *
   * For type safety, we have to say that this method only accepts number | null. In reality
   * strings are also accepted but as parsed to a number immediately.
   * */
  @Input()
  public set value(value: number | null) {
    if (typeof value !== 'number') {
      value = this.parseNumber(value);
    }

    this._value = value;
    this.form.patchValue({amount: value}, {emitEvent: false});
    this.updateNumberView();
    this.stateChanges.next();
  }

  public get value(): number | null {
    return this._value;
  }

  private _value: number | null = null;

  /** Implemented as part of MatFormFieldControl. */
  @Input()
  public set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }

  public get placeholder() {
    return this._placeholder;
  }

  private _placeholder: string = '';

  /** Implemented as part of MatFormFieldControl. */
  @Input()
  public set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  public get required() {
    return this._required;
  }

  private _required: boolean = false;

  /** Implemented as part of MatFormFieldControl. */
  @Input()
  public set disabled(req) {
    this._disabled = coerceBooleanProperty(req);
    if (this._disabled) {
      this.form.disable({emitEvent: false});
    } else {
      this.form.enable({emitEvent: false});
    }

    // Browsers may not fire the blur event if the input is disabled too quickly.
    // Reset from here to ensure that the element doesn't become stuck.
    if (this.focused) {
      this.focused = false;
      this.stateChanges.next();
    }
  }

  public get disabled() {
    if (this.ngControl && this.ngControl.disabled !== null) {
      return this.ngControl.disabled;
    }

    return this._disabled;
  }

  private _disabled = false;

  /** Implemented as part of MatFormFieldControl. */
  @Input()
  public set focused(val) {
    if (this._focused && val === false) { // 'onBlur', mark as touched
      this._onTouched();
    }

    this._focused = val;

    this.updateNumberView();
    this.stateChanges.next();
  }

  public get focused() {
    return this._focused;
  }

  private _focused = false;

  constructor(formBuilder: FormBuilder,
              private focusMonitor: FocusMonitor,
              private elementRef: ElementRef<HTMLElement>,
              private store: Store<State>,
              private decimalPipe: DecimalPipe,
              @Optional() @Self() public ngControl: NgControl) {

    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using the providers) to
      // avoid running into a circular import
      this.ngControl.valueAccessor = this;
    }

    this.focusSubscription = focusMonitor.monitor(elementRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;

      this.stateChanges.next();
    });

    this.form = formBuilder.group({
      'amount': ''
    });

    // Start listening to changes on the input so we can act on them
    this.formValueSubscription = this.form.valueChanges
      .subscribe(formValues => {
        this._value = this.parseNumber(formValues.amount);
        this._onChange(this._value);
        this.stateChanges.next();
      });
  }

  /**
   * After initialisation, setup the locale, thousand separator and allowed characters
   */
  public ngOnInit(): void {
    this.localeSubscription = this.store.pipe(select(selectLocale))
      .subscribe((locale: any) => {
        this.locale = locale;
        switch (locale) {
          case Locale.NlNL:
            this.country = this.country ? this.country : SupportedCountry.NL;
            this.thousandSeparator = this.DOT;
            this.allowedCharacters = new RegExp('^[0-9\-' + this.COMMA + ']$');
            break;
          default:
            this.country = this.country ? this.country : SupportedCountry.GB;
            this.thousandSeparator = this.COMMA;
            this.allowedCharacters = new RegExp('^[0-9\-' + this.DOT + ']$');
        }
      });
  }

  /** Implemented as part of MatFormFieldControl. */
  public get empty() {
    const amount = this.form.value.amount;
    return amount === null || amount === undefined || amount === '';
  }

  /** Implemented as part of MatFormFieldControl. */
  @HostBinding('class.floating')
  public get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  /** Prevents the input of non-numerical characters except for the . and , corresponding with the locale **/
  public onKeyPress(event: KeyboardEvent) {
    if (!this.allowedCharacters.test(event.key)) {
      event.preventDefault();
    }
  }

  /** Implemented as part of MatFormFieldControl. */
  public get errorState(): boolean {
    const currentErrorState = this.ngControl && this.ngControl.touched && this.ngControl.errors != null;
    if (currentErrorState != this._errorState) {
      setTimeout(() => this.stateChanges.next());
      this._errorState = currentErrorState;
    }
    return currentErrorState;
  }

  private _errorState: boolean;

  /** Implemented as part of MatFormFieldControl. */
  public onContainerClick(event: MouseEvent): void {
    this.elementRef.nativeElement.querySelector('input').focus();
  }

  /** Implemented as part of MatFormFieldControl. */
  public setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);

    if (this.localeSubscription) {
      this.localeSubscription.unsubscribe();
      this.localeSubscription = null;
    }

    if (this.focusSubscription) {
      this.focusSubscription.unsubscribe();
      this.focusSubscription = null;
    }

    if (this.formValueSubscription) {
      this.formValueSubscription.unsubscribe();
      this.formValueSubscription = null;
    }
  }

  /** Implemented as part of ControlValueAccessor */
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  /** Implemented as part of ControlValueAccessor */
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  /** Implemented as part of ControlValueAccessor */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /** Implemented as part of ControlValueAccessor */
  writeValue(obj: any): void {
    this.value = obj;
  }

  /**
   * This will overwrite the value in the amountField with the correct value.
   *
   * If focused; show a plain number.
   * If not-focused: show a formatted number according to the users locale
   *
   * When the user clicks somewhere in the input field, he wants his cursor to start where he clicked. However,
   * because we change the input after clicking, the cursor gets place at the end. This function also fixes this
   * by placing the cursor where the user expects it, taking into account the offset for removing dots/comma's
   * from the un-formatted number view.
   */
  private updateNumberView() {
    if (this.amountField && this.value) {
      // This change has to happen after the change detection cycle so angular doesn't
      // overwrite the value we are setting here
      setTimeout(() => {
        const nativeElement = this.amountField.nativeElement;

        if (this.focused) {
          const oldValue = nativeElement.value;
          // Store the current carrot position so we can restore it
          const carrotPosition = nativeElement.selectionStart;

          // Overwite the value
          nativeElement.value = this.value;

          // if the value has only 1 decimal, append a 0 to make it the same as the un-focused value
          if (this.hasSingleDecimal(this.value)) {
            nativeElement.value += '0';
          }

          // Restore the carrot position, taking into account the offset for removing dots and comma's
          const relevantValuePart = oldValue.toString().substring(0, carrotPosition);
          const amountOfThousandSeparators = (relevantValuePart.match(new RegExp(this.thousandSeparator, 'g')) || []).length;
          const newCaretPosition = carrotPosition - amountOfThousandSeparators;

          nativeElement.setSelectionRange(newCaretPosition, newCaretPosition);
        } else {
          const value = this.parseNumber(this.value);
          const digitInfo = getDigitsInfo(value);
          nativeElement.value = this.decimalPipe.transform(value, digitInfo, this.locale);
        }
      });
    }
  }

  private hasSingleDecimal(value) {
    return hasDecimals(value) && value.toFixed(2) !== value.toLocaleString();
  }

  private parseNumber(input: string | number): number | null {
    if (input === null || input === undefined) {
      return null;
    }

    if (typeof input === 'number') {
      return input;
    }

    if (this.thousandSeparator === this.DOT) {
      input = input.replace(',', '.');
    }

    const result = Number.parseFloat(input);

    if (isNaN(result)) {
      return null;
    } else {
      return result;
    }
  }
}
