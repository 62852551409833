import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { State } from '../../state-management/reducers';
import { UnauthenticatedAccess } from '../../state-management/login/actions';
import { Store } from '@ngrx/store';
import { selectIsAuthenticated } from '../../state-management/login/selector';

@Injectable()
export class AuthenticationGuard implements CanActivate {

  constructor(private store: Store<State>) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const authenticated = this.store.getSync(selectIsAuthenticated);

    if (!authenticated) {
      this.store.dispatch(new UnauthenticatedAccess(state.url));
    }

    return authenticated;
  }
}
