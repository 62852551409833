import {Component, Inject} from '@angular/core';
import {CloseModal} from '../../../../state-management/layout/actions';
import {select, Store} from '@ngrx/store';
import {State} from '../../../../state-management/reducers';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {IdType} from '../../../../state-management/util/actions';
import {UpdateProductType} from '../../../state-management/actions';
import {Observable} from 'rxjs';
import {selectFundingProcess} from '../../../state-management/selector';
import {filter, map} from 'rxjs/operators';
import {Product} from '../../../../shared/api/models/product';

@Component({
  selector: 'caple-product-type-selector-dialog',
  templateUrl: './product-type-selector-dialog.component.html',
  styleUrls: ['product-type-selector-dialog.component.scss']
})
export class ProductTypeSelectorDialogComponent {

  public isValid = true;
  public product: Product;

  public existingProduct$: Observable<Product>;
  private existingProduct: Product;

  constructor(private store: Store<State>, @Inject(MAT_DIALOG_DATA) private options: ProductTypeSelectorDialogOptions) {
    this.existingProduct$ = store.pipe(
      select(selectFundingProcess({id: options.fundingProcessId})),
      filter(fp => !!fp),
      map(fp => {
        this.existingProduct = fp.product;
        return {
          productType: fp.product.productType,
          productSubType: fp.product.productSubType,
          loanSize: fp.product.loanSize
        };
      })
    );
  }

  public changeProductType() {
    if (this.product) {
      if (this.product.productType === this.existingProduct.productType &&
        this.product.productSubType === this.existingProduct.productSubType &&
        this.product.loanSize === this.existingProduct.loanSize) {
        // Type didn't change so we can just close the dialog
        this.closeModal();
      } else {
        this.store.dispatch(new UpdateProductType({
          productType: this.product.productType,
          productSubType: this.product.productSubType,
          loanSize: this.product.loanSize,
          id: this.options.fundingProcessId
        }));
      }
    } else {
      this.closeModal();
    }
  }

  public closeModal() {
    this.store.dispatch(new CloseModal());
  }
}

export interface ProductTypeSelectorDialogOptions {
  fundingProcessId?: IdType;
}
