import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { AbstractApiService } from './abstract-api.service';
import { WithId } from '../../../state-management/util/actions';
import { RxStompService } from '@stomp/ng2-stompjs';
import { map } from 'rxjs/operators';
export var ResourceType = {
    KYC: 'KYC',
    FUNDING_PROPOSAL: 'FUNDING_PROPOSAL',
    CONDITIONS_PRECEDENT: 'CONDITIONS_PRECEDENT'
};
var DiscussionService = /** @class */ (function (_super) {
    tslib_1.__extends(DiscussionService, _super);
    function DiscussionService(httpClient, rxStompService) {
        var _this = _super.call(this, httpClient) || this;
        _this.rxStompService = rxStompService;
        return _this;
    }
    DiscussionService.prototype.getById = function (payload) {
        return this.get(this.getDiscussionBaseUrl(payload) + "/" + payload.id);
    };
    DiscussionService.prototype.startDiscussion = function (payload) {
        return this.post("" + this.getDiscussionBaseUrl(payload), payload);
    };
    DiscussionService.prototype.postComment = function (payload) {
        return this.post(this.getDiscussionBaseUrl(payload) + "/" + payload.id + "/comment", payload.comment);
    };
    DiscussionService.prototype.markAsRead = function (payload) {
        return this.post(this.getDiscussionBaseUrl(payload) + "/" + payload.id + "/mark-as-read", null);
    };
    DiscussionService.prototype.getCommentsStream = function (payload) {
        return this.rxStompService.watch(this.getCommentStreamUrl(payload)).pipe(map(function (message) { return JSON.parse(message.body); }));
    };
    DiscussionService.prototype.getDiscussionBaseUrl = function (payload) {
        switch (payload.resourceType) {
            case ResourceType.KYC:
                return "/api/v1/kyc/" + payload.resourceId + "/discussions";
            case ResourceType.CONDITIONS_PRECEDENT:
                return "/api/v1/conditions-precedent/" + payload.resourceId + "/discussions";
            case ResourceType.FUNDING_PROPOSAL:
                return "/api/v1/funding-processes/" + payload.resourceId + "/discussions";
            default:
                throw new Error("ResourceType [" + payload.resourceType + "] not supported for discussion!");
        }
    };
    DiscussionService.prototype.getCommentStreamUrl = function (payload) {
        switch (payload.resourceType) {
            case ResourceType.KYC:
                return "/user/queue/kyc/" + payload.resourceId + "/discussions/" + payload.id;
            case ResourceType.FUNDING_PROPOSAL:
                return "/user/queue/funding-processes/" + payload.resourceId + "/discussions/" + payload.id;
            default:
                throw new Error("ResourceType [" + payload.resourceType + "] not supported for comment stream!");
        }
    };
    return DiscussionService;
}(AbstractApiService));
export { DiscussionService };
