/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./upload-block.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "@ngx-translate/core";
import * as i7 from "@angular/common";
import * as i8 from "./upload-block.component";
var styles_UploadBlockComponent = [i0.styles];
var RenderType_UploadBlockComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UploadBlockComponent, data: {} });
export { RenderType_UploadBlockComponent as RenderType_UploadBlockComponent };
function View_UploadBlockComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
function View_UploadBlockComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "span", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["check_circle"])), (_l()(), i1.ɵted(4, null, [" ", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 8, "div", [["class", "justify-space-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 5, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDownload.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(8, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(9, 0, [" ", " "])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, 0, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["vertical_align_bottom"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadBlockComponent_2)), i1.ɵdid(14, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.reuploadAvailable; _ck(_v, 14, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "/assets/images/uploads/", _co.type, "-active.png"); _ck(_v, 5, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 8).disabled || null); var currVal_3 = (i1.ɵnov(_v, 8)._animationMode === "NoopAnimations"); _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_4 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("generic.download")); _ck(_v, 9, 0, currVal_4); }); }
function View_UploadBlockComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "justify-space-between"]], null, null, null, null, null)), i1.ɵncd(null, 1), i1.ɵncd(null, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "/assets/images/uploads/", _co.type, "-inactive.png"); _ck(_v, 2, 0, currVal_1); }); }
export function View_UploadBlockComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadBlockComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["notUploaded", 2]], null, 0, null, View_UploadBlockComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.uploaded; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_UploadBlockComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "caple-upload-reporting-document-block", [], null, null, null, View_UploadBlockComponent_0, RenderType_UploadBlockComponent)), i1.ɵdid(1, 49152, null, 0, i8.UploadBlockComponent, [], null, null)], null, null); }
var UploadBlockComponentNgFactory = i1.ɵccf("caple-upload-reporting-document-block", i8.UploadBlockComponent, View_UploadBlockComponent_Host_0, { uploaded: "uploaded", title: "title", type: "type", reuploadAvailable: "reuploadAvailable" }, { onDownload: "onDownload" }, [".reupload-button", ".download-template", ".upload-button"]);
export { UploadBlockComponentNgFactory as UploadBlockComponentNgFactory };
