import * as tslib_1 from "tslib";
import { FormBuilder } from '@angular/forms';
var CapleFormBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(CapleFormBuilder, _super);
    function CapleFormBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CapleFormBuilder.prototype.group = function (controlsConfig, extra) {
        var formGroup = _super.prototype.group.call(this, controlsConfig, extra);
        // Wrap the patch function of the formGroup
        var originalPatchValue = formGroup.patchValue;
        formGroup.patchValue = function (value, options) {
            originalPatchValue.apply(formGroup, [value || {}, options]);
        };
        for (var controlKey in formGroup.controls) {
            var control = formGroup.controls[controlKey];
            control.name = controlKey;
        }
        return formGroup;
    };
    CapleFormBuilder.prototype.array = function (controlsConfig, validator, asyncValidator) {
        var formArray = _super.prototype.array.call(this, controlsConfig, validator, asyncValidator);
        var originalPatchValue = formArray.patchValue;
        formArray.patchValue = function (value, options) {
            originalPatchValue.apply(formArray, [value || [], options]);
        };
        for (var controlKey in formArray.controls) {
            var control = formArray.controls[controlKey];
            control.name = controlKey;
        }
        return formArray;
    };
    return CapleFormBuilder;
}(FormBuilder));
export { CapleFormBuilder };
