var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
import { createSelector } from '@ngrx/store';
import { chamberOfCommerceAdapter, clientAdapter, eligibilityAssessmentPrefillAdapter, financialRatingAdapter, fundingProcessAdapter, fundingProcessSummaryAdapter, fundingProposalSnapshotAdapter, fundingProposalTranslationAdapter, kycAssessmentPrefillAdapter, kycStatusAdapter, metaModelAdapter, metaModelIdentifierAdapter, selectFeature, teamAdapter } from './reducer';
import { isNullOrUndefined } from 'util';
import { Phase } from '../../shared/api/models/phase';
import { FundingProposalStatus } from '../../shared/api/models/fundingProposalStatus';
import { AssessmentStatus } from '../../shared/api/models/assessmentStatus';
import { Role } from '../../shared/api/models/role';
import { selectHasOneOfRoles } from '../../state-management/login/selector';
import { FundingProposalMode } from '../funding-process/funding-proposal/shared/funding-proposal-mode';
var ɵ0 = function (state) { return state.fundingProcessesSummaries; };
var selectFundingProcessesSummariesSlice = createSelector(selectFeature, ɵ0);
var ɵ1 = function (state) { return state.fundingProcesses; };
var selectFundingProcessesSlice = createSelector(selectFeature, ɵ1);
var ɵ2 = function (state) { return state.fundingProposalSnapshot; };
var selectFundingProposalSnapshotSlice = createSelector(selectFeature, ɵ2);
var ɵ3 = function (state) { return state.fundingProposalTranslation; };
var selectFundingProposalTranslationSlice = createSelector(selectFeature, ɵ3);
var ɵ4 = function (state) { return state.fundingProposalMode; };
var selectfundingProposalModeSlice = createSelector(selectFeature, ɵ4);
var ɵ5 = function (state) { return state.clients; };
var selectClientsSlice = createSelector(selectFeature, ɵ5);
var ɵ6 = function (state) { return state.client_users; };
var selectClientUsersSlice = createSelector(selectFeature, ɵ6);
var ɵ7 = function (state) { return state.financialRatingSummaries; };
var selectFinancialRatingSummariesSlice = createSelector(selectFeature, ɵ7);
var ɵ8 = function (state) { return state.financialRatings; };
var selectFinancialRatingsSlice = createSelector(selectFeature, ɵ8);
var ɵ9 = function (state) { return state.financialRatingMetaModels; };
var selectFinancialRatingMetaModelsSlice = createSelector(selectFeature, ɵ9);
var ɵ10 = function (state) { return state.fundingProposalMetaModels; };
var selectFundingProposalMetaModelsSlice = createSelector(selectFeature, ɵ10);
var ɵ11 = function (state) { return state.fundingProposalMetaModelLatest; };
var selectFundingProposalMetaModelLatestSlice = createSelector(selectFeature, ɵ11);
var ɵ12 = function (state) { return state.fundingProcessValidations; };
var selectValidationsSlice = createSelector(selectFeature, ɵ12);
var ɵ13 = function (state) { return state.chamberOfCommerce; };
var selectChamberOfCommerceSlice = createSelector(selectFeature, ɵ13);
var ɵ14 = function (state) { return state.chamberOfCommerceName; };
var selectChamberOfCommerceNameSlice = createSelector(selectFeature, ɵ14);
var ɵ15 = function (state) { return state.kycStatus; };
var selectKycStatusSlice = createSelector(selectFeature, ɵ15);
var ɵ16 = function (state) { return state.team; };
var selectTeamSlice = createSelector(selectFeature, ɵ16);
var ɵ17 = function (state) { return state.eligibilityAssessmentPrefill; };
var selectEligibilityAssessmentPrefillSlice = createSelector(selectFeature, ɵ17);
var ɵ18 = function (state) { return state.kycAssessmentPrefill; };
var selectKycAssessmentPrefillSlice = createSelector(selectFeature, ɵ18);
/////////////
//// Selectors
/////////////
/** Clients **/
export var selectClients = (_a = clientAdapter.getSelectors(selectClientsSlice), _a.selectAll), selectClient = _a.selectSingle, selectClientsIsLoaded = _a.selectIsLoaded;
export var selectClientUsers = function (client) { return createSelector(selectClientUsersSlice, function (users) { return users.entities[client.id]; }); };
export var selectClientUsersIsLoaded = function (client) { return createSelector(selectClientUsers(client), function (users) { return users !== undefined; }); };
/** FundingProcesses **/
export var selectFundingProcessSummaryIsLoaded = (_b = fundingProcessSummaryAdapter.getSelectors(selectFundingProcessesSummariesSlice), _b.selectSingleIsLoaded), selectFundingProcessesSummaries = _b.selectAll, selectActiveFundingProcessSummary = _b.selectSelected, selectFundingProcessesSummariesIsLoaded = _b.selectIsLoaded, selectFundingProcessesSummariesSize = _b.selectSize;
export var selectFundingProcessIsLoaded = (_c = fundingProcessAdapter.getSelectors(selectFundingProcessesSlice), _c.selectSingleIsLoaded), selectActiveFundingProcess = _c.selectSelected, selectFundingProcess = _c.selectSingle;
export var selectPhase = function (fundingProcessId) { return createSelector(selectFundingProcess(fundingProcessId), function (fundingProcess) { return fundingProcess ? fundingProcess.phase : undefined; }); };
export var selectClientFromFundingProcess = function (fundingProcessId) { return createSelector(selectFundingProcess(fundingProcessId), function (fundingProcess) { return fundingProcess ? fundingProcess.client : undefined; }); };
export var selectFundingProcessEditable = function (withId) { return createSelector(selectFundingProcess(withId), selectHasOneOfRoles([Role.DIRECTCLIENT, Role.PARTNER]), function (fundingProcess, hasRole) { return isFundingProcessEditable(fundingProcess) && hasRole; }); };
var ɵ19 = function (fundingProcess, hasRole) { return isFundingProcessEditable(fundingProcess) && hasRole; };
export var selectActiveFundingProcessEditable = createSelector(selectActiveFundingProcess, selectHasOneOfRoles([Role.DIRECTCLIENT, Role.PARTNER]), ɵ19);
var ɵ20 = function (fundingProcess) {
    return fundingProcess && fundingProcess.client.country;
};
export var selectActiveFundingProcessCountry = createSelector(selectActiveFundingProcess, ɵ20);
var ɵ21 = function (fundingProcess) {
    return fundingProcess && fundingProcess.fundingProposalVersion;
};
export var selectActiveFundingProcessProposalVersion = createSelector(selectActiveFundingProcess, ɵ21);
var ɵ22 = function (fundingProcess) {
    return fundingProcess && fundingProcess.product;
};
export var selectActiveFundingProcessProduct = createSelector(selectActiveFundingProcess, ɵ22);
export var selectFinancialRatingEditable = function (withId) { return createSelector(selectFundingProcess(withId), selectHasOneOfRoles([Role.DIRECTCLIENT, Role.PARTNER]), function (fundingProcess, hasRole) { return isFundingProcessEditable(fundingProcess) && hasRole; }); };
var ɵ23 = function (state) { return state.fundingProcessesFilteredCountries; };
export var selectFundingProcessesFilteredCountries = createSelector(selectFeature, ɵ23);
var ɵ24 = function (state) { return state.fundingProcessesFilteredStatuses; };
export var selectFundingProcessesFilteredStatuses = createSelector(selectFeature, ɵ24);
var ɵ25 = function (state) { return state.fundingProcessesSearchQuery; };
export var selectFundingProcessesSearchQuery = createSelector(selectFeature, ɵ25);
/** Financial Rating Summary **/
export var selectFinancialRatingSummariesForFundingProcess = function (fundingProcess) { return createSelector(selectFinancialRatingSummariesSlice, function (financialRatings) { return financialRatings.entities[fundingProcess.id]; }); };
export var selectFinancialRatingsSummariesForFundingProcessLoaded = function (fundingProcess) { return createSelector(selectFinancialRatingSummariesForFundingProcess(fundingProcess), function (financialRatings) { return financialRatings !== undefined; }); };
/** Financial Rating **/
export var selectFinancialRating = (_d = financialRatingAdapter.getSelectors(selectFinancialRatingsSlice), _d.selectSingle), selectFinancialRatingIsLoaded = _d.selectSingleIsLoaded;
export var selectFinancialRatingMetaModel = (_e = metaModelAdapter.getSelectors(selectFinancialRatingMetaModelsSlice), _e.selectSingle), selectFinancialRatingMetaModelIsLoaded = _e.selectSingleIsLoaded;
/** Team **/
export var selectTeam = (_f = teamAdapter.getSelectors(selectTeamSlice), _f.selectSingle), selectTeamLoaded = _f.selectSingleIsLoaded;
/** Funding Proposal **/
export var selectFundingProposalMetaModel = (_g = metaModelAdapter.getSelectors(selectFundingProposalMetaModelsSlice), _g.selectSingle), selectFundingProposalMetaModelIsLoaded = _g.selectSingleIsLoaded;
export var selectFundingProposalSnapshot = (_h = fundingProposalSnapshotAdapter.getSelectors(selectFundingProposalSnapshotSlice), _h.selectSingle), selectFundingProposalSnapshotIsLoaded = _h.selectSingleIsLoaded;
export var selectFundingProposalTranslation = (_j = fundingProposalTranslationAdapter.getSelectors(selectFundingProposalTranslationSlice), _j.selectSingle), selectFundingProposalTranslationIsLoaded = _j.selectSingleIsLoaded;
export var selectFundingProposalMetaModelLatest = (_k = metaModelIdentifierAdapter.getSelectors(selectFundingProposalMetaModelLatestSlice), _k.selectSingle), selectFundingProposalMetaModelLatestlIsLoaded = _k.selectSingleIsLoaded;
var ɵ26 = function (fundingProcess, snapshotSlice) { return !!snapshotSlice.entities[fundingProcess.id]; };
export var selectActiveProcessHasSnapshot = createSelector(selectActiveFundingProcess, selectFundingProposalSnapshotSlice, ɵ26);
var ɵ27 = function (fundingProcess, modeSlice) {
    if (fundingProcess) {
        var modeObject = modeSlice.entities[fundingProcess.id];
        if (modeObject) {
            return modeObject.mode;
        }
    }
    return FundingProposalMode.ORIGINAL;
};
export var selectActiveFundingProposalMode = createSelector(selectActiveFundingProcess, selectfundingProposalModeSlice, ɵ27);
export var selectValidationForCompleteFundingProcess = function (fundingProcess) {
    return createSelector(selectValidationsSlice, function (validations) {
        if (validations && validations.entities[fundingProcess.id]) {
            return Object.values(validations.entities[fundingProcess.id].entities).reduce(function (previousValue, value) { return previousValue && value.valid; }, true);
        }
        else {
            return undefined;
        }
    });
};
export var selectValidationForFundingProcess = function (fundingProcess, validationId) {
    return createSelector(selectValidationsSlice, function (validations) {
        if (validations && validations.entities[fundingProcess.id] && validations.entities[fundingProcess.id].entities[validationId]) {
            return validations.entities[fundingProcess.id].entities[validationId].valid;
        }
        else {
            return undefined;
        }
    });
};
/** Assessment **/
export var selectFundingProcessAssessable = function (withId) { return createSelector(selectFundingProcess(withId), function (fundingProcess) {
    return fundingProcess && fundingProcess.fundingProposal && fundingProcess.fundingProposal.status === FundingProposalStatus.COMPLETED && (!fundingProcess.assessment || fundingProcess.assessment.status === AssessmentStatus.INPROGRESS);
}); };
export var selectFundingProcessAssessmentCompleted = function (withId) { return createSelector(selectFundingProcess(withId), function (fundingProcess) {
    return fundingProcess && fundingProcess.fundingProposal && fundingProcess.fundingProposal.status === FundingProposalStatus.COMPLETED && (!fundingProcess.assessment || fundingProcess.assessment.status === AssessmentStatus.COMPLETED);
}); };
var ɵ28 = function (fundingProcess, hasRole) { return fundingProcess && fundingProcess.phase !== Phase.COMPLETED && hasRole; };
export var selectActiveFundingProcessAssessmentEditable = createSelector(selectActiveFundingProcess, selectHasOneOfRoles([Role.CREDITOFFICER]), ɵ28);
export var selectFileInCommunication = function (fundingprocessId, fileId) { return createSelector(selectFundingProcess(fundingprocessId), function (fundingProcess) {
    if (fundingProcess) {
        return fundingProcess.assessment.communicationFiles.find(function (file) { return file.fileId === fileId; });
    }
    return undefined;
}); };
export var selectEligbilityAssessmentPrefill = (_l = eligibilityAssessmentPrefillAdapter.getSelectors(selectEligibilityAssessmentPrefillSlice), _l.selectSingle), selectEligbilityAssessmentPrefillIsLoaded = _l.selectSingleIsLoaded;
export var selectKycAssessmentPrefill = (_m = kycAssessmentPrefillAdapter.getSelectors(selectKycAssessmentPrefillSlice), _m.selectSingle), selectKycAssessmentPrefillIsLoaded = _m.selectSingleIsLoaded;
/** Chamber of Commerce **/
export var selectChamberOfCommerceResult = (_o = chamberOfCommerceAdapter.getSelectors(selectChamberOfCommerceSlice), _o.selectSingle), selectChamberOfCommerceIsLoaded = _o.selectSingleIsLoaded;
export var selectChamberOfCommerceNameResult = (_p = chamberOfCommerceAdapter.getSelectors(selectChamberOfCommerceNameSlice), _p.selectSingle), selectChamberOfCommerceNameIsLoaded = _p.selectSingleIsLoaded;
/** KYC Status **/
export var selectKYCStatus = (_q = kycStatusAdapter.getSelectors(selectKycStatusSlice), _q.selectSingle), selectKYCStatusIsLoaded = _q.selectSingleIsLoaded;
export var selectUsersForCompany = function (companyId) { return createSelector(selectFeature, function (state) {
    return state.users.entities[companyId];
}); };
export var selectUsersForCompanyLoaded = function (company) { return createSelector(selectFeature, function (state) {
    return state.users.entities[company.id] !== undefined;
}); };
/////////////
//// Helper functions
/////////////
function isFundingProcessEditable(fundingProcess) {
    var proposalEditable = fundingProcess && fundingProcess.fundingProposal && (fundingProcess.fundingProposal.status === FundingProposalStatus.INPROGRESS || fundingProcess.fundingProposal.status === FundingProposalStatus.INAMENDMENT);
    var noProposal = fundingProcess && !fundingProcess.fundingProposal;
    return (proposalEditable || noProposal);
}
/**
 * Whether the current funding proposal is in at least one of the given Phases.=
 */
function isInPhase(phases) {
    // return (state: State) => phases.indexOf(state.phase) >= 0;
    return false;
}
/**
 * Will return false if the supplied boolean is null or undefined, otherwise it will return the value of the param
 */
function isValid(valid) {
    if (isNullOrUndefined(valid)) {
        return false;
    }
    return valid;
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27, ɵ28 };
