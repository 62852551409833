import { Action, ActionReducer } from '@ngrx/store';
import { State } from '../reducers';
import { ApiError } from '../../shared/api/api-error';
import { LayoutActions } from '../layout/actions';
import { AccessTokenRenewFailed, LoginActions } from '../login/actions';

const filteredActionTypes = [
  '@ngrx/store/init',
  '@ngrx/effects/init',
  '@ngrx/store/update-reducers',
  '[Loading] Show indicator',
  '[Loading] Hide indicator',
  '[Progress] Show indicator',
  '[Progress] indicator opened',
  '[Progress] Update indicator',
  '[Progress] Hide indicator',
  '[Progress] indicator closed',
  '[Scroll] Scroll to',
  '[Modal] Modal Opened',
  '[Modal] Close modal',
  '[Modal] Modal Closed',
  'ROUTER_NAVIGATION',
  '[Redirect] Redirect',
  '[|ANNOUNCEMENT| Announcement] LOAD_SINGLE_WITHOUT_ID',
  '[|ANNOUNCEMENT| Announcement] LOAD_SINGLE_SUCCESS',
  '[|PREF| PREFERENCES] LOAD_SINGLE_WITHOUT_ID',
  '[|PREF| PREFERENCES] LOAD_SINGLE_SUCCESS'
];

const filteredActionNames = [
  'SELECT',
  'VALIDATION_CHANGED'
];

/**
 * Meta-reducer that doesn't touch the actions or the state coming through.
 *
 * But only fires analytics events to Piwik (if loaded on the window scope)
 * @param reducer
 */
export function analyticsReducer(reducer: ActionReducer<State, Action>): ActionReducer<any> {
  return function (state = undefined, action: any) {
    if ((window as any)._paq) {
      processAction(action);
    }
    return reducer(state, action);
  };
}

const sliceAndActionNameRegex = /^\[(.+)\] (.+)$/;

function processAction(action: any) {
  if (!filteredActionTypes.includes(action.type)) {
    const sliceAndName = sliceAndActionNameRegex.exec(action.type);
    if (sliceAndName) {
      actionWithSliceAndName(sliceAndName[1], sliceAndName[2], action);
    } else {
      sendEvent('generic_event', action.type);
    }
  }
}

function actionWithSliceAndName(slice: string, actionName: string, action: any) {
  if (!filteredActionNames.includes(actionName)) {
    sendEvent(slice, actionName, getActionValue(action));
  }
}

function getActionValue(action: any) {
  switch (action.type) {
    case '[|ORG| FUNDING_PROCESS_SUMMARY] UPDATE_FUNDING_PROCESS_FILTERED_COUNTRIES':
      return action.payload.country + '-' + action.payload.isChecked;
    case '[|KNW| KNOWLEDGE ITEM] DOWNLOAD_ITEM':
      return action.payload.title;
    case '[Toast] Show Toast':
      return action.message instanceof ApiError ? action.message.code : action.message;
    case LayoutActions.OPEN_MODAL:
      return action.componentName;
    case LoginActions.ACCESS_TOKEN_RENEW_FAILED:
      return (action as AccessTokenRenewFailed).error + ' description: ' + (action as AccessTokenRenewFailed).errorDescription;
    default:
      return undefined;
  }
}

/**
 * A typed wrapper around Piwik sendEvent
 * @param category
 * @param action
 * @param name
 * @param value
 */
function sendEvent(category: string, action: string, name?: string, value?: any) {
  (window as any)._paq.push(['trackEvent', category, action, name, value]);
}
