import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { fixPiwikMissingWarning } from './matomo-utils';

if (environment.runInProdMode) {
  enableProdMode();
}

if (environment.matomoSiteId) {
  const s = document.createElement('script');
  s.type = 'text/javascript';
  s.text = ` var _paq = _paq || [];
        /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
        _paq.push(['enableLinkTracking']);
        _paq.push(['setRequestMethod', 'POST']);
        (function () {
          var u = "https://caple.matomo.cloud/";
          _paq.push(['setTrackerUrl', u + 'piwik.php']);
          _paq.push(['setSiteId', '${environment.matomoSiteId}']);
          var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
          g.type = 'text/javascript';
          g.async = true;
          g.defer = true;
          g.src = '//cdn.matomo.cloud/caple.matomo.cloud/piwik.js';
          s.parentNode.insertBefore(g, s);
        })();`;
  document.head.appendChild(s);
} else {
  fixPiwikMissingWarning();
}

if ((<any>window).browserCheck !== 'NOK') {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err))
    .then(ng => {
      (<any>window).angular = ng;

      // Hide the loading indicator
      document.getElementById('load').style.display = 'none';
    });
} else if (environment.matomoSiteId) {
  (window as any)._paq.push(['trackPageView']);
  (window as any)._paq.push(['trackEvent', 'generic_event', 'unsupported_browser']);
}
