import * as tslib_1 from "tslib";
import { tap } from 'rxjs/operators';
import { actions } from '../util/actions';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { typeFor } from '../util/util';
import { SLICE } from './slice';
import { PreferencesService } from '../../shared/api/services/preferences.service';
import { Effects } from '../util/effect-functions';
import { TranslateService } from '@ngx-translate/core';
var PreferencesEffects = /** @class */ (function () {
    function PreferencesEffects(actions$, effects, preferencesService, translateService) {
        var _this = this;
        this.actions$ = actions$;
        this.effects = effects;
        this.preferencesService = preferencesService;
        this.translateService = translateService;
        this.loadPreferences$ = this.effects
            .loadSingleWithoutID(SLICE.PREFERENCES, 'urn:preferences:load', {
            functionToExecute: this.preferencesService.getPreferences,
            scope: this.preferencesService
        });
        this.preferencesLoaded$ = this.actions$
            .pipe(ofType(typeFor(SLICE.PREFERENCES, actions.LOAD_SINGLE_SUCCESS)), tap(function (action) {
            _this.translateService.setDefaultLang(action.payload.locale);
            _this.translateService.use(action.payload.locale);
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PreferencesEffects.prototype, "loadPreferences$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], PreferencesEffects.prototype, "preferencesLoaded$", void 0);
    return PreferencesEffects;
}());
export { PreferencesEffects };
