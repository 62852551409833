import { BehaviorSubject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectIsLoading } from '../../../state-management/layout/selector';
import { switchMap } from 'rxjs/operators';
var LoadingWrapperComponent = /** @class */ (function () {
    function LoadingWrapperComponent(store) {
        var _this = this;
        this.store = store;
        /**
         * Size of the element
         */
        this.diameter = 100;
        this.fullscreen = false;
        this.keySubject = new BehaviorSubject('');
        this.isLoading$ = this.keySubject.pipe(switchMap(function (key) { return _this.store.pipe(select(selectIsLoading(key))); }));
    }
    Object.defineProperty(LoadingWrapperComponent.prototype, "key", {
        set: function (value) {
            this.keySubject.next(value);
        },
        enumerable: true,
        configurable: true
    });
    return LoadingWrapperComponent;
}());
export { LoadingWrapperComponent };
