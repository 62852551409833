import * as tslib_1 from "tslib";
import { actions, EntityAction } from '../../state-management/util/actions';
import { SLICE } from './slice';
export var discussionActions = tslib_1.__assign({}, actions, { START_DISCUSSION: 'START_DISCUSSION', START_DISCUSSION_SUCCESS: 'START_DISCUSSION_SUCCESS', POST_COMMENT: 'POST_COMMENT', POST_COMMENT_SUCCESS: 'POST_COMMENT_SUCCESS', MARK_AS_READ: 'MARK_CHANGELOG_AS_READ', MARK_AS_READ_SUCCESS: 'MARK_CHANGELOG_AS_READ_SUCCESS', CHANGE_DISCUSSION_LOCK: 'CHANGE_DISCUSSION_LOCK' });
var StartDiscussion = /** @class */ (function (_super) {
    tslib_1.__extends(StartDiscussion, _super);
    function StartDiscussion(payload) {
        var _this = _super.call(this, SLICE.DISCUSSION, payload) || this;
        _this.actionName = discussionActions.START_DISCUSSION;
        return _this;
    }
    return StartDiscussion;
}(EntityAction));
export { StartDiscussion };
var StartDiscussionSuccess = /** @class */ (function (_super) {
    tslib_1.__extends(StartDiscussionSuccess, _super);
    function StartDiscussionSuccess(payload, request, user) {
        var _this = _super.call(this, SLICE.DISCUSSION, payload) || this;
        _this.payload = payload;
        _this.request = request;
        _this.user = user;
        _this.actionName = discussionActions.START_DISCUSSION_SUCCESS;
        _this.postedAt = new Date();
        return _this;
    }
    return StartDiscussionSuccess;
}(EntityAction));
export { StartDiscussionSuccess };
var PostComment = /** @class */ (function (_super) {
    tslib_1.__extends(PostComment, _super);
    function PostComment(payload) {
        var _this = _super.call(this, SLICE.DISCUSSION, payload) || this;
        _this.actionName = discussionActions.POST_COMMENT;
        return _this;
    }
    return PostComment;
}(EntityAction));
export { PostComment };
var PostCommentSuccess = /** @class */ (function (_super) {
    tslib_1.__extends(PostCommentSuccess, _super);
    function PostCommentSuccess(payload, user) {
        var _this = _super.call(this, SLICE.DISCUSSION, payload) || this;
        _this.payload = payload;
        _this.user = user;
        _this.actionName = discussionActions.POST_COMMENT_SUCCESS;
        _this.postedAt = new Date();
        return _this;
    }
    return PostCommentSuccess;
}(EntityAction));
export { PostCommentSuccess };
var ChangeDiscussionLock = /** @class */ (function (_super) {
    tslib_1.__extends(ChangeDiscussionLock, _super);
    function ChangeDiscussionLock(payload) {
        var _this = _super.call(this, SLICE.DISCUSSION, payload) || this;
        _this.actionName = discussionActions.CHANGE_DISCUSSION_LOCK;
        return _this;
    }
    return ChangeDiscussionLock;
}(EntityAction));
export { ChangeDiscussionLock };
var MarkAsRead = /** @class */ (function (_super) {
    tslib_1.__extends(MarkAsRead, _super);
    function MarkAsRead(payload) {
        var _this = _super.call(this, SLICE.DISCUSSION, payload) || this;
        _this.actionName = discussionActions.MARK_AS_READ;
        return _this;
    }
    return MarkAsRead;
}(EntityAction));
export { MarkAsRead };
var MarkAsReadSuccess = /** @class */ (function (_super) {
    tslib_1.__extends(MarkAsReadSuccess, _super);
    function MarkAsReadSuccess(payload) {
        var _this = _super.call(this, SLICE.DISCUSSION, payload) || this;
        _this.payload = payload;
        _this.actionName = discussionActions.MARK_AS_READ_SUCCESS;
        return _this;
    }
    return MarkAsReadSuccess;
}(EntityAction));
export { MarkAsReadSuccess };
