<div>
  <div class="spinner-holder">
    <div *ngIf="isTranferingData(progress$ | async); else notTransfering">
      <mat-progress-spinner [diameter]="130" [value]="(progress$ | async)?.percentage"
                            color="orange"></mat-progress-spinner>
      <div class="spinner-percentage">
        <span class="spinner-percentage-text">{{(progress$ | async)?.percentage}}</span>
        <span class="spinner-percentage-sign">%</span>
      </div>
    </div>
    <ng-template #notTransfering>
      <mat-spinner class="progress-spinner-no-precentage" [diameter]="130"></mat-spinner>
    </ng-template>
  </div>
  <div *ngIf="translationKey" class="spinner-message">
    {{translationKey | translate: translationData}}
    <caple-loading-dots></caple-loading-dots>
  </div>
  <div *ngIf="isTranferingData(progress$ | async)" class="spinner-message spinner-size">
    {{(progress$ | async)?.loaded | fileSize}} / {{(progress$ | async)?.total | fileSize}}
  </div>
  <div *ngIf="!(progress$ | async)?.total" class="spinner-message spinner-size">
    {{"generic.initializing" | translate }}
    <caple-loading-dots></caple-loading-dots>
  </div>
  <div *ngIf="(progress$ | async)?.percentage === 100" class="spinner-message spinner-size">
    {{"generic.processing" | translate }}
    <caple-loading-dots></caple-loading-dots>
  </div>
</div>
