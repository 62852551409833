import * as tslib_1 from "tslib";
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { EligibilityParent } from './eligibility-parent';
import { EligibilityCheckType } from '../shared/api/models/eligibilityCheckType';
import { isEBITToTotalDebtValid, isNetDebtToEBITDAValid } from './eligibility-validator';
var AdvancedEligibilityCriteriaComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AdvancedEligibilityCriteriaComponent, _super);
    function AdvancedEligibilityCriteriaComponent(store) {
        var _this = _super.call(this, store) || this;
        _this.START_SELECT_DRAWDOWN_YEAR = 2018;
        _this.END_SELECT_DRAWDOWN_YEAR = 2028;
        _this.years = [];
        for (var i = _this.START_SELECT_DRAWDOWN_YEAR; i <= _this.END_SELECT_DRAWDOWN_YEAR; i++) {
            _this.years.push(i.toString());
        }
        _this.form = new FormBuilder().group({
            drawdownQuarter: ['1', Validators.required],
            drawdownYear: [new Date().getFullYear().toString(), Validators.required],
            netDebt: [null, [Validators.required, Validators.min(0)]],
            ebitda: [null, [Validators.required, Validators.min(0)]],
            ebit: [null, [Validators.required, Validators.min(0)]],
            totalDebtService: [null, [Validators.required, Validators.min(0)]]
        });
        _this.setFormDisableSetting();
        _this.onFormChanged();
        return _this;
    }
    AdvancedEligibilityCriteriaComponent.prototype.patchEligibility = function (eligibility) {
        this.patchEligibilityCheck(eligibility.advancedEligibilityCheck);
    };
    AdvancedEligibilityCriteriaComponent.prototype.submitAdvancedEligibilityData = function () {
        this.submitEligibilityData(EligibilityCheckType.ADVANCED);
    };
    AdvancedEligibilityCriteriaComponent.prototype.getQuarterTitle = function () {
        var quarter = this.form.controls['drawdownQuarter'].value;
        var nextYear = Number.parseInt(this.form.controls['drawdownYear'].value, 10) + 1;
        return "Q" + quarter + " " + nextYear;
    };
    AdvancedEligibilityCriteriaComponent.prototype.getYearTitle = function () {
        var quarter = this.form.controls['drawdownQuarter'].value;
        var year = Number.parseInt(this.form.controls['drawdownYear'].value, 10);
        var nextYear = year + 1;
        return "Q" + quarter + " " + year + " - Q" + quarter + " " + nextYear;
    };
    AdvancedEligibilityCriteriaComponent.prototype.validateEligibility = function () {
        this.validateRatios();
    };
    AdvancedEligibilityCriteriaComponent.prototype.validateRatios = function () {
        var netDebt = this.form.controls['netDebt'].value;
        var ebitda = this.form.controls['ebitda'].value;
        if (!isNetDebtToEBITDAValid(netDebt / ebitda)) {
            this.addError('netDebtToEBITDAMoreThenMin', ['netDebt', 'ebitda']);
        }
        var ebit = this.form.controls['ebit'].value;
        var totalDebtService = this.form.controls['totalDebtService'].value;
        if (!isEBITToTotalDebtValid(ebit / totalDebtService)) {
            this.addError('EBITToTotalDebtServiceLessThenMin', ['ebit', 'totalDebtService']);
        }
    };
    return AdvancedEligibilityCriteriaComponent;
}(EligibilityParent));
export { AdvancedEligibilityCriteriaComponent };
