import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { RepeatableItem } from '../../../../../shared/layout/repeatable-form-item/repeatable-item';
import { DateValidators } from '../../../../../shared/util/date-validators';
import { select, Store } from '@ngrx/store';
import { selectActiveFundingProcessCountry } from '../../../../state-management/selector';
import { MatExpansionPanel } from '@angular/material/expansion';
import { CapleFormBuilder } from '../../../../../shared/util/caple-form-builder';
var HedgingFacilitiesItemComponent = /** @class */ (function (_super) {
    tslib_1.__extends(HedgingFacilitiesItemComponent, _super);
    function HedgingFacilitiesItemComponent(store) {
        var _this = _super.call(this) || this;
        _this.store = store;
        _this.removed = new EventEmitter();
        _this.country$ = _this.store.pipe(select(selectActiveFundingProcessCountry));
        return _this;
    }
    HedgingFacilitiesItemComponent.produceFormGroup = function (val) {
        val = val || {};
        return new CapleFormBuilder().group({
            'provider': [val.provider, Validators.required],
            'facilityType': [val.facilityType, Validators.required],
            'hedgingLimit': [val.hedgingLimit, [Validators.required, Validators.min(0)]],
            'notionalAmountCovered': [val.notionalAmountCovered, [Validators.required, Validators.min(0)]],
            'hedgingPurpose': [val.hedgingPurpose, Validators.required],
            'maturityDate': [val.maturityDate, [Validators.required, DateValidators.validDate]],
            'indicativeMarkToMarket': [val.indicativeMarkToMarket, Validators.required]
        });
    };
    HedgingFacilitiesItemComponent.prototype.produceFormGroup = function (val) {
        return HedgingFacilitiesItemComponent.produceFormGroup(val);
    };
    HedgingFacilitiesItemComponent.prototype.ngOnInit = function () {
        this.openOnStart(this.expansionPanel);
    };
    HedgingFacilitiesItemComponent.prototype.equalToSnapShot = function (val) {
        return this.item.get('provider').value === val.provider &&
            this.item.get('facilityType').value === val.facilityType;
    };
    HedgingFacilitiesItemComponent.prototype.open = function () {
        this.expansionPanel.open();
    };
    return HedgingFacilitiesItemComponent;
}(RepeatableItem));
export { HedgingFacilitiesItemComponent };
