import * as tslib_1 from "tslib";
import { actions, EntityAction } from '../../state-management/util/actions';
import { SLICE } from './slice';
export var monitoringActions = tslib_1.__assign({}, actions, { DOWNLOAD_FINANCIALS: 'DOWNLOAD_FINANCIALS', DOWNLOAD_CERTIFICATE: 'DOWNLOAD_CERTIFICATE', DOWNLOAD_FINANCIALS_TEMPLATE: 'DOWNLOAD_FINANCIALS_TEMPLATE', SUBMIT_FINANCIALS: 'SUBMIT_FINANCIALS', SUBMIT_FINANCIALS_SUCCESS: 'SUBMIT_FINANCIALS_SUCCESS', SUBMIT_CERTIFICATE: 'SUBMIT_CERTIFICATE', SUBMIT_CERTIFICATE_SUCCESS: 'SUBMIT_CERTIFICATE_SUCCESS', RESUBMIT_FINANCIALS: 'RESUBMIT_FINANCIALS', RESUBMIT_FINANCIALS_SUCCESS: 'RESUBMIT_FINANCIALS_SUCCESS', RESUBMIT_CERTIFICATE: 'RESUBMIT_CERTIFICATE', RESUBMIT_CERTIFICATE_SUCCESS: 'RESUBMIT_CERTIFICATE_SUCCESS', UPDATE_COVENANTS: 'UPDATE_COVENANTS', UPDATE_COVENANTS_SUCCESS: 'UPDATE_COVENANTS_SUCCESS', UPDATE_GENERAL_INFORMATION: 'UPDATE_GENERAL_INFORMATION', UPDATE_GENERAL_INFORMATION_SUCCESS: 'UPDATE_GENERAL_INFORMATION_SUCCESS', ADD_REPORTING_SOURCE: 'ADD_REPORTING_SOURCE', UPDATE_REPORTING_SOURCE: 'UPDATE_REPORTING_SOURCE', REMOVE_REPORTING_SOURCE: 'REMOVE_REPORTING_SOURCE', REMOVE_REPORTING_SOURCE_SUCCESS: 'REMOVE_REPORTING_SOURCE_SUCCESS', REQUEST_RESUBMISSION: 'REQUEST_RESUBMISSION', REQUEST_RESUBMISSION_SUCCESS: 'REQUEST_RESUBMISSION_SUCCESS', COMPLETE_REPORTING_DOCUMENTS: 'COMPLETE_REPORTING_DOCUMENTS', COMPLETE_REPORTING_DOCUMENTS_SUCCESS: 'COMPLETE_REPORTING_DOCUMENTS_SUCCESS' });
var DownloadReportingDocumentsFinancials = /** @class */ (function (_super) {
    tslib_1.__extends(DownloadReportingDocumentsFinancials, _super);
    function DownloadReportingDocumentsFinancials(slice, payload) {
        var _this = _super.call(this, slice, payload) || this;
        _this.slice = slice;
        _this.actionName = monitoringActions.DOWNLOAD_FINANCIALS;
        return _this;
    }
    return DownloadReportingDocumentsFinancials;
}(EntityAction));
export { DownloadReportingDocumentsFinancials };
var DownloadReportingDocumentsCertificate = /** @class */ (function (_super) {
    tslib_1.__extends(DownloadReportingDocumentsCertificate, _super);
    function DownloadReportingDocumentsCertificate(slice, payload) {
        var _this = _super.call(this, slice, payload) || this;
        _this.slice = slice;
        _this.actionName = monitoringActions.DOWNLOAD_CERTIFICATE;
        return _this;
    }
    return DownloadReportingDocumentsCertificate;
}(EntityAction));
export { DownloadReportingDocumentsCertificate };
var DownloadReportingDocumentsFinacialsTemplate = /** @class */ (function (_super) {
    tslib_1.__extends(DownloadReportingDocumentsFinacialsTemplate, _super);
    function DownloadReportingDocumentsFinacialsTemplate(slice, payload) {
        var _this = _super.call(this, slice, payload) || this;
        _this.slice = slice;
        _this.actionName = monitoringActions.DOWNLOAD_FINANCIALS_TEMPLATE;
        return _this;
    }
    return DownloadReportingDocumentsFinacialsTemplate;
}(EntityAction));
export { DownloadReportingDocumentsFinacialsTemplate };
var SubmitFinancials = /** @class */ (function (_super) {
    tslib_1.__extends(SubmitFinancials, _super);
    function SubmitFinancials(slice, payload) {
        var _this = _super.call(this, slice, payload) || this;
        _this.slice = slice;
        _this.actionName = monitoringActions.SUBMIT_FINANCIALS;
        return _this;
    }
    return SubmitFinancials;
}(EntityAction));
export { SubmitFinancials };
var SubmitFinancialsSuccess = /** @class */ (function (_super) {
    tslib_1.__extends(SubmitFinancialsSuccess, _super);
    function SubmitFinancialsSuccess(slice, request) {
        var _this = _super.call(this, slice) || this;
        _this.slice = slice;
        _this.request = request;
        _this.actionName = monitoringActions.SUBMIT_FINANCIALS_SUCCESS;
        return _this;
    }
    return SubmitFinancialsSuccess;
}(EntityAction));
export { SubmitFinancialsSuccess };
var SubmitCertificate = /** @class */ (function (_super) {
    tslib_1.__extends(SubmitCertificate, _super);
    function SubmitCertificate(slice, payload) {
        var _this = _super.call(this, slice, payload) || this;
        _this.slice = slice;
        _this.actionName = monitoringActions.SUBMIT_CERTIFICATE;
        return _this;
    }
    return SubmitCertificate;
}(EntityAction));
export { SubmitCertificate };
var SubmitCertificateSuccess = /** @class */ (function (_super) {
    tslib_1.__extends(SubmitCertificateSuccess, _super);
    function SubmitCertificateSuccess(slice, request) {
        var _this = _super.call(this, slice) || this;
        _this.slice = slice;
        _this.request = request;
        _this.actionName = monitoringActions.SUBMIT_CERTIFICATE_SUCCESS;
        return _this;
    }
    return SubmitCertificateSuccess;
}(EntityAction));
export { SubmitCertificateSuccess };
var ResubmitFinancials = /** @class */ (function (_super) {
    tslib_1.__extends(ResubmitFinancials, _super);
    function ResubmitFinancials(slice, payload) {
        var _this = _super.call(this, slice, payload) || this;
        _this.slice = slice;
        _this.actionName = monitoringActions.RESUBMIT_FINANCIALS;
        return _this;
    }
    return ResubmitFinancials;
}(EntityAction));
export { ResubmitFinancials };
var ResubmitFinancialsSuccess = /** @class */ (function (_super) {
    tslib_1.__extends(ResubmitFinancialsSuccess, _super);
    function ResubmitFinancialsSuccess(slice, request) {
        var _this = _super.call(this, slice) || this;
        _this.slice = slice;
        _this.request = request;
        _this.actionName = monitoringActions.RESUBMIT_FINANCIALS_SUCCESS;
        return _this;
    }
    return ResubmitFinancialsSuccess;
}(EntityAction));
export { ResubmitFinancialsSuccess };
var ResubmitCertificate = /** @class */ (function (_super) {
    tslib_1.__extends(ResubmitCertificate, _super);
    function ResubmitCertificate(slice, payload) {
        var _this = _super.call(this, slice, payload) || this;
        _this.slice = slice;
        _this.actionName = monitoringActions.RESUBMIT_CERTIFICATE;
        return _this;
    }
    return ResubmitCertificate;
}(EntityAction));
export { ResubmitCertificate };
var ResubmitCertificateSuccess = /** @class */ (function (_super) {
    tslib_1.__extends(ResubmitCertificateSuccess, _super);
    function ResubmitCertificateSuccess(slice, request) {
        var _this = _super.call(this, slice) || this;
        _this.slice = slice;
        _this.request = request;
        _this.actionName = monitoringActions.RESUBMIT_CERTIFICATE_SUCCESS;
        return _this;
    }
    return ResubmitCertificateSuccess;
}(EntityAction));
export { ResubmitCertificateSuccess };
var AddReportingSource = /** @class */ (function (_super) {
    tslib_1.__extends(AddReportingSource, _super);
    function AddReportingSource(payload) {
        var _this = _super.call(this, SLICE.MONITORING_FILE) || this;
        _this.payload = payload;
        _this.actionName = monitoringActions.ADD_REPORTING_SOURCE;
        return _this;
    }
    return AddReportingSource;
}(EntityAction));
export { AddReportingSource };
var UpdateReportingSource = /** @class */ (function (_super) {
    tslib_1.__extends(UpdateReportingSource, _super);
    function UpdateReportingSource(payload) {
        var _this = _super.call(this, SLICE.MONITORING_FILE) || this;
        _this.payload = payload;
        _this.actionName = monitoringActions.UPDATE_REPORTING_SOURCE;
        return _this;
    }
    return UpdateReportingSource;
}(EntityAction));
export { UpdateReportingSource };
var RemoveReportingSource = /** @class */ (function (_super) {
    tslib_1.__extends(RemoveReportingSource, _super);
    function RemoveReportingSource(payload) {
        var _this = _super.call(this, SLICE.MONITORING_FILE) || this;
        _this.payload = payload;
        _this.actionName = monitoringActions.REMOVE_REPORTING_SOURCE;
        return _this;
    }
    return RemoveReportingSource;
}(EntityAction));
export { RemoveReportingSource };
var RemoveReportingSourceSuccess = /** @class */ (function (_super) {
    tslib_1.__extends(RemoveReportingSourceSuccess, _super);
    function RemoveReportingSourceSuccess(payload) {
        var _this = _super.call(this, SLICE.MONITORING_FILE) || this;
        _this.payload = payload;
        _this.actionName = monitoringActions.REMOVE_REPORTING_SOURCE_SUCCESS;
        return _this;
    }
    return RemoveReportingSourceSuccess;
}(EntityAction));
export { RemoveReportingSourceSuccess };
var UpdateCovenants = /** @class */ (function (_super) {
    tslib_1.__extends(UpdateCovenants, _super);
    function UpdateCovenants(payload) {
        var _this = _super.call(this, SLICE.MONITORING_FILE, payload) || this;
        _this.actionName = monitoringActions.UPDATE_COVENANTS;
        return _this;
    }
    return UpdateCovenants;
}(EntityAction));
export { UpdateCovenants };
var UpdateCovenantsSuccess = /** @class */ (function (_super) {
    tslib_1.__extends(UpdateCovenantsSuccess, _super);
    function UpdateCovenantsSuccess(request) {
        var _this = _super.call(this, SLICE.MONITORING_FILE) || this;
        _this.request = request;
        _this.actionName = monitoringActions.UPDATE_COVENANTS_SUCCESS;
        return _this;
    }
    return UpdateCovenantsSuccess;
}(EntityAction));
export { UpdateCovenantsSuccess };
var UpdateGeneralInformation = /** @class */ (function (_super) {
    tslib_1.__extends(UpdateGeneralInformation, _super);
    function UpdateGeneralInformation(payload) {
        var _this = _super.call(this, SLICE.MONITORING_FILE, payload) || this;
        _this.actionName = monitoringActions.UPDATE_GENERAL_INFORMATION;
        return _this;
    }
    return UpdateGeneralInformation;
}(EntityAction));
export { UpdateGeneralInformation };
var UpdateGeneralInformationSuccess = /** @class */ (function (_super) {
    tslib_1.__extends(UpdateGeneralInformationSuccess, _super);
    function UpdateGeneralInformationSuccess(request) {
        var _this = _super.call(this, SLICE.MONITORING_FILE) || this;
        _this.request = request;
        _this.actionName = monitoringActions.UPDATE_GENERAL_INFORMATION_SUCCESS;
        return _this;
    }
    return UpdateGeneralInformationSuccess;
}(EntityAction));
export { UpdateGeneralInformationSuccess };
var RequestResubmission = /** @class */ (function (_super) {
    tslib_1.__extends(RequestResubmission, _super);
    function RequestResubmission(slice, payload) {
        var _this = _super.call(this, slice, payload) || this;
        _this.slice = slice;
        _this.actionName = monitoringActions.REQUEST_RESUBMISSION;
        return _this;
    }
    return RequestResubmission;
}(EntityAction));
export { RequestResubmission };
var RequestResubmissionSuccess = /** @class */ (function (_super) {
    tslib_1.__extends(RequestResubmissionSuccess, _super);
    function RequestResubmissionSuccess(slice, request) {
        var _this = _super.call(this, slice) || this;
        _this.slice = slice;
        _this.request = request;
        _this.actionName = monitoringActions.REQUEST_RESUBMISSION_SUCCESS;
        return _this;
    }
    return RequestResubmissionSuccess;
}(EntityAction));
export { RequestResubmissionSuccess };
var CompleteReportingDocuments = /** @class */ (function (_super) {
    tslib_1.__extends(CompleteReportingDocuments, _super);
    function CompleteReportingDocuments(slice, payload) {
        var _this = _super.call(this, slice, payload) || this;
        _this.slice = slice;
        _this.actionName = monitoringActions.COMPLETE_REPORTING_DOCUMENTS;
        return _this;
    }
    return CompleteReportingDocuments;
}(EntityAction));
export { CompleteReportingDocuments };
var CompleteReportingDocumentsSuccess = /** @class */ (function (_super) {
    tslib_1.__extends(CompleteReportingDocumentsSuccess, _super);
    function CompleteReportingDocumentsSuccess(slice, request) {
        var _this = _super.call(this, slice) || this;
        _this.slice = slice;
        _this.request = request;
        _this.actionName = monitoringActions.COMPLETE_REPORTING_DOCUMENTS_SUCCESS;
        return _this;
    }
    return CompleteReportingDocumentsSuccess;
}(EntityAction));
export { CompleteReportingDocumentsSuccess };
