import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { AbstractApiService } from './abstract-api.service';
var ConditionsPrecedentService = /** @class */ (function (_super) {
    tslib_1.__extends(ConditionsPrecedentService, _super);
    function ConditionsPrecedentService(httpClient) {
        var _this = _super.call(this, httpClient) || this;
        _this.baseUrl = '/api/v1/conditions-precedent';
        return _this;
    }
    ConditionsPrecedentService.prototype.createSingle = function (payload) {
        return this.post(this.baseUrl, payload);
    };
    ConditionsPrecedentService.prototype.loadSingle = function (payload) {
        return this.get(this.baseUrl + "/" + payload.id);
    };
    ConditionsPrecedentService.prototype.updateFile = function (payload) {
        return this.post(this.baseUrl + "/" + payload.id, payload.updatedFile);
    };
    ConditionsPrecedentService.prototype.publishFile = function (payload) {
        return this.post(this.baseUrl + "/" + payload.id + "/publish", null);
    };
    ConditionsPrecedentService.prototype.uploadResource = function (file) {
        var formData = new FormData();
        formData.append('file', file);
        return this.postFile(this.baseUrl + "/resources", formData);
    };
    ConditionsPrecedentService.prototype.uploadFile = function (id, file) {
        var formData = new FormData();
        formData.append('file', file);
        return this.postFile(this.baseUrl + "/" + id.id + "/files", formData);
    };
    ConditionsPrecedentService.prototype.downloadResource = function (id) {
        return this.getFileWithProgress(this.baseUrl + "/" + id.id + "/resources/" + id.resourceId);
    };
    ConditionsPrecedentService.prototype.downloadFile = function (filePayload) {
        return this.getFileWithProgress(this.baseUrl + "/" + filePayload.id + "/" + filePayload.conditionId + "/" + filePayload.fileId);
    };
    ConditionsPrecedentService.prototype.downloadZip = function (id) {
        return this.getFileWithProgress(this.baseUrl + "/" + id.id + "/zip");
    };
    ConditionsPrecedentService.prototype.removeFile = function (filePayload) {
        return this.delete(this.baseUrl + "/" + filePayload.id + "/" + filePayload.conditionId + "/" + filePayload.fileId);
    };
    ConditionsPrecedentService.prototype.attachFile = function (payload) {
        return this.post(this.baseUrl + "/" + payload.id + "/" + payload.conditionId, payload.fileDetails);
    };
    ConditionsPrecedentService.prototype.loadAll = function () {
        return this.get("" + this.baseUrl);
    };
    ConditionsPrecedentService.prototype.assessFile = function (payload) {
        return this.post(this.baseUrl + "/" + payload.id + "/" + payload.conditionId + "/" + payload.fileId + "/assessment", {
            decision: payload.decision
        });
    };
    ConditionsPrecedentService.prototype.assessCondition = function (payload) {
        return this.post(this.baseUrl + "/" + payload.id + "/" + payload.conditionId + "/assessment", {
            completed: payload.completed
        });
    };
    ConditionsPrecedentService.prototype.loadDefault = function (payload) {
        return this.get(this.baseUrl + "/defaults/" + payload.id);
    };
    return ConditionsPrecedentService;
}(AbstractApiService));
export { ConditionsPrecedentService };
