import { EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EligibilityData } from '../shared/api/models/eligibilityData';
import { EligibilityCheck } from '../shared/api/models/eligibilityCheck';
import { EligibilityCheckType } from '../shared/api/models/eligibilityCheckType';
import { CreateSingle, LoadSingle, UpdateSingle } from '../state-management/util/actions';
import { SLICE } from './state-management/slice';
import { select, Store } from '@ngrx/store';
import { selectEligibility, selectEligibilityEditable, selectEligibilityIdByCorrelationId } from './state-management/selector';
import { filter, take } from 'rxjs/operators';
import { State } from '../state-management/reducers';
import { ReadEligibilityCheck } from '../shared/api/models/readEligibilityCheck';
import { Subscription } from 'rxjs';
import * as empty from 'deep-empty-object';
import { ReadEligibility } from '../shared/api/models/readEligibility';

export abstract class EligibilityParent implements OnDestroy {

  @Output()
  public onCreated = new EventEmitter();

  protected _country: string;
  private _eligibilityId: string | null;
  protected subscriptions = new Subscription();
  protected loaded = false;

  public form: FormGroup;
  public errors: string[] = [];
  public notices: string[] = [];
  public failedControls: string[] = [];
  public checked = false;
  public editable = true;

  constructor(protected store: Store<State>) {
  }

  /**
   * This method should implement validation for eligibility check
   */
  abstract validateEligibility();

  /**
   * This method should call this.patchEligibilityCheck() with correct ReadEligibilityCheck object
   */
  abstract patchEligibility(eligibilityCheck: ReadEligibility);

  @Input()
  public set country(value) {
    this._country = value;
  }

  public get country() {
    return this._country;
  }

  @Input()
  public set eligibilityId(value) {
    this._eligibilityId = value;
    if (this.eligibilityId) {
      this.store.dispatch(new LoadSingle(SLICE.ELIGIBILITY, {id: this.eligibilityId}));
      this.store.pipe(select(selectEligibility({id: this.eligibilityId})),
        filter(eligibility => !!eligibility),
        take(1))
        .subscribe(eligibility => {
          this.patchEligibility(eligibility);
          this.loaded = true;
        });
    }
  }

  public get eligibilityId() {
    return this._eligibilityId;
  }

  protected checkEligibility() {
    this.errors = [];
    this.notices = [];
    this.failedControls = [];
    this.validateEligibility();
    this.checked = true;
  }

  protected onFormChanged() {
    this.subscriptions.add(this.form.valueChanges.subscribe(() => {
      this.checked = false;
    }));
  }

  protected setFormDisableSetting() {
    this.subscriptions.add(this.store.pipe(select(selectEligibilityEditable)).subscribe(isEditable => {
      if (!isEditable) {
        this.form.disable();
      }
    }));
  }

  protected submitEligibilityData(type: EligibilityCheckType) {
    this.setFormSettingsForValidation();
    if (this.form.valid) {
      this.checkEligibility();

      const eligibilityCheck: EligibilityCheck = {
        data: this.generateEligibilityData(),
        errors: this.errors,
        eligibilityCheckType: type
      };

      if (this.eligibilityId) {
        this.store.dispatch(new UpdateSingle(SLICE.ELIGIBILITY, {
          id: this.eligibilityId,
          eligibilityCheck: eligibilityCheck
        }));
      } else {
        const correlationId = new Date().getUTCMilliseconds().toString();

        this.store.dispatch(new CreateSingle(SLICE.ELIGIBILITY, {
          eligibilityCheck: eligibilityCheck,
          correlationId: correlationId
        }));

        this.store.pipe(
          select(selectEligibilityIdByCorrelationId(correlationId)),
          filter(value => !!value),
          take(1))
          .subscribe(value => {
            this.eligibilityId = value;
            this.onCreated.next({id: value});
          });
      }
    }
  }

  private setFormSettingsForValidation() {
    Object.keys(this.form.controls).forEach(field => {
      const control = this.form.get(field);
      control.updateValueAndValidity();
      control.markAsTouched({onlySelf: true});
    });
  }

  public isFailedControl(controlName: string) {
    return this.checked && this.failedControls.includes(controlName);
  }

  protected generateEligibilityData(): { [key: string]: EligibilityData; } {
    const formData = this.form.getRawValue();
    const eligibilityData = {};
    Object.keys(formData).forEach(field => {
      if (formData[field] !== null && formData[field] !== undefined) {
        eligibilityData[field] = {value: formData[field], valid: !this.isFailedControl(field)};
      }
    });
    return eligibilityData;
  }

  protected validateQuestion(key: string, correctAnswer: string): boolean {
    const result = this.checkQuestion(key, correctAnswer);
    if (!result) {
      this.addError(key, [key]);
    }
    return result;
  }

  protected checkQuestion(key: string, correctAnswer: string): boolean {
    const answer = this.form.controls[key].value;
    if (answer !== correctAnswer) {
      return false;
    }
    return true;
  }

  protected addError(criteria: string, controls: string[]) {
    this.errors.push(criteria);
    this.failedControls = [...this.failedControls, ...controls];
  }

  protected patchEligibilityCheck(eligibilityCheck: ReadEligibilityCheck) {
    if (eligibilityCheck) {
      const formData = {};
      const data = eligibilityCheck.data;
      Object.keys(data).forEach(key => {
        formData[key] = data[key].value;
        if (!data[key].valid) {
          this.failedControls.push(key);
        }
      });
      this.form.patchValue(formData);
      this.errors = [...eligibilityCheck.errors];
      this.checkEligibility();
    }
  }

  public formHasUnsavedChanges() {
    return !this.checked && !empty.all(this.form.getRawValue());
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
