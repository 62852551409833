import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { RepeatableItem } from '../../../../../shared/layout/repeatable-form-item/repeatable-item';
var IncumbentLenderItemComponent = /** @class */ (function (_super) {
    tslib_1.__extends(IncumbentLenderItemComponent, _super);
    function IncumbentLenderItemComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.removed = new EventEmitter();
        return _this;
    }
    IncumbentLenderItemComponent.produceFormGroup = function (val) {
        val = val || '';
        return new FormBuilder().control(val, Validators.required);
    };
    IncumbentLenderItemComponent.prototype.produceFormGroup = function (val) {
        return IncumbentLenderItemComponent.produceFormGroup(val);
    };
    return IncumbentLenderItemComponent;
}(RepeatableItem));
export { IncumbentLenderItemComponent };
