import { createFeatureSelector } from '@ngrx/store';
import { createForeignTableAdapter, createTableAdapter, ForeignTable, Table } from '../../state-management/util/adapters';
import { actions, EntityAction, IdType, LoadSingleSuccess } from '../../state-management/util/actions';
import { compare, typeFor } from '../../state-management/util/util';
import { SLICE } from './slice';
import { ReadMonitoringFile } from '../../shared/api/models/readMonitoringFile';
import { ReadCompany } from '../../shared/api/models/readCompany';
import { FundingProcess } from '../../shared/api/models/fundingProcess';
import {
  CompleteReportingDocumentsSuccess,
  monitoringActions,
  RemoveReportingSourceSuccess,
  RequestResubmissionSuccess,
  SubmitCertificateSuccess,
  SubmitFinancialsSuccess,
  UpdateCovenantsSuccess,
  UpdateGeneralInformationSuccess
} from './actions';
import { ReadReportingDocuments } from '../../shared/api/models/readReportingDocuments';
import { ReadReportingDocumentsType } from '../../shared/api/models/readReportingDocumentsType';
import { RequestResubmissionPayload } from '../../shared/api/services/monitoring-files.service';
import { ReadUser } from '../../shared/api/models/readUser';
import { usersAdapter } from '../../administration/state-management/reducer';

/********
 * Adapters
 ********/
export const monitoringFileAdapter = createTableAdapter<ReadMonitoringFile>({
  sort: (a, b) => {
    const dateA = compare(a.reportingEndDate, a.reportingYearEndDate) <= 0 ? a.reportingEndDate : a.reportingEndDate;
    const dateB = compare(b.reportingEndDate, b.reportingYearEndDate) <= 0 ? b.reportingEndDate : b.reportingEndDate;

    return compare(dateA, dateB);
  }
});
export const reportingDocumentsSummaryAdapter = createForeignTableAdapter<ReadReportingDocuments>({
  idSelector: (item: ReadReportingDocuments) => item.reportingFrequency + '-' + item.reportingPeriodNumber + '-' + item.startYear + '-' + item.version + '-' + item.type,
  sort: (a, b) => {
    const yearDifference = b.startYear - a.startYear;
    if (yearDifference === 0) {
      return b.reportingPeriodNumber - a.reportingPeriodNumber;
    }
    return yearDifference;
  }
});
export const companiesAdapter = createTableAdapter<ReadCompany>();
export const fundingProcessAdapter = createTableAdapter<FundingProcess>();

/********
 * State
 ********/
export interface State {
  monitoringFiles: Table<ReadMonitoringFile>;
  reportingDocumentsSummary: ForeignTable<ReadReportingDocuments>;
  companies: Table<ReadCompany>;
  fundingProcesses: Table<FundingProcess>;
  users: ForeignTable<ReadUser>;
}

export const initialState: State = {
  monitoringFiles: monitoringFileAdapter.getInitialState(),
  reportingDocumentsSummary: reportingDocumentsSummaryAdapter.getInitialState(),
  companies: companiesAdapter.getInitialState(),
  fundingProcesses: fundingProcessAdapter.getInitialState(),
  users: usersAdapter.getInitialState(),
};

export function reducer(state = initialState, action: EntityAction): State {
  function findPartialOrDefault(request): ReadReportingDocuments {
    const partial = state.reportingDocumentsSummary.entities[request.id].entities[request.reportingPeriod.reportingFrequency + '-' + request.reportingPeriod.reportingPeriodNumber + '-' + request.reportingPeriod.startYear + '-' + (request.version ? request.version : 1) + '-' + ReadReportingDocumentsType.PARTIAL];
    if (partial !== undefined) {
      return Object.assign({}, partial);
    }
    return {
      startYear: request.reportingPeriod.startYear,
      reportingPeriodNumber: request.reportingPeriod.reportingPeriodNumber,
      version: (request.version ? request.version : 1),
      type: ReadReportingDocumentsType.PARTIAL,
      financialsUploaded: false,
      certificateUploaded: false,
      reportingFrequency: request.reportingPeriod.reportingFrequency
    } as ReadReportingDocuments;
  }

  function markFinalAsOld(request: ReadReportingDocuments, id: IdType, stateToUpdate: State) {
    const currentFinal = stateToUpdate.reportingDocumentsSummary.entities[id].entities[request.reportingFrequency + '-' + request.reportingPeriodNumber + '-' + request.startYear + '-' + (request.version ? request.version - 1 : 1) + '-' + ReadReportingDocumentsType.FINAL];
    if (currentFinal) {
      const cleanedState = Object.assign({}, stateToUpdate, {
        reportingDocumentsSummary: reportingDocumentsSummaryAdapter.removeOne(currentFinal, id, stateToUpdate.reportingDocumentsSummary),
      });

      const updatedFinal = Object.assign(
        {},
        currentFinal,
        {
          type: ReadReportingDocumentsType.OLD,
          resubmissionRequested: false
        }
      );
      return Object.assign({}, cleanedState, {
        reportingDocumentsSummary: reportingDocumentsSummaryAdapter.addOne(
          updatedFinal, id, cleanedState.reportingDocumentsSummary)
      });
    } else {
      return stateToUpdate;
    }
  }

  switch (action.type) {
    case typeFor(SLICE.MONITORING_FILE, actions.SELECT): {
      return Object.assign({}, state, {
        monitoringFiles: monitoringFileAdapter.select(action.payload.id, state.monitoringFiles)
      });
    }
    case typeFor(SLICE.MONITORING_FILE, actions.LOAD_ALL_SUCCESS): {
      return Object.assign({}, state, {
        monitoringFiles: monitoringFileAdapter.addAll(action.payload, state.monitoringFiles)
      });
    }
    case typeFor(SLICE.MONITORING_FILE, actions.LOAD_SINGLE_SUCCESS): {
      return Object.assign({}, state, {
        monitoringFiles: monitoringFileAdapter.addOne(action.payload, state.monitoringFiles)
      });
    }
    case typeFor(SLICE.REPORTING_DOCUMENTS_SUMMARY, actions.LOAD_SINGLE_SUCCESS): {
      const monitoringFileId = (action as LoadSingleSuccess<ReadReportingDocuments>).request.id;
      return Object.assign({}, state, {
        reportingDocumentsSummary: reportingDocumentsSummaryAdapter.addAll(action.payload, monitoringFileId, state.reportingDocumentsSummary)
      });
    }
    case typeFor(SLICE.REPORTING_DOCUMENTS, monitoringActions.RESUBMIT_FINANCIALS_SUCCESS):
    case typeFor(SLICE.REPORTING_DOCUMENTS, monitoringActions.SUBMIT_FINANCIALS_SUCCESS): {
      const request = (action as SubmitFinancialsSuccess<any>).request;
      const partial = findPartialOrDefault(request);
      if (partial.certificateUploaded) {
        return completeReportingPeriodAfterFinancials(state, partial, request.id);
      } else {
        const finalMarkedAsOldState = markFinalAsOld(partial, request.id, state);
        partial.financialsUploaded = true;
        return Object.assign({}, finalMarkedAsOldState, {
          reportingDocumentsSummary: reportingDocumentsSummaryAdapter.addOne(
            partial, request.id, finalMarkedAsOldState.reportingDocumentsSummary)
        });
      }
    }
    case typeFor(SLICE.REPORTING_DOCUMENTS, monitoringActions.RESUBMIT_CERTIFICATE_SUCCESS):
    case typeFor(SLICE.REPORTING_DOCUMENTS, monitoringActions.SUBMIT_CERTIFICATE_SUCCESS): {
      const request = (action as SubmitCertificateSuccess<any>).request;
      const partial = findPartialOrDefault(request);
      if (partial.financialsUploaded) {
        const cleanedState = Object.assign({}, state, {
          reportingDocumentsSummary: reportingDocumentsSummaryAdapter.removeOne(partial, request.id, state.reportingDocumentsSummary),
        });
        return Object.assign({}, cleanedState, {
          reportingDocumentsSummary: reportingDocumentsSummaryAdapter.addOne(
            Object.assign({}, partial, {
              certificateUploaded: true,
              type: ReadReportingDocumentsType.FINAL
            }) as ReadReportingDocuments,
            request.id, cleanedState.reportingDocumentsSummary),
        });
      } else {
        const finalMarkedAsOldState = markFinalAsOld(partial, request.id, state);
        partial.certificateUploaded = true;
        return Object.assign({}, finalMarkedAsOldState, {
          reportingDocumentsSummary: reportingDocumentsSummaryAdapter.addOne(
            partial, request.id, finalMarkedAsOldState.reportingDocumentsSummary)
        });
      }
    }
    case typeFor(SLICE.REPORTING_DOCUMENTS, monitoringActions.REQUEST_RESUBMISSION_SUCCESS): {
      const request = (action as RequestResubmissionSuccess<any>).request as RequestResubmissionPayload;
      const reportingDocumentsList = state.reportingDocumentsSummary.entities[request.id].entities;
      for (const key of Object.keys(reportingDocumentsList)) {
        const reportingDocuments = reportingDocumentsList[key];
        if (reportingDocuments.type === ReadReportingDocumentsType.FINAL
          && request.reportingPeriod.reportingPeriodNumber === reportingDocuments.reportingPeriodNumber
          && request.reportingPeriod.reportingFrequency === reportingDocuments.reportingFrequency
          && request.reportingPeriod.startYear === reportingDocuments.startYear) {
          const newReportingDocuments = Object.assign({}, reportingDocuments, {resubmissionRequested: true});
          return Object.assign({}, state, {
            reportingDocumentsSummary: reportingDocumentsSummaryAdapter.updateOne(newReportingDocuments, request.id, state.reportingDocumentsSummary)
          });
        }
      }
      return state;
    }
    case typeFor(SLICE.REPORTING_DOCUMENTS, monitoringActions.COMPLETE_REPORTING_DOCUMENTS_SUCCESS): {
      const request = (action as CompleteReportingDocumentsSuccess<any>).request;
      const partial = findPartialOrDefault(request);
      return completeReportingPeriodAfterFinancials(state, partial, request.id);
    }
    case typeFor(SLICE.COMPANY, actions.LOAD_ALL_SUCCESS): {
      return Object.assign({}, state, {
        companies: companiesAdapter.addAll(action.payload, state.companies)
      });
    }
    case typeFor(SLICE.COMPANY, actions.LOAD_SINGLE_SUCCESS): {
      return Object.assign({}, state, {
        companies: companiesAdapter.addOne(action.payload, state.companies)
      });
    }
    case typeFor(SLICE.COMPANY_USERS, actions.LOAD_SINGLE_SUCCESS): {
      const companyId = (action as LoadSingleSuccess<ReadUser>).request.id;
      return Object.assign({}, state, {
        users: usersAdapter.addAll(action.payload, companyId, state.users)
      });
    }
    case typeFor(SLICE.FUNDING_PROCESS, actions.LOAD_ALL_SUCCESS): {
      return Object.assign({}, state, {
        fundingProcesses: fundingProcessAdapter.addPage(action.payload, state.fundingProcesses)
      });
    }
    case typeFor(SLICE.MONITORING_FILE, monitoringActions.UPDATE_COVENANTS_SUCCESS): {
      const event = <UpdateCovenantsSuccess>action;
      return Object.assign({}, state, {
        monitoringFiles: monitoringFileAdapter.updateOne(event.request.payload, state.monitoringFiles)
      });
    }
    case typeFor(SLICE.MONITORING_FILE, monitoringActions.UPDATE_GENERAL_INFORMATION_SUCCESS): {
      const event = <UpdateGeneralInformationSuccess>action;
      return Object.assign({}, state, {
        monitoringFiles: monitoringFileAdapter.updateOne(event.request.payload, state.monitoringFiles)
      });
    }
    case typeFor(SLICE.MONITORING_FILE, monitoringActions.REMOVE_REPORTING_SOURCE_SUCCESS): {
      const removeReportingSourceSuccess = <RemoveReportingSourceSuccess>action;
      const oldMonitoringFile = state.monitoringFiles.entities[removeReportingSourceSuccess.payload.id];
      const newReportingSources = oldMonitoringFile.reportingSources.filter(readReportingSource => removeReportingSourceSuccess.payload.reportingSourceId !== readReportingSource.company.id);
      const newMonitoringFile = Object.assign({}, oldMonitoringFile, {
        reportingSources: newReportingSources
      });
      return Object.assign({}, state, {
        monitoringFiles: monitoringFileAdapter.updateOne(newMonitoringFile, state.monitoringFiles)
      });
    }
  }
  return state;
}

function completeReportingPeriodAfterFinancials(state, partial, id) {
  const cleanedState = Object.assign({}, state, {
    reportingDocumentsSummary: reportingDocumentsSummaryAdapter.removeOne(partial, id, state.reportingDocumentsSummary),
  });
  return Object.assign({}, cleanedState, {
    reportingDocumentsSummary: reportingDocumentsSummaryAdapter.addOne(
      Object.assign({}, partial, {
        financialsUploaded: true,
        type: ReadReportingDocumentsType.FINAL
      }) as ReadReportingDocuments,
      id, cleanedState.reportingDocumentsSummary),
  });
}

export const selectFeature = createFeatureSelector<State>('monitoring');
