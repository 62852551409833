<caple-file-upload-button *ngIf="!hidden"
                          (fileSelected)="onFileSelected($event)"
                          label="{{label | translate}}"
                          [accept]="ACCEPTED_FILE_EXTENTIONS"
                          [isActionButton]="isActionButton"
                          [isSecondaryButton]="isSecondaryButton"
                          [isTextareaButton]="isTextareaButton"
                          [isPrimaryButton]="isPrimaryButton"
                          [iconBeforeText]="true"
                          [disabled]="disabled"
                          [disabledTooltip]="disabledTooltip"
></caple-file-upload-button>
