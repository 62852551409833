import * as tslib_1 from "tslib";
import { map, tap } from 'rxjs/operators';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { RedirectTo, ResetState } from '../generic/actions';
import { LoginActions } from './actions';
import { ShowToast } from '../layout/actions';
import { config } from '../../../environments/config';
import { Auth0Factory } from '../../login/login-form/auth0-factory.service';
import { Store } from '@ngrx/store';
var LoginEffects = /** @class */ (function () {
    function LoginEffects(router, actions$, store, auth0Factory) {
        var _this = this;
        this.router = router;
        this.actions$ = actions$;
        this.store = store;
        this.auth0Factory = auth0Factory;
        this.loginSuccess$ = this.actions$
            .pipe(ofType(LoginActions.LOGIN_SUCCESS), tap(function (action) {
            _this.router.navigateByUrl(action.redirectUri);
        }));
        this.logout$ = this.actions$
            .pipe(ofType(LoginActions.LOGOUT), tap(function () {
            _this.auth0Factory.createInstance().logout({
                returnTo: config.baseUrl + 'login'
            });
        }));
        this.unauthenticatedAccess$ = this.actions$
            .pipe(ofType(LoginActions.UNAUTHENTICATED_ACCESS), tap(function (action) {
            _this.router.navigate(['/login'], { queryParams: { redirectUri: action.redirectUri } });
        }), map(function () {
            return new ResetState();
        }));
        this.unauthorizedAccess$ = this.actions$
            .pipe(ofType(LoginActions.UNAUTHORIZED_ACCESS), map(function () { return new RedirectTo(['/dashboard']); }));
        this.tokenRenewalFailed$ = this.actions$
            .pipe(ofType(LoginActions.ACCESS_TOKEN_RENEW_FAILED), map(function () { return new ShowToast('generic.authentication.token-renewal-failed'); }));
    }
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], LoginEffects.prototype, "loginSuccess$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], LoginEffects.prototype, "logout$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], LoginEffects.prototype, "unauthenticatedAccess$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], LoginEffects.prototype, "unauthorizedAccess$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], LoginEffects.prototype, "tokenRenewalFailed$", void 0);
    return LoginEffects;
}());
export { LoginEffects };
