<h2 mat-dialog-title>{{ 'monitoring.update-reporting-source.title' | translate}} {{ company.name }}</h2>
<mat-dialog-content>
  <form [formGroup]="form">
    <div class="pure-g">
      <div class="pure-u-1-1">
        <caple-loading-wrapper [key]="['urn:users:load']" [diameter]="36">
          <mat-label>{{ 'monitoring.users' | translate }}</mat-label>
          <mat-list>
            <mat-list-item *ngFor="let user of (users$ | async)">
              <mat-checkbox [formControl]="form.get(user.id)" [value]="user.id">{{user.name}}</mat-checkbox>
            </mat-list-item>
          </mat-list>
        </caple-loading-wrapper>
      </div>
    </div>
  </form>

  <mat-dialog-actions>
    <button mat-button (click)="closeModal()">
      {{ 'generic.cancel' | translate }}
    </button>

    <caple-loading-wrapper key="urn:monitoring-file:update-reporting-source" [diameter]="30">
      <button mat-button (click)="updateReportingSource()">
        <span>{{'generic.update' | translate }}</span>
      </button>
    </caple-loading-wrapper>
  </mat-dialog-actions>
</mat-dialog-content>
