import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { AbstractApiService } from './abstract-api.service';
var PreferencesService = /** @class */ (function (_super) {
    tslib_1.__extends(PreferencesService, _super);
    function PreferencesService(httpClient) {
        var _this = _super.call(this, httpClient) || this;
        _this.baseUrl = '/api/v1/preferences';
        return _this;
    }
    PreferencesService.prototype.getPreferences = function () {
        return this.get(this.baseUrl);
    };
    PreferencesService.prototype.getNotificationPreferences = function () {
        return this.get(this.baseUrl + '/notifications');
    };
    PreferencesService.prototype.updateNotificationPreferences = function (notificationPreferences) {
        return this.post(this.baseUrl + '/notifications', notificationPreferences);
    };
    return PreferencesService;
}(AbstractApiService));
export { PreferencesService };
