/**
 * Caple Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface PromoteCompany {
    role?: PromoteCompany.RoleEnum;
}
export namespace PromoteCompany {
    export type RoleEnum = 'CLIENT' | 'DIRECT_CLIENT';
    export const RoleEnum = {
        CLIENT: 'CLIENT' as RoleEnum,
        DIRECTCLIENT: 'DIRECT_CLIENT' as RoleEnum
    }
}
