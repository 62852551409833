import * as tslib_1 from "tslib";
import { createSelector } from '@ngrx/store';
/**
 * Adapter for the table, always use this class to perform actions
 * on a Table<T>
 *
 * Don't instantiate a instance of this class yourself, use the
 * `createTableAdapter` function.
 */
var TableAdapter = /** @class */ (function () {
    function TableAdapter(options) {
        if (options === void 0) { options = {}; }
        this.selectId = options.idSelector || (function (model) { return model.id; });
        this.sort = options.sort;
    }
    /**
     * Get the initial state for the Table.
     * @returns {Table<T>}
     */
    TableAdapter.prototype.getInitialState = function () {
        return {
            ids: [],
            entities: {},
            size: 0,
            loaded: false
        };
    };
    /**
     * Get a set of selectors for the Table<V>, contains:
     * selectTable
     * selectIsLoaded
     * selectIds
     * selectEntities
     * selectAll
     * selectTotal
     * selectSingle (note that this is a function returning a selector)
     * selectSingleIsLoaded
     * selectSelected
     */
    TableAdapter.prototype.getSelectors = function (selectState) {
        var selectTable = function (state) { return state; };
        var selectIsLoaded = function (state) { return state.loaded; };
        var selectIds = function (state) { return state.ids; };
        var selectEntities = function (state) { return state.entities; };
        var selectAll = createSelector(selectIds, selectEntities, function (ids, entities) {
            return ids.map(function (id) { return entities[id]; });
        });
        var selectSize = function (state) { return state.size; };
        var selectSingle = function (withId) { return function (state) { return state.entities[withId.id]; }; };
        var selectSingleIsLoaded = function (withId) { return createSelector(selectSingle(withId), function (any) { return any !== undefined; }); };
        var selectSelected = function (state) { return state.selected ? state.entities[state.selected] : undefined; };
        if (!selectState) {
            return {
                selectTable: selectTable,
                selectIsLoaded: selectIsLoaded,
                selectIds: selectIds,
                selectEntities: selectEntities,
                selectAll: selectAll,
                selectSize: selectSize,
                selectSingle: selectSingle,
                selectSingleIsLoaded: selectSingleIsLoaded,
                selectSelected: selectSelected
            };
        }
        return {
            selectTable: createSelector(selectState, selectTable),
            selectIsLoaded: createSelector(selectState, selectIsLoaded),
            selectIds: createSelector(selectState, selectIds),
            selectEntities: createSelector(selectState, selectEntities),
            selectAll: createSelector(selectState, selectAll),
            selectSize: createSelector(selectState, selectSize),
            selectSingle: function (withId) { return createSelector(selectState, selectSingle(withId)); },
            selectSingleIsLoaded: function (withId) { return createSelector(selectState, selectSingleIsLoaded(withId)); },
            selectSelected: createSelector(selectState, selectSelected)
        };
    };
    /**
     * Does the same as addAll but clears the existing items
     */
    TableAdapter.prototype.reloadAll = function (newModels) {
        return this.addAll(newModels, this.getInitialState(), true);
    };
    /**
     * Add all the newModels to the Table. Comparison for this is done by id.
     *
     * @param {T[]} newModels The models to store in the table
     * @param {Table<T>} state The current state
     * @param {boolean} markAsLoaded Whether or not to mark the table as 'loaded' after this action completes
     * @returns {Table<T>}
     */
    TableAdapter.prototype.addAll = function (newModels, state, markAsLoaded) {
        var _this = this;
        if (markAsLoaded === void 0) { markAsLoaded = true; }
        var newIds = newModels.filter(function (model) { return !(_this.selectId(model) in state.entities); }).map(function (model) { return _this.selectId(model); });
        var ids;
        var entities;
        if (this.sort) {
            ids = Object.assign([], state.ids);
            entities = Object.assign({}, state.entities);
            newModels.forEach(function (model) {
                var _a;
                if (model !== undefined && model !== null) {
                    ids = _this.addIDAtCorrectIndex(entities, model, _this.selectId(model), ids);
                    entities = tslib_1.__assign({}, entities, (_a = {}, _a[_this.selectId(model)] = model, _a));
                }
            });
        }
        else {
            // Although id's can only be string, typescript doesn't get that the result of this expression is a
            // string[], therefore we cast explicitly. Will be fixed in https://github.com/Microsoft/TypeScript/issues/10727
            ids = state.ids.concat(newIds);
            entities = tslib_1.__assign({}, state.entities, newModels.reduce(function (map, obj) {
                map[_this.selectId(obj)] = obj;
                return map;
            }, {}));
        }
        return tslib_1.__assign({}, state, { loaded: markAsLoaded, size: ids.length, ids: ids,
            entities: entities });
    };
    TableAdapter.prototype.addPage = function (page, state, markAsLoaded) {
        var _this = this;
        if (markAsLoaded === void 0) { markAsLoaded = true; }
        var models = page.content;
        var newIds = models.map(function (model) { return _this.selectId(model); });
        // Empty existing lists of ids and entities, because we're creating a new page
        var ids = [];
        var entities = {};
        if (this.sort) {
            models.forEach(function (model) {
                var _a;
                if (model !== undefined && model !== null) {
                    ids = _this.addIDAtCorrectIndex(entities, model, _this.selectId(model), ids);
                    entities = tslib_1.__assign({}, entities, (_a = {}, _a[_this.selectId(model)] = model, _a));
                }
            });
        }
        else {
            // Although id's can only be string, typescript doesn't get that the result of this expression is a
            // string[], therefore we cast explicitly. Will be fixed in https://github.com/Microsoft/TypeScript/issues/10727
            ids = newIds.slice();
            entities = tslib_1.__assign({}, models.reduce(function (map, obj) {
                map[_this.selectId(obj)] = obj;
                return map;
            }, {}));
        }
        return tslib_1.__assign({}, state, { loaded: markAsLoaded, size: page.totalElements ? page.totalElements : ids.length, ids: ids,
            entities: entities });
    };
    /**
     * Add a single model to the table, will NOT change the state of `loaded`
     *
     * @param {T} newModel The new model
     * @param {Table<T>} state The current state
     * @returns {Table<T>}
     */
    TableAdapter.prototype.addOne = function (newModel, state) {
        if (newModel !== undefined && newModel !== null) {
            return this.addOneWithId(newModel, this.selectId(newModel), state);
        }
        else {
            return state;
        }
    };
    /**
     * Add a single model with a certain id. This ID does not have to equal the ID as retrieved by the
     * selectId function. This is usefull when you want to index entities on an other id then their own.
     *
     * @param {T} newModel
     * @param {IdType} id
     * @param {Table<T>} state
     */
    TableAdapter.prototype.addOneWithId = function (newModel, id, state) {
        var _a;
        if (newModel !== undefined && newModel !== null) {
            var ids = state.ids.slice();
            if (this.sort) {
                ids = this.addIDAtCorrectIndex(state.entities, newModel, id, ids);
            }
            else {
                if (ids.indexOf(id) < 0) {
                    ids.push(id);
                }
            }
            var entities = tslib_1.__assign({}, state.entities, (_a = {}, _a[id] = newModel, _a));
            return tslib_1.__assign({}, state, { size: ids.length, ids: ids,
                entities: entities });
        }
        else {
            return state;
        }
    };
    /**
     * Mark a certain entity as selected
     *
     * @param {IdType} id
     * @param {Table<T>} state
     * @returns {Table<T>}
     */
    TableAdapter.prototype.select = function (id, state) {
        return tslib_1.__assign({}, state, { selected: id });
    };
    /**
     * Remove a certain entity
     */
    TableAdapter.prototype.removeOne = function (id, state) {
        if (state.entities[id] !== undefined) {
            var entities = tslib_1.__assign({}, state.entities);
            delete entities[id];
            var ids = state.ids.slice();
            ids.splice(state.ids.indexOf(id), 1);
            return tslib_1.__assign({}, state, { size: ids.length, entities: entities,
                ids: ids });
        }
        else {
            return state;
        }
    };
    /**
     * Update a single entity. The new will merge together with the old model, overwriting
     * any existing keys and adding new ones. It will not remove keys.
     */
    TableAdapter.prototype.updateOne = function (newModel, state) {
        if (state.ids.indexOf(this.selectId(newModel)) < 0) {
            return state;
        }
        var ids = Object.assign([], state.ids);
        if (this.sort) {
            ids = this.addIDAtCorrectIndex(state.entities, newModel, this.selectId(newModel), ids);
        }
        var entities = tslib_1.__assign({}, state.entities);
        entities[this.selectId(newModel)] = Object.assign({}, entities[this.selectId(newModel)], newModel);
        return Object.assign({}, state, { ids: ids, entities: entities });
    };
    TableAdapter.prototype.addIDAtCorrectIndex = function (entities, model, modelId, ids) {
        var indexOf = ids.indexOf(modelId);
        if (indexOf >= 0) { // If the id is already in the list, remove it first
            ids.splice(indexOf, 1);
        }
        if (ids.length === 0) {
            ids.push(modelId);
            return ids;
        }
        for (var index = 0; index < ids.length; index++) {
            if (this.sort(model, entities[ids[index]]) <= 0) {
                ids.splice(index, 0, modelId);
                return ids;
            }
            else if (index === (ids.length - 1)) { // This model is the greatest, append it to the end
                ids.push(modelId);
                return ids;
            }
        }
    };
    return TableAdapter;
}());
export { TableAdapter };
/**
 * Create a new Table Adapter with the given options
 * @param {TableAdapterOptions} options
 * @returns {TableAdapter<T>}
 */
export function createTableAdapter(options) {
    return new TableAdapter(options);
}
/**
 * Adapter for the ForeignTable, always use this class to perform actions
 * on a ForeignTable<T>
 *
 * Don't instantiate a instance of this class yourself, use the
 * `createForeignTableAdapter` function.
 */
var ForeignTableAdapter = /** @class */ (function () {
    function ForeignTableAdapter(options) {
        if (options === void 0) { options = {}; }
        this.tableAdapter = createTableAdapter(options);
    }
    /**
     * Get the initial state
     * @returns {ForeignTable<T>}
     */
    ForeignTableAdapter.prototype.getInitialState = function () {
        return {
            ids: [],
            entities: {}
        };
    };
    /**
     * Will add the given newModels under the given ID. If there is already a Table<T> stored
     * under the given ID, then the newModels are added to that table as described
     * in TableAdapter::addAll.
     *
     * @param {T[]} newModels
     * @param {string} foreignId
     * @param {ForeignTable<T[]>} state
     * @returns {ForeignTable<T[]>}
     */
    ForeignTableAdapter.prototype.addAll = function (newModels, foreignId, state) {
        var _a;
        var currentState = state.entities[foreignId] || this.tableAdapter.getInitialState();
        var newItem = (_a = {},
            _a[foreignId] = this.tableAdapter.addAll(newModels, currentState),
            _a);
        var ids = state.ids;
        if (!state.entities[foreignId]) {
            // Although id's can only be string, typescript doesn't get that the result of this expression is a
            // string[], therefore we cast explicitly. Will be fixed in https://github.com/Microsoft/TypeScript/issues/10727
            ids = state.ids.concat([foreignId]);
        }
        var entities = tslib_1.__assign({}, state.entities, newItem);
        return { ids: ids, entities: entities };
    };
    /**
     * Will add the given newModel under the given ID. If there is already a Table<T> stored
     * under the given ID, then the newModel are added to that table as described
     * in TableAdapter::addOne.
     *
     * @param {T} newModel
     * @param {string} foreignId
     * @param {ForeignTable<T[]>} state
     * @returns {ForeignTable<T[]>}
     */
    ForeignTableAdapter.prototype.addOne = function (newModel, foreignId, state) {
        return this.addAll([newModel], foreignId, state);
    };
    /**
     * Remove an entity from the list of entities
     */
    ForeignTableAdapter.prototype.removeOne = function (entity, foreignId, state) {
        return this.removeOneById(this.tableAdapter.selectId(entity), foreignId, state);
    };
    /**
     * Remove an entity from the list of entities by entityId
     */
    ForeignTableAdapter.prototype.removeOneById = function (id, foreignId, state) {
        var _a;
        if (state.entities[foreignId]) {
            var newItem = (_a = {},
                _a[foreignId] = this.tableAdapter.removeOne(id, state.entities[foreignId]),
                _a);
            var entities = tslib_1.__assign({}, state.entities, newItem);
            return tslib_1.__assign({}, state, { entities: entities });
        }
        else {
            return state;
        }
    };
    /**
     * Update a record if it exists. For the exact update behaviour,
     * see TableAdapter::updateOne.
     *
     * @param {T} updatedModel
     * @param {IdType} foreignId
     * @param {ForeignTable<T>} state
     * @returns {ForeignTable<T>}
     */
    ForeignTableAdapter.prototype.updateOne = function (updatedModel, foreignId, state) {
        var _a;
        if (state.ids.indexOf(foreignId) < 0) {
            return state;
        }
        var newItem = (_a = {},
            _a[foreignId] = this.tableAdapter.updateOne(updatedModel, state.entities[foreignId]),
            _a);
        var entities = tslib_1.__assign({}, state.entities, newItem);
        return tslib_1.__assign({}, state, { entities: entities });
    };
    ForeignTableAdapter.prototype.getForeignSelectors = function (selectState, foreignId) {
        var _this = this;
        var foreignTableSelector = createSelector(selectState, function (foreignTable) {
            var filteredTable = foreignTable.entities[foreignId];
            return filteredTable || _this.tableAdapter.getInitialState();
        });
        return this.tableAdapter.getSelectors(foreignTableSelector);
    };
    return ForeignTableAdapter;
}());
export { ForeignTableAdapter };
/**
 * Create a new ForeignTable Adapter with the given options
 * @param {TableAdapterOptions} options
 * @returns {TableAdapter<T>}
 */
export function createForeignTableAdapter(options) {
    return new ForeignTableAdapter(options);
}
