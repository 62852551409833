import { InjectableRxStompConfig } from '@stomp/ng2-stompjs';
import { select, Store } from '@ngrx/store';
import { State } from '../state-management/reducers';
import { selectAccessToken } from '../state-management/login/selector';
import { filter, take } from 'rxjs/operators';
import { config as envConfig } from '../../environments/config';

export function createWebsocketConfig(store: Store<State>): InjectableRxStompConfig {
  return {
    brokerURL: envConfig.wsBaseUrl + 'api/v1/socket',
    heartbeatIncoming: 20000,
    heartbeatOutgoing: 20000,
    reconnectDelay: 500,
    beforeConnect: function () { // Invalidly marked as unused
      const self = this;
      return store.pipe(
        select(selectAccessToken),
        filter(a => !!a),
        filter(token => token.isNotExpired()),
        take(1)
      ).toPromise().then((value) => {
        self.stompClient.connectHeaders['Authorization'] = `Bearer ${value.getToken()}`;
      });
    },
    // This is kept here so you can easily enable it if you need debugging
    // debug: (msg: string): void => {
    //   console.warn(new Date(), msg);
    // },

  };
}
