import * as tslib_1 from "tslib";
import { FormGroupContainer } from '../../../../shared/layout/form-group-container';
import { Validators } from '@angular/forms';
import { ArrayValidators } from '../../../../shared/util/array-validators';
var CovenantsForm = /** @class */ (function (_super) {
    tslib_1.__extends(CovenantsForm, _super);
    function CovenantsForm(formBuilder) {
        var _this = _super.call(this, formBuilder.group({
            'covenants': formBuilder.array([], Validators.compose([ArrayValidators.minLength(1), ArrayValidators.maxLength(256)]), null)
        })) || this;
        _this.formBuilder = formBuilder;
        return _this;
    }
    return CovenantsForm;
}(FormGroupContainer));
export { CovenantsForm };
