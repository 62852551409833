/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../eligibility/advanced-eligibility-criteria.component.ngfactory";
import * as i2 from "../../../../eligibility/advanced-eligibility-criteria.component";
import * as i3 from "@ngrx/store";
import * as i4 from "@angular/common";
import * as i5 from "./advanced-eligiblity.component";
var styles_AdvancedEligiblityComponent = [];
var RenderType_AdvancedEligiblityComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AdvancedEligiblityComponent, data: {} });
export { RenderType_AdvancedEligiblityComponent as RenderType_AdvancedEligiblityComponent };
export function View_AdvancedEligiblityComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { advancedComponent: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 2, "caple-advanced-eligibility-criteria", [], null, [[null, "onCreated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onCreated" === en)) {
        var pd_0 = (_co.eligibilityCreated($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_AdvancedEligibilityCriteriaComponent_0, i1.RenderType_AdvancedEligibilityCriteriaComponent)), i0.ɵdid(2, 180224, [[1, 4], ["advancedComponent", 4]], 0, i2.AdvancedEligibilityCriteriaComponent, [i3.Store], { country: [0, "country"], eligibilityId: [1, "eligibilityId"], introText: [2, "introText"], passedText: [3, "passedText"] }, { onCreated: "onCreated" }), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.country$)); var currVal_1 = _co.eligiblityId; var currVal_2 = "funding-process.start.advanced-intro"; var currVal_3 = "funding-process.start.passed-advanced"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_AdvancedEligiblityComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "caple-start-funding-process-advanced-eligibility", [], null, null, null, View_AdvancedEligiblityComponent_0, RenderType_AdvancedEligiblityComponent)), i0.ɵdid(1, 49152, null, 0, i5.AdvancedEligiblityComponent, [i3.Store], null, null)], null, null); }
var AdvancedEligiblityComponentNgFactory = i0.ɵccf("caple-start-funding-process-advanced-eligibility", i5.AdvancedEligiblityComponent, View_AdvancedEligiblityComponent_Host_0, { eligiblityId: "eligiblityId" }, { checked: "checked", onCreated: "onCreated" }, []);
export { AdvancedEligiblityComponentNgFactory as AdvancedEligiblityComponentNgFactory };
