import { EMPTY, Observable, timer } from 'rxjs';
import { PreloadingStrategy, Route } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from './state-management/reducers';
import { selectHasOneOfRoles } from './state-management/login/selector';
import { flatMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

/**
 * Caple specific preloading strategy.
 */
@Injectable()
export class CaplePreloadStrategy implements PreloadingStrategy {

  constructor(private store: Store<State>) {

  }

  /**
   * Preload all modules unless the route mentions a role as being required which the user doesn't have then skip preloading that module.
   * @param route
   * @param load
   */
  preload(route: Route, load: Function): Observable<any> {
    if (route.data && route.data.onlyPreloadFor) {
      const hasRole = this.store.getSync(selectHasOneOfRoles(route.data.onlyPreloadFor));
      if (!hasRole) {
        return EMPTY;
      }
    }
    return this.loadRoute(load, route.data && route.data.preloadDelay);
  }

  private loadRoute(load: Function, delay: boolean) {
    // Add in a bit of random delay for each preload, to help spread out the network activity and the parsing moments the browser will experience
    const delayAmount = (delay ? 500 : 0) + Math.random() * 500;
    return timer(delayAmount).pipe(flatMap(() => load()));
  }
}
