/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../shared/layout/full-page-message/full-page-message.component.ngfactory";
import * as i2 from "../../shared/layout/full-page-message/full-page-message.component";
import * as i3 from "@angular/common";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "./login-failure.component";
import * as i10 from "@ngrx/store";
import * as i11 from "@ngx-translate/core";
import * as i12 from "@angular/router";
var styles_LoginFailureComponent = [];
var RenderType_LoginFailureComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoginFailureComponent, data: {} });
export { RenderType_LoginFailureComponent as RenderType_LoginFailureComponent };
function View_LoginFailureComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "error-message"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Something went wrong whilst trying to log in. Please return to the login page and try again."])), (_l()(), i0.ɵeld(3, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["This can happen if you used a bookmark to get to the login page, please make sure your bookmark points to "])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵeld(7, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Er is een fout opgetreden bij het inloggen. Ga terug naar de login pagina en probeer het opnieuw."])), (_l()(), i0.ɵeld(10, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Dit kan gebeuren als u een bladwijzer gebruikt om op de login pagina te komen. Zorg ervoor dat uw bladwijzer is ingesteld op "])), (_l()(), i0.ɵeld(12, 0, null, null, 1, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(13, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.url, ""); _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.url; _ck(_v, 6, 0, currVal_1); var currVal_2 = i0.ɵinlineInterpolate(1, "", _co.url, ""); _ck(_v, 12, 0, currVal_2); var currVal_3 = _co.url; _ck(_v, 13, 0, currVal_3); }); }
function View_LoginFailureComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "error-message"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Your account is currently blocked. If you believe this to be a mistake please contact "])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "a", [["href", "support@caple.eu"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["support@caple.eu"])), (_l()(), i0.ɵeld(5, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Uw account is momenteel geblokkeerd. Neem contact op als u denkt dat dit een fout is "])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "a", [["href", "support@caple.eu"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["support@caple.eu"]))], null, null); }
export function View_LoginFailureComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "caple-full-page-message", [], null, null, null, i1.View_FullPageMessageComponent_0, i1.RenderType_FullPageMessageComponent)), i0.ɵdid(1, 49152, null, 0, i2.FullPageMessageComponent, [], null, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_LoginFailureComponent_1)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_LoginFailureComponent_2)), i0.ɵdid(5, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, 0, 7, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i0.ɵdid(7, 16384, null, 0, i4.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(8, 0, null, null, 5, "button", [["class", "mat-action-button"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i0.ɵdid(9, 180224, null, 0, i6.MatButton, [i0.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵeld(10, 0, null, 0, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Login page"])), (_l()(), i0.ɵeld(12, 0, null, 0, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["arrow_back"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.reason; _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.reason == "blocked"); _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = (i0.ɵnov(_v, 9).disabled || null); var currVal_3 = (i0.ɵnov(_v, 9)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_2, currVal_3); }); }
export function View_LoginFailureComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "caple-login-failure-page", [], null, null, null, View_LoginFailureComponent_0, RenderType_LoginFailureComponent)), i0.ɵdid(1, 114688, null, 0, i9.LoginFailureComponent, [i10.Store, i11.TranslateService, i12.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginFailureComponentNgFactory = i0.ɵccf("caple-login-failure-page", i9.LoginFailureComponent, View_LoginFailureComponent_Host_0, {}, {}, []);
export { LoginFailureComponentNgFactory as LoginFailureComponentNgFactory };
