<h2 mat-dialog-title>{{ 'monitoring.edit-general-information.title' | translate}}</h2>
<mat-dialog-content>
  <div class="notice error" *ngIf="form.hasError('mismatchedReportingPeriodAndAnnual')">
    <i class="material-icons">error</i>
    <p>{{'monitoring.validation.invalid-reporting-period-annual-match' | translate}}</p>
  </div>
  <form [formGroup]="form">
    <div class="pure-g">
      <div class="pure-u-1-1">
        <mat-form-field class="full">
          <mat-select disabled="true" placeholder="{{ 'monitoring.reporting-frequency.title' | translate }}" [formControl]="form.controls['reportingFrequency']">
            <mat-option *ngFor="let frequency of ReportingFrequencyEnum" [value]="frequency">{{ 'monitoring.reporting-frequency.' + frequency |translate}}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.hasError('required', ['reportingFrequency'])">{{ 'generic.validation.required' | translate}}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="pure-g">
      <div class="pure-u-1-2">
        <caple-date-picker placeholder="monitoring.reporting-period-end-date"
                           formControlName="reportingEndDate"
                           [formControl]="form.get('reportingEndDate')"
                           datePickerFilter="LAST_DAY_OF_MONTH"
                           [matTooltip]="dueDateKeyIfControlIsDisabled(form.get('reportingEndDate')) | translate" [matTooltipPosition]="'above'">
          <mat-error *ngIf="form.get('reportingEndDate').hasError('date')">{{ 'generic.validation.date' | translate}}</mat-error>
          <mat-error *ngIf="form.get('reportingEndDate').hasError('required')">{{ 'generic.validation.required' | translate}}</mat-error>

        </caple-date-picker>
      </div>
      <div class="pure-u-1-2">
        <caple-date-picker placeholder="monitoring.reporting-year-end-date" formControlName="reportingYearEndDate"
                           [formControl]="form.get('reportingYearEndDate')"
                           datePickerFilter="LAST_DAY_OF_MONTH"
                           [matTooltip]="dueDateKeyIfControlIsDisabled(form.get('reportingYearEndDate')) | translate" [matTooltipPosition]="'above'">
          <mat-error *ngIf="form.get('reportingYearEndDate').hasError('date')">{{ 'generic.validation.date' | translate}}</mat-error>
          <mat-error *ngIf="form.get('reportingYearEndDate').hasError('required')">{{ 'generic.validation.required' | translate}}</mat-error>
        </caple-date-picker>
      </div>
    </div>

    <div class="pure-g">
      <div class="pure-u-1-2">
        <caple-date-picker placeholder="monitoring.first-interest-payment-by" formControlName="firstInterestPayment"
                           [formControl]="form.get('firstInterestPayment')">
          <mat-error *ngIf="form.get('firstInterestPayment').hasError('date')">{{ 'generic.validation.date' | translate}}</mat-error>
          <mat-error *ngIf="form.get('firstInterestPayment').hasError('required')">{{ 'generic.validation.required' | translate}}</mat-error>
        </caple-date-picker>
      </div>
      <div class="pure-u-1-2">
        <caple-date-picker placeholder="monitoring.first-redemption-payment-by" formControlName="firstRedemptionPayment"
                           [formControl]="form.get('firstRedemptionPayment')">
          <mat-error *ngIf="form.get('firstRedemptionPayment').hasError('date')">{{ 'generic.validation.date' | translate}}</mat-error>
          <mat-error *ngIf="form.get('firstRedemptionPayment').hasError('required')">{{ 'generic.validation.required' | translate}}</mat-error>
        </caple-date-picker>
      </div>
    </div>
    <div class="pure-g">
      <div class="pure-u-1-2">
        <mat-form-field class="full">
          <input type="number" matInput placeholder="{{ 'monitoring.term' | translate}}"
                 [formControl]="form.controls['term']"/>
          <mat-error *ngIf="form.hasError('required', ['term'])">{{ 'generic.validation.required' | translate}}</mat-error>
          <mat-error *ngIf="form.hasError('min', ['term'])">{{ 'generic.validation.min' | translate:{value: '1'} }}</mat-error>
        </mat-form-field>
      </div>
      <div class="pure-u-1-2">
        <mat-form-field class="full">
          <input type="number" matInput placeholder="{{ 'monitoring.interest-rate' | translate}}"
                 [formControl]="form.controls['interestRate']"/>
          <span matPrefix>%&nbsp;</span>
          <mat-error *ngIf="form.hasError('required', ['interestRate'])">{{ 'generic.validation.required' | translate}}</mat-error>
          <mat-error *ngIf="form.hasError('min', ['interestRate'])">{{ 'monitoring.create-monitoring-file.errors.interest-rate-between-0-100' |
            translate}}
          </mat-error>
          <mat-error *ngIf="form.hasError('max', ['interestRate'])">{{ 'monitoring.create-monitoring-file.errors.interest-rate-between-0-100' |
            translate}}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="pure-g">
      <div class="pure-u-1-2">
        <mat-form-field class="full">
          <caple-currency-input
            placeholder="{{ 'monitoring.loan-amount' | translate}}"
            [country]="monitoringFile?.borrower?.country"
            [formControl]="form.controls['loanAmount']"></caple-currency-input>
          <mat-error *ngIf="form.hasError('required', ['loanAmount'])">{{ 'generic.validation.required' | translate}}</mat-error>
          <mat-error *ngIf="form.hasError('min', ['loanAmount'])">{{ 'generic.validation.min' | translate:{value: '1'} }}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>

  <mat-dialog-actions>
    <button mat-button (click)="closeModal()">
      {{ 'generic.cancel' | translate }}
    </button>
    <caple-loading-wrapper key="urn:monitoring-file:general:update" [diameter]="30">
      <button mat-button (click)="updateGeneralInformation()" [disabled]="form.invalid">
        <span>{{'monitoring.edit-general-information.update' | translate }}</span>
      </button>
    </caple-loading-wrapper>
  </mat-dialog-actions>
</mat-dialog-content>

