<div class="option">
  <div class="pure-g">
    <div class="pure-u-1-12">
      <div class="checkbox-container form">
        <mat-radio-button (change)="changed($event)" [checked]="activeType == 'growth'" name="productType"
                          value="growth"></mat-radio-button>
      </div>
    </div>
    <div class="pure-u-11-12">
      <h2>{{ 'funding-process.product-type.dialog.growth' | translate}}</h2>
      <div class="description">{{ 'funding-process.product-type.dialog.growth-description' | translate}}</div>
      <div *ngIf="activeType == 'growth'" class="loan-size">
        <form [formGroup]="form" autocomplete="off">
          <mat-form-field>
            <caple-currency-input
              [country]="country$ | async"
              [formControl]="form.controls['loanSize']"
              placeholder="{{ 'funding-process.product-type.dialog.loan-size' | translate}}"></caple-currency-input>
            <mat-error
              *ngIf="form.hasError('required', ['loanSize'])">{{ 'generic.validation.required' | translate}}</mat-error>
            <mat-error
              *ngIf="form.hasError('min', ['loanSize'])">{{ 'funding-process.funding-proposal-min-max-loan' | translate}}</mat-error>
            <mat-error
              *ngIf="form.hasError('max', ['loanSize'])">{{ 'funding-process.funding-proposal-min-max-loan' | translate}}</mat-error>
          </mat-form-field>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="option" *ngIf="countryString == 'NL'">
  <div class="pure-g">
    <div class="pure-u-1-12">
      <div class="checkbox-container form">
        <mat-radio-button (change)="changed($event)" [checked]="activeType == 'transaction'" name="productType"
                          value="transaction"></mat-radio-button>
      </div>
    </div>
    <div class="pure-u-11-12">
      <h2>{{ 'funding-process.product-type.dialog.transaction' | translate}}</h2>
      <div class="description">{{ 'funding-process.product-type.dialog.transaction-description' | translate}}</div>
      <div *ngIf="activeType == 'transaction'" class="transaction-type">
        <form [formGroup]="form" autocomplete="off">
          <mat-form-field class="mat-form-field-no-input">
            <input [formControl]="form.get('productSubType')" matInput>
            <mat-radio-group [formControl]="form.get('productSubType')">
              <mat-radio-button (change)="transactionTypeChanged($event)" [checked]="activeSubType == 'MBO'"
                                name="productSubType"
                                value="MBO">
                {{'funding-process.product-type.sub-types.MBO' | translate}}
              </mat-radio-button>
              <mat-radio-button (change)="transactionTypeChanged($event)" [checked]="activeSubType == 'MBI'"
                                name="productSubType"
                                value="MBI">
                {{'funding-process.product-type.sub-types.MBI' | translate}}
              </mat-radio-button>
              <mat-radio-button (change)="transactionTypeChanged($event)" [checked]="activeSubType == 'OTHER'"
                                name="productSubType"
                                value="OTHER">
                {{'funding-process.product-type.sub-types.OTHER' | translate}}
              </mat-radio-button>
            </mat-radio-group>
          </mat-form-field>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="option" *ngIf="countryString == 'GB'">
  <div class="pure-g">
    <div class="pure-u-1-12">
      <div class="checkbox-container form">
        <mat-radio-button (change)="changed($event)" [checked]="activeType == 'transaction' && activeSubType == 'MBO'" name="productType"
                          value="MBO"></mat-radio-button>
      </div>
    </div>
    <div class="pure-u-11-12 no-explanation">
      <h2>{{ 'funding-process.product-type.sub-types.MBO' | translate}}</h2>
    </div>
  </div>
</div>

<div class="option">
  <div class="pure-g">
    <div class="pure-u-1-12">
      <div class="checkbox-container form">
        <mat-radio-button (change)="changed($event)" [checked]="activeType == 'acquisition'" name="productType"
                          value="acquisition"></mat-radio-button>
      </div>
    </div>
    <div class="pure-u-11-12 no-explanation">
      <h2>{{ 'funding-process.product-type.dialog.acquisition' | translate}}</h2>
    </div>
  </div>
</div>

<div class="option" *ngIf="countryString == 'GB'">
  <div class="pure-g">
    <div class="pure-u-1-12">
      <div class="checkbox-container form">
        <mat-radio-button (change)="changed($event)" [checked]="activeType == 'mbi'" name="productType"
                          value="mbi"></mat-radio-button>
      </div>
    </div>
    <div class="pure-u-11-12 no-explanation">
      <h2>{{ 'funding-process.product-type.dialog.mbi' | translate}}</h2>
    </div>
  </div>
</div>

<div class="option" *ngIf="countryString == 'GB'">
  <div class="pure-g">
    <div class="pure-u-1-12">
      <div class="checkbox-container form">
        <mat-radio-button (change)="changed($event)" [checked]="activeType == 'transaction' && activeSubType == 'OTHER'" name="productType"
                          value="OTHER"></mat-radio-button>
      </div>
    </div>
    <div class="pure-u-11-12 no-explanation">
      <h2>{{ 'funding-process.product-type.sub-types.OTHER' | translate}}</h2>
    </div>
  </div>
</div>

<div class="help">
  {{'funding-process.product-type.help' | translate}}
</div>
