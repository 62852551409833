import { config } from '../../../environments/config';

export class AccessToken {

  public constructor(private encodedToken: string, private decodedToken: any = {}) {
  }

  public getCompanyId(): string {
    return this.decodedToken[config.auth0namespace + '/companyId'];
  }

  public getCountry(): string {
    return this.decodedToken[config.auth0namespace + '/country'];
  }

  public getToken(): string {
    return this.encodedToken;
  }

  public isNotExpired(): boolean {
    return this.decodedToken['exp'] > AccessToken.toJwtTimestamp(new Date().getTime());
  }

  public getExpiresIn(): number {
    return this.decodedToken['exp'] - AccessToken.toJwtTimestamp(new Date().getTime());
  }

  public getRoles(): string[] {
    const roles = this.decodedToken[config.auth0namespace + '/roles'];
    return roles ? roles : [];
  }

  public getUserId(): string {
    return this.decodedToken['sub'];
  }

  public getName(): string {
    return this.decodedToken[config.auth0namespace + '/name'];
  }

  public getEmail(): string {
    return this.decodedToken[config.auth0namespace + '/email'];
  }

  /**
   * Convert a normal timestamp to a JWT timestamp
   *
   * JWT's use timestamps in seconds while by default timestamps are in milliseconds.
   */
  public static toJwtTimestamp(timestamp: number) {
    return timestamp / 1000;
  }
}
