import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractApiService } from './abstract-api.service';
import { Changelog } from '../models/changelog';
import { Release } from '../models/release';

@Injectable()
export class ChangelogService extends AbstractApiService {

  private baseUrl = '/api/v1/changelog/';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getAll() {
    return this.get<Changelog>(this.baseUrl);
  }

  public createOne(release: Release) {
    return this.post(this.baseUrl, release);
  }

  public markAsRead() {
    return this.post(`${this.baseUrl}mark-as-read`, null);
  }
}
