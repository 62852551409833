import {createSelector} from '@ngrx/store';
import {
  chamberOfCommerceAdapter,
  clientAdapter,
  eligibilityAssessmentPrefillAdapter,
  financialRatingAdapter,
  fundingProcessAdapter,
  fundingProcessSummaryAdapter,
  fundingProposalSnapshotAdapter,
  fundingProposalTranslationAdapter,
  kycAssessmentPrefillAdapter,
  kycStatusAdapter,
  metaModelAdapter,
  metaModelIdentifierAdapter,
  selectFeature,
  State,
  teamAdapter
} from './reducer';
import {FundingProcess} from '../../shared/api/models/fundingProcess';
import {ForeignTable} from '../../state-management/util/adapters';
import {FinancialRatingSummary} from '../../shared/api/models/financialRatingSummary';
import {WithId} from '../../state-management/util/actions';
import {isNullOrUndefined} from 'util';
import {Phase} from '../../shared/api/models/phase';
import {ReadUser} from '../../shared/api/models/readUser';
import {FundingProposalStatus} from '../../shared/api/models/fundingProposalStatus';
import {AssessmentStatus} from '../../shared/api/models/assessmentStatus';
import {Role} from '../../shared/api/models/role';
import {selectHasOneOfRoles} from '../../state-management/login/selector';
import {ReadFileDetails} from '../../shared/api/models/readFileDetails';
import {FundingProposalMode} from '../funding-process/funding-proposal/shared/funding-proposal-mode';

const selectFundingProcessesSummariesSlice = createSelector(selectFeature, (state: State) => state.fundingProcessesSummaries);
const selectFundingProcessesSlice = createSelector(selectFeature, (state: State) => state.fundingProcesses);
const selectFundingProposalSnapshotSlice = createSelector(selectFeature, (state: State) => state.fundingProposalSnapshot);
const selectFundingProposalTranslationSlice = createSelector(selectFeature, (state: State) => state.fundingProposalTranslation);
const selectfundingProposalModeSlice = createSelector(selectFeature, (state: State) => state.fundingProposalMode);
const selectClientsSlice = createSelector(selectFeature, (state: State) => state.clients);
const selectClientUsersSlice = createSelector(selectFeature, (state: State) => state.client_users);
const selectFinancialRatingSummariesSlice = createSelector(selectFeature, (state: State) => state.financialRatingSummaries);
const selectFinancialRatingsSlice = createSelector(selectFeature, (state: State) => state.financialRatings);
const selectFinancialRatingMetaModelsSlice = createSelector(selectFeature, (state: State) => state.financialRatingMetaModels);
const selectFundingProposalMetaModelsSlice = createSelector(selectFeature, (state: State) => state.fundingProposalMetaModels);
const selectFundingProposalMetaModelLatestSlice = createSelector(selectFeature, (state: State) => state.fundingProposalMetaModelLatest);
const selectValidationsSlice = createSelector(selectFeature, (state: State) => state.fundingProcessValidations);
const selectChamberOfCommerceSlice = createSelector(selectFeature, (state: State) => state.chamberOfCommerce);
const selectChamberOfCommerceNameSlice = createSelector(selectFeature, (state: State) => state.chamberOfCommerceName);
const selectKycStatusSlice = createSelector(selectFeature, (state: State) => state.kycStatus);
const selectTeamSlice = createSelector(selectFeature, (state: State) => state.team);
const selectEligibilityAssessmentPrefillSlice = createSelector(selectFeature, (state: State) => state.eligibilityAssessmentPrefill);
const selectKycAssessmentPrefillSlice = createSelector(selectFeature, (state: State) => state.kycAssessmentPrefill);

/////////////
//// Selectors
/////////////

/** Clients **/
export const {
  selectAll: selectClients,
  selectSingle: selectClient,
  selectIsLoaded: selectClientsIsLoaded
} = clientAdapter.getSelectors(selectClientsSlice);

export const selectClientUsers = (client: WithId) => createSelector(selectClientUsersSlice,
  (users: ForeignTable<ReadUser>) => users.entities[client.id]);
export const selectClientUsersIsLoaded = (client: WithId) => createSelector(selectClientUsers(client),
  (users) => users !== undefined);

/** FundingProcesses **/
export const {
  selectSingleIsLoaded: selectFundingProcessSummaryIsLoaded,
  selectAll: selectFundingProcessesSummaries,
  selectSelected: selectActiveFundingProcessSummary,
  selectIsLoaded: selectFundingProcessesSummariesIsLoaded,
  selectSize: selectFundingProcessesSummariesSize,
} = fundingProcessSummaryAdapter.getSelectors(selectFundingProcessesSummariesSlice);

export const {
  selectSingleIsLoaded: selectFundingProcessIsLoaded,
  selectSelected: selectActiveFundingProcess,
  selectSingle: selectFundingProcess
} = fundingProcessAdapter.getSelectors(selectFundingProcessesSlice);

export const selectPhase = (fundingProcessId: WithId) => createSelector(selectFundingProcess(fundingProcessId),
  (fundingProcess?: FundingProcess) => fundingProcess ? fundingProcess.phase : undefined);
export const selectClientFromFundingProcess = (fundingProcessId: WithId) => createSelector(selectFundingProcess(fundingProcessId),
  (fundingProcess?: FundingProcess) => fundingProcess ? fundingProcess.client : undefined);
export const selectFundingProcessEditable = (withId: WithId) => createSelector(selectFundingProcess(withId), selectHasOneOfRoles([Role.DIRECTCLIENT, Role.PARTNER]),
  (fundingProcess: FundingProcess, hasRole) => isFundingProcessEditable(fundingProcess) && hasRole
);

export const selectActiveFundingProcessEditable = createSelector(selectActiveFundingProcess, selectHasOneOfRoles([Role.DIRECTCLIENT, Role.PARTNER]),
  (fundingProcess: FundingProcess, hasRole) => isFundingProcessEditable(fundingProcess) && hasRole
);
export const selectActiveFundingProcessCountry = createSelector(selectActiveFundingProcess, (fundingProcess: FundingProcess) => {
  return fundingProcess && fundingProcess.client.country;
});
export const selectActiveFundingProcessProposalVersion = createSelector(selectActiveFundingProcess, (fundingProcess: FundingProcess) => {
  return fundingProcess && fundingProcess.fundingProposalVersion;
});
export const selectActiveFundingProcessProduct = createSelector(selectActiveFundingProcess, (fundingProcess: FundingProcess) => {
  return fundingProcess && fundingProcess.product;
});

export const selectFinancialRatingEditable = (withId: WithId) => createSelector(selectFundingProcess(withId), selectHasOneOfRoles([Role.DIRECTCLIENT, Role.PARTNER]),
  (fundingProcess: FundingProcess, hasRole) => isFundingProcessEditable(fundingProcess) && hasRole
);

export const selectFundingProcessesFilteredCountries = createSelector(selectFeature, (state: State) => state.fundingProcessesFilteredCountries);
export const selectFundingProcessesFilteredStatuses = createSelector(selectFeature, (state: State) => state.fundingProcessesFilteredStatuses);

export const selectFundingProcessesSearchQuery = createSelector(selectFeature, (state: State) => state.fundingProcessesSearchQuery);

/** Financial Rating Summary **/
export const selectFinancialRatingSummariesForFundingProcess = (fundingProcess: WithId) => createSelector(selectFinancialRatingSummariesSlice,
  (financialRatings: ForeignTable<FinancialRatingSummary>) => financialRatings.entities[fundingProcess.id]);
export const selectFinancialRatingsSummariesForFundingProcessLoaded = (fundingProcess: WithId) => createSelector(selectFinancialRatingSummariesForFundingProcess(fundingProcess),
  (financialRatings) => financialRatings !== undefined);

/** Financial Rating **/
export const {
  selectSingle: selectFinancialRating,
  selectSingleIsLoaded: selectFinancialRatingIsLoaded
} = financialRatingAdapter.getSelectors(selectFinancialRatingsSlice);

export const {
  selectSingle: selectFinancialRatingMetaModel,
  selectSingleIsLoaded: selectFinancialRatingMetaModelIsLoaded
} = metaModelAdapter.getSelectors(selectFinancialRatingMetaModelsSlice);

/** Team **/
export const {
  selectSingle: selectTeam,
  selectSingleIsLoaded: selectTeamLoaded
} = teamAdapter.getSelectors(selectTeamSlice);

/** Funding Proposal **/
export const {
  selectSingle: selectFundingProposalMetaModel,
  selectSingleIsLoaded: selectFundingProposalMetaModelIsLoaded
} = metaModelAdapter.getSelectors(selectFundingProposalMetaModelsSlice);

export const {
  selectSingle: selectFundingProposalSnapshot,
  selectSingleIsLoaded: selectFundingProposalSnapshotIsLoaded
} = fundingProposalSnapshotAdapter.getSelectors(selectFundingProposalSnapshotSlice);

export const {
  selectSingle: selectFundingProposalTranslation,
  selectSingleIsLoaded: selectFundingProposalTranslationIsLoaded
} = fundingProposalTranslationAdapter.getSelectors(selectFundingProposalTranslationSlice);

export const {
  selectSingle: selectFundingProposalMetaModelLatest,
  selectSingleIsLoaded: selectFundingProposalMetaModelLatestlIsLoaded
} = metaModelIdentifierAdapter.getSelectors(selectFundingProposalMetaModelLatestSlice);

export const selectActiveProcessHasSnapshot = createSelector(selectActiveFundingProcess, selectFundingProposalSnapshotSlice,
  (fundingProcess: FundingProcess, snapshotSlice) => !!snapshotSlice.entities[fundingProcess.id]
);

export const selectActiveFundingProposalMode = createSelector(selectActiveFundingProcess, selectfundingProposalModeSlice,
  (fundingProcess: FundingProcess, modeSlice) => {
    if (fundingProcess) {
      const modeObject = modeSlice.entities[fundingProcess.id];
      if (modeObject) {
        return modeObject.mode;
      }
    }
    return FundingProposalMode.ORIGINAL;
  }
);

export const selectValidationForCompleteFundingProcess = (fundingProcess: WithId) =>
  createSelector(selectValidationsSlice, (validations: ForeignTable<{ id: string, valid: boolean }>) => {
    if (validations && validations.entities[fundingProcess.id]) {
      return Object.values(validations.entities[fundingProcess.id].entities).reduce((previousValue, value: { id: string, valid: boolean }) => previousValue && value.valid, true);
    } else {
      return undefined;
    }
  });
export const selectValidationForFundingProcess = (fundingProcess: WithId, validationId: string) =>
  createSelector(selectValidationsSlice, (validations: ForeignTable<{ id: string, valid: boolean }>) => {
    if (validations && validations.entities[fundingProcess.id] && validations.entities[fundingProcess.id].entities[validationId]) {
      return validations.entities[fundingProcess.id].entities[validationId].valid;
    } else {
      return undefined;
    }
  });

/** Assessment **/
export const selectFundingProcessAssessable = (withId: WithId) => createSelector(selectFundingProcess(withId),
  (fundingProcess) =>
    fundingProcess && fundingProcess.fundingProposal && fundingProcess.fundingProposal.status === FundingProposalStatus.COMPLETED && (!fundingProcess.assessment || fundingProcess.assessment.status === AssessmentStatus.INPROGRESS)
);

export const selectFundingProcessAssessmentCompleted = (withId: WithId) => createSelector(selectFundingProcess(withId),
  (fundingProcess) =>
    fundingProcess && fundingProcess.fundingProposal && fundingProcess.fundingProposal.status === FundingProposalStatus.COMPLETED && (!fundingProcess.assessment || fundingProcess.assessment.status === AssessmentStatus.COMPLETED)
);

export const selectActiveFundingProcessAssessmentEditable = createSelector(selectActiveFundingProcess, selectHasOneOfRoles([Role.CREDITOFFICER]),
  (fundingProcess: FundingProcess, hasRole) => fundingProcess && fundingProcess.phase !== Phase.COMPLETED && hasRole
);

export const selectFileInCommunication = (fundingprocessId: WithId, fileId: string) => createSelector(selectFundingProcess(fundingprocessId), (fundingProcess: FundingProcess): ReadFileDetails => {
  if (fundingProcess) {
    return fundingProcess.assessment.communicationFiles.find((file) => file.fileId === fileId);

  }
  return undefined;
});

export const {
  selectSingle: selectEligbilityAssessmentPrefill,
  selectSingleIsLoaded: selectEligbilityAssessmentPrefillIsLoaded
} = eligibilityAssessmentPrefillAdapter.getSelectors(selectEligibilityAssessmentPrefillSlice);

export const {
  selectSingle: selectKycAssessmentPrefill,
  selectSingleIsLoaded: selectKycAssessmentPrefillIsLoaded
} = kycAssessmentPrefillAdapter.getSelectors(selectKycAssessmentPrefillSlice);

/** Chamber of Commerce **/
export const {
  selectSingle: selectChamberOfCommerceResult,
  selectSingleIsLoaded: selectChamberOfCommerceIsLoaded
} = chamberOfCommerceAdapter.getSelectors(selectChamberOfCommerceSlice);

export const {
  selectSingle: selectChamberOfCommerceNameResult,
  selectSingleIsLoaded: selectChamberOfCommerceNameIsLoaded
} = chamberOfCommerceAdapter.getSelectors(selectChamberOfCommerceNameSlice);

/** KYC Status **/
export const {
  selectSingle: selectKYCStatus,
  selectSingleIsLoaded: selectKYCStatusIsLoaded
} = kycStatusAdapter.getSelectors(selectKycStatusSlice);


export const selectUsersForCompany = (companyId: string) => createSelector(selectFeature, (state: State) => {
  return state.users.entities[companyId];
});
export const selectUsersForCompanyLoaded = (company: WithId) => createSelector(selectFeature, (state: State) => {
  return state.users.entities[company.id] !== undefined;
});

/////////////
//// Helper functions
/////////////

function isFundingProcessEditable(fundingProcess: FundingProcess) {
  const proposalEditable = fundingProcess && fundingProcess.fundingProposal && (fundingProcess.fundingProposal.status === FundingProposalStatus.INPROGRESS || fundingProcess.fundingProposal.status === FundingProposalStatus.INAMENDMENT);
  const noProposal = fundingProcess && !fundingProcess.fundingProposal;
  return (proposalEditable || noProposal);
}

/**
 * Whether the current funding proposal is in at least one of the given Phases.=
 */
function isInPhase(phases: Phase[]) {
  // return (state: State) => phases.indexOf(state.phase) >= 0;
  return false;
}

/**
 * Will return false if the supplied boolean is null or undefined, otherwise it will return the value of the param
 */
function isValid(valid?: boolean) {
  if (isNullOrUndefined(valid)) {
    return false;
  }

  return valid;
}
