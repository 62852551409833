import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { RepeatableItem } from '../../../../../../shared/layout/repeatable-form-item/repeatable-item';
import { FormGroup, Validators } from '@angular/forms';
import { CapleFormBuilder } from '../../../../../../shared/util/caple-form-builder';
import { GrowthFundingSourceCategory } from '../../../../../../shared/api/models/growthFundingSourceCategory';
var CompanyFundingSourceItemComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyFundingSourceItemComponent, _super);
    function CompanyFundingSourceItemComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.removed = new EventEmitter();
        _this.FundingSourcesCategory = Object.keys(GrowthFundingSourceCategory).filter(function (k) { return k.indexOf('_') === -1; }).map(function (k) {
            return {
                key: k,
                value: GrowthFundingSourceCategory[k]
            };
        });
        return _this;
    }
    CompanyFundingSourceItemComponent.produceFormGroup = function (itemValueOpt) {
        var val = itemValueOpt || { source: null, amount: '', comment: '' };
        return new CapleFormBuilder().group({
            'source': [val.source, Validators.required],
            'amount': [val.amount, [Validators.required, Validators.min(0)]],
            'comment': val.comment
        });
    };
    CompanyFundingSourceItemComponent.prototype.produceFormGroup = function (val) {
        return CompanyFundingSourceItemComponent.produceFormGroup(val);
    };
    CompanyFundingSourceItemComponent.prototype.equalToSnapShot = function (val) {
        return this.item.get('source').value === val.source;
    };
    return CompanyFundingSourceItemComponent;
}(RepeatableItem));
export { CompanyFundingSourceItemComponent };
