import { select, Store } from '@ngrx/store';
import { State } from '../../state-management/reducers';
import { CloseModal, ScrollTo } from '../../state-management/layout/actions';
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DiscussionIdPayload, ResourceType } from '../../shared/api/services/discussion.service';
import { LoadSingle } from '../../state-management/util/actions';
import { SLICE } from '../state-management/slice';
import { Discussion } from '../../shared/api/models/discussion';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { selectDiscussion } from '../state-management/selector';
import { MarkAsRead, PostComment, StartDiscussion } from '../state-management/actions';

@Component({
  selector: 'caple-discussion-dialog',
  templateUrl: './discussion-dialog.component.html'
})
export class DiscussionDialogComponent implements OnDestroy, OnInit {

  /**
   * The content of the message the user is currently typing
   * @type {string}
   */
  public message = '';

  /**
   * The ID of the current discussion, is a null of not set and other wise just the ID
   */
  public discussionId: string | null = null;

  /**
   * The Discussion itself
   */
  public discussion$: Observable<Discussion>;

  /**
   * Reference to the div containing all messages, used for scrolling.
   */
  @ViewChild('messageBox', {static: true}) public messagesElement: ElementRef;

  private readonly subscriptions = new Subscription();

  private loadDiscussionSubscription = new Subscription();

  constructor(private store: Store<State>,
              @Inject(MAT_DIALOG_DATA) public options: DiscussionDialogOptions) {
  }

  public ngOnInit(): void {
    this.subscriptions.add(this.options.discussionId$
      .pipe(filter(id => !!id))
      .subscribe(discussionId => {
        this.updateDiscussionId(discussionId);
      })
    );
  }

  public updateDiscussionId(discussionId: string) {
    this.discussionId = discussionId;

    const payload: DiscussionIdPayload = {
      id: discussionId,
      resourceId: this.options.resourceId,
      resourceType: this.options.resourceType
    };

    this.store.dispatch(new LoadSingle(SLICE.DISCUSSION, payload));

    this.discussion$ = this.store.pipe(
      select(selectDiscussion({id: discussionId}))
    );

    // When a comment is added (or loaded for the first time), this will fire
    this.loadDiscussionSubscription.unsubscribe();
    this.loadDiscussionSubscription =
      this.store.pipe(
        select(selectDiscussion({id: discussionId})),
        filter(discussion => !!discussion),
        distinctUntilChanged((previous: Discussion, current: Discussion) => previous.comments.length === current.comments.length)
      ).subscribe((discussion) => {
        this.store.dispatch(new ScrollTo({document: this.messagesElement.nativeElement, target: '.bottom-anchor'}));

        if (discussion.unreadComments) {
          this.store.dispatch(new MarkAsRead({id: discussionId, resourceId: this.options.resourceId, resourceType: this.options.resourceType}));
        }
      });
  }

  public postComment() {
    if (this.discussionId) { // If the discussion is started already
      this.store.dispatch(new PostComment({
        resourceId: this.options.resourceId,
        resourceType: this.options.resourceType,
        id: this.discussionId,
        comment: {
          message: this.message
        }
      }));
    } else {
      this.store.dispatch(new StartDiscussion({
        ...this.options.discussionLocation,
        resourceId: this.options.resourceId,
        resourceType: this.options.resourceType,
        title: this.options.title,
        comment: {message: this.message}
      }));
    }

    this.message = '';
  }

  public closeModal() {
    this.store.dispatch(new CloseModal());
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.loadDiscussionSubscription.unsubscribe();
  }

}

export interface DiscussionDialogOptions {
  discussionId$: BehaviorSubject<string>;
  resourceId: string;
  resourceType: ResourceType;
  /**
   * If we have a discussionID, we will load the discussion and show the title of that discussion. However, we can also use the dialog to create a
   * new discussion. In that case, we would also like to show a title. If this title property is present, than we will show it if the discussion isn't 'started'
   * yet.
   */
  title: string;

  /**
   * When starting a discussion, we sometimes need more properties next to the 'resourceId' and 'resourceType'. These properties should be
   * present in this object. For example, to start a discussion for KYC we need to add the {chapter: '..', item: '..'} properties.
   */
  discussionLocation?: any;
}
