import { select, Store } from '@ngrx/store';
import { CloseModal, FileUploadFailed, HideProgressIndicator, ShowProgressIndicator, UpdateProgressIndicator } from '../../../../state-management/layout/actions';
import { SLICE } from '../../../state-management/slice';
import { selectReportingDocumentsSummary } from '../../../state-management/selector';
import { MonitoringFilesService } from '../../../../shared/api/services/monitoring-files.service';
import { DownloadReportingDocumentsCertificate, DownloadReportingDocumentsFinacialsTemplate, DownloadReportingDocumentsFinancials, ResubmitCertificate, ResubmitFinancials, SubmitCertificate, SubmitFinancials } from '../../../state-management/actions';
import { finalize, tap } from 'rxjs/operators';
import { HttpEventType } from '@angular/common/http';
import { ApiError } from '../../../../shared/api/api-error';
import { ReadReportingDocumentsType } from '../../../../shared/api/models/readReportingDocumentsType';
var UploadReportingDocumentsDialogComponent = /** @class */ (function () {
    function UploadReportingDocumentsDialogComponent(store, data, monitoringService) {
        this.store = store;
        this.data = data;
        this.monitoringService = monitoringService;
        this.status$ = this.store.pipe(select(selectReportingDocumentsSummary(data)));
        this.reportingPeriodTitle = this.getReportingPeriodTitle(data);
        if (data.startYear !== data.endYear) {
            this.reportingPeriodTitle += "/" + data.endYear;
        }
    }
    UploadReportingDocumentsDialogComponent.prototype.downloadFinancialsTemplate = function () {
        this.store.dispatch(new DownloadReportingDocumentsFinacialsTemplate(SLICE.REPORTING_DOCUMENTS, {
            id: this.data.id,
            reportingPeriodNumber: this.data.reportingPeriodNumber,
            reportingFrequency: this.data.reportingFrequency,
            startYear: this.data.startYear,
            endYear: this.data.endYear,
            borrower: this.data.borrower.name
        }));
    };
    UploadReportingDocumentsDialogComponent.prototype.submitFinancials = function (financialModel) {
        var payload = {
            id: this.data.id,
            reportingPeriod: {
                reportingPeriodNumber: this.data.reportingPeriodNumber,
                startYear: this.data.startYear,
                reportingFrequency: this.data.reportingFrequency
            },
            endYear: this.data.endYear,
            version: this.data.version,
            financials: financialModel
        };
        if (this.data.type === ReadReportingDocumentsType.FINAL) {
            this.store.dispatch(new SubmitFinancials(SLICE.REPORTING_DOCUMENTS, payload));
        }
        else {
            this.store.dispatch(new ResubmitFinancials(SLICE.REPORTING_DOCUMENTS, payload));
        }
    };
    Object.defineProperty(UploadReportingDocumentsDialogComponent.prototype, "excelImportFn", {
        get: function () {
            var _this = this;
            return function (payload) {
                return _this.monitoringService.importFinancialsExcel(payload);
            };
        },
        enumerable: true,
        configurable: true
    });
    UploadReportingDocumentsDialogComponent.prototype.fileSelected = function (file) {
        var _this = this;
        this.store.dispatch(new ShowProgressIndicator('generic.importing-file'));
        var _a = this.monitoringService.uploadFile(this.data.id, file), uploadFileObservable$ = _a[0], progressObservable$ = _a[1];
        progressObservable$.pipe(tap(function (event) {
            if (event.type === HttpEventType.UploadProgress) {
                var percentDone = Math.round((event.loaded / event.total) * 100);
                _this.store.dispatch(new UpdateProgressIndicator(percentDone, event.loaded, event.total));
            }
        }), finalize(function () { return _this.store.dispatch(new HideProgressIndicator()); })).subscribe();
        uploadFileObservable$
            .subscribe(function (response) {
            var payload = {
                id: _this.data.id,
                reportingPeriod: {
                    reportingPeriodNumber: _this.data.reportingPeriodNumber,
                    startYear: _this.data.startYear,
                    reportingFrequency: _this.data.reportingFrequency
                },
                version: _this.data.version,
                certificateFile: {
                    fileId: response.fileId,
                    name: file.name
                }
            };
            if (_this.data.type === ReadReportingDocumentsType.FINAL) {
                _this.store.dispatch(new SubmitCertificate(SLICE.REPORTING_DOCUMENTS, payload));
            }
            else {
                _this.store.dispatch(new ResubmitCertificate(SLICE.REPORTING_DOCUMENTS, payload));
            }
        }, function (error) {
            var apiError = new ApiError(error.error.code, error.error.values);
            _this.store.dispatch(new FileUploadFailed(apiError));
        });
    };
    UploadReportingDocumentsDialogComponent.prototype.downloadCertificate = function () {
        var payload = {
            id: this.data.id,
            reportingPeriodNumber: this.data.reportingPeriodNumber,
            reportingFrequency: this.data.reportingFrequency,
            startYear: this.data.startYear,
            endYear: this.data.endYear,
            borrower: this.data.borrower.name,
            version: this.data.version
        };
        this.store.dispatch(new DownloadReportingDocumentsCertificate(SLICE.REPORTING_DOCUMENTS, payload));
    };
    UploadReportingDocumentsDialogComponent.prototype.downloadFinancials = function () {
        var payload = {
            id: this.data.id,
            reportingPeriodNumber: this.data.reportingPeriodNumber,
            reportingFrequency: this.data.reportingFrequency,
            startYear: this.data.startYear,
            endYear: this.data.endYear,
            borrower: this.data.borrower.name,
            version: this.data.version
        };
        this.store.dispatch(new DownloadReportingDocumentsFinancials(SLICE.REPORTING_DOCUMENTS, payload));
    };
    UploadReportingDocumentsDialogComponent.prototype.closeModal = function () {
        this.store.dispatch(new CloseModal());
    };
    UploadReportingDocumentsDialogComponent.prototype.getReportingPeriodTitle = function (data) {
        switch (data.reportingFrequency) {
            case 'QUARTERLY':
                return "Q" + data.reportingPeriodNumber + "-" + data.startYear;
            case 'HALF_YEARLY':
                return this.reportingPeriodTitle = "HY" + data.reportingPeriodNumber + "-" + data.startYear;
            default:
                throw new Error("unknown reporting frequency [" + data.reportingFrequency + "]");
        }
    };
    return UploadReportingDocumentsDialogComponent;
}());
export { UploadReportingDocumentsDialogComponent };
