<div [class.empty-repeatable]="itemsFormArray.length == 0"></div>
<div class="repeatable-items-holder" *ngIf="itemsFormArray.length != 0">
  <caple-repeatable-item-selector
    *ngFor="let item of itemsFormArray.controls; let i=index"
    [component]="component"
    [item]="item"
    [index]="i"
    [showRemove]="shouldShowRemove(itemsFormArray)"
    [data]="data"
    (removed)="removeItem(i)">
  </caple-repeatable-item-selector>
</div>
<div *ngIf="itemsFormArray.enabled && !addButton">
  <button (click)="addItem()"
          class="mat-button add-button-bottom" mat-button
          type="button">
    <div class="mat-mini-fab mat-primary">
      <i class="material-icons">add</i>
    </div>
    <span> {{ 'generic.add-more' | translate }} </span>
  </button>
</div>

