var _a;
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { companiesAdapter } from './reducer';
/**************
 * Slices
 **************/
var selectFeature = createFeatureSelector('administration');
var ɵ0 = function (state) { return state.companies; };
var selectCompaniesSlice = createSelector(selectFeature, ɵ0);
/** Companies **/
export var selectAllCompanies = (_a = companiesAdapter.getSelectors(selectCompaniesSlice), _a.selectAll), selectCompaniesIsLoaded = _a.selectIsLoaded, selectCompany = _a.selectSingle, selectCompanyIsLoaded = _a.selectSingleIsLoaded;
export var selectCompanyHasRole = function (companyId, role) { return createSelector(selectFeature, function (state) {
    var company = state.companies.entities[companyId];
    if (company && company.roles) {
        return company.roles.indexOf(role) !== -1;
    }
    return false;
}); };
var ɵ1 = function (state) { return state.companyFilteredCountries; };
export var selectCompanyFilteredCountries = createSelector(selectFeature, ɵ1);
var ɵ2 = function (state) { return state.companySearchQuery; };
export var selectCompanySearchQuery = createSelector(selectFeature, ɵ2);
/** Users **/
export var selectUsersForCompany = function (companyId) { return createSelector(selectFeature, function (state) {
    return state.users.entities[companyId];
}); };
export var selectUsersForCompanyLoaded = function (company) { return createSelector(selectFeature, function (state) {
    return state.users.entities[company.id] !== undefined;
}); };
/** Clients **/
export var selectClientsForCompany = function (companyId) { return createSelector(selectFeature, function (state) {
    return state.clients.entities[companyId];
}); };
export var selectClientsForCompanyLoaded = function (company) { return createSelector(selectFeature, function (state) {
    return state.clients.entities[company.id] !== undefined;
}); };
export { ɵ0, ɵ1, ɵ2 };
