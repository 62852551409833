/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./repeatable-array.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./repeabtable-item-selector.ngfactory";
import * as i3 from "./repeabtable-item-selector";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "@ngx-translate/core";
import * as i10 from "./repeatable-array.component";
import * as i11 from "@ngrx/store";
import * as i12 from "angulartics2/piwik";
var styles_RepeatableArrayComponent = [i0.styles];
var RenderType_RepeatableArrayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RepeatableArrayComponent, data: {} });
export { RenderType_RepeatableArrayComponent as RenderType_RepeatableArrayComponent };
function View_RepeatableArrayComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "caple-repeatable-item-selector", [], null, [[null, "removed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("removed" === en)) {
        var pd_0 = (_co.removeItem(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_RepeatableItemSelector_0, i2.RenderType_RepeatableItemSelector)), i1.ɵdid(1, 770048, [[1, 4]], 0, i3.RepeatableItemSelector, [i1.ComponentFactoryResolver], { item: [0, "item"], showRemove: [1, "showRemove"], component: [2, "component"], index: [3, "index"], data: [4, "data"] }, { removed: "removed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.shouldShowRemove(_co.itemsFormArray); var currVal_2 = _co.component; var currVal_3 = _v.context.index; var currVal_4 = _co.data; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_RepeatableArrayComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "repeatable-items-holder"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RepeatableArrayComponent_2)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.itemsFormArray.controls; _ck(_v, 2, 0, currVal_0); }, null); }
function View_RepeatableArrayComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "button", [["class", "mat-button add-button-bottom"], ["mat-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addItem() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "div", [["class", "mat-mini-fab mat-primary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["add"])), (_l()(), i1.ɵeld(6, 0, null, 0, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("generic.add-more")); _ck(_v, 7, 0, currVal_2); }); }
export function View_RepeatableArrayComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { children: 1 }), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [], [[2, "empty-repeatable", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RepeatableArrayComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RepeatableArrayComponent_3)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.itemsFormArray.length != 0); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.itemsFormArray.enabled && !_co.addButton); _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.itemsFormArray.length == 0); _ck(_v, 1, 0, currVal_0); }); }
export function View_RepeatableArrayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "caple-repeatable-array", [], null, null, null, View_RepeatableArrayComponent_0, RenderType_RepeatableArrayComponent)), i1.ɵdid(1, 245760, null, 0, i10.RepeatableArrayComponent, [i11.Store, i1.ElementRef, i12.Angulartics2Piwik], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RepeatableArrayComponentNgFactory = i1.ɵccf("caple-repeatable-array", i10.RepeatableArrayComponent, View_RepeatableArrayComponent_Host_0, { itemsFormArray: "itemsFormArray", component: "component", showRemove: "showRemove", itemProducer: "itemProducer", closeOnAdd: "closeOnAdd", scrollAfterAdd: "scrollAfterAdd", addButton: "addButton", analyticsKey: "analyticsKey", data: "data" }, {}, []);
export { RepeatableArrayComponentNgFactory as RepeatableArrayComponentNgFactory };
