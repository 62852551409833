import { defer, EMPTY, of, throwError } from 'rxjs';
import { catchError, filter, finalize, last, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { HttpEventType } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { actions, CreateSingleSuccess, DeleteSingleSuccess, EntityAction, Failure, LoadAll, LoadAllSuccess, LoadAllWithoutId, LoadSingle, LoadSingleSuccess, LoadSingleWithoutId, UpdateSingleSuccess, WithId } from './actions';
import { typeFor } from './util';
import { HideLoadingIndicator, HideProgressIndicator, ShowLoadingIndicator, ShowProgressIndicator, UpdateProgressIndicator } from '../layout/actions';
import { ApiError } from '../../shared/api/api-error';
/***************
 * Functions
 ***************/
var Effects = /** @class */ (function () {
    function Effects(actions$, store) {
        this.actions$ = actions$;
        this.store = store;
        this.activeRequests = new Set();
    }
    /**
     * Generic execution function. Will start listening to certain actions and execute the given function when an action is encountered.
     * @param {string[]} allowedTypes
     * @param {string} loadingUri
     * @param {FunctionWrapper} functionWrapper
     * @returns {Observable<ExecutionResult>}
     */
    Effects.prototype.registerEffectHandler = function (allowedTypes, loadingUri, functionWrapper) {
        var _this = this;
        return this.actions$
            .pipe(ofType.apply(void 0, allowedTypes), switchMap(function (action) {
            return _this.executeServiceCallWithLoadingIndicator(action.slice, action, loadingUri, functionWrapper).pipe(catchError(function (err) { return of(err); }));
        }));
    };
    /**
     * Execute a function with loading indicators and default error handling
     *
     * @param {string} key
     * @param action
     * @param {string} loadingUri
     * @param {FunctionWrapper} functionWrapper
     * @returns {Observable<any>}
     */
    Effects.prototype.executeServiceCallWithLoadingIndicator = function (key, action, loadingUri, functionWrapper) {
        var _this = this;
        this.store.dispatch(new ShowLoadingIndicator(loadingUri));
        return functionWrapper.functionToExecute.apply(functionWrapper.scope, [action.payload || {}])
            .pipe(map(function (result) {
            return { action: action, result: result };
        }), catchError(function (response) {
            var apiError = new ApiError(response.error.code, response.error.values);
            return throwError(new Failure(key, apiError));
        }), finalize(function () { return _this.store.dispatch(new HideLoadingIndicator(loadingUri)); }));
    };
    /**
     * Execute a function with loading indicators and default error handling
     *
     * @param {string} key
     * @param action
     * @param {string} translationKey
     * @param {FunctionWrapper} functionWrapper
     * @param translationData
     * @returns {Observable<any>}
     */
    Effects.prototype.executeServiceCallWithProgressIndicator = function (key, action, translationKey, functionWrapper, translationData) {
        var _this = this;
        this.store.dispatch(new ShowProgressIndicator(translationKey, translationData));
        return functionWrapper.functionToExecute.apply(functionWrapper.scope, [action.payload || {}])
            .pipe(tap(function (event) {
            if (event.type === HttpEventType.DownloadProgress) {
                var percentDone = Math.round((event.loaded / event.total) * 100);
                _this.store.dispatch(new UpdateProgressIndicator(percentDone, event.loaded, event.total));
            }
        }), last(), map(function (result) {
            return { action: action, result: result };
        }), catchError(function (response) {
            var apiError = new ApiError(response.error.code, response.error.values);
            return throwError(new Failure(key, apiError));
        }), finalize(function () { return _this.store.dispatch(new HideProgressIndicator()); }));
    };
    /**
     * Load all items of a certain type. If the items are already present in the store (determined by using the selector), nothing happens
     * except if `forceReload` is true.
     *
     * @param {string} key The unique key to correlate the action
     * @param {string} loadingUri The Unique Resource Identifier to use for displaying of the loading indicator
     * @param {FunctionWrapper} loadFunctionWrapper The actual implementation of the loading function
     * @param {BooleanSelector} isLoadedSelector The selector that helps to determine if the item is already present in store
     * @returns {Observable<any>}
     */
    Effects.prototype.loadAll = function (key, loadingUri, loadFunctionWrapper, isLoadedSelector) {
        var _this = this;
        return this.actions$
            .pipe(ofType(typeFor(key, actions.LOAD_ALL)), withLatestFrom(defer(function () { return _this.store.pipe(select(isLoadedSelector)); })), mergeMap(function (_a) {
            var action = _a[0], isLoaded = _a[1];
            if (!action.forceReload && isLoaded) {
                return EMPTY;
            }
            else {
                return _this.executeServiceCallWithLoadingIndicator(key, action, loadingUri, loadFunctionWrapper).pipe(map(function (result) {
                    if (result instanceof Failure) {
                        return result;
                    }
                    else {
                        return new LoadAllSuccess(key, result.result);
                    }
                }), catchError(function (error) { return of(error); }));
            }
        }));
    };
    /**
     * Load all items of a certain type that doesn't have a unique ID. If the items are already present in the store (determined by using the selector), nothing happens
     * except if `forceReload` is true.
     *
     * @param {string} key The unique key to correlate the action
     * @param {string} loadingUri The Unique Resource Identifier to use for displaying of the loading indicator
     * @param {FunctionWrapper} loadFunctionWrapper The actual implementation of the loading function
     * @param {BooleanSelector} isLoadedSelector The selector that helps to determine if the item is already present in store
     * @returns {Observable<any>}
     */
    Effects.prototype.loadAllWithoutID = function (key, loadingUri, loadFunctionWrapper, isLoadedSelector) {
        var _this = this;
        return this.actions$
            .pipe(ofType(typeFor(key, actions.LOAD_ALL_WITHOUT_ID)), withLatestFrom(defer(function () { return _this.store.pipe(select(isLoadedSelector)); })), mergeMap(function (_a) {
            var action = _a[0], isLoaded = _a[1];
            if (!action.forceReload && isLoaded) {
                return EMPTY;
            }
            else {
                return _this.executeServiceCallWithLoadingIndicator(key, action, loadingUri, loadFunctionWrapper).pipe(map(function (result) {
                    if (result instanceof Failure) {
                        return result;
                    }
                    else {
                        return new LoadAllSuccess(key, result.result);
                    }
                }), catchError(function (error) { return of(error); }));
            }
        }));
    };
    /**
     * Load a single item of a certain type. If the item is already present in the store (determined by using the selector), nothing happens
     * except if `forceReload` is true.
     *
     * @param {string} key The unique key to correlate the action
     * @param {string} loadingUri The Unique Resource Identifier to use for displaying of the loading indicator
     * @param {FunctionWrapper} loadFunctionWrapper The actual implementation of the loading function
     * @param {BooleanSelector} isLoadedIdSelector The selector that helps to determine if the item is already present in store
     * @returns {Observable<any>}
     */
    Effects.prototype.loadSingle = function (key, loadingUri, loadFunctionWrapper, isLoadedIdSelector) {
        var loadSingle$ = this.actions$.pipe(ofType(typeFor(key, actions.LOAD_SINGLE)));
        return this.handleLoadSingle(loadSingle$, isLoadedIdSelector, key, loadingUri, loadFunctionWrapper);
    };
    Effects.prototype.loadSingleWithVersion = function (key, loadingUri, loadFunctionWrapper, isLoadedIdSelector) {
        var loadSingle$ = this.actions$.pipe(ofType(typeFor(key, actions.LOAD_SINGLE_WITH_VERSION)));
        return this.handleLoadSingle(loadSingle$, isLoadedIdSelector, key, loadingUri, loadFunctionWrapper);
    };
    Effects.prototype.handleLoadSingle = function (loadSingle$, isLoadedIdSelector, key, loadingUri, loadFunctionWrapper) {
        var _this = this;
        return loadSingle$.pipe(filter(function (action) {
            var requestAlreadyActive = _this.activeRequests.has(_this.getActionIdentifier(action));
            if (!requestAlreadyActive) {
                _this.activeRequests.add(_this.getActionIdentifier(action));
            }
            return action.forceReload || !requestAlreadyActive;
        }), mergeMap(function (action) {
            return of(action).pipe(withLatestFrom(_this.store.pipe(select(isLoadedIdSelector(action.payload)))), mergeMap(function (_a) {
                var _ = _a[0], isLoaded = _a[1];
                if (!action.forceReload && isLoaded) {
                    _this.activeRequests.delete(_this.getActionIdentifier(action));
                    return EMPTY;
                }
                else {
                    return _this.executeServiceCallWithLoadingIndicator(key, action, loadingUri, loadFunctionWrapper).pipe(map(function (result) {
                        if (result instanceof Failure) {
                            return result;
                        }
                        else {
                            return new LoadSingleSuccess(key, result.result, result.action.payload);
                        }
                    }), catchError(function (error) { return of(error); }), finalize(function () { return _this.activeRequests.delete(_this.getActionIdentifier(action)); }));
                }
            }));
        }));
    };
    /**
     * Load a single item of a certain type that doesn't contain a unique ID.
     *
     * @param {string} key The unique key to correlate the action
     * @param {string} loadingUri The Unique Resource Identifier to use for displaying of the loading indicator
     * @param {FunctionWrapper} loadFunctionWrapper The actual implementation of the loading function
     * @returns {Observable<any>}
     */
    Effects.prototype.loadSingleWithoutID = function (key, loadingUri, loadFunctionWrapper) {
        var _this = this;
        return this.actions$
            .pipe(ofType(typeFor(key, actions.LOAD_SINGLE_WITHOUT_ID)), mergeMap(function (action) {
            return _this.executeServiceCallWithLoadingIndicator(key, action, loadingUri, loadFunctionWrapper).pipe(map(function (result) {
                if (result instanceof Failure) {
                    return result;
                }
                else {
                    return new LoadSingleSuccess(key, result.result, result.action.payload);
                }
            }), catchError(function (error) { return of(error); }));
        }));
    };
    /**
     * Create a new item of a certain type
     *
     * @param {string} key
     * @param {string} loadingUri
     * @param {FunctionWrapper} functionWrapper
     * @returns {Observable<CreateSingleSuccess>}
     */
    Effects.prototype.createSingle = function (key, loadingUri, functionWrapper) {
        return this.registerEffectHandler([typeFor(key, actions.CREATE_SINGLE)], loadingUri, functionWrapper).pipe(map(function (result) {
            if (result instanceof Failure) {
                return result;
            }
            else {
                return new CreateSingleSuccess(key, result.result, result.action.payload);
            }
        }));
    };
    /**
     * Delete a certain item
     *
     * @param {string} key
     * @param {string} loadingUri
     * @param {FunctionWrapper} functionWrapper
     * @returns {Observable<DeleteSingleSuccess<T>>}
     */
    Effects.prototype.deleteSingle = function (key, loadingUri, functionWrapper) {
        return this.registerEffectHandler([typeFor(key, actions.DELETE_SINGLE)], loadingUri, functionWrapper).pipe(map(function (result) {
            if (result instanceof Failure) {
                return result;
            }
            else {
                return new DeleteSingleSuccess(key, result.result, result.action.payload);
            }
        }));
    };
    /**
     * Update a single entity
     *
     * @param {string} key
     * @param {string} loadingUri
     * @param {FunctionWrapper} functionWrapper
     * @returns {Observable<LoadSingleSuccess<T>>}
     */
    Effects.prototype.updateSingle = function (key, loadingUri, functionWrapper) {
        return this.registerEffectHandler([typeFor(key, actions.UPDATE_SINGLE)], loadingUri, functionWrapper).pipe(map(function (result) {
            if (result instanceof Failure) {
                return result;
            }
            else {
                return new UpdateSingleSuccess(key, result.result, result.action.payload);
            }
        }));
    };
    Effects.prototype.getActionIdentifier = function (action) {
        return action.type + action.payload.id;
    };
    return Effects;
}());
export { Effects };
