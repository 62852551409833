import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedDate',
  pure: false
})
export class LocalizedDatePipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  transform(value: any, pattern: string = 'shortDate'): any {
    let datePipe: DatePipe;
    switch (this.translateService.currentLang) {
      case 'en':
        datePipe = new DatePipe('en-GB');
        break;
      default:
        datePipe = new DatePipe(this.translateService.currentLang);
        break;
    }
    return datePipe.transform(value, pattern);
  }

}
