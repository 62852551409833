import { Component, Inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { State } from '../../../state-management/reducer';
import { CloseModal, FileUploadFailed, HideProgressIndicator, ShowProgressIndicator, UpdateProgressIndicator } from '../../../../state-management/layout/actions';
import { WithId } from '../../../../state-management/util/actions';
import { Observable } from 'rxjs';
import { SLICE } from '../../../state-management/slice';
import { selectReportingDocumentsSummary } from '../../../state-management/selector';
import { MonitoringFilesService } from '../../../../shared/api/services/monitoring-files.service';
import { FinancialModel } from '../../../../shared/api/models/financialModel';
import {
  DownloadReportingDocumentsCertificate,
  DownloadReportingDocumentsFinacialsTemplate,
  DownloadReportingDocumentsFinancials,
  ResubmitCertificate,
  ResubmitFinancials,
  SubmitCertificate,
  SubmitFinancials
} from '../../../state-management/actions';
import { finalize, tap } from 'rxjs/operators';
import { HttpEventType, HttpProgressEvent } from '@angular/common/http';
import { ApiError } from '../../../../shared/api/api-error';
import { ReadCompany } from '../../../../shared/api/models/readCompany';
import { ReportingFrequency } from '../../../../shared/api/models/reportingFrequency';
import { ReadReportingDocuments } from '../../../../shared/api/models/readReportingDocuments';
import { ReadReportingDocumentsType } from '../../../../shared/api/models/readReportingDocumentsType';

@Component({
  selector: 'caple-upload-reporting-documents-dialog',
  templateUrl: './upload-reporting-documents-dialog.component.html',
  styleUrls: ['./upload-reporting-documents-dialog.component.scss'],
})
export class UploadReportingDocumentsDialogComponent {

  public status$: Observable<ReadReportingDocuments>;
  public reportingPeriodTitle: string;

  constructor(private store: Store<State>, @Inject(MAT_DIALOG_DATA) public data: UploadReportingDocumentsDialogData, private monitoringService: MonitoringFilesService) {
    this.status$ = this.store.pipe(select(selectReportingDocumentsSummary(data)));
    this.reportingPeriodTitle = this.getReportingPeriodTitle(data);
    if (data.startYear !== data.endYear) {
      this.reportingPeriodTitle += `/${data.endYear}`;
    }
  }

  public downloadFinancialsTemplate() {
    this.store.dispatch(new DownloadReportingDocumentsFinacialsTemplate(SLICE.REPORTING_DOCUMENTS, {
      id: this.data.id,
      reportingPeriodNumber: this.data.reportingPeriodNumber,
      reportingFrequency: this.data.reportingFrequency,
      startYear: this.data.startYear,
      endYear: this.data.endYear,
      borrower: this.data.borrower.name
    }));
  }

  public submitFinancials(financialModel: FinancialModel) {
    const payload = {
      id: this.data.id,
      reportingPeriod: {
        reportingPeriodNumber: this.data.reportingPeriodNumber,
        startYear: this.data.startYear,
        reportingFrequency: this.data.reportingFrequency
      },
      endYear: this.data.endYear,
      version: this.data.version,
      financials: financialModel
    };

    if (this.data.type === ReadReportingDocumentsType.FINAL) {
      this.store.dispatch(new SubmitFinancials(SLICE.REPORTING_DOCUMENTS, payload));
    } else {
      this.store.dispatch(new ResubmitFinancials(SLICE.REPORTING_DOCUMENTS, payload));
    }
  }

  public get excelImportFn() {
    return (payload: any) => {
      return this.monitoringService.importFinancialsExcel(payload);
    };
  }

  public fileSelected(file: File) {
    this.store.dispatch(new ShowProgressIndicator('generic.importing-file'));

    const [uploadFileObservable$, progressObservable$] = this.monitoringService.uploadFile(this.data.id, file);

    progressObservable$.pipe(
      tap((event: HttpProgressEvent) => {
        if (event.type === HttpEventType.UploadProgress) {
          const percentDone = Math.round((event.loaded / event.total) * 100);
          this.store.dispatch(new UpdateProgressIndicator(percentDone, event.loaded, event.total));
        }
      }),
      finalize(() => this.store.dispatch(new HideProgressIndicator()))
    ).subscribe();

    uploadFileObservable$
      .subscribe(
        response => {
          const payload = {
            id: this.data.id,
            reportingPeriod: {
              reportingPeriodNumber: this.data.reportingPeriodNumber,
              startYear: this.data.startYear,
              reportingFrequency: this.data.reportingFrequency
            },
            version: this.data.version,
            certificateFile: {
              fileId: response.fileId,
              name: file.name
            }
          };


          if (this.data.type === ReadReportingDocumentsType.FINAL) {
            this.store.dispatch(new SubmitCertificate(SLICE.REPORTING_DOCUMENTS, payload));
          } else {
            this.store.dispatch(new ResubmitCertificate(SLICE.REPORTING_DOCUMENTS, payload));
          }
        },
        error => {
          const apiError = new ApiError(error.error.code, error.error.values);
          this.store.dispatch(new FileUploadFailed(apiError));
        }
      );
  }

  public downloadCertificate() {
    const payload = {
      id: this.data.id,
      reportingPeriodNumber: this.data.reportingPeriodNumber,
      reportingFrequency: this.data.reportingFrequency,
      startYear: this.data.startYear,
      endYear: this.data.endYear,
      borrower: this.data.borrower.name,
      version: this.data.version
    };
    this.store.dispatch(new DownloadReportingDocumentsCertificate(SLICE.REPORTING_DOCUMENTS, payload));
  }

  public downloadFinancials() {
    const payload = {
      id: this.data.id,
      reportingPeriodNumber: this.data.reportingPeriodNumber,
      reportingFrequency: this.data.reportingFrequency,
      startYear: this.data.startYear,
      endYear: this.data.endYear,
      borrower: this.data.borrower.name,
      version: this.data.version
    };
    this.store.dispatch(new DownloadReportingDocumentsFinancials(SLICE.REPORTING_DOCUMENTS, payload));
  }

  public closeModal() {
    this.store.dispatch(new CloseModal());
  }

  private getReportingPeriodTitle(data: UploadReportingDocumentsDialogData): string {
    switch (data.reportingFrequency) {
      case 'QUARTERLY':
        return `Q${data.reportingPeriodNumber}-${data.startYear}`;
      case 'HALF_YEARLY':
        return this.reportingPeriodTitle = `HY${data.reportingPeriodNumber}-${data.startYear}`;
      default:
        throw new Error(`unknown reporting frequency [${data.reportingFrequency}]`);
    }
  }
}

export interface UploadReportingDocumentsDialogData extends WithId {
  reportingPeriodNumber: number;
  startYear: number;
  endYear: number;
  borrower: ReadCompany;
  type: ReadReportingDocumentsType;
  version?: number;
  disabled?: boolean;
  reportingFrequency: ReportingFrequency;
}
