import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { RepeatableItem } from '../../../../../../shared/layout/repeatable-form-item/repeatable-item';
import { CapleFormBuilder } from '../../../../../../shared/util/caple-form-builder';
var EbitdaCovenantItemComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EbitdaCovenantItemComponent, _super);
    function EbitdaCovenantItemComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.removed = new EventEmitter();
        return _this;
    }
    EbitdaCovenantItemComponent.produceFormGroup = function (val) {
        val = val || {};
        return new CapleFormBuilder().group({
            'from': [val.from, [Validators.required]],
            'to': [val.to, Validators.required],
            'level': [val.level, Validators.required]
        });
    };
    EbitdaCovenantItemComponent.prototype.produceFormGroup = function (val) {
        return EbitdaCovenantItemComponent.produceFormGroup(val);
    };
    return EbitdaCovenantItemComponent;
}(RepeatableItem));
export { EbitdaCovenantItemComponent };
