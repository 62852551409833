import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { RepeatableItem } from '../../shared/layout/repeatable-form-item/repeatable-item';
import { CapleFormBuilder } from '../../shared/util/caple-form-builder';
import { ClosingDocumentCategory } from '../../shared/api/models/closingDocumentCategory';
var ConditionItemComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ConditionItemComponent, _super);
    function ConditionItemComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.showRemove = true;
        _this.removed = new EventEmitter();
        _this.categories = Object.values(ClosingDocumentCategory);
        _this.animationsEnabled = true;
        return _this;
    }
    ConditionItemComponent.produceFormGroup = function (val) {
        val = val || {};
        var capleFormBuilder = new CapleFormBuilder();
        return capleFormBuilder.group({
            'id': [val.id],
            'number': [val.number, Validators.required],
            'title': [val.title, Validators.required],
            'helpText': [val.helpText],
            'category': [val.category, Validators.required],
            'template': capleFormBuilder.group({
                fileId: [val.template ? val.template.fileId : undefined],
                name: [val.template ? val.template.name : undefined]
            }),
            'instructions': capleFormBuilder.group({
                fileId: [val.instructions ? val.instructions.fileId : undefined],
                name: [val.instructions ? val.instructions.name : undefined]
            })
        });
    };
    ConditionItemComponent.prototype.produceFormGroup = function (val) {
        return ConditionItemComponent.produceFormGroup(val);
    };
    ConditionItemComponent.prototype.ngOnInit = function () {
        this.openOnStart(this.expansionPanel);
    };
    ConditionItemComponent.prototype.enableRemove = function () {
        if (this.showRemove) {
            // When showRemove is true the conditions precedent file is unpublished this means structure changes are allowed.
            return true;
        }
        else {
            // When a conditions precedent file is published we only allow the removal of conditions that were not saved yet.
            return !this.item.controls['id'].value;
        }
    };
    ConditionItemComponent.prototype.close = function () {
        var _this = this;
        this.animationsEnabled = false;
        this.expansionPanel.close();
        setTimeout(function () { return _this.animationsEnabled = true; }, 0);
    };
    return ConditionItemComponent;
}(RepeatableItem));
export { ConditionItemComponent };
