<form [formGroup]="form">

  <div class="pure-g">
    <div class="pure-u-1-2">
      <mat-form-field floatLabel="always">
        <input [formControl]="form.controls['companyNumber']" matInput
               placeholder="{{ 'funding-process.company-number' | translate}}"/>
        <mat-error
          *ngIf="form.hasError('required', ['companyNumber'])">{{ 'generic.validation.required' | translate}}</mat-error>
        <mat-error
          *ngIf="form.hasError('pattern', ['companyNumber'])">{{ 'generic.validation.valid-company-number' | translate}}</mat-error>
        <caple-loading-wrapper [diameter]="20" key="urn:chamber-of-commerce:load"
                               style="position: absolute; right: 0px;">
        </caple-loading-wrapper>
      </mat-form-field>
    </div>
    <div class="pure-u-1-2">
      <mat-form-field floatLabel="always">
        <input [formControl]="form.controls['companyName']" matInput
               placeholder="{{ 'funding-process.company-name' | translate}}"/>
        <mat-error
          *ngIf="form.hasError('required', ['companyName'])">{{ 'generic.validation.required' | translate}}</mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="pure-g">
    <div class="pure-u-1-2">
      <mat-form-field class="full" floatLabel="always">
        <input [formControl]="form.controls['addressLine1']" matInput
               placeholder="{{ 'funding-process.address-line1' | translate}}"/>
        <mat-error
          *ngIf="form.hasError('required', ['addressLine1'])">{{ 'generic.validation.required' | translate}}</mat-error>
      </mat-form-field>
    </div>
    <div class="pure-u-1-2">
      <mat-form-field class="full" floatLabel="always">
        <input [formControl]="form.controls['addressLine2']" matInput
               placeholder="{{ 'funding-process.address-line2' | translate}}"/>
      </mat-form-field>
    </div>
  </div>

  <div class="pure-g">
    <div class="pure-u-1-2">
      <mat-form-field floatLabel="always">
        <input [formControl]="form.controls['city']" matInput placeholder="{{ 'funding-process.city' | translate}}"/>
        <mat-error
          *ngIf="form.hasError('required', ['city'])">{{ 'generic.validation.required' | translate}}</mat-error>
      </mat-form-field>
    </div>
    <div class="pure-u-1-2">
      <mat-form-field floatLabel="always">
        <input [formControl]="form.controls['region']" matInput
               placeholder="{{ 'funding-process.region' | translate}}"/>
      </mat-form-field>
    </div>
  </div>
  <div class="pure-g">
    <div class="pure-u-1-2">
      <mat-form-field floatLabel="always">
        <input [formControl]="form.controls['postalCode']" matInput
               placeholder="{{ 'funding-process.postal-code' | translate}}"/>
        <mat-error
          *ngIf="form.hasError('required', ['postalCode'])">{{ 'generic.validation.required' | translate}}</mat-error>
      </mat-form-field>
    </div>
  </div>


  <div class="pure-g">
    <div class="pure-u-1-2">
      <mat-form-field class="full" floatLabel="always">
        <mat-select [formControl]="form.controls['financialYearEnd']"
                    placeholder="{{ 'funding-process.financial-year-end' | translate }}">
          <mat-option [value]="1">{{ 'generic.month.january' | translate }}</mat-option>
          <mat-option [value]="2">{{ 'generic.month.february' | translate }}</mat-option>
          <mat-option [value]="3">{{ 'generic.month.march' | translate }}</mat-option>
          <mat-option [value]="4">{{ 'generic.month.april' | translate }}</mat-option>
          <mat-option [value]="5">{{ 'generic.month.may' | translate }}</mat-option>
          <mat-option [value]="6">{{ 'generic.month.june' | translate }}</mat-option>
          <mat-option [value]="7">{{ 'generic.month.july' | translate }}</mat-option>
          <mat-option [value]="8">{{ 'generic.month.august' | translate }}</mat-option>
          <mat-option [value]="9">{{ 'generic.month.september' | translate }}</mat-option>
          <mat-option [value]="10">{{ 'generic.month.october' | translate }}</mat-option>
          <mat-option [value]="11">{{ 'generic.month.november' | translate }}</mat-option>
          <mat-option [value]="12">{{ 'generic.month.december' | translate }}</mat-option>
        </mat-select>
        <mat-error
          *ngIf="form.hasError('required', ['financialYearEnd'])">{{ 'generic.validation.required' | translate}}</mat-error>
      </mat-form-field>
    </div>
    <div class="pure-u-1-2">
      <mat-form-field class="full" floatLabel="always">
        <input [formControl]="form.controls['sector']" matInput placeholder="{{ 'funding-process.sector' | translate}}"
               type="number"/>
        <mat-error
          *ngIf="form.hasError('required', ['sector'])">{{ 'generic.validation.required' | translate}}</mat-error>
        <mat-error
          *ngIf="form.hasError('pattern', ['sector'])">{{ 'generic.validation.valid-sector-code' | translate}}</mat-error>
        <mat-error
          *ngIf="form.hasError('excludedSector', ['sector'])">{{ 'generic.validation.excluded-sector-code' | translate}}</mat-error>
      </mat-form-field>
    </div>
  </div>
</form>
