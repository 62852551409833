import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { RepeatableItem } from '../../../../../../shared/layout/repeatable-form-item/repeatable-item';
import { CapleFormBuilder } from '../../../../../../shared/util/caple-form-builder';
import { GrowthFundingUseCategory } from '../../../../../../shared/api/models/growthFundingUseCategory';
var CompanyFundingUseItemComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyFundingUseItemComponent, _super);
    function CompanyFundingUseItemComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.removed = new EventEmitter();
        _this.FundingUsesCategory = Object.keys(GrowthFundingUseCategory).filter(function (k) { return k.indexOf('_') === -1; }).map(function (k) {
            return {
                key: k,
                value: GrowthFundingUseCategory[k]
            };
        });
        return _this;
    }
    CompanyFundingUseItemComponent.produceFormGroup = function (itemValOpt) {
        var val = itemValOpt || { use: null, amount: '', comment: '' };
        return new CapleFormBuilder().group({
            'use': [val.use, Validators.required],
            'amount': [val.amount, [Validators.required, Validators.min(0)]],
            'comment': val.comment
        });
    };
    CompanyFundingUseItemComponent.prototype.produceFormGroup = function (val) {
        return CompanyFundingUseItemComponent.produceFormGroup(val);
    };
    CompanyFundingUseItemComponent.prototype.equalToSnapShot = function (val) {
        return this.item.get('use').value === val.use;
    };
    return CompanyFundingUseItemComponent;
}(RepeatableItem));
export { CompanyFundingUseItemComponent };
