import * as tslib_1 from "tslib";
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { EligibilityParent } from './eligibility-parent';
import { EligibilityCheckType } from '../shared/api/models/eligibilityCheckType';
import { filter } from 'rxjs/operators';
import { COUNTRY_DATA } from '../origination/shared/country-data';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SupportedCountry } from '../shared/api/models/supportedCountry';
import { isCapleLoanPercentageHigh, isSectorCodeValid, isTotalAssetsMinValid, isTurnoverAndTotalAssetsMaxValid, isTurnoverMinValid, MAX_CAPLE_LOAN, MAX_ENTITIES_IN_GROUP, MIN_CAPLE_LOAN, MIN_DIRECTORS_SHAREHOLDERS } from './eligibility-validator';
import { ScrollTo } from '../state-management/layout/actions';
import { ProductType } from '../shared/api/models/productType';
var BasicEligibilityCriteriaComponent = /** @class */ (function (_super) {
    tslib_1.__extends(BasicEligibilityCriteriaComponent, _super);
    function BasicEligibilityCriteriaComponent(store) {
        var _this = _super.call(this, store) || this;
        _this.passedText = 'eligibility-criteria.passed-basic';
        _this.YES = 'yes';
        _this.NO = 'no';
        _this.animatedControls = [];
        _this.form = new FormBuilder().group({
            sectorCode: [null, Validators.required],
            loanAmount: [null, [Validators.required, Validators.min(0)]],
            turnover: [null, [Validators.required, Validators.min(0)]],
            totalAssets: [null, [Validators.required, Validators.min(0)]],
            registeredAccountsCountQuestion: [null, Validators.required],
            registeredAccountsExpectCountQuestion: [null],
            registeredAccountProfitableQuestion: [null],
            directors: [null, [Validators.required, Validators.min(0)]],
            shareholders: [null],
            directorsOrShareholdersExpectedQuestion: [null],
            entitiesInGroup: [null, [Validators.required, Validators.min(0)]],
            currentTotalDebt: [null, [Validators.required, Validators.min(0)]],
            refinanceExistingDebtQuestion: [null, Validators.required],
            paymentOrDemandEventsQuestion: [null, Validators.required]
        });
        _this.setFormDisableSetting();
        _this.onFormChanged();
        _this.hiddenControls = ['registeredAccountsExpectCountQuestion',
            'registeredAccountProfitableQuestion', 'shareholders', 'directorsOrShareholdersExpectedQuestion'
        ];
        _this.subscriptions.add(_this.form.controls['registeredAccountsCountQuestion'].valueChanges.subscribe(function (value) {
            if (value === _this.YES) {
                _this.hideControl('registeredAccountsExpectCountQuestion');
            }
            else if (value === _this.NO) {
                _this.showControl('registeredAccountsExpectCountQuestion');
            }
            if (_this.form.controls['registeredAccountsExpectCountQuestion'].value === null) {
                if (value === _this.YES) {
                    _this.showControl('registeredAccountProfitableQuestion');
                }
                else if (value === _this.NO) {
                    _this.hideControl('registeredAccountProfitableQuestion');
                }
            }
        }));
        _this.subscriptions.add(_this.form.controls['registeredAccountsExpectCountQuestion'].valueChanges.subscribe(function (value) {
            if (value === _this.YES) {
                _this.showControl('registeredAccountProfitableQuestion');
            }
            else if (value === _this.NO) {
                _this.hideControl('registeredAccountProfitableQuestion');
            }
        }));
        _this.subscriptions.add(_this.form.controls['directors'].valueChanges.pipe(filter(function (value) { return coerceBooleanProperty(value); })).subscribe(function (value) {
            if (value < MIN_DIRECTORS_SHAREHOLDERS) {
                _this.showControl('shareholders', [Validators.min(0)]);
            }
            else {
                _this.hideControl('shareholders');
                _this.hideControl('directorsOrShareholdersExpectedQuestion');
            }
        }));
        _this.subscriptions.add(_this.form.controls['shareholders'].valueChanges.pipe(filter(function (value) { return coerceBooleanProperty(value); })).subscribe(function (value) {
            if (value < MIN_DIRECTORS_SHAREHOLDERS && _this.form.controls['directors'].value < MIN_DIRECTORS_SHAREHOLDERS) {
                _this.showControl('directorsOrShareholdersExpectedQuestion');
            }
            else {
                _this.hideControl('directorsOrShareholdersExpectedQuestion');
            }
        }));
        return _this;
    }
    Object.defineProperty(BasicEligibilityCriteriaComponent.prototype, "fundingProcessProductType", {
        set: function (value) {
            this._fundingProcessProductType = value;
            /*
            We only want to validate basic eligibility if
              - eligibility exists
              - it is filled (we check the field 'turnover' because it is mandatory)
             */
            if (this.eligibilityId && this.form.controls['turnover'].value) {
                this.checkEligibility();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BasicEligibilityCriteriaComponent.prototype, "sectorCode", {
        set: function (value) {
            if (value && !this.form.controls['sectorCode'].value) {
                this.form.controls['sectorCode'].patchValue(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BasicEligibilityCriteriaComponent.prototype, "directors", {
        set: function (value) {
            if (value !== undefined && value !== null && !this.form.controls['directors'].value) {
                this.form.controls['directors'].patchValue(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BasicEligibilityCriteriaComponent.prototype, "country", {
        get: function () {
            return this._country;
        },
        set: function (value) {
            this._country = value;
            if (value) {
                this.form.controls['sectorCode'].setValidators([Validators.required, COUNTRY_DATA.get(SupportedCountry[value]).sectorCodePatternFn]);
            }
        },
        enumerable: true,
        configurable: true
    });
    BasicEligibilityCriteriaComponent.prototype.patchEligibility = function (eligibility) {
        this.patchEligibilityCheck(eligibility.basicEligibilityCheck);
    };
    BasicEligibilityCriteriaComponent.prototype.submitBasicEligibilityData = function () {
        this.submitEligibilityData(EligibilityCheckType.BASIC);
    };
    BasicEligibilityCriteriaComponent.prototype.validateEligibility = function () {
        this.validateSectorCode();
        this.validateLoamAmountRange();
        this.validateTurnoverAndTotalAssets();
        this.validateAccounts();
        this.validateCompanyStructure();
        this.validateLoanAmount();
        this.validateQuestion('paymentOrDemandEventsQuestion', this.YES);
        this.scrollAfterCheck();
    };
    BasicEligibilityCriteriaComponent.prototype.scrollAfterCheck = function () {
        var _this = this;
        setTimeout(function () {
            if (_this.checked) {
                _this.store.dispatch(new ScrollTo({ target: 'body' }));
            }
        });
    };
    BasicEligibilityCriteriaComponent.prototype.validateSectorCode = function () {
        var sectorCode = this.form.controls['sectorCode'].value;
        if (!isSectorCodeValid(sectorCode, this.country)) {
            this.addError('incorrectSector', ['sectorCode']);
        }
    };
    BasicEligibilityCriteriaComponent.prototype.validateLoamAmountRange = function () {
        var loanAmount = this.form.controls['loanAmount'].value;
        if (loanAmount < MIN_CAPLE_LOAN || loanAmount > MAX_CAPLE_LOAN) {
            this.addError('loanAmountNotInRange', ['loanAmount']);
        }
    };
    BasicEligibilityCriteriaComponent.prototype.validateTurnoverAndTotalAssets = function () {
        var turnoverName = 'turnover';
        var totalAssetsName = 'totalAssets';
        var turnover = this.form.controls[turnoverName].value;
        if (!isTurnoverMinValid(turnover)) {
            this.addError(turnoverName + 'LessThenMin', [turnoverName]);
        }
        var totalAssets = this.form.controls[totalAssetsName].value;
        if (!isTotalAssetsMinValid(totalAssets)) {
            this.addError(totalAssetsName + 'LessThenMin', [totalAssetsName]);
        }
        if (!isTurnoverAndTotalAssetsMaxValid(turnover, totalAssets)) {
            this.addError('turnoverTotalAssetsMoreThenMax', [turnoverName, totalAssetsName]);
        }
    };
    BasicEligibilityCriteriaComponent.prototype.validateAccounts = function () {
        var countResult = this.checkQuestion('registeredAccountsCountQuestion', this.YES);
        var expectedCountHidden = this.hiddenControls.includes('registeredAccountsExpectCountQuestion');
        if ((!countResult && expectedCountHidden)
            || (!countResult && !expectedCountHidden && !this.checkQuestion('registeredAccountsExpectCountQuestion', this.YES))) {
            this.addError('registeredAccountsCountQuestion', ['registeredAccountsCountQuestion', 'registeredAccountsExpectCountQuestion']);
        }
        if (!this.hiddenControls.includes('registeredAccountProfitableQuestion')) {
            this.validateQuestion('registeredAccountProfitableQuestion', this.YES);
        }
    };
    BasicEligibilityCriteriaComponent.prototype.validateCompanyStructure = function () {
        if (this.form.controls['directors'].value < MIN_DIRECTORS_SHAREHOLDERS
            && this.form.controls['shareholders'].value < MIN_DIRECTORS_SHAREHOLDERS
            && !this.checkQuestion('directorsOrShareholdersExpectedQuestion', this.YES)) {
            this.addError('directorsAndShareholdersLessThenMin', ['directors', 'shareholders', 'directorsOrShareholdersExpectedQuestion']);
        }
        if (this.form.controls['entitiesInGroup'].value > MAX_ENTITIES_IN_GROUP) {
            this.addError('entitiesInGroupMoreThenMax', ['entitiesInGroup']);
        }
    };
    BasicEligibilityCriteriaComponent.prototype.validateLoanAmount = function () {
        var loanAmount = Number.parseInt(this.form.controls['loanAmount'].value, 10);
        var currentTotalDebt = Number.parseInt(this.form.controls['currentTotalDebt'].value, 10);
        var capleLoanPercentage = loanAmount / (loanAmount + currentTotalDebt) * 100;
        if (!isCapleLoanPercentageHigh(capleLoanPercentage)) {
            this.notices.push(this._fundingProcessProductType === ProductType.GROWTH ? 'capleLoanPercentageHighGrowth' : 'capleLoanPercentageHighTransaction');
        }
        this.validateQuestion('refinanceExistingDebtQuestion', this.NO);
    };
    BasicEligibilityCriteriaComponent.prototype.hideControl = function (controlName) {
        if (!this.hiddenControls.includes(controlName)) {
            this.hiddenControls = this.hiddenControls.concat([controlName]);
            var control = this.form.controls[controlName];
            control.clearValidators();
            control.setValue(null);
            control.markAsUntouched();
        }
    };
    BasicEligibilityCriteriaComponent.prototype.showControl = function (controlName, additionalValidators) {
        var index = this.hiddenControls.indexOf(controlName);
        if (index > -1) {
            this.hiddenControls.splice(index, 1);
            var validators = additionalValidators ? [Validators.required].concat(additionalValidators) : Validators.required;
            this.form.controls[controlName].setValidators(validators);
        }
        if (this.loaded && !this.animatedControls.includes(controlName)) {
            this.animatedControls = this.animatedControls.concat([controlName]);
        }
    };
    return BasicEligibilityCriteriaComponent;
}(EligibilityParent));
export { BasicEligibilityCriteriaComponent };
