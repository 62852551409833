import { Component, OnDestroy } from '@angular/core';
import { State } from '../../state-management/reducers';
import { Store } from '@ngrx/store';
import { LoginSuccess } from '../../state-management/login/actions';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'caple-login-page',
  templateUrl: './login-page.component.html'
})
export class LoginPageComponent implements OnDestroy {

  private loginSuccesSubscription: Subscription = new Subscription();

  constructor(private store: Store<State>, private route: ActivatedRoute) {
  }

  ngOnDestroy(): void {
    this.loginSuccesSubscription.unsubscribe();
  }

  public loginSuccess(accessToken: string) {
    this.loginSuccesSubscription.unsubscribe();
    this.loginSuccesSubscription.add(
      this.route.queryParams.subscribe(params => {
        const redirectUri = params['redirectUri'];
        this.store.dispatch(new LoginSuccess(accessToken, redirectUri ? redirectUri : '/dashboard'));
      })
    );
  }
}
