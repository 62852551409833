import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { InjectableRxStompConfig, RxStompService, rxStompServiceFactory } from '@stomp/ng2-stompjs';
import { createWebsocketConfig } from './websocket.config';
import { Store } from '@ngrx/store';

@NgModule({
  providers: []
})
export class WebsocketModule {

  constructor(@Optional() @SkipSelf() parentModule: WebsocketModule) {
    if (parentModule) {
      throw new Error(
        'WebsocketModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: WebsocketModule,
      providers: [
        {provide: InjectableRxStompConfig, useFactory: createWebsocketConfig, deps: [Store]},
        {provide: RxStompService, useFactory: rxStompServiceFactory, deps: [InjectableRxStompConfig]},
      ]
    };
  }
}
