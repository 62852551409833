/**
 * Caple Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type GrowthFundingSourceCategory = 'CAPLE' | 'OTHER_LENDER' | 'EQUITY' | 'SHAREHOLDER_LOAN' | 'VENDOR_LOAN' | 'DIRECTOR_LOAN' | 'OTHER';

export const GrowthFundingSourceCategory = {
    CAPLE: 'CAPLE' as GrowthFundingSourceCategory,
    OTHERLENDER: 'OTHER_LENDER' as GrowthFundingSourceCategory,
    EQUITY: 'EQUITY' as GrowthFundingSourceCategory,
    SHAREHOLDERLOAN: 'SHAREHOLDER_LOAN' as GrowthFundingSourceCategory,
    VENDORLOAN: 'VENDOR_LOAN' as GrowthFundingSourceCategory,
    DIRECTORLOAN: 'DIRECTOR_LOAN' as GrowthFundingSourceCategory,
    OTHER: 'OTHER' as GrowthFundingSourceCategory
}
