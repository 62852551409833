import * as tslib_1 from "tslib";
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ShowToast } from '../../state-management/layout/actions';
import { Effects } from '../../state-management/util/effect-functions';
import { actions } from '../../state-management/util/actions';
import { typeForSlice } from '../../state-management/util/util';
import { Store } from '@ngrx/store';
import { SLICE } from './slice';
import { EligibilityService } from '../../shared/api/services/eligibility.service';
import { selectEligibilityIsLoaded } from './selector';
var EligibilityEffects = /** @class */ (function () {
    function EligibilityEffects(actions$, store, effects, eligibilityService) {
        this.actions$ = actions$;
        this.store = store;
        this.effects = effects;
        this.eligibilityService = eligibilityService;
        this.showError$ = this.actions$
            .pipe(ofType.apply(void 0, typeForSlice(SLICE, actions.FAIL)), switchMap(function (action) {
            return of(new ShowToast(action.payload));
        }));
        /*************
         * ELIGIBILITY
         *************/
        this.loadEligibility$ = this.effects
            .loadSingle(SLICE.ELIGIBILITY, 'urn:eligibility:load', {
            functionToExecute: this.eligibilityService.getById,
            scope: this.eligibilityService
        }, selectEligibilityIsLoaded);
        this.createEligibility$ = this.effects
            .createSingle(SLICE.ELIGIBILITY, 'urn:eligibility:save', {
            functionToExecute: this.eligibilityService.saveEligibility,
            scope: this.eligibilityService
        });
        this.updateEligibility$ = this.effects
            .updateSingle(SLICE.ELIGIBILITY, 'urn:eligibility:save', {
            functionToExecute: this.eligibilityService.updateEligibility,
            scope: this.eligibilityService
        });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], EligibilityEffects.prototype, "showError$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], EligibilityEffects.prototype, "loadEligibility$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], EligibilityEffects.prototype, "createEligibility$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], EligibilityEffects.prototype, "updateEligibility$", void 0);
    return EligibilityEffects;
}());
export { EligibilityEffects };
